import { Button, Fade, Modal } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./Formsubmission.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import homeImage from "../../../../constant";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import Header from "../../../../components/Pages/Header/Header";

export default function Formsubmission(props) {
  const handleClose = () => {
    props.setOpen(false);
  };
  useEffect(() => {
   window.scrollTo({
    top: 0,
    behavior: "smooth"
   })
  },[])

  const path = window.location.pathname.split("/")[2];

  const dynamicText = {
    artist: "We are pre-registering the artist prior to the launch of our new Art Smiley Marketplace. All selected artist will be notified prior to the launch with access to your dashboards to complete the registration process.",
    curator: "We are pre-registering the curator prior to the launch of our new Art Smiley Marketplace. All selected curator will be notified prior to the launch with access to your dashboards to complete the registration process.",
    affiliate: "We are pre-registering the affiliate prior to the launch of our new Art Smiley Marketplace. All selected affiliate will be notified prior to the launch with access to your dashboards to complete the registration process.",
    gallery: "We are pre-registering the gallery prior to the launch of our new Art Smiley Marketplace. All selected gallery will be notified prior to the launch with access to your dashboards to complete the registration process.",
  };
  
  return (
    <>
      <Header />
      <Navbar />
      <div
        className={
          window.location.pathname.split("/")[2] === "artist"
            ? styles.Formsubmission
            : window.location.pathname.split("/")[2] === "curator"
            ? styles.FormsubmissionCurator
            : window.location.pathname.split("/")[2] === "affiliate"
            ? styles.FormsubmissionAffiliate
            : window.location.pathname.split("/")[2] === "gallery" ? styles.FormsubmissionGallery : null
        }
      >
        <Box className={styles.modal_uploaded}>
          <h1 className={styles.top_heading}>
            Thank you for your application on{" "}
          </h1>
          <h2 className={styles.second_heading}>Art Smiley! </h2>
          <div className={styles.card}>
            <div className={styles.done_icon}>
              <img
                src={ "/img/checkmark 1 .png"}
                width="74px"
                height="67px"
              />
              <h1>Your submission has been received!</h1>
            </div>
            <h2 className={styles.upload_first_text}>
            {dynamicText[path] || "We are preparing for the launch of our new Art Smiley Marketplace. Stay tuned for updates!"}
            </h2>
            <h2 className={styles.upload_second_text}>
              Due to high volume of applications we are receiving, the review
              process will take little longer thAlso soon we will be notifiying
              the launch date for Art Smiley marketplace.
            </h2>
          </div>
        </Box>
      </div>
    </>
  );
}
