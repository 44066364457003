import React, { useState } from "react";
import Commonsidebar from "../../../List/Common/sideBar/Commonsidebar";

const sidebarRoutes = [
  {
    name: "MY PROFILE",
    icon: "fa fa-dashboard",
    path: "/dashboard",
    hasSubmenu: false,
  },
  {
    name: "MY ADDRESS",
    icon: "fa fa-truck",
    path: "/useraddress",
    hasSubmenu: false,
  },
  {
    name: "MY INQUIRY",
    icon: "fa fa-cube",
    path: "/userinquiry",
    hasSubmenu: false,
  },
  {
    name: "MY ORDERS",
    icon: "fa fa-users",
    path: "/userorder",
    hasSubmenu: false,
  },
  {
    name: "FOLLOWED ARTIST",
    icon: "fa fa-cube",
    path: "/followedartist",
    hasSubmenu: false,
  },
  // {
  //   name: "REQUEST FOR QOUTE ",
  //   icon: "fa fa-th-large",
  //   path: "/userquotes",
  //   hasSubmenu: false,
  // },
  {
    name: "ART SMILEY NEWS",
    icon: "fa fa-cube",
    path: "/news",
    hasSubmenu: false,
  },
  {
    name: "REQUEST MADE",
    icon: "fa fa-cube",
    path: "/requestmade",
    hasSubmenu: false,
  },
  {
    name: "GIFT CARD",
    icon: "fa fa-cube",
    path: "/giftcard",
    hasSubmenu: false,
  },
  {
    name: "CAMPAIGNS",
    icon: "fa fa-cube",
    path: "/campaigns",
    hasSubmenu: false,
  },
  {
    name: "AUCTIONS",
    icon: "fa fa-cube",
    path: "/auction",
    hasSubmenu: false,
  },
  {
    name: "EXHIBITIONS",
    icon: "fa fa-cube",
    path: "/exhibition",
    hasSubmenu: false,
  },
  {
    name: "DISPUTE",
    icon: "fa fa-cube",
    path: "/userdispute",
    hasSubmenu: false,
  },
];
export default function UserSidebar() {
  const [open, setOpen] = useState(true);

  return (
    <>
      {/* <Webpopup open={open} setOpen={setOpen} /> */}
      {/* <Artistgallery open={open} setOpen={setOpen} /> */}
      <Commonsidebar sidebarRoutes={sidebarRoutes} />
    </>
  );
}
