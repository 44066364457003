import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
        country_code: "hi-IN",
        currency: "INR",
    }
};

const deliveryCountrySlice = createSlice({
    name: "deliverycountry",
    initialState,
    reducers: {
        setDeliveryCountry: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setDeliveryCountry } = deliveryCountrySlice.actions;

export default deliveryCountrySlice.reducer;
