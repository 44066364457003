import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Box, IconButton, MenuItem, Select } from "@mui/material"
import React from "react"
import styles from "./AdminFiveColumn.module.css"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { addData } from "../../../../../../State/Features/Artist/profileSlice"
import CircularLoader from "../../../../../../common/loader/Loader"
import moment from "moment"

export default function AdminFiveColumn(props) {
  console.log(props, "props--auc")
  const [anchorEl, setAnchorEl] = React.useState(null)
  const loader = useSelector((state) => state.loader)
  const [threedot_id, setThreedot_id] = React.useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const threedots = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setThreedot_id(event.target.id)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handledetailpage = (row) => {
    if (location.pathname === "/artist") {
      navigate(`/artist/${row._id}`)
    } else if (location.pathname === "/affiliate") {
      navigate(`/affiliate/${row._id}`)
    }
    // else if (location.pathname === "/auction") {
    //   navigate(`/auction/${row._id}`)
    // }
    else if (location.pathname === "/exhibitions") {
      navigate(`/exhibitions/${row._id}`)
    } else if (location.pathname === "/career") {
      navigate(`/career-detail/${row._id}`)
    }
    dispatch(addData(row))
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  className={styles.table_head}
                  align={props.head.length - 1 === i ? "left" : "left"}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((row, i) => (
                    <TableRow
                      style={{ color: "#636365", height: "30px" }}
                      className={styles.table_row}
                      key={row._id}
                    >
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() => handledetailpage(row)}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "request"
                          ? row.start_date &&
                            moment(row.start_date).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "request"
                          ? row.end_date &&
                            moment(row.end_date).format("DD-MM-YYYY")
                          : ""}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {props.activetab === "request" ? row.name : ""}
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Select
                          value=""
                          // onChange={handleChange}
                          displayEmpty
                          // fullWidth
                          sx={{
                            background: "#f6f6f6",
                            ".MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-root": {
                              borderRadius: "6px",
                            },
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#636365",
                            height: "40px",
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">Artists Name</MenuItem>
                          {row.invite_artists &&
                            row.invite_artists.map((val) => (
                              <MenuItem disabled key={val._id}>
                                {val.first_name} {val.last_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </TableCell>

                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.column}
                        align="center"
                      >
                        <IconButton>
                          <img
                            src={"/img/delete.png"}
                            height="15px"
                            width="14px"
                            alt="img"
                            onClick={() => props.handleDeleteAuction(row._id)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
