import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const blogSlice = createSlice({
    name: "blogData",
    initialState,
    reducers: {
        setBlogdata: (state, action) => {
            state.data = action.payload;
        }
    },
});

export const { setBlogdata } = blogSlice.actions;

// export const testimonial = (state) => state.testmonialdata;

export default blogSlice.reducer;
