import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./State/app/Store";
import { Provider, useDispatch } from "react-redux";
import {
  BrowserRouter,
 
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { removeUser, setUser } from "./State/Features/common/userSlice";

import "swiper/css/bundle";
import { TranslationProvider } from "react-google-multi-lang";
const theme = createTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: '"DM Sans", sans-serif',
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        fontFamily: '"DM Sans",sans-serif',
        fontSize: "18px",
        lineHeight: "26px",
      },
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    
    <Provider store={store}>
      <ThemeProvider theme={theme}>
    <TranslationProvider
        apiKey="AIzaSyCSQByqNgZ8UWwAUOkX5JmcbbLVoUyLyYw"
        defaultLanguage={localStorage.getItem("selectedLanguageCode") || "en"}
      >
      <BrowserRouter>
        <App />
        </BrowserRouter>
      </TranslationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
