import { TextField } from '@mui/material';
import { borderRadius } from '@mui/system';
import React, { useState } from 'react';
import Togglebutton from '../../Common/toggleButton/Togglebutton';
import styles from './Addpress.module.css';
import homeImage from '../../../../../constant';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Addpress() {
    const [image, setImage] = useState('');
    const [src, setSrc] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const handleChangeImage = (e) => {
        console.log('trigger', e.target)
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0]
            const imgs = URL.createObjectURL(e.target.files[0])
            setImage(e.target.files[0]);
            setSrc(imgs);
        }
    }
    const handleBack = () => {
        if (location.pathname.includes("/blog/")) {
            navigate("/blog");
        } else if (location.pathname.includes("/pressrelease/")) {
            navigate("/pressrelease");
        }
    };
    return (
        <div className={styles.mains}>
            <div className={styles.components}>
                <div className={styles.back_button}>
                    <img
                        src={ "/img/_ Back.png"}
                        onClick={handleBack}
                        style={{ cursor: "pointer" }}
                        className={styles.backimage}
                        height="13px"
                        width="46px"
                    />
                </div>
                <div className={styles.card}>
                    <div className={styles.header}>
                        <h2 className={styles.textarea_label}>Add New Post</h2>
                        <TextField placeholder='Enter title' type='text' fullWidth sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px', backgroundColor: '#f6f6f6' }} ></TextField>
                    </div>
                    <div className={styles.upload_img}>
                        <div className={styles.textarea_label}>Add Media</div>
                        <div className={styles.preview_img}>
                            {src === '' ?
                                <div className={styles.img_box}>
                                    <div htmlFor='input' style={{ textAlign: 'center' }}
                                        onClick={handleChangeImage}>
                                        <input
                                            onChange={handleChangeImage}
                                            type="file"
                                            id='input'
                                            hidden
                                        /><label htmlFor='input' className={styles.label}>
                                            <img src={ '/img/uploadimg.png'} width='46px' height='43px' />
                                            <p>  Upload your image
                                            </p></label>
                                    </div>
                                </div>
                                :
                                <>
                                    <input
                                        onChange={handleChangeImage}
                                        type="file"
                                        id='input'
                                        hidden
                                    />
                                    <label htmlFor='input'>
                                        <img src={src} alt='img' id='img' className={image ? styles.img_view : styles.img_view_hide} />
                                    </label>
                                </>
                            }
                        </div>
                        <div className={styles.file_div}></div>
                        <p>Upload an image of your artwork.Your file must be least 1200*1500 px , a JPG or PNG file ,width a color format of rgb,and less than 50MB</p>
                    </div>

                    <div>
                        <Togglebutton />
                    </div>
                </div>
            </div>
        </div>
    )
}
