import validator from "validator"
import isEmpty from "./isEmpty"

export const validateEditOfferad = (offer) => {
  let offerErrors = {}

  // Validate Offer Name
  if (validator.isEmpty(offer.name)) {
    offerErrors.name = "Offer name is required"
  }

  // Validate Discount
  if (validator.isEmpty(offer.discount)) {
    offerErrors.discount = "Discount is required"
  } else if (!validator.isNumeric(offer.discount.toString())) {
    offerErrors.discount = "Discount must be a number"
  }

  // Validate Discount Type
  if (validator.isEmpty(offer.discountType)) {
    offerErrors.discountType = "Discount type is required"
  }

  // Validate Offer Image
  if (!offer.image) {
    offerErrors.image = "Offer image is required"
  }

  // Validate Start Date
  if (validator.isEmpty(offer.start_date)) {
    offerErrors.start_date = "Start date is required"
  }

  // Validate End Date
  if (validator.isEmpty(offer.end_date)) {
    offerErrors.end_date = "End date is required"
  }

  // Validate Description
  if (validator.isEmpty(offer.description)) {
    offerErrors.description = "Description is required"
  }

  return {
    offerErrors,
    isValid: isEmpty(offerErrors),
  }
}
