import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const menuSlice = createSlice({
  name: "menuData",
  initialState,
  reducers: {
    menuData: (state, action) => {
      state.data = action.payload
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
})

export const { menuData } = menuSlice.actions

export const menuesData = (state) => state.menuData

export default menuSlice.reducer
