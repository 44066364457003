import { Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import React from 'react';
import styles from './Editprofilesecond.module.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import homeImage from '../../../../constant';

const input = [
    {
        label: 'What is your first name?*',
        placeholder: 'Emily',
        name: 'name',
        type: 'text'
    },
    {
        label: 'What is your last name? *',
        placeholder: 'Cooper',
        name: 'last_name',
        type: 'text'
    },
    {
        label: "How many years of experience you have as an artist?*",
        placeholder: '25',
        name: 'experience',
        type: 'tel'
    },
    {
        label: 'If you are represented by another online art gallery, Please provide URL links of your works on those galleries: ',
        placeholder: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi...',
        name: 'mail',
        type: 'email'
    },
    {
        label: 'Please provide the link to your online portfolio: ',
        placeholder: 'www.artsmiley.com',
        name: 'cv',
        type: 'text'
    },
    {
        label: 'Please provide us the links of your social media platform platform here:',
        placeholder: 'www.artsmiley.com',
        name: 'if_proof',
        type: 'text'
    },
    {
        label: 'What is your main category? ',
        placeholder: 'Painting',
        name: 'category',
        type: 'select'
    },
    {
        label: 'Please select your artwork selling price range:',
        placeholder: '$250',
        name: 'range',
        type: 'select'
    },
    {
        label: 'How many artworks have you sold in the past 12 months? ',
        placeholder: '5',
        name: 'past_12month',
        type: 'text'
    },
    {
        label: 'How many artworks do you produce each month? ',
        placeholder: '15',
        name: 'work_per_month',
        type: 'text'
    },
    {
        label: 'Why do you think Art Smiley would need to select you and promote your works?',
        placeholder: 'I am an experienced artist',
        name: 'why_buy',
        type: 'text'
    },

]
export default function Editprofilesecond() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    return (
        <div className={styles.mains}>
            <div className={styles.components}>
                {input.map((val) => (
                    <div key={val.name} className={styles.inputs_div_second}>
                        {val.type === 'select' ?


                            <div className={styles.inputs_div}>
                                <label className={styles.input_label}>What is your main category? </label>
                                <FormControl sx={{}} fullWidth>
                                    <Select
                                        value={age}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        fullWidth
                                        sx={{
                                            mt: 0.5,
                                            color: '#636365', backgroundColor: 'white',
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                                borderRadius: "6px"
                                            }, ".MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                                borderRadius: "6px",
                                                // backgroundColor: 'white',
                                                // color:'black'
                                            },
                                        }}
                                    >
                                        <MenuItem value="">
                                            Painting
                                        </MenuItem>
                                        <MenuItem value={10}>Painting</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            :
                            <div className={styles.inputs_div}>
                                <label className={styles.input_label}>{val.label}</label>
                                <TextField className={styles.inpu} variant='outlined' type={val.type} name={val.name} placeholder={val.placeholder} sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '6px', mt: 1, mb: 0.5,
                                    fontFamily: 'DM Sans',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                            // opacity: 0.5
                                        },
                                    }
                                }} fullWidth />
                            </div>
                        }
                    </div>
                ))}
                <div className={styles.inputs_div}>
                    <FormControl>
                        <label className={styles.input_label}>Do you prefer to work Exclusively with Art Smiley?</label>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group" defaultValue="yes"
                        >
                            <FormControlLabel value="yes" control={<Radio defaultChecked size='small' />} label="Yes" />
                            <FormControlLabel value="male" control={<Radio size='small' />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={styles.inputs_div}>
                    <FormControl>
                        <label className={styles.input_label} id="demo-row-radio-buttons-group-label">I confirm that the information given in this form is true, complete and accurate. </label>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            defaultValue="yes"
                        >
                            <FormControlLabel defaultChecked value="yes" control={<Radio size='small' />} label="Yes" />
                            <FormControlLabel value="male" control={<Radio size='small' />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={styles.inputs_div}>
                    <label className={styles.input_label}>What is your your preferred language? *</label>
                    <FormControl sx={{}} fullWidth>
                        <Select
                            value={age}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            fullWidth
                            sx={{
                                mt: 0.5,
                                color: '#636365', backgroundColor: 'white',
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                    borderRadius: "6px"
                                }, ".MuiOutlinedInput-notchedOutline": {
                                    border: "none",
                                    borderRadius: "6px",
                                    // backgroundColor: 'white',
                                    // color:'black'
                                },
                            }}
                        >
                            <MenuItem value="">
                                English
                            </MenuItem>
                            <MenuItem value={10}>English</MenuItem>
                            <MenuItem value={20}>Hindi</MenuItem>
                            <MenuItem value={30}>Kannad</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className={styles.files_div}>
                    <div>
                        <label className={styles.input_label}>ID</label><br />
                        <input type='file' />
                    </div>
                    <div>
                        <label className={styles.input_label}>Cover Image</label><br />
                        <input type='file' />
                    </div>
                </div>
                <div className={styles.new_heading}>
                    <h2>Bank</h2>
                    <hr className={styles.hrs} />

                </div>
                <div className={styles.new_heading}>
                    <p>Please add bank details</p>
                    <Button sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <img src={  '/img/addicon.png'} height='13px' width='13px' />
                        Add
                    </Button>
                </div>
                <div className={styles.btn_div}>
                    <Button variant='contained' sx={{ width: '120px', height: '54px', borderRadius: '10px' }} >Save</Button>
                </div>
            </div>
        </div>
    )
}
