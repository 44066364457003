import React, { useState } from "react"
import { Route, Routes } from "react-router-dom"
import Affiliatepage from "./affiliate/page/affiliate/Affiliatepage"

import Earningsaffiliate from "./affiliate/page/earnings/Earningsaffiliate"
import Myprofileaffiliate from "./affiliate/page/myProfile/Myprofileaffiliate"
import Home from "./common/home/Home"
import ProductDetails from "./components/Pages/User/ProductDetails"
import UserMain from "./UserMain"
import { useSelector } from "react-redux"
import Arts from "./affiliate/page/arts/Arts"
import TableAffiliate from "./affiliate/Table/TableAffiliate"
import MarketingMaterial from "./affiliate/Marketingmaterial/MarketingMaterial"

export default function Affiliate() {
  const user = useSelector((state) => state.user.data)
  return (
    <Routes>
      {user.is_accepted === "Pending" ? (
        <>
          <Route path="*" element={<UserMain />} />
          <Route path="/home" element={<Home user="Affiliate" />} />
        </>
      ) : (
        <>
          <Route path="*" element={<UserMain />} />
          <Route
            path="/dashboard"
            element={
              // localStorage.getItem("applied_profile") === "true" ? (
              //   <Myprofileaffiliate />
              // ) : (
              //   <Affiliatepage />
              // )
              <TableAffiliate />
            }
          />
          <Route path="/earnings" element={<Earningsaffiliate />} />
          <Route path="/arts/:id" element={<ProductDetails />} />
          <Route path="/myprofile" element={<Myprofileaffiliate />} />
          <Route path="/marketing" element={<MarketingMaterial />} />
          {/* <Route path="/artsmiley/applyform" element={<Affiliateapplyform />} />
        <Route path="/artsmiley/applyformsecond" element={<Applysecondaffileate setApply={setApply} apply={apply}
          handleChangeApplyData={handleChangeApplyData} />} />
        <Route path="/artsmiley/fillquestion" element={<Fillquestionaffliate handleChangeSocialLinks={handleChangeSocialLinks}
          handleSelectCheckbox={handleSelectCheckbox}
          apply={apply}
          handleChangeApplyData={handleChangeApplyData} />} />
        <Route path="/artsmiley/questionhome" element={<Questionhomeaffliate />} />
        <Route path="*" element={<UserMain />} /> */}
        </>
      )}
    </Routes>
  )
}
