import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 data: null,
};

export const quateDataSlice = createSlice({
 name: "uploadart",
 initialState,
 reducers: {
  setQuateData: (state, action) => {
   state.data = action.payload;
  },
 },
});

// Action creators are generated for each case reducer function
export const { setQuateData } = quateDataSlice.actions;

export default quateDataSlice.reducer;
