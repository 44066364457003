import { Avatar, Button, Grid, Typography } from "@mui/material";
import React from "react";
import styles from "./AboutUs.module.css";
import homeImage from "../../../constant";
function AboutUs() {
  const ourArtistCard = [
    {
      id: 1,
      imgUrl: "/img/ourartist1.png",
      artistName: "Maria Cooper",
    },
    {
      id: 2,
      imgUrl: "/img/ourartist2.png",
      artistName: "Titus Archibald",
    },
    {
      id: 3,
      imgUrl: "/img/ourartist3.png",
      artistName: "Winslow Homer",
    },
  ];
  return (
    <div className={styles.aboutus}>
      <div className={styles.header}>
        <div>
          <Typography fontFamily="marcellus" color="white" fontSize="2vw">
            <span className={styles.bannerheading}>About Us</span>
          </Typography>
          <Typography fontFamily="marcellus" color="white" fontSize="1vw">
            <span className={styles.bannersubheading}>
              <b>Home</b>/ About Us
            </span>
          </Typography>
        </div>
      </div>
      <div className={styles.aboutuscontainer}>
        <div className={styles.aboutusTop}>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={5} md={5}>
              <div className={styles.leftText}>
              <p>Online Art Gallery, ArtSmiley, Buy affordable art online</p>
                <Typography fontFamily="marcellus" fontSize="3rem">
                About Art Smiley
                </Typography>
                <Typography fontSize="0.9rem" color="#636365" textAlign='justify'>
                Art Smiley, founded in 2017, has grown into a versatile and innovative art service provider, offering an extensive range of art-related solutions across three primary divisions: Marketplace, Business Services, and NFTs. What began as a simple online platform has transformed into a comprehensive art hub, serving the diverse needs of collectors, homeowners, businesses, and trade professionals. Each division operates with a dedicated team to ensure tailored and high-quality service delivery, meeting the ever-evolving demands of the art industry.
                </Typography><br/>
                {/* <div className={styles.top__flex}>
                  <Typography fontSize="0.9rem" color="#636365">
                    Lorem ipsum dolor sit amet, conse adipiscing elit. Quisque
                    vestibulum duis ultricies mauris.
                  </Typography>
                  <Typography fontSize="0.9rem" color="#636365">
                    Lorem ipsum dolor sit amet, conse adipiscing elit. Quisque
                    vestibulum duis ultricies mauris.
                  </Typography>
                </div><br/> */}
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "20%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="10px">Explore</Typography>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <div className={styles.greybgDiv}>
                <img
                  className={styles.greybg}
                  src={  "/img/Rectangle grey bg.png"}
                />
              </div>
              <div className={styles.vintageframeDiv}>
                <img
                  className={styles.vintageframe}
                  src={ "/img/vintage-wood-frame-mockup-front-view 1.png"}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.welcomeheading}>
          <Typography fontFamily="marcellus" fontSize="3.5rem">
            Welcome To Art Smiley
          </Typography>
        </div>
        <div className={styles.maingrid__container}>
          <Grid mt={2} container>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex}>
                <img
                  className={styles.leftImage}
                  src={  "/img/curatorleftimg1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages}
                    src={  "/img/curatorrightimg1.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages}
                    src={  "/img/curatorrightimg2.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Art Smiley Marketplace
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                The Art Smiley Marketplace offers an expansive selection of contemporary art, digital art, photography prints, sculptures, and home décor items. Individuals and businesses looking for unique art pieces for their spaces can purchase, sell, and rent them from us. Participate in our exciting auctions to acquire one-of-a-kind artworks while connecting directly with artists and fellow collectors. Explore our virtual exhibitions, curated by industry experts, to discover fresh talent and innovative pieces. Our dedicated curators enhance your experience by providing personalized recommendations, while our affiliate program allows art enthusiasts to earn commissions by sharing these exceptional works with their networks. We offer professional framing services to enhance the presentation of your artworks, along with a selection of limited edition pieces that elevate your space.
                </Typography>
              
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              ArtSmiley Business
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Our specialty is delivering customized art solutions to government entities and industries such as real estate, hospitality, corporate environments,  and interior design. We offer services like UV printing, canvas printing, mural paintings, framing,  sculptures, graffiti art, royal portraits and public arts. Our expertise ensures businesses find art that perfectly matches their aesthetic and functional needs.
                </Typography>
          
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Art Smiley NFTs
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Venturing into the digital realm, Art Smiley has created a decentralized NFT marketplace combining traditional and digital artworks. Our NFT platform allows collectors to purchase, sell, and trade digital artwork and rare collectibles. We aim to bridge the gap between the physical and digital art worlds, bringing NFTs into the mainstream for art enthusiasts.
                </Typography>
           
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Mission
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                At Art Smiley, Our Dream is to make a community that has the world’s largest treasure - a trove of unique arts and crafts. We aspire to bring the world of arts, cultures, and artists under one common platform.
                </Typography>
          
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Vision
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley was born with the vision to make art accessible to every community and provide a viable career to artists.
                </Typography>
           
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Recognition & Awards
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley’s commitment to excellence has been recognized across the Middle East. We were honored as "Digital Business of the Year" in both 2020 and 2021 at the Gulf Capital SME Awards. In 2022, we were also finalists in the "Customer Focus of the Year" category, a testament to our dedication to client satisfaction.
                </Typography>
          
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Art Smiley Services
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley serves as a comprehensive platform that enables artists to reach a wider audience. Art buyers can navigate an expansive collection of artworks tailored to their preferences, including budget considerations, design themes, and color schemes.
We present an impressive variety of artistic styles, such as abstract, figurative, illustrative, nature, landscapes, Islamic art, and pop art, across multiple mediums. Our platform features essential tools like dedicated dashboards for artists and buyers, a feedback system, and the ability to follow and interact with favorite artists. Distinctive services, including virtual exhibitions, live auctions, and Augmented Reality (AR) experiences, elevate the art acquisition process.
                </Typography>
           
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Support for Artists
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley nurtures a collaborative environment for artists, offering various features and initiatives for connection and growth. Artists can display their work through virtual exhibitions and auctions, gaining exposure to a global audience of collectors and buyers. We host year-round modern and contemporary art auctions in partnership with galleries, artists, and auction houses worldwide. Each artist enjoys a personalized dashboard that provides insights into their artworks, exhibition details, auction information, followers, views, and likes.
                </Typography>
          
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Curated Selections
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Our team of curators from around the globe carefully handpicks exceptional artworks from a diverse array of artists. Art Smiley is dedicated to offering a unique assortment of art and crafts to customers, ensuring a rich and varied selection.
                </Typography>
           
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Cultural Preservation
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley is passionate about preserving cultural heritage through the creative expressions of our talented artists. We champion the harmonious blend of culture and tradition with our value-added services.
                </Typography>
          
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              What Buyers Can Anticipate from Art Smiley?
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley provides a holistic range of customized art services, all available under one roof. Our AR capabilities enhance the viewing experience, allowing buyers to visualize artworks in their own environments. We offer an extensive selection of original pieces and prints, including bulk rental services tailored to the needs of the hospitality sector and beyond.
Our curators meticulously select the finest collections from talented artists, while our artworks are showcased through physical and virtual exhibitions as well as auction events.
                </Typography>
           
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Join Our Affiliate Program
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Become a part of the Art Smiley Affiliate Program and start earning commissions today! We provide high-quality artwork at attractive prices, making us a favorite among art lovers. Affiliates can effortlessly track their earnings and access marketing materials via our online dashboard. With an easy process for generating product links, affiliates can share them with clients and earn commissions seamlessly. Our dedicated support team ensures prompt communication and assistance through our affiliate email service.
                </Typography>
          
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
                <p>Number of artists, curators, artworks, countries we represent</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Visual Search
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Art Smiley is the world’s largest art gallery, featuring a meticulously curated catalog of images, handcrafted custom framing, and high-quality printing. Our diverse art categories cater to every customer’s preferences, enhancing the art purchase journey.
Customers can search for artwork based on criteria such as medium, color, design, and other visual elements. With our deep learning-based search methods, Art Smiley delivers a list of artworks that perfectly match your specified criteria.

                </Typography>
           
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Discover Unique Artworks with ArtSmiley's Curators
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                At ArtSmiley, we prioritize your art collection journey by offering expert advice tailored to your needs, from selecting artwork to framing and installation. Our dedicated curators, in collaboration with industry experts, continuously scout art fairs and exhibitions worldwide to bring you the most innovative and culturally significant talents. By staying connected with the global art scene, we bring to our platform the freshest, most innovative artists that truly stand out.
                </Typography>
          
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Artist Selection Process
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                At ArtSmiley, we prioritize quality over quantity. Every artist we represent undergoes a rigorous selection process, ensuring that only the most exceptional creators are featured. Our team of curators evaluates each artist based on key factors such as exhibition history, education, and artistic merit. We classify artists according to their experience, allowing both new talents and established names to thrive. Emerging artists are those at the early stages of their career, showcasing fresh perspectives and innovative techniques. Featured artists have a proven track record of exhibitions and artistic achievements, offering collectors and curators a more seasoned approach to contemporary art.[Check Out Our Artists]
                </Typography>
           
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Our Team
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Our dedicated team includes passionate curators, art historians, and industry insiders who work closely with artists and collectors alike. They guide the process from artist selection to artwork delivery, ensuring a seamless and enriching experience.
                </Typography>
          
                {/* <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Start Learning</Typography>
                </Button> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={4} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex3}>
                <img
                  className={styles.leftImage3}
                  src={  "/img/aboutusflex1.png"}
                />
                <div>
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex2.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages3}
                    src={  "/img/aboutusflex3.png"}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6.5} fontFamily="marcellus" fontSize="2rem">
              Stay Updated with Our Curator’s Picks
              </Typography>
              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Our curators hand-select a range of artworks, highlighting top picks from the newest arrivals each week. Our collections offer everything from contemporary paintings to intricate sculptures for every taste and budget. Be sure to check out the latest curated collections to find the perfect piece for your home or collection.
                </Typography>
           
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png" }/>
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid mt={3} container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Typography ml={6} fontFamily="marcellus" fontSize="2rem">
              Articles News
              </Typography>

              <div className={styles.info__div}>
                <Typography color="#636365" fontSize="0.9rem">
                Looking for the freshest art news? Uncover unique insights into the visual arts world from experienced collectors and creative professionals. Be among the first to learn about global exhibitions, emerging artists, and well-known figures, with the opportunity to explore and purchase their artwork directly from our online gallery.
                </Typography>
          
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "40%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                  endIcon={
                    <img className={styles.iconimg} src={  "/img/Arrow 2.png"} />
                  }
                >
                  <Typography fontSize="8px">Know more</Typography>
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.image__flex2}>
                <img className={styles.leftImage2} src={  "/img/aboutusimg.png"} />
                <div>
                  <img
                    className={styles.rightImages2}
                    src={  "/img/Rectangle 5204.png"}
                  />
                  <br />
                  <img
                    className={styles.rightImages2}
                    src={  "/img/aboutusbirds.png"}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.ourartistdiv}>
          <div className={styles.artistTypo}><Typography fontFamily="marcellus" fontSize="4rem" mb={2}>Our Artists</Typography></div>
          
          <Grid container spacing={2}>
            {ourArtistCard.map((item) => (
              <Grid item xs={12} sm={4} md={4}>
                <div className={styles.ourartistcard}>
                  <img className={styles.ourartistimg} src={  item.imgUrl} />
                  <div className={styles.ourartistinfo}>
                    <div>
                      <Typography                        
                        fontFamily="dm sans"
                        fontWeight="500"
                        fontSize="20px"
                        textAlign='center'
                      >
                        {item.artistName}
                      </Typography>
                      <Typography
                        sx={{marginBottom: "2%" }}
                        fontFamily="dm sans"
                        fontWeight="500"
                        textAlign='center'
                        fontSize="15px"
                        color="#636365"
                      >
                        PAINTER, SCULPTOR ARTIST
                      </Typography>
                      <div className={styles.socialmediaflex}>
                        <img
                          className={styles.sociallogo}
                          src={  "/img/Facebookour.png"}
                        />
                        <img
                          className={styles.sociallogo}
                          src={  "/img/instagramour.png"}
                        />
                        <img
                          className={styles.sociallogo}
                          src={  "/img/linkedinour.png"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
