import { Button, Typography,Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import api from "../../../State/api/Api";
import { fetchPrivacyPolicy } from "../../../State/api/userApi";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import styles from "./PolicySidebar.module.css";

const divData = [
  {
    id: 1,
    label: "SHIPPING POLICY",
    value: "shipping__policy",
    url: "api/user/get-shipping-policy",
  },
  {
    id: 2,
    label: "RETURN POLICY",
    value: "return__policy",
    url: "api/user/get-return-policy",
  },

];
function PolicySidebar(props) {
  const dispatch = useDispatch();
  const [policydata, setPolicydata] = useState();
  const [policies, setPolicies] = useState({
    url: "api/user/get-shipping-policy",
    type: "shipping__policy",
  });

  const faqdata = useSelector((state) => state.userFaqData);
  const policiesdata = useSelector((state) => state.policyData);

  const loader = useSelector((state) => state.loader);

  const handleClick = (val) => {
    // SetdivLinks(val);
    setPolicies({ url: val.url, type: val.value });
    // props.policies
  };
  useEffect(() => {
    dispatch(fetchPrivacyPolicy(policies.url, {}));
  }, [policies]);

  console.log(policiesdata.data, "demo");

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
      <div className={styles.demo}>
        {divData &&
          divData.map((val, i) => (
            <div className={styles.demo_child_div} key={i}>
              <div
                className={policies.type === val.value ? styles.flex_start : ""}
              ></div>
              <Button
                value={policies.type}
                onClick={() => handleClick(val)}
                variant={policies.type === val.value ? "contained" : ""}
                fullWidth
                sx={{
                  ml: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  pl: 2,
                  mr: 1,
                  borderRadius: " 2.06016px 0px 0px 2.06016px",
                }}
              >
                {val.label}
              </Button>
            </div>
          ))}
      </div>
      </Grid>
      <Grid item xs={12} md={8}>
      {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : policies.type === "shipping__policy" ? (
        <div className={styles.shipping__policy}>
          {policiesdata.data &&
            policiesdata.data.response.slice(0,1).map((val) => (
              <div className={styles.information__use}>
                {/* <p>
                  <Typography fontSize="1.1rem">
                    <i>
                      <b>{val.title}: </b>
                    </i>
                    {val.description}
                  </Typography>
                </p> */}
            
                <div className={styles.calendarHeading}>
                  <h4>Shipping Details</h4>
                </div>
                <p><b>Delivery Timeline:{" "}</b>
                    Expect domestic deliveries within 5-7 business days, and international orders typically arrive within 10-14 business days.</p>

                <p><b>Shipping Fees:{" "}</b>
                    All shipping costs are not included with your purchase.
                </p>

                <p><b>Packaging:{" "}</b>
    Artworks are packed and dispatched securely to the customers. Artists follow strict packaging standards to ensure safe delivery. To know more about the packaging guidelines, <br />
    <a href="#"  style={{color:"black"}} rel="noopener noreferrer">
    visit here
    </a>.
</p>

                <p><b>Customs & Duties:{" "}
                      </b>Please note that international orders may be subject to
                    customs duties and taxes, which are the responsibility of
                    the customer. These fees are not included in the shipping
                    price.
                </p>

          
              </div>
            ))}
        </div>
      ) : policies.type === "return__policy" ? (
        <div className={styles.return__policy}>
          {policiesdata.data &&
            policiesdata.data.response.map((val) => (
              <div className={styles.information__use}>
                <div className={styles.calendarHeading}>
                  <p>{val.title}: {val.description}</p>
                </div>
                <div className={styles.calendarHeading}>
                  <h4 >
                    Return Guidelines
                  </h4>
                </div>
                <p><b>
                        {/* {val.title} */}
                        Return Period:{" "}
                      </b>
                    {/* {val.description} */}
                    We offer a 14-day return period for all artworks. For more
                    details, please visit our help section(add hyperlink for
                    page). If you have further questions, Get in touch with our
                    team at support@artsmiley.com. <br />
                    <a href="#"  style={{color:"black"}} rel="noopener noreferrer">
    visit here
    </a>.
                </p>

                {/* faq */}
                <div className={styles.calendarHeading}>
                  <h4>
                    Frequently Asked Questions
                  </h4>
                </div>
                <p>  <b>
                        {/* {val.title} */}1. How long will it take for my
                        artwork to be delivered?{" "}
                      </b>
                    {/* {val.description} */}
                 
                  Expect domestic deliveries within 5-7 business days, while
                  international orders usually arrive within 10-14 business
                  days. Delivery times may vary based on your location. After
                  your order is confirmed, we’ll provide an estimated delivery
                  date, and you’ll be able to track your shipment throughout the
                  entire process.
                </p>

                <p>  <b>
                        {/* {val.title} */}2. What should I do if my artwork
                        arrives damaged?{" "}
                      </b>
                  In the rare event your artwork is damaged during transit,
                  please contact us immediately with photos of the damage and
                  the packaging. We’ll guide you through the steps to resolve
                  the issue quickly, including a replacement or refund, as per
                  our policy. To learn more about our return process, visit our
                  return policy section here.
                </p>

                <p> <b>
                        {/* {val.title} */}3. Do you offer international
                        shipping?{" "}
                      </b>
                  Yes, we ship artwork worldwide! International shipping fees
                  are calculated based on the destination and size of the
                  artwork. Please note that customs duties may apply. To learn
                  more about our delivery options, please visit our shipping
                  page here(add hyperlink for page).
                </p>

                <p> <b>{/* {val.title} */}4. Can I track my shipment? </b>
                    
                  Yes, once your artwork has been shipped, we’ll send you a
                  tracking number so you can monitor the progress of your
                  delivery in real-time. You can also check the status of your
                  order through your account on our website(add hyperlink for
                  page).
                </p>

                <p>   <b>
                        {/* {val.title} */}5. What is your return policy for
                        custom-made or commissioned pieces?{" "}
                      </b> 
                    {/* {val.description} */} 
                  Custom and commissioned artworks are made specifically for you
                  and cannot be returned. However, we work closely with you
                  throughout the creation process to ensure the final piece
                  meets your expectations. For more details on returns, visit
                  our return policy page here. (add hyperlink for page)
                </p>

                <p>
                <b>
                        {/* {val.title} */}6. Are shipping fees refundable if I
                        return my artwork, and how much will I get refunded?{" "}
                      </b>
                    {/* {val.description} */}
                  While we offer a 14-day return policy, shipping fees are
                  non-refundable. You will receive a refund for the artwork cost
                  minus a 20% processing fee. For full details, please review
                  our shipping and returns policy.(add hyperlink for page)
                </p>

                <p>  <b>
                        {/* {val.title} */}7. Can I get my artwork framed
                        through ArtSmiley?{" "}
                      </b>
                    {/* {val.description} */}
                  Yes! We offer custom framing services crafted by skilled
                  artisans. Our framing options are designed with both
                  aesthetics and the preservation of your artwork and this is
                  available only for UAE customers.
                </p>

                <p>
                      <b>
                        {/* {val.title} */}8. Do you offer any financing options
                        for purchasing art?{" "}
                      </b>
                    {/* {val.description} */}
                  While we currently do not offer direct financing, we’re always
                  working on improving our services to better serve our
                  customers. However, for our UAE customers, we’ve partnered
                  with Tabby and Amazon, allowing you to pay for artwork in two
                  or three easy installments. This option provides flexibility
                  for budgeting your art investment.
                </p>
                {/* <Typography fontSize="1.1rem">
                <ul>
                  <li>personalize and imporove the Services;</li>
                  <li>
                    customize the Services and provide advertisements, content or
                    features that match your interests;
                  </li>
                  <li>allow our users to set up a user account and profile;</li>
                  <li>
                    communicate with you about or fulfil your requests for certain
                    products, services, offers, promotions and events offered by
                    ArtSmiley;
                  </li>
                  <li>
                    send you notices, updates, and other administrative messages;
                  </li>
                  <li>process transactions and send you related information;</li>
                  <li>operate sweepstakes, contests or giveaways;</li>
                  <li>
                    link or combine with information we get from others to help
                    provide you with better service;
                  </li>
                  <li>
                    detect, investigate and/or prevent fraudulent, unauthorized or
                    illegal activity
                  </li>
                  <li>
                    analyze and monitor how users utilize the websites and
                    Services; and
                  </li>
                  <li>
                    carry out other purposes described in this Privacy Policy
                  </li>
                </ul>
              </Typography> */}
              </div>
            ))}
        </div>
      ) : (
        <div className={styles.faqs}>
          {policiesdata.data &&
            policiesdata.data.response.map((val) => (
              <div className={styles.information__use}>
                <p>
                      <b>{val.title}: </b>
                    {val.description}
                  </p>
                <div className={styles.calendarHeading}>
                  <h4>
                    {val.title}
                  </h4>
                </div>
                <p>
                  {val.description}
                </p>

                {/* <Typography fontSize="1.1rem">
                    <ul>
                      <li>personalize and imporove the Services;</li>
                      <li>
                        customize the Services and provide advertisements, content or
                        features that match your interests;
                      </li>
                      <li>allow our users to set up a user account and profile;</li>
                      <li>
                        communicate with you about or fulfil your requests for certain
                        products, services, offers, promotions and events offered by
                        ArtSmiley;
                      </li>
                      <li>
                        send you notices, updates, and other administrative messages;
                      </li>
                      <li>process transactions and send you related information;</li>
                      <li>operate sweepstakes, contests or giveaways;</li>
                      <li>
                        link or combine with information we get from others to help
                        provide you with better service;
                      </li>
                      <li>
                        detect, investigate and/or prevent fraudulent, unauthorized or
                        illegal activity
                      </li>
                      <li>
                        analyze and monitor how users utilize the websites and
                        Services; and
                      </li>
                      <li>
                        carry out other purposes described in this Privacy Policy
                      </li>
                    </ul>
                  </Typography> */}
              </div>
            ))}
        </div>
      )}
      </Grid>
    </Grid>
    <div className={styles.sidebar_flex}>
      

      
    </div>
    </>
    
  );
}

export default PolicySidebar;
