import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import RemoveIcon from "@mui/icons-material/Remove";
import { Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../alert/Alert";
import { useMediaQuery } from "@material-ui/core";

const data = [
  {
    id: 1,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 2,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 3,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
];
const service_data = [
  {
    id: 1,
    src: "img/service_artist_1",
    heading: "Fair Participation",
    des: "We participate in 8 international art fairs each year to showcase our artists on a global scale.   ",
  },
  {
    id: 2,
    src: "img/service_artist_1",
    heading: "Our Online magzine",
    des: "Art Blog, News and Artist Interviews",
  },
  {
    id: 3,
    src: "img/service_artist_1",
    heading: "Meetings",
    des: "Artists Meet & Greet Jan 2020",
  },
];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeAffiliate() {

  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/artsmiley/affiliate/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
       
          <Box
            className={styles.hero_main_affiliate}
          
            sx={{  width:"100%",height:"100%" }}
          >
            <div className={styles.hero_heading}>
              <h1>
              Promote Art, Earn Commissions: 
              <br/>
              Start Your Affiliate Journey
              </h1>
            </div>
            <Button
              onClick={handleSignupButton}
              variant="contained"
              sx={{
                width: "158px",
                height: "63px",
                borderRadius: "9px",
                backgroundColor: "white",
                color: "black",
              }}
              className={styles.hero_heaing_button}
            >
              Apply
            </Button>
          </Box>
          <Box
            className={styles.hero_main1}
            sx={{ positions: "relative", width:"100%", height:"100%" }}
          >
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
              }}
              component="div"
            >
           Expand Your  Income Stream With Art Smiley Affiliate Program
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
                width:"60%",
              }}
              component="div"
            >
              Support artists while earning through the Art Smiley Affiliate Program. If you’re passionate about promoting art and connecting with others, you can earn recurring high commissions on sale by sharing our diverse range of artworks and prints.
            </Typography>
            <div className={styles.hero_card}>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "213px",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                {/* <Avatar className={styles.Avatar1} >s</Avatar> */}
                <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"img/curatorsignup.svg"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                    Sign Up
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
                    Register and await our review within 14 business days. You’ll receive an email update on the next steps.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "213px",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"img/curator-recommended.svg"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                    Recommended
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
                    Share artworks with your audience. We have customized linking tools and techniques to share the artwork.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "213px",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"img/curator-earn.svg"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                    Earn
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
                    You can earn high affiliate commissions depending on the artwork and the purchase.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
          <Box
            className={styles.hero_main_affiliate_benefits}
          
            sx={{ position:"relative", width:"100%",height:"100%",flexWrap:"wrap" }}
          >
            <Box className={styles.hero_main_curator_benefits_top}>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="108.3px"
                alt="logo"
                color="white"
              />
              <Typography
                sx={{
                  fontSize: "40px",
                  fontWeight: " 400",
                  fontFamily: "Marcellus",
                  color: "white",
                  lineHeight: "50.16px",
                }}
                component="div"
              >
                Benefits of Partnering As Affiliate with ArtSmiley
              </Typography>
            </Box>
            <Stack
              py={3}
              width="100%"
              direction="row"
              display="flex"
              justifyContent="center"
              spacing={3}
              flexWrap="wrap"
            >
               <Box className={styles.hero_main_affiliate_benefits_top_box}>
              <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
              <div className={styles.hero_main_curator_circle}>01</div>
              </div>
                <Typography variant="h5" color="#FFFFFF" pb={1}>High Commissions</Typography>
                <Typography variant="caption" color="#FFFFFF">
                Earn a commission of [dynamic value updated in superadmin]% on all artworks, sculptures, frames, prints etc. When a customer makes a purchase through your referral link, you earn a commission on the transaction.
                </Typography>
              </Box>
              <Box className={styles.hero_main_affiliate_benefits_top_box}>
              <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
              <div className={styles.hero_main_curator_circle}>02</div>
              </div>
                <Typography variant="h5" color="#FFFFFF" pb={1}>Reliable and secure</Typography>
                <Typography variant="caption" color="#FFFFFF">
                Tracking and payments are managed through our affiliate program. Art Smiley is committed to protecting from unethical marketing practices.
                </Typography>
              </Box>
              <Box className={styles.hero_main_affiliate_benefits_top_box}>
              <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
              <div className={styles.hero_main_curator_circle}>03</div>
              </div>
                <Typography variant="h5" color="#FFFFFF" pb={1}>Powerful tracking</Typography>
                <Typography variant="caption" color="#FFFFFF">
                With our affiliate dashboard, you can view clicks, trials, sales and commissions in one place. You will be paid every month at the same time.
                </Typography>
              </Box>
              <Box className={styles.hero_main_curator_benefits_top_box}>
              <div style={{width:"100%",display:'flex',justifyContent:'center'}}>
              <div className={styles.hero_main_curator_circle}>04</div>
              </div>
                <Typography variant="h5" color="#FFFFFF" pb={1}>Exclusive Access</Typography>
                <Typography variant="caption" color="#FFFFFF">
                Gain access to a range of exclusive promotions, art events, and special offers that you can share with your network.
                </Typography>
              </Box>
            </Stack>
            <Button
              onClick={handleSignupButton}
              variant="contained"
              sx={{
                width: "158px",
                height: "63px",
                borderRadius: "9px",
                backgroundColor: "white",
                color: "black",
              }}
              className={styles.hero_heaing_button}
            >
              Sign Up
            </Button>
          </Box>
          {/* <Box className={styles.benifits_box}>
                        <Box className={styles.benifits_card}>
                            h
                            <div className={styles.Avatar_ligth} >
                                <div className={styles.Avatar4}>04</div>
                            </div>
                            <h3 className={styles.benifits_head}>Best Earn</h3>
                            <p className={styles.benifits_des}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </Box>
                        <div className={styles.dot_line}></div>
                    </Box> */}

          <Box className={styles.hero_main6} sx={{ maxWidth: "1287px" }}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
        Frequently Asked Questions
            </Typography>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "25px",
                        height: "25px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel1" ? (
                    <div className={styles.accodian_no}>1</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel1" ? 0 : 7.1,
                    }}
                  >
                    What is the process for becoming an affiliate?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                The Art Smiley affiliate program allows its users to refer friends, family, and customers. The commissions from each sale are paid to the affiliates. Our platform also offers affiliate opportunities for artists, curators, and professionals who wish to sell our artworks and prints.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel2" ? (
                    <div className={styles.accodian_no}>2</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel2" ? 0 : 7.1,
                    }}
                  >
                    What is the commission process?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                Commissions are earned on qualified sales (excluding sales returned and sale cancellations). We have a 30-day cookie period. In this period, affiliates will receive commissions on all sales associated with their account.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel3" ? (
                    <div className={styles.accodian_no}>3</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel3" ? 0 : 7.1,
                    }}
                  >
                 What is the eligibility for Art Smiley's affiliate program?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                Art Smiley sells products that appeal to a variety of audiences. It is open to all affiliates to apply. You must have traffic of at least 1000 followers, generating consistent traffic on social media platforms. You must have a network of people interested in buying art and sculptures. You must have exposure to implementing multiple branding and promotion ideas that support affiliate programs supporting our website theme.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel4" ? (
                    <div className={styles.accodian_no}>4</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel4" ? 0 : 7.1,
                    }}
                  >
                    What happens after I register for the ArtSmiley Affiliate Program?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                After you register, you will receive a confirmation email welcoming you to the program. Our team will review your application within 14 business days. We will inform you about the next steps once the evaluation is complete. If you have any questions or need assistance, feel free to contact our affiliate support team.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel5" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel5bh-content"
                id="panel5bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel5" ? (
                    <div className={styles.accodian_no}>5</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel5" ? 0 : 7.1,
                    }}
                  >
                    What should I do if my affiliate application is rejected?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If your application is not successful, you will receive a notification email explaining that your profile did not align with our current affiliate needs. This decision is not a reflection of your talent. You are encouraged to enhance your profile and consider reapplying after 6 months. For more details or feedback, you can reach out to our support team.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel6" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel6bh-content"
                id="panel6bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel6" ? (
                    <div className={styles.accodian_no}>6</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel6" ? 0 : 7.1,
                    }}
                  >
                    How will I know if my affiliate account has been approved?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                You will receive an approval email informing you that your affiliate account has been approved. The email will include a link to create your password and access your account. Once you set your password, you will be able to explore all the tools and resources available to you as an affiliate.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel7" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel7bh-content"
                id="panel7bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel7" ? (
                    <div className={styles.accodian_no}>7</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel7" ? 0 : 7.1,
                    }}
                  >
                    What should I do if I have questions or need support?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If you have any questions or require assistance, you can contact our affiliate support team[give the link to contact us page] at any time. We are here to help ensure your success as an affiliate and address any concerns you may have.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel8" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel8bh-content"
                id="panel8bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel8" ? (
                    <div className={styles.accodian_no}>8</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel8" ? 0 : 7.1,
                    }}
                  >
                    How do I access my affiliate tools and resources?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                After your account is approved, you will receive an email with a link to create your password. Once your password is set, you will have full access to the affiliate tools and resources on our platform.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel9" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel9bh-content"
                id="panel9bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel9" ? (
                    <div className={styles.accodian_no}>9</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel9" ? 0 : 7.1,
                    }}
                  >
                    How can I stay updated with ArtSmiley’s news and promotions?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                You can follow us on our social media platforms and subscribe to our newsletters for the latest updates, news, and promotions related to ArtSmiley. Links to our social media pages will be provided in our emails and on our website.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel10" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel10bh-content"
                id="panel10bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel10" ? (
                    <div className={styles.accodian_no}>10</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel10" ? 0 : 7.1,
                    }}
                  >
                    What should I do if I don't receive the confirmation or approval email?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If you don't receive the email, please check your spam or junk folder. If it’s not there, contact our affiliate support team to verify your email address and ensure that there were no issues with your registration or approval process.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel11" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "25px",
                        height: "25px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel11" ? (
                    <div className={styles.accodian_no}>11</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel11" ? 0 : 7.1,
                    }}
                  >
                    How can I unsubscribe from ArtSmiley’s communications?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If you wish to unsubscribe from our communications, you can click the "Unsubscribe" link provided in our emails or manage your preferences through the provided hyperlink. This will remove you from our mailing list.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel12" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel12" ? (
                    <div className={styles.accodian_no}>12</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel12" ? 0 : 7.1,
                    }}
                  >
                    What are the different account types I can select during registration?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                You can choose between two account types: Private and Company. Select the one that best describes your affiliate status.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel13" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel13bh-content"
                id="panel13bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel13" ? (
                    <div className={styles.accodian_no}>13</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel13" ? 0 : 7.1,
                    }}
                  >
                 What information do I need to provide for a company account?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                For a company account, you will need to enter your company name along with other company details.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel14"}
              onChange={handleChange("panel14")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel14" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                // expandIcon={<AddIcon sx={{ backgroundColor: '#EFEFEF', color: '#B7B7B7', width: '30px', height: '30px', p: 0,my:0 }} />}
                aria-controls="panel14bh-content"
                id="panel14bh-header"
                className={styles.accordian_show}
              >
                {/* <div  className={styles.accordian_show}> */}
                <div className={styles.show_content}>
                  {/* <AccordionDetails> */}
                  {expanded === "panel14" ? (
                    <div className={styles.accodian_no}>14</div>
                  ) : (
                    ""
                  )}
                  {/* </AccordionDetails> */}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel14" ? 0 : 7.1,
                    }}
                  >
                    What personal details are required for registration?
                  </Typography>
                </div>
                {/* <div className={styles.expandicon}>+</div> */}
                {/* </div> */}
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                You need to provide your first name, last name, email address, username, phone number, and Whatsapp number.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel15"}
              onChange={handleChange("panel15")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel15" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel15bh-content"
                id="panel15bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel15" ? (
                    <div className={styles.accodian_no}>15</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel15" ? 0 : 7.1,
                    }}
                  >
                    How do I select my country and language?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                You can select your country and language from the dropdown menus provided in the registration form.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel16"}
              onChange={handleChange("panel16")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel16" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel16bh-content"
                id="panel16bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel16" ? (
                    <div className={styles.accodian_no}>16</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel16" ? 0 : 7.1,
                    }}
                  >
                    What should I do if I encounter issues during the registration process?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If you experience any issues or need assistance with the registration process, please contact our affiliate support team for help.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel17"}
              onChange={handleChange("panel17")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel17" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel17bh-content"
                id="panel7bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel17" ? (
                    <div className={styles.accodian_no}>17</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel17" ? 0 : 7.1,
                    }}
                  >
                    How can I update my information after registration?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                If you need to update any information after registering, please contact our support team to make the necessary changes to your account.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel18"}
              onChange={handleChange("panel18")}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel18" ? (
                    <RemoveIcon
                      sx={{
                        backgroundColor: "#1C1C1C",
                        color: "white",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  ) : (
                    <AddIcon
                      sx={{
                        backgroundColor: "#EFEFEF",
                        color: "#B7B7B7",
                        width: "30px",
                        height: "30px",
                        p: 0,
                        my: 0,
                      }}
                    />
                  )
                }
                aria-controls="panel18bh-content"
                id="panel18bh-header"
                className={styles.accordian_show}
              >
                <div className={styles.show_content}>
                  {expanded === "panel18" ? (
                    <div className={styles.accodian_no}>18</div>
                  ) : (
                    ""
                  )}
                  <Typography
                    sx={{
                      width: "33%",
                      flexShrink: 0,
                      pl: expanded === "panel18" ? 0 : 7.1,
                    }}
                  >
                    What is the purpose of providing details about my promotion strategies and audience interests?
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
                <Typography>
                By providing this information helps us understand how you plan to promote ArtSmiley services and ensures that your strategies align with our affiliate program's goals.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  my: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.subscribe_banner}>
            <div>
              <img alt="img" src={"img/email.png"} width="80px" height="63px" />
            </div>
            <h5 className={styles.subscribe_head}>
              Subscribe to the art smiley newsletter
            </h5>
            <TextField
              variant="outlined"
              value={email}
              placeholder="Please enter your mail address"
              onChange={handleChangeSubscription}
              sx={{
                width: "400px",
                background: "#3c3c3c",
                // opacity: '0.2',
                border: "1px solid #FFFFFF",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                // "::placeholder": { color: "red" },
                // '&::-webkit-input-placeholder': { color: 'blue' },
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#FFFFFF",
                input: { color: "white" },
                border: "none",
                // opacity: '0.6',
              }}
            />
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                height: "54px",
                width: "131px",
                borderRadius: "6px",
              }}
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
          </Box>
      
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default HomeAffiliate;
