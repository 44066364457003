import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    type: "",
    signed: false,
    // data: "",
    is_accepted:""
  },
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    removeUser: (state, action) => {
      state.data = action.payload;
      // console.log(action.payload,"called removeuser")
      localStorage.removeItem("login_token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("usertype")
      localStorage.removeItem("password")
      localStorage.removeItem("is_accepted")
      localStorage.removeItem("applied_profile")
      
      localStorage.removeItem("total_amount")
      localStorage.removeItem("amount")
      // window.open("http://localhost:3000/user/","_self");
      // window.location.replace("/user/");
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export const selectuser = (state) => state.user;

export default userSlice.reducer;
