import {
  Button,
  createTheme,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import moment from 'moment'
import React, { useEffect } from "react";
import styles from "./ExhibitionDetails.module.css";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import { useParams } from "react-router-dom";
import {useState} from 'react'
function ExhibitionDetails() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#FFFFFF",
      },
    },
  });
  const ExhibitionDetailsCard = [
    {
      id: 1,
      imgUrl: "/img/Rectangle prd.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 5267.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 5268.png",
    },
  ];
  const [exhibitionDetali, setExhibitionDetails] = useState({})
  const {id} = useParams();
const fetchExhibitionsById = async () => {
  try {
   const {data} = await api.applicationApi(`api/exhibition/fetchExhibitionsbyID/${id}`, "POST")
   setExhibitionDetails(data.data)
  }catch(error) {
  }
}
  useEffect(() => {
    fetchExhibitionsById()
  },[])
  return (
    <ThemeProvider theme={theme}>
      <>
        <div className={styles.exhibitiondetails}>
          <div className={styles.header}>
            <div className={styles.header__content}>
              <Typography fontFamily="marcellus" fontSize="1.5vw" color="white">
                <span className={styles.headingart}>ART EXHIBITIONS</span>
              </Typography>
              <Typography
                fontFamily="marcellus"
                fontSize="2.25vw"
                color="white"
              >
                <span className={styles.headingdiscover}>
                  {exhibitionDetali?.title}
                </span>
              </Typography>
              <Typography fontSize="0.85vw" color="white">
                <span className={styles.headingdiscover}>
                  {exhibitionDetali?.concept}
                </span>
              </Typography>
              {/* <Typography fontSize="0.85vw" color="white">
                <span className={styles.headingthis}>this April.</span>
              </Typography> */}
              <div className={styles.location__date}>
                <div className={styles.loc}>
                  <FmdGoodOutlinedIcon fontSize="small" color="primary" />
                  <Typography fontSize="0.9vw" color="white">
                    {exhibitionDetali?.city} | {exhibitionDetali?.venue_address}
                  </Typography>
                </div>
                <div className={styles.date}>
                  <DateRangeOutlinedIcon fontSize="small" color="primary" />
                  <Typography fontSize="0.9vw" color="white" ml={0.5}>
                    {moment(exhibitionDetali?.start_date).format('dddd')}-{moment(exhibitionDetali?.end_date).format('dddd')}
                  </Typography>
                </div>
              </div>
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "white",
                  padding: "1.5% 2.5%",
                  marginLeft: { xs: "31.5%", sm: "33%" },
                  marginTop: "3%",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "white",
                  },
                }}
              >
                <Typography fontSize="0.6vw">Interested</Typography>
              </Button>
            </div>
          </div>
          <div className={styles.details__content}>
            <div className={styles.top__content}>
              <Typography fontSize="1rem" fontWeight="600">
                Curator at Large: Exhibitions to see in April
              </Typography>
              <p>
                <Typography fontSize="0.9rem" color="#636365">
                  The four exhibitions I have written about this month focus on
                  the everyday. Each artist starts with the familiar,
                  transforming subjects that we think we know by showing us
                  their own version of them. In these exhibitions, we find
                  wonder in pavement debris, anxiety in stuffed animals and
                  outright terror in tables and chairs. And outside of them,
                  having been exposed to a new view on our quotidian
                  surroundings, perhaps we will pay a little more attention to
                  them.
                </Typography>
              </p>
            </div>
            <div className={styles.cardContainer}>
              <Grid container>
                {ExhibitionDetailsCard.map((item) => (
                  <Grid item xs={12} sm={12} md={12}>
                    <div className={styles.exhibition__card}>
                      <img className={styles.card__img} src={item.imgUrl} />
                      <Typography fontFamily="marcellus" fontSize="1.5rem">
                        Can't take my eyes off you | Kavitha Balasingham &
                        Shayna Fonseka, indigo+madder
                      </Typography>
                      <div className={styles.exhibitiondate}>
                        <DateRangeOutlinedIcon fontSize="small" />
                        <Typography ml={1}>24 Mar - 23 Apr</Typography>
                      </div>
                      <p>
                        <Typography fontSize="0.9rem" color="#636365">
                          The four exhibitions I have written about this month
                          focus on the everyday. Each artist starts with the
                          familiar, transforming subjects that we think we know
                          by showing us their own version of them. In these
                          exhibitions, we find wonder in pavement debris,
                          anxiety in stuffed animals and outright terror in
                          tables and chairs. And outside of them, having been
                          exposed to a new view on our quotidian surroundings,
                          perhaps we will pay a little more attention to them.
                        </Typography>
                      </p>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
            <div className={styles.discover_con_btns}>
              <Button
                sx={{
                  mt: 1,
                  textTransform: "none",
                  color: "white",
                  backgroundColor: "black",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: "black",
                  },
                  borderRadius: "8px",
                  width: "10%",
                  height: "2rem",
                }}
                className={styles.discover_con_btn}
              >
                <Typography fontSize="0.75rem">Discover New Art</Typography>
              </Button>
            </div>
          </div>
        </div>
      </>
    </ThemeProvider>
  );
}

export default ExhibitionDetails;
