import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   offset:0,
}

const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        nextPage: (state, action) => {  
            state.offset = action.payload;
        },
        prevPage: (state, action) => {
            state.offset = action.payload;
        }
    }
});

export const { nextPage,prevPage } = paginationSlice.actions;

export const selectpagination = (state) => state.pagination;

export default paginationSlice.reducer;