import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import homeImage from "../../../constant";
import styles from "./CuratorCircle.module.css";
import { Link } from "react-router-dom";
function CuratorCircle() {
  return (
    <div className={styles.curatorcircle}>
      <div className={styles.header}>
        <div>
          <Typography fontFamily="marcellus" color="white" fontSize="2vw">
          Join the Art Smiley Curator’s Community
          </Typography>
          <Typography
            ml={2}
            fontSize="1vw"
            fontFamily="marcellus"
            color="white"
            textAlign="center"
          >
            {/* <b>Home</b>/ Curator's Circle */}
            <Link to="/user/" className={styles.bold}>Home</Link>/ Curator's Circle

          </Typography>
        </div>
      </div>
      <div className={styles.member__benefits}>
        <div className={styles.benefits__grid}>
          <Grid container>
            <Grid item xs={12} sm={2} md={2}>
              <div className={styles.artist__imgDiv}>
                <div className={styles.imgcontainer}>
                  <img
                    className={styles.artistImg}
                    src={ "/img/Ellipse 251.png"}
                  />
                </div>

                <div className={styles.verticalLine}></div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <div className={styles.benefit__text}>
                <Typography fontFamily="marcellus" mt={-1} fontSize="1.75rem">
                Drive Art Sales Through Engagement and Storytelling with Art Smiley Curator’s Community
                </Typography>
                <p>
                  <Typography mt={-1} fontSize="0.9rem" color="#636365">
                  Art Smiley Curator’s Community makes a strong contribution to multifaceted art. By fostering connections with art lovers and patrons, curators can greatly improve artwork sales through the artful narration of compelling stories behind the art pieces. Furthermore, by curating exhibitions that showcase a variety of artistic expressions, curators foster a collaborative environment that nurtures personal connections between artists and buyers. Promoting these exhibitions through multiple channels enhances the relationship between the art and its audience, ultimately enriching the overall experience and deepening appreciation for the artworks.
                  </Typography>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={styles.main__content}>
        <div className={styles.curatorcards}>
          <Grid container spacing={8.5}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.leftcard}>
                <div className={styles.image__flex}>
                  <img className={styles.leftImage} src={ "/img/curatorleftimg1.png"} />
                  <div>
                    <img className={styles.rightImages} src={ "/img/curatorrightimg1.png"} /><br />
                    <img className={styles.rightImages} src={ "/img/curatorrightimg2.png"} />
                  </div>
                </div>

                <div className={styles.cardInfo__right}>
                  <Typography fontFamily="marcellus" fontSize="2vw">
                  How to Join the Art Smiley Curator’s Community?
                  </Typography>
                  <div className={styles.info__div}>
                    <ul>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Submit Your Application Begin your journey by providing <br />
                       the necessary details for registration. This is the first <br />
                       step towards becoming a valued member of the ArtSmiley Curator's Community. <span><Button
          variant="contained"
          sx={{ width: "fit-content", height: "25px" }}
         
        >
          Apply
        </Button></span>
                        </Typography>
                      </li>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Review and Approval Once your information is submitted, <br />  
                        our team will review your application within 3-4 weeks. <br /> 
                        During this period, we’ll assess your profile to ensure it <br />
                         aligns with our curatorial standards and goals. Upon approval, <br />
                          you will receive a confirmation email to create a password for
                           your account and login to your dashboard.

                        </Typography>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.rightcard}>
                <div className={styles.cardInfo__left}>
                  <Typography fontFamily="marcellus" fontSize="2vw">
                  Curator Privileges
                  </Typography>
                  <div className={styles.info__div}>
                  <ul>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Collaborate with a Global Community: Connect with artists, collectors, <br />
          and curators from around the world. Exchange ideas, inspire others, and <br />
           help shape the narrative of contemporary art.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Access to Diverse Artworks: Explore a wide range of artworks, <br />
          from traditional to contemporary, and curate collections that <br />
           reflect your unique perspective.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Curate Exhibitions to Sell Artworks: As a curator, <br />
           you have the opportunity to organize physical and <br />
            virtual exhibitions specifically designed to showcase and sell artworks.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Earn Commissions: By curating and promoting exceptional artworks,<br />
           you will earn commissions, adding value to both your career and <br /> the artists you support.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Support and Guidance: Our dedicated team is here to assist <br />
           you every step of the way. Whether you need help navigating <br />
            the platform or advice on curating, we’re just a message away.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Track Your Commissions: Use our platform to easily monitor <br />
           and track your commissions, providing you with clear insights <br />
            into your earnings and helping you manage your curatorial activities effectively.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Select Featured Artists: As a curator, you play a pivotal role <br />
           in highlighting talent. You have the authority to select and <br />
            showcase featured artists, drawing attention to their work <br />
            and giving them a platform to reach wider audiences.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Specially Curated Collections: Manage the curated artworks that <br />
           reflect your unique artistic vision. All curated collections are <br />
            featured on a dedicated page, allowing you to showcase your selections <br />
             in a professional, organized manner, making it easier for collectors to explore and purchase the artworks.
        </Typography>
      </li>
    </ul>
                  </div>
                </div>
                <div className={styles.image__flex}>
                  <img className={styles.leftImage} src={ "/img/curatorleftimg2.png"} />
                  <div>
                    <img className={styles.rightImages} src={ "/img/curatorrightimg3.png"} /><br />
                    <img className={styles.rightImages} src={ "/img/curatorrightimg4.png"} />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid mt={2} container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.leftcard}>
                <div className={styles.image__flex}>
                  <img className={styles.leftImage} src={ "/img/curatorleftimg3.png"} />
                  <div>
                    <img className={styles.rightImages} src={ "/img/curatorrightimg5.png"} /><br />
                    <img className={styles.rightImages} src={ "/img/curatorrightimg6.png"} />
                  </div>
                </div>
                <div className={styles.cardInfo__right}>
                  <Typography fontFamily="marcellus" fontSize="2vw">
                  Members-Only Insights
                  </Typography>
                  <div className={styles.info__div}>
                    <ul>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Exclusive Artworks: Discover a curated selection of exceptional and original pieces that represent the cutting edge of contemporary art.
                        </Typography>
                      </li>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Curator Insights: Gain deeper understanding with detailed commentary and stories from our curators about each artwork.
                        </Typography>
                      </li>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Early Previews: Get first access to new arrivals and special releases, keeping you ahead of the latest art trends
                        </Typography>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
              <div className={styles.rightcard}>
                <div className={styles.cardInfo__left}>
                  <Typography fontFamily="marcellus" fontSize="2vw">
                  Dashboard Features and Benefits
                  </Typography>
                  <div className={styles.info__div}>
                  <ul>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Featured Artists: Track the number of artists in your collections.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Arts: Monitor the total amount of art represented.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Curated Collections: Count your specially curated artwork collections.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Earnings: Check your earnings to track financial progress.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Number of Artists: Know how many artists you’re engaging currently.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Weekly Earnings: Stay updated on weekly earnings.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Monthly Earnings: Track your monthly earnings.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Yearly Earnings: View your cumulative earnings for the year.
        </Typography>
      </li>
      <li>
        <Typography fontSize="0.85rem" color="#636365">
          Total Curated Exhibitions: Review the total number of the curated exhibitions by you.
        </Typography>
      </li>
    </ul>
                  </div>
                </div>
                <div className={styles.image__flex}>
                  <img className={styles.leftImage} src={ "/img/curatorleftimg4.png"} />
                  <div>
                    <img className={styles.rightImages} src={ "/img/curatorrightimg7.png"} /><br />
                    <img className={styles.rightImages} src={ "/img/curatorrightimg8.png"} />
                  </div>
                </div>
              </div>
              <div className={styles.leftcard}>
                <div className={styles.image__flex}>
                  <img className={styles.leftImage} src={ "/img/curatorleftimg3.png"} />
                  <div>
                    <img className={styles.rightImages} src={ "/img/curatorrightimg5.png"} /><br />
                    <img className={styles.rightImages} src={ "/img/curatorrightimg6.png"} />
                  </div>
                </div>
                <div className={styles.cardInfo__right}>
                  <Typography fontFamily="marcellus" fontSize="2vw">
                  Remarkable Events
                  </Typography>
                  <div className={styles.info__div}>
                    <ul>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Private Viewings: Enjoy exclusive events where you can see artworks up close and interact with artists and curators.
                        </Typography>
                      </li>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Curator-led Tours: Join guided tours to explore the collection and appreciate the diverse styles and techniques of featured pieces.
                        </Typography>
                      </li>
                      <li>
                        <Typography fontSize="0.85rem" color="#636365">
                        Special Collaborations: Participate in unique events that foster connections between artists, collectors, and art enthusiasts.
                        </Typography>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default CuratorCircle;
