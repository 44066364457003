import React, { useEffect } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import Home from "./common/home/Home"
import Login from "./components/Login/Login"
import Signup from "./components/Signup/Signup"
import Dashboard from "./components/Pages/Dashboard/Dashboard"
import Activity from "./components/Pages/List/Activity/Activity"
import Admindispute from "./components/Pages/List/AdminDispute/Admindispute"
import Deputedetail from "./components/Pages/List/AdminDispute/deputeDetails/Deputedetail"
import Affiliate from "./components/Pages/List/Affiliate/Affiliate"
import Account from "./components/Pages/List/AllAccount/Account"
import Artist from "./components/Pages/List/Artist/Artist"
import Artdetails from "./components/Pages/List/Artpage/Artdetails/Artdetails"
import Artpage from "./components/Pages/List/Artpage/Artpage"
import Auction from "./components/Pages/List/Auction/Auction"
import Blogs from "./components/Pages/List/Blogs/Blogs"
import Buyerpage from "./components/Pages/List/Buyerpage/Buyerpage"
import Campaigns from "./components/Pages/List/Campaigns/Campaigns"
import Career from "./components/Pages/List/Career/Career"
import Careerdetail from "./components/Pages/List/Career/Careerdetail"
import PrintPricing from "./components/Pages/List/PrintPricing/PrintPricing"
import Category from "./components/Pages/List/Category/Category"
import Curator from "./components/Pages/List/Curator/Curator"
import Enquiries from "./components/Pages/List/Enquiries/Enquiries"
import Exhibition from "./components/Pages/List/Exhibition/Exhibition"
import Faq from "./components/Pages/List/FAQ/Faq"
import Gallery from "./components/Pages/List/Gallery/Gallery"
import Giftcard from "./components/Pages/List/GiftCard/Giftcard"
import Hero from "./components/Pages/List/HeroSection/Hero"
import Menu from "./components/Pages/List/Menu/Menu"
import Notification from "./components/Pages/List/Notification/Notification"
import Offer from "./components/Pages/List/Offer/Offer"
import Orders from "./components/Pages/List/Orders/Orders"
import Policy from "./components/Pages/List/Policies/Policy"
import Press from "./components/Pages/List/PressRelease/Press"
import Ratingreview from "./components/Pages/List/RatingReview/Ratingreview"
import Reports from "./components/Pages/List/Reports/Reports"
import Setting from "./components/Pages/List/Setting/Setting"
import Subscription from "./components/Pages/List/Subscription/Subscription"
import SubscriptionArtist from "./components/Pages/List/Subscription/SubscriptionArtistList"
import Subadmin from "./components/Pages/List/SunAdmin/Subadmin"
import Termscondition from "./components/Pages/List/TermsConditions/Termscondition"
import Testimonial from "./components/Pages/List/Testimonials/Testimonial"
import Categorylist from "./components/Pages/List/Category/categoryList/Categorylist"
import Artisttype from "./components/Pages/List/Artist/artistType/Artisttype"
import { useSelector } from "react-redux"
import Subscriptionfeature from "./components/Pages/List/Subscription/subscriptionFeature/Subscriptionfeature"
import UserMain from "./UserMain"
import Sidebar from "./components/Pages/Sidebar/Sidebar"
import Artistprofile from "./components/Pages/List/Artist/Artistprofile/Artistprofile"
import Affiliatedetail from "./components/Pages/List/Affiliate/Affiliatedetail/Affiliatedetail"
import Orderdetail from "./components/Pages/List/Orders/Orderdetail/Orderdetail"
import Curatordetails from "./components/Pages/List/Curator/Curatordetails/Curatordetails"
import Addpress from "./components/Pages/List/PressRelease/addNew/Addpress"
import Addblog from "./components/Pages/List/Blogs/addBlog/Addblog"
import Faqlist from "./components/Pages/List/FAQ/faqList/Faqlist"
import Acceptreject from "./components/Pages/List/Exhibition/Accepreject/Acceptreject"
import AuctionDetail from "./components/Pages/List/Auction/auctionDetail/AuctionDetail"
import CategoryDetail from "./components/Pages/List/Category/detail/CategoryDetail"
import MenuDetail from "./components/Pages/List/Menu/menudetails/MenuDetail"
import FrameCountry from "./components/Pages/List/frameCountry/FrameCountry"
import Print from "./components/Pages/List/Print/Print"
import PrintDetailpage from "./components/Pages/List/Print/detail/PrintDetailpage"
import ContactUs from "./components/Pages/List/Contact Us/ContactUs"
import Recreate from "./components/Pages/List/Recreate/Recreate"
import HeadingMenu from "./components/Pages/List/Menu/HeadingMenu"
import BuyerArtist from "./artist/pages/buyer/Buyerartist";
import Artistquoterequest from "./artist/pages/quoteRequest/Artistquoterequest"
import Recreateartist from './artist/pages/recreate/Recreateartist'
export default function Superadminmain() {
  const user = useSelector((state) => state.user.data)
  const a = window.location.pathname.includes("*")
  return (
    // <div className='supermian'>
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/art" element={<Artpage />} />
      <Route path="/buyerlist" element={<Buyerpage />} />
      <Route path="/artist" element={<Artist />} />
      {/* <Route path='/sidebar' element={<Sidebar />} /> */}
      {/* <Route path='/' element={<Home />} /> */}
      <Route path="/curators" element={<Curator />} />
      <Route path="/affiliate" element={<Affiliate />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/exhibitions" element={<Exhibition />} />
      <Route path="/auction" element={<Auction />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/career" element={<Career />} />
      <Route path="/career-detail/:id" element={<Careerdetail />} />
      <Route path="/print-pricing" element={<PrintPricing />} />
      <Route path="/inquiry" element={<Enquiries />} />
      <Route path="/pressrelease" element={<Press />} />
      <Route path="/blog" element={<Blogs />} />
      <Route path="/ratingreview" element={<Ratingreview />} />
      <Route path="/subadmin" element={<Subadmin />} />
      <Route path="/offers" element={<Offer />} />
      <Route path="/buyeroffer" element={<BuyerArtist />} />
      <Route path="/quoterequest" element={<Artistquoterequest />} />
      <Route path="/recreate" element={<Recreateartist />} />
      <Route path="/giftcard" element={<Giftcard />} />
      <Route path="/herosection" element={<Hero />} />
      <Route path="/testimonials" element={<Testimonial />} />
      <Route path="/termscondition" element={<Termscondition />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/allaccount" element={<Account />} />
      <Route path="/gallerys" element={<Gallery />} />
      <Route path="/dispute" element={<Admindispute />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/settings" element={<Setting />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/activity" element={<Activity />} />
      <Route path="/policies" element={<Policy />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route
        path="/subscription/artistlist/:id"
        element={<SubscriptionArtist />}
      />
      <Route path="/category" element={<Category />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/print" element={<Print />} />
      <Route path="/print/:id" element={<PrintDetailpage />} />
      {/* <Route path="/categorylist" element={<Categorylist />} /> */}
      <Route path="/artistype" element={<Artisttype />} />
      <Route path="/subscribefeature" element={<Subscriptionfeature />} />
      <Route path="/framecountry" element={<FrameCountry />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/recreate" element={<Recreate />} />
      {/* <Route path='/accept' element={<Acceptreject />} />
            <Route path='/privacyde' element={<Privacydetail />} />
            <Route path='/addpolicy' element={<Addpolicy />} />
          */}
      <Route path="/dispute/:id" element={<Deputedetail />} />
      <Route path="/blog/:id" element={<Addblog />} />
      <Route path="/faq/:id" element={<Faqlist />} />
      <Route path="/pressrelease/:id" element={<Addpress />} />
      <Route path="/curators/:id" element={<Curatordetails />} />
      <Route path="/orders/:id" element={<Orderdetail />} />
      <Route path="/exhibitions/:id" element={<Acceptreject />} />
      <Route path="/category/:id" element={<CategoryDetail />} />
      <Route path="/menu/:id" element={<MenuDetail />} />
      <Route path="/menu/heading/:id" element={<HeadingMenu />} />
      <Route path="/auction/:id" element={<AuctionDetail />} />
      <Route path="/art/:id" element={<Artdetails />} />
      <Route path="/affiliate/:id" element={<Affiliatedetail />} />
      <Route
        className="artisprofile"
        path="/artist/:id"
        element={<Artistprofile />}
      />
      {/* <Route path="*" element={<Navigate to={url} />} /> */}
      <Route path="*" element={<UserMain />} />
      {/* <Route
        path="*"
        element={
          <Navigate
            to={
              user.signed && localStorage.getItem("login_token")
                ? localStorage.getItem("artsmiley-backtrack-url")
                  ? localStorage.getItem("artsmiley-backtrack-url")
                  : "/dashboard"
                : "/"
            }
          />
        }
      /> */}
      {/* <UserMain /> */}
    </Routes>
    // </div>
  )
}
