import { SearchOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import homeImage from "../../../constant";
import styles from "./SearchResult.module.css";

function SearchResult() {
  const [artist, setArtist] = React.useState("");

  const handleChange = (event) => {
    setArtist(event.target.value);
  };
  const artistList = [
    {
      id: 1,
      imgUrl: "/img/Ellipse 251.png",
      artistName: "Aflie Cooper",
    },
    {
      id: 2,
      imgUrl: "/img/searchartist1.png",
      artistName: "Arcan Zac",
    },
    {
      id: 3,
      imgUrl: "/img/searchartist2.png",
      artistName: "Abrial Sam",
    },
  ];
  const eventList = [
    {
      id: 1,
      imgUrl: "/img/event1.png",
      text: "Abstract Art",
    },
    {
      id: 2,
      imgUrl: "/img/event2.png",
      text: "Street Art",
    },
    {
      id: 3,
      imgUrl: "/img/event3.png",
      text: "African Art",
    },
    {
      id: 4,
      imgUrl: "/img/event3.png",
      text: "Fine Art",
    },
  ];
  const artworksCard = [
    {
      id: 1,
      imgUrl: "/img/search1.png",
    },
    {
      id: 2,
      imgUrl: "/img/search2.png",
    },
    {
      id: 3,
      imgUrl: "/img/search3.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5208.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle 5201.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle 5202.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle 5203.png",
    },
    {
      id: 8,
      imgUrl: "/img/search2.png",
    },
  ];
  return (
    <div className={styles.searchresult}>
      <div className={styles.searchbar}>
        <TextField
          id="input-with-icon-textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="art"
          sx={{
            width: "40%",
          }}
          size="small"
        />
        <Box sx={{ minWidth: 100 }}>
          <FormControl fullWidth>
            <TextField
              select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={artist}
              label="Artist"
              onChange={handleChange}
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  marginTop: "-1%",
                },
              }}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </TextField>
          </FormControl>
        </Box>
        <Button>
          <Typography color="#1C1C1C" fontSize="0.9rem">
            X Close
          </Typography>
        </Button>
      </div>
      <div className={styles.main__content}>
        <div className={styles.artists}>
          <Typography fontFamily="marcellus" fontSize="1.65rem">
            Artists
          </Typography>
          <Grid container>
            {artistList.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className={styles.artistinfo}>
                  <div className={styles.artistimgDiv}>
                    <img className={styles.artistimage} src={  item.imgUrl} />
                  </div>
                  <div>
                    <Typography
                      ml={1}
                      fontSize="1.45rem"
                      fontFamily="marcellus"
                    >
                      {item.artistName}
                    </Typography>
                    <Typography ml={1} color="#636365" fontSize="0.9rem">
                      Denmark - Painter
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <hr className={styles.divider} />
        </div>
        <div className={styles.Events}>
          <Typography fontFamily="marcellus" fontSize="1.65rem">
            Events
          </Typography>
          <Grid container>
            {eventList.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className={styles.artistinfo}>
                  <div className={styles.artistimgDiv}>
                    <img className={styles.artistimage} src={  item.imgUrl} />
                  </div>
                  <div>
                    <Typography
                      ml={1}
                      fontSize="1.45rem"
                      fontFamily="marcellus"
                    >
                      {item.text}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <hr className={styles.divider} />
        </div>
        <div className={styles.artworks}>
          <Typography fontFamily="marcellus" fontSize="1.65rem">
            Artworks
          </Typography>
          <Grid container>
            {artworksCard.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className={styles.artworkcard}>
                  <img className={styles.artworkimg} src={ item.imgUrl} />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default SearchResult;
