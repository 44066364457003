import React from 'react';
import styles from './FrameCountry.module.css'
import country from '../../../../Country';
import { Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useEffect } from 'react';
import { fetchFrameCountry, handleAddFrameCountry } from '../../../../State/api/superadminApi';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertsSuccess } from '../../../../common/alert/Alert';
import CircularLoader from '../../../../common/loader/Loader';
import ClearIcon from '@mui/icons-material/Clear';

const FrameCountry = () => {
    const dispatch = useDispatch();

    const loader = useSelector((state) => state.loader);
    const [frameCountry, setFrameCountry] = useState([])

    const handleSelectFrameCountry = (e) => {
        const vl = e.target.value;
        setFrameCountry(vl);
    };
    const handleRemoveCountry = (value) => {
        var frmcnty = [...frameCountry];
        const indexs = frameCountry.findIndex((val) => val === value);
        frmcnty.splice(indexs, 1);
        setFrameCountry(frmcnty);
    }
    // deep enterpirses redition hotel shubhssh chock sohna road
    const handleUpdateFrame = () => {
        dispatch(handleAddFrameCountry({ countries: frameCountry }))
    }
    useEffect(() => {
        dispatch(fetchFrameCountry(setFrameCountry))
    }, []);
    return (
        <div className={styles.mains}>
            <AlertsSuccess />
            <div className={styles.card_div}>
                <Typography className={styles.card_title}>Change Frame Country</Typography>
                <Select
                    labelId="select-mutli-artist"
                    id="demo-select-artist"
                    value={frameCountry === 0 ? [] : frameCountry.map((val) => val)}
                    fullWidth
                    multiple
                    sx={{
                        my: 2,
                        background: "#fff",
                        ".MuiOutlinedInput-notchedOutline": { border: "1px solid black" },
                        "& .MuiInputBase-root": {
                            borderRadius: "6px",
                        },
                        height: "40px",
                    }}
                    placeholder="select type"
                    onChange={handleSelectFrameCountry}
                    name="country"
                // input={<OutlinedInput label="Aritst Name" />}
                // MenuProps={MenuProps}
                >
                    {country.map((val) => (
                        <MenuItem
                            value={val.label} key={val.label}>
                            {val.label}
                        </MenuItem>
                    ))}
                </Select>
                <div className={styles.country_label_div}>
                    {loader.loader ?
                        <div className={styles.loader}>
                            <CircularLoader />
                        </div>
                        : frameCountry.map((val) => (
                            <>
                                <Typography variant='outlined' className={styles.labels}>{val}
                                    <IconButton onClick={() => handleRemoveCountry(val)} sx={{ p: 0, ml: 1 }}><ClearIcon /></IconButton></Typography>
                            </>
                        ))}
                </div>
                <div className={styles.save_btn}>
                    <Button
                        className={styles.btn}
                        sx={{ borderRadius: "10px", mt: 3, height: "54px", fontFamily: "DM Sans" }}
                        variant="contained"
                        fullwidth
                        onClick={handleUpdateFrame}>
                        Save Frame Country
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FrameCountry;
