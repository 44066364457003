import * as React from "react"
import styles from "./Navbar.module.css"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Header from "../Header/Header"
import { Badge, Menu, MenuItem, TextField, Tooltip } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined"
import { useDispatch, useSelector } from "react-redux"
import { setActiveStatus } from "../../../State/Features/user/activetabSlice"
import { removeUser } from "../../../State/Features/common/userSlice"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useEffect } from "react"
import api from "../../../State/api/Api"
import { useState } from "react"
import {
  fetchArtistTypes,
  fetchCategoryData,
  fetchFetchAllcart,
  fetchProductType,
  fetchUserArtist,
} from "../../../State/api/userApi"
import CircularLoader from "../../../common/loader/Loader"
import {
  setArtistFilterNav,
  setNoFilter,
  setStyleFilterNav,
} from "../../../State/Features/user/filterationSlice"
import { style } from "@mui/system"
import { AccountCircle } from "@mui/icons-material"
import axios from "axios"
import { Errornotify, Successnotify } from "../../../common/alert/Alert"
import {
  handleFetchStyle,
  getAllMenuSubMenu,
} from "../../../State/api/superadminApi"
import Webpopup from "../../../gallery/components/popups/galleryWeb/Webpopup"
import CloseIcon from "@mui/icons-material/Close"
import serverimage from "../../../serverConstant"
import capitalizeWords from "../../../utils/capitalizeContent"
const drawerWidth = 240

function Navbar(props) {
  const { windows } = props
  const location = useLocation()

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [submenu, setSubmenu] = React.useState({})
  const [rsubmenu, setRsubmenu] = React.useState({
    cart: false,
  })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElSearch, setAnchorElSearch] = React.useState(null)
  const [name, setName] = React.useState("")
  const [condition, setCondition] = useState({
    country: null,
    orientation: null,
    color: null,
  })
  const handleOnclickRightMaintab = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank")
    } else {
    }
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const auctionstatus = useSelector((state) => state.activetab)
  const usercart = useSelector((state) => state.mycartData.data)
  const usertype = useSelector((state) => state.user.data)
  const loader = useSelector((state) => state.loader)
  const productType = useSelector((state) => state.productType)
  const artistTypes = useSelector((state) => state.artistTypedata)

  const getAllMenuSubMenudata = useSelector(
    (state) => state.getAllMenuSubMenudata.data
  )
  const userartists = useSelector((state) => state.userartistData)
  const allcategory = useSelector((state) => state.allcategoryData)
  const filteration = useSelector((state) => state.filteration)
  const activetab = useSelector((state) => state.activetab)
  const user = useSelector((state) => state.user.data)
  const open = Boolean(anchorEl)
  const openSearch = Boolean(anchorElSearch)
  const [signPop, setSignPop] = useState(true)

  const [subject, setSubject] = useState([])
  const [medium, setMedium] = useState([])
  const [style, setStyle] = useState({ style: "" })

  const fetchSubjects = async (id) => {
    dispatch(setLoader(true))
    try {
      const response = await api.applicationApi(
        "api/itemSubject/fetchItemSubjects",
        "POST",
        { category_id: id }
      )
      if (response.status === 200) {
        setSubject(response.data.data)
        dispatch(setLoader(false))
      }
    } catch (error) {
    }
  }
  const fetchMedium = async (id) => {
    dispatch(setLoader(true))
    try {
      const response = await api.applicationApi(
        "api/itemMedium/fetchItemMediums",
        "POST",
        { category_id: id }
      )
      if (response.status === 200) {
        setMedium(response.data.data)
        dispatch(setLoader(false))
      }
    } catch (error) {
    }
  }
  useEffect(() => {
    if (filteration.style.length === 0) {
      dispatch(setActiveStatus({ status: "style_all", maintab: "" }))
    }
  }, [filteration])
  const handleFetchCondition = async function (id) {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcountry`)
      const orientationResult = await api.applicationApi(
        `api/user/getorientation`
      )
      const colorResult = await api.applicationApi(`api/user/getcolors`)
      // const styleResult = await api.applicationApi(
      //   `api/itemStyle/fetchItemStyles`,
      //   "POST",
      //   { category_id: id }
      // );
      if (result && result.status === 200) {
        setCondition({
          ...condition,
          country: result.data.response,
          orientation: orientationResult.data.response,
          color: colorResult.data.response,
        })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const fetchStyle = (id) => {
    dispatch(handleFetchStyle({ category_id: id }, setStyle))
  }

  // heading menu--
  const fetchHeadingMenuData = () => {
    dispatch(getAllMenuSubMenu())
  }
  React.useEffect(() => {
    fetchHeadingMenuData()
  }, [])

  const handleFetchOrientation = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getorientation`)
      if (result && result.status === 200) {
        setCondition({ ...condition, orientation: result.data.response })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const handleFetchColor = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcolors`)
      if (result && result.status === 200) {
        setCondition({ ...condition, color: result.data.response })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  useEffect(() => {
    handleFetchCondition()
    dispatch(fetchProductType())
    dispatch(fetchArtistTypes())
    dispatch(fetchUserArtist({}))
    dispatch(fetchCategoryData({}))
  }, [])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleHoverOnProfile = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleOnclickProfile = (val) => {
    setAnchorEl(null)
    if (val === "apply") {
      navigate("/artsmiley/applynow")
    } else {
      navigate("/dashboard")
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCloseSearch = () => {
    setAnchorElSearch(null)
  }

  useEffect(() => {
    if (getAllMenuSubMenudata) {
      const initialSubmenuState = getAllMenuSubMenudata.reduce((acc, item) => {
        acc[item.menu?._id] = false
        return acc
      }, {})
      setSubmenu(initialSubmenuState)
    }
  }, [getAllMenuSubMenudata])

  const handleLeftHover = (e) => {
    const id = e.target.getAttribute("data-id")
    const nav_cat = allcategory.data?.allcategories.filter(
      (val) => val._id === id
    )

    if (nav_cat?.length > 0) {
      fetchSubjects(nav_cat[0]._id)
      fetchMedium(nav_cat[0]._id)
      fetchStyle(nav_cat[0]._id)
    } else {
      setSubject([])
      setMedium([])
      setStyle({ style: "" })
    }

    const updatedSubmenu = { ...submenu }

    if (updatedSubmenu.hasOwnProperty(id)) {
      // Reset all submenu states to false
      Object.keys(updatedSubmenu).forEach(
        (key) => (updatedSubmenu[key] = false)
      )
      // Set the hovered menu item to true
      updatedSubmenu[id] = true
    }

    setSubmenu(updatedSubmenu)
  }

  const handleRightHover = (e) => {
    const name = e.target.getAttribute("name")

    if (name === "cart") {
      setRsubmenu((prevState) => ({
        ...prevState,
        cart: true,
      }))
    }
  }
  const handleClickCart = () => {
    setRsubmenu({
      sculpture: false,
      artist: false,
      artbiz: false,
      nft: false,
      explore: false,
      cart: true,
    })
  }
  const handleNavigate = (route, status, filter_by) => {
    navigate(route)
    setSubmenu({
      live_event: false,
      paintings: false,
      prints: false,
      drawings: false,
      photgraphy: false,
    })
    if (filter_by === "style") {
      dispatch(setStyleFilterNav(status))
      // }else{
      //   dispatch(setNoFilter())
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  // const handleNavigateAuctionExhibition = (route, status, maintab) => {
  //   navigate(route)
  //   setSubmenu({
  //     live_event: false,
  //     paintings: false,
  //     prints: false,
  //     drawings: false,
  //     photgraphy: false,
  //   })
  //   dispatch(setActiveStatus({ status: status, maintab: maintab }))
  //   window.scrollTo({ top: 0, behavior: "smooth" })
  // }

  const handleNavigateAuctionExhibition = (route, status, maintab) => {
    navigate(route)

    // Dynamically set all submenu IDs to false
    if (getAllMenuSubMenudata) {
      const updatedSubmenuState = getAllMenuSubMenudata.reduce((acc, item) => {
        acc[item.menu?._id] = false
        return acc
      }, {})
      setSubmenu(updatedSubmenuState)
    }

    dispatch(setActiveStatus({ status: status, maintab: maintab }))
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleNavigateRight = (route, status, maintab, filtertype) => {
    navigate(route)
    window.scrollTo({ top: 0, behavior: "smooth" })
    setRsubmenu({
      sculpture: false,
      artist: false,
      artbiz: false,
      nft: false,
      explore: false,
      cart: false,
    })
    if (filtertype === "sculpture") {
      dispatch(setStyleFilterNav(status))
    } else if (filtertype === "country") {
      dispatch(setArtistFilterNav(status))
      dispatch(setActiveStatus({ status: status, maintab: maintab }))
    } else {
    }
    dispatch(setActiveStatus({ status: status, maintab: maintab }))
  }
  const handleAll = (route, status, maintab) => {
    navigate(route)
    setSubmenu({
      live_event: false,
      paintings: false,
      prints: false,
      drawings: false,
      photgraphy: false,
    })
    setRsubmenu({
      sculpture: false,
      artist: false,
      artbiz: false,
      nft: false,
      explore: false,
      cart: false,
    })
    status && dispatch(setActiveStatus({ status: status, maintab: maintab }))
    dispatch(setNoFilter({ status: status, maintab: maintab }))
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const handleMobileNavBack = () => {
    setSubmenu({
      live_event: false,
      paintings: false,
      prints: false,
      drawings: false,
      photgraphy: false,
    })
  }
  const handleSignoutbtn = () => {
    localStorage.removeItem("login_token")
    localStorage.removeItem("artsmiley-backtrack-url")
    localStorage.removeItem("usertype")
    localStorage.removeItem("user_id")
    localStorage.removeItem("user_type_id")
    localStorage.removeItem("password")
    dispatch(
      removeUser(
        {
          signed: false,
          type: "",
          data: "",
        },
        navigate("/")
      )
    )
    window.location.reload()
  }
  const [data, setData] = useState(1);
  const userty = useSelector((state) => state.user.data);
  const container =
    windows !== undefined ? () => windows().document.body : undefined

  const filteredSubmenus = getAllMenuSubMenudata
    ?.filter((menuItem) => submenu[menuItem?.menu?._id])
    .map((menuItem) => menuItem)

  //
  const handleNavigateCategory = (id) => {
    const searchParams = new URLSearchParams()
    searchParams.set("category", id.toLowerCase())

    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : ""

    navigate(`/user/search${newSearch}`, { replace: true })
  }

  const handleRemoveItemcart = async function (ids) {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/user/removeFromCart/${ids}`,
        "DELETE"
      );
      if (result.status === 200 || result.status === 201) {
        dispatch(setLoader(false));
        userty.signed === true &&
          dispatch(
            fetchFetchAllcart({
              offset: data,
              user_id: localStorage.getItem("user_id"),
            })
          );
        dispatch(
          fetchFetchAllcart({
            offset: 1,
            user_id: localStorage.getItem("user_id"),
          })
        );
        Successnotify(result.data.message);
      } else if (result.status === 400) {
        Errornotify(result.data.message);
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  //

  return (
    <>
      {/* <Header /> */}
      {signPop ? <Webpopup /> : ""}
      <Box
        className={styles.nav_main}
        style={{
          padding: "0px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <AppBar
          className={styles.nav_mains}
          style={{
            padding: "0px",
            align: "center",
            backgroundColor: "white",
            color: "#636365",
            boxShadow: "none",
            marginLeft: "100px",
          }}
          component="nav"
        >
          <Toolbar style={{ padding: "0px", align: "center" }}>
            <IconButton
              color="inherit"
              disableRipple
              aria-label="open drawer"
              // edge="start"
              sx={{
                mr: 2,
                display: { sm: "none" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className={styles.navLeft}>
                <MenuIcon onClick={handleDrawerToggle} />
                <Typography
                  sx={{ fontWeight: "600", ml: 1 }}
                  onClick={() => navigate("/")}
                >
                  ARTSMILEY
                </Typography>
              </div>
              <div className={styles.navRight}>
                <div className={styles.profilebtn}>
                  {user.signed && localStorage.getItem("login_token") ? (
                    <>
                      <AccountCircle
                        fontSize="medium"
                        sx={{ color: "black" }}
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleHoverOnProfile}
                      />

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{ left: "-35px", top: "5px" }}
                      >
                        {localStorage.getItem("usertype") === "buyer" ? (
                          <MenuItem
                            onClick={() => handleOnclickProfile("profile")}
                          >
                            Profile
                          </MenuItem>
                        ) : localStorage.getItem("is_accepted") ===
                          "Pending" ? (
                          <MenuItem
                            onClick={() => handleOnclickProfile("apply")}
                          >
                            Apply
                          </MenuItem>
                        ) : (
                          <MenuItem
                            onClick={() => handleOnclickProfile("profile")}
                          >
                            Profile
                          </MenuItem>
                        )}
                        <MenuItem onClick={handleSignoutbtn}>Logout</MenuItem>
                      </Menu>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <Button sx={{ minWidth: 0 }}>
                  <img
                    alt="img"
                    src={"/img/nav_Search.png"}
                    height="20px"
                    width="20px"
                    style={{ margin: "0px 10px" }}
                    onClick={() => navigate("/user/search")}
                  />
                </Button>
                <Button
                  name="cart"
                  onClick={() => navigate("/user/mycart")}
                  // onMouseEnter={handleRightHover}
                  sx={{ minWidth: 0 }}
                >
                  <Badge
                    name="cart"
                    // onMouseEnter={handleRightHover}
                    badgeContent={usercart && usercart.result.length}
                    color="primary"
                    sx={{ ml: "-2" }}
                  >
                    <LocalMallOutlinedIcon
                      // onMouseEnter={handleRightHover}
                      name="cart"
                    />
                    {/* <img
                  src={ "/img/Cart.png"}
                  height="20px"
                  width="20px"
                  style={{ margin: "0px 10px" }}
                /> */}
                  </Badge>
                </Button>
              </div>
            </IconButton>
            <Box
              sx={{
                // display: { xs: "none", sm: "block" },
                textAlign: "center",
                fontFamily: "DM Sans",
              }}
              className={styles.button_box}
            >
              <div className={styles.nav_btn_box} style={{width:'70%'}}>
                {getAllMenuSubMenudata?.slice(0, 5).map((item) => (
                  <Button
                    key={item.menu.name}
                    className={styles.nav_btn}
                    sx={{
                      my: 0.7,
                      mx: 0.4,
                      fontFamily: "DM Sans",
                      fontSize: "17px",
                      lineHeight: "23px",
                      color:
                        item.value === activetab.maintab ? "white" : "#636365",
                      backgroundColor:
                        item.value === activetab.maintab ? "#636365" : "",
                    }}
                    onMouseEnter={handleLeftHover}
                    data-id={item?.menu?._id}
                    onClick={() => handleNavigateCategory(item?.menu?.name)}
                  >
                    {capitalizeWords(item?.menu?.name)}
                  </Button>
                ))}

                {getAllMenuSubMenudata
                  ? filteredSubmenus?.map((menuItem, i) => (
                      <div key={i} className={styles.submenu}>
                        <div>
                          <img
                            alt="img"
                            src={ menuItem?.menu?.image}
                            width="200px"
                            height="200px"
                          />
                        </div>
                        <div className={styles.vertical_line}></div>
                        <>
                          {menuItem?.headings?.map((head, i) => (
                            <div key={i}>
                              <h2 className={styles.submenu_header}>
                                {head?.name}
                              </h2>
                              {head?.submenus?.map((subm, j) => (
                                <div key={j}>
                                  <h5
                                    id="/exhibition"
                                    name="upcoming"
                                    onClick={() =>
                                      handleNavigateAuctionExhibition(
                                        `${subm.link}`,
                                        "upcoming",
                                        "exhibition"
                                      )
                                    }
                                    className={
                                      location.pathname.includes(
                                        "exhibition"
                                      ) && auctionstatus.status === "upcoming"
                                        ? styles.submenu_links
                                        : styles.submenu_links
                                    }
                                  >
                                    {subm.name}
                                  </h5>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      </div>
                    ))
                  : ""}
              </div>

              <div
                onClick={() => navigate("/")}
                className={styles.logo_wrapper}
              >
                <img
                  alt="img"
                  src={"/img/curve.svg"}
                  className={styles.svg_icon}
                />
                <img
                  alt="img"
                  src={"/img/curve.svg"}
                  className={styles.svg_icon1}
                />
                <img
               src="/img/updated_logo.png"
                  className={styles.logo_img}
                  alt="img"
                />
              </div>
              <div className={styles.nav_btns_box} style={{width:"51%"}}>
                {getAllMenuSubMenudata?.slice(5, 10)?.map((item) => (
                  <Button
                    key={item.name}
                    className={styles.nav_btn}
                    sx={{
                      my: 0.7,
                      mx: 0.4,
                      fontFamily: "DM Sans",
                      fontSize: "17px",
                      lineHeight: "23px",
                      whitSpace: "nowrap",
                      color:
                        item.value === activetab.maintab ? "white" : "#636365",
                      backgroundColor:
                        item.value === activetab.maintab ? "#636365" : "",
                    }}
                    onClick={() => handleNavigateCategory(item?.menu?.name)}
                    onMouseEnter={handleLeftHover}
                    // name={item.name}
                    data-id={item?.menu?._id}
                  >
                    {capitalizeWords(item?.menu?.name)}
                  </Button>
                ))}

                {getAllMenuSubMenudata
                  ? filteredSubmenus?.map((menuItem, i) => (
                      <div key={i} className={styles.submenu_right}>
                        <div>
                          <img
                            alt="img"
                            // src={menuItem?.menu?.image}
                            src={ menuItem?.menu?.image}
                            width="200px"
                            height="200px"
                          />
                        </div>
                        <div className={styles.vertical_line}></div>
                        <>
                          {menuItem?.headings?.map((head, i) => (
                            <div key={i}>
                              <h2 className={styles.submenu_header}>
                                {head?.name}
                              </h2>
                              {head?.submenus?.map((subm, j) => (
                                <div key={j}>
                                  <h5
                                    id="/exhibition"
                                    name="upcoming"
                                    onClick={() =>
                                      handleNavigateAuctionExhibition(
                                        `${subm.link}`,
                                        "upcoming",
                                        "exhibition"
                                      )
                                    }
                                    className={
                                      location.pathname.includes(
                                        "exhibition"
                                      ) && auctionstatus.status === "upcoming"
                                        ? styles.submenu_links
                                        : styles.submenu_links
                                    }
                                  >
                                    {subm.name}
                                  </h5>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      </div>
                    ))
                  : ""}
              </div>
              <Button>
                <img
                  alt="img"
                  src={"/img/nav_Search.png"}
                  height="20px"
                  width="20px"
                  style={{ margin: "0px 10px" }}
                  onClick={() => navigate("/user/search")}
                />
              </Button>
              {/* <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorElSearch}
        open={openSearch}
        onClose={handleCloseSearch}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClose} sx={{display:'flex'}}>
        <TextField size="small" fullWidth  label="Search arts" variant="outlined"/>   <img
                  alt="img"
                  src={"/img/nav_Search.png"}
                  height="20px"
                  width="20px"
                  style={{ margin: "0px 10px" }} 
                  // onClick={() => navigate("/user/search")}
                />
        </MenuItem>
      
      </Menu> */}
              <div className={styles.nav_btns_box}>
                <Button name="cart" onMouseEnter={handleRightHover}>
                  <Badge
                    name="cart"
                    // onMouseEnter={handleRightHover}
                    badgeContent={usercart && usercart.result.length}
                    color="primary"
                    sx={{ ml: "-2" }}
                  >
                    <LocalMallOutlinedIcon
                      // onMouseEnter={handleRightHover}
                      name="cart"
                    />
                    {/* <img
                  src={ "/img/Cart.png"}
                  height="20px"
                  width="20px"
                  style={{ margin: "0px 10px" }}
                /> */}
                  </Badge>
                </Button>
                {rsubmenu.cart ? (
                  <div className={styles.submenu_cart}>
                    {usercart?.result?.map((data) => (
                      <div className={styles.card_row}>
                        <div className={styles.submenu_img_cart}>
                          <img
                            src={` ${data.item_id?.image}`}
                            height="80px"
                            width="80px"
                            style={{ margin: "10px 10px" }}
                          />
                        </div>
                        <div className={styles.submenu_header_card1}>
                          <article className={styles.submenu_header_cart}>
                            {data.item_id?.name}
                          </article>
                          <article className={styles.submenu_links_cart}>
                            {`${data.item_id?.width}*${data.item_id?.height}*${data.item_id?.depth}cm`}
                          </article>
                        </div>
                        <div className={styles.btn_cart}>
                          <CloseIcon onClick={() => handleRemoveItemcart(data._id)}/>
                        </div>
                      </div>
                    ))}
                    <div className={styles.btn_button}>
                      <Button
                        variant="contained"
                        onClick={() => navigate("user/mycart")}
                      >
                        View Cart
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => navigate("user/allcategory")}
                      >
                        Shop More
                      </Button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                margin: "auto",
              },
            }}
          >
            {/* drawer */}
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <Typography></Typography>
        </Box>
      </Box>
    </>
  )
}

export default Navbar
