import React, { useState } from "react";
import styles from "./Buyerartist.module.css";
import EightcolimgArtist from "../../../artist/commonTables/EightcolimgArtist/EightcolimgArtist";
import TableheaderArtist from "../../../artist/commonTables/TableheaderArtist/TableheaderArtist";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  acceptDeclineBuyerOffer,
  fetchBuyerOffer,
} from "../../../State/api/artistapi";
import Recretepopup from "../../../gallery/components/popups/recretepopup/Recretepopup";
import { setbuyerOfferData } from "../../../State/Features/Artist/buyerOfferSlice";
import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Buyerofferpopup from "../../../gallery/components/popups/recretepopup/buyerofferpopup";
import { ToastContainer } from "react-toastify";

const headData = [
  {
    id: "customer_id",
    label: "Image",
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "email",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "country",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  // {
  //   id: "item",
  //   label: "Art Type",
  //   format: (value) => value.toLocaleString(),
  // },
  {
    id: "newsletter",
    label: "Offer Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Price",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "actionsss",
    label: "Offer Price",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
];
const rows = [
  {
    customer_id: "#hd3444h",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "acce",
    offer_price: "%7410",
    status: 1,
  },
  {
    customer_id: "#hd344h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "accept",
    offer_price: "%7410",
    status: 0,
  },
  {
    customer_id: "#hd3444",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "Block",
    offer_price: "%7410",
    status: 1,
  },
  {
    customer_id: "#hd444h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "Unblock",
    offer_price: "%7410",
    status: 0,
  },
  {
    customer_id: "hd3444h",
    buyer_name: "smiley",
    email: "smiley@gmail.com",
    country: "India",
    items: "43",
    newsletter: "yes",
    contact: "87348798384",
    action: "rejected",
    offer_price: "%7410",
    status: 1,
  },
  {
    customer_id: "#d3444h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "Unblock",
    offer_price: "%7410",
    status: 0,
  },
  {
    customer_id: "#d3444h",
    buyer_name: "art smiley",
    email: "ARTsmiley@gmail.com",
    country: "India",
    items: "473",
    newsletter: "yes",
    contact: "87348798384",
    action: "accept",
    offer_price: "%7410",
    status: 0,
  },
];
const data = [
  {
    id: 1,
    title: "User Name",
    des: "John Deo",
  },
  {
    id: 2,
    title: "Sku_Id",
    des: "#76787",
  },
  {
    id: 3,
    title: "Art Name",
    des: "Morgan Wave",
  },
  {
    id: 4,
    title: "Art Type",
    des: "Painting",
  },
  {
    id: 5,
    title: "Offer Date",
    des: "July 21, 2021",
  },
  {
    id: 6,
    title: "Price",
    des: "$250",
  },
  {
    id: 7,
    title: "Offer Price",
    des: "$250",
  },
];
const sortdata = [
  { label: "By Price ↓", value: "low" },
  { label: "By Price ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
];
export default function Gallerybuyeroffer() {
  const [open, setOpen] = useState(false);
  const [acceptPopupData, setAcceptPopuupData] = useState(null);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null);
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });
  const buyerofferdt = useSelector((state) => state.buyerOfferData.data);
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchBuyerOffer(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  const handlePopupData = (dt) => {
    setOpen(true);
    setAcceptPopuupData(dt);
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    setCondition({ ...condition, filter: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };
  const handleAcceptRejectOffer = (id, dt) => {
    dispatch(acceptDeclineBuyerOffer(id, dt, fetchData));
  };
  const handleSorting = (event) => {
    const value = event.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setbuyerOfferData({
          data:
            buyerofferdt &&
            [...buyerofferdt.data].sort((a, b) => {
              return a.price - b.price;
            }),
          totalCount: buyerofferdt.totalCount,
        })
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: event.target.value });
      dispatch(
        setbuyerOfferData({
          data:
            buyerofferdt &&
            [...buyerofferdt.data].sort((a, b) => {
              return b.price - a.price;
            }),
          totalCount: buyerofferdt.totalCount,
        })
      );
    } else {
      dispatch(setbuyerOfferData(buyerofferdt));
      setCondition({ ...condition, sorting: value });
    }
  };

  return (
    <div className={styles.mains}>
      {open ? (
        <Buyerofferpopup
          handleAcceptRejectOffer={handleAcceptRejectOffer}
          acceptPopupData={acceptPopupData}
          data={buyerofferdt.data}
          open={open}
          setOpen={setOpen}
          activetab="buyeroffer"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.tableheader}>
          <TableheaderArtist
            setOpen={setOpen}
            handleFilter={handleFilter}
            button=""
            search={search}
            condition={condition}
            filterdata={filterdata}
            sortdata={sortdata}
            handleSearch={handleSearch}
            handleSorting={handleSorting}
          />
        </div>
        <EightcolimgArtist
          activetab="buyeroffer"
          headData={headData}
          data={
            buyerofferdt &&
            buyerofferdt?.makeOffers?.slice()
              .reverse() // Reverse the data array
              .filter((val) =>
                (val.first_name + " " + val.last_name)
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
              .filter((val) => {
                if (condition.filter === "approved") {
                  return val.is_accepted && val.is_accepted === "Approved";
                } else if (condition.filter === "rejected") {
                  return val.is_accepted && val.is_accepted === "Rejected";
                } else if (condition.filter === "pending") {
                  return val.is_accepted && val.is_accepted === "Pending";
                } else {
                  return val;
                }
              })
          
          }
          setOpen={setOpen}
          handlePopupData={handlePopupData}
        />
        <Pagination
          totaldata={buyerofferdt && buyerofferdt.totalCount}
          pagination={data}
          setPagination={setData}
        />
        <ToastContainer />
      </div>
    </div>
  );
}
