import Commoninputs from "../../../gallery/pages/editProfile/commonEditprofile/inputs/Commoninputs"
import React, { useEffect, useState } from "react"
import styles from "./Myprofileaffiliate.module.css"
import { Button, Grid } from "@mui/material"
import Uploadpic from "../../../gallery/pages/editProfile/commonEditprofile/uploadPic/Uploadpic"
import Enterdetails from "../../../components/Pages/List/Popupdelete/enterDetails/Enterdetails"
import {
  fetchAffiliateType,
  handlechangePassword,
} from "../../../State/api/superadminApi"
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import { useDispatch } from "react-redux"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import api from "../../../State/api/Api"
import { editAffiliateProfile } from "../../../State/api/artistapi"
import { removeUser } from "../../../State/Features/common/userSlice"
import serverimage from "../../../serverConstant"
// import Addexshibitiongallery from '../../../gallery/components/popups/editProfile/Addexhibitiongallery';

const placeholder = {
  placeholder: "upload new pic",
}

export default function Myprofileaffiliate() {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [affiliateDetails, setAffiliateDetails] = useState(null)
  const [affiliateType, setAffiliateType] = useState(null)
  const [passpop, setPasspop] = useState(false)
  const [apply, setApply] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    affiliate_account_type_id: "",
    image: "",
    promote_art_smiley: "",
    audience_interest: "",
    website_url: "",
    app_url: "",
    description: [],
    traffic_drivation: [],
    build_links: "",
    generate_income: "",
    unique_visitor_count: "",
    primary_reason_to_join: "",
    hear_about_us: "",
    website_about: "",
    bank_name: "",
    account_holder_name: "",
    // routing_ABA_number: "",
    // account_number: "",
    bank_location: "",
    account_type: "",
    bank_address: "",
    swift_code: "",
    address_type: "",
    address_line_1: "",
    address_type_2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  })
  const [src, setSrc] = useState({ profile: "" })
  const handleFetchAffiliateProfile = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/affiliate/fetchAffiliateDetails`,
        "GET"
      )
      if (result && result.status === 200) {
        const res = result.data.data.data
        const bank = result.data.data.bankDetail
        setSrc({
          ...src,
          profile:
            res?.image === "" || res.image === "undefined" ? "" : res.image,
        })
        setAffiliateDetails(result.data.data.data)
        // var canvas = document.createElement("canvas");

        // // Copy the image contents to the canvas
        // var ctx = canvas.getContext("2d");
        // ctx.drawImage(res.image, 0, 0);

        // var dataURL = canvas.toDataURL("image/png");
        // dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        setApply({
          first_name: res.affiliate_name.substring(
            0,
            res.affiliate_name.indexOf(" ")
          ),
          last_name: res.affiliate_name.substring(
            res.affiliate_name.length,
            res.affiliate_name.lastIndexOf(" ") + 1
          ),
          phone: res.phone,
          email: res.email,
          affiliate_account_type_id: res.affiliate_account_type_id._id,
          image: res.image,
          promote_art_smiley: res.promote_art_smiley,
          audience_interest: res.audience_interest,
          website_url: res.website_url,
          app_url: res.app_url,
          description: res.description ? res.description : [],
          traffic_drivation: res.traffic_drivation ? res.traffic_drivation : [],
          build_links: res.build_links,
          generate_income: res.generate_income,
          unique_visitor_count: res.unique_visitor_count,
          primary_reason_to_join: res.primary_reason_to_join,
          hear_about_us: res.hear_about_us,
          website_about: res.website_about,
          bank_name: bank ? bank.bank_name : "",
          account_holder_name: bank ? bank.account_holder_name : "",
          // routing_ABA_number: bank ? bank.routing_ABA_number : "",
          // account_number: bank ? bank.account_number : "",
          bank_location: bank ? bank.bank_location : "",
          account_type: bank ? bank.account_type : "",
          bank_address: bank ? bank.bank_address : "",
          swift_code: bank ? bank.swift_code : "",
          address_type: res.address_type,
          address_line_1: res.address_line_1,
          address_type_2: res.address_type_2,
          city: res.city,
          state: res.state,
          country: res.country,
          zipcode: res.zipcode,
        })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {
    }
  }
  const handleFetchAffiliateType = () => {
    dispatch(fetchAffiliateType(setAffiliateType))
  }
  useEffect(() => {
    handleFetchAffiliateProfile()
    handleFetchAffiliateType()
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target
    setApply({ ...apply, [name]: value })
  }
  // const handleChangeAffiliateType = (event, value) => {
  
  //   if (value) {
  //     setApply({ ...apply, affiliate_account_type_id: value._id });
  //   }
  
  // };
  const handleChangeImage = (e) => {
    const name = e.target.name
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const imgs = URL.createObjectURL(e.target.files[0])
      setSrc({ ...src, profile: imgs })
      setApply({ ...apply, image: img })
    }
  }
  const handleSubmitProfile = () => {
    dispatch(editAffiliateProfile(apply, handleFetchAffiliateProfile))
  }
  const changePassword = (pass) => {
    if (pass.password !== pass.confirmpassword) {
      Errornotify("Please fill same password both inputs")
    } else if (pass.password.length < 8) {
      Errornotify("Password must be 8 characters")
    } else {
      dispatch(handlechangePassword({ password: pass.password }, setPasspop))
    }
  }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {passpop ? (
        <Enterdetails
          changePassword={changePassword}
          open={passpop}
          setOpen={setPasspop}
          poptype="newpassword"
        />
      ) : (
        ""
      )}
      {/* {open ? <Addexshibitiongallery open={open} setOpen={setOpen} /> : ''} */}
      <div className={styles.components}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.upload_pic_div}>
              <Uploadpic
                val={placeholder}
                name="profile"
                src={src.profile}
                affiliateType={affiliateType}
                handleChangeImage={handleChangeImage}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <Commoninputs
              setOpen={setOpen}
              setPasspop={setPasspop}
              detail={apply}
              src={src}
              affiliateType={affiliateType}
              handleChangeImage={handleChangeImage}
              rendertype="Affiliate"
              affiliateDetails={affiliateDetails}
              handleChange={handleChange}
              // handleChangeAffiliateType={handleChangeAffiliateType}
            />
          </Grid>
        </Grid>
        <div className={styles.btn_div}>
          <Button
            variant="contained"
            onClick={handleSubmitProfile}
            sx={{ width: "120px", height: "54px", borderRadius: "10px", mt: 2 }}
          >
            Save
          </Button>
        </div>
      </div>
      {/* <div className={styles.components}>
                <Editprofilesecond />
            </div> */}
    </div>
  )
}
