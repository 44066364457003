import * as React from "react";
import styles from "./Popupfeatured.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Fourcolimg from "../../Table/Fourcolumn/Fourcolimg";
import { Grid, MenuItem, Select } from "@mui/material";
import { handleAddArtist } from "../../../../../State/api/superadminApi";
import { useDispatch } from "react-redux";
import homeImage from "../../../../../constant";

const style = {
 position: "absolute",
 top: "40%",
 left: "50%",
 transform: "translate(-50%, -50%)",
 width: 400,
 bgcolor: "background.paper",
 // border: '2px solid #000',
 boxShadow: 24,
 borderRadius: "6px",
 p: 2,
};
const data = [
 {
  title: "Eleanor Pena",
  content: "Paris",
 },
 {
  title: "Kathrn Murfy",
  content: "India",
 },
 {
  title: "Robert Fox",
  content: "Paris",
 },
 {
  title: "Bessie Cooper",
  content: "India",
 },
 {
  title: "Brooklyn Simmons",
  content: "Paris",
 },
 {
  title: "Cody Fisher",
  content: "Singapore",
 },
 {
  title: "Dianell Russell",
  content: "Los Angeles",
 },
];
const top100Films = [
 { label: "The Shawshank Redemption", year: 1994 },
 { label: "The Godfather", year: 1972 },
 { label: "The Godfather: Part II", year: 1974 },
 { label: "The Dark Knight", year: 2008 },
 { label: "12 Angry Men", year: 1957 },
 { label: "Schindler's List", year: 1993 },
 { label: "Pulp Fiction", year: 1994 },
];
const featuredhead = [
 {
  id: 1,
  label: "Image",
 },
 {
  id: 2,
  label: "Name",
 },
 {
  id: 3,
  label: "Followers",
 },
 {
  id: 4,
  label: "Likes",
 },
];
const featureddata = [
 {
  id: 1,
  src: "/img/image 1",
  name: "Galaxy On Earth",
  follower: "85.3k",
  like: "450",
 },
 {
  id: 2,
  src: "/img/image 1",
  name: "Galaxy On Earth",
  follower: "85.3k",
  like: "450",
 },
 {
  id: 3,
  src: "/img/image 1",
  name: "Galaxy On Earth",
  follower: "85.3k",
  like: "450",
 },
 {
  id: 4,
  src: "/img/image 1",
  name: "Galaxy On Earth",
  follower: "85.3k",
  like: "450",
 },
];
export default function Popupfeatured(props) {
 const [user, setUser] = React.useState({
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  password: "",
 });
 const [search, setSearch] = React.useState("");
 const dispatch = useDispatch();
 const handleClose = () => props.setOpen(false);
 const handleChange = (e) => {
  const { name, value } = e.target;
  setUser({ ...user, [name]: value });
 };
 const handleSubmit = (e) => {
  e.preventDefault();
  dispatch(handleAddArtist(user, props.setOpen, props.fetchData));
 };
 const handleChangeSearch = (e) => {
  const val = e.target.value;
  props.setSearch({ ...props.condition, search: val });
  console.log(val);
 };
 return (
  <div>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={props.open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}>
    {props.poptype === "search" ? (
     <Fade in={props.open}>
      <Box className={styles.search_modal}>
       <img
        align="right"
        onClick={handleClose}
        className={styles.cancel_img}
        src={ "/img/cancel_cross.png"}
        width="26px"
        height="25px"
       />
       <Typography
        align="center"
        id="transition-modal-title"
        className={styles.assign_heading}
        variant="h6"
        component="h2">
        Assign To
       </Typography>
       <div className={styles.search_action}>
        <label className={styles.search_label}>Search</label>
        <Select
         labelId="demo-select-small"
         id="demo-select-small"
         value={props.condition.search}
         fullWidth
         //  multiple
         sx={{
          background: "#f6f6f6",
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
          "& .MuiInputBase-root": {
           borderRadius: "6px",
          },
          height: "40px",
         }}
         placeholder="select type"
         onChange={handleChangeSearch}
         name="theme">
         {props.artistData &&
          props.artistData.map((val) => (
           <MenuItem key={val._id} value={val.assigned_to}>
            {val.assigned_to}
           </MenuItem>
          ))}
        </Select>
       </div>
      </Box>
     </Fade>
    ) : props.poptype === "searchs" ? (
     <Fade in={props.open}>
      <Box className={styles.modal_boxs}>
       <div className={styles.input_actions}>
        <div>
         <div className={styles.input_divs}>
          <input
           className={styles.search_inputs}
           value={props.condition.search}
           onChange={handleChangeSearch}
           placeholder="Morgan l"
           type="text"
          />
          <img
           className={styles.search_icons}
           src={ "/img/Search Symbol.png"}
           width="20px"
           height="20px"
          />
         </div>
         <hr className={styles.hr} />
         {props.artistData &&
          props.artistData
           .filter((vl) => 
            ( vl.is_featured === true && (vl.first_name.toLowerCase()+ " "+vl.last_name.toLowerCase()).includes(props.condition.search.toLowerCase()))
           )
           .map((val) => (
            <div className={styles.modal_data} key={val.title}>
             <p>
              {val.first_name} {val.last_name}
             </p>
             <p>{val.country}</p>
            </div>
           ))}
        </div>
        <Button
         variant="contained"
         onClick={handleClose}
         className={styles.close_btn}
         sx={{
          height: "44px",
          backgroundColor: "white",
          color: "black",
         }}>
         Close
        </Button>
       </div>
      </Box>
     </Fade>
    ) : // this is for artist>>> featrue >>> add
    props.poptype === "add" ? (
     <Fade in={props.open}>
      <Box className={styles.modal_box}>
       <div className={styles.input_action_div}>
        <div className={styles.input_div}>
         <input className={styles.search_input} placeholder="Morgan l" type="text" />
         <img
          className={styles.search_icon}
          src={ "/img/Search Symbol.png"}
          width="20px"
          height="20px"
         />
        </div>
        <Button
         onClick={handleClose}
         variant="contained"
         className={styles.cancel_btn}
         sx={{
          ml: 2,
          height: "44px",
          backgroundColor: "white",
          color: "black",
          minWidth: "100px",
         }}>
         close
        </Button>
       </div>
       <Fourcolimg
        poptype={props.poptype}
        head={featuredhead}
        data={featureddata}
        sx={{
         borderRadius: "none",
         border: "2px solid red",
         backgroundColor: "red",
         p: 4,
        }}
       />
       <Box
        sx={{
         py: 5,
         mx: "auto",
         display: "flex",
         justifyContent: "center",
         backgroundColor: "white",
         mt: -2,
        }}>
        <Button
         variant="contained"
         className={styles.save_btn}
         sx={{
          width: "156px",
          height: "54px",
          borderRadius: "10px",
         }}>
         Save
        </Button>
       </Box>
      </Box>
     </Fade>
    ) : (
     <Fade in={props.open}>
      <Box className={styles.addnew_modal}>
       <img
        align="right"
        onClick={handleClose}
        className={styles.cancel_img}
        src={ "/img/cancel_cross.png"}
        width="26px"
        height="25px"
       />
       <Typography
        align="center"
        id="transition-modal-title"
        className={styles.assign_heading}
        variant="h6"
        component="h2">
        {" "}
        Add New Artist
       </Typography>
       <div className="right_main">
        <div className="signup_cente">
         <form className="form_login" onSubmit={handleSubmit}>
          <Grid
           container
           columnSpacing={{ xs: 2, md: 3 }}
           rowSpacing={{ xs: 1, md: 1 }}
           columns={{ xs: 12, sm: 12, md: 12 }}>
           <Grid item xs={12} sm={6} md={6}>
            <span className="span_input">First name</span>
            <TextField
             onChange={handleChange}
             value={user.first_name}
             name="first_name"
             sx={{
              color: "#636365",
              mt: 0.4,
              "& .MuiOutlinedInput-notchedOutline": {
               border: "non",
              },
              "& .MuiInputBase-root": {
               // height: 35
              },
             }}
             //   inputProps={{
             //     style: {
             //       height: 1,
             //     },
             //   }}
             id="outlined-basic"
             placeholder="Enter your first name"
             className=""
             fullWidth
             variant="outlined"
            />
           </Grid>
           <Grid item xs={12} sm={6} md={6}>
            <span className="span_input">Last name</span>
            <TextField
             onChange={handleChange}
             value={user.last_name}
             name="last_name"
             sx={{
              color: "#636365",
              mt: 0.4,
              "& .MuiOutlinedInput-notchedOutline": {
               border: "non",
              },
              "& .MuiInputBase-root": {
               // height: 35
              },
             }}
             id="outlined-basic"
             placeholder="Enter your last name"
             className=""
             fullWidth
             variant="outlined"
            />
           </Grid>
           <Grid item xs={12} sm={6} md={6}>
            <span className="span_input">Mobile number</span>
            <TextField
             onChange={handleChange}
             value={user.phone}
             name="phone"
             sx={{
              color: "#636365",
              mt: 0.4,
              "& .MuiOutlinedInput-notchedOutline": {
               border: "non",
              },
              "& .MuiInputBase-root": {
               // height: 35
              },
             }}
             id="outlined-basic"
             placeholder="Enter your email"
             className=""
             fullWidth
             variant="outlined"
            />
           </Grid>
           <Grid item xs={12} sm={6} md={6}>
            <span className="span_input">Email</span>

            <TextField
             onChange={handleChange}
             value={user.email}
             name="email"
             sx={{
              color: "#636365",
              mt: 0.4,
              "& .MuiOutlinedInput-notchedOutline": {
               border: "non",
              },
              "& .MuiInputBase-root": {
               // height: 35
              },
             }}
             id="outlined-basic"
             placeholder="Enter your email"
             className=""
             fullWidth
             variant="outlined"
            />
           </Grid>
           <Grid item xs={12} sm={6} md={6}>
            <span className="span_input">Password</span>
            <TextField
             onChange={handleChange}
             value={user.password}
             name="password"
             sx={{
              color: "#636365",
              mt: 0.4,
              "& .MuiOutlinedInput-notchedOutline": {
               border: "non",
              },
              "& .MuiInputBase-root": {
               // height: 35
              },
             }}
             id="outlined-basic"
             placeholder="Enter your password"
             className=""
             fullWidth
             variant="outlined"
            />
           </Grid>
          </Grid>
          <Box className={styles.submit_btn}>
           <Button
            variant="contained"
            sx={{ height: "45px", my: 2, width: "152px" }}
            // fullWidth
            type="submit">
            Submit
           </Button>
          </Box>
         </form>
        </div>
       </div>
      </Box>
     </Fade>
    )}
   </Modal>
  </div>
 );
}
