import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const producttypeSlice = createSlice({
    name: "alltype",
    initialState,
    reducers: {
        setAllType: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAllType } = producttypeSlice.actions;

export default producttypeSlice.reducer;
