import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const gallerySlice = createSlice({
    name: 'gallerydata',
    initialState,
    reducers: {
        setGallery: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setGallery } = gallerySlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default gallerySlice.reducer;