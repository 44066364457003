import React from 'react';
import Commondisputedetails from '../../../../components/Pages/List/AdminDispute/common/Commondisputedetails';
import styles from './Disputedetailgallery.module.css'


const data = [
    {
        title: 'Depute ID',
        content: '#565787'
    },
    {
        title: 'Order ID',
        content: '#23444'
    },
    {
        title: 'Description',
        content: 'Lorem this is for onlyu tedt that is true'
    },
    {
        title: 'Payment',
        content: 'Pending'
    },
]
export default function Disputedetailgallery() {
    return (
        <div className={styles.mains}>
            <div className={styles.components}>
                <Commondisputedetails type='gallery' data={data} />
            </div>
        </div>
    )
}
