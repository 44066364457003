import React from 'react';
import './OrderComplete.module.css'; // Importing CSS file for styling
import { Step, StepLabel, Stepper } from '@mui/material';

const CompletedOrder = () => {
  return (

     <>
        <Stepper activeStep={3} sx={{ width: "67%", marginLeft: "16%", marginTop: "5%" }}>
    <Step>
      <StepLabel>Shopping Cart</StepLabel>
    </Step>
    <Step >
      <StepLabel>Checkout</StepLabel>
    </Step>
    <Step >
      <StepLabel>Completed</StepLabel>
    </Step>
  </Stepper>
    <div style={{width:"100%",display:"flex",justifyContent:"center",height:"50vh",alignItems:"center"}}>
    <div className="order-success-container">
      <h2>Order Placed Successfully!</h2>
      <p>Your order has been successfully placed. Thank you for shopping with us!</p>
      {/* <p>Your Order ID: <strong>{JSON.parse(localStorage.getItem('order_id'))}</strong></p> */}
    </div>
    </div>
     </>
  );
}

export default CompletedOrder;