import { Button, MenuItem, Select, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import api from "../../../../State/api/Api"
import {
  fetchFrameCountry,
  handleAddFrameCountry,
  handlechangePassword,
  handleUpdateSetting,
} from "../../../../State/api/superadminApi"
import { setLoader } from "../../../../State/Features/common/loaderSlice"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import styles from "./Setting.module.css"
import country from "../../../../Country"

export default function Setting() {
  const [inputs, setInputs] = useState({
    buy_percentage: 0,
    print_percentage: 0,
    rent_percentage: 0,
    vat_percentage: 0,
    custom_duties: 0,
    tax_percentage: 0,
    subscription_percentage: 0,
    curator_percentage: 0,
  })
  const [password, setPassword] = useState(localStorage.getItem("password"))
  const [passpop, setPasspop] = useState(false)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const { name, value } = e.target
    setInputs({ ...inputs, [name]: value })
  }
  const handleSetting = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/superAdmin/fetchSettingDetails`,
        "GET"
      )
      if (result && result.status === 200) {
        result.data.data.forEach((element) => {
          setInputs(element)
        })
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err)
    }
  }
  const handleUpdate = (dt) => {
    dispatch(handleUpdateSetting(dt, handleSetting))
  }
  const handleChangepass = (e) => {
    setPassword(e.target.value)
  }
  const changePassword = (pass) => {
    if (pass.password !== pass.confirmpassword) {
      Errornotify("Please fill same password both inputs")
    } else if (pass.password.length < 8) {
      Errornotify("Password must be 8 characters")
    } else {
      dispatch(handlechangePassword({ password: pass.password }, setPasspop))
    }
  }

  useEffect(() => {
    handleSetting()
  }, [])
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {passpop ? (
        <Enterdetails
          changePassword={changePassword}
          open={passpop}
          setOpen={setPasspop}
          poptype="newpassword"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div>
          {/* {data.map((val, i) => (
      <div className={styles.card_div}>
       <p className={styles.card_title}>{val.title}</p>
       {/* <div className={styles.card}> */}
          {/* <p className={styles.card_content}>30%</p> */}
          {/* <input
        type="text"
        placeholder={val.placeholder}
        className={styles.card}
        name={val.name}
        onChange={handleChange}
       />
      </div>
     ))} */}
          <div className={styles.card_div}>
            <p className={styles.card_title}>Curator Percentage</p>
            <input
              type="text"
              className={styles.card}
              name="curator_percentage"
              value={inputs.curator_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>Buy Percentage</p>
            <input
              type="text"
              className={styles.card}
              name="buy_percentage"
              value={inputs.buy_percentage}
              onChange={handleChange}
            />
          </div>

          <div className={styles.card_div}>
            <p className={styles.card_title}>Rent Percentage</p>
            <input
              type="number"
              className={styles.card}
              name="rent_percentage"
              value={inputs.rent_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>Print Percentage</p>
            <input
              type="number"
              className={styles.card}
              name="print_percentage"
              value={inputs.print_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>Vat Percentage</p>
            <input
              type="number"
              className={styles.card}
              name="vat_percentage"
              value={inputs.vat_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>Custom Duties</p>
            <input
              type="number"
              className={styles.card}
              name="custom_duties"
              value={inputs.custom_duties}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>Tax Percentage</p>
            <input
              type="number"
              className={styles.card}
              name="tax_percentage"
              value={inputs.tax_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <p className={styles.card_title}>
              Discount on yearly subscription (%)
            </p>
            <input
              type="number"
              className={styles.card}
              name="subscription_percentage"
              value={inputs.subscription_percentage}
              onChange={handleChange}
            />
          </div>
          <div className={styles.card_div}>
            <label className={styles.card_title}>Current Password</label>
            <input type="password" className={styles.card} value={password} />
          </div>
          <div className={styles.forgot_pass}>
            <div onClick={() => setPasspop(true)}>Change Password?</div>
          </div>
          <div className={styles.save_btn}>
            <Button
              className={styles.btn}
              sx={{
                borderRadius: "10px",
                width: "120px",
                height: "54px",
                fontFamily: "DM Sans",
              }}
              variant="contained"
              fullwidth
              onClick={() => handleUpdate(inputs)}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
