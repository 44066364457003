import { Grid, Typography } from "@mui/material"
import { createTheme } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./BlogDetailsPage.module.css"
import homeImage from "../../../constant"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import serverimage from "../../../serverConstant"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
const PressReleaseDetailsCard = [
  {
    id: 1,
    imgUrl: "/img/Rectangle prd.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 5267.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 5268.png",
  },
]
function BlogDetailsPage() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [pressDetail, setPressDetail] = useState()
  console.log(pressDetail, "pressDetail--")

  const handleFetchPessDetail = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/blog/fetchBlogBySlug/${id}`)
      if (result && result.status === 200) {
        setPressDetail(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    handleFetchPessDetail()
    // dispatch(fetchUsertypes());
  }, [])
  return (
    <div className={styles.pressreleasesdetails}>
      <div className={styles.headerCategory}>
        <br />
        <div>
          <Typography fontFamily="Marcellus" fontSize="2vw">
            <span className={styles.headerTitle}>Blog</span>
          </Typography>
          <Typography ml={8} fontFamily="Marcellus" fontSize="1vw">
            <span className={styles.headerSubTitle}>
              <b>Home / </b>Blog
            </span>
          </Typography>
        </div>
      </div>
      <Grid container>
        {pressDetail && (
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.content__main}>
              <img
                className={styles.content__image}
                src={ pressDetail?.data?.media}
              />
              <Typography mt={2} fontFamily="marcellus" fontSize="1.75rem">
                {pressDetail?.data?.title}
              </Typography>
              {/* <Typography fontSize="0.85rem">{item.press_type}</Typography> */}
              {/* <p>
                <Typography fontSize="0.9rem" color="#636365">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  amet pellentesque enim, non dignissim mauris, accumsan viverra
                  elementum. Aliquam lobortis amet faucibus id rutrum.
                  Vestibulum volutpat pellentesque fames augue sit elementum.
                  Auctor eget nisi ultrices aenean egestas sodales odio erat.
                  Ultricies cursus dolor mattis nunc. In eget quis ut pulvinar
                  sit in malesuada. Viverra integer fringilla eu et. Proin
                  ullamcorper facilisi diam, eleifend augue sed.
                </Typography>
              </p>
              <p>
                <Typography fontSize="0.9rem" color="#636365">
                  Qquam pellentesque justo montes, dignissim pharetra. Magna
                  eleifend nam amet arcu leo suspendisse. Ullamcorper commodo
                  amet integer nullam viverra sapien mattis. Massa mollis
                  scelerisque nisi, tortor purus leo sed sit. Justo id id nec
                  dui a varius. Ligula sodales feugiat consequat amet. Leo id
                  orci dignissim dignissim lectus turpis non rhoncus feugiat.
                </Typography>
              </p>
              <p>
                <Typography fontSize="0.9rem" color="#636365">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  amet pellentesque enim, non dignissim mauris, accumsan viverra
                  elementum. Aliquam lobortis amet faucibus id rutrum.
                  Vestibulum volutpat pellentesque fames augue sit elementum.
                  Auctor eget nisi ultrices aenean egestas sodales odio erat.
                  Ultricies cursus dolor mattis nunc. In eget quis ut pulvinar
                  sit in malesuada. Viverra integer fringilla eu et. Proin
                  ullamcorper facilisi diam, eleifend augue sed.
                </Typography>
              </p> */}
              <p>
                <Typography fontSize="0.9rem" color="#636365">
                  {/* {pressDetail?.data?.description} */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        pressDetail &&
                        pressDetail?.data?.description &&
                        pressDetail?.data?.description.length > 200
                          ? pressDetail?.data?.description.substring(0) + " "
                          : pressDetail?.data && pressDetail?.data?.description
                          ? pressDetail?.data?.description
                          : "",
                    }}
                  ></p>
                </Typography>
              </p>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default BlogDetailsPage
