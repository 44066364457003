import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const exshibitionSlice = createSlice({
    name: "exshibitiondata",
    initialState,
    reducers: {
        setExshibitiondata: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    },
});

export const { setExshibitiondata } = exshibitionSlice.actions;

// export const exshibitionData = (state) => state.exshibitiondata;

export default exshibitionSlice.reducer;
