import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const policySlice = createSlice({
    name: "policydata",
    initialState,
    reducers: {
        setPolicydata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setPolicydata } = policySlice.actions;

export default policySlice.reducer;
