import React, { useState } from "react";
import styles from "./Homemodel.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid"
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../../../State/api/userApi";
import { RiCloseFill, RiFacebookFill, RiTwitterXFill, RiInstagramLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // height:'700px',
    backgroundColor: "#FFFFFF",
    width: 445,
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    pl: 3,
    pr: 1,
    pt: 1,
    pb: 4,
};

export default function Homemodel(props) {
    const handleClose = () => props.setOpen(false);
    const navigate = useNavigate();


    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={props.open}>
                    <Box className={styles.modal_del}>
                        <a  className={styles.closebtn} onClick={() => props.setOpen(false)} ><RiCloseFill /></a>
                    <Grid container spacing={2}>
  <Grid item md={4} xs={12}>
  <div className={styles.left_div}>
                                <img src={"/img/Rectangle 5198.png"} className={styles.img_fluid} alt='img' />
                            </div>
  </Grid>
  <Grid item md={8} xs={12}>
  <div className={styles.right_div}>
                                <h2>Unlock 10% Off - Join Our Art Lovers Newsletter Today!</h2>
                                <h6>Get the Latest updates from ArtSmiley Subscribe to our newsletter for updates on fresh collections, special promotions, spotlighted artists, and more.</h6>
                                <div className={styles.input_div}>
                                    <TextField
                                        placeholder="Enter your email"
                                        sx={{
                                            backgroundColor: "#f3f3f3", "& fieldset": {
                                                border: props.email.length === 0 ? "none" : props.email.match(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+[A-Za-z]+$/) ? "none" : ""
                                            }
                                        }}
                                        variant="outlined"
                                        value={props.email}
                                        type='email'
                                        error='true'
                                        onChange={props.handleChange}
                                    />
                                    <button
                                        className={styles.scribuebtn}
                                        type="submit"
                                        onClick={props.handleSubmit}>
                                        Subscribe
                                    </button>
                                </div>
                                <p onClick={() => navigate("artsmiley/signup",{state: {type:"buyer"}})} style={{ cursor: "pointer" }}>Stay Inspired! Subscribe to the ArtSmiley Newsletter!</p>
                                <ul className={styles.social_links}>
                                    <li><a href=""><RiFacebookFill/></a></li>
                                    <li><a href=""><RiTwitterXFill/></a></li>
                                    <li><a href=""><RiInstagramLine/></a></li>
                                </ul>
                            </div>
  </Grid>
</Grid>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
