import React, { useEffect } from 'react'
import styles from "./Giftcard.module.css"
import Tableheader from '../../../List/Table/Tableheader/Tableheader';
import Moreoption from '../../../List/Table/Moreoption/Moreoption';
import { useState } from 'react';
import homeImage from '../../../../../constant';
import Sixcolumnimg from '../Tables/Sixcolumnimg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminOffer } from '../../../../../State/api/artistapi';
import { fetcCompaingnsData } from '../../../../../State/api/superadminApi';

const moreOptions = [
  {
    id: "sent",
    label: "Sent",
    value: "sent",
  },
  {
    id: "received",
    label: "Received",
    value: "received",
  },  
];
const approvedhead = [
 
  {
    id: "mobile",
    label: "Campaign Name",
  },
  {
    id: "date",
    label: "Campaign Code",
  },
  {
    id: "type",
    label: "Discount",
  },
  {
    id: "action",
    label: "Status",
  },
  {
    id: "email",
    label: "Date",
  },
];
const datas = [
  {
    id: 1,
    src: "/img/image 1",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 2,
    src: "/img/image 2",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 3,
    src: "/img/image 3",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 4,
    src: "/img/image 4",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
];
function BuyerOffers() {
    const dispatch = useDispatch()
  
  const [data, setData] = useState({ offset: 1 })
  const compaingns = useSelector((state) => state.compaingnsdata.data)
  console.log(compaingns,'campaingns')
  const fetchData = () => {
    dispatch(fetcCompaingnsData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  return (
    <div className={styles.mains}>
      <div className={styles.component}>
        {/* <div className={styles.header}>
          <div className={styles.rightheader}>
            <img src={`${homeImage}/image 135 (Traced).png`} width='20px' height='20px' /><span><b> $ 10,000</b></span>            
            <p><b>Available Balance</b></p> 
          </div>
        <Moreoption          
            moreOptions={moreOptions}   
            handleMorecategories={handleMorecategories}  
          />
        </div> */}
       
          <Sixcolumnimg
          
            head={approvedhead}   
            data={compaingns}
          />
      
      
      </div>
    </div>
  )
}

export default BuyerOffers