import validator from "validator";
import isEmpty from "./isEmpty";

export const validationForQuote = (
   { email,
    first_name,
    last_name,
    phone,
    message}
) => {
 
  let quoteErrors = {};

  if (validator.isEmpty(first_name)) {
    quoteErrors.first_name = "First name is required";
  } else if (
    !validator.isEmpty(first_name) &&
    !/^[A-Za-z\s]+$/.test(first_name)
  ) {
    quoteErrors.first_name = "First name can only contain alphabets";
  }
  if (validator.isEmpty(last_name)) {
    quoteErrors.last_name = "Last name is required";
  } else if (!validator.isEmpty(last_name) && !/^[A-Za-z\s]+$/.test(last_name)) {
    quoteErrors.last_name = "Last name can only contain alphabets";
  }
  if (validator.isEmpty(phone)) {
    quoteErrors.phone = "Phone number is required";
  } else if (!validator.isEmpty(phone) && !validator.isMobilePhone(phone)) {
    quoteErrors.phone = "Phone number is invalid";
  }

  if (validator.isEmpty(email)) {
    quoteErrors.email = "Email is required";
  } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    quoteErrors.email = "Email is invalid";
  }


  
  if(validator.isEmpty(message)) {
    quoteErrors.message = "Message is required"
  }



 

  
  return {
    quoteErrors,
    isValidQuote: isEmpty(quoteErrors),
  };
};
