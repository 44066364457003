import * as React from "react"
import styles from "./PopupCuratorList.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { assignCurator } from "../../../../State/api/superadminApi"
import { Grid, MenuItem, Select } from "@mui/material"
import { useDispatch } from "react-redux"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 4,
}
export default function PopupCuratorList(props) {
  console.log(props.curatorData, "props.curatorData-----")
  console.log(props.curatorUser, "7878ytestcur")
  const dispatch = useDispatch()
  const handleClose = () => props.setOpen(false)

  const handleChange = (e) => {
    const { value } = e.target
    // Update the parent's state through the passed setCuratorUser function
    props.setCuratorUser(value)
  }
  const curatorSubmit = () => {
    dispatch(assignCurator(props.curatorUser, props.popupdata, props.fetchData))
    props.setOpen(false)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            <Typography
              align="center"
              id="transition-modal-title"
              className={styles.assign_heading}
              variant="h6"
              component="h2"
            >
              Assign To
            </Typography>
            <div className={styles.search_action}>
              <label className={styles.search_label}>Search</label>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={props.curatorUser || ""}
                fullWidth
                //  multiple
                sx={{
                  background: "#f6f6f6",
                  ".MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiInputBase-root": {
                    borderRadius: "6px",
                  },
                  height: "40px",
                }}
                placeholder="Select curator"
                onChange={handleChange}
                name="curator_id"
              >
                {props.curatorData &&
                  props.curatorData.map((val) => (
                    <MenuItem key={val._id} value={val.user_id}>
                      {val.curator_name}
                    </MenuItem>
                  ))}
              </Select>
            </div>
            <div className={styles.action}>
              <Button
                variant="contained"
                sx={{ borderRadius: "10px" }}
                className={styles.action_btn}
                onClick={curatorSubmit}
              >
                Add
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
