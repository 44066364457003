import React from "react"
import styles from "./Tables.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Button, IconButton } from "@mui/material"
import Popup from "../../Popupdelete/Popup"
import dayjs from "dayjs"
import Tableheader from "../Tableheader/Tableheader"
import Moreoption from "../Moreoption/Moreoption"
import homeImage from "../../../../../constant"
import serverimage from "../../../../../serverConstant"
const headData = [
  {
    id: "feature",
    label: "feature",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "description",
    label: "Description",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "createdAt",
    label: "Created At",
    // format: (value) => value.toLocaleString(),
  },

  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]

export default function TableMarketing(props) {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      {open ? <Popup open={open} setOpen={setOpen} /> : ""}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F6F6", color: "black", boxShadow: "none" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {headData.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data &&
                props.data.map((row, i) => (
                  <TableRow style={{ color: "#636365" }} key={i}>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      component="th"
                      scope="row"
                    >
                      {row.feature}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            row &&
                            row.description &&
                            row.description.length > 100
                              ? row.description.substring(0, 100) + "..."
                              : row && row.description
                              ? row.description
                              : "",
                        }}
                      ></span>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.createdAt
                        ? dayjs(row.createdAt?.row?.createdAt)
                            .format("YYYY-MM-DD")
                            .toString()
                        : "---"}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* <Button
                        color={row.status ? "primary" : "primary"}
                        variant={row.status ? "contained" : "contained"}
                        sx={{ width: "100px", mr: 2 }}
                      >
                        Block
                      </Button> */}
                      <IconButton
                      // onClick={() => setOpen(true)}
                      >
                        <img
                          src={"/img/delete.png"}
                          width="20px"
                          height="23px"
                          alt="img"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
