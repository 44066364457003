import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const disputeSlice = createSlice({
    name: "disputedata",
    initialState,
    reducers: {
        setDisputedata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setDisputedata } = disputeSlice.actions;
export const disputeData = (state) => state.disputeData;

export default disputeSlice.reducer;
