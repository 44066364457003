import { createTheme, Grid, ThemeProvider, Typography, Container, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Offers.module.css";
import ProductSidebar from "./ProductSidebar";
import homeImage from "../../../constant";
import { useNavigate } from "react-router-dom";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import serverimage from "../../../serverConstant";
import { fetchFetchOffers } from "../../../State/api/userApi";
import striptags from "striptags";
import dateFormat from "../../../utils/dateFormat";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});
const CardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 5201.png",
    discount: "-14%",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 5202.png",
    discount: "-30%",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 5203.png",
    discount: "-20%",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5280.png",
    discount: "-14%",
  },
  {
    id: 5,
    imgUrl: "/img/Rectangle 5281.png",
    discount: "-30%",
  },
  {
    id: 6,
    imgUrl: "/img/Rectangle 5282.png",
    discount: "-20%",
  },
  {
    id: 7,
    imgUrl: "/img/Rectangle 5292.png",
    discount: "-14%",
  },
  {
    id: 8,
    imgUrl: "/img/Rectangle 5293.png",
    discount: "-30%",
  },
  {
    id: 9,
    imgUrl: "/img/Rectangle 5294.png",
    discount: "-20%",
  },
  {
    id: 10,
    imgUrl: "/img/Rectangle 5303.png",
    discount: "-14%",
  },
  {
    id: 11,
    imgUrl: "/img/Rectangle 5304.png",
    discount: "-30%",
  },
  {
    id: 12,
    imgUrl: "/img/Rectangle 5305.png",
    discount: "-20%",
  },
];
function Offers() {
  const [offerData, setOfferData] = useState();
  const [style, setStyle] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const [filter, setFilter] = useState({
    type: [],
    category: [],
    style: [],
    artist_type: [],
    artist: [],
    country: [],
    orientation: "",
    color: [],
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loader = useSelector((state) => state.loader);
  const offersdt = useSelector((state) => state.offersData);

  const handleNavigate = (route) => {
    navigate(route);
  };
  // const handleFetchOffer = async function () {
  //   dispatch(setLoader(true));
  //   try {
  //     const result = await api.applicationApi('api/user/getoffers'
  //     )
  //     if (result && result.status === 200) {
  //       setOfferData(result.data);
  //       dispatch(setLoader(false));
  //     }
  //     else if (result.status === 401) {
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  useEffect(() => {
    // handleFetchOffer();
    dispatch(fetchFetchOffers());
  }, []);
  const handleOfferDetail = (id) => {
    navigate(`/user/search?offername=${id}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.offers}>
      <div className={styles.headerCategory}>
        <Container maxWidth="lg">
      <div className={styles.pageitle}>
              <h1>Offers</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Offers</li>
              </ul>
            </div>
      </Container>
        </div>
        
        <div className={styles.mainContent}>
          {/* <ProductSidebar
            value_height={value_height}
            value_width={value_width}
            priceRange={priceRange}
            setValueHeight={setValueHeight}
            filter={filter}
            style={style}
            setFilter={setFilter}
            setPriceRange={setPriceRange}
            setValueWidth={setValueWidth}
          /> */}
          <div className={styles.productCards__main}>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              <Grid container spacing={3}>
                {offersdt.data &&
                 [...offersdt.data]
                 .filter((item) => !item?.end_date || new Date(item.end_date) >= new Date()) // Exclude items with end_date in the past
                 .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by createdAt in descending order
                 .map((item) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      onClick={() => handleOfferDetail(item.name)}
                    >
                      <div className={styles.productCards}>
                        <img
                          className={styles.offerCardImg}
                          src={ item.offer_image} alt=""
                        />
                        <div className={styles.discountDiv}>
                          {item?.discountType === "Percentage" ? item.discount + "%" : "$" + item.discount} 
                        </div>
                        <div className={styles.card__title}>
                          <Typography fontWeight="600" fontSize="0.95rem" sx={{width:'50%'}}>
                            {item.name}
                          </Typography>
                          <div className={styles.calendar}>
                            <img
                              className={styles.calendarImg}
                              src="/img/calendar-2.png" alt=""
                            />
                            <Typography mt={0.35} ml={0.5} fontSize="0.85rem">
                              {dateFormat(item.start_date)} - {dateFormat(item.end_date)}
                            </Typography>
                          </div>
                        </div>
                        <Typography fontSize="0.92rem" color="#636365">
                     
                        </Typography>
                        <hr className={styles.card__divider} />
                        <Typography fontSize="0.85rem" color="#636365">
                          {striptags(item.description.slice(0,40))}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Offers;
