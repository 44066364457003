import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import {
  Avatar,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton,
  XIcon,
} from "react-share";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./ArtistDetails.module.css";
import ProductSidebar from "./ProductSidebar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import homeImage from "../../../constant";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../State/api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import serverimage from "../../../serverConstant";
import CircularLoader from "../../../common/loader/Loader";
import {
  filterAllArts,
  handelAddFollow,
  handelUnFollow,
  handleFetchBuyerDetail,
} from "../../../State/api/userApi";
import { Errornotify } from "../../../common/alert/Alert";
import { CloseOutlined, SearchOutlined } from "@mui/icons-material";
import SliderCarousel from "./components/SliderCarousel";
import { formatNewCurrency } from "../../../utils/currencyFormat";
import striptags from "striptags";
import { setSearchFilter } from "../../../State/Features/user/filterationSlice";
import { set } from "date-fns";
import { handleFetchStyle } from "../../../State/api/superadminApi";

const otherartistCard = [
  {
    id: 1,
    artImg: "/img/Rectangle 5253.png",
    artistImg: "/img/Ellipse 259.png",
  },
  {
    id: 2,
    artImg: "/img/Rectangle 58.png",
    artistImg: "/img/Ellipse 28.png",
  },
  {
    id: 3,
    artImg: "/img/Rectangle 62.png",
    artistImg: "/img/Ellipse 30.png",
  },
  {
    id: 4,
    artImg: "/img/Rectangle 66.png",
    artistImg: "/img/Ellipse 259.png",
  },
];
function ArtistDetails() {
  const [artistDetail, setArtistDetail] = useState();
  const [arts, setArts] = useState();
  const [style, setStyle] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const { id } = useParams();
  const [filteredValues, setFilteredValues] = useState({
    categoryName: [],
    styleName: "",
    style: "",
    colorName: "",
    artistTypeName: [],
    artistName: [],
    orientationName: "",
    startPrice: "0",
    endPrice: "50000",
    minHeight: 0,
    maxHeight: 1500,
    minWidth: 0,
    maxWidth: 1500,
    countryName: "",
    searchTitle: "",
  });
  const { option } = useParams();
  const [loadingArts, setLoadingArts] = useState(false);
  const [initialValueSet, setInitialValueSet] = useState(false);
  const loader = useSelector((state) => state.loader);
  const [searchTitle, setSearchTitle] = useState("");
  const filter = useSelector((state) => state.filteration);
  const [selectedSort, setSelectedSort] = useState("low");
  const setAllFilterValues = useSelector(
    (state) => state.filteration.allFilterValues
  );
  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const allcategory = useSelector((state) => state.allcategoryData);
  const userartists = useSelector((state) => state.userartistData);
  const userDetail = useSelector((state) => state.buyerdetail.data);

  const handleFetchArtist = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(
        `api/user/getartists/${id}`,
        "GET"
      );
      if (result && result.status === 200) {
        setArtistDetail(result.data);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };

  const handleFetchArtByArtist = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/allitems`, "GET");
      if (result && result.status === 200) {
        setArts(result.data.artworks);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };

  const navigateToArtworkDetail = (ids) => {
    navigate(`/user/allcategory/${id}/${ids}`);
  };
  const handleAddFollowArtist = (type, id) => {
    const user_id = localStorage.getItem("user_id");
    if (!user_id) {
      Errornotify("Please Login First");
    } else if (type === "follow") {
      dispatch(handelAddFollow({ user_id: id }));
    } else {
      dispatch(handelUnFollow({ user_id: id }));
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [window.location.pathname]);
  useEffect(() => {
    handleFetchArtist();
    handleFetchArtByArtist();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [id]);
  useEffect(() => {
    dispatch(handleFetchBuyerDetail());
  }, []);

  const handleChangeFilteredValues = (e) => {
    if (e.target.name === "categoryName") {
    }
    setFilteredValues({ ...filteredValues, [e.target.name]: e.target.value });
    window.scrollTo({
      top: 120,
      behavior: "smooth",
    });
  };

  const sortedFilterValues = Array.isArray(setAllFilterValues)
  ? [...setAllFilterValues]
      .filter((item) => {
        // Check if searchTitle matches
        const matchesSearchTitle = filteredValues.searchTitle
          ? item?.name
              .toLowerCase()
              .includes(filteredValues.searchTitle.toLowerCase())
          : true;

        // Check if artist_id matches
        const matchesArtistId =
          artistDetail?.data?.artist?.user_id === item?.artist_id;

        return matchesSearchTitle && matchesArtistId;
      })
      .sort((a, b) => {
        // Sort by price based on selectedSort
        if (selectedSort === "low") {
          return a.sale.price - b.sale.price; // Low to High
        } else if (selectedSort === "high") {
          return b.sale.price - a.sale.price; // High to Low
        } else {
          return new Date(b.createdAt) - new Date(a.createdAt); // Default sort by date
        }
      })
  : [];

  const selectedFilters = [
    filteredValues.categoryName.length ? filteredValues.categoryName : null,
    filteredValues.artistTypeName.length ? filteredValues.artistTypeName : null,
    filteredValues.artistName.length ? filteredValues.artistName : null,
    filteredValues.colorName,
    filteredValues.orientationName,
    filteredValues.countryName,
    filteredValues.styleName,
    (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
    (filteredValues.endPrice && filteredValues.endPrice !== "50000")
      ? "$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice
      : null,
    (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
    (filteredValues.maxHeight && filteredValues.maxHeight !== 1500)
      ? filteredValues.minHeight +
        " cm" +
        " - " +
        filteredValues.maxHeight +
        " cm" +
        " height"
      : null,
    (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
    (filteredValues.maxWidth && filteredValues.maxWidth !== 1500)
      ? filteredValues.minWidth +
        " cm" +
        " - " +
        filteredValues.maxWidth +
        " cm" +
        " width"
      : null,
    filteredValues.searchTitle,
  ].filter(
    (filterValue) =>
      filterValue !== "" && filterValue !== null && filterValue !== undefined
  );
  console.log(selectedFilters,'selectedFilters')

  const sortdata = [
    { label: "Sale Price ↓", value: "low" },
    { label: "Sale Price ↑", value: "high" },
  ];
  const clearCategoryName = (categoryName) => {
    setFilteredValues({ ...filteredValues, categoryName: "" });
    // searchParams.delete("category");
  };
  const clearArtistTypeName = (artistTypeName) => {
    setFilteredValues({ ...filteredValues, artistTypeName: "" });
  };
  const clearArtistName = () => {
    setFilteredValues({ ...filteredValues, artistName: "" });
  };
  const clearColorName = (colorName) => {
    setFilteredValues({ ...filteredValues, colorName: "" });
  };
  const clearOrientationName = (colorName) => {
    setFilteredValues({ ...filteredValues, orientationName: "" });
  };
  const clearFunctions = {
    [filteredValues.categoryName]: clearCategoryName,
    [filteredValues.artistTypeName]: clearArtistTypeName,
    [filteredValues.colorName]: clearColorName,
    [filteredValues.orientationName]: clearOrientationName,
    [filteredValues.artistName]: clearArtistName,
    [filteredValues.searchTitle]: () => {
      setSearchTitle("")
      setFilteredValues({ ...filteredValues, searchTitle: "" });
    },
    [filteredValues.countryName]: () =>
      setFilteredValues({ ...filteredValues, countryName: "" }),
    [filteredValues.styleName]: () =>
      setFilteredValues({ ...filteredValues, styleName: "" }),
    ["$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice]:
      () =>
        setFilteredValues({
          ...filteredValues,
          startPrice: "0",
          endPrice: "50000",
        }),
    [filteredValues.minWidth +
    " cm" +
    " - " +
    filteredValues.maxWidth +
    " cm" +
    " width"]: () =>
      setFilteredValues({ ...filteredValues, minWidth: 0, maxWidth: 1500 }),
    [filteredValues.minHeight +
    " cm" +
    " - " +
    filteredValues.maxHeight +
    " cm" +
    " height"]: () =>
      setFilteredValues({ ...filteredValues, minHeight: 0, maxHeight: 1500 }),
    // ... map other filter values to their corresponding clear functions ...
  };
  const removeFilter = (filtervalue) => {
    const clearFunction = clearFunctions[filtervalue];
    if (clearFunction) {
      clearFunction();
    }
  };

  

  useEffect(() => {
    if (!initialValueSet) return;
    const searchParams = new URLSearchParams();

    if (filteredValues.categoryName) {
      searchParams.set("category", filteredValues.categoryName.toLowerCase());
    }
    if (filteredValues.styleName) {
      searchParams.set("style-name", filteredValues.styleName.toLowerCase());
    }
    if (filteredValues.style) {
      searchParams.set("style", filteredValues.style.toLowerCase());
    }
    if (filteredValues.colorName) {
      searchParams.set("color", filteredValues.colorName.toLowerCase());
    }
    if (filteredValues.artistTypeName.length > 0) {
      searchParams.set("artist-type", filteredValues.artistTypeName);
    }

    if (filteredValues.artistName.length > 0) {
      searchParams.set("artist-name", filteredValues.artistName);
    }

    if (filteredValues.orientationName) {
      searchParams.set(
        "orientation",
        filteredValues.orientationName.toLowerCase()
      );
    }
    if (
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000")
    ) {
      searchParams.set(
        "price-range",
        `${filteredValues.startPrice}-${filteredValues.endPrice}`
      );
    }
    if (filteredValues.minHeight || filteredValues.maxHeight) {
      searchParams.set(
        "height-range",
        `${filteredValues.minHeight}-${filteredValues.maxHeight}`
      );
    }
    if (filteredValues.minWidth || filteredValues.maxWidth) {
      searchParams.set(
        "width-range",
        `${filteredValues.minWidth}-${filteredValues.maxWidth}`
      );
    }
    if (filteredValues.countryName) {
      searchParams.set("country", filteredValues.countryName.toLowerCase());
    }

    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : "";

    if (
      filteredValues.categoryName ||
      filteredValues.styleName ||
      filteredValues.style ||
      filteredValues.colorName ||
      (filteredValues.artistTypeName &&
        filteredValues.artistTypeName.length > 0) ||
      (filteredValues.artistName && filteredValues.artistName.length > 0) ||
      filteredValues.orientationName ||
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000") ||
      (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
      (filteredValues.maxHeight && filteredValues.maxHeight !== 1500) ||
      (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
      (filteredValues.maxWidth && filteredValues.maxWidth !== 1500) ||
      filteredValues.countryName
    ) {
      navigate(`/user/artist/${id}${newSearch}`, { replace: true });
    } else {
      navigate(`/user/artist/${id}`);
    }
  }, [filteredValues, dispatch]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    // Parse URL parameters and set initial state
    const searchParams = new URLSearchParams(window.location.search);
    const params = {
      categoryName: searchParams.get("category") || "",
      styleName: searchParams.get("style-name") || "",
      style: searchParams.get("style") || "",
      colorName: searchParams.get("color") || "",
      artistTypeName: searchParams.get("artist-type")
        ? searchParams.get("artist-type").split(",")
        : [],
      artistName: searchParams.get("artist-name")
        ? searchParams.get("artist-name").split(",")
        : [],
      orientationName: searchParams.get("orientation") || "",
      startPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[0]
        : "0",
      endPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[1]
        : "50000",
      minHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[0]
        : "",
      maxHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[1]
        : "",
      minWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[0]
        : "",
      maxWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[1]
        : "",
      countryName: searchParams.get("country") || "",
    };

    setFilteredValues({ ...filteredValues, ...params });
    dispatch(filterAllArts(params, setLoadingArts)); // Ensure dispatch is done with initial params
    setInitialValueSet(true);
  }, [window.location.search, dispatch]);

  const handleRouteArtist = (id) => {
    window.location.href = `/user/artist/${id}`;
  };

  const handleSorting = (event) => {
    const value = event.target.value;
    setSelectedSort(value);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTitle(value);
    setFilteredValues({ ...filteredValues, searchTitle: value });
    dispatch(setSearchFilter(value));
  };

  useEffect(() => {
    if(filteredValues.categoryName) {
     
      const category_id = allcategory?.data?.allcategories?.filter((val) => 
        val?.name === filteredValues.categoryName
      )[0]?._id;
      console.log(category_id,'category_id')
      if(category_id) {
       dispatch(handleFetchStyle({category_id: category_id}, setStyle));
       
      }
    }
    },[filteredValues.categoryName,allcategory?.data?.allcategories])
  const handleShare = () => {
    const data = {
      title: "Artsmilly codes",
      text: "Hello Artsmilly",
      url: window.location.href,
    }
    if (navigator.canShare && navigator.canShare(data)) {
      navigator.share(data)
    } else {
    }
  }
  return (
    <div className={styles.artistdetails}>
      {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : (
        artistDetail &&
        artistDetail?.data?.artist && (
          <>
            <div className={styles.headerImage}>
              <img
                className={styles.bannerbigimg}
                src={
                  artistDetail.data.artist.backgroundImage
                    ?
                      artistDetail.data.artist.backgroundImage
                    : "/img/artist_bg_alts.jpeg"
                }
                alt="img"
              />
              <div className={styles.artistImgDiv}>
                <img
                  className={styles.artistImg}
                  src={
                    artistDetail.data.artist.image
                      ?  artistDetail.data.artist.image
                      : "/img/profile_alt.jpg"
                  }
                  alt="img"
                />
              </div>
            </div>
            <div className={styles.artistInfo}>
              <Typography
                ml={-4}
                fontFamily="marcellus"
                fontSize="2rem"
                textAlign="center"
              >
                {artistDetail.data.artist.artist_name}
              </Typography>
              <div className={styles.artistStats}>
                <div className={styles.arts}>
                  <Typography fontSize="2rem" textAlign="center">
                    {arts &&
                      arts.filter(
                        (val) =>
                          val.artist_id?._id ===
                            artistDetail.data.artist?.user_id &&
                          val.is_accepted === "Approved"
                      ).length}
                  </Typography>
                  <Typography ml={1}>Arts</Typography>
                </div>
                <div className={styles.verticalLine3}></div>
                <div className={styles.followers}>
                  <div className={styles.arts}>
                    <Typography fontSize="2rem" textAlign="center">
                      {artistDetail &&
                        artistDetail.data.followers.length}
                    </Typography>
                    <Typography ml={1}>Followers</Typography>
                  </div>
                </div>
                <div className={styles.verticalLine3}></div>
                <div className={styles.sold}>
                  <div className={styles.arts}>
                    <Typography fontSize="2rem">{artistDetail?.data?.artist?.art_Sold_Count > 1000 ? artistDetail?.data?.artist?.art_Sold_Count?.slice(0,1) + "K" : artistDetail?.data?.artist?.art_Sold_Count}</Typography>
                    <Typography ml={1}>Sold</Typography>
                  </div>
                </div>
              </div>
              <div className={styles.artistShare}>
              {userDetail &&
              userDetail.data.followings &&
              userDetail.data.followings
                .map((val) => val._id?._id)
                .includes(
                  artistDetail && artistDetail.data.artist.user_id
                ) ? (
                // artistDetail && artistDetail.data.followers.map(val => val._id)
                <Button
                  variant="outlined"
                  sx={{
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    // "&:hover": {
                    //   background: "black",
                    // },
                  }}
                  onClick={() =>
                    handleAddFollowArtist(
                      "unfollow",
                      artistDetail && artistDetail.data.artist.user_id
                    )
                  }
                  startIcon={<PersonAddAlt1OutlinedIcon />}
                >
                  Unfollow this artist
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    handleAddFollowArtist(
                      "follow",
                      artistDetail && artistDetail.data.artist.user_id
                    )
                  }
                  sx={{
                    color: "white",
                    backgroundColor: "black",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                    "&:hover": {
                      background: "black",
                    },
                  }}
                  startIcon={<PersonAddAlt1OutlinedIcon />}
                >
                  Follow this artist
                </Button>
              )}
              <div className={styles.sharelogo}>
                <img
                onClick={handleClick}
                  className={styles.shareimage}
                  src={"/img/share 1 (Traced).png"}
                  alt="img"
                />
                   <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
  <MenuItem onClick={handleClose}>

    <FacebookShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
      <FacebookIcon size={30} />
      <span style={{ marginLeft: 8 }}>Share on Facebook</span>
    </FacebookShareButton>

</MenuItem>
<MenuItem onClick={handleClose}>

<LinkedinShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <LinkedinIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on Linkedin</span>
</LinkedinShareButton>

</MenuItem>
<MenuItem onClick={handleClose}>

<WhatsappShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <WhatsappIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on Whatsapp</span>
</WhatsappShareButton>

</MenuItem>
<MenuItem onClick={handleClose}>

<TwitterShareButton url={window.location.href} style={{display:'flex',alignItems:"center",gap:'5px'}}>
  <XIcon size={30} />
  <span style={{ marginLeft: 8 }}>Share on X</span>
</TwitterShareButton>

</MenuItem>
   
      </Menu>
              </div>
            </div>
            </div>
         
            <div className={styles.artist__description}>
              <Typography fontWeight="600" fontSize="0.95rem">
                About Artist
              </Typography>
              <p>
                <Typography fontSize="0.9rem" mt={-1}>
                  {/* {artistDetail.data.artist.biography} */}
              {striptags(artistDetail?.data?.artist?.about)}
                </Typography>
              </p>
              <Typography fontWeight="600" fontSize="0.95rem">
                Professional Details
              </Typography>
              <p>
                <Typography fontSize="0.9rem" mt={-1}>
                {striptags(artistDetail?.data?.artist?.professional_details)}
                </Typography>
              </p>
            </div>
          </>
        )
      )}
      <div className={styles.moreart_heading}>
        <h2>Artworks</h2>
        <hr />
        <div className={styles.art_de_sear_con}>
          <TextField
            id="input-with-icon-textfield"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Search"
            value={searchTitle}
            onChange={handleSearch}
            sx={{
              width: "30%",
              mt: 0,
            }}
            size="small"
          />

          <div className={styles.filter__maindiv}>
            <div className={styles.filter__div}>
              <span className={styles.art_de_sear_label}>Sort By</span>

              <FormControl sx={{ minWidth: 120, color: "red" }} size="small">
              <Select
                value={selectedSort}
                onChange={handleSorting}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                sx={{
                  color: "#636365",
                  border: "0.6px solid #665D6F",
                  borderRadius: "6px",
                
                }}
              >
                {/* <MenuItem value="" sx={{ color: "#636365" }}>
                  Best Seller
                </MenuItem> */}
                <MenuItem value="">Sort by</MenuItem>
                {sortdata &&
                  sortdata.map((val) => (
                    <MenuItem value={val.value}>{val.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.artwork__div}>
        <ProductSidebar
          value_height={value_height}
          value_width={value_width}
          setValueHeight={setValueHeight}
          style={style}
          setStyle={setStyle}
          priceRange={priceRange}
          filter={filter}
          setFilter={filter}
          setPriceRange={setPriceRange}
          selectedOption={option}
          setValueWidth={setValueWidth}
          producttypeData={productType.data}
          artisttypeData={artistTypes.data}
          allartistData={userartists.data}
          allcategoryData={allcategory.data && allcategory.data.allcategories}
          setFilteredValues={setFilteredValues}
          handleChangeFilteredValues={handleChangeFilteredValues}
          filteredValues={filteredValues}
        />
        <div className={styles.ProductCards}>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              padding: "10px 20px",
            }}
          >
            {selectedFilters?.map((val, index) => (
              <button
                className={styles.filterButtonsStyles}
                // onClick={() => removeFilter(filterValue)}
              >
                {/* <FaTimes onClick={() => removeFilter(filterValue)} /> */}
                {val}
                <CloseOutlined
                  onClick={() => removeFilter(val)}
                  sx={{ fontSize: "1rem", marginLeft: "4px" }}
                  className={styles.filterButtonsStylesIcon}
                />
              </button>
            ))}
          </div>
          <Container>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 5 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {loader.loader ? (
                <div className={styles.loader}>
                  <CircularLoader />
                </div>
              ) : (
                sortedFilterValues &&
                sortedFilterValues?.map((item) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ mb: 2 }}
                    key={item._id}
                  >
                    <div
                      className={styles.card__main}
                      onClick={() => navigateToArtworkDetail(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.cardImage}
                          src={ item.image}
                          alt="img"
                        />
                      </div>
                      <div className={styles.card__middle}>
                        <div className={styles.card__middleLeft}>
                          <Typography
                            fontSize="0.95rem"
                            fontWeight="700"
                            // mt={1}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            fontSize="0.8rem"
                            fontWeight="700"
                            color="#ccc"
                          >
                            {item.artist_id &&
                              item.artist_id.first_name +
                                " " +
                                item.artist_id &&
                              item.artist_id.last_name}
                          </Typography>
                        </div>
                        <div className={styles.card__middleRight}>
                          <FavoriteBorderIcon fontSize="small" />
                          <Typography fontWeight="400" ml={0.5}>
                            {item?.likesView?.[0]?.likes.length || 0}
                          </Typography>
                        </div>
                      </div>
                      <hr />
                      <div className={styles.card__bottom}>
                        <div className={styles.buy__text}>
                          <Button
                            sx={{
                              marginTop: "15px",
                              maxHeight: "5vh",
                              color: "white",
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Buy
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3}
                            >
                              <b>$ {item.sale.price}</b>
                            </Typography>
                          </Button>
                        </div>
                        {item?.rent?.rent_price ? (
                          <>
                            {" "}
                            <div className={styles.verticalLine}></div>
                            <div className={styles.rent__text}>
                              <Button
                                sx={{
                                  marginTop: "15px",
                                  maxHeight: "5vh",
                                  color: "white",
                                  backgroundColor: "white",
                                  "&:hover": { backgroundColor: "white" },
                                }}
                              >
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Rent/Month
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                  mt={4}
                                  ml={-6.5}
                                  mr={3}
                                >
                                  <b>$ {item?.rent?.rent_price}</b>
                                </Typography>
                              </Button>
                            </div>
                          </>
                        ) : null}
                        {item?.print[0]?.print_price > 0 ? (<>
                          <div className={styles.verticalLine}></div>
                        <div className={styles.print__text}>
                          <Button
                            sx={{
                              marginTop: "15px",
                              maxHeight: "5vh",
                              color: "white",
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Print
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3.75}
                            >
                              {/* <b>$ 356</b> */}
                              <b>$ {item?.print[0]?.print_price}</b>
                            </Typography>
                          </Button>
                        </div></>) : null }
                       
                      </div>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          </Container>
          {/* <Button
            sx={{
              color: "white",
              backgroundColor: "black",
              width: "30%",
              marginLeft: "34%",
              padding: "1.25% 0%",
              borderRadius: "9px",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            <Typography fontSize="0.9rem">Request For Commission</Typography>
          </Button> */}
        </div>
      </div>
      <div className={styles.latestartworks}>
        <Grid container>
          <Grid item xs={12} sm={6} md={2}>
            <div className={styles.latestartworksheading}>
              <img className={styles.designImage} src={"/img/Design.png"} />
              <Typography fontSize="2rem" fontFamily="marcellus">
                Latest Artworks
              </Typography>
              <Typography fontSize="0.9rem" color="#636365">
                Discover the world through original <br /> paintings for sale
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
          <SliderCarousel
            data={
              sortedFilterValues
                ? sortedFilterValues.filter(
                    (val) => val.is_accepted === "Approved"
                    // && val.updatedAt > (new Date() - new Date().getDate() - 15)
                  )
                : []
            }
            type="latestartwork"
            formatNewCurrency={formatNewCurrency}
          />
          </Grid>
        </Grid>
      </div>
      <div className={styles.otherartist__div}>
        <div className={styles.title}>
          <div>
            <img className={styles.designimage} src={"/img/Design.png"} />
            <Typography
              fontFamily="marcellus"
              fontSize="2rem"
              ml={-3.5}
              mt={-1}
            >
              Other Artist
            </Typography>
          </div>
        </div>
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Grid container spacing={4}>
            {userartists.data &&
              userartists.data
                .filter((item) => {
                  return item.is_accepted === "Approved" && item?._id !== id
                })
                .map((item, i) =>
                  i >= 4 ? (
                    ""
                  ) : (
                    <Grid item xs={12} sm={6} md={3}>
                      <div className={styles.otherartistcards}>
                        <div className={styles.otherartistcardmain}>
                          <img
                            className={styles.otherartimg}
                            src={
                              item.backgroundImage
                                ?  item.backgroundImage
                                : "/img/artist_bg_alt.jpeg"
                            }
                          />
                          <div className={styles.otherartistimage__div}>
                            <img
                              className={styles.otherartistimg}
                              src={
                                item.image
                                  ?  item.image
                                  : "/img/artist_profile.png"
                              }
                            />
                          </div>
                          <div className={styles.otherartistnamerating}>
                            <Typography fontSize="0.95rem" fontWeight="600">
                              {item.artist_name}
                            </Typography>
                            <div className={styles.ratingdiv}>
                              {/* <img
                                src={"/img/Gold Star.png"}
                                className={styles.art_de_rating_img}
                              />
                              <Typography>0</Typography> */}
                            </div>
                          </div>
                          <Typography fontSize="0.85rem">
                            {item?.country}
                          </Typography>
                          <Button
                           onClick={() => handleRouteArtist(item._id)}
                            sx={{
                              color: "black",
                              border: "1px solid black",
                              width: "100%",
                            }}
                            size="small"
                          >
                           
                            Explore
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  )
                )}
          </Grid>
        )}
      </div>
    </div>
  );
}

export default ArtistDetails;
