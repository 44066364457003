import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const AuctionSlice = createSlice({
    name: "auctiondata",
    initialState,
    reducers: {
        setAuctionData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAuctionData } = AuctionSlice.actions;

export default AuctionSlice.reducer;
