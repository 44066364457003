import validator from "validator"
import isEmpty from "./isEmpty"

const isRichTextEmpty = (content) => {
  if (typeof content !== "string") return true
  const strippedContent = content.replace(/<[^>]*>/g, "").trim()
  return strippedContent === ""
}

export const validateAdminFaq = (faq) => {
  let faqErrors = {}

  if (validator.isEmpty(faq.question)) {
    faqErrors.question = "Question is required"
  }
  if (isRichTextEmpty(faq.answer)) {
    faqErrors.answer = "Answer is required"
  }
  if (validator.isEmpty(faq.faq_type)) {
    faqErrors.faq_type = "Faq type is required"
  }

  return {
    faqErrors,
    isValid: isEmpty(faqErrors),
  }
}
