import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const artisttypeSlice = createSlice({
  name: "artisttypedata",
  initialState,
  reducers: {
    setArtisttypedata: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setArtisttypedata } = artisttypeSlice.actions;

export const setArtisttype = (state) => state.setArtisttypedata;

export default artisttypeSlice.reducer;
