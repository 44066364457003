import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

export const recreateDataSlice = createSlice({
    name: "recreateRequest",
    initialState,
    reducers: {
        setRecreateData: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setRecreateData } = recreateDataSlice.actions;

export default recreateDataSlice.reducer;