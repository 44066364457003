import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material"
// import Divider from '@mui/material/Divider';
import React, { useState, useEffect } from "react"
import styles from "./Tables.module.css"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import profileSlice, {
  addData,
} from "../../../../../State/Features/Artist/profileSlice"
import homeImage from "../../../../../constant"
import CircularLoader from "../../../../../common/loader/Loader"
import moment from "moment"
import dateFormat from "../../../../../utils/dateFormat"

export default function Tables(props) {
  console.log(props, "props--1111")
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [delid, setDelid] = useState()

  const loader = useSelector((state) => state.loader)
  const [artistType, setArtistType] = useState("")
  const [selectedArtistType, setSelectedArtistType] = useState("")
  const [selectedArtistTypes, setSelectedArtistTypes] = useState({})
  const threedots = Boolean(anchorEl)
  const handleClick = (id, e) => {
    setDelid(id)
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handlePopup = (e) => {
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  const handleProfilepage = (id) => {
    const path = window.location.pathname
    if (path === "/artist") {
      navigate(`/artist/${id}`, { state: { active: props.activetab } })
    } else if (path === "/exhibitions") {
      navigate(`/exhibitions/${id}`)
    } else if (path === "/auction") {
      navigate(`/auction/${id}`)
    }
  }

  // const handleChange = (e) => {
  //   setArtistType(e.target.value)
  // }
  const handleDelete = (data) => {
    props.handlePopup(data) // Trigger the handlePopup
    if (props.setOpen) {
      props.setOpen(true) // Ensure modal state is updated
    }
  }
  // useEffect(() => {
  //   if (props.artist_type && props.artist.artist_type) {
  //     setSelectedArtistType(props.artist.artist_type)
  //   }
  // }, [props.artist_type])

  // const handleChange = (event) => {
  //   setSelectedArtistType(event.target.value)

  //   // Call the function to update the artist type if needed (e.g., API call)
  //   if (props.onArtistTypeChange) {
  //     props.onArtistTypeChange(event.target.value)
  //   }
  // }

  useEffect(() => {
    if (Array.isArray(props.data)) {
      // Check if props.data is an array
      const initialArtistTypes = {}
      props.data.forEach((artist) => {
        initialArtistTypes[artist._id] = artist.artist_type // Assuming artist_type is a string, modify if it's an array
      })
      setSelectedArtistTypes(initialArtistTypes)
    }
  }, [props.data])

  // Handle change of the dropdown value for each artist
  const handleArtistTypeChange = (event, artistId) => {
    const newValue = event.target.value

    // Update the state with the new value for the specific artist
    setSelectedArtistTypes((prev) => ({
      ...prev,
      [artistId]: newValue,
    }))

    // Call a function to update the artist type in the backend or handle it in the parent component
    // if (props.onArtistTypeChange) {
    //   props.onArtistTypeChange(artistId, newValue)
    // }
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props?.data?.map((row, i) => (
                  <TableRow
                    value={row.artist_name}
                    style={{ color: "#636365" }}
                    key={i}
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    {}
                    {props.activetab === "gallery_pending" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.gallery[0] && row.gallery[0]?.gallery_name}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      onClick={() => handleProfilepage(row._id)}
                      align="left"
                    >
                      {row?.artist_name}
                      {/* {row.first_name && row.first_name +" "+ row.last_name && row.last_name}  */}
                      {row?.first_name} {row?.last_name}
                      <span className={styles.title}>{row?.title}</span>
                      {row?.name}
                      {props.activetab === "enquiry"
                        ? row.enquiry_id
                          ? row.enquiry_id
                          : "---"
                        : ""}
                      {/* {dateFormat(row?.createdAt)} */}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.artist_name}
                    >
                      {row?.order_number}
                      {props.activetab === "gallery_pending"
                        ? row.phone
                          ? row.phone
                          : "---"
                        : ""}
                      {props.activetab === "pending" ? (
                        <>
                          <div>{row.email}</div> <div>{row.phone}</div>
                        </>
                      ) : (
                        ""
                      )}

                      {props.activetab === "request" ? (
                        <>
                          {/* <Select
                            value=""
                            // onChange={handleChange}
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artists Invited</MenuItem>
                            {row.artist_emails &&
                              row.artist_emails.map((val) => (
                                <MenuItem key={val._id}>{val}</MenuItem>
                              ))}
                          </Select> */}
                          {row.is_paid && row.is_paid === true
                            ? "Paid"
                            : "Free"}
                        </>
                      ) : (
                        row.start_date &&
                        moment(row.start_date).format("DD-MM-YYYY")
                      )}
                      {props.activetab === "enquiry"
                        ? row.customer_name
                          ? row.customer_name
                          : "---"
                        : ""}
                      {/* for artists in down */}
                      {/* {row.phone} */}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* {row.is_paid && row.is_paid === true ? "Paid" : ""} */}
                      {props.activetab === "pending"
                        ? row.createdAt
                          ? dayjs(row.createdAt).format("YYYY-MM-DD").toString()
                          : "----"
                        : ""}
                      {props.activetab === "past"
                        ? row.end_date &&
                          moment(row.end_date).format("DD-MM-YYYY")
                        : props.activetab === "gallery_pending"
                        ? ""
                        : props.activetab === "pending"
                        ? ""
                        : ""}
                      {props.activetab === "request"
                        ? row.end_date_registration &&
                          moment(row.end_date_registration).format("DD-MM-YYYY")
                        : "---"}
                      {/* {"$" + row?.price} */}
                      {props.activetab === "enquiry"
                        ? row.enquiry_type
                          ? row.enquiry_type
                          : "---"
                        : ""}
                      {props.activetab === "gallery_pending"
                        ? row.email
                          ? row.email
                          : "---"
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        // width:props.activetab==='enquiry'?'30%':'',
                        // width:'200',
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "past"
                        ? row.win_bid
                        : row.start_date &&
                          moment(row.start_date).format("DD-MM-YYYY")}
                      {props.activetab === "enquiry" ? (
                        <div className={styles.desctiption_enquiry}>
                          {row?.description ? row?.description : "---"}
                        </div>
                      ) : (
                        ""
                      )}
                      {props.activetab === "pending" ? (
                        <>
                          {row.artist[0]?.curator?.length > 0
                            ? row.artist?.map((cur, i) =>
                                cur.curator.map(
                                  (curname, i) => curname.curator_name
                                )
                              )
                            : "---"}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "enquiry"
                        ? row.assigned_to
                          ? row.assigned_to
                          : "---"
                        : ""}
                      {row?.payment_method}
                      {props.activetab === "pending" ? (
                        <>
                          <Select
                            value={selectedArtistTypes[row._id] || ""}
                            onChange={(event) =>
                              handleArtistTypeChange(event, row._id)
                            }
                            displayEmpty
                            // fullWidth
                            sx={{
                              background: "#f6f6f6",
                              ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                              "& .MuiInputBase-root": {
                                borderRadius: "6px",
                              },
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                              height: "40px",
                            }}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="">Artist Type</MenuItem>
                            {props.artistTypes &&
                              props.artistTypes.data.map((val) => (
                                <MenuItem key={val._id} value={val.name}>
                                  {val.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </>
                      ) : (
                        ""
                      )}

                      {props?.activetab === "past"
                        ? row?.total_bid
                        : row?.end_date &&
                          moment(row?.end_date)?.format("DD-MM-YYYY")}
                    </TableCell>
                    {/* <TableCell className={styles.table_column} align="left">{row.action}</TableCell> */}

                    {props.activetab === "enquiry" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          // color: '#636365'
                        }}
                        align="left"
                      >
                        <div
                          className={
                            row.is_open
                              ? styles.table_status_active
                              : styles.table_status_inactive
                          }
                        >
                          {row.is_open ? "Open" : "Closed"}
                        </div>
                      </TableCell>
                    ) : props.activetab === "pending" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Button
                          color={row.status ? "primary" : "primary"}
                          variant="contained"
                          onClick={() => {
                            const selectedType = selectedArtistTypes[row._id]
                            props.handleAcceptDecline(
                              row._id,
                              "Approved",
                              selectedType,
                              {
                                name: "Basic Subscription",
                                artist_id: row._id,
                                subscriptionId: "66a24113b46eb6ba162ec273",
                                subscription_tenure: "monthly",
                                payment_mode: "From_admin",
                                transactionId: "TXN123456789",
                                amount: "0",
                              }
                            )
                          }}
                          sx={{ width: "80px", mr: 2 }}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={() => {
                            const selectedType = selectedArtistTypes[row._id]
                            props.handleAcceptDecline(
                              row._id,
                              "Rejected",
                              selectedType
                            )
                          }}
                          color="primary"
                          variant="outlined"
                          sx={{ width: "80px", mr: 2 }}
                        >
                          Decline
                        </Button>
                        <Button
                          onClick={() => handleDelete(row)}
                          color="primary"
                          variant="outlined"
                          sx={{ width: "100px", mr: 0 }}
                        >
                          Assign Curator
                        </Button>
                      </TableCell>
                    ) : props.activetab === "gallery_pending" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ width: "80px", mr: 2 }}
                          onClick={() =>
                            props.handleAcceptDecline(row._id, "Approved")
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          color="primary"
                          variant="outlined"
                          sx={{ width: "80px", mr: 0 }}
                          onClick={() =>
                            props.handleAcceptDecline(row._id, "Rejected")
                          }
                        >
                          Decline
                        </Button>
                        {/* <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={
                              threedots ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={threedots ? "true" : undefined}
                          >
                            <img
                              src={"/img/Menu Icon.png"}
                              height="21.62px"
                              width="4.63px"
                            />
                          </IconButton>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={threedots}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              width: "150px",
                              overflow: "visible",
                              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                              filter:
                                "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                              mt: 2.5,
                              "& .MuiAvatar-root": {
                                width: "120px",
                                height: "132px",
                                ml: 0.5,
                                mr: 1,
                                // mt:5
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                // horizontal: 'center',
                                right: 62,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "center",
                            vertical: "right",
                          }}
                          anchorOrigin={{
                            horizontal: "left",
                            vertical: "center",
                          }}
                        >
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              lineHeight: "12px",
                              fontWeight: "400",
                              fontFamily: "DM Sans",
                            }}
                            onClick={() =>
                              props.handleRecommended(
                                row._id,
                                row.is_recommended
                              )
                            }
                          >
                            {row.is_recommended
                              ? "Not-Recommended"
                              : "Reconmmended"}
                          </MenuItem>
                          <Divider />
                          <MenuItem
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "16px",
                              lineHeight: "12px",
                              fontWeight: "400",
                              fontFamily: "DM Sans",
                            }}
                            onClick={() =>
                              props.handleBlockUnblock(row._id, row.is_blocked)
                            }
                          >
                            {row.is_blocked ? "Unblock" : "Block"}
                          </MenuItem>
                        </Menu> */}
                      </TableCell>
                    ) : props.activetab === "earning_gallery" ? (
                      <TableCell
                        sx={{
                          // fontFamily: "DM Sans",
                          // fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color:
                            row?.order_status === "Cancelled" ? "red" : "black",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row?.order_status}
                        {row.action}
                      </TableCell>
                    ) : props.activetab === "request" &&
                      row?.curator?.some(
                        (curator) => curator._id === props.userId
                      ) ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        <Button
                          color={row.status ? "primary" : "primary"}
                          onClick={() =>
                            props.acceptReject(row._id, "Approved")
                          }
                          variant="contained"
                          sx={{ width: "80px", mr: 2 }}
                        >
                          Accept
                        </Button>
                        <Button
                          color={row.status ? "primary" : "primary"}
                          onClick={() =>
                            props.acceptReject(row._id, "Rejected")
                          }
                          variant="outlined"
                          sx={{ width: "80px", mr: 0 }}
                        >
                          Decline
                        </Button>
                        {/* <IconButton onClick={() => props.handleDelete(row._id)}>
                          <img
                            src="/img/delete.png"
                            height="20px"
                            width="18px"
                            alt="img"
                          />
                        </IconButton> */}
                      </TableCell>
                    ) : props.activetab === "upcoming_gallery" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          width: "30%",
                          // color: '#636365'
                        }}
                        align="left"
                      >
                        <div className={styles.flex_div}>
                          {row.action}
                          {row.status ? (
                            <Button
                              variant="contained"
                              sx={{
                                width: "106px",
                                height: "34px",
                                borderRadius: "4px",
                              }}
                            >
                              Interested?
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                width: "106px",
                                height: "34px",
                                borderRadius: "4px",
                              }}
                            >
                              Interested
                            </Button>
                          )}
                        </div>
                      </TableCell>
                    ) : props.activetab === "request_gallery" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          // color: '#636365'
                        }}
                        align="left"
                      >
                        <div
                          className={
                            row.action
                              ? styles.table_status_active
                              : styles.table_status_inactive
                          }
                        >
                          {row.action ? "Pending" : "Rejected"}
                        </div>
                      </TableCell>
                    ) : props.activetab === "past" || "upcoming" ? (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.action}
                        {row.winner_buyer}
                      </TableCell>
                    ) : (
                      <></>
                    )}
                    {/* </Link> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
