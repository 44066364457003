import React from 'react'
import Commonnewsgallery from '../../../gallery/pages/news/common/Commonnewsgallery'
const access_roles = [
    {
        title: 'Newsletter',
    },
    {
        title: 'Notification',
    },
    {
        title: 'Offer',
    },
    {
        title: 'Events',
    },
    {
        title: 'Blogs',
    },
    {
        title: 'Customised Campaings',
    }
]
export default function Artistnews() {
  return (
    <>
        <Commonnewsgallery access_roles={access_roles} />
    </>
  )
}
