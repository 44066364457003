import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './Userinquiry.module.css';
import Fivecolumntable from '../Tables/Fivecolumntable';
import api from '../../../../../State/api/Api';
import TableForInquiry from '../Tables/TableForInquiry';


const exhibitionhead = [
   {
      id: 'artist_name ',
      label: 'Inquiry ID',
   },
   {
      id: 'mobile_number',
      label: 'Customer Name',
   },
   {
      id: 'email',
      label: 'Inquiry Type',
   },
   {
      id: 'assign_to',
      label: 'Description',
   },   
   {
      id: 'action',
      label: 'Status',
   },

]
const exhibitionData = [
   {
      id: 1,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: true
   },

   {
      id: 2,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: true
   },
   {
      id: 3,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: false
   },
   {
      id: 4,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: true
   },
   {
      id: 5,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: false
   },
   {
      id: 6,
      artist_name: '#123456 ',
      mobile_no: 'John David',
      email: 'Paintings',
      type: 'Emerging artist',
      action: false
   },   
]

export default function UserInquiry() {
   const [age, setAge] = React.useState(''); 
   const[sortedValue, setSortedValue] = useState(10)  
  const [inquiries, setInquiries] = useState([])

   const handleChange = (event) => {
      setAge(event.target.value);
   };   

    const handleFetchAllInquiries = async () => {
      try {
        const {data} = await api.applicationApi("api/enquiry/fetchEnquiries","POST",{
         "is_open":"Open", //Optional
         "limit":sortedValue
     }
     )
        setInquiries(data.data.enquiries)
      }catch(error) {
         console.log(error)
      }
    }
    useEffect(() => {
     handleFetchAllInquiries()
    },[sortedValue])
   return (
      <div className={styles.mains}>
         <div className={styles.components}>
            <div className={styles.table_header}>
               <FormControl>
                  <Select
                     value={sortedValue}
                     onChange={(e) => setSortedValue(e.target.value)}
                     displayEmpty
                     inputProps={{ 'aria-label': 'Without label' }}
                     sx={{ height: '44px', minWidth: '150px', color: '#636365' }}
                     className={styles.selects}
                  >
                     <MenuItem value="">
                        Sort by
                     </MenuItem>
                     <MenuItem value={10}>10</MenuItem>
                     <MenuItem value={20}>20</MenuItem>
                     <MenuItem value={30}>30</MenuItem>
                     <MenuItem value={40}>40</MenuItem>
                     <MenuItem value={50}>50</MenuItem>
                     <MenuItem value={60}>60</MenuItem>
                     <MenuItem value={70}>70</MenuItem>
                     <MenuItem value={80}>80</MenuItem>
                     <MenuItem value={90}>90</MenuItem>
                     <MenuItem value={100}>100</MenuItem>
                  </Select>
               </FormControl>
            </div>
            <TableForInquiry head={exhibitionhead} data={inquiries} />
         </div>
      </ div >
   )
}
