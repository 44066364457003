import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import Formsubmission from "../../../components/popups/formSubmit/Formsubmission";
import styles from "./Galleryapplyform.module.css";
import homeImage from "../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../components/Pages/Header/Header";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchCategoryData } from "../../../../State/api/userApi";
import { updateGalleryProfile } from "../../../../State/api/galleryApi";
import { fetchCategories } from "../../../../State/api/superadminApi";
import { Errornotify } from "../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import { validationForGalleryApplyform } from "../../../../validation/validationForGalleryApplyform";
import ReactQuill from "react-quill";

export default function Galleryapplyform({
  apply,
  setApply,
  handleSelectCheckbox,
  handleChangeApplyData,
  handleChangeSocialLinksGallery,
  handleChangeTextEditor,
  handleImageChangeMultipleGallery,
  handleRemoveImageGallery,
  selectedFilesGallery,
}) {
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const allcategory = useSelector((state) => state.allcategoryData.data);
  const apply_category_id =
    apply.category_id && apply.category_id.map((val) => val._id);

  // const handleChangeImage = (e) => {

  //   if (e.target.files && e.target.files[0]) {
  //     const img = e.target.files[0];
  //     const imgs = URL.createObjectURL(e.target.files[0]);
  //     setImage(e.target.files[0]);
  //     setSrc(imgs);

  //   }
  // };

  useEffect(() => {
    dispatch(fetchCategoryData({}));
    // if (
    //   apply.portfolio_file === "" ||
    //   apply.portfolio === "" ||
    //   apply.best_work === "" ||
    //   apply.exp === "" ||
    //   apply.degree_year === 0 ||
    //   apply.description.length === 0 ||
    //   apply.art_price_range.length === 0 ||
    //   apply.arts_solds_in_past_12_months === 0 ||
    //   apply.award === "" ||
    //   apply.other_URL === "" ||
    //   apply.category_id.length === 0
    // ) {
    //   // navigate("/artsmiley/gallery/fillquestion");
    // }
  }, []);

  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const checkboxdt = [
    {
      id: 1,
      label: "< $250",
    },
    {
      id: 2,
      label: "$250-$1000",
    },
    {
      id: 1,
      label: "$1000-$2500",
    },
    {
      id: 1,
      label: "$2500-5000",
    },
    {
      id: 1,
      label: "$5000-10000",
    },
    {
      id: 1,
      label: ">$10000",
    },
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];
  const [galleryError, setGalleryError] = useState({});
  console.log(galleryError, "galleryError");
  const [othersReasonCategory, setOthersReasonCategory] = useState(
    sessionStorage.getItem("othersReasonCategory") || ""
  );

  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

  const linkedinRegex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;

  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

  const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;

  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;

  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);

      if (name === "portfolio_file") {
        const validTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]; // pdf and word doc types

        // Check file size (1MB = 1048576 bytes)
        const maxSize = 2 * 1048576; // 2MB

        if (!validTypes.includes(img.type)) {
          Errornotify("Please upload a PDF or Word document.");
          e.target.value = ""; // Clear the input
        } else if (img.size > maxSize) {
          Errornotify("File size should be less than 2 MB.");
          e.target.value = ""; // Clear the input
        } else {
          setApply({ ...apply, portfolio_file: img });
        }
      }

      if (name === "best_work") {
        if (img.type !== "image/png" && img.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid img type
        } else {
          setApply({ ...apply, best_work: img });
          // setImageBestWorks(img);
        }
      }
      setImage(e.target.files[0]);
    }
  };

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    const updatedCategoryId = [...apply.category_id];

    if (checked) {
      // Add the item if it is checked and not already in the list
      if (!updatedCategoryId.some((cat) => cat._id === value)) {
        updatedCategoryId.push({ _id: value });
      }
    } else {
      // Remove the item if it is unchecked
      const index = updatedCategoryId.findIndex((cat) => cat._id === value);
      if (index > -1) {
        updatedCategoryId.splice(index, 1);
      }
    }

    // Update state
    setApply((prevState) => ({
      ...prevState,
      category_id: updatedCategoryId,
    }));
  };

  const handleChangeArtPriceRange = (event) => {
    const { value, checked } = event.target;
    const updatedArtPriceRange = [...apply.art_price_range];

    if (checked) {
      // Add the price range if it is checked and not already in the list
      if (!updatedArtPriceRange.includes(value)) {
        updatedArtPriceRange.push(value);
      }
    } else {
      // Remove the price range if it is unchecked
      const index = updatedArtPriceRange.indexOf(value);
      if (index > -1) {
        updatedArtPriceRange.splice(index, 1);
      }
    }

    // Update state
    setApply((prevState) => ({
      ...prevState,
      art_price_range: updatedArtPriceRange,
    }));
  };

  const handleKeywordChangeRemoveCategory = () => {
    sessionStorage.removeItem("othersReasonCategory"); // Remove first from sessionStorage
    setOthersReasonCategory(""); // Update state after removing
  };

  const renderPhotosGallery = (source) => {
    return source.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => handleRemoveImageGallery(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const handleChangeKeywordCategory = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change

    setOthersReasonCategory(value);
    sessionStorage.setItem("othersReasonCategory", value);
  };
  // const handleKeywordChangeCategory = (event) => {
  //   if (event.key === "Enter" && othersReasonCategory.trim()) {
  //     event.preventDefault();
  //     const newKeyword = othersReasonCategory.trim();

  //     if (othersReasonArrayCategory.length > 0) {
  //       // Update the existing category with the new one
  //       setOtherReasonArrayCategory([newKeyword]);
  //     } else {
  //       // Add the new category for the first time
  //       setOtherReasonArrayCategory([newKeyword]);
  //     }

  //     // Clear the input field
  //     setOthersReasonCategory("");
  //   }
  // };
  return (
    <>
      <Header />
      <Navbar />
      <div className={styles.mains}>
        <div className={styles.components}>
          <h1>Join Art Smiley's community of Reputed Galleries</h1>
          <div className={styles.upload_img}>
            <div className={styles.input_label}>
              Please upload portfolio of your gallery in word document or pdf.*
            </div>
            <div className={styles.preview_img}>
              {apply.portfolio_file === "" ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="input"
                    style={{ textAlign: "center" }}
                    onClick={handleChangeImage}
                  >
                    <input
                      name="portfolio_file"
                      onChange={handleChangeImage}
                      type="file"
                      id="portfolio_file"
                      hidden
                      accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <label htmlFor="portfolio_file" className={styles.label}>
                      <img
                        name="portfolio_file"
                        src={"/img/uploadimg.png"}
                        width="46px"
                        height="43px"
                        alt="img"
                      />
                      <p>Upload Your Portfolio File</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.img_box}>
                    <div
                      htmlFor="input"
                      style={{ textAlign: "center" }}
                      onClick={handleChangeImage}
                    >
                      <input
                        name="portfolio_file"
                        onChange={handleChangeImage}
                        type="file"
                        id="portfolio_file"
                        hidden
                        accept=".pdf"
                      />
                      <label htmlFor="portfolio_file" className={styles.label}>
                        <img
                          name="portfolio_file"
                          src={"/img/uploadimg.png"}
                          width="46px"
                          height="43px"
                          alt="img"
                        />
                        <p>{apply?.portfolio_file?.name}</p>
                      </label>
                    </div>
                  </div>
                </>
              )}

              {/* <input
                                    onChange={handleChangeImage}
                                    type="file"
                                    id='input'
                                    hidden
                                /> */}
            </div>
            {/* <label htmlFor='input' className='labels' onClick={handleChangeImage}>Upload</label> */}
            {/* <input type='file' className={styles.input_file} /> */}
            <div className={styles.file_div}></div>
          </div>
          {galleryError?.portfolio_file ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {galleryError?.portfolio_file}
            </span>
          ) : null}
          <div className={styles.input_div} style={{padding:'15px 0px'}} >
            <label className={styles.input_label}>About Gallery*</label>
            <ReactQuill
              className="reactQuilStle"
              style={{
                height: "120px",
                textAlign: "left",
                marginBottom: "45px",
              }}
              value={apply.description}
              onChange={handleChangeTextEditor("description")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
           
            {galleryError?.description ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px", }}
              >
                {galleryError?.description}
              </span>
            ) : null}
          </div>
          <div className={styles.input_div}>
            <label className={styles.input_label}>
              Please provide details of prestigious exhibitions, awards/prizes,
              commissions, grants, recognitions etc*
            </label>
            <ReactQuill
              className="reactQuilStle"
              style={{
                height: "120px",
                textAlign: "left",
                marginBottom: "45px",
              }}
               value={apply.award}
              onChange={handleChangeTextEditor("award")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
            {galleryError?.award ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.award}
              </span>
            ) : null}
          </div>
          <div className={styles.input_div} style={{padding:'15px 0px'}}>
            <label className={styles.input_label}>
              Please provide us with the name, the year and the place of each
              experience.
            </label>
            <ReactQuill
              className="reactQuilStle"
              style={{
                height: "120px",
                textAlign: "left",
                marginBottom: "45px",
              }}
              value={apply.degree_year}
              onChange={handleChangeTextEditor("degree_year")}
              theme="snow"
              modules={modules}
              formats={formats}
            />
            {/* <TextField
              className={styles.input_top}
              name="degree_year"
              value={apply.degree_year}
              onChange={handleChangeApplyData}
              variant="standard"
              sx={{
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #665D6F",
                    opacity: 0.5,
                  },
                  // '&:hover fieldset': {
                  //     borderColor: 'yellow',
                  // },
                  // '&.Mui-focused fieldset': {
                  //     borderColor: 'green',
                  // },
                },
              }}
              fullWidth
            /> */}
            {galleryError?.degree_year ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.degree_year}
              </span>
            ) : null}
          </div>
          <div className={styles.input_div}>
            <label className={styles.input_label}>
              Upload Your Best Artists' Works*   <span style={{ fontSize: "15px" }}>
                      (Upload Limit: 2–10 artworks, file format: PNG/JPEG, size: 2MB)
                    </span>
            </label>{" "}
            {apply.best_work === "" ? (
              <div className={styles.img_box}>
                <div
                  htmlFor="input"
                  style={{ textAlign: "center" }}
                  onClick={handleChangeImage}
                >
                  <input
                    name="best_work"
                    onChange={handleChangeImage}
                    type="file"
                    id="best_work"
                    hidden
                    accept="image/*"
                  />
                  <label htmlFor="best_work" className={styles.label}>
                    <img
                      name="best_work"
                      src={"/img/uploadimg.png"}
                      width="46px"
                      height="43px"
                      alt="img"
                    />
                    <p>Upload your Best Work</p>
                  </label>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.img_box}>
                  <div
                    htmlFor="input"
                    style={{ textAlign: "center" }}
                  
                  >
                    <input
                      name="best_work"
                      onChange={handleImageChangeMultipleGallery}
                      type="file"
                      id="best_work"
                      hidden
                      accept="image/*"
                    />
                    <label htmlFor="best_work" className={styles.label}>
                      <img
                        name="best_work"
                        src={"/img/uploadimg.png"}
                        width="46px"
                        height="43px"
                        alt="img"
                      />
                      <p>Upload Your Best Artists' Works</p>
                    </label>
                  </div>
                </div>
              </>
            )}
              <div className={styles.result}>
                  {renderPhotosGallery(selectedFilesGallery)}
                </div>
            {galleryError?.best_work ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.best_work}
              </span>
            ) : null}
          </div>

          <div className={styles.input_div}>
            <label className={styles.input_label}>
              If your gallery is represented by another online platform, Please provide
              URL link:
            </label>
            <TextField
              className={styles.input_top}
              name="other_URL"
              value={apply.other_URL}
              onChange={handleChangeApplyData}
              variant="standard"
              sx={{
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #665D6F",
                    opacity: 0.5,
                  },
                  // '&:hover fieldset': {
                  //     borderColor: 'yellow',
                  // },
                  // '&.Mui-focused fieldset': {
                  //     borderColor: 'green',
                  // },
                },
              }}
              fullWidth
            />
            {galleryError?.other_URL ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.other_URL}
              </span>
            ) : null}
          </div>
          <div className={styles.input_div}>
            <label className={styles.input_label}>
              Please provide the link to your gallery online portfolio:
            </label>

            <TextField
              className={styles.input_top}
              name="portfolio"
              value={apply.portfolio}
              onChange={handleChangeApplyData}
              variant="standard"
              sx={{
                mt: 1,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #665D6F",
                    opacity: 0.5,
                  },
                  // '&:hover fieldset': {
                  //     borderColor: 'yellow',
                  // },
                  // '&.Mui-focused fieldset': {
                  //     borderColor: 'green',
                  // },
                },
              }}
              fullWidth
            />
            {galleryError?.portfolio ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.portfolio}
              </span>
            ) : null}
          </div>

          <div className={styles.multi_inputs}>
            <lable className={styles.input_label}>
              Please provide us the links of your gallery social media platform here:
            </lable>
            <br />
            {/* {datamulti &&
        datamulti.map((item) => ( */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  onClick={handleChangeApplyData}
                  className={styles.input_multi}
                  placeholder="www.linkedin.com/in/example"
                  name="linked_in"
                  fullWidth
                  error={
                    apply.social_links.linked_in === ""
                      ? false
                      : !apply.social_links.linked_in.match(linkedinRegex)
                  }
                  color={
                    apply.social_links.linked_in.length === 0 ? "" : "success"
                  }
                  onChange={handleChangeSocialLinksGallery}
                  value={apply.social_links.linked_in}
                  variant="standard"
                  sx={{
                    my: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #665D6F",
                        opacity: 0.5,
                      },
                    },
                  }}
                />
                {galleryError?.linked_in && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {galleryError.linked_in}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  onClick={handleChangeApplyData}
                  className={styles.input_multi}
                  onChange={handleChangeSocialLinksGallery}
                  placeholder="www.facebook.com/example"
                  name="facebook"
                  fullWidth
                  error={
                    apply.social_links.facebook === ""
                      ? false
                      : !apply.social_links.facebook.match(facebookRegex)
                  }
                  color={apply.social_links.facebook === "" ? "" : "success"}
                  value={apply.social_links.facebook}
                  variant="standard"
                  sx={{
                    my: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #665D6F",
                        opacity: 0.5,
                      },
                    },
                  }}
                />
                {galleryError?.facebook && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {galleryError.facebook}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  onClick={handleChangeApplyData}
                  className={styles.input_multi}
                  error={
                    apply.social_links.instagram === ""
                      ? false
                      : !apply.social_links.instagram.match(instagramRegex)
                  }
                  color={apply.social_links.instagram === "" ? "" : "success"}
                  placeholder="www.instagram.com/example"
                  name="instagram"
                  fullWidth
                  onChange={handleChangeSocialLinksGallery}
                  value={apply.social_links.instagram}
                  variant="standard"
                  sx={{
                    my: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #665D6F",
                        opacity: 0.5,
                      },
                    },
                  }}
                />
                {galleryError?.instagram && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {galleryError.instagram}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  onClick={handleChangeApplyData}
                  className={styles.input_multi}
                  placeholder="www.x.com/example"
                  name="twitter"
                  fullWidth
                  error={
                    apply.social_links.twitter === ""
                      ? false
                      : !apply.social_links.twitter.match(twitterRegex)
                  }
                  color={apply.social_links.twitter === "" ? "" : "success"}
                  onChange={handleChangeSocialLinksGallery}
                  value={apply.social_links.twitter}
                  variant="standard"
                  sx={{
                    my: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #665D6F",
                        opacity: 0.5,
                      },
                    },
                  }}
                />
                {galleryError?.twitter && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {galleryError.twitter}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  onClick={handleChangeApplyData}
                  className={styles.input_multi}
                  placeholder="www.youtube.com/example"
                  name="youtube"
                  fullWidth
                  error={
                    apply.social_links.youtube === ""
                      ? false
                      : !apply.social_links.youtube.match(youtubeRegex)
                  }
                  color={apply.social_links.youtube === "" ? "" : "success"}
                  onChange={handleChangeSocialLinksGallery}
                  value={apply.social_links.youtube}
                  variant="standard"
                  sx={{
                    my: 1,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1px solid #665D6F",
                        opacity: 0.5,
                      },
                    },
                  }}
                />
                {galleryError?.youtube && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {galleryError.youtube}
                  </div>
                )}
              </Grid>
            </Grid>
            {/* ))} */}
          </div>
          <label className={styles.input_label}>
            Please select the main categories your artists work with*
          </label>
          <div className={styles.main_divs_category}>
            <br />

            {allcategory &&
               [...allcategory?.allcategories]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="category_id"
                  key={item._id}
                  sx={{ margin: "0px", padding: "0px" }}
                >
                  <FormControlLabel
                    key={item._id}
                    sx={{
                      backgroundColor: "#f6f6f6",
                      minWidth: "180px",
                      maxWidth: "max-content",
                      borderRadius: "6px",
                      px: 1,
                    }}
                    value={item._id}
                    checked={apply?.category_id.some(
                      (cat) => cat._id === item._id
                    )}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        sessionStorage.removeItem("othersReasonCategory");
                        handleKeywordChangeRemoveCategory();
                      }
                    }}
                    name="category_id"
                    control={<Checkbox onChange={handleCategoryChange} />}
                    label={item.name}
                  />
                </RadioGroup>

                // </div>
              ))}
           
          </div>
          {galleryError?.category_id === "At least one category is required" ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {galleryError?.category_id}
            </span>
          ) : null}
          {apply?.category_id.some(
            (cat) => cat._id === "67334c2511d9938babd097f3"
          ) ? (
            // Your conditional rendering here

            <TextField
              InputProps={{ style: { fontSize: 25 } }}
              sx={{
                lineHeight: "34px",
                fontSize: "25px",
                marginBottom: "10px",
              }}
              variant="standard"
              className={styles.input_text}
              value={othersReasonCategory}
              fullWidth
              focused
              startAdornment={<InputAdornment position="end">↵</InputAdornment>}
              // onKeyDown={handleKeywordChangeCategory}
              onChange={handleChangeKeywordCategory}
              placeholder="Please specify"
              type="text"
            />
          ) : null}
     {galleryError?.category_id === "Other category is required" ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {galleryError?.category_id}
            </span>
          ) : null}
          <div className={styles.checkbox_div_container}>
            <label className={styles.input_label}>
              Please select your artwork selling price range:*
            </label>
            <br />
            {checkboxdt &&
              checkboxdt?.map((item) => (
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="art_price_range"
                  value={apply.art_price_range}
                  key={item.label}
                  onClick={handleSelectCheckbox}
                >
                  <FormControlLabel
                    name="art_price_range"
                    value={item.label}
                    control={<Checkbox onChange={handleChangeArtPriceRange} />}
                    label={item.label}
                    checked={apply.art_price_range.includes(item.label)}
                    sx={{ px: 2 }}
                  />
                </RadioGroup>
              ))}
          </div>
          {galleryError?.art_price_range ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {galleryError?.art_price_range}
            </span>
          ) : null}
          <div className={styles.input_div}>
            <label className={styles.input_label}>
              How many artworks have you sold in the past 12 months? *
            </label>
            <TextField
              className={styles.input_top}
              type="text"
              name="arts_solds_in_past_12_months"
              value={apply.arts_solds_in_past_12_months}
              onChange={handleChangeApplyData}
              variant="standard"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #665D6F",
                    opacity: 0.5,
                  },
                },
              }}
              fullWidth
            />
            {galleryError?.arts_solds_in_past_12_months ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {galleryError?.arts_solds_in_past_12_months}
              </span>
            ) : null}
          </div>
          {/* <div className={styles.checkbox_div_container}>
            <label className={styles.input_label}>
              I confirm that the information given in this form is true,
              complete and accurate.
            </label>
            <div>
           
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ display: "flex", flexDirection: "row" }}
                className={styles.radio_button}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
           
            </div>
          </div> */}
          <div className={styles.submit_btn}>
            <Button
              onClick={() => {
                const { applyForm, isValidGalleryApplyform } =
                  validationForGalleryApplyform(
                    apply.portfolio_file,
                    apply.description,
                    apply.category_id,
                    apply.art_price_range,
                    apply.arts_solds_in_past_12_months,

                    apply.portfolio,
                    apply.other_URL,
                    apply.online_art_gallary_URL,
                    apply.social_links,
                    apply.award,
                    apply.degree_year,
                    apply.best_work,
                    othersReasonCategory,
                    selectedFilesGallery
                  );
                if (isValidGalleryApplyform) {
                  navigate("/artsmiley/gallery/personaldetails");
                } else {
                  setGalleryError(applyForm);
                }
              }}
              //  onClick={() => navigate("/artsmiley/gallery/personaldetails")}
              variant="contained"
              fullWidth
              type="submit"
              sx={{ height: "54px", width: "156px", borderRadius: "10px" }}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
