import isEmpty from "./isEmpty";

const validationForThirdFormArt = (printType,uploadArt, type) => {
  const errors = {};

  if (type.sale) {
    if (!uploadArt.sale.qty || isNaN(Number(uploadArt.sale.qty))) {
      errors.saleQty = "Quantity in sale is required and must be a number";
    }
    
    if (!uploadArt.sale.price || isNaN(Number(uploadArt.sale.price))) {
      errors.salePrice = "Sale price is required and must be a number";
    }
    if(!uploadArt.sale.sale_weight || isNaN(Number(uploadArt.sale.sale_weight))) {
      errors.saleWeight = "Sale weight is required and must be a number";
    }
    if (uploadArt.is_hanged === "No") {
      if (Array.isArray(uploadArt.sale.rolled) && uploadArt.sale.rolled.length > 0) {
        const rolled = uploadArt.sale.rolled[0];
        if (!rolled.rolled_size || rolled.rolled_size.trim() === "") {
          errors.saleRolledSize = "Please select your tube size";
        }
      } else {
        errors.saleRolled = "Rolled entry is required when the artwork is hanged";
      }
    }
  }

  
  // Conditionally validate rolled_size based on is_hanged



  // Conditionally validate rent object if `type.rent` is true
  if (type.rent) {
    if (uploadArt.rent) {
      if (!uploadArt.rent.rent_price || isNaN(Number(uploadArt.rent.rent_price))) {
        errors.rentPrice = "Rent price is required and must be a number";
      }
  
      if (!uploadArt.rent.time || uploadArt.rent.time.trim() === "") {
        errors.rentTime = "Rent time is required";
      }
      if(!uploadArt.rent.rent_weight || isNaN(Number(uploadArt.rent.rent_weight))) {
        errors.rentWeight = "Rent weight is required and must be a number";
      }
    }
  }

  // Conditionally validate print array if `type.print` is true
  if (type.print) {
    if (printType === "printbyme") {
      // Validate print array
      if (!Array.isArray(uploadArt.print) || uploadArt.print.length === 0) {
        errors.print = "At least one print entry is required";
      } else {
        uploadArt.print.forEach((print, index) => {
          if (!print.edition || print.edition.trim() === "") {
            errors[`printEdition${index}`] = "Print edition is required";
          }
          if (!print.qty || isNaN(Number(print.qty))) {
            errors[`printQty${index}`] = "Print quantity is required and must be a number";
          }
          if (!print.print_price || isNaN(Number(print.print_price))) {
            errors[`printPrice${index}`] = "Print price is required and must be a number";
          }
          if (!print.material || Object.keys(print.material).length === 0) {
            errors[`printMaterial${index}`] = "Print material is required";
          }
          if (!print.size || Object.keys(print.size).length === 0) {
            errors[`printSize${index}`] = "Print size is required";
          }
          if (!print.print_weight || Object.keys(print.print_weight).length === 0) {
            errors[`printWeight${index}`] = "Print weight is required and must be a number";
          }
        });
      
    } 
    }
  }

  return {
    thirdFormErrors: errors,
    isValidThirdForm: isEmpty(errors),
  };
};

export default validationForThirdFormArt;
