import React from "react";
import Commonhome from "../../../../../curator/pages/applyForm/common/Commonhome";

const data = {
  heading: "Join the Art Smiley Affiliate Program and Grow with Us!",
  subheading:
    "Ensure that all required details are provided clearly and completely. Applications with full information will have a stronger likelihood of selection!",
};
export default function Questionhomeaffliate() {
  return (
    <div>
      <Commonhome data={data} />
    </div>
  );
}
