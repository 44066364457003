import { Errornotify, Successnotify } from "../../common/alert/Alert";
import { setLoader } from "../Features/common/loaderSlice";
import { removeUser } from "../Features/common/userSlice";
import api from "./Api";

export function updateGalleryProfile(data,navigate) {
  return async function fetchedirGalleryProfile(dispatch) {
    dispatch(setLoader(true));
    try {
      const formData = new FormData();
  

    formData.append("first_name", data.first_name);
formData.append("last_name", data.last_name);
formData.append("email", data.email);
formData.append("phone", data.phone);
formData.append("title", data.title);
formData.append("gallery_name", data.gallery_name);
formData.append("gallery_address", data.gallery_address);
formData.append("addresses[0][country]", data.country);

formData.append("addresses[0][address_type]", "Shipping Address")
formData.append("addresses[0][is_default]", true)
formData.append("gallery_established", data.gallery_established);
formData.append("language", data.language);
formData.append("registration_document", data.registration_document);
formData.append("profile_image", data.profile_image);
formData.append("cover_image", data.cover_image);
formData.append("completion", data.completion);


      data.category_id.forEach(function (element,index) {
       formData.append(`category_id[${index}][_id]`,element._id)
      })
      data.art_price_range.forEach((element,index) => {
        formData.append(`art_price_range[${index}]`,element)
      })
      formData.append("instagram",data.social_links.instagram)
      formData.append("facebook",data.social_links.facebook)
      formData.append("linkedin",data.social_links.linkedin)
      formData.append("twitter",data.social_links.twitter)
      formData.append("youtube",data.social_links.youtube)
      formData.append("description", data.description);
      formData.append("award", data.award);
      // formData.append("completion",data.completion)
      formData.append("degree_year", data.degree_year);
      formData.append("best_work", data.best_work);
      formData.append("exp", data.exp);
      formData.append("other_URL", data.other_URL);
      formData.append("online_portfolio", data.portfolio)
      formData.append("portfolio", data.portfolio_file)
      formData.append("arts_solds_in_past_12_months",data.arts_solds_in_past_12_months)

      const apiResponse = await api.multipartApi("api/gallery/updateGalleryProfile",
        "POST",
        formData
      );
      if(apiResponse.status === 200) {
        Successnotify(apiResponse.data.message)
        setTimeout(() => {
            navigate("/artsmiley/gallery/form-submission")
           },500)
           dispatch(setLoader(false))
      } else {
        if(apiResponse.status === 401) { 
            dispatch(removeUser({
                signed: false,
                type: "",
                data: ""
            }))
            Errornotify(apiResponse.data.message);
        }else {
            dispatch(setLoader(false));
            Errornotify(apiResponse.data.message);
        }
      }
    } catch (error) {
      console.log("catch render:", error);
      dispatch(setLoader(false));
    }
  };
}
