import React, { useState, useEffect } from "react"
import styles from "./heading.module.css"
import { useParams, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  editHeadingmenu,
  deleteHeadingMenu,
  fetchHeadingByID,
} from "../../../../State/api/superadminApi.js"
import { Button, IconButton, TextField } from "@mui/material"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import CircularLoader from "../../../../common/loader/Loader"

const HeadingMenu = () => {
  const location = useLocation()
  const { heading } = location.state || {}
  console.log(heading, "heading--meu")
  const { id } = useParams()
  const dispatch = useDispatch()
  const [name, setName] = useState({ name: "", _id: "" })
  const [menu, setMenu] = useState({ add: false, edit: false })
  const headingByMenu = useSelector((state) => state.headingByMenu.data)
  console.log(headingByMenu, "heading by menu000--")
  const loader = useSelector((state) => state.loader)

  const handleEdits = (type, menuName, _id) => {
    if (type === "edit") {
      setMenu({ ...menu, edit: true })
      setName({ name: menuName, _id })
    } else {
      setMenu({ ...menu, add: true })
    }
  }

  const handleSubmit = (id) => {
    dispatch(editHeadingmenu(id, name))
  }

  const handleDelete = (id) => {
    dispatch(deleteHeadingMenu(id)).then(() => {
      fetchHeading()
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setName((prevName) => ({ ...prevName, [name]: value }))
  }

  const subMenuByMenuIdData = useSelector(
    (state) => state.MenuBySubMenuData.data
  )

  // const fetchMenuData = () => {
  //   dispatch(fetchSubmenuData(id))
  // }

  // useEffect(() => {
  //   fetchMenuData()
  // }, [])
  const fetchHeading = () => {
    dispatch(fetchHeadingByID(id))
  }

  React.useEffect(() => {
    fetchHeading()
  }, [id])

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <h3>Menu Name: {heading ? heading?.name : ""}</h3>
      <hr />
      <div className={styles.detail_main}>
        <div className={styles.detail}>
          <h3 className={styles.title}>Heading</h3>
          {loader.loader ? (
            <CircularLoader size={60} />
          ) : headingByMenu && headingByMenu.headings.length === 0 ? (
            "------"
          ) : (
            headingByMenu &&
            headingByMenu?.headings?.map((val) => (
              <p className={styles.detail_para} key={val._id}>
                {menu.edit && val._id === name._id ? (
                  <>
                    <TextField
                      value={name.name}
                      name="name"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />

                    <Button
                      variant=""
                      sx={{ m: "auto" }}
                      onClick={() => handleSubmit(val._id)}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    {val.name}
                    <IconButton
                      onClick={() => handleEdits("edit", val.name, val._id)}
                      sx={{ ml: 3 }}
                    >
                      <img
                        src="/img/Edit.png"
                        width="20px"
                        height="20px"
                        alt="Edit"
                      />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(val._id)}>
                      <img
                        src="/img/delete.png"
                        width="20px"
                        height="20px"
                        alt="Delete"
                      />
                    </IconButton>
                  </>
                )}
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default HeadingMenu
