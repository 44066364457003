import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    acceptDeclineExshibitionArt,
    fetchExhibitionArtData,
} from "../../../../../State/api/superadminApi";
import CommonAcceptreject from "../../Common/artdetail/acceptReject/Acceptreject";
import Cancel from "../../Popupdelete/Cancel/Cancel";
import styles from "./Acceptreject.module.css";

const data = [
    {
        main_title: "Art Of Space",
        content: "Shedule Pickup pending",
        img: "/img/Image 1.png",
        more_detail: [
            {
                title: "Price",
                content: "$45,000",
            },
            {
                title: "Materials",
                content: "Bronze, Cardboard, Carbon FIber,Ceramic",
            },
            {
                title: "Category",
                content: "Photography",
            },
            {
                title: "Dimensions",
                content: "20 W * 20 H * 50 D in",
            },
        ],
    },
    {
        main_title: "The Wonder of India",
        content: "Returned",
        img: "/img/Image 2.png",
        more_detail: [
            {
                title: "Price",
                content: "$45,000",
            },
            {
                title: "Materials",
                content: "Bronze, Cardboard, Carbon FIber,Ceramic",
            },
            {
                title: "Category",
                content: "Photography",
            },
            {
                title: "Dimensions",
                content: "20 W * 20 H * 50 D in",
            },
        ],
        action: "Remark* kjdnkoiue uoief iue f",
    },
    {
        main_title: "Way Of Seeing",
        content: "Shedule Pickup pending",
        img: "/img/Image 3.png",
        more_detail: [
            {
                title: "Price",
                content: "$45,000",
            },
            {
                title: "Materials",
                content: "Bronze, Cardboard, Carbon FIber,Ceramic",
            },
            {
                title: "Category",
                content: "Photography",
            },
            {
                title: "Dimensions",
                content: "20 W * 20 H * 50 D in",
            },
        ],
        action: "",
    },
];
const detaildata = [
    {
        title: "Exshibition Name",
        content: "Art of Space",
    },
    {
        title: "No. of Items",
        content: "06",
    },
];
export default function Acceptreject() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const exhibitionAllArt = useSelector((state) => state.exhibitionAllArt.data);
    const fetchData = () => {
        dispatch(fetchExhibitionArtData(id));
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleAcceptRejejectArt = (ids, data) => {
        dispatch(acceptDeclineExshibitionArt(ids, data, fetchData));
    };
    const [open, setOpen] = useState(false);
    return (
        <div className={styles.mains}>
            {open ? <Cancel open={open} setOpen={setOpen} /> : ""}
            <CommonAcceptreject data={exhibitionAllArt} handleAcceptRejejectArt={handleAcceptRejejectArt} />
        </div>
    );
}
