function capitalizeWords(input) {
    if (!input || typeof input !== 'string') return '';
    
    return input
      .split(' ') // Split the string into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter
      .join(' '); // Join the words back into a string
  }


  export default capitalizeWords