import React from "react"
import Commonsidebar from "../../components/Pages/List/Common/sideBar/Commonsidebar"

const sidebarRoutes = [
  {
    name: "AFFILIATE",
    path: "/dashboard",
  },
  {
    name: "MY PROFILE",
    path: "/myprofile",
  },
  // {
  //   name: "ARTS",
  //   path: "/arts",
  // },
  {
    name: "MARKETING MATERIAL",
    path: "/marketing",
  },
  {
    name: "EARNINGS",
    path: "/earnings",
  },
]
export default function Sidebaraffiliate() {
  return (
    <>
      <Commonsidebar sidebarRoutes={sidebarRoutes} />
    </>
  )
}
