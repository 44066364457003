import { Button } from '@mui/material';
import React from 'react';
import Commonhome from '../common/Commonhome';
import styles from './Applyhome.module.css';

const data = {
    heading:"Become a Key Curator in Art Smiley’s Network!",
    subheading:"Complete all sections with as much clarity and detail as possible. Submissions with the fullest information will have a stronger chance for selection."
}
export default function Applyhome() {
    return (
        <>
        <Commonhome data={data} />
        </>
    )
}
