import * as React from 'react';
import styles from './Tabs.module.css';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Menu from '../../Menu/Menu';
import { useEffect, useState } from 'react';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const label = [
    {
        label: 'Paintings',
    },
    {
        label: 'Prints',
    },
    {
        label: 'Photography',
    },
    {
        label: 'Drawing',
    },
    {
        label: 'Craft',
    },
    {
        label: 'Sculpture',
    },
]

export default function Tabss(props) {
    const [size, setSize] = useState(window.innerWidth)
    useEffect(() => {
        setSize(window.innerWidth)
    }, [window.innerWidth])
    
    const handleChange = (e, newValue) => {
        props.setCategory_id(e.target.id)
        console.log('clicke tab', e.target.id, props.category_id)
    };
    return (
        <div className={styles.mains}>
            <div className={styles.components}>
                <Box
                    sx={{
                        display: 'fex', '.MuiTabs-indicator': {
                            left: 0, width: '4px', height: '48px', borderRadius: '20px'
                        },
                    }}
                >
                    <Tabs
                        orientation={size <= 913 ? "horizontal" : 'vertical'}
                        variant={size <= 913 ? "scrollable" : ''}
                        value={props.category_id}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ maxWidth: '100%', overflowX: 'scroll', display: 'flex', justifyContent: 'flex-start' }}
                    >
                        {props.label && props.label.map((item, i) => (
                            <Tab key={i}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    fontFamily: 'DM Sans',
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    lineHeight: '26px', my: 0.6, ml: 2
                                }}
                                variant='contained'
                                id={item._id}
                                value={item._id}
                                className={styles.tab_label} label={item.name} />
                        ))}
                    </Tabs>
                    {/* <TabPanel value={value} index={0}>
d
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        Item Four
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        Item Five
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        Item Six
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel> */}
                </Box>
            </div>
        </div>
    );
}
