import * as React from 'react';
import styles from './Addpaintingexshibition.module.css';
import styless from '../../../../components/Pages/List/Category/Category.module.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import homeImage from '../../../../constant';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '6px',
    p: 2,
};
const data = [
    {
        id: 1,
        src: '/img/Image 1.png',
        name_label: 'Name',
        name: 'Morgan Wave',
        views: 'Views',
        v_content: '2554',
        likes: 'Likes',
        l_content: '19,233',
        stock: 'Stock in Qty.',
        stock_content: '20',
        added: true
    },
    {
        id: 2,
        src: '/img/Image 1.png',
        name_label: 'Name',
        name: 'Morgan Wave',
        views: 'Views',
        v_content: '2554',
        likes: 'Likes',
        l_content: '19,233',
        stock: 'Stock in Qty.',
        stock_content: '20',
        added: false
    },
    {
        id: 3,
        src: '/img/Image 1.png',
        name_label: 'Name',
        name: 'Morgan Wave',
        views: 'Views',
        v_content: '2554',
        likes: 'Likes',
        l_content: '19,233',
        stock: 'Stock in Qty.',
        stock_content: '20',
        added: false
    },
    {
        id: 4,
        src: '/img/Image 1.png',
        name_label: 'Name',
        name: 'Morgan Wave',
        views: 'Views',
        v_content: '2554',
        likes: 'Likes',
        l_content: '19,233',
        stock: 'Stock in Qty.',
        stock_content: '20',
        added: false
    },
    {
        id: 5,
        src: '/img/Image 1.png',
        name_label: 'Name',
        name: 'Morgan Wave',
        views: 'Views',
        v_content: '2554',
        likes: 'Likes',
        l_content: '19,233',
        stock: 'Stock in Qty.',
        stock_content: '20',
        added: false
    },
]
export default function Addpaintingexshibition(props) {
    const [age, setAge] = React.useState('');
    const [addCategory, setAddCategory] = useState({ name: "", subject: [] })
    const handleChangeCategoryData = (e) => {
        const { name, value } = e.target
    }
    const handleClose = () => props.setOpen(false);

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div>
            {props.type === 'category' ?
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={props.open} >
                        <Box className={styles.modal_box}>

                            <div className={styles.cancel_btn_div}>
                                <img src={ '/img/cancel_cross.png'} onClick={handleClose} width='21px' height='21px' alt='img' />
                            </div>
                            <h2 className={styles.main_heading}>Add New Category</h2>

                            <div className={styles.card}>
                                <div className={styless.heading_main}>
                                    <div>Category</div>
                                    <div className={styless.hrline}></div>
                                </div>
                                <div className={styless.detail_inputs}>
                                    <TextField
                                        sx={{
                                            "& .MuiOutlinedInput-notchedOutline": { border: "none" }, "& .MuiInputBase-root": {
                                                height: 50
                                            }
                                        }} id="outlined-basic" placeholder='Category Name' className={styless.input} fullWidth variant="outlined" />

                                </div>
                                {/* <div className={styles.upload_img}>
                            <div className={styles.img_heading}>Images</div>
                            <div className={styles.preview_img}>
                                {src === '' ?
                                    <div className={styles.img_box}>
                                        <div htmlFor='input' style={{ textAlign: 'center' }}
                                            onClick={handleChangeImage}>
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                id='input'
                                                hidden
                                            /><label htmlFor='input' className={styles.label}>
                                                <img src={ '/img/uploadimg.png'} width='46px' height='43px' alt='img' />
                                                <p>  Upload your image
                                                </p></label>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <input
                                            onChange={handleChangeImage}
                                            type="file"
                                            id='input'
                                            hidden
                                        />
                                        <label htmlFor='input'>
                                            <img src={src} alt='img' id='img' className={image ? styles.img_view : styles.img_view_hide} />
                                        </label>
                                    </>
                                }
                            </div>
                           
                            <div className={styles.file_div}></div>
                            <p>Upload an image of your artwork.Your file must be least 1200*1500 px , a JPG or PNG file ,width a color format of rgb,and less than 50MB</p>
                        </div> */}

                                <div className={styless.heading_main}>
                                    <div className={styless.subheading}>Sub Category</div>
                                    {/* <div className={styless.hrline}></div> */}
                                </div>
                                {/* {data.map((val,i)=>( */}

                                <div className={styless.detail_inputs}>
                                    <div className={styless.input_detail_div}>
                                        <div className={styless.subheading_head}>Sub menu 1</div>
                                        <div className={styless.img_text_add}>
                                            <img src={ '/img/addicon.png'} height='11px' width="11px" alt='img' />
                                            <p>Add sub category</p>
                                        </div>
                                    </div>
                                    <div>
                                        <TextField
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" }, "& .MuiInputBase-root": {
                                                    height: 50
                                                }
                                            }} id="outlined-basic" placeholder='Enter menu' className={styless.input} fullWidth variant="outlined" />
                                        <Button >Add</Button>
                                    </div>
                                </div>
                                {/* ))} */}<div className={styless.detail_inputs}>
                                    <div className={styless.input_detail_div}>
                                        <div className={styless.subheading_head}>Sub menu 1</div>
                                        {/* <div className={styles.img_text_add}>
                                    <img src={ '/img/addicon.png'} height='11px' width="11px" />
                                    <p>Add Subcategory</p>
                                </div> */}
                                    </div>
                                    <div>
                                        <TextField
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none", }, "& .MuiInputBase-root": {
                                                    height: 50
                                                }
                                            }} id="outlined-basic" placeholder='Enter menu' className={styless.input} fullWidth variant="outlined" />
                                    </div>
                                </div><div className={styless.detail_inputs}>
                                    <div className={styless.input_detail_div}>
                                        <div className={styless.subheading_head}>Sub menu 1</div>
                                        {/* <div className={styles.img_text_add}>
                                    <img src={ '/img/addicon.png'} height='11px' width="11px" />
                                    <p>Add Subcategory</p>
                                </div> */}
                                    </div>
                                    <div>
                                        <TextField
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" }, "& .MuiInputBase-root": {
                                                    height: 50
                                                }
                                            }} id="outlined-basic" placeholder='Enter menu' className={styless.input} fullWidth variant="outlined" />
                                    </div>
                                </div>
                                <div className={styless.subheading_head}>Subject</div>
                                <Select
                                    labelId="select-mutli-artist"
                                    id="demo-select-artist"
                                    // value={addData.artist_ids.length === 0 ? [] : addData.artist_ids.map((val) => val._id)}
                                    fullWidth
                                    multiple
                                    sx={{
                                        background: "#f6f6f6",
                                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                                        "& .MuiInputBase-root": {
                                            borderRadius: "6px",
                                        },
                                        height: "40px",
                                    }}
                                    placeholder="select type"
                                    // onChange={handleSelectArtist}
                                    name="artist_ids"
                                // input={<OutlinedInput label="Aritst Name" />}
                                // MenuProps={MenuProps}
                                >
                                    {/* {artistdatas &&
         artistdatas.data
          .filter((fl) => {
           return fl.is_accepted === "Approved";
          })
          .map((val) => (
           <MenuItem value={val._id} key={val._id}>
            {val.first_name} {val.last_name}
           </MenuItem>
          ))} */}
                                </Select>
                                <div className={styless.save_btn}>
                                    <Button variant='contained' sx={{ width: '120px', height: '54px', borderRadius: '10px' }}>Save</Button>
                                </div>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
                :
                // this is for Exshibitionartist
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={props.open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={props.open} >
                        <Box className={styles.modal_box}>

                            <div className={styles.cancel_btn_div}>
                                <img src={ '/img/cancel_cross.png'} onClick={handleClose} width='21px' height='21px' alt='img' />
                            </div>
                            <h2 className={styles.main_heading}>Fill Exshibition Details</h2>

                            <div className={styles.main_content}>
                                <div>
                                    <label className={styles.input_label}>Painting</label>
                                    <FormControl fullWidth sx={{ mb: 2, mt: 0.5 }}>
                                        <Select
                                            fullWidth
                                            value={age}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            renderValue={
                                                age !== "" ? undefined : () => <span>Select Painting</span>
                                            }
                                            sx={{
                                                fontFamily: 'DM Sans',
                                                fontSize: '16px',
                                                color: '#636365',
                                                lineHeight: '21px',
                                                height: '50px',
                                                borderRadius: '6px',
                                                backgroundColor: '#f3f3f3', border: 'none', '.MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'rgba(228, 219, 233, 0.25)',
                                                },
                                            }}
                                        >
                                            <MenuItem disabled sx={{ color: '#636365' }} value="">
                                                None
                                            </MenuItem>
                                            <MenuItem value={10}>Ten</MenuItem>
                                            <MenuItem value={20}>Twenty</MenuItem>
                                            <MenuItem value={30}>Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                {data.map((val) => (
                                    <div key={val.id}>
                                        <div className={styles.details_div}>
                                            <div className={styles.img_detail_div}>
                                                <div className={styles.details_img_div}>
                                                    <img src={ '/img/Image 1.png'} width='121px' height='85px' alt='img' />
                                                </div>
                                                <div className={style.detail}>
                                                    <h6 className={styles.title}>{val.name_label}</h6>
                                                    <p className={styles.content}>{val.name}</p>
                                                </div>
                                            </div>
                                            <hr className={styles.hrverticle} />
                                            <div className={style.detail}>
                                                <h6 className={styles.title}>{val.views}</h6>
                                                <p className={styles.content}>{val.v_content}</p>
                                            </div>
                                            <hr className={styles.hrverticle} />
                                            <div className={style.detail}>
                                                <h6 className={styles.title}>{val.likes}</h6>
                                                <p className={styles.content}>{val.v_content}</p>
                                            </div>
                                            <hr className={styles.hrverticle} />
                                            <div className={styles.button_detail_div}>
                                                <div className={style.detail}>
                                                    <h6 className={styles.title}>{val.stock}</h6>
                                                    <p className={styles.content}>{val.stock_content}</p>
                                                </div>
                                                <div className={val.added ? styles.add_btn_div : styles.add_btn_div_hide}>

                                                    <Button variant='contained' sx={{ width: '120px', height: '44px', borderRadius: '6px' }} >Add</Button>

                                                </div>
                                            </div>
                                        </div>
                                        <hr className={styles.hori_hrs} />
                                    </div>
                                ))}
                                <Box zIndex={1} style={{ zIndex: 1 }} sx={{ py: 0, mx: 'auto', display: 'flex', zIndex: 1300, justifyContent: 'center', backgroundColor: 'white', mt: '0px' }}>
                                    <Button variant='contained' className={styles.save_btn} sx={{ mb: 2, width: '156px', height: '54px', borderRadius: '10px' }}>Save</Button>
                                </Box>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            }
        </div>
    );
}
