import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

}

const itemtypeSlice = createSlice({
    name: 'itemtypedata',
    initialState,
    reducers: {
        setItemtype: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setItemtype } = itemtypeSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default itemtypeSlice.reducer;