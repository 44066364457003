import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const MenuBySubMenuSlice = createSlice({
  name: "menubySubMenudata",
  initialState,
  reducers: {
    setSubMenuByMenuiddata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setSubMenuByMenuiddata } = MenuBySubMenuSlice.actions

export const subMenuByMenuidData = (state) => state.menubySubMenudata

export default MenuBySubMenuSlice.reducer
