import React, { useCallback, useState } from "react";
import styles from "./Commonuploadart.module.css";
import Steppergallery from "../stepper/Steppergallery";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addArtData } from "../../../../State/Features/Artist/uploadartSlice";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import serverimage from "../../../../serverConstant";
import { firstFormArtValidation } from "../../../../validation/firstFormArtValidation";

const stage = 0;
export default function Commonuploadart(props) {
 
  const [firstFormError, setFirstFormError] = useState({});
  const handleImageChang = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const MIN_FILE_SIZE = 50; // kb
      const MAX_FILE_SIZE = 1000; // kb

      const fileSizeKiloBytes = img.size / 1024;
      console.log(fileSizeKiloBytes);
      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size is very small");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size is very large");
      } else {
        if (props.selectedFiles.length + e.target.files.length >= 6) {
          Errornotify("Cannot select more than 5 image");
        } else {
          if (e.target.files) {
            const filesArray = Array.from(e.target.files).map((file) =>
              URL.createObjectURL(file)
            );
            props.setSelectedFiles((prevImages) => prevImages.concat(filesArray));
            // const filesArrays = Array.from(e.target.files).map((file) => file);
            // Array.from(e.target.files).map(
            //   (file) => URL.revokeObjectURL(file) // avoid memory leak
            // );
            const img = e.target.files[0];
            // if (props.uploadArt.images === "") {
            //   props.setUploadArt({
            //     ...props.uploadArt,
            //     images: props.uploadArt.images.push(URL.createObjectURL(e.target.files[i]))
            //   });
            // }
            // else {
            for (let i = 0; i < e.target.files.length; i++) {
              props.setUploadArt({
                ...props.uploadArt,
                // images: props.uploadArt.images.concat(", ", img),
                images: props.uploadArt.images.push(
                  URL.createObjectURL(e.target.files[i])
                ),
              });
            }
            console.log(props.uploadArt.images);
            // }
          }
          // if (e.target && e.target.files && e.target.files.length > 0) {
          //   const srcData = props.uploadArt.images;
          //   const images = props.uploadArt.images;
          //   for (let i = 0; i < e.target.files.length; i++) {
          //     srcData.push(URL.createObjectURL(e.target.files[i]));
          //     images.push(e.target.files[i]);
          //   }
          //   props.setUploadArt({
          //     ...props.uploadArt,
          //     images: images,
          //     imagesSrc: srcData,
          //   });
          // }
        }
      }
    }
  };

  // const handleImageChange = useCallback(
  //   (e) => {
  //     if (e.target && e.target.files && e.target.files.length > 0) {
  //       // const srcData = form.imagesSrc;
  //       const images = props.uploadArt.images;
  //       for (let i = 0; i < e.target.files.length; i++) {
  //         // images.push(URL.createObjectURL(e.target.files[i]));
  //         images.push(e.target.files[i]);
  //       }
  //       const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));
  //       props.setSelectedFiles((prevImages) => prevImages.concat(filesArray));
  //       props.setUploadArt({
  //         ...props.uploadArt,
  //         images: images,
  //         // imagesSrc: srcData,
  //       });
  //     }
  //   },
  //   [props.uploadArt]
  // );

  const MIN_FILE_SIZE = 100; // Minimum file size in KB
  const MAX_FILE_SIZE = 5000; // Maximum file size in KB (5 MB)
  
  const handleImageChange = useCallback(
    (e) => {
      if (e.target && e.target.files && e.target.files.length > 0) {
        const filesToAdd = Array.from(e.target.files); // Convert to array
        const totalImages = props.selectedFiles.length + filesToAdd.length;
  
        // Check the total number of images
        if (totalImages > 5) {
          Errornotify("You can't upload more than 5 images.");
          return; // Exit the function without processing further
        }
  
        // Validate file sizes (100 KB to 5000 KB)
        const validFiles = [];
        for (let file of filesToAdd) {
          const fileSizeInKB = file.size / 1024; // Convert to KB
  
          // File size validation
          if (fileSizeInKB < MIN_FILE_SIZE) {
            Errornotify("File size should be greater than 100KB");
          } else if (fileSizeInKB > MAX_FILE_SIZE) {
            Errornotify("File size should be less than 5MB");
          } else {
            validFiles.push(file); // Only valid files are added
          }
        }
  
        // Exit if no valid files
        if (validFiles.length === 0) return;
  
        // Update selectedFiles for preview
        const filesArray = validFiles.map((file) => URL.createObjectURL(file));
        props.setSelectedFiles((prevImages) => prevImages.concat(filesArray));
  
        // Update the props.uploadArt.images
        const images = props.uploadArt.images;
        images.push(...validFiles);
        props.setUploadArt({
          ...props.uploadArt,
          images: images,
        });
      }
    },
    [props.uploadArt, props.selectedFiles]
  );
  const handleImageRemove = useCallback(
    (index) => {
      // Remove the selected image from the preview array
      props.setSelectedFiles((prevImages) => prevImages.filter((_, i) => i !== index));
  
      // Remove the corresponding file from the props.uploadArt.images array
      const updatedImages = props.uploadArt.images.filter((_, i) => i !== index);
  
      // Update the parent component's state
      props.setUploadArt({
        ...props.uploadArt,
        images: updatedImages,
      });
    },
    [props.uploadArt]
  );
  
  const renderPhotos = (source, handleRemoveImage) => {
    return source.map((photo, index) => (
      <div key={photo} className={styles.imageContainer}>
        {/* IconButton for delete action */}
        <div className={styles.deleteButton}>
          <IconButton onClick={() => handleImageRemove(index)}>
            <img
              src={"/img/delete.png"}
              height="15px"
              width="14px"
              alt="delete"
            />
          </IconButton>
        </div>
        {/* Image */}
        <img className={styles.imgs} src={photo} alt="uploaded" />
      </div>
    ));
  };
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uploadarts = useSelector((state) => state.uploadArt);

  const handleChangeMainImage = (e) => {
    if (e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      const MIN_FILE_SIZE = 100; // kb
      const MAX_FILE_SIZE = 5000; // kb

      const fileSizeKiloBytes = img.size / 1024;
      console.log(img.size);
      if (fileSizeKiloBytes < MIN_FILE_SIZE) {
        Errornotify("File size should be greater than 100kb");
      } else if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        Errornotify("File size should be less than 5mb");
      } else {
        props.setUploadArt({ ...props.uploadArt, image: img });
        props.setSrc({ ...props.src, main: imgs });
      }
    }
  };

  const handleRemoveimage = () => {
    props.setSrc({ ...props.src, main: "" });
    props.setUploadArt({ ...props.uploadArt, image: "" });
  };
  const handlePopup = () => {
    const { firstFormErrors, isValidFirstForm } = firstFormArtValidation(
      props.uploadArt.name,
      props.uploadArt.image,

    );
    if (isValidFirstForm) {
      props.setOpen && props.setOpen(true);
      setFirstFormError({});
    } else {
      setFirstFormError(firstFormErrors);
      window.scrollTo({
        top: 150,
        behavior:"smooth"
      })
    }
  
  };
 
  return (
    <div className={styles.mains} sx={{ background: "blue" }}>
      <AlertsSuccess />
      {/* <div className={styles.stepper_div}> */}

      {/* </div> */}
      <Grid container spacing={{ sm: 5 }} columns={{ xs: 12, sm: 12, md: 12 }}>
   
        {/* <Grid item xs={0} sm={1} md={1}>
                </Grid> */}
        <Grid item xs={12} sm={12} md={12}>
          <div className={styles.atwork_div}>
            <h2 className={styles.main_heading}>ArtWork</h2>
            <label className={styles.labels}>Title*</label>
            <br />
            <TextField
              sx={{
                mt: 1,
                backgroundColor: "#f6f6f6",
                borderColor: "#ccc",
              }}
              fullWidth
              placeholder="Enter your title"
              onChange={props.handleChangedata}
              name="name"
              //    value={uploadarts.data ? uploadarts.data.name : uploadarts.name}
              value={props.uploadArt && props.uploadArt.name ? props.uploadArt.name : ""}
            />{" "}
            {firstFormError?.title ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {firstFormError?.title}
              </span>
            ) : null}
            <br />
            {localStorage.getItem("usertype") === "gallery" ?  <div style={{marginTop:"10px"}}>
            <label className={styles.labels} >Artist Name*</label>
            <br />
            <TextField
              sx={{
                mt: 1,
                backgroundColor: "#f6f6f6",
                borderColor: "#ccc",
              }}
              fullWidth
              placeholder="Enter artist name"
              onChange={props.handleChangedata}
              name="artist_name"
              //    value={uploadarts.data ? uploadarts.data.name : uploadarts.name}
              value={props.uploadArt && props.uploadArt.artist_name ? props.uploadArt.artist_name : ""}
            />{" "}
            </div> : null}
           
            {/* <div className={styles.search_action}>
       <lable className={styles.labels}>Artist name</lable>
       <br />
       <Autocomplete
        id="combo-box-demo"
        disableClearable
        options={top100Films}
        sx={{ width: "100%", mt: 0.8, backgroundColor: "#F3F3F3", border: "none" }}
        className={styles.search_select}
        renderInput={(params) => (
         <TextField {...params} sx={{ border: "none", color: "#636365" }} placeholder="Search" />
        )}
       />
      </div> */}
            <div style={{ marginTop: "10px" }}>
              <label className={styles.labels}>Image*</label>
              <div className={styles.upload_images}>
                {/* <div className={styles.upload_div_main}>
         <div className={styles.preview_img}>
          {src.main === "" ? (
           <div className={styles.img_box}>
            <div
             htmlFor="input"
             name="image"
             style={{ textAlign: "center" }}
             onClick={handleChangeMainImage}>
             <input onChange={handleChangeMainImage} type="file" name="image" id="input" hidden />
             <label htmlFor="input" className={styles.label}>
              <div className={styles.image_div}>
               <img src={"/img/uploadimg.png"} width="100%" height="100%" />
              </div>
              <p> Upload your image</p>
             </label>
            </div>
           </div>
          ) : (
           <>
            <input name="image" onChange={handleChangeMainImage} type="file" id="input" hidden />
            <label htmlFor="input">
             <img
              src={src.main}
              alt="img"
              id="img"
              name="image"
              className={image ? styles.img_view : styles.img_view_hide}
             />
            </label>
           </>
          )}
          <div className={styles.file_div}></div>
         </div> 
         {src.main !== "" ? (
          <div className={styles.del_img_div}>
           <IconButton onClick={handleRemoveimage}>
            <img src={"/img/delete.png"} height="15px" width="14px" />
           </IconButton>
          </div>
         ) : (
          ""
         )}
        </div> */}
                {/* <div> */}
                {/* <div className={styles.upload_div_main}>
          <div className={styles.preview_img}>
           {src.multi.length === 0 ? (
            <div className={styles.img_box}>
             <div
              name="images"
              htmlFor="input"
              style={{ textAlign: "center" }}
              onClick={(e)=>handleChangeImages(e)}>
              <input onChange={(e)=>handleChangeImages(e)} multiple name="images" type="file" id="input" hidden />
              <label htmlFor="input" className={styles.label}>
               <div className={styles.image_div}>
                <img src={"/img/uploadimg.png"} width="100%" height="100%" />
               </div>
               <p> Upload your image</p>
              </label>
             </div>
            </div>
           ) : (
            src.multi &&
            src.multi.map((val) => (
             <>
              <input name="images" onChange={(e)=>handleChangeImages(e)} type="file" id="input" hidden />
              <label htmlFor="input">
               <img
                src={val}
                alt="img"
                id="img"
                name="images"
                className={image ? styles.img_view : styles.img_view_hide}
               />
              </label>
             </>
            ))
           )}
           <div className={styles.file_div}></div>
          </div>
          {src.multi.length !== 0 ? (
           <div className={styles.del_img_div}>
            <IconButton onClick={handleRemoveimage}>
             <img src={"/img/delete.png"} height="15px" width="14px" />
            </IconButton>
           </div>
          ) : (
           ""
          )}
         </div> */}
                <div style={{ width: "100%" }}>
                  <div className={styles.img_box_div}>
                    {/* <div className={styles.img_box}>
           <div
            htmlFor="file"
            name="image"
            style={{ textAlign: "center" }}
           >
            <input type="file" id="file" multiple onChange={handleImageChange} hidden />
            <label htmlFor="file" className={styles.label}>
             <div className={styles.image_div}>
              <img src={"/img/uploadimg.png"} width="100%" height="100%" />
             </div>
             <p> Upload your image</p>
            </label>
           </div>
          </div> */}
                    <div className={styles.upload_div_main}>
                      <div className={styles.preview_img}>
                        {props.src?.main === "" &&
                        !props.uploadArt?.image?.length ? (
                          <>
                            <div className={styles.img_box}>
                              <div
                                htmlFor="input"
                                name="image"
                                style={{ textAlign: "center" }}
                                // onClick={handleChangeMainImage}
                              >
                                <input
                                  onChange={handleChangeMainImage}
                                  accept="image/*"
                                  type="file"
                                  name="image"
                                  id="input"
                                  hidden
                                />
                                <label htmlFor="input" className={styles.label}>
                                  <div className={styles.image_div}>
                                    <img
                                      src={"/img/uploadimg.png"}
                                      width="100%"
                                      height="100%"
                                      alt="img"
                                    />
                                  </div>
                                  <p> Upload Your Primary Image</p>
                                </label>
                              </div>
                            </div>
                          </>
                        ) : props.src?.main !== "" && props.uploadArt?.image ? (
                          <>
                            {/* // <div className={styles.img_boxs}>
                          //   <div */}
                            {/* //     style={{ textAlign: "center" }}> */}
                            <input
                              name="image"
                              onChange={handleChangeMainImage}
                              accept="image/*"
                              type="file"
                              id="input"
                              hidden
                            />
                            <label htmlFor="input">
                              <img
                                htmlFor="input"
                                src={
                                  props.src.main
                                    ? props.src.main
                                    :  props.uploadArt.image
                                }
                                alt="fimg"
                                id="img"
                                name="image"
                                className={styles.img_view}
                              />
                            </label>
                            {/* //   </div> */}
                            {/* // </div> */}
                          </>
                        ) : (
                          <>
                      
                            <input
                              name="image"
                              onChange={handleChangeMainImage}
                              accept="image/*"
                              type="file"
                              id="input"
                              hidden
                            />
                            <label htmlFor="input">
                              <img
                                htmlFor="input"
                                src={
                                  props.src?.main
                                    ? props.src?.main
                                    :  props.uploadArt?.image
                                }
                                alt="ivmg"
                                id="img"
                                name="image"
                                className={styles.img_view}
                              />
                            </label>
                            {/* //   </div> */}
                            {/* // </div> */}
                          </>
                        )}
                        <div className={styles.file_div}></div>
                      </div>
                     
                      {props.src?.main !== "" ? (
                        <div className={styles.del_img_div}>
                          <IconButton onClick={handleRemoveimage}>
                            <img
                              src={"/img/delete.png"}
                              height="15px"
                              width="14px"
                              alt="img"
                            />
                          </IconButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={styles.img_box}>
                      <div
                        htmlFor="file"
                        name="image"
                        style={{ textAlign: "center" }}
                        className={styles.preview_imgs}
                      >
                        <input
                          type="file"
                          id="file"
                          multiple
                          accept="image/*"
                          onChange={handleImageChange}
                          hidden
                        />
                        <label htmlFor="file" className={styles.label}>
                          <div className={styles.image_div}>
                            <img
                              src={"/img/uploadimg.png"}
                              width="100%"
                              height="100%"
                              alt="img"
                            />
                          </div>
                          <p> Upload your secondary image</p>
                        </label>
                      </div>
                    </div>
                    
                  </div>
                  {firstFormError?.image ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {firstFormError?.image}
              </span>
            ) : null}
                  <div className={styles.result}>
                    {renderPhotos(props.selectedFiles)}
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <p>Please selcet size in between 100kb to 5000kb</p>
              <p className={styles.des}>
                Upload an image of your artwork.
                {/* Your image file must be at least 1200 X 1500 px, a JPG or */}
                PNG file, with a color format of RGB.
              </p>
            </div>
            <div>
              <label className={styles.labels}>Copyright</label>
              <div className={styles.copyright_div}>
                <p>Are you the copyright owner of this artwork?</p>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={props.handleChangedata}
                    //   value={uploadarts.data ? uploadarts.data.copyright : uploadarts.copyright}
                    value={props.uploadArt && props.uploadArt.copyright ? props.uploadArt.copyright : ""}
                    name="copyright"
                  >
                    <FormControlLabel
                      className={styles.radio_label}
                      control={<Radio />}
                      label="Yes"
                      value="Yes"
                      name="copyright"
                    />
                    <FormControlLabel
                      className={styles.radio_label}
                      value="No"
                      control={<Radio />}
                      label="No"
                      name="copyright"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            {/* <div>
  <FormControl>
    <div className={styles.checkboxContainer}>
      <Checkbox
        checked={props.uploadArt && props.uploadArt.is_showcased === "Yes"}  // Checks if it's "Yes"
        onChange={(e) => {
          if (e.target.checked) {
            // When checked, set the value to "Yes"
            props.setUploadArt((prevState) => ({
              ...prevState,
              is_showcased: "Yes",
            }));
          } else {
            // When unchecked, remove the value
            props.setUploadArt((prevState) => {
              const { is_showcased, ...rest } = prevState; // Destructure to exclude `is_showcased`
              return rest; // Return the remaining state
            });
          }
        }}
        name="is_showcased"
        color="primary"
        value={props.uploadArt && props.uploadArt.is_showcased === "Yes" ? "Yes" : "No"}
      />
         <p>  I agree that the artwork I am uploading is showcased on other platforms.</p>
    </div>
  </FormControl>
</div> */}

            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handlePopup}
                sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
              >
                Continue
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
