import * as React from "react"
import styles from "./SuperadminOfferpopup.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import { FormControl, MenuItem, Select, TextField } from "@mui/material"
import homeImage from "../../../../constant"
import {
  offerAssignToArt,
  offerRemovedToArtAdmin,
} from "../../../../State/api/artistapi"
import { useDispatch } from "react-redux"

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: "6px",
  p: 2,
}
const data = [
  {
    id: 1,
    src: "/img/Image 1.png",
    name_label: "Name",
    name: "Morgan Wave",
    views: "Views",
    v_content: "2554",
    likes: "Likes",
    l_content: "19,233",
    stock: "Stock in Qty.",
    stock_content: "20",
  },
  {
    id: 2,
    src: "/img/Image 1.png",
    name_label: "Name",
    name: "Morgan Wave",
    views: "Views",
    v_content: "2554",
    likes: "Likes",
    l_content: "19,233",
    stock: "Stock in Qty.",
    stock_content: "20",
  },
  {
    id: 3,
    src: "/img/Image 1.png",
    name_label: "Name",
    name: "Morgan Wave",
    views: "Views",
    v_content: "2554",
    likes: "Likes",
    l_content: "19,233",
    stock: "Stock in Qty.",
    stock_content: "20",
  },
  {
    id: 4,
    src: "/img/Image 1.png",
    name_label: "Name",
    name: "Morgan Wave",
    views: "Views",
    v_content: "2554",
    likes: "Likes",
    l_content: "19,233",
    stock: "Stock in Qty.",
    stock_content: "20",
  },
  {
    id: 5,
    src: "/img/Image 1.png",
    name_label: "Name",
    name: "Morgan Wave",
    views: "Views",
    v_content: "2554",
    likes: "Likes",
    l_content: "19,233",
    stock: "Stock in Qty.",
    stock_content: "20",
  },
]
export default function SuperAdminOffer(props) {
  const [age, setAge] = React.useState("")
  const dispatch = useDispatch()
  const handleClose = () => props.setOpen(false)

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  const handleOfferAssign = (id) => {
    if (id) {
      dispatch(
        offerAssignToArt(props.artId, id, props.setOpen, props.fetchData)
      )
    }
  }

  const handleOfferRemove = (id) => {
    if (id) {
      dispatch(
        offerRemovedToArtAdmin(props.artId, id, props.setOpen, props.fetchData)
      )
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_box}>
            <div className={styles.input_action_div}>
              <div className={styles.input_div}>
                <TextField
                  inputProps={{
                    style: {
                      height: "11px",
                      borderRadius: "6px",
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    },
                  }}
                  variant="outlined"
                  placeholder="Search"
                  type="text"
                  fullWidth
                  sx={{
                    backgroundColor: "white",
                    "::placeholder": { color: "red" },
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  }}
                />
                {/* <input className={styles.search_input} placeholder='Morgan l' type='text' /> */}
                <img
                  className={styles.search_icon}
                  src={"/img/Search Symbol.png"}
                  width="20px"
                  height="20px"
                />
              </div>
              <Button
                onClick={handleClose}
                variant="contained"
                className={styles.cancel_btn}
                sx={{
                  ml: 0,
                  height: "44px",
                  backgroundColor: "white",
                  color: "black",
                  minWidth: "100px",
                }}
              >
                close
              </Button>
            </div>
            <div className={styles.main_content}>
              <div className={styles.selector}>
                <FormControl
                  sx={{
                    my: 1,
                    p: 0,
                    width: "120px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select
                    value={age}
                    size="small"
                    sx={{
                      color: "#1C1C1C",
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "21px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                        borderRadius: "10px",
                      },
                      height: "46px",
                      display: "flex",
                      justifyContent: "center",
                      // textAlign:'right'
                    }}
                    align="center"
                    onChange={handleChange}
                    displayEmpty
                    // IconComponent={() => (
                    //     <img src='/img/dropdownicon.png' width='13px' height='10px' style={{ marginRight: '20px', textAlign: 'left' }} />
                    // )}
                  >
                    <MenuItem value="">Select All</MenuItem>
                    <MenuItem value={10}>One</MenuItem>
                    <MenuItem value={20}>Two</MenuItem>
                    <MenuItem value={30}>Four</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {props.offerData?.data.map((val) => (
                <div key={val.id}>
                  <div className={styles.details_div}>
                    <div className={styles.img_detail_div}>
                      <img
                        src={
                          "http://13.126.71.199:8000/art/" + val?.offer_image
                        }
                        width="121px"
                        height="85px"
                      />
                      <div className={styles.details_img_div}></div>
                      <div className={style.detail}>
                        <h6 className={styles.title}>Offer Name</h6>
                        <p className={styles.content}>{val.name}</p>
                      </div>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={style.detail}>
                      <h6 className={styles.title}>Offer</h6>
                      <p className={styles.content}>{val.discount}</p>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={style.detail}>
                      <h6 className={styles.title}>Start Date</h6>
                      <p className={styles.content}>{val.start_date}</p>
                    </div>
                    <hr className={styles.hrverticle} />
                    <div className={styles.button_detail_div}>
                      <div className={style.detail}>
                        <h6 className={styles.title}>End Date</h6>
                        <p className={styles.content}>{val.end_date}</p>
                      </div>
                      <div className={styles.add_btn_div}>
                        {}
                        {val._id === props.offerId ? (
                          <Button
                            variant="contained"
                            sx={{
                              width: "120px",
                              height: "44px",
                              borderRadius: "6px",
                              backgroundColor: "red",
                              "&:hover": {
                                backgroundColor: "darkred",
                              },
                            }}
                            onClick={() => handleOfferRemove(val?._id)}
                          >
                            Remove
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            sx={{
                              width: "120px",
                              height: "44px",
                              borderRadius: "6px",
                              backgroundColor: "green",
                              "&:hover": {
                                backgroundColor: "darkgreen",
                              },
                            }}
                            onClick={() => handleOfferAssign(val?._id)}
                          >
                            Add
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr className={styles.hori_hrs} />
                </div>
              ))}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
