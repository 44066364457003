import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  fetchGiftcardData,
  handleAddGiftcard,
} from "../../../../State/api/superadminApi"
import { setGiftcarddata } from "../../../../State/Features/superadmin/giftcardSlice"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Fourcolimg from "../Table/Fourcolumn/Fourcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Giftcard.module.css"
import Pagination from "../Pagination/Pagination"

const head = [
  {
    id: 1,
    label: "Gift",
  },
  {
    id: 2,
    label: "Minimum Price ($)",
  },
  {
    id: 3,
    label: "Title",
  },
  {
    id: 4,
    label: " Created Date",
  },
  {
    id: 5,
    label: "Description",
  },
]

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
const heading = "Create Gift Card"
export default function Giftcard() {
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({ offset: 0, is_active: "Active" })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const dispatch = useDispatch()
  const giftdata = useSelector((state) => state.giftcardData.data)
  console.log(giftdata && giftdata.data.filter((val) => val.name))
  const fetchData = () => {
    dispatch(fetchGiftcardData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  const handleAdd = (gift) => {
    dispatch(handleAddGiftcard(gift, setOpen, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setGiftcarddata({
          data:
            giftdata.data &&
            [...giftdata.data].sort((a, b) =>
              a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
            ),
          totalCount: giftdata.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setGiftcarddata({
          data:
            giftdata.data &&
            [...giftdata.data].sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
            ),
          totalCount: giftdata.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setGiftcarddata({
          data:
            giftdata.data &&
            [...giftdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: giftdata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setGiftcarddata({
          data:
            giftdata.data &&
            [...giftdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: giftdata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      {open ? (
        <Addimgpopup
          handleAdd={handleAdd}
          heading={heading}
          poptype="giftcard"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      <AlertsSuccess />
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Fourcolimg
          activetab="giftcard"
          head={head}
          data={
            giftdata &&
            giftdata.data
              .filter((val) => {
                if (condition.search === "") {
                  return val
                } else if (
                  val.name &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                ) {
                  return val
                }
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        <Pagination
          totaldata={giftdata && giftdata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
