import validator from "validator"
import isEmpty from "./isEmpty"

export const validationCheckoutPage = ( { fullName,

contact,
city,
country,
state,
postal_code,
country_code
}) => {
  let checkoutErrors = {}

  if (validator.isEmpty(fullName)) {
    checkoutErrors.fullname = "Full Name is required"
  }
  // if (validator.isEmpty(address)) {
  //   checkoutErrors.address = "Address is required"
  // }
  if (validator.isEmpty(contact)) {
    checkoutErrors.contact = "Contact is required"
  }
  if (validator.isEmpty(postal_code)) {
    checkoutErrors.postal_code = "Postal code is required"
  }
  if (validator.isEmpty(country_code)) {
    checkoutErrors.country_code = "Country Code is required"
  }
  if (validator.isEmpty(city)) {
    checkoutErrors.city = "City is required"
  }
  if (validator.isEmpty(state)) {
    checkoutErrors.state = "State is required"
  }

  if (validator.isEmpty(country)) {
    checkoutErrors.country = "Country is required"
  }
  return {
    checkoutErrors,
    isValidCheckout: isEmpty(checkoutErrors),
  }
}
