import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    type:'',
    text:''
}


export const tableheaderbuttonSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {
    addButton: (state, action) => {
      console.log("tableheaderbutton",state.button);
      state.button = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addButton } = tableheaderbuttonSlice.actions;

export const selectbutton = (state) => state.button;

export default tableheaderbuttonSlice.reducer;