import { createTheme, Grid, Link, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./Offers.module.css";
import ProductSidebar from "./ProductSidebar";
import homeImage from "../../../constant";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../../../common/loader/Loader";
import serverimage from "../../../serverConstant";
import { fetchFetchOffers } from "../../../State/api/userApi";
import striptags from "striptags";
import { setActiveStatus } from "../../../State/Features/user/activetabSlice";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});
const CardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 5201.png",
    discount: "-14%",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 5202.png",
    discount: "-30%",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 5203.png",
    discount: "-20%",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5280.png",
    discount: "-14%",
  },
  {
    id: 5,
    imgUrl: "/img/Rectangle 5281.png",
    discount: "-30%",
  },
  {
    id: 6,
    imgUrl: "/img/Rectangle 5282.png",
    discount: "-20%",
  },
  {
    id: 7,
    imgUrl: "/img/Rectangle 5292.png",
    discount: "-14%",
  },
  {
    id: 8,
    imgUrl: "/img/Rectangle 5293.png",
    discount: "-30%",
  },
  {
    id: 9,
    imgUrl: "/img/Rectangle 5294.png",
    discount: "-20%",
  },
  {
    id: 10,
    imgUrl: "/img/Rectangle 5303.png",
    discount: "-14%",
  },
  {
    id: 11,
    imgUrl: "/img/Rectangle 5304.png",
    discount: "-30%",
  },
  {
    id: 12,
    imgUrl: "/img/Rectangle 5305.png",
    discount: "-20%",
  },
];
function ExhibitionArts() {
  const [offerData, setOfferData] = useState();
  const [style, setStyle] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const [filter, setFilter] = useState({
    type: [],
    category: [],
    style: [],
    artist_type: [],
    artist: [],
    country: [],
    orientation: "",
    color: [],
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
const {id} = useParams()
  const loader = useSelector((state) => state.loader);
  const offersdt = useSelector((state) => state.offersData);
 const [exhibitionDetailArt, setExhibitionDetailArt] = useState([])
 console.log(exhibitionDetailArt,'exhibitionDetailArt')
  const handleNavigate = (route) => {
    navigate(route);
  };
  // const handleFetchOffer = async function () {
  //   dispatch(setLoader(true));
  //   try {
  //     const result = await api.applicationApi('api/user/getoffers'
  //     )
  //     if (result && result.status === 200) {
  //       setOfferData(result.data);
  //       dispatch(setLoader(false));
  //     }
  //     else if (result.status === 401) {
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  const auctionStatus = useSelector((state) => state.activetab);
  const handleAuctionStatus = (status) => {
    dispatch(setActiveStatus({ status, maintab: "exhibition" }));
  };
  const fetchExhibitionArtDetails = async () => {
    try {
        const {data} = await api.applicationApi(`api/exhibition/fetchExhibitionArtDetails/${id}`,"POST");
        setExhibitionDetailArt(data.data.data)
    }catch(error) {
        console.log(error)
    }
  }
  useEffect(() => {
    // handleFetchOffer();
    fetchExhibitionArtDetails()
    dispatch(setActiveStatus({ status: "ongoing exhibitions", maintab: "exhibition" }));
  }, []);
  const handleOfferDetail = (id) => {
    navigate(`/user/productdetail/${id}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.offers}>
        <div className={styles.headerCategory}>
          <br />
          <div>
            <Typography variant="h4">
              <span className={styles.headerTitle}>Exhibition</span>
            </Typography>
            <Typography ml={8} variant="h6">
              <span className={styles.headerSubTitle}>
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/")}
                >
                  Home /{" "}
                </b>
                Exhibition
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.link__div}>
            {["ongoing exhibitions", "past", "upcoming"].map(
              (status, index) => (
                <React.Fragment key={status}>
                  <Link
                    style={{ textDecoration: "none", color: "black",display:'flex',alignItems:'center' }}
                    onClick={() => handleAuctionStatus(status)}
                  >
                    <Typography
                      fontSize="1vw"
                      fontFamily="marcellus"
                      marginRight={"7px"}
                      sx={{cursor:"pointer"}}
                      fontWeight={auctionStatus.status === status ? "bold" : "400"}
                      color={auctionStatus.status === status ? "black" : "#636365"}
                    >
                      {status.toUpperCase()}
                    </Typography>
                  {index < 2 ?    <div style={{height:"18px",borderRight:"1px solid #636365",marginRight:'7px'}}></div> : null}
                  </Link>
                  {index < 2 && <div className={styles.verticalLine3}></div>}{" "}
                  {/* Add line only if it's not the last item */}
                </React.Fragment>
              )
            )}
          </div>
          <hr className={styles.divider} />
        <div className={styles.mainContent}>
          {/* <ProductSidebar
            value_height={value_height}
            value_width={value_width}
            priceRange={priceRange}
            setValueHeight={setValueHeight}
            filter={filter}
            style={style}
            setFilter={setFilter}
            setPriceRange={setPriceRange}
            setValueWidth={setValueWidth}
          /> */}
          <div className={styles.productCards__main}>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              <Grid container spacing={1}>
                {exhibitionDetailArt &&
                  exhibitionDetailArt?.map((item) => (
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      md={3}
                      onClick={() => handleOfferDetail(item._id)}
                    >
                      <div className={styles.productCards}>
                        <img
                          className={styles.offerCardImgExhibition}
                          src={ item?.item?.[0].image}
                        />
                      
                        <div className={styles.card__title}>
                          <Typography fontWeight="600" fontSize="0.95rem">
                            {item?.item?.[0].name}
                          </Typography>
                          {/* <div className={styles.calendar}>
                            <img
                              className={styles.calendarImg}
                              src="/img/calendar-2.png"
                            />
                            <Typography mt={0.35} ml={0.5} fontSize="0.85rem">
                              24 Mar - 23 Apr
                            </Typography>
                          </div> */}
                        </div>
                        <Typography fontSize="0.92rem" color="#636365">
                          {item?.artist}
                        </Typography>
                        <hr className={styles.card__divider} />
                        <Typography fontSize="0.85rem" color="#636365">
                          $ {item?.price}
                        </Typography>
                      </div>
                    </Grid>
                  ))}
              </Grid>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ExhibitionArts;
