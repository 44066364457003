import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const ArtistSlice = createSlice({
  name: "artistdata",
  initialState,
  reducers: {
    setArtistdata: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setArtistdata } = ArtistSlice.actions;

export const ArtistData = (state) => state.aritistdata;

export default ArtistSlice.reducer;
