import validator from "validator"
import isEmpty from "./isEmpty"

const isRichTextEmpty = (content) => {
  if (typeof content !== "string") return true
  const strippedContent = content.replace(/<[^>]*>/g, "").trim()
  return strippedContent === ""
}
export const validateAdminPolicy = (policy) => {
  let policyErrors = {}

  if (validator.isEmpty(policy.title)) {
    policyErrors.title = "Title is required"
  }
  if (isRichTextEmpty(policy.description)) {
    policyErrors.description = "Description is required"
  }

  return {
    policyErrors,
    isValid: isEmpty(policyErrors),
  }
}
