import { ThemeProvider } from "@emotion/react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  Container,
  Link,
  Grid
} from "@mui/material"
import { createTheme } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./FAQ.module.css"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import homeImage from "../../../constant"
import { useNavigate } from "react-router-dom"
import api from "../../../State/api/Api"
import { useDispatch, useSelector } from "react-redux"
import { fetchFaq } from "../../../State/api/userApi"
import CircularLoader from "../../../common/loader/Loader"
import { setLoader } from "../../../State/Features/common/loaderSlice"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
const divData = [
  {
    id: 1,
    label: "FOR ARTIST",
    value: "for__artist",
  },
  {
    id: 2,
    label: "FOR CURATOR",
    value: "for__curator",
  },
  {
    id: 3,
    label: "FOR AFFILIATE",
    value: "for__affiliate",
  },
  {
    id: 4,
    label: "FOR BUYER",
    value: "for__buyer",
  },
  {
    id: 5,
    label: "FOR GALLERIES",
    value: "for__galleries",
  },
]
const qnaData = [
  {
    id: 1,
    question: "What is Webflow and why is it the best website builder?",
  },
  {
    id: 2,
    question: "What is your favorite template from BRIX Templates?",
  },
  {
    id: 3,
    question: "How do you clone a template from the Showcase?",
  },
  {
    id: 4,
    question: "Why is BRIX Templates the best Webflow agency?",
  },
  {
    id: 5,
    question: "When was Webflow officially launched?",
  },
  {
    id: 6,
    question: "How do you integrate Jetboost with Webflow?",
  },
  {
    id: 7,
    question: "Are the artworks available as prints?",
  },
  {
    id: 8,
    question: "Is a certificate of authenticity provided?",
  },
  {
    id: 9,
    question: "Is framing available for all works?",
  },
  {
    id: 10,
    question: "What is your favorite template from BRIX Templates?",
  },
  {
    id: 11,
    question: "How do you clone a template from the Showcase?",
  },
  {
    id: 12,
    question: "Why is BRIX Templates the best Webflow agency?",
  },
  {
    id: 13,
    question: "When was Webflow officially launched?",
  },
  {
    id: 14,
    question: "How do you integrate Jetboost with Webflow?",
  },
  {
    id: 15,
    question: "Are the artworks available as prints?",
  },
  {
    id: 16,
    question: "Is a certificate of authenticity provided?",
  },
  {
    id: 17,
    question: "Is framing available for all works?",
  },
]
function FAQ() {
  const [divLinks, SetdivLinks] = useState("for__buyer")
  console.log(divLinks,'divLinks')
  const [faqData, setFaqData] = useState()
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.loader)
  const usertypes = useSelector((state) => state.usertype)
  const userFaqData = useSelector((state) => state.userFaqData)
  const FAQ_data = userFaqData?.data?.responses

  // const usertype_id = usertypes.data.map((vl) => { return vl._id })
  const handleClick = (val) => {
    SetdivLinks(val)
  }
  const navigate = useNavigate()
  const handleNavigate = (route) => {
    navigate(route)
  }
  // const handleFetchFaq = async function () {
  //   dispatch(setLoader(true))
  //   try {
  //     const result = await api.applicationApi("api/user/getfaq")
  //     if (result && result.status === 200) {
  //       setFaqData(result.data)
  //       dispatch(setLoader(false))
  //     } else if (result.status === 401) {
  //     }
  //   } catch (err) {
  
  //   }
  // }

  useEffect(() => {
    dispatch(fetchFaq())
  }, [])
  return (
    <div className={styles.faq}>
      <div className={styles.headerCategory}>
      <Container maxWidth="lg">
      <div className={styles.pageitle}>
              <h1>FAQ</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Your questions answered</li>
              </ul>
            </div>
      </Container>
      </div>
      <div className={styles.faqlist}>
      <Container maxWidth="lg">
                  <Grid container spacing={2}>
    <Grid item md={3} xs={12}>
      <div className={styles.faq_category}>
      {divData &&
            divData.map((val, i) => (
              <div className={styles.demo_child_div} key={i}>
                <div
                  className={divLinks === val.value ? styles.flex_start : ""}
                ></div>
                <Button
                  value={divLinks}
                  onClick={() => handleClick(val.value)}
                  variant={divLinks === val.value ? "contained" : ""}
                  fullWidth
                  sx={{
                    ml: 1,
                    display: "flex",
                    justifyContent: "flex-start",
                    mr: 0,
                    pr: 0,
                    borderRadius: " 2.06016px 0px 0px 2.06016px",
                  }}
                >
                  {val.label}
                </Button>
              </div>
            ))}
      </div>
    </Grid>
    <Grid item md={9} xs={12}>
    {loader.loader ? (
          <Box className={styles.loader_box}>
            {" "}
            <CircularLoader size={60} />
          </Box>
        ) : divLinks === "for__artist" ? (
          <div className={styles.for__artist}>
            {FAQ_data &&
              FAQ_data.filter((val) => {
                return val.user_type_id === "633282ce3777d5d6559bd24e"
                
                // return usertypes.data.filter((vl) => (vl._id === val.user_type_id))
                // usertypes.data.filter((vl) => { return vl._id })
              }).map((item, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className={styles.quesDiv}>
                      <div className={styles.idDiv}>{i + 1}</div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        {item.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      fontSize="0.8rem"
                      dangerouslySetInnerHTML={{
                        __html:
                          item && item.answer
                            ? item.answer
                            : item && item.answer
                            ? item.answer
                            : "",
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        ) : divLinks === "for__curator" ? (
          <div className={styles.for__curator}>
            {FAQ_data &&
              FAQ_data.filter((val) => {
                return val.user_type_id === "633282d73777d5d6559bd251"
              }).map((item, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className={styles.quesDiv}>
                      <div className={styles.idDiv}>{i + 1}</div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        {item.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      fontSize="0.8rem"
                      dangerouslySetInnerHTML={{
                        __html:
                          item && item.answer
                            ? item.answer
                            : item && item.answer
                            ? item.answer
                            : "",
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        ) : divLinks === "for__affiliate" ? (
          <div className={styles.for__affiliate}>
            {FAQ_data &&
              FAQ_data.filter((val) => {
                return val.user_type_id === "633282eb3777d5d6559bd257"
              }).map((item, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className={styles.quesDiv}>
                      <div className={styles.idDiv}>{i + 1}</div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        {item.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      fontSize="0.8rem"
                      dangerouslySetInnerHTML={{
                        __html:
                          item && item.answer
                            ? item.answer
                            : item && item.answer
                            ? item.answer
                            : "",
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        ) : divLinks === "for__buyer" ? (
          <div className={styles.for__buyer}>
            {FAQ_data &&
              FAQ_data.filter((val) => {
                return val.user_type_id === "633282e33777d5d6559bd254"
              }).map((item, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className={styles.quesDiv}>
                      <div className={styles.idDiv}>{i + 1}</div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        {item.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      fontSize="0.8rem"
                      dangerouslySetInnerHTML={{
                        __html:
                          item && item.answer
                            ? item.answer
                            : item && item.answer
                            ? item.answer
                            : "",
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        ) : (
          <div className={styles.for__galleries}>
            {FAQ_data &&
              FAQ_data.filter((val) => {
                return val.user_type_id === "663c6e47f02e16a2957a1e46"
              }).map((item, i) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className={styles.quesDiv}>
                      <div className={styles.idDiv}>{i + 1}</div>
                      <Typography fontWeight="600" fontSize="0.9rem" ml={2}>
                        {item.question}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      fontSize="0.8rem"
                      dangerouslySetInnerHTML={{
                        __html:
                          item && item.answer
                            ? item.answer
                            : item && item.answer
                            ? item.answer
                            : "",
                      }}
                    ></Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        )}
    </Grid>
    </Grid>
    </Container>
      </div>
      
    </div>
  )
}

export default FAQ
