import { Button } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import styles from "./CommonArtdetail.module.css"
import { useParams, useNavigate } from "react-router-dom"
import homeImage from "../../../../../constant"
import serverimage from "../../../../../serverConstant"

const data = {
  description: [
    {
      title: "Title",
      content: "Art of Space",
    },
    {
      title: "Copyright",
      content: "yes",
    },
    {
      title: "Category",
      content: "Oil Painting",
    },
    {
      title: "Subject",
      content: "Lorem this is Subject",
    },
    {
      title: "Year",
      content: "2022",
    },
    {
      title: "Material",
      content: "Canvas",
    },
    {
      title: "Styles",
      content: "Art",
    },
    {
      title: "Dimenstions",
      content: "Cm H25 X  W215 X D14",
    },
    {
      title: "Keywords",
      content: "Art, Painting, Space",
    },
    {
      title: "Description",
      content:
        "ver since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently wi",
    },
    {
      title: "Exibition 3",
      content: "Art of Space",
    },
    {
      title: "Subscription Plan",
      content: "Premium",
    },
    {
      title: "Exxibition 2",
      content: "Art of space",
    },
    {
      title: "Biography",
      content:
        "k. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
    },
  ],
  detailPrice: {
    heading: "Price &  Detail",
    content: [
      {
        details_subheading: "Sale",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "sale",
          },
          {
            title: "Price",
            content: "$250,25",
          },
        ],
      },
      {
        details_subheading: "Rent",
        detailsubcontent: [
          {
            title: "Availablity",
            content: "Rent",
          },
          {
            title: "Price",
            content: "$250,25",
          },
          {
            title: "Time",
            content: "1 Month",
          },
        ],
      },
    ],
  },
}

export default function CommonArtdetail(props) {
  console.log(props.data, "props--curator arts")
  const navigate = useNavigate()

  const handleBack = () => {
    navigate("/art")
  }
  return (
    <>
      <div className={styles.back_button}>
        <img
          src={"/img/_ Back.png"}
          onClick={handleBack}
          className={styles.backimage}
          height="13px"
          width="46px"
          alt="img"
        />
      </div>
      {props.data &&
        props.data.map((val) => (
          <>
            <div className={styles.previewimg_img_div}>
              <img src={val.image} className={styles.previewimg} alt="img" />
            </div>
            <div
              className={
                props.page === "superadmin"
                  ? styles.details_main
                  : styles.details_main_curator
              }
            >
              <hr className={styles.horizontal} />
              <h2>Description</h2>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Title :</div>
                <div className={styles.description_details_content}>
                  {val.name}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Copyright :
                </div>
                <div className={styles.description_details_content}>
                  {val.copyright}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Category :
                </div>
                <div className={styles.description_details_content}>
                  {val.category}
                </div>
              </div>

              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Subject :
                </div>
                <div className={styles.description_details_content}>
                  {val.subject && val.subject.map((val) => val.name)}
                </div>
              </div>

              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Year :</div>
                <div className={styles.description_details_content}>
                  {val.year}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Material :
                </div>
                <div className={styles.description_details_content}>
                  {val.material.map((v) => v.name)}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Style :</div>
                <div className={styles.description_details_content}>
                  {val.style && val.style.map((v) => v.name)}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Dimensions :
                </div>
                <div className={styles.description_details_content}>
                  {val.height} * {val.width} * {val.depth}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Keywords :
                </div>
                <div className={styles.description_details_content}>
                  {val.keywords}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Orientation :
                </div>
                <div className={styles.description_details_content}>
                  {val.orientation.map((item, i) => item.name)}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Ready to hang :
                </div>
                <div className={styles.description_details_content}>
                  {val.is_hanged}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>Signed :</div>
                <div className={styles.description_details_content}>
                  {val.is_signed}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Subscription :
                </div>
                <div className={styles.description_details_content}>
                  {val.artist_subscription &&
                    val.artist_subscription?.map((val, i) => val.name)}
                </div>
              </div>
              <div className={styles.description_details}>
                <div className={styles.description_details_title}>
                  Description :
                </div>
                <div className={styles.description_details_content}>
                  {val.description}
                </div>
              </div>
              <div className={styles.description_details}>
                {val.exhibitions &&
                  val.exhibitions.map((v, i) => (
                    <>
                      <div className={styles.description_details_title}>
                        Exhibition {i + 1} :
                      </div>
                      <div className={styles.description_details_content}>
                        {v.name}
                      </div>
                    </>
                  ))}
              </div>
              <hr className={styles.horizontal} />
              <h2>{data.detailPrice.heading}</h2>
              <div>
                <p className={styles.details_subheading}>Sale</p>
                <div className={styles.price_details}>
                  <div className={styles.description_details_title}>
                    Availablity :
                  </div>
                  <div className={styles.description_details_content}>
                    {val.sale ? "Sale" : "Not Available"}
                  </div>
                </div>
                <div className={styles.price_details}>
                  <div className={styles.description_details_title}>
                    Price :
                  </div>
                  <div className={styles.description_details_content}>
                    ${val.sale ? val.sale.price : "----"}
                  </div>
                </div>
                {/* ))} */}
                <hr className={styles.hr_short} />
                <div>
                  <p className={styles.details_subheading}>Rent</p>
                  <div className={styles.price_details}>
                    <div className={styles.description_details_title}>
                      Availablity :
                    </div>
                    <div className={styles.description_details_content}>
                      {val.rent ? "Rent" : "Not Available"}
                    </div>
                  </div>
                  <div className={styles.price_details}>
                    <div className={styles.description_details_title}>
                      Price :
                    </div>
                    <div className={styles.description_details_content}>
                      {val?.rent?.rent_price
                        ? `$${val.rent.rent_price}`
                        : "------"}
                    </div>
                  </div>
                  <div className={styles.price_details}>
                    <div className={styles.description_details_title}>
                      Time :
                    </div>
                    <div className={styles.description_details_content}>
                      {val?.rent ? val?.rent?.time : "------"}
                    </div>
                  </div>
                  {/* ))} */}
                  <hr className={styles.hr_short} />
                </div>
              </div>
              {/* <p className={styles.details_subheading}>Sale</p>
                <div className={styles.price_details}>
                    <div className={styles.description_details_title}>Availablity :</div>
                    <div className={styles.description_details_content}>Sale</div>
                </div>
                <hr />
                <p>Rent</p>
                <div className={styles.price_details}>
                    <div className={styles.description_details_title}>Availablity :</div>
                    <div className={styles.description_details_content}>Rent</div>
                </div>*/}
              <p className={styles.details_subheading}>Print</p>
              <div className={styles.price_details}>
                <div className={styles.description_details_title}>
                  Availablity :
                </div>
                <div className={styles.description_details_content}>
                  {val.print.length === 0 ? "Not Available" : "Print"}
                </div>
              </div>
              <div className={styles.price_details_print}>
                <div className={styles.description_details_title}>
                  Size and Price :
                </div>
                <div className={styles.description_details_print_sizing}>
                  {val.print.length > 0 ? (
                    val.print.map((vl, index) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            padding: "7px 0px 0px 0px",
                            mr: 2,
                            alignItems: "flex-start",
                          }}
                        >
                          <div className={styles.title}>Size :</div>
                          <div className={styles.description_details_content}>
                            {vl.size.height}*{vl.size.width}
                            <br />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: 6,
                            alignItems: "center",
                            padding: "1px 0px 0px 0px",
                          }}
                        >
                          <label className={styles.title}>Price :</label>
                          <div className={styles.description_details_content}>
                            {vl.print_price ? vl.print_price : "------"}
                            <br />
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div>----</div>
                  )}
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 15,
                  marginTop: 49,
                }}
              >
                {val.is_accepted === "Approved" ? (
                  <Button
                    variant="contained"
                    sx={{ width: "160px", height: "46px", borderRadius: "16x" }}
                    disabled
                  >
                    Approved
                  </Button>
                ) : val.is_accepted === "Rejected" ? (
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      width: "160px",
                      height: "46px",
                      borderRadius: "16x",
                    }}
                  >
                    Declined
                  </Button>
                ) : val.is_accepted === "Pending" ? (
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      width: "160px",
                      height: "46px",
                      borderRadius: "16x",
                    }}
                  >
                    Pending
                  </Button>
                ) : null}
              </div> */}
            </div>
          </>
        ))}
    </>
  )
}
