import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Divider, IconButton, Menu, MenuItem, Table, Tooltip } from "@mui/material";
import styles from "./Fourcolumnimg.module.css";
import homeImage from "../../../../../constant";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../../../serverConstant";
import CircularLoader from "../../../../../common/loader/Loader";
import { handelAddFollow, handelUnFollow } from "../../../../../State/api/userApi";
import api from "../../../../../State/api/Api";
import { Successnotify } from "../../../../../common/alert/Alert";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Fourcolumnimg(props) {
  console.log(props,'props')
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch()
  const threedots = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const loader = useSelector((state) => state.loader);
  const handlePopup = (e) => {
    // console.log('clicked',e.target.value)
    props.setOpen(true);
    props.setPoptype("addfeature");
  };
  const fetchFollowedArtist = async () => {
    try {
     const {data} = await api.applicationApi("api/buyer/fetchFollowedUser","GET");
    props.setFollowedArtist(data.data.followings)
    }catch(error) {

    }
  }
  const handleAddFollowArtist = (type, id,first_name,last_name) => {
   
    if (type === "follow") {
      dispatch(handelAddFollow({ user_id: id },first_name + " " + last_name))
      fetchFollowedArtist()
    } else {
      dispatch(handelUnFollow({ user_id: id },first_name + " " +  last_name))
    fetchFollowedArtist()
  
    }
  }
  return (
    <div>
      <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ minWidth: 700, borderRadius: "0px" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3", borderRadius: "none" }}>
              {props.head.map((column, index) => (
                <TableCell
                  align={index === 0 ? "center" : "start"}
                  key={column.id}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ( */}
            <TableBody>
            {props.data?.length === 0 ?  <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow> : <>
                {props.data &&
                props.data?.map((row) => (
                  <TableRow
                    className={styles.table_row}
                    key={row.id}
                    style={{
                      color: "#636365",
                      backgroundColor: "#F6F6F6",
                    }}>
                    <TableCell
                onClick={() => window.open(`/user/artist/${row?._id}`, '_blank')}

                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                             cursor:'pointer'
                      }}
                      className={styles.table_column}
                      align="center">
                      <img
                        className={props.poptype === "add" ? styles.add_img : styles.add_img_second}
                        src={` ${row.image}`}
                      />
                    </TableCell>
                    <TableCell
                 onClick={() => window.open(`/user/artist/${row?._id}`, '_blank')}

                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        cursor:'pointer'
                      }}
                      className={styles.table_column}
                      align="left">
                      {row.first_name} {row.last_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left">
                    {row?.followers || 0}
                    </TableCell>
                 
                    <TableCell
                      sx={{
                        width: "25%",
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left">
                      <div className={styles.auction_last_col}>
                        <Button variant="outlined"    onClick={() =>
                            handleAddFollowArtist("unfolow", row._id, row?.first_name,row?.last_name)
                          }>UNFOLLOW</Button>
                      </div>
                    </TableCell>                   
                    
                  </TableRow>
                ))}
                </>}
           
            </TableBody>
          
        </Table>
      </TableContainer>
      <ToastContainer />
    </div>
  );
}
