import validator from "validator";
import { PhoneNumberUtil } from 'google-libphonenumber';
import isEmpty from "./isEmpty";
const isValidFile = file => file instanceof File && file.name && file.size > 0;

export const validationForArtistDetails = (
   first_name,
   last_name,
   email,
   phone,
   country,
   nationality,
   language,
   ID_proof,
   image,
   backgroundImage,
   
   completion 

) => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone_no) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone_no));
    } catch (error) {
      return false;
    }
  };
  const isValid = isPhoneValid(phone);
  language = typeof language === "string" ? language : (language?.toString() || "");
    const isFile = file => file instanceof File;
  
  let artistErrors = {};

  if (validator.isEmpty(first_name)) {
    artistErrors.first_name = "First name is required";
  } else if (
    !validator.isEmpty(first_name) &&
    !/^[A-Za-z\s]+$/.test(first_name)
  ) {
    artistErrors.first_name = "First name can only contain alphabets";
  }
  if (validator.isEmpty(last_name)) {
    artistErrors.last_name = "Last name is required";
  } else if (!validator.isEmpty(last_name) && !/^[A-Za-z\s]+$/.test(last_name)) {
    artistErrors.last_name = "Last name can only contain alphabets";
  }
  if (validator.isEmpty(phone || "")) {
    artistErrors.phone = "Phone number is required";
  }  else if(!isValid){
    artistErrors.phone = "Invalid Phone Number format"
      }
  

  if (validator.isEmpty(email)) {
    artistErrors.email = "Email is required";
  } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    artistErrors.email = "Email is invalid";
  } else {
    // Custom validation to check if email ends with a proper domain (like .com, .org)
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|gov|edu)$/;
    if (!emailPattern.test(email)) {
      artistErrors.email = "Email must have a valid domain (.com, .org, .net, etc.)";
    }
  }

  if(validator.isEmpty(country)) {
    artistErrors.country = "Country is required";
  }

  if(validator.isEmpty(nationality)) {
    artistErrors.nationality = "Nationality is required";
  }
  

  // Validate ID proof file
  if (!isFile(ID_proof) || !ID_proof.name) {
    artistErrors.ID_proof = "ID proof is required";
  }

  // Validate profile image file
  if (!isFile(image) || !image.name) {
    artistErrors.image = "Profile image is required";
  }

  // Validate background image file
  if (!isFile(backgroundImage) || !backgroundImage.name) {
    artistErrors.background_image = "Cover image is required";
  }

  // Validate artwork file
  // if (!isFile(art_work) || !art_work.name) {
  //   artistErrors.art_work = "Artwork image is required";
  // }
  if(validator.isEmpty(language)) {
    artistErrors.language = "Language is required";
  }

  if (validator.isEmpty(completion || "")) {
    artistErrors.completion = "Please confirm the information.";
  } else if(completion === "No"){
    artistErrors.completion = "Please Select Yes to confirm the information is accurate.";
  }
  return {
    artistErrors,
    isValidArtistDetails: isEmpty(artistErrors),
  };
};
