import React, { useState, useEffect } from "react"
import styles from "./CopyLinkModal.module.css"
import { useRef } from "react"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
// import { assignCurator } from "../../../../State/api/superadminApi"
import { Snackbar } from "@mui/material"
import { useDispatch } from "react-redux"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 4,
}
export default function CopyLinkModal(props) {
  const dispatch = useDispatch()

  const textFieldRef = useRef(null)
  const [copied, setCopied] = useState(false)
  const handleCopy = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select()
      document.execCommand("copy")
      setCopied(true)
    }
  }
  const handleCloseLink = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleCloseLink}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            <Typography
              align="center"
              id="transition-modal-title"
              className={styles.assign_heading}
              variant="h6"
              component="h2"
            >
              Copy Link
            </Typography>
            <div className={styles.emailInfo}>
              <Typography fontSize="0.9rem">Text Link</Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter link"
                inputRef={textFieldRef}
                size="small"
                sx={{
                  backgroundColor: "#F3F3F3",
                  border: "none",
                  width: "100%",
                }}
                type="text"
                value={props.link ? props.link : ""}
                name="link"
              />
            </div>
            <Button
              sx={{
                textTransform: "none",
                marginLeft: "36.5%",
                border: "1px solid black",
                marginTop: "3%",
                marginBottom: "4%",
                color: "white",
                width: "30%",
                backgroundColor: "black",
              }}
              onClick={handleCopy}
            >
              Copy Link
            </Button>
            <Snackbar
              open={copied}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => setCopied(false)}
              sx={{ width: "20px" }}
              autoHideDuration={2000}
              message="Link Copied"
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
