import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditHero = (hero) => {
  let editHeroErrors = {}

  if (validator.isEmpty(hero.title)) {
    editHeroErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(hero.sub_title)) {
    editHeroErrors.sub_title = "Subtitle is required"
  }
  if (validator.isEmpty(hero.button_name)) {
    editHeroErrors.button_name = "Button name is required"
  }
  if (validator.isEmpty(hero.position)) {
    editHeroErrors.position = "Position is required"
  }
  if (validator.isEmpty(hero.link)) {
    editHeroErrors.link = "Link is required"
  } else if (!validator.isURL(hero.link)) {
    editHeroErrors.link = "Invalid link"
  }

  if (!hero.image || hero.image === null) {
    editHeroErrors.image = "Image is required"
  }

  return {
    editHeroErrors,
    isValid: isEmpty(editHeroErrors),
  }
}
