import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const curatorDetailsSlice = createSlice({
  name: "curatorDetailsdata",
  initialState,
  reducers: {
    setCuratordetailsdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setCuratordetailsdata } = curatorDetailsSlice.actions

export default curatorDetailsSlice.reducer
