import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const exhibitionAdminSlice = createSlice({
  name: "exhibitiondata",
  initialState,
  reducers: {
    setExhibitiondata: (state, action) => {
      state.data = action.payload
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
})

export const { setExhibitiondata } = exhibitionAdminSlice.actions

// export const exshibitionData = (state) => state.exshibitiondata;

export default exhibitionAdminSlice.reducer
