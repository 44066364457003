import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const orderSlice = createSlice({
    name: 'orderData',
    initialState,
    reducers: {
        setOrderdata: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setOrderdata } = orderSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default orderSlice.reducer;