import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const mainMenuSubMenuSlice = createSlice({
  name: "menuSubMenudata",
  initialState,
  reducers: {
    setMenuSubMenudata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setMenuSubMenudata } = mainMenuSubMenuSlice.actions

export const setMenuSubMenu = (state) => state.setMenuSubMenudata

export default mainMenuSubMenuSlice.reducer
