import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const userpolicySlice = createSlice({
    name: "policydata",
    initialState,
    reducers: {
        setPolicydata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setPolicydata } = userpolicySlice.actions;

export default userpolicySlice.reducer;
