import { createTheme, ThemeProvider, Typography, Container, Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ShippingReturnDets.module.css";

function ShippingReturnDets() {
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
      imgText: {
        fontFamily: "Marcellus",
        fontWeight: 400,
        align: "center",
        fontSize: "1.15vw",
      },
    },
  });

  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.shippingandreturns}>
      <div className={styles.headerCategory}>
        <Container maxWidth="lg">
      <div className={styles.pageitle}>
              <h1>Shipping & Returns</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Shipping & Returns</li>
              </ul>
            </div>
      </Container>
        </div>
        <div className={styles.main__content}>
        <Container maxWidth="lg">
          <h3>Shipping</h3>
          <ol>
              <li className={styles.bullets}>
                Any order placed for Artwork will be fulfilled in accordance
                with the delviery date stated in your order. If ArtSmiley is
                unable to fulfil delivery fo your Artwork, ArtSmiley will notify
                you and arrange for a refund of any applicable sums.
              </li>
              <li className={styles.bullets}>
                Delivery will be completed once the Artwork is delivered to the
                address you have provided.
              </li>
              <li className={styles.bullets}>
                All risk in the Artwork will pass to you on completion of
                delivery
              </li>
              <li className={styles.bullets}>
                You will own the Artwork when all payments for the Artwork,
                including delivery charges, have been paid in full.
              </li>
            </ol>
            <h3>Refunds and Returns</h3>
            <ol>
              <li className={styles.bullets}>
                ArtSmiley offers a 14-day money back guarantee on any Artwork
                (less framing costs, and/or reasonable shipping and delivery
                costs) from the date of deliver("Return Period"), provided that
                (i) the Artwork is returned undamaged to ArtSmiley in the
                original packaging and any other materials provided at the time
                of delivery, and (ii) and provide to us such information
                supporting your request for cancellation as we may reasonably
                require.
              </li>
              <li className={styles.bullets}>
                The return period shall terminate upon the earlier of (a) expiry
                of the 14-day period immediately following delivery, (b) your
                posting of a positive message of feedback in relation to the
                applicable Artwork on any page of the Services, or (c)
                indication, whether by means of tick-box or otherwise, that you
                have approved the applicable delivery of Artwork and/or that you
                waive your rights to refund.
              </li>
              <li className={styles.bullets}>
                You will be responsible for arranging the return shipping of any
                Artwork that you wish to return to ArtSmiley.
              </li>
              <li className={styles.bullets}>
                You are responsible for the integrity of any packages sent
                pursuant to a cancelled order and shall not under any
                circumstances place or permit to be placed any items in the sent
                package that were not in the originally-delivered package, and
                shall not do or permit to be done any act that may be in breach
                or any applicable laws or regulations.
              </li>
              <li className={styles.bullets}>
                You warrant that you will comply with all local and
                international shipping and customs regulations and applicable
                laws.
              </li>
              <li className={styles.bullets}>
                ArtSmiley shall use all reasonable endeavours to return the sums
                paid for the(less framing costs, and/or reasonable, shipping and
                delivery costs) within 30 days of notification of receipt from
                ArtSmiley that the Artwork has been received undamaged in the
                original packaging together with any other materials supplied
              </li>
              <li className={styles.bullets}>
                You will be liable for any costs in returning the Artwork,
                except in the unlikely event that the Artwork is already damaged
                or defective at the time of delivery.
              </li>
              <li className={styles.bullets}>
                Where the Artwork is damaged or defective at the time of
                delivery, ArtSmiley shall be liable for the arranging and costs
                of returning the Artwork to ArtSmiley.
              </li>
            </ol>
        </Container>
         
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ShippingReturnDets;
