import React from "react";
import styles from "./Webpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Commoncard from "./common/Commoncard";
import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import CommoncardCurator from "./common/CommancardCurator";
import CommoncardArtist from "./common/CommancardArtist";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  // width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 0,
};

export default function Webpopup({ open,setOpen,setArtistPop, setCuratorPop,setArtistPopSign,setCuratorPopSign}) {
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  return (
    <>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={styles.modal_del}>
            <div className={styles.card_main}>
              <div className={styles.card}>
                <Commoncard />
                <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have an account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => navigate("/artsmiley/login",{state: {type:"buyer"}})}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.card_odd}>
                <CommoncardArtist setArtistPop={setArtistPop} setOpen={setOpen}  />
                <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have an account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => {
                        setArtistPopSign("ArtistAndGallerySignIn")
                        setOpen(false)
                      }}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.card}>
                <CommoncardCurator setCuratorPop={setCuratorPop} setOpen={setOpen} />
                <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have an account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => {
                        setCuratorPopSign("AffiliateAndCuratorSignIn")
                        setOpen(false)
                      }}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div>
              </div>

              <Box
                sx={{ mt: { xs: "-20px", sm: "-64px" } }}
                className={styles.cancel_imgweb}
              >
                <img
                  alt="img"
                  onClick={() => setOpen(false)}
                  src={ "/img/cancel_cross.png"}
                  height="30px"
                  width="30px"
                />
              </Box>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
