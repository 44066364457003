import { createSlice } from "@reduxjs/toolkit";
import { Errornotify, Successnotify } from "../../../common/alert/Alert";

const initialState = {
  data: null,
};

const subscriptionSlice = createSlice({
  name: "subscriptiondata",
  initialState,
  reducers: {
    // setWishlistdata: (state, action) => {
    //     state.data = state.data.push(action.payload);
    // },
    setAllSubscriptionData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setAllSubscriptionData } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
