import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const curatorExhibitionDetailsSlice = createSlice({
  name: "curatorExhibitionDetailsdata",
  initialState,
  reducers: {
    setCuratorExhibitiondetailsdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setCuratorExhibitiondetailsdata } =
  curatorExhibitionDetailsSlice.actions

export default curatorExhibitionDetailsSlice.reducer
