import React, { useState } from "react";
import styles from "./Recreateartist.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  acceptDeclineRecreateRequest,
  fetchRecreateRequest,
} from "../../../State/api/artistapi";
import Pagination from "../../../components/Pages/List/Pagination/Pagination";
import Recretepopup from "../../../gallery/components/popups/recretepopup/Recretepopup";
import EightcolimgArtist from "../../commonTables/EightcolimgArtist/EightcolimgArtist";
import TableheaderArtist from "../../commonTables/TableheaderArtist/TableheaderArtist";
import { setRecreateData } from "../../../State/Features/Artist/recreateSlice";
import { ToastContainer } from "react-toastify";
import TableForRecreateArtist from "../../commonTables/EightcolimgArtist/TableForRecreateArtist";

const headData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "Buyer Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },

  {
    id: "Request Date",
    label: "Request Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "contact",
    label: "Remark",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
];
const sortdata = [
  { label: "By Date ↓", value: "low" },
  { label: "By Date ↑", value: "high" },
];
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
];
export default function Galleryrecrete() {
  const [open, setOpen] = useState(false);
  const [acceptPopupData, setAcceptPopuupData] = useState(null);
  const recreatedt = useSelector((state) => state.recreateData.data);
  const [search, setSearch] = useState("");
  const [data, setData] = useState({ offset: 1 });
  const [condition, setCondition] = useState({
    sorting: "",
    filter: "",
  });
  
  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchRecreateRequest(data));
  };
  useEffect(() => {
    fetchData();
  }, [data]);
  const handleFilter = (event) => {
    const value = event.target.value;

    setCondition({ ...condition, filter: value });
  };
  const handleSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
  };
  const handleSorting = (event) => {
    const value = event.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setRecreateData({
          data:
            recreatedt &&
            [...recreatedt.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
                return -1;
            }),
          totalCount: recreatedt.totalCount,
        })
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: event.target.value });
      dispatch(
        setRecreateData({
          data:
            recreatedt &&
            [...recreatedt.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
                return -1;
            }),
          totalCount: recreatedt.totalCount,
        })
      );
    } else {
      dispatch(setRecreateData(recreatedt));
      setCondition({ ...condition, sorting: value });
    }
  };
  const handlePopupData = (dt) => {
    setOpen(true);
    setAcceptPopuupData(dt);
  };
  const handleAcceptRejectQuate = (id, dt) => {
    dispatch(acceptDeclineRecreateRequest(id, dt, fetchData));
  };
  return (
    <div className={styles.mains}>
      {open ? (
        <Recretepopup
          handleAcceptRejectRecreate={handleAcceptRejectQuate}
          acceptPopupData={acceptPopupData}
          data={data}
          open={open}
          setOpen={setOpen}
          activetab="galleryrecrete"
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <TableheaderArtist
          setOpen={setOpen}
          handleFilter={handleFilter}
          button=""
          search={search}
          condition={condition}
          filterdata={filterdata}
          sortdata={sortdata}
          handleSearch={handleSearch}
          handleSorting={handleSorting}
        />
        <TableForRecreateArtist
          activetab="galleryrecrete"
          headData={headData}
        
          data={
            [...recreatedt?.recreateRequests]?.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
              ?.filter((val) =>
                val?.item_id?.name
                  ?.toLowerCase()
                  .includes(search?.toLowerCase() || "")
              )
              ?.filter((val) => {
                if (condition.filter === "approved") {
                  return val.is_accepted === "Approved";
                } else if (condition.filter === "rejected") {
                  return val.is_accepted === "Rejected";
                } else if (condition.filter === "pending") {
                  return val.is_accepted === "Pending";
                } else {
                  return true; // Include all if no specific filter is set
                }
              })
          }
          
          setOpen={setOpen}
          handlePopupData={handlePopupData}
        />
        <Pagination
          totaldata={recreatedt && recreatedt.totalCount}
          pagination={data}
          setPagination={setData}
        />
        <ToastContainer />
      </div>
    </div>
  );
}
