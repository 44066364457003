import React, { useEffect, useState } from "react"
import styles from "./Print.module.css"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"

import Pagination from "../Pagination/Pagination"
import { FormControl, MenuItem, Select } from "@mui/material"
import { useParams } from "react-router-dom"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import { useDispatch } from "react-redux"
import api from "../../../../State/api/Api"
import { useCallback } from "react"
import TwoColTable from "../Table/Twocol/TwoColTable"
import Enterdetails from "../Popupdelete/enterDetails/Enterdetails"
import {
  deletePrintMaterial,
  deletePrintProduct,
  editedPrintMaterial,
  editedPrintProduct,
  fetchPrintMaterialData,
  fetchPrintProductData,
  handleAddPrintMaterial,
  handleAddPrintProduct,
} from "../../../../State/api/superadminApi"

const moreOptions = [
  {
    id: "material",
    label: "Material",
    value: "material",
  },
  {
    id: "product",
    label: "Product",
    value: "product",
  },
  {
    id: "productprice",
    label: "Product Price",
    value: "productprice",
  },
]

const materialhead = [
  {
    id: "id ",
    label: "Material ID",
  },
  {
    id: "date ",
    label: "Created Date",
  },
  {
    id: "name",
    label: "Name",
  },
]
const producthead = [
  {
    id: "id ",
    label: "Product ID",
  },
  {
    id: "name",
    label: "Name",
  },
]

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Approved", value: "approved" },
  { label: "Pending", value: "pending" },
  { label: "Cancel", value: "cancel" },
]
export default function Print() {
  const [activetab, setAcitvetab] = useState("material")
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [editdata, setEditdata] = useState()
  const [materialData, setMaterialData] = useState(null)
  console.log(materialData, "materialdata--print")
  const [productData, setProductData] = useState(null)

  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()

  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }

  const fetchMaterialData = () => {
    dispatch(fetchPrintMaterialData(setMaterialData))
  }
  useEffect(() => {
    fetchMaterialData()
  }, [])

  const handleEditdata = (dt) => {
    setEdit(true)
    setEditdata(dt)
  }
  const handleAdd = (dt) => {
    dispatch(handleAddPrintMaterial(dt, setOpen, fetchMaterialData))
  }
  const handleEdit = (dt) => {
    dispatch(editedPrintMaterial(dt._id, dt, setEdit, fetchMaterialData))
  }
  const handleDelete = (id) => {
    dispatch(deletePrintMaterial(id, fetchMaterialData))
  }
  // const handleFilter = (event) => {
  //   const value = event.target.value
  //   setCondition({ ...condition, filter: value })

  //   if (value === "name-asc") {
  //     dispatch(
  //       setMaterialData({
  //         data:
  //           materialData.data &&
  //           [...materialData.data].sort((a, b) =>
  //             a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
  //           ),
  //         totalCount: materialData.totalCount,
  //       })
  //     )
  //   } else if (value === "name-desc") {
  //     dispatch(
  //       setMaterialData({
  //         data:
  //           materialData.data &&
  //           [...materialData.data].sort((a, b) =>
  //             a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
  //           ),
  //         totalCount: materialData.totalCount,
  //       })
  //     )
  //   } else {
  //     fetchMaterialData()
  //   }
  // }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      setMaterialData({
        data:
          materialData.data &&
          [...materialData.data].sort((a, b) => {
            if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase()) return -1
          }),
        totalCount: materialData.totalCount,
      })
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      setMaterialData({
        data:
          materialData.data &&
          [...materialData.data].sort((a, b) => {
            if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase()) return -1
          }),
        totalCount: materialData.totalCount,
      })
    } else {
      fetchMaterialData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Enterdetails
          handleAdd={handleAdd}
          poptype="print"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Enterdetails
          handleEditMaterial={handleEdit}
          poptype="print"
          open={edit}
          editdata={editdata}
          setOpen={setEdit}
        />
      ) : (
        ""
      )}
      <div className={styles.component}>
        <Tableheader
          // handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          setOpen={setOpen}
          handleSearch={handleSearch}
          button={button}
        />
        {/* {activetab === "print" ? (
          <> */}
        {/* <div className={styles.moreoption_div}>
          <Moreoption
            activetab={activetab}
            moreOptions={moreOptions}
            setButton={setButton}
            handleMorecategories={handleMorecategories}
          />
        </div> */}
        {/* {activetab === "material" ? ( */}
        <TwoColTable
          handleEditdata={handleEditdata}
          activetab={activetab}
          handleDelete={handleDelete}
          head={materialhead}
          data={
            materialData &&
            materialData.data
              .filter((val) =>
                val.name.toLowerCase().includes(condition.search.toLowerCase())
              )
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        {/* ) 
        : activetab === "product" ? (
          <TwoColTable
            handleEditdata={handleEditdata}
            activetab={activetab}
            handleDelete={handleDelete}
            head={producthead}
            data={productData && productData.data}
          />
        ) : (
          ""
        )} */}
        {/* <Pagination /> */}
      </div>
    </div>
  )
}
