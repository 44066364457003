import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const useroffersSlice = createSlice({
    name: "useralloffers",
    initialState,
    reducers: {
        setUserAllOffer: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setUserAllOffer } = useroffersSlice.actions;

export default useroffersSlice.reducer;
