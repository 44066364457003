
import isEmpty from "./isEmpty";
import validator from "validator";

const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;
export const validationForSecondFormAffiliate = (
  promote_art_smiley,
  audience_interest,
  build_links,
  generate_income,
  unique_visitor_count,
  primary_reason_to_join,
  hear_about_us,
  traffic_drivation,
  description,
  app_url,
  website_url,
  otherPromotionMethod,
  otherSocialMethod,
  hear_about_us_other,
  description_others,
  traffic_drivation_others,
  completion ,
  platform_influence_marketing,
  other_social_influence,
  mobileAppWebsiteChecked 
) => {
  let applyForm = {};

  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

  if (mobileAppWebsiteChecked && app_url) {
    if (!regex.test(app_url)) {
      applyForm.app_url = "Please enter a valid URL."; // Invalid URL error
    } 
  }
  
  if (mobileAppWebsiteChecked && website_url) {
    if (!regex.test(website_url)) {
      applyForm.website_url = "Please enter a valid URL."; // Invalid URL error
    } 
  }
  if( mobileAppWebsiteChecked && traffic_drivation.includes("Others") && !traffic_drivation_others.length){
    applyForm.traffic_drivation_others = "Please specify the other traffic drive.";
  }
  if( mobileAppWebsiteChecked &&  description.includes("Others") && !description_others.length){
    applyForm.description_others = "Please specify the other description.";
  }

  if (!build_links.length) {
    applyForm.build_links = "Building links information is required";
  }


  if (!promote_art_smiley.length) {
    applyForm.promote_art_smiley = "Your plan to promote Art Smiley services is required";
} else if (promote_art_smiley.includes("Others") && !otherPromotionMethod.trim()) {
    applyForm.promote_art_smiley_other = "Please specify the other promotion services.";
} else if (promote_art_smiley.includes("Others") && otherPromotionMethod.trim()) {
    delete applyForm.promote_art_smiley_other; // Delete error if text is entered
} else if (promote_art_smiley.includes("Social media")) {
    if (!otherSocialMethod.trim()) {
        applyForm.promote_art_smiley_other_social = "Please specify the URLs.";
    } else {
        const invalidUrls = otherSocialMethod.split(",").filter(url => !urlRegex.test(url.trim()));
        if (invalidUrls.length) {
            applyForm.promote_art_smiley_other_social = `The following URLs are invalid: ${invalidUrls.join(", ")}`;
        } else {
            delete applyForm.promote_art_smiley_other_social; // Clear error if all URLs are valid
        }
    }
} else {
    // Clear errors when conditions are not met
    delete applyForm.otherSocialMethod; // Clear error if not needed
    delete applyForm.promote_art_smiley_other_social; // Clear error for social media
    delete applyForm.promote_art_smiley_other; // Clear error if "Others" is not selected
}



  if (!platform_influence_marketing && promote_art_smiley.includes("Influencer Marketing")) {
    applyForm.platform_influence_marketing = "This field is required";
  } else if (platform_influence_marketing === "Others" && promote_art_smiley.includes("Influencer Marketing") && !other_social_influence.trim()) {
    // Only validate other_social_influence if 'Influencer Marketing' is selected and 'Others' is chosen for platform_influence_marketing
    applyForm.other_social_influence = "Please specify the influencer platform for marketing";
  } else {
    delete applyForm.platform_influence_marketing; // Clear error if conditions are met
    delete applyForm.other_social_influence; // Clear error for other_social_influence if not required
  }

  // Validate audience_interest
  if(isEmpty(audience_interest) || audience_interest === "<p><br></p>") {
    applyForm.audience_interest = "Audience interest is required";
  }

  // Validate build_links
 

  if (mobileAppWebsiteChecked && (isEmpty(generate_income) || generate_income === "<p><br></p>")) {
  
    applyForm.generate_income = "Generate income field is required";
  }else {
    delete applyForm.generate_income; // Clear error if valid
  }

  // Validate unique_visitor_count (should be a positive number)
  if (mobileAppWebsiteChecked && !unique_visitor_count) {
    applyForm.unique_visitor_count = "Unique visitor count is required";
  } else if (isNaN(unique_visitor_count) || unique_visitor_count < 0) {
    applyForm.unique_visitor_count = "Please enter a valid positive number for unique visitor count";
  }else {
    delete applyForm.unique_visitor_count; // Clear error if valid
  }

  if(isEmpty(primary_reason_to_join) || primary_reason_to_join === "<p><br></p>") {
    applyForm.primary_reason_to_join = "Primary reason to join is required";
  }

  // Validate hear_about_us
  if (!hear_about_us) {
    applyForm.hear_about_us = "Please specify how you heard about us";
  } else if(hear_about_us === "Others" && !hear_about_us_other.length) {
    applyForm.hear_about_us_other = "Please specify the other way you heard about us.";
  } else {
    delete applyForm.hear_about_us_other; // Clear the error if conditions are met
  }

 

  if (!completion) {
    applyForm.completion = "Please confirm the information.";
  } else if(completion === "No"){
    applyForm.completion = "Please Select Yes to confirm the information is accurate.";
  }
  return {
    applyForm,
    isValidSecondForm: isEmpty(applyForm),
  };
};
