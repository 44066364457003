import React from 'react';
import './OrderComplete.module.css'; // Importing CSS file for styling
import { Button, Step, StepLabel, Stepper } from '@mui/material';

const CompleteOrderSubscription = () => {
  return (

     <>
        <Stepper activeStep={2} sx={{ width: "67%", marginLeft: "16%", marginTop: "5%" }}>
    <Step>
      <StepLabel>Subscription Cart</StepLabel>
    </Step>
  
    <Step >
      <StepLabel>Completed</StepLabel>
    </Step>
  </Stepper>
    <div style={{width:"100%",display:"flex",justifyContent:"center",height:"50vh",alignItems:"center",textAlign:"center"}}>
    <div className="order-success-container">
      <h2>Subscription Purchased Successfully!</h2>
      <Button
                variant="contained"
                onClick={() => window.location.href = "/dashboard"}
                sx={{ width: "136px", height: "44px", borderRadius: "10px",textAlign:'center' }}>
                Click for Profile
              </Button>
      {/* <p>Your subscription placed successfully . Thank you for shopping with us!</p> */}
      {/* <p>Your Order ID: <strong>{JSON.parse(localStorage.getItem('order_id'))}</strong></p> */}
    </div>
    </div>
     </>
  );
}

export default CompleteOrderSubscription;