import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminGift = (gift) => {
  let giftErrors = {}

  if (validator.isEmpty(gift.name)) {
    giftErrors.name = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(gift.description)) {
    giftErrors.description = "Description is required"
  }
  if (validator.isEmpty(gift.min_price)) {
    giftErrors.min_price = "Price is required"
  }
  if (!gift.image || gift.image === null) {
    giftErrors.image = "Image is required"
  }

  return {
    giftErrors,
    isValid: isEmpty(giftErrors),
  }
}
