import React from 'react';
import styles from './Cancel.module.css';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import homeImage from '../../../../../constant';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // height:'700px',
    backgroundColor: '#FFFFFF',
    width: 445,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    pl: 3,
    pr: 1,
    pt: 1,
    pb: 4,
};

const data = [
    {
        id: 1,
        user_title: "Username",
        user_val: "Artsmiley"
    },
    {
        id: 2,
        user_title: "Customer ID",
        user_val: "h3jh45"
    },
    {
        id: 3,
        user_title: "Buyer Name",
        user_val: "Smiley"
    },
    {
        id: 4,
        user_title: "Number of items",
        user_val: "878"
    },
    {
        id: 5,
        user_title: "Email Address",
        user_val: "jhe63hkj@gmail.com"
    },
]

export default function Cancel(props) {

    // const handleOpen = () => props.setOpen(true);
    const handleClose = () => props.setOpen(false);
    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <Box className={styles.modal_del}>
                        <div className={styles.cancel_img}>
                            <img onClick={()=> props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        </div>
                        <p className={styles.modal_heading} id="transition-modal-title" variant="h6" component="h2">
                            John David
                        </p>
                        <div className={styles.textarea}>
                            <textarea className={styles.textareas} placeholder='Write a Remark' rows='10' />
                        </div>
                        <div className={styles.action}>
                            <Button variant='contained' className={styles.action_btn} >Save</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
