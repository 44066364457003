import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditBlog = (blog) => {
  let blogErrors = {}

  if (validator.isEmpty(blog.title)) {
    blogErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {supar
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(blog.description)) {
    blogErrors.description = "Description is required"
  }
  if (validator.isEmpty(blog.date)) {
    blogErrors.date = "Date is required"
  }

  return {
    blogErrors,
    isValid: isEmpty(blogErrors),
  }
}
