import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const getAllMenuSubMenuSlice = createSlice({
  name: "getmenuSubMenudata",
  initialState,
  reducers: {
    setGetallMenuSubMenudata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setGetallMenuSubMenudata } = getAllMenuSubMenuSlice.actions

export const setgetallMenuSubMenu = (state) => state.setGetallMenuSubMenudata

export default getAllMenuSubMenuSlice.reducer
