import validator from "validator";
import isEmpty from "./isEmpty";

export const validationForJoinAffiliate = (
  first_name,
  last_name,
  email,
  company_name,
  phone,
  country,
  language,
  phoneLengthByCountry,
  affiliate_account_type_id
) => {
  let joinAffiliateErrors = {};

  // Validate first name
  if (validator.isEmpty(first_name || "")) {
    joinAffiliateErrors.first_name = "First name is required";
  }

  // Validate last name
  if (validator.isEmpty(last_name || "")) {
    joinAffiliateErrors.last_name = "Last name is required";
  }

  // Validate email
  if (validator.isEmpty(email || "")) {
    joinAffiliateErrors.email = "Email is required";
  } else if (!validator.isEmail(email)) {
    joinAffiliateErrors.email = "Email is invalid";
  }

  if (
    affiliate_account_type_id !== "66307a1362041dbbcad0609b" && // "Private" ID
    validator.isEmpty(company_name || "")
  ) {
    joinAffiliateErrors.company_name = "Company name is required";
  }

  // Validate phone number
  if (validator.isEmpty(phone || "")) {
    joinAffiliateErrors.phone = "Phone number is required";
  } else if (!phone.startsWith(country) &&  phone.length <= country.length) {
    const phoneLength = phoneLengthByCountry[country.toLowerCase()];
    joinAffiliateErrors.phone =
    joinAffiliateErrors.phone = `Phone number should be ${phoneLength} digits after the country code`;
  } else {
    const phoneLength = phoneLengthByCountry[country.toLowerCase()];
    if (phoneLength && phone.length !== country.length + phoneLength) {
      joinAffiliateErrors.phone = `Phone number should be ${phoneLength} digits after the country code`;
    }
  }

  // Validate country
  if (validator.isEmpty(country || "")) {
    joinAffiliateErrors.country = "Country is required";
  }

  // Validate language
  if (validator.isEmpty(language || "")) {
    joinAffiliateErrors.language = "Language is required";
  }

  return {
    joinAffiliateErrors,
    isValid: isEmpty(joinAffiliateErrors),
  };
};
