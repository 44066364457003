import { Button } from "@mui/material"
import React, { useState } from "react"
import serverimage from "../../../../../../serverConstant"
import styles from "./Acceptreject.module.css"

export default function CommonAcceptreject(props) {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.mains}>
      <h1 className={styles.profiler_name}>
        {props.data && props.data.data.exhibition}
      </h1>
      <hr />
      <div className={styles.detail_id}>
        <h1>Exhibition Name</h1>
        <p>{props.data && props.data.data.exhibition}</p>
      </div>{" "}
      <div className={styles.detail_id}>
        <h1>No. of Items</h1>
        <p>{props.data && props.data.data.totalCount}</p>
      </div>
      {/* <hr /> */}
      {props.data && props.data.data && props.data.data.length === 0 ? (
        <h2 align="center">No Data Found</h2>
      ) : (
        //    ) : props.data.data.data && props.data.data.data.length === 0 ? (
        //     <h2 align="center">No Data Found</h2>
        props.data &&
        props.data.data.data &&
        props.data.data.data.map((item, i) => (
          <div key={item._id}>
            <hr />
            <div className={styles.status}>
              <h1>{item.exhibition}</h1>
              {/* <p>{item.content}</p> */}
              {item.is_accepted === "Pending" ? (
                <div className={styles.action_button}>
                  <Button
                    onClick={props.handleAcceptRejejectArt(item._id, {
                      is_accepted: "Approved",
                    })}
                    variant="contained"
                    sx={{ height: "46px", width: "120px" }}
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={props.handleAcceptRejejectArt(item._id, {
                      is_accepted: "Rejected",
                    })}
                    variant="outlined"
                    sx={{ height: "46px", width: "120px" }}
                  >
                    Decline
                  </Button>
                </div>
              ) : (
                item.is_accepted
              )}
            </div>
            <div className={styles.more_detail}>
              <div>
                {item.item &&
                  item.item.map((val, i) => (
                    <div key={val._id}>
                      <div>
                        <img
                          src={ val.image}
                          height="85px"
                          width="121px"
                          alt="img"
                        />{" "}
                      </div>
                      <div className={styles.more_detail_child}>
                        <div className={styles.title}>Bidding Price:</div>
                        <div className={styles.content}>not in db</div>
                      </div>
                      <div className={styles.more_detail_child}>
                        <div className={styles.title}>Material:</div>
                        <div className={styles.content}>
                          {val.material.map((c) => (
                            <>{c.name}, </>
                          ))}
                        </div>
                      </div>
                      <div className={styles.more_detail_child}>
                        <div className={styles.title}>Category:</div>
                        <div className={styles.content}>{val.category}</div>
                      </div>
                      <div className={styles.more_detail_child}>
                        <div className={styles.title}>Dimension:</div>
                        <div className={styles.content}>
                          {val.height}*{val.width}*{val.depth}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {/* {item.action ===  } */}
              {/* <div className={styles.action}>
                            <Button onClick={() => setOpen(true)} variant='contained' className={styles.action_btn}  >Cancel</Button>
                        </div> */}
            </div>
          </div>
        ))
      )}
    </div>
  )
}
