import React, { useEffect, useState } from "react";
import styles from "./UserOrderDetails.module.css";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  Link,
  Modal,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import serverimage from "../../../../../serverConstant";
import api from "../../../../../State/api/Api";
import { useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../../../common/loader/Loader";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { handleAddDisputeBuyer } from "../../../../../State/api/userApi";
const UserOrderDetails = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState(null);
  const top100Films = [
    "Billing",
    "Account",
    "Dashboard Management",
    "Subscription",
    "Technical",
    "Exhibition",
    "Auction",
    "Events",
    "products",
  ]
  const data = [
    {
      title: "No of Items",
      content: "1",
    },
    {
      title: "Type",
      content: "Painting",
    },
    {
      title: "Shipping from",
      content: "Germony",
    },
    {
      title: "Delivery To",
      content: "India",
    },
  ];
  const [disputeValues, setDisputeValues] = useState({
    title: "",
    description: "",
    images: null,
    artist_id: "",
    order_id: "",
    order_item_id: ""
  });
  const [rating, setRating] = useState(
    JSON.parse(localStorage.getItem("rating")) || 0
  );
  const storedRatingId = JSON.parse(localStorage.getItem("rating_id")) || null;
  const storedRating = JSON.parse(localStorage.getItem("rating")) || 0;
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const formatDate = (dateString) => {
    if (!dateString) return "Invalid Date";
    const date = new Date(dateString);
    if (isNaN(date)) return "Invalid Date";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  console.table(orderData, "orderData details");
  const navigate = useNavigate();
  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true);
    try {
      const result = await api.applicationApi(
        `api/order/fetchOrder/${id}`,
        "GET"
      );

      if (result && result.status === 200) {
        setOrderData(result.data.data);
        // dispatch(setLoader(false));
        setLoading(false);
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
      setLoading(false);
    }
  };
  useEffect(() => {
    handleFetchOrders();
  }, []);

  const handleChangeDispute = (e) => {
    setDisputeValues({ ...disputeValues, [e.target.name]: e.target.value });
  };
  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      setDisputeValues({ ...disputeValues, images: img });
      setSrc(imgs);
    }
  };
  const handleSubmitDispute = async () => {
try {
  dispatch(handleAddDisputeBuyer(disputeValues,setOpen))
  // setOpen(false);
  
}catch(error) {
}

    
  };
  const handleRemoveItemcart = async function (id) {
    try {
      const result = await api.applicationApi(
        `api/order/updateOrderItemStatus/${id}`,
        "PATCH",
        {
          order_status: "Cancelled",
          cancellation_reason: "Cancel Testing",
        }
      );
      if (result.status === 200 || result.status === 201) {
        Successnotify(result.data.message);
        setTimeout(() => {
          handleFetchOrders();
        }, 1000);
      } else {
        Errornotify(result.data.message);
      }
    } catch (err) {
    }
  };
  return (
    <>
      <div className={styles.mains}>
        <Breadcrumbs sx={{ padding: "30px 0px" }} aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={() => navigate("/userorder")}
          >
            User Order
          </Link>
          <Link underline="hover" color="inherit">
            User Order Details
          </Link>
        </Breadcrumbs>
        {loading ? (
          <div
            style={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "50vh",
            }}
          >
            <CircularLoader />
          </div>
        ) : (
          <>
            {orderData?.map((val) =>
              val?.items?.map((item) => (
                <>
            
                  <Grid
                    container
                    spacing={{ xs: 0, md: 0 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={5} md={5}>
                      <div className={styles.img_detail}>
                        <div>
                          <p className={styles.dates}>
                            <span style={{ fontWeight: "bold" }}>OrderID:</span>{" "}
                            #{val?.order_number}
                          </p>
                        </div>
                        <div>
                          <p className={styles.dates}>
                            <span style={{ fontWeight: "bold" }}>Date:</span>{" "}
                            {formatDate(val?.order_date)}
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}></Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={{ xs: 0, md: 0 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={5} md={5}>
                      <div className={styles.flex_img}>
                        <div className={styles.img_div}>
                          <img
                            alt="img"
                            src={` ${item?.item?.image}`}
                            className={styles.order_img}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7} position="relative">
                      <div className={styles.flex_description}>
                        <h2 className={styles.main_heading}>
                          {item?.item?.name}
                        </h2>
                        <p className={styles.present_by}>
                          By{" "}
                          {item?.artist?.first_name +
                            " " +
                            item?.artist?.last_name}
                        </p>
                        <div className={styles.price_detail}>
                          <h4>${item?.item_amount}</h4>
                          {/* <p className={styles.mrp}>
                            <del>$0</del>
                          </p> */}
                          <p className={styles.tax_detail}>incl. all taxes</p>
                        </div>
                        <div className={styles.rating}>
                        {false ?   <Rating
              name="customized-10"
             defaultValue={storedRatingId === val._id ? storedRating : 0}
              max={5}
              onChange={(event, newValue) => {
                localStorage.setItem("rating", JSON.stringify(newValue));
                localStorage.setItem("rating_id", JSON.stringify(val._id));
                setRating(newValue); // Update the rating state
              }}
            /> : null}
                          {/* <img src={ '/img/rating.png'} />
                            <img src={ '/img/rating.png'} />
                            <img src={ '/img/rating.png'} />
                            <img src={ '/img/rating.png'} /> */}
                       {false ?    <p>
                            <u>Write a review</u>{" "}
                          </p> : null}
                        </div>
                        <p className={styles.para_description}>
                          {item?.item?.description.slice(0,300)}
                        </p>

                        <div className={styles.main_detail} key={val.title}>
                          <h6>No Of Items:</h6>
                          <p>01</p>
                        </div>
                        <div className={styles.main_detail} key={val.title}>
                          <h6>Type:</h6>
                          <p>{item.order_type?.name}</p>
                        </div>
                        <div className={styles.main_detail} key={val.title}>
                          <h6>Shipping From:</h6>
                          <p>UAE</p>
                        </div>
                        <div className={styles.main_detail} key={val.title} style={{paddingBottom:"10px"}}>
                          <h6>Delivery To:</h6>
                          <p>{val?.address?.country}</p>
                        </div>

                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            className={styles.track_btn}
                          >
                            Track Order
                          </Button>
                          <Button
                            variant="contained"
                            className={styles.track_btn}
                            sx={{background:"rgba(246, 246, 246, 1)",color:"rgba(42, 42, 44, 1)"}}
                          >
                            Reorder
                          </Button>
                          {item?.order_status === "Cancelled" ?   <h6
                            style={{
                              fontSize: "14px",

                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            Cancelled
                          </h6> :   <Button
                           onClick={() => handleRemoveItemcart(item?._id)}
                            variant="contained"
                            className={styles.track_btn}
                            sx={{background:"rgba(246, 246, 246, 1)",color:"rgba(42, 42, 44, 1)"}}
                          >
                            Cancel Order
                          </Button>}
                        
                         {item?.order_status === "Cancelled" ?   <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              // padding: "8px 0px 0px 5px",
                            }}
                          >
                            <Link
                              onClick={() => {
                                setDisputeValues({
                                  ...disputeValues,
                                  order_id: val?._id,
                                  artist_id: item?.artist?._id,
                                  order_item_id: item?.item?._id
                                });
                                setOpen(true);
                              }}
                              // onClick={() => setOpen(true)}
                              underline="none"
                              sx={{
                                fontSize: "14px",
                                textDecoration: "underline",
                                color: "gray",
                                cursor: "pointer",
                              }}
                            >
                              {"Generate Dispute"}
                            </Link>
                          </div> : null}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <hr className={styles.hrs} />
                </>
              ))
            )}
          </>
        )}
      </div>
      
      <Modal
        // sx={{  outline: 0,
        //   border: 'none' }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.makeofferModal}>
          <img
            sx={{ padding: "5px", cursor: "pointer" }}
            className={styles.modalclosebutton}
            onClick={() => setOpen(false)}
            src={"/img/image 86.png"}
            alt="img"
          />
          <Typography textAlign="center" fontWeight="600" fontSize="1.25rem">
            Generate Dispute
          </Typography>
          <div className={styles.emailInfo}>
            <Typography mb={1} sx={{padding:"5px 5px 0px 0px",color:"#1C1C1C",fontWeight:"500",fontSize:"18px"}}>Title</Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Enter Title"
              size="small"
              sx={{
                backgroundColor: "#F3F3F3",
              
                width: "100%",
                border: 'none',"& fieldset": { border: 'none' },
                "&:focus": { outline: "none" },
              }}
              type="text"
              value={disputeValues.title}
              name="title"
              onChange={handleChangeDispute}
            />
          </div>
          <div className={styles.emailInfo}>
          <label className={styles.search_label}>Select Reason</label>
                <Autocomplete
                  // disablePortal
                  id="combo-box-demo"
                  disableClearable
                  size="small"
                  options={top100Films}
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    color: "#636365",
                    lineHeight: "21px",
                    mt: 0.5,
                    backgroundColor: "#F6F6F6",
                    border: "none",
                    borderRadius: "6px",
                  }}
                  className={styles.search_select}
                  fullwidth
                  // value={generateDisputeValues.description}
                  onChange={handleChangeDispute}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        backgroundColor: "#f6f6f6",
                        borderRadius: "6px",
                        color: "#636365",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-root": {
                          // height: 40
                        },
                      }}
                      {...params}
                      fullwidth
                      placeholder="Select Theme"
                    />
                  )}
                />
          </div>
                
           
          <Typography  ml={3} sx={{padding:"5px 5px 8px 0px",color:"#1C1C1C",fontWeight:"500",fontSize:"18px"}}>
            Description
          </Typography>
          <TextareaAutosize
            maxRows={5}
            minRows={5}
            name="description"
            className={styles.textarea}
            fullWidth
            placeholder="Enter Description"
            value={disputeValues.description}
            onChange={handleChangeDispute}
          />

          <div>
            <Typography mt={2} ml={3}  sx={{color:"#1C1C1C",fontWeight:"500",fontSize:"18px"}}>
              Upload Proof
            </Typography>
            {/* <br /> */}

            <div className={styles.preview_img}>
              {src === null ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="input"
                    style={{ textAlign: "center" }}
                    onClick={handleChangeImage}
                  >
                    <input
                      onChange={handleChangeImage}
                      type="file"
                      id="input"
                      hidden
                    />
                    <label htmlFor="input" className={styles.label}>
                      <div className={styles.image_div}>
                        <img
                          src={"/img/uploadimg.png"}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </div>
                      <p style={{color:"#665D6F",fontWeight:'400',fontSize:"16px",fontFamily:"DM Sans"}}>  Upload Your Image
                            </p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    onChange={handleChangeImage}
                    type="file"
                    id="input"
                    hidden
                  />
                  <label htmlFor="input">
                    <img
                      src={src}
                      alt="img"
                      id="img"
                      className={styles.img_view}
                    />
                  </label>
                </>
              )}
              <div className={styles.file_div}></div>
              {src !== null ? (
                <div className={styles.del_img_div}>
                  <IconButton>
                    <img
                      src={"/img/delete.png"}
                      onClick={() => {
                        setSrc(null);
                        setDisputeValues({ ...disputeValues, image: null });
                      }}
                      height="15px"
                      width="14px"
                      alt="img"
                    />
                  </IconButton>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Button
            sx={{
              textTransform: "none",
              marginLeft: "36.5%",
              border: "1px solid black",
              marginTop: "3%",
              marginBottom: "4%",
              color: "white",
              width: "30%",
              backgroundColor: "black",
            }}
            // onClick={handleSubmitOffer}
            onClick={handleSubmitDispute}
          >
            Generate Dispute
          </Button>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default UserOrderDetails;
