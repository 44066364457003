import validator from "validator"
import isEmpty from "./isEmpty"

export const validationForArtistApplication = (about, award_prize, art_work,

) => {
  const isFile = file => file instanceof File;
  let artistApplicationErrors = {}
  award_prize = !award_prize ? "" : award_prize
  if (validator.isEmpty(about) || about === "<p><br></p>") {
    artistApplicationErrors.about = "About yourself is required"
  }

  if (validator.isEmpty(award_prize) || award_prize === "<p><br></p>") {
    artistApplicationErrors.award_prize = "Exhibitions , awards/prizes are required"
  }

  if (!Array.isArray(art_work) || art_work.length === 0) {
  
  artistApplicationErrors.art_work = "At least 2 artworks are required.";
} else if (art_work.length < 2 || art_work.length > 10) {
 
  artistApplicationErrors.art_work = "Artwork should be between 2 and 10.";
} else {
  
  const invalidFiles = art_work.some(
    (file) => !isFile(file) || !file.name
  );
  if (invalidFiles) {
    artistApplicationErrors.art_work = "All artworks must be valid files.";
  }
}

  return {
    artistApplicationErrors,
    isValid: isEmpty(artistApplicationErrors),
  }
}
