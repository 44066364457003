import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

const curatorSlice = createSlice({
  name: "curatorData",
  initialState,
  reducers: {
    setCuratordata: (state, action) => {
      state.data = action.payload;
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
});

export const { setCuratordata } = curatorSlice.actions;

export const curatorData = (state) => state.curatorData;

export default curatorSlice.reducer;
