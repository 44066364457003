import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminMenu = (menu) => {
  let menuErrors = {}

  if (validator.isEmpty(menu.name)) {
    menuErrors.name = "Name is required"
  }

  if (validator.isEmpty(menu.link)) {
    menuErrors.link = "Link is required"
  } else if (!validator.isURL(menu.link)) {
    menuErrors.link = "Please enter a valid URL"
  }

  return {
    menuErrors,
    isValid: isEmpty(menuErrors),
  }
}
