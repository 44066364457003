import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const offerSlice = createSlice({
    name: 'offerData',
    initialState,
    reducers: {
        setOfferdata: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { setOfferdata } = offerSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default offerSlice.reducer;