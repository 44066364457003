import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./ArtistSidebar.module.css";
import {
  setArtistTypeFilter,
  setCategoryFilter,
  setCountryFilter,
} from "../../../State/Features/user/filterationSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import {
  fetchArtistTypes,
  fetchCategoryData,
} from "../../../State/api/userApi";
import CircularLoader from "../../../common/loader/Loader";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";

function ArtistSidebar(props) {
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#ccc",
      },
    },
    typography: {
      accordionheading: {
        fontSize: "14px",
        fontWeight: 500,
      },
      radioname: {
        fontSize: "0.95vw",
        fontWeight: "400",
      },
    },
  });
  const loader = useSelector((state) => state.loader);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const allcategory = useSelector((state) => state.allcategoryData);
  const [countrydt, setCountrydt] = useState();

  const handleFetchCountry = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getcountry`);
      if (result && result.status === 200) {
        setCountrydt(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleFilterCategory = (e) => {
    const val = e.target.id;
    dispatch(setCategoryFilter(val));
  };
  const handleCountry = (e) => {
    const val = e.target.value;
    dispatch(setCountryFilter(val));
  };
  const handleArtistType = (e) => {
    const val = e.target.id;
    dispatch(setArtistTypeFilter(val));
  };
  useEffect(() => {
    handleFetchCountry();
    dispatch(fetchArtistTypes());
    dispatch(fetchCategoryData({}));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.artist__sidebar}>
        <div className={styles.ProductSidebar}>
          <Accordion
            elevation={0}
            sx={{
              border: "none",
              backgroundColor: "rgb(231, 230, 230)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="accordionheading">
                <b>CATEGORY</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loader.loader ? (
                <div className={styles.loader}>
                  <CircularLoader />
                </div>
              ) : (
                allcategory.data &&
                allcategory.data.allcategories.map((val) => (
                  <div className={styles.radio__div}>
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      type="checkbox"
                      name={val.name}
                      value={val.name}
                      id={val._id}
                      onClick={handleFilterCategory}
                      size="small"
                      color="primary"
                      checked={props.filter?.category?.includes(val._id)}
                    />
                    <label className={styles.radio__label} htmlFor="Art">
                      {val.name}
                    </label>
                    <br />
                    <br />
                  </div>
                ))
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            elevation={0}
            sx={{
              border: "none",
              backgroundColor: "rgb(231, 230, 230)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="accordionheading">
                <b>FILTERS</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loader.loader ? (
                <div className={styles.loader}>
                  <CircularLoader />
                </div>
              ) : (
                artistTypes.data &&
                artistTypes.data.map((val) => (
                  <div className={styles.radio__div}>
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      name={val.name}
                      value={val._id}
                      id={val._id}
                      size="small"
                      color="primary"
                      onClick={handleArtistType}
                      checked={props.filter?.artist_type.includes(val._id)}
                    />
                    <label className={styles.radio__label} htmlFor="Emerging">
                      {val.name}
                    </label>
                    <br />
                    <br />
                  </div>
                ))
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion
            elevation={0}
            sx={{
              border: "none",
              backgroundColor: "rgb(231, 230, 230)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="accordionheading">
                <b>NATIONALITIES</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {countrydt &&
                countrydt.map((val) => (
                  <div className={styles.radio__div} key={val._id}>
                    <Checkbox
                      name={val.name}
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      value={val.name}
                      id={val._id}
                      size="small"
                      color="primary"
                      onClick={handleCountry}
                    />
                    <label className={styles.radio__label} htmlFor="Albania">
                      {val.name}
                    </label>
                    <br />
                    <br />
                  </div>
                ))}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ArtistSidebar;
