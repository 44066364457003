import React from 'react';
import styles from './Termscondition.module.css';
export default function Termscondition() {
    return (
        <div className={styles.mains}>
            <div className={styles.components} >this is Termscondition
            </div>
        </div>
    )
}
