import React, { useEffect } from "react";
import styles from "./Exshibitiondetailgallery.module.css";
import Commondetailexshibitiongallery from "../commonExshibitiongallery/details/Commondetailexshibitiongallery";
import { Button } from "@mui/material";
import {
 acceptDeclineExshibitionArt,
 fetchExhibitionArtData,
} from "../../../../State/api/superadminApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const data1 = [
 {
  id: 1,
  title: "Exshibition name",
  content: "Art of Space",
 },
 {
  id: 2,
  title: "No. of items",
  content: "06",
 },
];
const data = [
 {
  main_title: "Art Of Space",
  content: "0",
  img: "/img/Image 1.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
 {
  main_title: "The Wonder of India",
  content: "1",
  img: "/img/Image 2.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "Remarks*  Lorem ip dolor sit amet corcresdac",
 },
 {
  main_title: "Way Of Seeing",
  content: "2",
  img: "/img/Image 3.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
];

export default function Exshibitiondetailgallery() {
 const { id } = useParams();
 const exhibitionAllArt = useSelector((state) => state.exhibitionAllArt.data);

 const dispatch = useDispatch();
 const fetchData = () => {
  dispatch(fetchExhibitionArtData(id));
 };
 useEffect(() => {
  fetchData();
 }, []);
 const handleAcceptRejejectArt = (ids, data) => {
  dispatch(acceptDeclineExshibitionArt(ids, data, fetchData));
 };
 return (
  <div className={styles.mains}>
   <Commondetailexshibitiongallery
    type="exshibition_gallery"
    data={exhibitionAllArt}
    handleAcceptRejejectArt={handleAcceptRejejectArt}
   />
   <div className={styles.footer}>
    <span>* Lorem ip dolor sit amet corcresdac</span>
    <Button variant="contained" sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
     Pay Now
    </Button>
   </div>
  </div>
 );
}
