import React, { useEffect } from "react"
import styles from "./CuratorExhibitionDetails.module.css"
import Tablesfour from "../../Table/Fourcolumn/Tables"
import { IconButton } from "@mui/material"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import TableHead from "@mui/material/TableHead"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import TableBody from "@mui/material/TableBody"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { fetchcuratorExhibitionDetails } from "../../../../../State/api/superadminApi"
import { Box } from "@mui/material"
import CircularLoader from "../../../../../common/loader/Loader"

const approvedhead = [
  {
    id: "artist_name",
    label: "Artist Name",
  },
  {
    id: "number",
    label: "Mobile Number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "type",
    label: "Type",
  },
]

export default function CuratorExhibitiondetails() {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loader = useSelector((state) => state.loader)
  const curatorDetailsData = useSelector(
    (state) => state.curatorExhibitionDetailsData.data
  )
  console.log(curatorDetailsData, "curatorExhibitionDetailsData-------")
  useEffect(() => {
    dispatch(fetchcuratorExhibitionDetails(id))
  }, [dispatch, id])

  const handleBack = () => {
    navigate("/exhibitions")
  }

  return (
    <div className={styles.mains}>
      <div className={styles.back_button}>
        <img
          src={"/img/_ Back.png"}
          onClick={handleBack}
          style={{ cursor: "pointer" }}
          className={styles.backimage}
          height="13px"
          width="46px"
          alt="img"
        />
      </div>

      {curatorDetailsData && (
        <div>
          <div className={styles.top_div}>
            <h1>{curatorDetailsData?.title}</h1>
            <div className={styles.del_img}>
              <IconButton>
                <img
                  src={"/img/delete.png"}
                  alt="img"
                  width="20px"
                  height="23px"
                  className={styles.del_ig}
                />
              </IconButton>
            </div>
          </div>
          <hr />

          <div className={styles.detail_earning}>
            <p className={styles.title}>Concept :</p>
            <p className={styles.content}>{curatorDetailsData.concept}</p>
          </div>
          <div className={styles.detail_earning}>
            <p className={styles.title}>end date registration :</p>
            <p className={styles.content}>
              {dayjs(curatorDetailsData.end_date_registration)
                .format("YYYY-MM-DD")
                .toString()}
            </p>
          </div>
          <div className={styles.detail_earning}>
            <p className={styles.title}>Chief Guest :</p>
            <p className={styles.content}>{curatorDetailsData.chief_guest}</p>
          </div>
          <div className={styles.detail_earning}>
            <p className={styles.title}>Venue :</p>
            <p className={styles.content}>{curatorDetailsData.venue_address}</p>
          </div>
        </div>
      )}

      <div className={styles.mains1}>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#f6f6f6", color: "black" }}
        >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {approvedhead.map((column, i) => (
                  <TableCell
                    align={approvedhead.length - 1 === i ? "left" : "left"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader.loader ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}>
                      <CircularLoader size={60} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {curatorDetailsData &&
                curatorDetailsData[0]?.curators[0]?.artists.length > 0 ? (
                  curatorDetailsData[0]?.curators?.map(
                    (curator, curatorIndex) =>
                      curator?.artists?.map((artist, artistIndex) => (
                        <TableRow
                          key={`${curatorIndex}-${artistIndex}`}
                          style={{ color: "#636365", height: "30px" }}
                          className={styles.table_row}
                        >
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.artist_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.phone}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.email}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontFamily: "DM Sans",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "16px",
                              lineHeight: "21px",
                              color: "#636365",
                            }}
                            className={styles.table_column}
                            align="left"
                          >
                            {artist?.type}
                          </TableCell>
                        </TableRow>
                      ))
                  )
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Box className={styles.loader_box}>No Data Found</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}
