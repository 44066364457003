import React from 'react';
import Commonnewsgallery from './common/Commonnewsgallery';


const access_roles = [
    {
        title: 'Newsletter',
    },
    {
        title: 'Notification',
    },
    {
        title: 'Offer',
    },
    {
        title: 'Events',
    },
    {
        title: 'Blogs',
    },
    {
        title: 'Customised Campaings',
    }
]
export default function Gallerynews() {
    return (
        <>
            <Commonnewsgallery access_roles={access_roles} />
        </>
    )
}
