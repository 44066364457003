import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const getAllaffiliateLinkSlice = createSlice({
  name: "getAllaffiliateLinkdata",
  initialState,
  reducers: {
    setAllAffiliateLinkdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setAllAffiliateLinkdata } = getAllaffiliateLinkSlice.actions

export const affliateLinkData = (state) => state.getAllaffiliateLinkdata

export default getAllaffiliateLinkSlice.reducer
