import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const adminOfferDataSlice = createSlice({
  name: "adminoffer",
  initialState,
  reducers: {
    setadminOfferData: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setadminOfferData } = adminOfferDataSlice.actions;

export default adminOfferDataSlice.reducer;
