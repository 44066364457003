import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminTestimonials = (testimonial) => {
  let testimonialsErrors = {}

  if (validator.isEmpty(testimonial.customer_name)) {
    testimonialsErrors.customer_name = "Name is required"
  }

  if (validator.isEmpty(testimonial.position)) {
    testimonialsErrors.position = "Position is required"
  }

  if (!testimonial.image) {
    testimonialsErrors.image = "Testimonials image is required"
  }

  if (validator.isEmpty(testimonial.description)) {
    testimonialsErrors.description = "Description is required"
  }

  return {
    testimonialsErrors,
    isValid: isEmpty(testimonialsErrors),
  }
}
