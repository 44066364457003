import validator from "validator";
import isEmpty from "./isEmpty";

export const validateAdminPassword = (pass) => {
  let passErrors = {};

  // Validate old password
  if (validator.isEmpty(pass.oldPassword)) {
    passErrors.oldPassword = "Kindly enter the current password.";
  }

  // Validate new password
  if (validator.isEmpty(pass.newPassword)) {
    passErrors.newPassword = "Kindly mention the new password.";
  } else if (pass.newPassword === pass.oldPassword) {
    passErrors.newPassword = "New password cannot be the same as the current password.";
  } else if (!validator.isLength(pass.newPassword, { min: 8 })) {
    passErrors.newPassword = "New password must be at least 8 characters long.";
  }

  // Validate confirm new password
  if (validator.isEmpty(pass.confirm_new_password)) {
    passErrors.confirm_new_password = "Kindly confirm the new password.";
  } else if (pass.newPassword !== pass.confirm_new_password) {
    passErrors.confirm_new_password = "Incorrect, Kindly confirm the new password.";
  }

  return {
    passErrors,
    isValid: isEmpty(passErrors),
  };
};
