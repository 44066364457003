import validator from "validator";
import isEmpty from "./isEmpty";

export const firstFormArtValidation = (
name,
image,

) => {
  const isFile = file => file instanceof File;
  
  let firstFormErrors = {};

   if(validator.isEmpty(name)) {
    firstFormErrors.title = "Title is required";
   }
  // Validate ID proof file

  // Validate ID proof file
  if(typeof image !== "string") {
    if (!isFile(image) || !image.name) {
      firstFormErrors.image = "image is required";
    }
  }

  return {
    firstFormErrors,
    isValidFirstForm: isEmpty(firstFormErrors),
  };
};
