import React from 'react'
import styles from './Setterpgallery.module.css'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import StepConnector, from '@mui/material/StepConnector';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';


const steps = [
    { label: 'Image', },
    { label: 'Description', },
    { label: 'Price & Details', },
];


const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16p)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'black',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'black',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
export default function Steppergallery(props) {
    return (
        <div style={{ textAlign: 'center' }}>
            {/* <Stepper className={styles.steppers} activeStep={1} alternativeLabel >
                {steps.map((label) => (
                    <Step className={styles.steps}  sx={{ textAlign: 'center',px:0,maxWidth: 'maxContent',minWidth:'maxContent' }} key={label.label}>
                        <StepLabel className={styles.steppers_step} sx={{ width: 'maxContent' }}>{label.label}</StepLabel>
                    </Step>
                ))}
            </Stepper> */}
            <Stepper className={styles.steppers} alternativeLabel activeStep={props.stage} connector={<QontoConnector />}>
                {steps.map((label) => (
                    <Step key={label.label} className={styles.steps}>
                        <StepLabel>{label.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    )
}
