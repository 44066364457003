import * as React from "react"
import styles from "./Category.module.css"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Button, TextField } from "@mui/material"
import { useState } from "react"
import Tabss from "../Common/Tabs/Tabs"
import homeImage from "../../../../constant"
import {
  deleteCategory,
  fetchCategories,
  handleAddStyle,
} from "../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import Addpaintingexshibition from "../../../../artist/pages/exshibition/popup/Addpaintingexshibition"
import Editcategory from "../Popupdelete/editCategory/Editcategory"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import Categorylist from "./categoryList/Categorylist"
// import TextField from '@mui/material/TextField';

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

export default function Category() {
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)
  const [category_id, setCategory_id] = useState("")
  const [category, setCategory] = useState({ add: false, edit: false })
  const [name, setName] = useState({ name: "", _id: "", image: "" })
  const [subcatData, setSubcateData] = useState({
    style: "",
    medium: "",
    material: "",
    subject: "",
  })

  const handleChangeSubData = (e) => {
    const { name, value } = e.target
    setSubcateData({ ...subcatData, [name]: value })
  }
  const handleSubmit = (type) => {
    switch (type) {
      case "style":
        setSubcateData({ ...subcatData, style: "" })
        dispatch(
          handleAddStyle("api/itemStyle/addItemStyle", {
            category_id: category_id,
            name: subcatData.style,
          })
        )
        break
      case "medium":
        setSubcateData({ ...subcatData, medium: "" })
        dispatch(
          handleAddStyle("api/itemMedium/addItemMedium", {
            category_id: category_id,
            name: subcatData.medium,
          })
        )
        break
      case "material":
        setSubcateData({ ...subcatData, material: "" })
        dispatch(
          handleAddStyle("api/itemMaterial/addItemMaterial", {
            category_id: category_id,
            name: subcatData.material,
          })
        )
        break
      case "subject":
        setSubcateData({ ...subcatData, subject: "" })
        dispatch(
          handleAddStyle("api/itemSubject/addItemSubject", {
            category_id: category_id,
            name: subcatData.subject,
          })
        )
        break
      default:
    }
  }

  const categoriesData = useSelector((state) => state.categoryData.data)
  const fetchData = () => {
    dispatch(fetchCategories(setCategory_id))
  }
  React.useEffect(() => {
    fetchData()
  }, [])
  const handleAddCategoryPopup = (type, value) => {
    if (type === "add") {
      setCategory({ ...category, add: true })
    } else {
      setCategory({ ...category, edit: true })
      setName({
        ...name,
        name: value.name,
        _id: value._id,
        image: value.image,
      })
    }
  }
  const handleNewChange = (e) => {
    setName({ ...name, name: e.target.value })
  }
  const handleDelete = (id) => {
    dispatch(deleteCategory(id, fetchData))
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Addpaintingexshibition open={open} setOpen={setOpen} type="category" />
      ) : (
        ""
      )}
      {category.add ? (
        <Editcategory
          handleNewChange={handleNewChange}
          name={name}
          type="add"
          open={category.add}
          fetchData={fetchData}
          setOpen={setCategory}
        />
      ) : (
        ""
      )}

      {category.edit ? (
        <Editcategory
          type="edit"
          handleNewChange={handleNewChange}
          name={name}
          open={category.edit}
          fetchData={fetchData}
          setOpen={setCategory}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <div className={styles.category_btn}>
          <Button
            onClick={() => handleAddCategoryPopup("add")}
            variant="contained"
            sx={{ height: "44px", width: "141px" }}
          >
            Add Category
          </Button>
        </div>
        {/* <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider' }}
                    >
                        <Tab label="Item One" {...a11yProps(0)} />
                        <Tab label="Item Two" {...a11yProps(1)} />
                        <Tab label="Item Three" {...a11yProps(2)} />
                        <Tab label="Item Four" {...a11yProps(3)} />
                        <Tab label="Item Five" {...a11yProps(4)} />
                        <Tab label="Item Six" {...a11yProps(5)} />
                        <Tab label="Item Seven" {...a11yProps(6)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        Item Three
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        Item Four
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        Item Five
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        Item Six
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        Item Seven
                    </TabPanel>
                </Box> */}
        <div className={styles.card_main}>
          <div>
            <Tabss
              category_id={category_id}
              setCategory_id={setCategory_id}
              label={categoriesData && categoriesData}
            />
          </div>
          <div className={styles.card}>
            <div className={styles.heading_main}>
              <div>Category</div>
              <div className={styles.hrline}></div>
            </div>
            <div className={styles.detail_inputs}>
              <TextField
                value={
                  categoriesData &&
                  categoriesData.filter((val) => val._id === category_id)
                    .length !== 0
                    ? categoriesData &&
                      categoriesData.filter((val) => val._id === category_id)[0]
                        .name
                    : ""
                }
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiInputBase-root": {
                    height: 50,
                  },
                }}
                id="outlined-basic"
                placeholder="Category Name"
                className={styles.input}
                fullWidth
                variant="outlined"
              />
            </div>
            {/* <div className={styles.upload_img}>
                            <div className={styles.img_heading}>Images</div>
                            <div className={styles.preview_img}>
                                {src === '' ?
                                    <div className={styles.img_box}>
                                        <div htmlFor='input' style={{ textAlign: 'center' }}
                                            onClick={handleChangeImage}>
                                            <input
                                                onChange={handleChangeImage}
                                                type="file"
                                                id='input'
                                                hidden
                                            /><label htmlFor='input' className={styles.label}>
                                                <img src={ '/img/uploadimg.png'} width='46px' height='43px' alt='img' />
                                                <p>  Upload your image
                                                </p></label>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <input
                                            onChange={handleChangeImage}
                                            type="file"
                                            id='input'
                                            hidden
                                        />
                                        <label htmlFor='input'>
                                            <img src={src} alt='img' id='img' className={image ? styles.img_view : styles.img_view_hide} />
                                        </label>
                                    </>
                                }
                            </div>
                           
                            <div className={styles.file_div}></div>
                            <p>Upload an image of your artwork.Your file must be least 1200*1500 px , a JPG or PNG file ,width a color format of rgb,and less than 50MB</p>
                        </div> */}

            <div className={styles.heading_main}>
              <div className={styles.subheading}>Sub Category</div>
              {/* <Button variant="contained">Show List</Button> */}
            </div>
            {/* {data.map((val,i)=>( */}

            <div className={styles.detail_inputs}>
              <div className={styles.input_detail_div}>
                <div className={styles.subheading_head}>Style</div>
                <div className={styles.img_text_add}>
                  <Button
                    sx={{ height: "40px" }}
                    onClick={() => handleSubmit("style")}
                  >
                    <img
                      style={{ marginRight: "5px" }}
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                      alt="img"
                    />
                    <p>Add Style</p>
                  </Button>
                </div>
              </div>
              <div>
                <TextField
                  onChange={handleChangeSubData}
                  name="style"
                  value={subcatData.style}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter Style"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>
            {/* ))} */}
            <div className={styles.detail_inputs}>
              <div className={styles.input_detail_div}>
                <div className={styles.subheading_head}>Medium</div>
                <div className={styles.img_text_add}>
                  <Button
                    sx={{ height: "40px" }}
                    onClick={() => handleSubmit("medium")}
                  >
                    <img
                      style={{ marginRight: "5px" }}
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                      alt="img"
                    />
                    <p>Add Medium</p>
                  </Button>
                </div>
              </div>
              <div>
                <TextField
                  onChange={handleChangeSubData}
                  name="medium"
                  value={subcatData.medium}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter Medium"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>
            <div className={styles.detail_inputs}>
              <div className={styles.input_detail_div}>
                <div className={styles.subheading_head}>Material</div>
                <div className={styles.img_text_add}>
                  <Button
                    sx={{ height: "40px" }}
                    onClick={() => handleSubmit("material")}
                  >
                    <img
                      style={{ marginRight: "5px" }}
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                      alt="img"
                    />
                    <p>Add Material</p>
                  </Button>
                </div>
              </div>
              <div>
                <TextField
                  onChange={handleChangeSubData}
                  name="material"
                  value={subcatData.material}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter menu"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>
            <div className={styles.detail_inputs}>
              <div className={styles.input_detail_div}>
                <div className={styles.subheading_head}>Subject</div>
                <div className={styles.img_text_add}>
                  <Button
                    sx={{ height: "40px" }}
                    onClick={() => handleSubmit("subject")}
                  >
                    <img
                      style={{ marginRight: "5px" }}
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                      alt="img"
                    />
                    <p>Add Subject</p>
                  </Button>
                </div>
              </div>
              <div>
                <TextField
                  onChange={handleChangeSubData}
                  name="subject"
                  value={subcatData.subject}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter Subject"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </div>
            </div>
            {/* <div className={styles.save_btn}>
                            <Button variant='contained' sx={{ width: '120px', height: '54px', borderRadius: '10px' }}>Save</Button>
                        </div> */}
          </div>
        </div>
        <Categorylist
          open={open}
          handleDelete={handleDelete}
          setOpen={setCategory}
          handleAddCategoryPopup={handleAddCategoryPopup}
          categoriesData={categoriesData && categoriesData}
        />
      </div>
    </div>
  )
}
