import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminEditMenu = (menu) => {
  let menuEditErrors = {}

  if (validator.isEmpty(menu.name)) {
    menuEditErrors.name = "Name is required"
  }

  if (validator.isEmpty(menu.link)) {
    menuEditErrors.link = "Link is required"
  } else if (!validator.isURL(menu.link)) {
    menuEditErrors.link = "Please enter a valid URL"
  }

  return {
    menuEditErrors,
    isValid: isEmpty(menuEditErrors),
  }
}
