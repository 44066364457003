import React, { useState } from "react"
import styles from "./Editcategory.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { TextField } from "@mui/material"
import serverimage from "../../../../../serverConstant"
import { DataObjectOutlined } from "@mui/icons-material"
import {
  editCategorydata,
  handleAddCategory,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { validateAdminCategory } from "../../../../../validation/validationAdminCategory"
import { validateAdminEditCategory } from "../../../../../validation/validationAdminEditCat"

export default function Editcategory(props) {
  console.log(props, "props-edit cat")

  const [data, setData] = useState(
    props.name
      ? {
          name: props.name && props.name.name,
          image: props.name && props.name.image,
        }
      : {
          name: "",
          image: "",
        }
  )
  const [src, setSrc] = useState("")
  const [editsrc, setEditsrc] = useState({
    category: props.name && props.name.image,
  })
  const loader = useSelector((state) => state.loader)
  const [categoryAddErrors, setcategoryAddErrors] = useState({})
  const [categoryEditErrors, setcategoryEditErrors] = useState({})
  const [newdata, setNewdata] = useState({ image: "", name: "" })
  const [newsrc, setNewsrc] = useState("")
  const [image, setImage] = useState("")
  const handleClose = () => props.setOpen(false)
  const dispatch = useDispatch()

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const imgs = URL.createObjectURL(e.target.files[0])
      setImage(e.target.files[0])
      setSrc(imgs)
      setNewdata({ ...newdata, image: img })
      setData({ ...data, image: img })
      setEditsrc({ category: imgs })
    }
  }

  const handleChange = (e) => {
    setData({ name: e.target.value, image: data.image })
  }

  const handleNewImage = (e) => {
    // console.log('trigger', e.target)
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const imgs = URL.createObjectURL(e.target.files[0])
      // setImage(e.target.files[0]);
      setNewdata({ image: img, name: newdata.name })
      setNewsrc(imgs)
    }
  }

  const handleNewChange = (e) => {
    const { value } = e.target
    const validValue = value.replace(/[^a-zA-Z\s]/g, "")

    setNewdata({ name: validValue, image: newdata.image })
  }

  const handleSubmit = () => {
    const { catErrors, isValid } = validateAdminCategory(newdata)

    if (!isValid) {
      setcategoryAddErrors(catErrors)
    } else {
      setcategoryAddErrors({})
      dispatch(handleAddCategory(newdata, props.setOpen, props.fetchData))
    }
  }
  const handleEdit = (id) => {
    const { catErrors, isValid } = validateAdminEditCategory(data)

    if (!isValid) {
      setcategoryEditErrors(catErrors)
    } else {
      setcategoryEditErrors({})
      dispatch(editCategorydata(id, data, props.setOpen, props.fetchData))
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      {props.type === "add" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setOpen(false)}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Category
              </p>

              <div className={styles.upload_img}>
                <div className={styles.textarea_label}>Images</div>
                <div className={styles.preview_img}>
                  {newsrc === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleNewImage}
                      >
                        <input
                          onChange={handleNewImage}
                          type="file"
                          id="input"
                          hidden
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleNewImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          src={newsrc}
                          alt="img"
                          id="img"
                          className={
                            newdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <p>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </p>
                {categoryAddErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {categoryAddErrors?.image}
                  </span>
                )}
              </div>

              {/* <div className={styles.textarea}>
                        <textarea className={styles.textareas} placeholder='Write a Remark' rows='10' />
                    </div> */}
              <div className={styles.textarea_label}>Name</div>
              <TextField
                onChange={handleNewChange}
                value={newdata.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                // sx={{ mb: 2 }}
              />
              {categoryAddErrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {categoryAddErrors?.name}
                </span>
              )}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  className={styles.action_btn}
                >
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setOpen(false)}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Edit Category
              </p>

              <div className={styles.upload_img}>
                <div className={styles.textarea_label}>Images</div>
                <div className={styles.preview_img}>
                  {data.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          src={editsrc.category}
                          alt="img"
                          id="img"
                          className={
                            data.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <p>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </p>
                {categoryEditErrors?.image && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {categoryEditErrors?.image}
                  </span>
                )}
              </div>

              {/* <div className={styles.textarea}>
                            <textarea className={styles.textareas} placeholder='Write a Remark' rows='10' />
                        </div> */}
              <label>Name</label>
              <TextField
                onChange={handleChange}
                value={data.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                sx={{ mb: 2 }}
              />
              {categoryEditErrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {categoryEditErrors?.name}
                </span>
              )}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={() => handleEdit(props.name._id)}
                  className={styles.action_btn}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
