import React, { useState } from "react";
import styles from "./QouteRequest.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Pagination from "../../../List/Pagination/Pagination";
import { setLoader } from "../../../../../State/Features/common/loaderSlice";
import api from "../../../../../State/api/Api";
import TenColumnTable from "../Tables/TenColumnTable";
import NineColumnTable from "../Tables/NineColumnTable";
import { fetchQuateRequestData } from "../../../../../State/api/artistapi";

const qouteheadData = [
  {
    id: "customer_id",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buyer_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "art_tpe",
    label: "Art Type",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "date",
    label: "Request Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "remark",
    label: "Remark",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "status",
    label: "Status",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
];

export default function QouteRequests() {
  const [makeOfferData, setMakeOfferData] = useState({ offset: 1 });
  const dispatch = useDispatch();
  const quatedt = useSelector((state) => state.quateRequestData.data);

  const fetchData = () => {
    dispatch(fetchQuateRequestData());
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
      <NineColumnTable
            activetab="qoute_request"
            headData={qouteheadData}
            data={quatedt && quatedt.data}
          />

        <Pagination />
      </div>
    </div>
  );
}
