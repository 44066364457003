import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

}

const permissionSlice = createSlice({
    name: 'permissiondata',
    initialState,
    reducers: {
        setPermission: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setPermission } = permissionSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default permissionSlice.reducer;