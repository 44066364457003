import {
  createTheme,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Container,
  Select,
  ThemeProvider,
  Link,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./CareerUser.module.css"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import { useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import api from "../../../State/api/Api"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../common/loader/Loader"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { ToastContainer } from "react-toastify"
import { Errornotify } from "../../../common/alert/Alert"
const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "3px",
  boxShadow: 24,
}
const CareerCardData = [
  {
    id: 1,
    title: "Angular Developer",
    location: "Surat, Gujarat",
    experience: "2years+ experience",
  },
  {
    id: 2,
    title: "React Native Developer",
    location: "Surat, Gujarat",
    experience: "3years+ experience",
  },
  {
    id: 3,
    title: "Front-end Developer",
    location: "Surat, Gujarat",
    experience: "1years+ experience",
  },
  {
    id: 4,
    title: "Back-end Developer",
    location: "Surat, Gujarat",
    experience: "2years+ experience",
  },
  {
    id: 5,
    title: "Wordpress Developer",
    location: "Remote",
    experience: "2years+ experience",
  },
  {
    id: 6,
    title: "Shopify Developer",
    location: "Surat, Gujarat",
    experience: "2years+ experience",
  },
  {
    id: 7,
    title: "Android Developer",
    location: "Surat , Gujarat",
    experience: "2years+ experience",
  },
  {
    id: 8,
    title: "Flutter Developer",
    location: "Surat , Gujarat",
    experience: "2years+ experience",
  },
  {
    id: 9,
    title: "Ios Developer",
    location: "Remote",
    experience: "2years+ experience",
  },
]
function CareerUser() {
  const navigate = useNavigate()

  const [title, setTitle] = React.useState("")
  const [location, setLocation] = React.useState("")
  const [department, setDepartment] = React.useState("")
  const [experience, setExperience] = React.useState("")
  const [openapply, setOpenApply] = React.useState(false)
  const [careerdata, setCareerdata] = useState()
  const [dataToRender, setDataToRender] = useState([])
  const [filterdataunique, setFilterdataunique] = useState()
  const [filterData, setFilterdata] = useState()
  const dispatch = useDispatch()

  const navigatetoCareer = (id) => {
    navigate(`/user/career/${id}`)
  }

  const handleChangeTitle = (event) => {
    setTitle(event.target.value)
  }

  const handleChangeLoc = (event) => {
    setLocation(event.target.value)
  }

  const handleChangeDep = (event) => {
    setDepartment(event.target.value)
  }

  const handleChangeExp = (event) => {
    setExperience(event.target.value)
  }
  const handleOpenApply = () => setOpenApply(true)

  //
  const handleFilter = async () => {
    // Make API call with filter values
    if (!title && !location && !department && !experience) {
      Errornotify('Please select at least one filter value.');
      return; // Exit early if no filter values are selected
    }
    const query = new URLSearchParams({
      title,
      location,
      department,
      experience,
    }).toString()

    // Make API call with filter values
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(
        `api/career/filterCareers?${query}`,
        "GET"
      )
      if (result && result.status === 200) {
        setFilterdataunique({ responses: result.data })
      }
    } catch (err) {
      console.error(err)
    }

    dispatch(setLoader(false))
  }
  const handleClose = () => setOpenApply(false)

  const handleNavigate = (route) => {
    navigate(route)
  }
  const fetchCareer = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi("api/user/getcareers")
      if (result && result.status === 200) {
        setCareerdata(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }

  // filter api
  const filterCareer = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi("api/career/uniqueFields")
      if (result && result.status === 200) {
        setFilterdata(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const loader = useSelector((state) => state.loader)
  useEffect(() => {
    fetchCareer()
  }, [])

  useEffect(() => {
    filterCareer()
  }, [])

  useEffect(() => {
    if (filterdataunique && filterdataunique?.responses) {
      setDataToRender(filterdataunique?.responses?.responses)
    } else if (careerdata && careerdata?.responses) {
      setDataToRender(careerdata?.responses)
    } else {
      // If neither filterdataunique nor careerdata is set, set dataToRender as an empty array
      setDataToRender([])
    }
  }, [filterdataunique, careerdata])
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={styles.careeruser}>
          <div className={styles.headerCategory}>
          <Container maxWidth="lg">
            <div className={styles.pageitle}>
              <h1>Find Your Dream Job</h1>
              <ul>
                <li><Link onClick={() => handleNavigate("/")}>Home /{" "} </Link></li>
                <li>Career</li>
              </ul>
            </div>
          </Container>
          </div>
          <div className={styles.filtersec}>
          <Container maxWidth="lg">
          <Grid container spacing={2} sx={{
    justifyContent: "center",
    alignItems: "center",
  }}>
          <Grid item xs={12} md={2.4}>
          <FormControl fullWidth>
                <InputLabel id="title-select-label">Title</InputLabel>
                <Select
                  labelId="title-select-label"
                  id="title-select"
                  value={title}
                  label="Title"
                  onChange={handleChangeTitle}
                >
                  {filterData?.titles?.map((title, index) => (
                    <MenuItem key={index} value={title}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} md={2.4}>
          <FormControl fullWidth>
                <InputLabel id="location-select-label">Location</InputLabel>
                <Select
                  labelId="location-select-label"
                  id="location-select"
                  value={location}
                  label="Location"
                  onChange={handleChangeLoc}
                >
                  {filterData?.locations?.map((location, index) => (
                    <MenuItem key={index} value={location}>
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} md={2.4}>
          <FormControl fullWidth>
                <InputLabel id="department-select-label">Department</InputLabel>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  value={department}
                  label="Department"
                  onChange={handleChangeDep}
                >
                  {filterData?.departments?.map((department, index) => (
                    <MenuItem key={index} value={department}>
                      {department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} md={2.4}>
          <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Experience
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={experience}
                  label="Experience"
                  onChange={handleChangeExp}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} md={2.4} className="text-center">
          <button className={styles.apply_btn} onClick={handleFilter}>Apply Filter</button>
          </Grid>
          </Grid>
          </Container>
          </div>
          
              <div className={styles.listsec}>
              <Container maxWidth="lg">
              {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              <Grid container spacing={2}>
                {dataToRender.map((item) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <div
                      className={styles.career__cards}
                      onClick={() => navigatetoCareer(item._id)}>
                      <h4>{item.title}</h4>
                      <div className={styles.car_meta}>
                        <p>{item.location}</p>
                        <p>{item.max_exp} year + Experience</p>
                      </div>
                      <div className={styles.car_descr}>
                        <h5>Description</h5>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              item &&
                              item.description &&
                              item.description.length > 100
                                ? item.description.substring(0, 100) + "..."
                                : item && item.description
                                ? item.description
                                : "",
                          }}
                        ></span>
                      </div>
                      
                      <button
                        className={styles.apply_btn}
                        onClick={handleOpenApply}
                      >Apply Now
                      </button>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
              </Container>
              </div>
        </div>
      </ThemeProvider>
      <ToastContainer />
    </>
  )
}

export default CareerUser
