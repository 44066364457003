import React, { useState } from 'react'
import Datetime from '../../../../components/Pages/List/Popupdelete/datetime/Datetime'
import Enterdetails from '../../../../components/Pages/List/Popupdelete/enterDetails/Enterdetails'
import Commondetailexshibitiongallery from '../../../../gallery/pages/exshibition/commonExshibitiongallery/details/Commondetailexshibitiongallery'
import styles from './Orderartistdetail.module.css'
import ArtistOrderDetails from '../../../../gallery/pages/exshibition/commonExshibitiongallery/details/ArtistOrderDetail'
import BuyerDisputeDetail from '../../../../gallery/pages/exshibition/commonExshibitiongallery/details/BuyerDisputeDetail'

const data1 = [
  {
    id: 1,
    title: 'Order ID',
    content: '#23668'
  }
]
const data = [
  {
    main_title: 'Art Of Space',
    content: '2',
    img: '/img/Image 1.png',
    more_detail: [
      {
        title: 'Price',
        content: '$45,000'
      },
      {
        title: 'Materials',
        content: 'Bronze, Cardboard, Carbon FIber,Ceramic'
      },
      {
        title: 'Category',
        content: 'Photography'
      },
      {
        title: 'Dimensions',
        content: '20 W * 20 H * 50 D in'
      },
    ],
    remark: ''
  },
  {
    main_title: 'The Wonder of India',
    content: '1',
    img: '/img/Image 2.png',
    more_detail: [
      {
        title: 'Price',
        content: '$45,000'
      },
      {
        title: 'Materials',
        content: 'Bronze, Cardboard, Carbon FIber,Ceramic'
      },
      {
        title: 'Category',
        content: 'Photography'
      },
      {
        title: 'Dimensions',
        content: '20 W * 20 H * 50 D in'
      },
    ],
    remark: 'Remarks*  Lorem ip dolor sit amet corcresdac'
  },
  {
    main_title: 'Way Of Seeing',
    content: '3',
    img: '/img/Image 3.png',
    more_detail: [
      {
        title: 'Price',
        content: '$45,000'
      },
      {
        title: 'Materials',
        content: 'Bronze, Cardboard, Carbon FIber,Ceramic'
      },
      {
        title: 'Category',
        content: 'Photography'
      },
      {
        title: 'Dimensions',
        content: '20 W * 20 H * 50 D in'
      },
    ],
    remark: ''
  },
]
export default function BuyerDisputeDetails() {
  const [open,setOpen] = useState({open:false,dateopen:false})
  return (
    <div className={styles.mains}>
     
      <BuyerDisputeDetail setOpen={setOpen}  />
    </div>
  )
}
