import { Grid, Typography, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./GiftCardUser.module.css";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch } from "react-redux";

function GiftCardUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [giftData, setGiftData] = useState();
  console.log(giftData);
  const navigatetoGiftdetail = (id,data) => {
    navigate(`/user/giftcard/${id}`, {state: {data}});
  };

  const handleFetchGiftcard = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi('api/user/giftCards',
      )
      if (result && result.status === 200) {
        setGiftData(result.data.response);
        dispatch(setLoader(false));
      }
      else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    handleFetchGiftcard()
  }, [])
  return (
    <>
    <div className={styles.banner}>
      <Container maxWidth="lg">
      <Grid container  spacing={2}>
      <Grid item xs={12} sm={5} md={5}>
        <div className={styles.bannercont}>
          <h3>Gift Card</h3>
          <h1>The Gift Of Good Taste</h1>
          <div className={styles.graduationDiv}>
              <div className={styles.verticalLine}></div>
              <div className={styles.graduation}>
                <img className={styles.gradImg} src={ "/img/graduationImg.png"} alt="" />
                <Typography fontSize="0.9vw">GRADUATION</Typography>
              </div>
            </div>
          <ul>
            <li>The better option is to buy a Gift Card from ArtSmiley and
                    let your loved ones choose the Art gift from our Collection
                    of International Artworks.</li>
            <li>ArtSmiley gift Cards can be purchased in any amount and can
                    be used to buy or rent any Artworks across the whole
                    website.</li>
          </ul>
        </div>
      </Grid>
      <Grid item xs={12} sm={2} md={2}></Grid>
      <Grid item xs={12} sm={5} md={5}>
      <div className={styles.gcimgDiv}>
            <img className={styles.img_fluid} src={ "/img/Gift Card-1.png"} alt="" />
            {/* <Typography
              style={{ marginTop: "-8%" }}
              fontFamily="marcellus"
              fontSize="1.75vw"
            >
              <span className={styles.gclabel}>GIFT CARD</span>
            </Typography> */}
          </div>
      </Grid>
      </Grid>
      </Container>
    </div>
    <div className={styles.giftsec}>
      <Container maxWidth="lg">
      <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Select Gift Card</h3>
          </div>
          <Grid container spacing={2} sx={{justifyContent: "center",alignItems: "center",}}>
            <Grid item xs={12} md={10} >
            <Grid container spacing={2}>
            {giftData && giftData.map((val) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              
            >
              <div onClick={() => navigatetoGiftdetail(val._id,val)} className={styles.gift_img}>
              <img className={styles.img_fluid} src={ val.gift_card_image} alt="" />

              </div>
              
            </Grid>
          ))}
            </Grid>
            </Grid>
          
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={6}
            onClick={() => navigatetoGiftdetail(2)}
          >
            <img className={styles.gcImagemain} src={ "/img/Gift Card-3.png"} />
            <div className={styles.logodiv}>
              <img
                className={styles.logo}
                src={ "/img/design-8e5de6ac-1d6c-448f-9774-e5515de5c97d 3.png"}
              />
            </div>
          </Grid>
        </Grid>
        <Grid */}
          {/* container
          mt={5}
          spacing={1}
          onClick={() => navigatetoGiftdetail(3)}
        > */}
          {/* <Grid item xs={12} sm={6} md={6}>
            <img className={styles.gcImagemain} src={ "/img/Gift Card-4.png"} />
            <div className={styles.logodiv}>
              <img
                className={styles.logo}
                src={ "/img/design-8e5de6ac-1d6c-448f-9774-e5515de5c97d 3.png"}
              />
            </div>
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            sm={6}
            md={6}
            onClick={() => navigatetoGiftdetail(4)}
          >
            <img className={styles.gcImagemain} src={ "/img/Gift Card-5.png"} />
            <div className={styles.logodiv}>
              <img
                className={styles.logo}
                src={ "/img/design-8e5de6ac-1d6c-448f-9774-e5515de5c97d 3.png"}
              />
            </div>
          </Grid> */}
        </Grid>
      </Container>
    </div>
    
      <div className={styles.giftcard__grid}>
       
      </div>
    </>
  );
}

export default GiftCardUser;
