import SearchOutlined from "@mui/icons-material/SearchOutlined";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import ProductSidebar from "./ProductSidebar";
import styles from "./Search.module.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import homeImage from "../../../constant";
import api from "../../../State/api/Api";
import serverimage from "../../../serverConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllItemData,
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
  filterAllArts,
} from "../../../State/api/userApi";
import {
  ArticleSharp,
  Close,
  CloseOutlined,
  Search as Searchicon,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CircularLoader from "../../../common/loader/Loader";
import { setSearchFilter } from "../../../State/Features/user/filterationSlice";
import { handleFetchStyle } from "../../../State/api/superadminApi";
import {FiHeart } from "react-icons/fi";
const sortdata = [
  { label: "Sale Price ↓", value: "low" },
  { label: "Sale Price ↑", value: "high" },
];
function Search() {
  const cardDetails = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 369.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 370.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5199.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle 5197.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle 5200.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle 5202.png",
    },
    {
      id: 9,
      imgUrl: "/img/Rectangle 5203.png",
    },
    {
      id: 10,
      imgUrl: "/img/Rectangle 5206.png",
    },
    {
      id: 11,
      imgUrl: "/img/Rectangle 5207.png",
    },
    {
      id: 12,
      imgUrl: "/img/Rectangle 5208.png",
    },
  ];
  const searchParams = new URLSearchParams(window.location.search);

  const categoryParams = searchParams.get("category");
  const { state } = useLocation();
  const [loadingArts, setLoadingArts] = useState(false);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const [initialValueSet, setInitialValueSet] = useState(false);
  const [filteredValues, setFilteredValues] = useState({
    categoryName: state?.id ? state?.id : [],
    styleName: "",
    style: "",
    colorName: "",
    artistTypeName: [],
    artistName: [],
    orientationName: "",
    startPrice: "0",
    endPrice: "50000",
    minHeight: 0,
    maxHeight: 1500,
    minWidth: 0,
    maxWidth: 1500,
    countryName: "",
    searchTitle: "",
    offername: "",
  });
  console.log(filteredValues);
  const [searchTitle, setSearchTitle] = useState("");
  const [selectedSort, setSelectedSort] = useState("");
  const location = useLocation();
  console.log(location,'location')
  const [artist, setArtist] = React.useState("");
  const [artData, setArtdata] = useState();
  const [filterData, setFilterdata] = useState();
  const [priceRange, setPriceRange] = useState([0, 50000]);

  const [condition, setCondition] = useState({ sorting: "", filter: "" });
  const [style, setStyle] = useState([]);
  console.log(style,'style')
  const [filterByArtist, setFilterByArtist] = useState("all");
    const allcategory = useSelector((state) => state.allcategoryData);


  const { option } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToArtworkDetail = (ids) => {
    navigate(`/user/productdetails/${ids}`);
  };
  const getArt = async () => {
    const reqData = await api.applicationApi("api/user/allitems", "GET", {});
    setArtdata(reqData.data.artworks);
    setFilterdata(reqData);
  };

  useEffect(() => {
  if(filteredValues.categoryName) {
   
    const category_id = allcategory?.data?.allcategories?.filter((val) => 
      val?.name === filteredValues.categoryName
    )[0]?._id;
    console.log(category_id,'category_id')
    if(category_id) {
     dispatch(handleFetchStyle({category_id: category_id}, setStyle));
     
    }
  }
  },[filteredValues.categoryName,allcategory?.data?.allcategories])
  useEffect(() => {
    getArt();
    dispatch(fetchProductType());
    dispatch(fetchArtistTypes());
    dispatch(fetchUserArtist({}));
    dispatch(fetchCategoryData({}));
  
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const userartists = useSelector((state) => state.userartistData);

  const loader = useSelector((state) => state.loader);
  const filter = useSelector((state) => state.filteration);
  const setAllFilterValues = useSelector(
    (state) => state.filteration.allFilterValues
  );
  const sortedFilterValues = Array.isArray(setAllFilterValues)
  ? [...setAllFilterValues]
      .filter((item) => {
        // Filter by itemType and ensure `print` is not empty
        if (location?.state?.itemType === "print" && (!item.print || item.print.length === 0)) {
          return false; // Exclude items with an empty `print` field
        }

        // Filter by searchTitle
        if (filteredValues.searchTitle) {
          return item.name
            .toLowerCase()
            .includes(filteredValues.searchTitle.toLowerCase());
        }

        return true; // Include other items
      })
      .sort((a, b) => {
        // Sort by price based on selectedSort
        if (selectedSort === "low") {
          return a.sale.price - b.sale.price; // Low to High
        } else if (selectedSort === "high") {
          return b.sale.price - a.sale.price; // High to Low
        } else {
          return new Date(b.createdAt) - new Date(a.createdAt); // Default sort by date
        }
      })
  : [];
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTitle(value);
    dispatch(setSearchFilter(value));
  };
  const handleSubmitSearch = () => {
    setFilteredValues({ ...filteredValues, searchTitle: searchTitle });
  };
  const handleSubmitRemove = () => {
    setFilteredValues({ ...filteredValues, searchTitle: "", artistName: "" });
    setSearchTitle("");
  };
  const handleArtistSearch = (event) => {
    setArtist(event.target.value);
  };

  const handleFilter = (event) => {
    const value = event.target.value;
    setFilteredValues({ ...filteredValues, artistName: value });
    // setFilterByArtist(value);
    // setCondition({ ...condition, filter: value });
  };

  const handleSorting = (event) => {
    const value = event.target.value;
    setSelectedSort(value);
  };

  const filters =
    artData && artData.map((val) => val.style_id.map((c) => c._id?._id));

  const selectedFilters = [
    filteredValues.categoryName.length ? filteredValues.categoryName : null,
    filteredValues.artistTypeName.length ? filteredValues.artistTypeName : null,
    filteredValues.artistName.length ? filteredValues.artistName : null,
    filteredValues.colorName,
    filteredValues.orientationName,
    filteredValues.countryName,
    filteredValues.styleName,
    (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
    (filteredValues.endPrice && filteredValues.endPrice !== "50000")
      ? "$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice
      : null,
    (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
    (filteredValues.maxHeight && filteredValues.maxHeight !== 1500)
      ? filteredValues.minHeight +
        " cm" +
        " - " +
        filteredValues.maxHeight +
        " cm" +
        " height"
      : null,
    (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
    (filteredValues.maxWidth && filteredValues.maxWidth !== 1500)
      ? filteredValues.minWidth +
        " cm" +
        " - " +
        filteredValues.maxWidth +
        " cm" +
        " width"
      : null,
    filteredValues.searchTitle,
    filteredValues.offername
  ].filter(
    (filterValue) =>
      filterValue !== "" && filterValue !== null && filterValue !== undefined
  );
  // useEffect(() =>  {
  //   if( (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
  //   (filteredValues.endPrice && filteredValues.endPrice !== "50000")) {
  //   // setTimeout(() => {
  //   //   window.scrollTo({
  //   //     top: 100,
  //   //     behavior: "smooth"
  //   //    })
  //   // },[2000])
  //   }else {
  //     window.scrollTo({
  //       top: 100,
  //       behavior: "smooth"
  //      })
  //   }

  // },[selectedFilters])
  const clearCategoryName = (categoryName) => {
    setFilteredValues({ ...filteredValues, categoryName: "" });
    searchParams.delete("category");
  };
  const clearArtistTypeName = (artistTypeName) => {
    setFilteredValues({ ...filteredValues, artistTypeName: "" });
  };
  const clearArtistName = () => {
    setFilteredValues({ ...filteredValues, artistName: "" });
  };
  const clearColorName = (colorName) => {
    setFilteredValues({ ...filteredValues, colorName: "" });
  };
  const clearOrientationName = (colorName) => {
    setFilteredValues({ ...filteredValues, orientationName: "" });
  };
  const clearFunctions = {
    [filteredValues.categoryName]: clearCategoryName,
    [filteredValues.artistTypeName]: clearArtistTypeName,
    [filteredValues.colorName]: clearColorName,
    [filteredValues.orientationName]: clearOrientationName,
    [filteredValues.artistName]: clearArtistName,
    [filteredValues.offername]: () => setFilteredValues({ ...filteredValues, offername: "" }),
    [filteredValues.searchTitle]: () => {
      setFilteredValues({ ...filteredValues, searchTitle: "" });
      setSearchTitle("");
    },
    [filteredValues.countryName]: () =>
      setFilteredValues({ ...filteredValues, countryName: "" }),
    [filteredValues.styleName]: () =>
      setFilteredValues({ ...filteredValues, styleName: "" }),
    ["$" + filteredValues.startPrice + " - " + "$" + filteredValues.endPrice]:
      () =>
        setFilteredValues({
          ...filteredValues,
          startPrice: "0",
          endPrice: "50000",
        }),
    [filteredValues.minWidth +
    " cm" +
    " - " +
    filteredValues.maxWidth +
    " cm" +
    " width"]: () =>
      setFilteredValues({ ...filteredValues, minWidth: 0, maxWidth: 1500 }),
    [filteredValues.minHeight +
    " cm" +
    " - " +
    filteredValues.maxHeight +
    " cm" +
    " height"]: () =>
      setFilteredValues({ ...filteredValues, minHeight: 0, maxHeight: 1500 }),
    // ... map other filter values to their corresponding clear functions ...
  };
  const removeFilter = (filtervalue) => {
    const clearFunction = clearFunctions[filtervalue];
    if (clearFunction) {
      clearFunction();
    }
  };

  useEffect(() => {
    if (!initialValueSet) return;
    const searchParams = new URLSearchParams();

    if (filteredValues.categoryName) {
      searchParams.set("category", filteredValues.categoryName);
    }
    if (filteredValues.styleName) {
      searchParams.set("style-name", filteredValues.styleName.toLowerCase());
    }
    if (filteredValues.style) {
      searchParams.set("style", filteredValues.style.toLowerCase());
    }
    if (filteredValues.colorName) {
      searchParams.set("color", filteredValues.colorName.toLowerCase());
    }
    if (filteredValues.artistTypeName.length > 0) {
      searchParams.set("artist-type", filteredValues.artistTypeName);
    }
     if(filteredValues.offername) {
      searchParams.set("offername", filteredValues.offername.toLowerCase());
    }

    if (filteredValues.artistName.length > 0) {
      searchParams.set("artist-name", filteredValues.artistName);
    }

    if (filteredValues.orientationName) {
      searchParams.set(
        "orientation",
        filteredValues.orientationName.toLowerCase()
      );
    }
    if (
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000")
    ) {
      searchParams.set(
        "price-range",
        `${filteredValues.startPrice}-${filteredValues.endPrice}`
      );
    }
    if (filteredValues.minHeight || filteredValues.maxHeight) {
      searchParams.set(
        "height-range",
        `${filteredValues.minHeight}-${filteredValues.maxHeight}`
      );
    }
    if (filteredValues.minWidth || filteredValues.maxWidth) {
      searchParams.set(
        "width-range",
        `${filteredValues.minWidth}-${filteredValues.maxWidth}`
      );
    }
    if (filteredValues.countryName) {
      searchParams.set("country", filteredValues.countryName.toLowerCase());
    }

    const newSearch = searchParams.toString()
      ? `?${decodeURIComponent(searchParams.toString())}`
      : "";
    if (
      filteredValues.categoryName ||
      filteredValues.styleName ||
      filteredValues.style ||
      filteredValues.colorName ||
      (filteredValues.artistTypeName &&
        filteredValues.artistTypeName.length > 0) ||
      (filteredValues.artistName && filteredValues.artistName.length > 0) ||
      filteredValues.orientationName ||
      (filteredValues.startPrice && filteredValues.startPrice !== "0") ||
      (filteredValues.endPrice && filteredValues.endPrice !== "50000") ||
      (filteredValues.minHeight && filteredValues.minHeight !== 0) ||
      (filteredValues.maxHeight && filteredValues.maxHeight !== 1500) ||
      (filteredValues.minWidth && filteredValues.minWidth !== 0) ||
      (filteredValues.maxWidth && filteredValues.maxWidth !== 1500) ||
      filteredValues.offername  || 
      filteredValues.countryName
    ) {
      navigate(`/user/search${newSearch}`, { replace: true });
    } else {
      navigate(`/user/search`);
    }
  }, [filteredValues, dispatch]);

  useEffect(() => {
    // Parse URL parameters and set initial state
    const searchParams = new URLSearchParams(window.location.search);
    const params = {
      categoryName: searchParams.get("category") || "",
      styleName: searchParams.get("style-name") || "",
      style: searchParams.get("style") || "",
      colorName: searchParams.get("color") || "",
      offername: searchParams.get("offername") || "",
      artistTypeName: searchParams.get("artist-type")
        ? searchParams.get("artist-type").split(",")
        : [],
      artistName: searchParams.get("artist-name")
        ? searchParams.get("artist-name").split(",")
        : [],
      orientationName: searchParams.get("orientation") || "",
      startPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[0]
        : "0",
      endPrice: searchParams.get("price-range")
        ? searchParams.get("price-range").split("-")[1]
        : "50000",
      minHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[0]
        : "",
      maxHeight: searchParams.get("height-range")
        ? searchParams.get("height-range").split("-")[1]
        : "",
      minWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[0]
        : "",
      maxWidth: searchParams.get("width-range")
        ? searchParams.get("width-range").split("-")[1]
        : "",
      countryName: searchParams.get("country") || "",
    };

    setFilteredValues({ ...filteredValues, ...params });
    dispatch(filterAllArts(params, setLoadingArts)); // Ensure dispatch is done with initial params
    setInitialValueSet(true);
  }, [window.location.search, dispatch]);

  useEffect(() => {
    if (initialValueSet) {
      dispatch(filterAllArts(filteredValues, setLoadingArts));
    }
  }, [filteredValues, dispatch, initialValueSet]);
  const handleChangeFilteredValues = (e) => {
    if (e.target.name === "categoryName") {
    }
    setFilteredValues({ ...filteredValues, [e.target.name]: e.target.value });
    window.scrollTo({
      top: 120,
      behavior: "smooth",
    });
  };

  return (
   <>
   <div className={styles.searchbanner}>
    <Container maxWidth="lg">
    <div className={styles.searchbar}>
      
    <TextField
          id="input-with-icon-textfield"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="art"
          value={searchTitle}
          onChange={handleSearch}
          size="small"
          sx={{ mb: { xs: 2, sm: 0 },
          width: { xs: "100%", sm: "30% " },
            bgcolor:"#fff"
          
        }}
        />
      <div className={styles.sractnbtn}>
      <Button
          variant="outlined"
          sx={{ height: "40px", 
            width: { xs: "100%", sm: "100px" },
            bgcolor:"#fff"
           }}
          onClick={handleSubmitSearch}
        >
          <IconButton aria-label="delete" color="primary">
            <Searchicon />
          </IconButton>{" "}
          Search
        </Button>
        <Button
          variant="outlined"
          sx={{ height: "40px",
            width: { xs: "100%", sm: "100px" }, 
            ml:{xs:1, sm:1},
            bgcolor:"#fff"
           }}
          onClick={handleSubmitRemove}
        >
          <IconButton aria-label="delete" color="primary">
            <Close />
          </IconButton>{" "}
          Close
        </Button>
        <div className={styles.filter__div}>
          <FormControl sx={{ minWidth: 120, color: "red",
            ml:{xs:1, sm:1},
            bgcolor:"#fff" }} size="small">
            <Select
              value={filteredValues.artistName || "all"}
              onChange={handleFilter}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="select"
              sx={{
                color: "#636365",
                border: "0.6px solid #665D6F",
                borderRadius: "6px",
              }}
            >
              {/* <MenuItem value="" sx={{ color: "#636365" }}>
                  Best Seller
                </MenuItem> */}
              <MenuItem value="" disabled>
                Artist
              </MenuItem>
              {userartists.data &&
                userartists.data.map((item) => (
                  <MenuItem value={item.artist_name}>
                    {item.artist_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
        

        
    </div>
    </Container>
   </div>

    <div className={styles.search}>
       
      <div className={styles.searchresults__div}>
      <Container maxWidth="lg">
        <div className={styles.tosearch}>
        <Grid container spacing={2} sx={{alignItems:"center"}}>
              <Grid item xs={12} md={3}>
              <div className={styles.searchheading}>
          <h3>Search : Art</h3>
        </div>
              </Grid>
              <Grid item xs={12} md={6}>
              <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {selectedFilters?.map((val, index) => (
            <button
              className={styles.filterButtonsStyles}
              // onClick={() => removeFilter(filterValue)}
            >
              {/* <FaTimes onClick={() => removeFilter(filterValue)} /> */}
              {val}
              <CloseOutlined
                onClick={() => removeFilter(val)}
                sx={{ fontSize: "1rem", marginLeft: "4px" }}
                className={styles.filterButtonsStylesIcon}
              />
            </button>
          ))}
        </div>
              </Grid>
              <Grid item xs={12} md={3}>
              <div className={styles.filter__maindiv}>
          <div className={styles.filter__label}>
            <span className={styles.filter__span}>Sort By Price</span>
          </div>
          <div className={styles.filter__div}>
            <FormControl sx={{ minWidth: 120, color: "red" }} size="small">
              <Select
                value={selectedSort}
                onChange={handleSorting}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                sx={{
                  color: "#636365",
                  border: "0.6px solid #665D6F",
                  borderRadius: "6px",
                  ml: 2,
                }}
              >
                {/* <MenuItem value="" sx={{ color: "#636365" }}>
                  Best Seller
                </MenuItem> */}
                <MenuItem value="">Sort by</MenuItem>
                {sortdata &&
                  sortdata.map((val) => (
                    <MenuItem value={val.value}>{val.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
              </Grid>
            </Grid>
        </div>
      
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
              <ProductSidebar
          value_height={value_height}
          value_width={value_width}
          setValueHeight={setValueHeight}
          style={style}
          setStyle={setStyle}
          priceRange={priceRange}
          filter={filter}
          setFilter={filter}
          setPriceRange={setPriceRange}
          selectedOption={option}
          setValueWidth={setValueWidth}
          producttypeData={productType.data}
          artisttypeData={artistTypes.data}
          allartistData={userartists.data}
          allcategoryData={allcategory.data && allcategory.data.allcategories}
          setFilteredValues={setFilteredValues}
          handleChangeFilteredValues={handleChangeFilteredValues}
          filteredValues={filteredValues}
     
        />
              </Grid>
              <Grid item xs={12} md={9}>
              {loadingArts ? (
                <div className={styles.loader}>
                  <CircularLoader />
                </div>
              ) : (
                <>
                <Grid container spacing={2}>
                {sortedFilterValues.length > 0 ? (<>{ sortedFilterValues &&
                sortedFilterValues?.map((item) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    sx={{ mb: 2 }}
                    key={item._id}
                  >
                    <div
                      className={styles.card__main}
                      onClick={() => navigateToArtworkDetail(item._id)}
                    >
                      <div className={styles.card__top}>
                        <img
                          className={styles.cardImage}
                          src={ item.image}
                          alt="img"
                        />
                      </div>
                      <div className={styles.pic_info}>
                      <div className={styles.card__middle}>
                        <span className={styles.titles}>{item.name}</span>
                      </div>
                      <div className={styles.info_like}>
                        <div className={styles.wishlst}>
                          <FiHeart />
                        </div>
                        <span className={styles.like_count}>
                        {item?.likesView?.[0]?.likes.length || 0}
                        {console.log(item,'item')}
                        </span>
                      </div>
                    </div>
                    <p className={styles.names}>
                    {item.artist_id &&
                              item.artist_id.first_name +
                                " " +
                                item.artist_id &&
                              item.artist_id.last_name}
                        </p>
                        <div className={styles.price_sec}>
                      <div className={styles.card__bottom}>
                        <span className={styles.buys}>Buy</span>
                        <span className={styles.prices}>
                          $ {item?.sale?.price}
                        </span>
                      </div>
                      {item?.rent?.rent_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Rent/Month</span>
                            <span className={styles.prices}>
                              $ {item?.rent?.rent_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                      {item?.print?.[0]?.print_price > 0 ? (
                        <>
                          {" "}
                          <div className={styles.hrs} />
                          <div>
                            <span className={styles.buys}>Print</span>
                            <span className={styles.prices}>
                              $ {item?.print[0]?.print_price}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </div>
                      {/* <div className={styles.card__bottom}>
                        <div className={styles.buy__text}>
                          <Button
                            sx={{
                              marginTop: "15px",
                              maxHeight: "5vh",
                              color: "white",
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Buy
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3}
                            >
                              <b>${item.sale.price}</b>
                            </Typography>
                          </Button>
                        </div>
                        {item?.rent?.rent_price ? (
                          <>
                            {" "}
                            <div className={styles.verticalLine}></div>
                            <div className={styles.rent__text}>
                              <Button
                                sx={{
                                  marginTop: "15px",
                                  maxHeight: "5vh",
                                  color: "white",
                                  backgroundColor: "white",
                                  "&:hover": { backgroundColor: "white" },
                                }}
                              >
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Rent/Month
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                  mt={4}
                                  ml={-6.5}
                                  mr={3}
                                >
                                  <b>${item?.rent?.rent_price}</b>
                                </Typography>
                              </Button>
                            </div>
                          </>
                        ) : null}
                        {item?.print?.[0]?.print_price > 0  ? (<>
                          <div className={styles.verticalLine}></div>
                        <div className={styles.print__text}>
                          <Button
                            sx={{
                              marginTop: "15px",
                              maxHeight: "5vh",
                              color: "white",
                              backgroundColor: "white",
                              "&:hover": { backgroundColor: "white" },
                            }}
                          >
                            <Typography
                              fontWeight="600"
                              color="#ccc"
                              fontSize="0.65rem"
                            >
                              Print
                            </Typography>
                            <Typography
                              color="black"
                              fontWeight="500"
                              fontSize="0.85rem"
                              mt={4}
                              ml={-3.75}
                            >
                              <b>${item?.print?.[0]?.print_price}</b>
                            </Typography>
                          </Button>
                        </div>
                        </>): null}
                        
                      </div> */}
                    </div>
                  </Grid>
                ))}</>) : <div style={{width:"100%",height:"40vh",display:"flex",justifyContent:"center",alignItems:"center"}}><h2>No Artworks Found</h2></div>}
                 </Grid>
                </>
              )}
              
              </Grid>
            </Grid>
      </Container>
        
        
        
      </div>
      <div className={styles.ProductContent}>
        
        <div className={styles.ProductCards}>
          <Container>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 5 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              
            </Grid>
          </Container>
        </div>
      </div>
    </div>
    </>
  );
}

export default Search;
