import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const buyerdetailSlice = createSlice({
    name: "allitemdata",
    initialState,
    reducers: {
        setBuyerDetail: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setBuyerDetail } = buyerdetailSlice.actions;

export default buyerdetailSlice.reducer;
