import { createSlice } from "@reduxjs/toolkit";
import { Errornotify, Successnotify } from "../../../common/alert/Alert";

const initialState = {
  data: null,
};

const mycartSlice = createSlice({
  name: "mycartdata",
  initialState,
  reducers: {
    // setWishlistdata: (state, action) => {
    //     state.data = state.data.push(action.payload);
    // },
    setAllcartdata(state, action) {
      state.data = action.payload;
    },
    setMycartdata(state, action) {
      const { _id } = action.payload;
      const check_wishlist = state.data.findIndex((item) => item._id === _id);
      if (check_wishlist === -1) {
        state.data.push(action.payload);
        Successnotify("Added in Your Basket");
      } else {
        Errornotify("Already in Basket");
      }
    },
  },
});

export const { setMycartdata, setAllcartdata } = mycartSlice.actions;

export default mycartSlice.reducer;
