import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],

}

const faqlistSlice = createSlice({
    name: 'faqlistdata',
    initialState,
    reducers: {
        setFaqlist: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setFaqlist } = faqlistSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default faqlistSlice.reducer;