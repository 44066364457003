import React from "react";
import styles from "./WebpopupArtistAndGallery.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import homeImage from "../../../../constant";
import { useNavigate } from "react-router-dom";
import CommoncardCurator from "./common/CommancardCurator";

import WebpopupArtist from "./artistAndGalleryPopup/webPopupArtist";
import WebpopupGallery from "./artistAndGalleryPopup/webPopupGallery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  // width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 0,
};

export default function WebpopupArtistAndGallery({ open,setOpen,setArtistPopSign}) {
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  return (
    <>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={styles.modal_del}>
            <div className={styles.card_main}>
              <div className={styles.card_odd}>
                <WebpopupArtist open={open} setOpen={setOpen} />
                {/* <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => navigate("/artsmiley/login")}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div> */}
              </div>

              <div className={styles.card}>
                <WebpopupGallery open={open} setOpen={setOpen} />
                {/* <hr className={styles.hrs} />
                <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => navigate("/artsmiley/login")}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div> */}
              </div>

              <Box
                sx={{ mt: { xs: "-20px", sm: "-64px" } }}
                className={styles.cancel_imgweb}
              >
                <img
                  alt="img"
                  onClick={() => setOpen(false)}
                  src={"/img/cancel_cross.png"}
                  height="30px"
                  width="30px"
                />
              </Box>
            </div>
           <hr className={styles.hrs} />
          {open !== "ArtistAndGallerySignIn" &&   <div className={styles.card_footer}>
                  <h6 className={styles.already_signin}>
                    Already have account ?
                  </h6>
                  <div>
                    <p
                      className={styles.already_signin_btn}
                      onClick={() => {
                        setArtistPopSign("ArtistAndGallerySignIn")
                        setOpen(false)
                      }}
                    >
                      Sign in{" "}
                    </p>
                    <img
                      src={ "/img/next.png"}
                      height="12px"
                      alt="img"
                      width="7px"
                    />
                  </div>
                </div> }
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
