import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  TextareaAutosize,
  TextField,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./Addsubscription.module.css"
import Backdrop from "@mui/material/Backdrop"
import api from "../../../../../State/api/Api"
import {
  fetchsubscriptionFeature,
  handleAddSubscription,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"

import CircularLoader from "../../../../../common/loader/Loader"

const input = [
  {
    id: 1,
    label: "Plan",
    name: "plan_name",
  },
  {
    id: 2,
    label: "Duration",
    name: "plan_duration",
  },
  {
    id: 2,
    label: "Price",
    name: "plan_price",
  },
]

const inputFeature = [
  {
    id: 1,
    label: "Number of Art",
    name: "number_of_art",
  },
  {
    id: 2,
    label: "Duration",
    name: "number_of_exibition",
  },
  {
    id: 3,
    label: "Price",
    name: "plan_price",
  },
]
const checkbox = [
  {
    label1: "All limited links",
    label2: "Optimize hashtags",
  },
  {
    label1: "Own analytic platform",
    label2: "Unlimited users",
  },
  {
    label1: "Chat Support ",
    label2: "Only 5 unlock",
    name: "",
  },
]
export default function Addsubscription(props) {
  const featureCounts = {
    number_of_art: 0,
    number_of_exibition: 0,
    number_of_auction: 0,
    feature_artists_in_home_page: 0,
    exclusive_promotion_of_artworks_on_social_media: 0,
    Video_promotion_on_YT_and_social_networking: 0,
    promotion_artist_on_artsmiley_blogs: 0,
    solo_online_exhibitions: 0,
    discount_on_first_refusal_opportunity_on: 0,
  }
  if (props.val && props.val.feature_ids) {
    props.val.feature_ids.forEach((feature) => {
      switch (feature._id.name) {
        case "no. of Arts":
          featureCounts.number_of_art = feature._id.count
          break
        case "no. of Exhibition":
          featureCounts.number_of_exibition = feature._id.count
          break
        case "no. of Auction":
          featureCounts.number_of_auction = feature._id.count
          break
        case "feature artists in home page":
          featureCounts.feature_artists_in_home_page = feature._id.count
          break
        case "exclusive promotion of artworks on social media":
          featureCounts.exclusive_promotion_of_artworks_on_social_media =
            feature._id.count
          break
        case "Video promotion on YT and social networking":
          featureCounts.Video_promotion_on_YT_and_social_networking =
            feature._id.count
          break
        case "promotion artist on artsmiley blogs":
          featureCounts.promotion_artist_on_artsmiley_blogs = feature._id.count
          break
        case "solo online exhibitions":
          featureCounts.solo_online_exhibitions = feature._id.count
          break
        case "discount on first refusal opportunity on exhibitions":
          featureCounts.discount_on_first_refusal_opportunity_on =
            feature._id.count
          break
        default:
          break
      }
    })
  }

  
  const [store, setStore] = useState([])
  const [data, setData] = useState({
    plan_name: "",
    plan_duration: "",
    plan_price: "",
    plan_description: "",
    features: [
      { name: "no. of Arts", count: 0 },
      { name: "no. of Auction", count: 0 },
      { name: "no. of Exhibition", count: 0 },
      { name: "feature artists in home page", count: 0 },
      { name: "exclusive promotion of artworks on social media", count: 0 },
      { name: "Video promotion on YT and social networking", count: 0 },
      { name: "promotion artist on artsmiley blogs", count: 0 },
      { name: "solo online exhibitions", count: 0 },
      {
        name: "discount on first refusal opportunity on exhibitions",
        count: 0,
      },
    ],
  })
  const [editeddata, setEditedData] = useState({
    plan_name: props.val && props.val.plan_name,
    plan_duration: props.val && props.val.plan_duration,
    plan_price: props.val && props.val.plan_price,
    description: props.val && props.val.plan_description,
    features: [
      { name: "no. of Arts", count: featureCounts.number_of_art },
      { name: "no. of Auction", count: featureCounts.number_of_auction },
      { name: "no. of Exhibition", count: featureCounts.number_of_exibition },
      {
        name: "feature artists in home page",
        count: featureCounts.feature_artists_in_home_page,
      },
      {
        name: "exclusive promotion of artworks on social media",
        count: featureCounts.exclusive_promotion_of_artworks_on_social_media,
      },
      {
        name: "Video promotion on YT and social networking",
        count: featureCounts.Video_promotion_on_YT_and_social_networking,
      },
      {
        name: "promotion artist on artsmiley blogs",
        count: featureCounts.promotion_artist_on_artsmiley_blogs,
      },
      {
        name: "solo online exhibitions",
        count: featureCounts.solo_online_exhibitions,
      },
      {
        name: "discount on first refusal opportunity on exhibitions",
        count: featureCounts.discount_on_first_refusal_opportunity_on,
      },
    ],
  })
  const [plan, setPlan] = useState({ plan_duration: "", plan_name: "" })
  const [checkeds, setCheckeds] = React.useState([])
  const [istrue, setIstrue] = useState(false)

  // {"plan_name":"Basic Plan","plan_duration":"30","plan_price":"500","plan_feature":[{"features":"Unlimited visitors"},{"features":"suggestaions"}]}
  const dispatch = useDispatch()
  const features = useSelector((state) => state.subscriptionfeatureData)
  const loader = useSelector((state) => state.loader)

  const handleAdd = () => {
    dispatch(handleAddSubscription(data, props.setOpen, props.fetchData))
  }
  const handleInputs = (e) => {
    const name = e.target.name
    const value = e.target.value
    setData({ ...data, [name]: value })
  }

  const handleFeatureChange = (e, index) => {
    const features = [...data.features]
    features[index] = { ...features[index], count: Number(e.target.value) }
    setData({ ...data, features })
  }

  const handleEditInputs = (e) => {
    const { name, value } = e.target
    setEditedData({ ...editeddata, [name]: value })
  }

  const handleEditFeatureChange = (e, index) => {
    const features = [...editeddata.features]
    features[index] = { ...features[index], count: Number(e.target.value) }
    setEditedData({ ...editeddata, features })
  }
  const fetchFeatureData = () => {
    dispatch(fetchsubscriptionFeature())
  }
  useEffect(() => {
    fetchFeatureData()
  }, [])
  const handleClose = () => props.setOpen(false)
  return (
    <div>
      {props.type === "edit" ? (
        // <Modal
        //   aria-labelledby="transition-modal-title"
        //   aria-describedby="transition-modal-description"
        //   open={props.open}
        //   onClose={handleClose}
        //   closeAfterTransition
        //   BackdropComponent={Backdrop}
        //   BackdropProps={{
        //     timeout: 500,
        //   }}
        // >
        //   <Fade in={props.open}>
        //     <Box className={styles.modal_campaigns}>
        //       <div className={styles.cancel_img}>
        //         <img
        //           onClick={handleClose}
        //           src={"/img/cancel_cross.png"}
        //           height="20px"
        //           width="20px"
        //         />
        //       </div>
        //       <p
        //         className={styles.modal_heading}
        //         id="transition-modal-title"
        //         variant="h6"
        //         component="h2"
        //       >
        //         Edit Subscription Plan
        //       </p>
        //       <div className={styles.input_title}>
        //         <label>Price</label>
        //         <br />
        //         <TextField
        //           onChange={handleEditInputs}
        //           name="plan_price"
        //           sx={{
        //             mt: 0.5,
        //             backgroundColor: "#f6f6f6",
        //             "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        //             borderRadius: "6px",
        //           }}
        //           size="small"
        //           fullWidth
        //           placeholder="Enter Price"
        //           type="text"
        //           defaultValue={editeddata.plan_price}
        //         />
        //       </div>
        //       <Grid
        //         container
        //         direction="row"
        //         justifyContent="space-between"
        //         sx={{
        //           my: 1.5,
        //           display: "flex",
        //           justifyContent: "space-between",
        //         }}
        //         alignItems="center"
        //         item
        //         columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        //         colgap={1.5}
        //         columns={{ xs: 12, sm: 12, md: 22 }}
        //       >
        //         {/* {input.map((val) => ( */}
        //         <Grid
        //           item
        //           xs={12}
        //           sm={6}
        //           md={11}
        //           className={styles.inputs_gird}
        //         >
        //           <label>Plan Duration</label>
        //           <br />
        //           <TextField
        //             onChange={handleEditInputs}
        //             name="plan_duration"
        //             sx={{
        //               mt: 0.5,
        //               backgroundColor: "#f6f6f6",
        //               "& .MuiOutlinedInput-notchedOutline": {
        //                 border: "none",
        //               },
        //               borderRadius: "6px",
        //             }}
        //             size="small"
        //             fullWidth
        //             // placeholder={`Enter ${val.label}`}
        //             type="text"
        //             defaultValue={editeddata.plan_duration}
        //           />
        //         </Grid>
        //         <Grid
        //           item
        //           xs={12}
        //           sm={6}
        //           md={11}
        //           className={styles.inputs_gird}
        //         >
        //           <label>Plan Name</label>
        //           <br />
        //           <TextField
        //             onChange={handleEditInputs}
        //             name="plan_name"
        //             sx={{
        //               mt: 0.5,
        //               backgroundColor: "#f6f6f6",
        //               "& .MuiOutlinedInput-notchedOutline": {
        //                 border: "none",
        //               },
        //               borderRadius: "6px",
        //             }}
        //             size="small"
        //             fullWidth
        //             // placeholder={`Enter ${val.label}`}
        //             type="text"
        //             defaultValue={editeddata.plan_name}
        //           />
        //         </Grid>
        //         {/* ))} */}
        //       </Grid>
        //       {/* <p className={styles.new_feature_heading}>Feature List</p> */}
        //       {/* <Box className={styles.checkboxs_fle}> */}
        //       <div className={styles.input_title}>
        //         <label>Number of Art</label>
        //         <br />
        //         <TextField
        //           onChange={(e) => handleEditFeatureChange(e, 0)}
        //           name="number_of_art"
        //           sx={{
        //             mt: 0.5,
        //             backgroundColor: "#f6f6f6",
        //             "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        //             borderRadius: "6px",
        //           }}
        //           size="small"
        //           fullWidth
        //           placeholder="Number of art"
        //           type="number"
        //           defaultValue={
        //             editeddata.features.find((f) => f.name === "no. of Arts")
        //               .count
        //           }
        //         />
        //       </div>
        //       <div className={styles.input_title}>
        //         <label>Number of Exibition</label>
        //         <br />
        //         <TextField
        //           onChange={(e) => handleEditFeatureChange(e, 2)}
        //           name="number_of_exibition"
        //           sx={{
        //             mt: 0.5,
        //             backgroundColor: "#f6f6f6",
        //             "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        //             borderRadius: "6px",
        //           }}
        //           size="small"
        //           fullWidth
        //           placeholder="Number of exibition"
        //           type="number"
        //           defaultValue={
        //             editeddata.features.find(
        //               (f) => f.name === "no. of Exhibition"
        //             ).count
        //           }
        //         />
        //       </div>
        //       <div className={styles.input_title}>
        //         <label>Number of Auction</label>
        //         <br />
        //         <TextField
        //           onChange={(e) => handleEditFeatureChange(e, 1)}
        //           name="number_of_auction"
        //           sx={{
        //             mt: 0.5,
        //             backgroundColor: "#f6f6f6",
        //             "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        //             borderRadius: "6px",
        //           }}
        //           size="small"
        //           fullWidth
        //           placeholder="Number of auction"
        //           type="number"
        //           defaultValue={
        //             editeddata.features.find((f) => f.name === "no. of Auction")
        //               .count
        //           }
        //         />
        //       </div>
        //       {/* </Box> */}
        //       <div className={styles.textarea_div}>
        //         <label className={styles.textarea_label}>Description</label>
        //         <br />
        //         <TextareaAutosize
        //           onChange={handleEditInputs}
        //           sx={{
        //             "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        //             "&&:after": {
        //               borderBottom: "none",
        //             },
        //           }}
        //           placeholder="Enter description"
        //           className={styles.textarea}
        //           fullWidth
        //           minRows={5}
        //           defaultValue={editeddata.description}
        //         />
        //       </div>
        // <div className={styles.action}>
        //   <Button
        //     onClick={() =>
        //       props.handleEdit(props.val && props.val._id, editeddata)
        //     }
        //     variant="contained"
        //     sx={{ borderRadius: "10px" }}
        //     className={styles.action_btn}
        //   >
        //     Submit
        //   </Button>
        // </div>
        //     </Box>
        //   </Fade>
        // </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box
              className={styles.modal_campaigns}
              sx={{
                width: "70vw", // Use a percentage or a specific value to control the width
                height: "90vh", // Use a percentage or a specific value to control the height
                maxWidth: "100vw", // Ensure it doesn't exceed the viewport width
                maxHeight: "100vh", // Ensure it doesn't exceed the viewport height
                overflow: "auto", // Ensure content is scrollable if it overflows
                bgcolor: "background.paper", // Optional: background color
                borderRadius: "10px", // Optional: border radius for rounded corners
                boxShadow: 24, // Optional: shadow for the modal
                p: 4, // Optional: padding inside the modal
                mx: "auto", // Center the modal horizontally
                my: "auto", // Center the modal vertically
              }}
            >
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Edit Subscription Plan
              </p>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                colgap={1.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                {/* {input.map((val) => ( */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Plan</label>
                  <br />
                  <TextField
                    onChange={handleEditInputs}
                    name="plan_name"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    // placeholder={`Enter ${val.label}`}
                    type="text"
                    defaultValue={editeddata.plan_name}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Price</label>
                  <br />
                  <TextField
                    onChange={handleEditInputs}
                    name="plan_price"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    // placeholder={`Enter ${val.label}`}
                    type="text"
                    defaultValue={editeddata.plan_price}
                  />
                </Grid>
                {/* ))} */}
              </Grid>
              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Description</label>
                <br />
                <TextareaAutosize
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  }}
                  placeholder="Enter description"
                  name="plan_description"
                  onChange={handleEditInputs}
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                  defaultValue={editeddata.description}
                />
              </div>
              <h3>Subscription features</h3>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Art</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 0)}
                    name="number_of_art"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of art"
                    type="number"
                    defaultValue={
                      editeddata.features.find((f) => f.name === "no. of Arts")
                        .count
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Exibition</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 2)}
                    name="number_of_exibition"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of exibition"
                    type="number"
                    defaultValue={
                      editeddata.features.find(
                        (f) => f.name === "no. of Exhibition"
                      ).count
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Auction</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 1)}
                    name="number_of_auction"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of auction"
                    type="number"
                    defaultValue={
                      editeddata.features.find(
                        (f) => f.name === "no. of Auction"
                      ).count
                    }
                  />
                </Grid>
              </Grid>
              {/* <div className={styles.input_title}>
                <label>Number of Art</label>
                <br />
              </div>
              <div className={styles.input_title}>
                <label>Number of Exibition</label>
                <br />
              </div>
              <div className={styles.input_title}>
                <label>Number of Auction</label>
                <br />
              </div> */}
              {/* </Box> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>feature artists in home page</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 3)}
                    name="feature_artists_in_home_page"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="feature artists in home page"
                    type="number"
                    defaultValue={
                      editeddata.features.find(
                        (f) => f.name === "feature artists in home page"
                      ).count
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>exclusive promotion of artworks on social media</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 4)}
                    name="exclusive_promotion_of_artworks_on_social_media"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="exclusive promotion of artworks"
                    type="number"
                    defaultValue={
                      editeddata.features.find(
                        (f) =>
                          f.name ===
                          "exclusive promotion of artworks on social media"
                      ).count
                    }
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>Video promotion on YT and social networking</label>
                  <br />
                  <TextField
                    onChange={(e) => handleEditFeatureChange(e, 5)}
                    name="Video_promotion_on_YT_and_social_networking"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of art"
                    type="number"
                    defaultValue={
                      editeddata.features.find(
                        (f) =>
                          f.name ===
                          "Video promotion on YT and social networking"
                      ).count
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>promotion artist on artsmiley blogs</label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleEditFeatureChange(e, 6)}
                      name="promotion_artist_on_artsmiley_blogs"
                      defaultValue={
                        editeddata.features.find(
                          (f) =>
                            f.name === "promotion artist on artsmiley blogs"
                        ).count
                      }
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>{" "}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>solo online exhibitions</label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleEditFeatureChange(e, 7)}
                      name="solo_online_exhibitions"
                      defaultValue={
                        editeddata.features.find(
                          (f) => f.name === "solo online exhibitions"
                        ).count
                      }
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>
                    discount on first refusal opportunity on exhibitions
                  </label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleEditFeatureChange(e, 8)}
                      name="discount_on_first_refusal_opportunity_on exhibitions"
                      defaultValue={
                        editeddata.features.find(
                          (f) =>
                            f.name ===
                            "discount on first refusal opportunity on exhibitions"
                        ).count
                      }
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <div className={styles.action}>
                <Button
                  onClick={() =>
                    props.handleEdit(props.val && props.val._id, editeddata)
                  }
                  variant="contained"
                  sx={{ borderRadius: "10px" }}
                  className={styles.action_btn}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box
              className={styles.modal_campaigns}
              sx={{
                width: "70vw", // Use a percentage or a specific value to control the width
                height: "90vh", // Use a percentage or a specific value to control the height
                maxWidth: "100vw", // Ensure it doesn't exceed the viewport width
                maxHeight: "100vh", // Ensure it doesn't exceed the viewport height
                overflow: "auto", // Ensure content is scrollable if it overflows
                bgcolor: "background.paper", // Optional: background color
                borderRadius: "10px", // Optional: border radius for rounded corners
                boxShadow: 24, // Optional: shadow for the modal
                p: 4, // Optional: padding inside the modal
                mx: "auto", // Center the modal horizontally
                my: "auto", // Center the modal vertically
              }}
            >
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Subscription Plan
              </p>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Plan</label>
                  <br />
                  <TextField
                    onChange={handleInputs}
                    name="plan_name"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Plan Name"
                    type="text"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Price</label>
                  <br />
                  <TextField
                    onChange={handleInputs}
                    name="plan_price"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="plan_price"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
              </Grid>
              {/*  */}
              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Description</label>
                <br />
                <TextareaAutosize
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  }}
                  placeholder="Enter description"
                  name="plan_description"
                  onChange={handleInputs}
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                />
              </div>
              <h3>Subscription features</h3>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 22 }}
              >
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Art</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 0)}
                    name="number_of_art"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of art"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Exibition</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 2)}
                    name="number_of_exibition"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of exibition"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={7} className={styles.inputs_gird}>
                  <label>Number of Auction</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 1)}
                    name="number_of_auction"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of auction"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
              </Grid>
              {/* <div className={styles.input_title}>
                <label>Number of Art</label>
                <br />
              </div>
              <div className={styles.input_title}>
                <label>Number of Exibition</label>
                <br />
              </div>
              <div className={styles.input_title}>
                <label>Number of Auction</label>
                <br />
              </div> */}
              {/* </Box> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>feature artists in home page</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 3)}
                    name="feature_artists_in_home_page"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="feature artists in home page"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>exclusive promotion of artworks on social media</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 4)}
                    name="exclusive_promotion_of_artworks_on_social_media"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="exclusive promotion of artworks"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
              </Grid>{" "}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>Video promotion on YT and social networking</label>
                  <br />
                  <TextField
                    onChange={(e) => handleFeatureChange(e, 5)}
                    name="Video_promotion_on_YT_and_social_networking"
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Number of art"
                    type="number"
                    defaultValue={0}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>promotion artist on artsmiley blogs</label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleFeatureChange(e, 6)}
                      name="promotion_artist_on_artsmiley_blogs"
                      defaultValue=""
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          paddingTop: "10px",
                          paddingBottom: "6px",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              minHeight: "30px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>{" "}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                  my: 1.5,
                  display: "flex",
                  justifyContent: "space-between",
                }}
                alignItems="center"
                item
                columnSpacing={{ xs: 0.5, sm: 1, md: 1 }}
                colgap={0.5}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>solo online exhibitions</label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleFeatureChange(e, 7)}
                      name="solo_online_exhibitions"
                      defaultValue=""
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          paddingTop: "10px",
                          paddingBottom: "6px",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              minHeight: "30px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={styles.inputs_gird}>
                  <label>
                    discount on first refusal opportunity on exhibitions
                  </label>
                  <br />
                  <FormControl
                    fullWidth
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      borderRadius: "6px",
                    }}
                  >
                    <Select
                      onChange={(e) => handleFeatureChange(e, 8)}
                      name="discount_on_first_refusal_opportunity_on exhibitions"
                      defaultValue=""
                      displayEmpty
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          paddingTop: "10px",
                          paddingBottom: "6px",
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root": {
                              minHeight: "30px",
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>Please select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>
              </Grid>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  sx={{ borderRadius: "10px" }}
                  className={styles.action_btn}
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
