import React, { useEffect, useState } from "react"
import styles from "./Categorylist.module.css"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import { fetchCategories } from "../../../../../State/api/superadminApi"
import Pagination from "../../Pagination/Pagination"
import Editcategory from "../../Popupdelete/editCategory/Editcategory"
import Tableheader from "../../Table/Tableheader/Tableheader"
import Threecolimg from "../../Table/Threecolmn/img/Threecolimg"
import Tabletwocol from "../../Table/Twocol/Tabletwocol"

const upcominghead = [
  {
    id: "_id",
    label: "ID",
  },
  {
    id: "name",
    label: "Category Name",
  },
]
export default function Categorylist(props) {
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        {/* <AlertsSuccess /> */}
        {/* {edit ? (
          <Editcategory
            fetchData={fetchData}
            dt_id={dt_id}
            open={edit}
            setOpen={setEdit}
          />
        ) : (
          ""
        )}
        {add ? (
          <Editcategory type="add" dt_id={dt_id} open={add} setOpen={setAdd} />
        ) : (
          ""
        )} */}
        {/* <Tableheader button=""  /> */}
        {/* <Threecolimg
          setDt_id={setDt_id}
          setOpen={setEdit}
          head={upcominghead}
          type="category_list"
          categoriesData={props.categoriesData}
        /> */}
        <Tabletwocol
          // setDt_id={setDt_id}
          setOpen={props.setOpen}
          handleDelete={props.handleDelete}
          open={props.open}
          head={upcominghead}
          activetab="category_list"
          data={props.categoriesData}
          handleAddCategoryPopup={props.handleAddCategoryPopup}
        />
        {/* <Pagination /> */}
      </div>
    </div>
  )
}
