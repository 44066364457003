import React, { useEffect, useState } from 'react';
import styles from './PrintDetailpage.module.css'
import { useParams } from 'react-router-dom';
import { deletePrintProduct, deletePrintProductPrice, editedPrintMaterial, editedPrintProduct, editedPrintProductPrice, fetchPrintMaterialData, fetchPrintPriceData, fetchPrintProductData, handleAddPrintProduct, handleAddPrintProductPrice } from '../../../../../State/api/superadminApi';
import { useDispatch, useSelector } from 'react-redux';
import homeImage from '../../../../../constant';
import { Button, IconButton, TextField } from '@mui/material';
import CircularLoader from '../../../../../common/loader/Loader';
import Enterdetails from '../../Popupdelete/enterDetails/Enterdetails';
import { AlertsSuccess } from '../../../../../common/alert/Alert';

const PrintDetailpage = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [materialDt, setMaterialDt] = useState(null);
    const [productDt, setProductData] = useState(null);
    const [printPriceDt, setPrintPriceData] = useState(null);
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(false);

    const [name, setName] = useState({ name: '', _id: "" })
    const [category, setCategory] = useState({ add: false, edit: false });

    const loader = useSelector((state) => state.loader);

    const fetchMaterialData = () => {
        dispatch(fetchPrintMaterialData(setMaterialDt));
    };

    const fetchProductData = () => {
        dispatch(fetchPrintProductData(setProductData));
    };

    const fetchprintPriceDt = () => {
        dispatch(fetchPrintPriceData({ print_material_id: id }, setPrintPriceData));
    };
    useEffect(() => {
        fetchMaterialData();
        fetchProductData();
        fetchprintPriceDt();
    }, []);

    const handleEditdata = (type, value, id) => {
        setCategory({ ...category, edit: true })
        setName({ ...name, name: value, _id: id });
    };

    const handleDelete = (type, id) => {
        switch (type) {
            case 'product':
                dispatch(deletePrintProduct(id, fetchProductData));
                break;
            case 'product_price':
                dispatch(deletePrintProductPrice(id, fetchprintPriceDt))
                break;
            default: break;
        }
    }

    const handleChange = (e) => {
        setName({ ...name, name: e.target.value });
    }

    const hundleSubmit = (type, id) => {
        switch (type) {
            case 'editProductPrice':
                dispatch(editedPrintProductPrice(id, { price: name.name }, fetchprintPriceDt))
                break;
            case 'editProduct':
                dispatch(editedPrintProduct(id, { name: name.name }, fetchProductData));
                break;
            //     case 'editStyle':
            //         dispatch(handleEdit(`api/itemStyle/editItemStyle/${id}`, name, fetchData));
            //         setCategory({ ...category, edit: false });
            //         break;
            //     case 'editMedium':
            //         dispatch(handleEdit(`api/itemMedium/editItemMedium/${id}`, name, fetchDataMedium));
            //         setCategory({ ...category, edit: false });
            //         break;
            //     case 'editSubject':
            //         dispatch(handleEdit(`api/itemSubject/editItemSubject/${id}`, name, fetchDataSubject));
            //         setCategory({ ...category, edit: false });
            //         break;
            default:
                break;
        }
        setCategory({ ...category, edit: false });
    }
    const handleAddBtn = () => {
        setOpen(true)
    }
    const handleAddProduct = (dt) => {
        dispatch(handleAddPrintProduct(dt, setOpen, fetchProductData));
    };
    const handleAddPrice = (price, product_id) => {
        dispatch(handleAddPrintProductPrice({ print_material_id: id, price: price, print_product_id: product_id }, setPrice, fetchprintPriceDt));
    };
    return (
        <div className={styles.mains}>
            <AlertsSuccess />
            {open ? (
                <Enterdetails
                    handleAdd={handleAddProduct}
                    poptype="print"
                    open={open}
                    setOpen={setOpen}
                />
            ) : (
                ""
            )}
            {price ? (
                <Enterdetails
                    handleAdd={handleAddPrice}
                    poptype="print_prcie"
                    open={price}
                    setOpen={setPrice}
                    productDt={productDt}
                />
            ) : (
                ""
            )}
            <h3>Material Name : {materialDt && materialDt.data.filter(v => v._id === id)[0].name} </h3>
            <hr />
            <div className={styles.main_details}>

                <div>
                    <div className={styles.add_div}>
                        <h5>Product Name</h5>
                        <Button variant='contained' onClick={handleAddBtn}>Add</Button>
                    </div>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : productDt && productDt.data.length === 0 ? "------" :
                        productDt && productDt.data.map(val => (
                            <div className={styles.labels}>
                                {category.edit && val._id === name._id ?
                                    <TextField value={name.name} onChange={handleChange} variant='outlined' type='text'></TextField>
                                    : <h6>{val.name}</h6>}
                                {category.edit && val._id === name._id ?
                                    <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editProduct', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEditdata("edit", val.name, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("product", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </div>
                        ))}
                </div>
                <div><div className={styles.add_div}>
                    <h5>Product Price</h5>
                    <Button variant='contained' onClick={() => setPrice(true)} >Add</Button>
                </div>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : printPriceDt && printPriceDt.data.length === 0 ? "------" :
                        printPriceDt && printPriceDt.data.map(val => (
                            <div className={styles.labels}>
                                {category.edit && val._id === name._id ?
                                    <TextField value={name.name} type="number" onChange={handleChange} variant='outlined' ></TextField>
                                    : <h6>{val.price}</h6>}
                                {category.edit && val._id === name._id ?
                                    <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editProductPrice', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEditdata("edit", val.price, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("product_price", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </div>
                        ))}
                </div>
            </div>


        </div>
    );
}

export default PrintDetailpage;
