import validator from "validator";
import isEmpty from "./isEmpty";

export const validationForRecreate = ({ email, message }) => {
  let recreateErrors = {};

  if (validator.isEmpty(email)) {
    recreateErrors.email = "Email is required";
  } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    recreateErrors.email = "Email is invalid";
  }

  if (validator.isEmpty(message)) {
    recreateErrors.message = "Message is required";
  }

  return {
    recreateErrors,
    isValidRecreate: isEmpty(recreateErrors),
  };
};
