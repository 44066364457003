import React, { useEffect, useState } from "react"
import styles from "./ReportsModal.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Grid, MenuItem, Select } from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import homeImage from "../../../../../constant"
import { useDispatch, useSelector } from "react-redux"
import { handleAddReports } from "../../../../../State/api/superadminApi"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import TextField from "@mui/material/TextField"
import dayjs from "dayjs"

const state = {
  option: [
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ],
}

export default function ReportsModal(props) {
  const [radio, setRadio] = useState("")
  const [hidesubs, setHideSubs] = useState(false)
  const [adminReport, setAdminReport] = useState({
    report_type: "",
    startDate: "",
    endDate: "",
    status: "",
    plan_name: "",
  })
  const handleChangeReport = (e) => {
    const { name, value } = e.target
    setAdminReport({ ...adminReport, [name]: value })
    if (name === "report_type") {
      setHideSubs(value === "artist")
    }
  }

  const getProductOptions = (report) => {
    switch (report) {
      case "buyer":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "artist":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "curator":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "gallery":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "order":
        return [
          { value: "Placed", label: "Placed" },
          {
            value: "Delivered",
            label: "Delivered",
          },
          {
            value: "Completed",
            label: "Completed",
          },
        ]
      case "arts":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]
      case "affiliate":
        return [
          { value: "Approved", label: "Approved" },
          {
            value: "Pending",
            label: "Pending",
          },
          {
            value: "Decline",
            label: "Decline",
          },
        ]

      default:
        return []
    }
  }

  const dispatch = useDispatch()

  const handleClose = () => props.setOpen(false)

  const handleStartDate = (start) => {
    setAdminReport({
      ...adminReport,
      startDate: dayjs(start).format("DD-MM-YYYY").toString(),
    })
  }
  const handleEndDate = (end) => {
    setAdminReport({
      ...adminReport,
      endDate: dayjs(end).format("DD-MM-YYYY").toString(),
    })
  }

  const handleClick = async () => {
    const response = await dispatch(handleAddReports(adminReport))
    if (response?.filePath) {
      const fileUrl = `http://3.6.185.87:8000${response.filePath}`
      window.open(fileUrl, "_blank")
    }
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_exhibition}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src={"/img/cancel_cross.png"}
                height="20px"
                width="20px"
                alt="img"
              />
            </div>
            <p
              className={styles.modal_heading}
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              Create Report
            </p>
            <div className={styles.input_title}>
              <label>Report type</label>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={adminReport.report_type}
                label="Report type"
                fullWidth
                sx={{
                  background: "#f6f6f6",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                placeholder="select type"
                onChange={handleChangeReport}
                name="report_type"
                // name="blog_type"
              >
                <MenuItem value="buyer">Buyer</MenuItem>
                <MenuItem value="artist">Artist</MenuItem>
                <MenuItem value="curator">Curator</MenuItem>
                <MenuItem value="gallery">Gallery</MenuItem>
                <MenuItem value="order">Order</MenuItem>
                <MenuItem value="arts">Arts</MenuItem>
                <MenuItem value="affiliate">Affiliate</MenuItem>
              </Select>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{ display: "flex", justifyContent: "space-between" }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 22 }}
            >
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>From Date</label>
                <br />
                {/* <input onChange={handleChange} name='start_date' value={addData.start_date} placeholder={`Enter Start Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="MM-DD-YYYY"
                    value={adminReport.startDate}
                    onChange={handleStartDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          background: "#F3F3F3",
                          borderRadius: "6px",
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>To Date</label>
                <br />
                {/* <input onChange={handleChange} name='end_date' value={addData.end_date} placeholder={`Enter End Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="MM-DD-YYYY"
                    value={adminReport.endDate}
                    onChange={handleEndDate}
                    minDate={adminReport.startDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          background: "#F3F3F3",
                          borderRadius: "6px",
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Status</label>
                <br />
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={adminReport.status}
                  label="Report type"
                  fullWidth
                  sx={{
                    background: "#f6f6f6",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  placeholder="select type"
                  onChange={handleChangeReport}
                  name="status"
                  // name="blog_type"
                >
                  {getProductOptions(adminReport.report_type).map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {hidesubs && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Subscription</label>
                  <br />
                  <input
                    onChange={handleChangeReport}
                    name="Subscription"
                    value={adminReport.plan_name}
                    placeholder={`Enter subscription `}
                    type="text"
                  />
                </Grid>
              )}
            </Grid>
            <br></br>
            <div className={styles.downloadcv_btn}>
              <Button
                variant="contained"
                onClick={handleClick}
                fullWidth
                className={styles.download_btn}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "162px",
                  height: "44px",
                  fontFfamily: "Poppins",
                }}
              >
                <img
                  src={"/img/Download Icon.png"}
                  width="16px"
                  height="13px"
                />{" "}
                Download CSV{" "}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
