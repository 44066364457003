import validator from "validator"
import isEmpty from "./isEmpty"

export const userProfileValidation = ( { flat_no,


city,
state,
country,

}) => {
  let errors = {}

  if (validator.isEmpty(flat_no)) {
    errors.flat_no = "Flat no is required"
  }

  if (validator.isEmpty(city)) {
    errors.city = "City is required"
  }
  if (validator.isEmpty(state)) {
    errors.state = "State is required"
  }

  if (validator.isEmpty(country)) {
    errors.country = "Country is required"
  }

  return {
    errors,
    isValidAddress: isEmpty(errors),
  }
}
