import { Checkbox, FormControlLabel } from "@mui/material"
import React from "react"
import Threedots from "../../Table/Threedots/Threedots"
import styles from "./Notification.module.css"
import homeImage from "../../../../../constant"

const data = [
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: true,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
  {
    src: "/img/image 1.png",
    name: "Laurie Fox",
    title: "Reported Order",
    content: "kioiewfew ou judif dfhj sdk uwer ij f ew...",
    time: "2:00 PM",
    check: false,
    btn: [
      {
        label: "Confirm File...",
      },
      {
        label: "Important ...",
      },
    ],
  },
]
const threedotlinks = [
  {
    link: "opened",
  },
]
export default function Notify() {
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <h5 style={{ textAlign: "center" }}>No Notification Found !</h5>
      </div>
    </div>
  )
}
