import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const userartisttypeSlice = createSlice({
    name: "userallartisttype",
    initialState,
    reducers: {
        setAllArtitType: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAllArtitType } = userartisttypeSlice.actions;

export default userartisttypeSlice.reducer;
