import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminHero = (hero) => {
  let heroErrors = {}

  if (validator.isEmpty(hero.title)) {
    heroErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(hero.sub_title)) {
    heroErrors.sub_title = "Subtitle is required"
  }
  if (validator.isEmpty(hero.button_name)) {
    heroErrors.button_name = "Button name is required"
  }
  if (validator.isEmpty(hero.position)) {
    heroErrors.position = "Position is required"
  }
  if (validator.isEmpty(hero.link)) {
    heroErrors.link = "Link is required"
  } else if (!validator.isURL(hero.link)) {
    heroErrors.link = "Invalid link"
  }

  if (!hero.image || hero.image === null) {
    heroErrors.image = "Image is required"
  }

  return {
    heroErrors,
    isValid: isEmpty(heroErrors),
  }
}
