import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
    orderType: null,
};

const allitemSlice = createSlice({
    name: "allitemdata",
    initialState,
    reducers: {
        setAllitemdata: (state, action) => {
            state.data = action.payload;
        },
        setOrderType: (state, action) => {
            state.orderType = action.payload;
        },
    },
});

export const { setAllitemdata, setOrderType } = allitemSlice.actions;

export default allitemSlice.reducer;
