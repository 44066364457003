import { Errornotify, Successnotify } from "../../common/alert/Alert";
import { removeUser } from "../Features/common/userSlice";
import api from "./Api";

const handleRemoveItemcart = async function (ids) {
 
  try {
    const result = await api.applicationApi(
      `api/user/emptyUsercart`,
      "DELETE"
    );
    // if ((result.status === 200) || (result.status === 201)) {
    
     
    // } else if (result.status === 400) {
    //   Errornotify(result.data.message);
    // } else if (result.status === 401) {
    // }
  } catch (err) {
    console.log(err);
  }
};
export const placeOrderApi =
  (
   data
  ) =>
  async (dispatch) => {
    try {
   console.log(data,'in place order action')
      const apiResponse = await  api.applicationApi("api/order/addOrder",
      "POST",
      data);

      if (apiResponse.status === 200 || apiResponse.status === 201) {
        localStorage.setItem("order-data",JSON.stringify(data))
        handleRemoveItemcart()
        Successnotify(apiResponse.data.message);
       setTimeout(() => {
        window.location.replace("/user/order-placed");
       },1500)
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          );
          Errornotify(apiResponse.data.message);
        } else {
          Errornotify(apiResponse.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };