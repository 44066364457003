import { Button } from "@mui/material"
import styles from "./Affiliatedetail.module.css"
import React, { useEffect, useState } from "react"
import Artistdetail from "../../Artist/Common/Artistdetail"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchAffiliateProfileData } from "../../../../../State/api/superadminApi"
import { affliateData } from "../../../../../State/Features/superadmin/affiliateprofileSlice"
import AffiliateDetailsPage from "../../../../../affiliate/AffiliateDetail/AffiliateDetailsPage"

const dt = {
  img: "",
  name: "Emily Cooper",
  more_details: [],
}
export default function Affiliatedetail() {
  const [pendingProfileData, setPendingProfileData] = useState({
    artist_name: "Emily Cooper",
  })

  var { id } = useParams()
  const dispatch = useDispatch()
  const affiliateprofileData = useSelector(
    (state) => state.affiliateprofiledata
  )
  useEffect(() => {
    dispatch(fetchAffiliateProfileData(id))
  }, [])
  return (
    <div className={styles.mains}>
      <AffiliateDetailsPage dt={dt} data={affiliateprofileData.data} />
      {/* <div className={styles.action_div}>
        <Button className={styles.btn} variant="contained">
          Accept
        </Button>
        <Button className={styles.btn} variant="outlined">
          Reject
        </Button>
      </div> */}
    </div>
  )
}
