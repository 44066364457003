import { Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./AllCategory.module.css";
// import "fontsource-marcellus";
import { Container } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import serverimage from "../../../serverConstant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoryData } from "../../../State/api/userApi";
import axios from "axios";
import CircularLoader from "../../../common/loader/Loader";

function AllCategory() {

  const navigate = useNavigate();
  const categorydt = useSelector((state) => state.allcategoryData);

  const dispatch = useDispatch();
  const fetchData = () => {
    dispatch(fetchCategoryData({}));
  }
  useEffect(() => {
    fetchData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])

  const navigateToArtwork = (id) => {
    navigate(`/user/allcategory/${id}`);
  };

  const loader = useSelector((state) => state.loader);
  const handleNavigate = (route) => {
    navigate(route)
  }
  const theme = createTheme({
    typography: {
      h4: {
        fontFamily: "Marcellus",
        fontSize: "2vw",
      },
      h6: {
        fontFamily: "Marcellus",
        fontSize: "1vw",
      },
      imgText: {
        fontFamily: "Marcellus",
        fontWeight: 400,
        align: "center",
        fontSize: "1.15vw",
      },
    },
  });
  return (
    <>
      {loader.loader ?
        <div className={styles.loader}>
          <CircularLoader />
        </div>
        :
        <ThemeProvider theme={theme}>
          <div className={styles.allcategory}>
            <div className={styles.headerCategory}>
              <br />
              <div>
                <Typography variant="h4">
                  <span className={styles.headerTitle}>All Category</span>
                </Typography>
                <Typography variant="h6">
                  <span className={styles.headerSubTitle}>
                    <b style={{ cursor: 'pointer' }} onClick={() => handleNavigate('/user/')}>Home/</b>
                    Category
                  </span>
                </Typography>
              </div>
            </div>
            <div className={styles.container__div}>
              <Container>
                <Grid container spacing={0}>
                  {categorydt.data && categorydt.data.allcategories.map((item) => (
                    <Grid style={{ cursor: "default" }} onClick={() => navigateToArtwork(item._id)} item xs={6} sm={4} md={4} lg={4}>
                      <div className={styles.card__main}>
                        <div className={styles.card__top}>
                          <img className={styles.card__Image} src={ item.image} alt='img' />
                          <div className={styles.imgText}>
                            <div>
                              <Typography variant="imgText">
                                <span className={styles.textSpan}>
                                  {item.name}
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </div>
          </div>
        </ThemeProvider>
      }
    </>
  );
}

export default AllCategory;
