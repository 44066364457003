import React, { useEffect, useState } from "react"
import styles from "./Menulist.module.css"
// import { fetchCategories } from "../../../../../State/api/superadminApi"
import Pagination from "../Pagination/Pagination"
import Tabletwocol from "../Table/Twocol/Tabletwocol"

const upcominghead = [
  {
    id: "_id",
    label: "ID",
  },
  {
    id: "name",
    label: "Menu Name",
  },
  {
    id: "heading",
    label: "Heading",
  },
]
export default function MenuList(props) {
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        {/* <AlertsSuccess /> */}
        {/* {edit ? (
          <Editcategory
            fetchData={fetchData}
            dt_id={dt_id}
            open={edit}
            setOpen={setEdit}
          />
        ) : (
          ""
        )}
        {add ? (
          <Editcategory type="add" dt_id={dt_id} open={add} setOpen={setAdd} />
        ) : (
          ""
        )} */}
        {/* <Tableheader button=""  /> */}
        {/* <Threecolimg
          setDt_id={setDt_id}
          setOpen={setEdit}
          head={upcominghead}
          type="category_list"
          categoriesData={props.categoriesData}
        /> */}
        <Tabletwocol
          // setDt_id={setDt_id}
          // setOpen={props.setOpen}
          handleDelete={props.handleDelete}
          // headingByMenu={props.headingByMenu}
          // open={props.open}
          head={upcominghead}
          activetab="menu_list"
          data={props.menuesData}
          handleAddCategoryPopup={props.handleAddCategoryPopup}
        />
        {/* <Pagination /> */}
      </div>
    </div>
  )
}
