import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:null,

}

const subscriptionSlice = createSlice({
    name: 'subscriptionData',
    initialState,
    reducers: {
        storeData: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const { storeData } = subscriptionSlice.actions;

export const subscriptionData = (state) => state.subscriptionData;

export default subscriptionSlice.reducer;