import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  
}

export const profileSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    addData: (state, action) => {
      // console.log(state.data);
      state.data = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { addData } = profileSlice.actions;

export const selectprofile = (state) => state.data;

export default profileSlice.reducer