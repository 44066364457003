import React, { useState } from 'react'
// import './Login.css'
import styles from './varify.module.css';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import homeImage from '../../constant';
import { useNavigate } from 'react-router-dom';

export default function Varifycode({ handleOtpVerify }) {
    const [user, setUser] = useState({ first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' });
    const otps = user.first.concat(user.second).concat(user.third).concat(user.fourth).concat(user.fifth).concat(user.sixth)
    const navigate = useNavigate();
    const handleChange = (e) => {
        let name = e.target.name;
        let val = e.target.value
        setUser({ ...user, [name]: val });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <div className={styles.login_main}>
            <div className={styles.left_main_varify}>
                <img alt='img' src={ '/img/Login Image.png'} height='60%' width='50%' className={styles.img_login} />
            </div>
            <div className={styles.right_main_o}>
                <div>
                    <div>
                        <img alt='img' onClick={() => navigate('/user/')} src={ '/img/updated_logo.png'} className={styles.img_forgot} />
                        <h2 className={styles.heading_art_varify}>OTP Verification</h2>
                    </div>
                    <div>
                        <span className={styles.para_span_otp}>
                            <span className={styles.para_spa}>{localStorage.getItem("art_mail_email")}</span></span><br />
                        <span className={styles.opt_text}> Enter the OTP you received to</span>
                    </div>
                    <div className={styles.opt_box_div}>
                        <div className={styles.opt_all}>
                            <input maxlength="1" autofocus={true} className={styles.otp_box} value={user.first} name='first' onChange={handleChange} type='text' />
                            <input maxlength="1" className={styles.otp_box} value={user.second} name='second' onChange={handleChange} type='text' />
                            <input maxlength="1" className={styles.otp_box} value={user.third} name='third' onChange={handleChange} type='text' />
                            <input maxlength="1" className={styles.otp_box} value={user.fourth} name='fourth' onChange={handleChange} type='text' />
                            <input maxlength="1" className={styles.otp_box} value={user.fifth} name='fifth' onChange={handleChange} type='text' />
                           
                        </div>
                        {/* <form>
                            <div className={user.otp ? 'input-box active' : 'input-box'}>
                                <input name='otp' style={{ textAlign: 'center' }} value={user.otp} onChange={handleChange} autoFocus maxLength={6} type="number" secure />
                            </div>
                            {resendTime > 0 ? <span className='resend-span'>resend otp in {resendTime} seconds</span> : <button className='resend-btn' onClick={resendOtpHandler} type='button'>Resend OTP</button>}
                                {error && (<p className='error-message'>{error}</p>)}
                            <button type='button' onClick={changeOtpView}>Send</button>
                        </form> */}
                        <div className={styles.resend}>
                            <span> Resend OTP?</span>
                        </div>
                        <div className={styles.submit_otp}>
                            <Button variant='contained' fullWidth className={styles.submit_otp_btn} type='submit' onClick={() => handleOtpVerify(otps)}>Continue</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
