import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const userartistSlice = createSlice({
    name: "userartistdata",
    initialState,
    reducers: {
        setUserArtistdata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setUserArtistdata } = userartistSlice.actions;

export default userartistSlice.reducer;
