import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:null,

}

const subscriptionfeatureSlice = createSlice({
    name: 'subscriptionfeatureData',
    initialState,
    reducers: {
        setsubscriptionFeature: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setsubscriptionFeature } = subscriptionfeatureSlice.actions;

export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default subscriptionfeatureSlice.reducer;