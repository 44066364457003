import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const usercareerSlice = createSlice({
  name: "userallcareer",
  initialState,
  reducers: {
    setUserAllCareer: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setUserAllCareer } = usercareerSlice.actions

export default usercareerSlice.reducer
