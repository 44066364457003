import React from "react";
import styles from "./Popup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import homeImage from "../../../../constant";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 4,
};

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
];

export default function Popup(props) {
  console.log(props.popupdata);
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);
  const handleBlockunblock = () => {
    const is_blocked = props.popupdata.is_blocked;
    console.log(is_blocked);
    props.handleBlockunblock &&
      props.handleBlockunblock(props.popupdata._id, !is_blocked);
  };
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            <Typography
              className={styles.modal_heading}
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              {props.popupdata?.first_name} {props.popupdata?.last_name}
            </Typography>
            <hr width="100%" className={styles.horizontal_line} />
            <div className={styles.data_div}>
              {/* {data.map((val) => ( */}
              <div className={styles.user_detail}>
                <h5 className={styles.user_title}>username</h5>
                <p className={styles.user_value}>
                  {props.popupdata?.first_name} {props.popupdata?.last_name}
                </p>
              </div>

              <div className={styles.user_detail}>
                <h5 className={styles.user_title}>Customer ID</h5>
                <p className={styles.user_value}>{props.popupdata?._id}</p>
              </div>

              <div className={styles.user_detail}>
                <h5 className={styles.user_title}>Buyer name</h5>
                <p className={styles.user_value}>
                  {props.popupdata?.first_name} {props.popupdata?.last_name}
                </p>
              </div>

              <div className={styles.user_detail}>
                <h5 className={styles.user_title}>No.of Items</h5>
                <p className={styles.user_value}>{props.popupdata?.items}</p>
              </div>

              <div className={styles.user_detail}>
                <h5 className={styles.user_title}>Email Address</h5>
                <p className={styles.user_value}>{props.popupdata?.email}</p>
              </div>

              {/* ))} */}
            </div>
            <div className={styles.action}>
              <Button
                variant="contained"
                sx={{ mr: 1, width: "100px" }}
                onClick={handleBlockunblock}
                color={props.popupdata?.is_blocked ? "secondary" : "primary"}
              >
                {props.popupdata?.is_blocked ? "Unblock" : "Block"}
              </Button>
              <Button
                variant="outlined"
                onClick={() => props.handleDeletebuyer(props.popupdata._id)}
                sx={{ ml: 1, width: "100px" }}
              >
                Delete
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
