import { Button, Fade, Modal } from "@mui/material";
import React from "react";
import styles from "./Uploadpopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import homeImage from "../../../../constant";
import { useLocation, useNavigate } from "react-router-dom";

export default function Uploadpopup(props) {

  const navigate = useNavigate();
 const location = useLocation()
 console.log(location.pathname,'pathname')
 const handleClose = (e) => {
  props.setOpen(false);
  if(e.target.value === "accept"){
   if(location.pathname === "/upload/stepfirst") {
    props.setUploadArt((prevState) => ({
      ...prevState,
      is_showcased: "Yes",
    }));
    navigate("/upload/stepsecond")
   }else {
 navigate('/edit/stepsecond')
   }
  }
 };
 return (
  <div>
   <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={props.open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
     timeout: 500,
    }}>
    <Fade in={props.open}>
     {props.poptype === "uploaded" ? (
      <Box className={styles.modal_uploaded}>
       <div className={styles.cancel_btn_div}>
        <img
         src={ "/img/cancels.png"}
         onClick={handleClose}
         width="21px"
         height="21px"
        />
       </div>
       <div className={styles.done_icon}>
        <img src={ "/img/checkmark 1 .png"} width="74px" height="67px" />
        <h1>Done!</h1>
       </div>
       <h2 className={styles.upload_done}>
        Your artwork submitted to curator review & approval and this process may take up to 15 days.
       </h2>
      </Box>
     ) : (
      <Box className={styles.modal_del}>
       <div className={styles.main_div}>
        <h2>
         Please note that this artwork should be exclusively available for Artsmiley and can't
         showcase on any other platforms at least for a period of 6 months
        </h2>
        <div className={styles.btn_div}>
         <Button
          variant="contained"
          value="accept"
          onClick={handleClose}
          sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
          Accept
         </Button>
         <Button
          variant="outlined"
          onClick={handleClose}
          value="decline"
          sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
          Decline
         </Button>
        </div>
       </div>
      </Box>
     )}
    </Fade>
   </Modal>
  </div>
 );
}
