import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const addMarketingSlice = createSlice({
  name: "addmarketingdata",
  initialState,
  reducers: {
    setAddMarketingdata: (state, action) => {
      state.data = action.payload
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
})

export const { setAddMarketingdata } = addMarketingSlice.actions

// export const exshibitionData = (state) => state.addmarketingdata;

export default addMarketingSlice.reducer
