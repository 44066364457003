import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminAuction = (auc) => {
  let aucErrors = {}

  if (validator.isEmpty(auc.name)) {
    aucErrors.name = "Name is required"
  }

  if (validator.isEmpty(auc.theme)) {
    aucErrors.theme = "Theme is required"
  }

  if (validator.isEmpty(auc.start_date)) {
    aucErrors.start_date = "Start date is required"
  }

  if (validator.isEmpty(auc.end_date)) {
    aucErrors.end_date = "End date is required"
  }
  if (validator.isEmpty(auc.application_end_date)) {
    aucErrors.application_end_date = "Application end date is required"
  }
  if (validator.isEmpty(auc.description)) {
    aucErrors.description = "Description is required"
  }

  return {
    aucErrors,
    isValid: isEmpty(aucErrors),
  }
}
