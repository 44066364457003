import React from "react";
import Moreoption from "../../../../components/Pages/List/Table/Moreoption/Moreoption";
import Tableheader from "../../../../components/Pages/List/Table/Tableheader/Tableheader";

export default function Commonadmindisputegallery(props) {
 return (
  <div>
   <Tableheader button="" 
   handleFilter={props.handleFilter}
   handleSorting={props.handleSorting}
   filterdata={props.filterdata}
   sortdata={props.sortdata}
   condition={props.condition}
   search={props.condition.search}
   handleSearch={props.handleSearch}/>
   {/* <Moreoption
    moreOptions={props.moreOptions}
    handleMorecategories={props.handleMorecategories}
    activetab={props.activetab}
    setActivetab={props.setActivetab}
    setData={props.setData}
   /> */}
  </div>
 );
}
