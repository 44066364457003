import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./UserDispute.module.css";
import Add from "../../../List/Popupdelete/addPopup/Addpopup";
import Commonadmindisputegallery from "../../../../../gallery/pages/adminDispute/common/Commonadmindisputegallery";
import Tableheader from "../../../List/Table/Tableheader/Tableheader";
import Pagination from "../../../List/Pagination/Pagination";
import Sevencoltable from "../../../../../artist/commonTables/sevencol/Sevencoltable";
import { fetchDisputeData } from "../../../../../State/api/superadminApi";
import TableHeaderFollowedArtist from "../../../List/Table/Tableheader/TableHeaderFollowedArtist";
const head = [
   {
      id: "artist_name ",
      label: "Dispute ID",
   },
   {
      id: "mobile_number",
      label: "Order ID",
   },
   {
      id: "type",
      label: "Title",
   },
   {
      id: "assign_to",
      label: "Description",
   },
   {
      id: "actiont",
      label: "Refund",
   },
   {
      id: "action",
      label: "Status",
   },
];

const placeholder = {
   placeholder: "upload new pic",
};

export default function UserDispute() {
   
   const [open, setOpen] = useState(false);
   const dispatch = useDispatch();
   const [searchInput, setSearchInput] = useState("");
   const [sortedValue, setSortedValue] = useState(10);
 
   const [data, setData] = useState({ createdBy: "1", offset: 1 });
   const disputedata = useSelector((state) => state.disputeData.data);
   console.log(disputedata,'disputedata')
   const filteredFollowedArtist = disputedata?.data
   ?.filter((data) => 
     data?.title.toLowerCase().includes(searchInput.toLowerCase())
   
   )
   .slice(0, sortedValue);
 
   useEffect(() => {
      dispatch(fetchDisputeData(data));
   }, [dispatch,data]);
   return (
      <div className={styles.mains}>
         {open ? <Add poptype="gallery_dispute" val={placeholder} open={open} setOpen={setOpen} /> : ""}
         <div className={styles.components}>
         <TableHeaderFollowedArtist
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          setSortedValue={setSortedValue}
          sortedValue={sortedValue}
          type="dispute"
          
        />
            <Sevencoltable
               head={head}
               data={filteredFollowedArtist}
            />
            <Pagination totaldata={disputedata && disputedata.totalCount}
        pagination={data}
        setPagination={setData} />
         </div>
      </div>
   );
}
