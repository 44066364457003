import React, { lazy } from "react"
import { Route, Routes } from "react-router-dom"
import Home from "./common/home/Home"
import AuctionDetail from "./components/Pages/List/Auction/auctionDetail/AuctionDetail"
import CuratorArtdetail from "./curator/pages/artPage/artDetail/CuratorArtdetail"
import UserMain from "./UserMain"
import Artistprofile from "./components/Pages/List/Artist/Artistprofile/Artistprofile"
import { useSelector } from "react-redux"
import Applyhome from "./curator/pages/applyForm/applyHome/Applyhome"
import Fillquestion from "./curator/pages/applyForm/questions/Fillquestion"
import { useState } from "react"
import CuratorArtistprofile from "./curator/pages/artist/profile/CuratorArtistprofile"
import ArtistProfileCurator from "./components/Pages/List/Artist/ArtistProfileCurator"
import CuratorExhibitionDetailsSlice from "./State/Features/superadmin/curatorExhibitionDetailsSlice"
import CuratorExhibitiondetails from "./components/Pages/List/Curator/CuratorExhibitionDetails/CuratorExhibitionDetails"
import CuratorTestimonial from "./components/Pages/List/Curator/Testimonials/CuratorTestimonials"
const CuratorAccount = lazy(() =>
  import("./curator/pages/account/CuratorAccount")
)
const Applyform = lazy(() => import("./curator/pages/applyForm/Applyform"))
const CuratorArtist = lazy(() => import("./curator/pages/artist/CuratorArtist"))
const CuratorArt = lazy(() => import("./curator/pages/artPage/CuratorArt"))
const CuratorAuction = lazy(() =>
  import("./curator/pages/auction/CuratorAuction")
)
const Curatordashboard = lazy(() =>
  import("./curator/pages/dashboard/CuratorDashboard")
)
const Earning = lazy(() => import("./curator/pages/earning/Earning"))
const CuratorExhibition = lazy(() =>
  import("./curator/pages/exhibition/CuratorExhibition")
)
const CuratorAcceptreject = lazy(() =>
  import("./curator/pages/exhibition/acceptReject/CuratorAcceptreject")
)
const CuratorNotification = lazy(() =>
  import("./curator/pages/notification/CuratorNotification")
)
const CuratorOrder = lazy(() => import("./curator/pages/order/CuratorOrder"))
const CuratorOrderDetail = lazy(() =>
  import("./curator/pages/order/orderDetails/OrderDetail")
)

export default function Curatormain() {
  // const Navigate = useNavigate();
  const user = useSelector((state) => state.user.data)
  return (
    <Routes>
      {user.is_accepted === "Pending" ? (
        <>
          <Route path="*" element={<UserMain />} />

          <Route path="/home" element={<Home user="Curator" />} />
        </>
      ) : (
        <>
          <Route path="/dashboard" element={<Curatordashboard />} />
          <Route path="/myaccount" element={<CuratorAccount />} />
          <Route path="/earnings" element={<Earning />} />
          <Route
            path="/curator-testimonials"
            element={<CuratorTestimonial />}
          />
          <Route path="/notification" element={<CuratorNotification />} />
          <Route path="/orders" element={<CuratorOrder />} />
          <Route path="/art" element={<CuratorArt />} />
          <Route path="/artists" element={<CuratorArtist />} />
          <Route path="/artist/:id" element={<Artistprofile />} />
          <Route path="/exhibitions" element={<CuratorExhibition />} />
          <Route path="/auction" element={<CuratorAuction />} />

          {/* <Route path='*' element={<Navigate to="/" />} /> */}
          {/* <Route path="/artist/:id" element={<ArtistProfileCurator />} /> */}
          <Route path="/art/:id" element={<CuratorArtdetail />} />
          <Route path="/auction/:id" element={<AuctionDetail />} />
          <Route path="/orders/:id" element={<CuratorOrderDetail />} />
          {/* <Route path='/orders/details/:id' element={<} */}
          {/* <Route path="/exhibitions/:id" element={<CuratorAcceptreject />} /> */}
          <Route
            path="/exhibitions/:id"
            element={<CuratorExhibitiondetails />}
          />
          <Route path="/artist/:id" element={<Artistprofile />} />
          <Route path="*" element={<UserMain />} />
          <Route
            path="/curator/artist/:id"
            element={<CuratorArtistprofile />}
          />
        </>
      )}
      {/* <Route path='/detail' element={<CuratorOrderDetail />} /> */}
      {/* 
              <Route path='/curator/artist/:id' element={<CuratorArtistprofile />} />
              <Route path='/curatoraccept' element={<CuratorAcceptreject />} />
              <Route path='/curatorapplyhome' element={<Applyhome />} />
            <Route path='/curatorfillquestion' element={<Fillquestion />} /> */}
    </Routes>
  )
}
