import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../../common/alert/Alert"
import {
  curatorAcceptDcline,
  curatorBlockUnblock,
  deleteCurator,
  fetchCuratorData,
  handleAddCurator,
} from "../../../../State/api/superadminApi"
import { selectpagination } from "../../../../State/Features/common/paginationSlice"
import {
  curatorData,
  setCuratordata,
} from "../../../../State/Features/superadmin/curatorSlice"
import Artistprofile from "../Artist/Artistprofile/Artistprofile"
import Artistdetail from "../Artist/Common/Artistdetail"
import Pagination from "../Pagination/Pagination"
import Add from "../Popupdelete/addPopup/Addpopup"
import Tablesfour from "../Table/Fourcolumn/Tables"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Curator.module.css"
import Moreoption from "../Table/Moreoption/Moreoption"
import Curatordetails from "./Curatordetails/Curatordetails"
import Curatorprofile from "./Curatorprofile/Curatorprofile"

const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "approved",
  },
  {
    id: "decline",
    label: "Decline",
    value: "decline",
  },
]
const approvedhead = [
  {
    id: "curator_name ",
    label: "Curator Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "weekly_earning",
    label: "Weekly Earning",
  },
  {
    id: "action",
    label: "Action",
  },
]
const sortdata = [
  { label: "Weekly Earning ↓", value: "low" },
  { label: "Weekly Earning ↑", value: "high" },
]
const filterdata = [
  { label: "Pending", value: "pending" },
  { label: "Approved", value: "approved" },
  { label: "Rejected", value: "rejected" },
]

export default function Curator() {
  // const [pagetype, setPagetype] = useState("");
  const [activetab, setAcitvetab] = useState("pending")
  const [poptype, setPoptype] = useState("")
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [popup, setPopup] = useState(false)
  const [newcurator, setNewCurator] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    role: "curator ",
  })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const [pendingProfileData, setPendingProfileData] = useState({
    artist_name: "Emily Cooper",
  })
  const dispatch = useDispatch()
  const curatorsData = useSelector((state) => state.curatorData.data)
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const pagination = useSelector(selectpagination)
  useEffect(() => {
    fetchData()
  }, [data])
  const fetchData = () => {
    dispatch(fetchCuratorData(data))
  }
  const handleAcceptDecline = (id, dt) => {
    dispatch(curatorAcceptDcline(id, dt, fetchData))
  }
  const handleDelete = (id) => {
    dispatch(deleteCurator(id, fetchData))
  }

  const handleBlockUnBlock = (id, dt) => {
    dispatch(curatorBlockUnblock(id, { is_blocked: dt }, fetchData))
  }
  const handleAddnew = () => {
    if (newcurator.phone.length !== 10) {
      Errornotify("Please Enter Valid Number")
    } else if (newcurator.password.length < 8) {
      Errornotify("Please fill password atleast 8 character")
    } else {
      dispatch(handleAddCurator(newcurator, setPopup, fetchData))
    }
  }
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewCurator({ ...newcurator, [name]: value })
  }
  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
    setCondition({ ...condition, sorting: value })

    if (curatorsData.data) {
      let sortedData = [...curatorsData.data].sort((a, b) => {
        const aEarning = a.curator[0]?.weekly_earning || 0
        const bEarning = b.curator[0]?.weekly_earning || 0

        if (value === "low") {
          return aEarning - bEarning
        } else if (value === "high") {
          return bEarning - aEarning
        }
        return 0
      })

      dispatch(
        setCuratordata({
          data: sortedData,
          totalCount: curatorsData.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {popup ? (
        <Add
          handleChange={handleChange}
          poptype="curator"
          fetchData={fetchData}
          handleAddnew={handleAddnew}
          open={popup}
          setOpen={setPopup}
        />
      ) : (
        ""
      )}
      <div className={styles.component}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          poptype={poptype}
          search={condition.search}
          handleSearch={handleSearch}
          button={button}
          setOpen={setPopup}
          className={styles.tableheader_enter}
        />
        <Moreoption
          //  setButton={setButton}
          activetab={activetab}
          setPoptype={setPoptype}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
          data={data}
        />
        <Tablesfour
          // setPagetype={setPagetype}
          activetab="curator"
          head={approvedhead}
          data={
            curatorsData &&
            curatorsData.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val
                } else if (condition.filter === "pending") {
                  return val.is_accepted === "Pending"
                } else if (condition.filter === "approved") {
                  return val.is_accepted === "Approved"
                } else {
                  return val.is_accepted === "Rejected"
                }
              })
              .filter((val) => {
                if (condition.search === "") {
                  return val
                }
                {
                  const fullName = `${val.first_name || ""} ${
                    val.last_name || ""
                  }`
                    .toLowerCase()
                    .trim()
                  const search = condition.search.toLowerCase().trim()
                  const fullNameMatch = fullName.includes(search)
                  const nameMatch =
                    val.first_name &&
                    val.first_name.toLowerCase().includes(search)
                  const lastnameMatch =
                    val.last_name &&
                    val.last_name.toLowerCase().includes(search)
                  const emailMatch =
                    val.email && val.email.toLowerCase().includes(search)

                  return (
                    nameMatch || lastnameMatch || emailMatch || fullNameMatch
                  )
                }
              })
              .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          }
          handleAcceptDecline={handleAcceptDecline}
          handleDelete={handleDelete}
          handleBlockUnBlock={handleBlockUnBlock}
        />
        {curatorsData &&
          Array.isArray(curatorsData.data) &&
          curatorsData.data.length > 0 && (
            <Pagination
              totaldata={curatorsData && curatorsData.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )}

        {/* <Curatordetails /> */}
      </div>
    </div>
  )
}
