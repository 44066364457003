import * as React from "react"
import { useState, useEffect } from "react"
import styles from "./Sidebar.module.css"
import { useLocation, useNavigate } from "react-router-dom"
import Commonsidebar from "../List/Common/sideBar/Commonsidebar"

const sidebarRoutes = [
  { name: "ART", icon: "fa fa-dashboard", path: "/art", hasSubmenu: false },
  { name: "ARTIST", icon: "fa fa-truck", path: "/artist", hasSubmenu: false },
  {
    name: "CURATORS",
    icon: "fa fa-users",
    path: "/curators",
    hasSubmenu: false,
  },
  {
    name: "BUYER",
    icon: "fa fa-th-large",
    path: "/buyerlist",
    hasSubmenu: false,
  },
  { name: "ORDERS", icon: "fa fa-cube", path: "/orders", hasSubmenu: false },
  {
    name: "EXHIBITIONS",
    icon: "fa fa-cube",
    path: "/exhibitions",
    hasSubmenu: false,
  },
  { name: "AUCTION", icon: "fa fa-cube", path: "/auction", hasSubmenu: false },
  {
    name: "SUB ADMIN",
    icon: "fa fa-exchange",
    path: "/subadmin",
    hasSubmenu: false,
  },
  {
    name: "CHAT",
    icon: "fa fa-exchange",
    path: "/livechat",
    hasSubmenu: false,
  },
  {
    name: "AFFILIATE",
    icon: "fa fa-file-invoice",
    path: "/affiliate",
    hasSubmenu: false,
  },
  {
    name: "NOTIFICATION",
    icon: "fa fa-home",
    path: "/notification",
    hasSubmenu: false,
  },
  { name: "INQUIRY", icon: "fa fa-gear", path: "/inquiry", hasSubmenu: false },
  {
    name: "OFFERS",
    icon: "fa-solid fa-comments",
    path: "/offers",
    hasSubmenu: false,
  },
  {
    name: "BUYER'S OFFERS",
    icon: "fa-solid fa-comments",
    path: "/buyeroffer",
    hasSubmenu: false,
  },
  { name: "RECREATE REQUEST", path: "/recreate", hasSubmenu: false },
  {
    name: "QUOTE REQUEST",
    icon: "fa-solid fa-comments",
    path: "/quoterequest",
    hasSubmenu: false,
  },
  {
    name: "TESTIMONIALS",
    icon: "fa fa-home",
    path: "/testimonials",
    hasSubmenu: false,
  },
  { name: "GALLERY", icon: "fa fa-gear", path: "/gallerys", hasSubmenu: false },
  {
    name: "MENU",
    icon: "fa-solid fa-comments",
    path: "/menu",
    hasSubmenu: false,
  },
  {
    name: "CAMPAIGNS",
    icon: "fa fa-home",
    path: "/campaigns",
    hasSubmenu: false,
  },
  { name: "CAREER", icon: "fa fa-gear", path: "/career", hasSubmenu: false },
  {
    name: "SUBSCRIPTION",
    icon: "fa-solid fa-comments",
    path: "/subscription",
    hasSubmenu: false,
  },
  {
    name: "PRESS RELEASE",
    icon: "fa fa-gear",
    path: "/pressrelease",
    hasSubmenu: false,
  },
  {
    name: "BLOG",
    icon: "fa-solid fa-comments",
    path: "/blog",
    hasSubmenu: false,
  },
  {
    name: "GIFT CARD",
    icon: "fa-solid fa-comments",
    path: "/giftcard",
    hasSubmenu: false,
  },
  { name: "ACTIVITY PAGE", path: "/activity", hasSubmenu: false },
  { name: "HERO SECTION", path: "/herosection", hasSubmenu: false },
  { name: "CATEGORY", path: "/category", hasSubmenu: false },
  { name: "PRINT", path: "/print", hasSubmenu: false },
  { name: "PRINT PRICING", path: "/print-pricing", hasSubmenu: false },
  { name: "REPORTS", path: "/reports", hasSubmenu: false },
  { name: "POLICIES", path: "/policies", hasSubmenu: false },
  { name: "ALL ACCOUNT", path: "/allaccount", hasSubmenu: false },
  { name: "FAQ", path: "/faq", hasSubmenu: false },

  { name: "DISPUTE", path: "/dispute", hasSubmenu: false },
  { name: "RATING AND REVIEW", path: "/ratingreview", hasSubmenu: false },
  { name: "SETTINGS", path: "/settings", hasSubmenu: false },
  { name: "FRAME COUNTRY", path: "/framecountry", hasSubmenu: false },
  { name: "CONTACT-US", path: "/contactus", hasSubmenu: false },
]

export default function Sidebar() {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname

  const [filteredRoutes, setFilteredRoutes] = useState(sidebarRoutes)

  useEffect(() => {
    // Fetch the admin role and is_subadmin status from localStorage
    const adminRole = localStorage.getItem("admin_role")
    const isSubAdmin = localStorage.getItem("is_subadmin") // Convert to boolean

    if (isSubAdmin && adminRole) {
      // Parse the adminRole string into an array of route names
      const allowedRoles = adminRole.split(",").map((role) => role.trim())

      // Filter the sidebar routes based on allowed roles
      const allowedRoutes = sidebarRoutes.filter((route) =>
        allowedRoles.includes(route.name)
      )

      setFilteredRoutes(allowedRoutes)
    } else {
      // If not subadmin, show all routes
      setFilteredRoutes(sidebarRoutes)
    }
  }, [])

  const handleClick = (val) => {
    navigate(val)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <Commonsidebar sidebarRoutes={filteredRoutes} />
    </>
  )
}
