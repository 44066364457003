import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null
};

const editArtSlice = createSlice({
    name: "editArtId",
    initialState,
    reducers: {
        setEditArtId: (state, action) => {
            state.id = action.payload
        }
    }
})

export const {setEditArtId} = editArtSlice.actions;
export  default editArtSlice.reducer