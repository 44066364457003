import { createTheme, Grid, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./UserAuction.module.css";
import homeImage from "../../../constant";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import {
  setActiveStatus,
  setAuctionstatus,
} from "../../../State/Features/user/activetabSlice";
import moment from "moment";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});
function UserAuction() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [auctionData, setAuctionData] = useState();
  const auctionstatus = useSelector((state) => state.activetab);

  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleFetchAuction = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/user/getauctions");
      if (result && result.status === 200) {
        setAuctionData(result.data);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    handleFetchAuction();
  }, []);
  const gotoAuctionDetail = (id) => {
    navigate(`/user/auction/${id}`);
  };
  const handleAuctionstatus = (vl) => {
    dispatch(setActiveStatus({ status: vl, maintab: "auction" }));
  };
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.userauction}>
        <div className={styles.headerCategory}>
          <br />
          <div>
            <Typography variant="h4">
              <span className={styles.headerTitle}>Auction</span>
            </Typography>
            <Typography variant="h6">
              <span className={styles.headerSubTitle}>
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/user")}
                >
                  Home /{" "}
                </label>
                <b>Auction</b>
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.main__container}>
          <div className={styles.link__div}>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <Typography
                color={auctionstatus.status === "ongoing" ? "" : "#636365"}
                onClick={() => handleAuctionstatus("ongoing")}
                fontSize="1vw"
                fontFamily="marcellus"
              >
                ONGOING
              </Typography>
            </Link>
            <div className={styles.verticalLine3}></div>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => handleAuctionstatus("past")}
            >
              <Typography
                fontSize="1vw"
                color={auctionstatus.status === "past" ? "" : "#636365"}
                fontFamily="marcellus"
              >
                PAST
              </Typography>
            </Link>
            <div className={styles.verticalLine3}></div>
            <Link style={{ textDecoration: "none", color: "black" }}>
              <Typography
                onClick={() => handleAuctionstatus("upcoming")}
                fontSize="1vw"
                color={auctionstatus.status === "upcoming" ? "" : "#636365"}
                fontFamily="marcellus"
              >
                UPCOMING
              </Typography>
            </Link>
          </div>
          <hr className={styles.divider} />
          <div className={styles.auctioncards__Main}>
            <Grid container columnSpacing={2}>
              {auctionData &&
              auctionData.response.filter((item) => {
                return (
                  (auctionstatus.status === "upcoming"
                    ? new Date(item.end_date.substring(0, 10)) > new Date() > 0
                    : auctionstatus.status === "past"
                    ? new Date(item.start_date.substring(0, 10)) <
                      new Date() >
                      0
                    : new Date(item.start_date.substring(0, 10)) <=
                        new Date() >
                        0 &&
                      new Date(item.end_date.substring(0, 10)) >=
                        new Date() >
                        0) && item.is_accepted === "Approved"
                );
              }).length === 0 ? (
                <label style={{ textAlign: "center", width: "100%" }}>
                  No Auction Found
                </label>
              ) : (
                auctionData &&
                auctionData.response
                  .filter((item) => {
                    return (
                      (auctionstatus.status === "upcoming"
                        ? new Date(item.end_date.substring(0, 10)) >
                          new Date() >
                          0
                        : auctionstatus.status === "past"
                        ? new Date(item.start_date.substring(0, 10)) <
                          new Date() >
                          0
                        : new Date(item.start_date.substring(0, 10)) <=
                            new Date() >
                            0 &&
                          new Date(item.end_date.substring(0, 10)) >=
                            new Date() >
                            0) && item.is_accepted === "Approved"
                    );
                  })
                  .map((item) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      onClick={() => gotoAuctionDetail(item._id)}
                    >
                      <div className={styles.auction__cards}>
                        <img
                          className={styles.auctionImg}
                          src={
                            item.item_id
                              ?  item.item_id.image
                              : "/img/artist_profile.png"
                          }
                          alt="img"
                          height="300px"
                        />
                        <Typography
                          fontWeight="600"
                          fontSize="0.95rem"
                          fontFamily={"DM Sans, sans-serif"}
                        >
                          {item.name}
                        </Typography>
                        <div className={styles.auction__timing}>
                          <Typography
                            fontSize="0.6rem"
                            fontFamily={"DM Sans, sans-serif"}
                          >
                            Ends:
                          </Typography>
                          <Typography
                            fontSize="0.6rem"
                            fontFamily={"DM Sans, sans-serif"}
                          >
                            {moment(item.end_date).format("DD-MM-YYYY")} at{" "}
                            {moment(item.end_date).format("hh:mm A")}
                          </Typography>
                        </div>
                        <hr />
                        <div className={styles.auction__price}>
                          <div className={styles.bid__price}>
                            <Typography
                              fontSize="0.8rem"
                              color="#636365"
                              fontFamily={"DM Sans, sans-serif"}
                            >
                              Current Bid
                            </Typography>
                            <Typography
                              fontSize="0.8rem"
                              fontWeight="600"
                              fontFamily={"DM Sans, sans-serif"}
                            >
                              $ 3254
                            </Typography>
                          </div>
                          <div className={styles.verticalLine}></div>
                          <div className={styles.bid__price}>
                            <Typography
                              fontSize="0.8rem"
                              color="#636365"
                              fontFamily={"DM Sans, sans-serif"}
                            >
                              Next Valid Bid
                            </Typography>
                            <Typography
                              fontSize="0.8rem"
                              fontWeight="600"
                              fontFamily={"DM Sans, sans-serif"}
                            >
                              $ 680
                            </Typography>
                          </div>
                        </div>
                        <div className={styles.auction__history}>
                          <Typography
                            fontSize="0.55rem"
                            fontFamily={"DM Sans, sans-serif"}
                          >
                            Estimate:
                          </Typography>
                          <Typography
                            fontSize="0.55rem"
                            fontFamily={"DM Sans, sans-serif"}
                          >
                            ${item.estimate ? item.estimate : "0"}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))
              )}
            </Grid>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default UserAuction;
