import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert"
import {
  acceptDeclineAffiliate,
  deleteAffiliate,
  fetchAffiliateData,
  handleAddAffiliate,
} from "../../../../State/api/superadminApi"
import { setAffiliatedata } from "../../../../State/Features/superadmin/affiliateSlice"
import Pagination from "../Pagination/Pagination"
import Add from "../Popupdelete/addPopup/Addpopup"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Affiliate.module.css"
import Affiliatedetail from "./Affiliatedetail/Affiliatedetail"
const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "affiliatepending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "affiliateapproved",
  },
  {
    id: "decline",
    label: "Decline",
    value: "affiliatedecline",
  },
]
const approvedhead = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "mobile",
    label: "Mobile Number",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "type",
    label: "Account type",
  },
  {
    id: "action",
    label: "Action",
  },
]
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]

export default function Affiliate() {
  const [activetab, setAcitvetab] = useState("affiliatepending")
  const [data, setData] = useState({ offset: 1, is_accepted: "Pending" })
  const affiliateData = useSelector((state) => state.affiliatedata.data)
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [popup, setPopup] = useState(false)
  const [newaffiliate, setNewAffiliate] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    role: "affiliate ",
  })

  const handleAddnew = () => {
    if (newaffiliate.phone.length !== 10) {
      Errornotify("Please Enter Valid Number")
    } else if (newaffiliate.password.length < 8) {
      Errornotify("Please fill password atleast 8 character")
    } else {
      dispatch(handleAddAffiliate(newaffiliate, setPopup, fetchData))
    }
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    setNewAffiliate({ ...newaffiliate, [name]: value })
  }
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const dispatch = useDispatch()
  const handleAcceptDecline = (id, data) => {
    dispatch(acceptDeclineAffiliate(id, { is_accepted: data }, fetchData))
  }
  const handleDelete = (id) => {
    dispatch(deleteAffiliate(id, fetchData))
  }
  const fetchData = () => {
    dispatch(fetchAffiliateData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setAffiliatedata({
          data:
            affiliateData.data &&
            [...affiliateData.data].sort((a, b) =>
              a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: affiliateData.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setAffiliatedata({
          data:
            affiliateData.data &&
            [...affiliateData.data].sort((a, b) =>
              a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
            ),
          totalCount: affiliateData.totalCount,
        })
      )
    } else {
      // Default fetch data when no filter is applied
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (event) => {
    const value = event.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAffiliatedata({
          data:
            affiliateData.data &&
            [...affiliateData.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: affiliateData.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAffiliatedata({
          data:
            affiliateData.data &&
            [...affiliateData.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: affiliateData.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {popup ? (
        <Add
          handleChange={handleChange}
          poptype="curator"
          fetchData={fetchData}
          handleAddnew={handleAddnew}
          open={popup}
          setOpen={setPopup}
        />
      ) : (
        ""
      )}
      <div className={styles.component}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          button={button}
          setOpen={setPopup}
        />
        <Moreoption
          setData={setData}
          handleMorecategories={handleMorecategories}
          activetab={activetab}
          setAcitvetab={setAcitvetab}
          moreOptions={moreOptions}
          data={data}
        />
        {activetab === "affiliatepending" ? (
          <Tablesfivecol
            handleDelete={handleDelete}
            handleAcceptDecline={handleAcceptDecline}
            activetab={activetab}
            head={approvedhead}
            data={
              affiliateData &&
              affiliateData.data
                //   .filter((val) => {
                //   return (
                //     val.is_accepted === "Pending" &&
                //     (
                //       val.first_name.toLowerCase() +
                //       " " +
                //       val.last_name.toLowerCase()
                //     ).includes(condition.search)
                //   )
                // })
                .filter((val) => {
                  const fullName = `${val.first_name || ""} ${
                    val.last_name || ""
                  }`
                    .toLowerCase()
                    .trim()
                  const search = condition.search.toLowerCase().trim()
                  const nameMatch =
                    val.first_name &&
                    val.first_name.toLowerCase().includes(search)
                  const lastnameMatch =
                    val.last_name &&
                    val.last_name.toLowerCase().includes(search)
                  const emailMatch =
                    val.email && val.email.toLowerCase().includes(search)
                  const fullNameMatch = fullName.includes(search)
                  const phoneMatch =
                    val.phone && val.phone.toLowerCase().includes(search)

                  return (
                    val.is_accepted === "Pending" &&
                    (nameMatch ||
                      lastnameMatch ||
                      emailMatch ||
                      fullNameMatch ||
                      phoneMatch)
                  )
                })
            }
          />
        ) : activetab === "affiliatedecline" ? (
          <Tablesfivecol
            handleDelete={handleDelete}
            activetab={activetab}
            head={approvedhead}
            data={
              affiliateData &&
              affiliateData.data.filter((val) => {
                return (
                  val.is_accepted === "Rejected" &&
                  (
                    val.first_name.toLowerCase() +
                    " " +
                    val.last_name.toLowerCase()
                  ).includes(condition.search)
                )
              })
            }
          />
        ) : (
          <Tablesfivecol
            handleDelete={handleDelete}
            activetab={activetab}
            head={approvedhead}
            data={
              affiliateData &&
              affiliateData.data.filter((val) => {
                const fullName = `${val.first_name || ""} ${
                  val.last_name || ""
                }`
                  .toLowerCase()
                  .trim()
                const search = condition.search.toLowerCase().trim()
                const nameMatch =
                  val.first_name &&
                  val.first_name.toLowerCase().includes(search)
                const lastnameMatch =
                  val.last_name && val.last_name.toLowerCase().includes(search)
                const emailMatch =
                  val.email && val.email.toLowerCase().includes(search)
                const fullNameMatch = fullName.includes(search)
                const phoneMatch =
                  val.phone && val.phone.toLowerCase().includes(search)

                return (
                  val.is_accepted === "Approved" &&
                  (nameMatch ||
                    lastnameMatch ||
                    emailMatch ||
                    fullNameMatch ||
                    phoneMatch)
                )
              })
            }
          />
        )}
        {affiliateData &&
          Array.isArray(affiliateData.data) &&
          affiliateData.data.length > 0 && (
            <Pagination
              totaldata={affiliateData && affiliateData.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )}
      </div>
    </div>
  )
}
