import React from "react"
import styles from "./PopupRequest.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Fade, Grid } from "@mui/material"
import dayjs from "dayjs"
import serverimage from "../../../../serverConstant"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 600,
  bgcolor: "background.paper",
  // border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 1,
}

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
]

export default function PopupRequest(props) {
  console.log(props.popupdata, "data--recreate")
  const [open, setOpen] = React.useState(false)
  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false)
  const handleBlockunblock = () => {
    const is_blocked = props.popupdata.is_blocked
    console.log(is_blocked)
    props.handleBlockunblock &&
      props.handleBlockunblock(props.popupdata._id, !is_blocked)
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            {/* grid */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{
                my: 1.5,
                display: "flex",
                justifyContent: "space-between",
              }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 22 }}
            >
              <Grid item xs={12} sm={4} md={11} className={styles.inputs_gird}>
                <img
                  alt="img"
                  src={` ${props.popupdata?.item_id?.image}`}
                  height="180px"
                  width="200px"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={11} className={styles.inputs_gird}>
                <div className={styles.data_div}>
                  {/* {data.map((val) => ( */}
                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Name:</h5>
                    <p className={styles.user_value}>
                      {props.popupdata?.item_id?.name}
                    </p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Category:</h5>
                    <p className={styles.user_value}>
                      {" "}
                      {props.popupdata?.item_id?.category_id?.name}
                    </p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Subject:</h5>
                    <p className={styles.user_value}>
                      {props.popupdata?.first_name}
                      {props.popupdata?.item_id?.subject_id?.name}
                    </p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Request Date:</h5>
                    <p className={styles.user_value}>
                      {dayjs(props.popupdata?.updatedAt)
                        .format("DD-MM-YYYY")
                        .toString()}
                    </p>
                  </div>

                  {/* ))} */}
                </div>
              </Grid>
            </Grid>

            <div>
              <h5 className={styles.requestpop}>Message for Quote Request:</h5>
              <p>{props.popupdata?.item_id?.description}</p>

              <h5 className={styles.requestpop}>Quatation:</h5>
              <p> {/* {props.popupdata?.item_id?.category_id?.name} */}</p>
            </div>

            {/*  */}
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
