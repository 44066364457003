import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const PrintPricingSlice = createSlice({
  name: "printPricingData",
  initialState,
  reducers: {
    setPrintPricingdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setPrintPricingdata } = PrintPricingSlice.actions

// export const compaingnsData = (state) => state.compaingnsData;

export default PrintPricingSlice.reducer
