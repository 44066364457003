import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const AffiliateSlice = createSlice({
    name: "affiliatedata",
    initialState,
    reducers: {
        setAffiliatedata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAffiliatedata } = AffiliateSlice.actions;

export const afiliatedata = (state) => state.affiliatedata;

export default AffiliateSlice.reducer;
