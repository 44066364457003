import * as React from "react"
import styles from "./EightcolumnTable.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Box, Button, IconButton } from "@mui/material"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import serverimage from "../../../../../serverConstant"
import dateFormat from "../../../../../utils/dateFormat"
import { useNavigate } from "react-router-dom"

export default function NineColumnTable(props) {
  //   console.log(props.data);
  const navigate = useNavigate();
  console.log(props, "propsnine")
  const loader = useSelector((state) => state.loader)
  const handleDelete = (data) => {
    props.handlePopupQuote(data)
  }
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.headData.map((column) => (
                <TableCell
                  className={styles.table_head}
                  align="start"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    onClick={() => handleDelete(row)}
                    style={{ color: "#636365", cursor: "pointer" }}
                    key={i}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <img
                        src={`${row.item_id?.image}`}
                        height="42px"
                        width="60px"
                        alt="offer"
                      />
                    </TableCell>

                    <TableCell
                      className={styles.table_column}
                      component="th"
                      scope="row"
                    >
                      {row?.first_name + " " + row?.last_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.item_id?.sku_id}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.item_id?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.message}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {dateFormat(row?.createdAt)}
                    </TableCell>
                    {props.activetab !== "qoute_request" && (
                      <TableCell
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                        }}
                        className={styles.table_column}
                        align="left"
                      >
                        {row.description ? row.description : ""}
                      </TableCell>
                    )}

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.is_accepted === "Approved" ? (
                        <span className={styles.accepted}> Accepted</span>
                      ) : row.is_accepted === "Pending" ? (
                        <span
                          className={styles.pending}
                          style={{ color: "#F2C94C" }}
                        >
                          {" "}
                          Pending
                        </span>
                      ) : (
                        <span className={styles.rejected}> Rejected</span>
                      )}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.is_accepted === "Approved" ? (
                        <p style={{color:"black",textDecoration:"underline"}}    onClick={() => {
                          navigate("/user/checkout", {
                            state: {
                              artist_id: row?.item_id?.artist_id,
                              order_type_id: props.orderTypes?.find((val) => val.name === "Request For Quote")?._id,
                              item_id: row?.item_id._id,
                              price: row?.price
                            },
                          });
    
                          localStorage.setItem("product_prices", row?.price);
                        }}>Checkout</p> 
                      ) : (
                        " "
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  )
}
