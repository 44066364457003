import { Tab } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  acceptDeclineExshibition,
  deleteExhibtion,
  fetchExshibitionAdmin,
  handleAddExshibition,
  // fetchCuratorData,
  fetchCuratorDataList,
} from "../../../../State/api/superadminApi"
import { setExshibitiondata } from "../../../../State/Features/superadmin/exshibitionSlice"
import Pagination from "../Pagination/Pagination"
import Exhibitionmodal from "../Popupdelete/Exhibition/Exhibitionmodal"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Tablesfour from "../Table/Fourcolumn/Tables"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tables from "../Table/sixcolumn/Tables"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Exhibition.module.css"
import Csvfile from "../../../../downloadFiles/Csvfile"
import AdminTableExhibition from "../Table/sixcolumn/AdminTableExhibition"

const moreOptions = [
  {
    id: "request",
    label: "Request",
    value: "request",
  },
  {
    id: "exhibitionupcoming",
    label: "Upcoming",
    value: "exhibitionupcoming",
  },
  {
    id: "exhibitionlive",
    label: "Live",
    value: "exhibitionlive",
  },
  {
    id: "exhibitionpast",
    label: "Past",
    value: "exhibitionpast",
  },
  {
    id: "exhibitiondecline",
    label: "Decline",
    value: "exhibitiondecline",
  },
]
const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Axhibitions Title",
  },
  {
    id: "mobile_number",
    label: "Artist Name",
  },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
  {
    id: "action",
    label: "Action",
  },
]

const exhibitionDeclinehead = [
  {
    id: "artist_name ",
    label: "Exhibition Title",
  },
  {
    id: "mobile_number",
    label: "Artist Name",
  },
  {
    id: "email",
    label: "Free/Paid",
  },
  {
    id: "assign_to",
    label: "Start Date",
  },
  {
    id: "type",
    label: "End Date",
  },
]

const livehead = [
  {
    id: "artist_name ",
    label: "Exhibition Title",
  },
  {
    id: "location",
    label: "Artist Name",
  },
  {
    id: "like",
    label: "Free/Paid",
  },
  {
    id: "followe",
    label: "End Date",
  },
]

const upcominghead = [
  {
    id: "artist_name ",
    label: "Exhibition Title",
  },
  {
    id: "location",
    label: "Artist Name",
  },
  {
    id: "like",
    label: "Free/Paid",
  },
  {
    id: "like",
    label: "Start Date",
  },
  {
    id: "follow",
    label: "End Date",
  },
]
const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
]
const filterdata = [
  { label: "Free", value: "free" },
  { label: "Paid", value: "paid" },
  //  { label: "Rejected", value: "rejected" },
]
export default function Exhibition() {
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [open, setOpen] = useState(false)
  const [activetab, setAcitvetab] = useState("request")
  const [data, setData] = useState({ is_accepted: "", offset: 1 })
  const [curData, setCurData] = useState({ is_accepted: "Approved" })
  const curatorsData = useSelector((state) => state.curatorData.data)
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const exshibitiondata = useSelector(
    (state) => state.exhibitionAdminData?.data
  )
  console.log(exshibitiondata, "exshibitiondata--=--")
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const dispatch = useDispatch()
  const fetchData = () => {
    dispatch(fetchExshibitionAdmin(data))
  }

  const loader = useSelector((state) => state.loader)
  console.log(loader)
  useEffect(() => {
    fetchData()
  }, [data])

  useEffect(() => {
    fetchDataCurator()
  }, [curData])
  const fetchDataCurator = () => {
    dispatch(fetchCuratorDataList(curData, 100))
  }
  const acceptReject = (id, dt) => {
    dispatch(acceptDeclineExshibition(id, { is_accepted: dt }, fetchData))
  }
  const handleAdd = (dt) => {
    dispatch(handleAddExshibition(dt, setOpen, fetchData))
  }
  const handleDelete = (id) => {
    dispatch(deleteExhibtion(id, fetchData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setExshibitiondata({
          data:
            exshibitiondata.data &&
            [...exshibitiondata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() < b.start_date.toLowerCase())
                return -1
            }),
          totalCount: exshibitiondata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setExshibitiondata({
          data:
            exshibitiondata.data &&
            [...exshibitiondata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() > b.start_date.toLowerCase())
                return -1
            }),
          totalCount: exshibitiondata.totalCount,
        })
      )
    } else if (value === "endlow") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setExshibitiondata({
          data:
            exshibitiondata.data &&
            [...exshibitiondata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) return -1
            }),
          totalCount: exshibitiondata.totalCount,
        })
      )
    } else if (value === "endhigh") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setExshibitiondata({
          data:
            exshibitiondata.data &&
            [...exshibitiondata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) return -1
            }),
          totalCount: exshibitiondata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <>
      {open ? (
        <Exhibitionmodal
          handleAdd={handleAdd}
          open={open}
          setOpen={setOpen}
          curatorsData={curatorsData}
        />
      ) : (
        <></>
      )}
      <AlertsSuccess />
      <div className={styles.mains}>
        <div className={styles.compoents}>
          <Tableheader
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            handleSearch={handleSearch}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            search={condition.search}
            setOpen={setOpen}
            button={button}
          />
          <div className={styles.more_download}>
            <Moreoption
              setButton={setButton}
              activetab={activetab}
              moreOptions={moreOptions}
              handleMorecategories={handleMorecategories}
              setData={setData}
            />
            {exshibitiondata && (
              <Csvfile
                exshibitiondata={exshibitiondata && exshibitiondata.data}
              />
            )}
          </div>

          {activetab === "request" ? (
            <AdminTableExhibition
              acceptReject={acceptReject}
              activetab={activetab}
              head={exhibitionhead}
              handleDelete={handleDelete}
              data={
                exshibitiondata &&
                exshibitiondata.data
                  .filter((val) => {
                    return val.is_accepted === "Pending"
                  })
                  .filter((val) =>
                    val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                  .filter((val) => {
                    if (condition.filter === "free") {
                      return val.is_paid === false
                    } else if (condition.filter === "paid") {
                      return val.is_paid === true
                    } else {
                      return val
                    }
                    //  }).filter(val =>{
                    //   if(condition.sorting === 'low'){
                    //     return val
                    //   }
                  })
              }
            />
          ) : activetab === "exshibitionupcoming" ? (
            <Tablesfivecol
              activetab={activetab}
              head={upcominghead}
              data={
                exshibitiondata &&
                exshibitiondata.data.filter((val) => {
                  return (
                    val.is_accepted === "Approved" &&
                    new Date(val.end_date.substring(0, 10)) > new Date() > 0 &&
                    val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                })
              }
            />
          ) : activetab === "exshibitiondecline" ? (
            <>
              <Tablesfivecol
                activetab={activetab}
                head={exhibitionDeclinehead}
                data={
                  exshibitiondata &&
                  exshibitiondata.data.filter((val) => {
                    return (
                      val.is_accepted === "Rejected" &&
                      val.title
                        .toLowerCase()
                        .includes(condition.search.toLowerCase())
                    )
                  })
                }
              />{" "}
            </>
          ) : activetab === "exhibitionlive" ? (
            <Tablesfour
              activetab={activetab}
              head={livehead}
              data={
                exshibitiondata &&
                exshibitiondata.data.filter((val) => {
                  return (
                    val.is_accepted === "Approved" &&
                    new Date(val.start_date.substring(0, 10)) <=
                      new Date() >
                      0 &&
                    new Date(val.end_date.substring(0, 10)) >= new Date() > 0 &&
                    val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                })
              }
            />
          ) : (
            <Tablesfivecol
              activetab={activetab}
              head={upcominghead}
              data={
                exshibitiondata &&
                exshibitiondata.data.filter((val) => {
                  return (
                    val.is_accepted === "Approved" &&
                    new Date(val.end_date.substring(0, 10)) < new Date() > 0 &&
                    val.title
                      .toLowerCase()
                      .includes(condition.search.toLowerCase())
                  )
                })
              }
            />
          )}
          {exshibitiondata &&
            Array.isArray(exshibitiondata.data) &&
            exshibitiondata.data.length > 0 && (
              <Pagination
                totaldata={exshibitiondata && exshibitiondata.totalCount}
                pagination={data}
                setPagination={setData}
              />
            )}
        </div>
      </div>
    </>
  )
}
