import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const testimonialSlice = createSlice({
    name: "testimonialData",
    initialState,
    reducers: {
        setTestimonialdata: (state, action) => {
            state.data = action.payload;
        }
    },
});

export const { setTestimonialdata } = testimonialSlice.actions;

// export const testimonial = (state) => state.testmonialdata;

export default testimonialSlice.reducer;
