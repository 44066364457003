import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const userfaqSlice = createSlice({
    name: "userfaqdata",
    initialState,
    reducers: {
        setFaqdata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setFaqdata } = userfaqSlice.actions;

export default userfaqSlice.reducer;
