import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Tooltip,
} from "@mui/material"
import React, { useState } from "react"
import styles from "./Commoninputs.module.css"
import 'react-quill/dist/quill.snow.css'; // Default Quill styles

import "./quill.css"
import homeImage from "../../../../../constant"
import { indexof } from "stylis"
import serverimage from "../../../../../serverConstant"
import { Download } from "@mui/icons-material"
import { ToastContainer } from "react-toastify"
import ReactQuill from "react-quill"

export default function Commoninputs(props) {
  console.log(props, "props,affiliate--")
  const handlePopup = () => {
    props.setOpen && props.setOpen(true)
  }

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",

  ];
  return (
    <>
      {props.rendertype && props.rendertype === "Artist" ? (
        <>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>First Name</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="first_name"
                  // defaultValue={localStorage.getItem("user_name").substring(
                  //   0,
                  //   localStorage.getItem("user_name").lastIndexOf(" "))}
                  value={props.detail?.first_name}
                  placeholder="Enter your first name"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Last Name</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="last_name"
                  // defaultValue={localStorage.getItem("user_name").substring(
                  //   localStorage.getItem("user_name").length,
                  //   localStorage.getItem("user_name").lastIndexOf(" "))}
                  value={props.detail?.last_name}
                  placeholder="Enter your last name"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Phone Number</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="number"
                  name="phone"
                  value={props.detail && props.detail.phone}
                  placeholder="Enter your phone number"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Email Address</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="email"
                  name="email"
                  // disabled
                  value={props.detail && props.detail.email}
                  placeholder="Enter Your Email"
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.password_label}>
                <label className={styles.input_label}>Password</label>
                <label
                  className={styles.forgot}
                  onClick={() => props.setPasspop(true)}
                >
                  Change Password?
                </label>
              </div>
              <TextField
                className={styles.input_pass}
                variant="outlined"
                type="password"
                // disabled
                value={localStorage.getItem("password")}
                name="password"
                placeholder="**"
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid
              container
              spacing={1}
              sx={{ paddingTop: "20px", paddingBottom: "10px" }}
            >
              <Grid item xs={12} sm={10} md={12} sx={{ paddingBottom: "10px" }}>
                <label
                  className={styles.input_label}
                  style={{ fontWeight: "300", fontSize: "26px" }}
                >
                  About Us
                </label>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div >
                  <label className={styles.input_label} style={{marginBottom:"5px"}}>
                  Please write about yourself, education, art qualifications, inspiration behind your artworks etc *
                  </label>
                  {/* <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="about"
                    value={props.detail && props.detail.about}
                    placeholder="Enter about your self"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  /> */}
                    <ReactQuill
  name="about"
  value={props.detail?.about || ""}
  placeholder="Write about yourself"
  style={{
    height: '120px',
    textAlign: "left",
    // background:'white',
    marginBottom: "35px",
  }}
  onChange={(value) => props.setApply({ ...props.detail, about: value })}
  theme="snow"
  modules={modules}
  formats={formats}
/>

                </div>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>Education</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="qualification"
                    value={ props.detail.qualification || ""}
                    placeholder="Enter Your Qualification"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>
                    Inspiration Behind Your Artswork
                  </label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="inspiration_behind_artwork"
                    value={
                      props.detail && props.detail.inspiration_behind_artwork
                    }
                    placeholder="Enter Your inspiration behind artwork"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>
                    Art Qualification
                  </label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="art_work"
                    value={props.detail && props.detail.art_work}
                    placeholder="Enter Your Art work"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid> */}
            </Grid>

            <Grid
              container
              spacing={1}
              sx={{ paddingTop: "20px", paddingBottom: "10px" }}
            >
              <Grid item xs={12} sm={10} md={12} sx={{ paddingBottom: "10px" }}>
                <label
                  className={styles.input_label}
                  style={{ fontWeight: "300", fontSize: "26px" }}
                >
                  Shipping Address
                </label>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>City</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="state"
                    value={props.detail && props.detail.state}
                    placeholder="Enter Your State"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>State</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="state"
                    value={props.detail && props.detail.state}
                    placeholder="Enter Your State"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>Country</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="country"
                    value={props.detail && props.detail.country}
                    placeholder="Enter Your Country"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>Zipcode</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="zipcode"
                    value={props.detail && props.detail.zipcode}
                    placeholder="Enter Your zipcode"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>Address 1</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="address1"
                    value={props.detail && props.detail.address1}
                    placeholder="Enter Your address1"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>Address 2</label>
                  <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="address2"
                    value={props.detail && props.detail.address2}
                    placeholder="Enter Your address2"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              sx={{ paddingTop: "15px", paddingBottom: "10px" }}
            >
              <Grid item xs={12} sm={10} md={12} sx={{ paddingBottom: "10px" }}>
                <label
                  className={styles.input_label}
                  style={{ fontWeight: "300", fontSize: "26px" }}
                >
                  Professional Details
                </label>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div className={styles.inputs_div_margin}>
                  <label className={styles.input_label}>
                    Please provide us with the name, the year and the place of
                    each experience
                  </label>
                  {/* <TextField
                    className={styles.inputs}
                    variant="outlined"
                    type="text"
                    rows={5}
                    name="professional_details"
                    value={props.detail && props.detail.professional_details}
                    placeholder="Enter Your professional details"
                    onChange={props.handleChange}
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                    fullWidth
                  /> */}
                      <ReactQuill
  name="professional_details"
  value={props.detail && props.detail.professional_details}
    placeholder="Enter Your professional details"
    style={{
      height: '120px',
      textAlign: "left",
      // background:'white',
      marginBottom: "35px",
    }}
  onChange={(value) => props.setApply({ ...props.detail, professional_details: value })}
  theme="snow"
  modules={modules}
  formats={formats}
/>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <label className={styles.input_label}>CV</label>
                <Tooltip title="Download CV">
                  <a
                    href={props.detail?.CV}
                    download
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    {props?.detail?.CV ? (
                      <IconButton>
                        <Download />
                      </IconButton>
                    ) : null}
                  </a>
                </Tooltip>
              </div>
              {/* <br /> */}
              {/* <input type="file" value={props.detail.art_work}/>c */}

              {props.src?.cv === "" ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="CV"
                    style={{ textAlign: "center" }}
                    onClick={props.handleChangeImage}
                  >
                    <input
                      onChange={props.handleChangeImage}
                      name="CV"
                      type="file"
                      id="CV"
                      hidden
                    />
                    <label htmlFor="CV" className={styles.label}>
                      <div className={styles.image_div}>
                        <img
                          src={"/img/uploadimg.png"}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </div>
                      <p>Upload CV</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.img_box}>
                    <div
                      htmlFor="CV"
                      style={{ textAlign: "center" }}
                      onClick={props.handleChangeImage}
                    >
                      <input
                        onChange={props.handleChangeImage}
                        name="CV"
                        type="file"
                        id="CV"
                        hidden
                      />
                      <label htmlFor="CV" className={styles.label}>
                        <div className={styles.image_div}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="100%"
                            height="100%"
                            alt="img"
                          />
                        </div>
                        {props?.src?.cv?.name ? (
                          <p>{props?.src?.cv?.name}</p>
                        ) : (
                          <p>{props?.src?.cv}</p>
                        )}
                      </label>
                    </div>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <label className={styles.input_label}>ID Proof</label>
                <Tooltip title="Download Id Proof">
                  <a
                    href={props.detail?.ID_proof}
                    download
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    rel="noreferrer"
                  >
                    {props?.detail?.ID_proof ? (
                      <IconButton>
                        <Download />
                      </IconButton>
                    ) : null}
                  </a>
                </Tooltip>
              </div>
              {/* <input type="file" value={props.detail.art_work}/>c */}

              {props.src.id === "" ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="ID_proof"
                    style={{ textAlign: "center" }}
                    onClick={props.handleChangeImage}
                  >
                    <input
                      onChange={props.handleChangeImage}
                      name="ID_proof"
                      type="file"
                      id="ID_proof"
                      hidden
                    />
                    <label htmlFor="ID_proof" className={styles.label}>
                      <div className={styles.image_div}>
                        <img
                          src={"/img/uploadimg.png"}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </div>
                      <p>Upload ID Proof</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.img_box}>
                    <div
                      htmlFor="ID_proof"
                      style={{ textAlign: "center" }}
                      onClick={props.handleChangeImage}
                    >
                      <input
                        onChange={props.handleChangeImage}
                        name="ID_proof"
                        type="file"
                        id="ID_proof"
                        hidden
                      />
                      <label htmlFor="ID_proof" className={styles.label}>
                        <div className={styles.image_div}>
                          <img
                            src={"/img/uploadimg.png"}
                            width="100%"
                            height="100%"
                            alt="img"
                          />
                        </div>
                        {props?.src?.id?.name ? (
                          <p>{props?.src?.id?.name}</p>
                        ) : (
                          <p>{props?.src?.id}</p>
                        )}
                      </label>
                    </div>
                  </div>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ my: 2 }}>
              <label className={styles.input_label}>
                Preview ArtWork Sample
              </label>
              <br />
              {/* <input type="file" value={props.detail.art_work}/>c */}

              {props.src.art === "" ? (
                <div className={styles.img_box}>
                  <div
                    htmlFor="art_work"
                    style={{ textAlign: "center" }}
                    onClick={props.handleChangeImage}
                  >
                    <input
                      onChange={props.handleChangeImage}
                      name="art_work"
                      type="file"
                      id="art_work"
                      hidden
                    />
                    <label htmlFor="art_work" className={styles.label}>
                      <div className={styles.image_div}>
                        <img
                          src={"/img/uploadimg.png"}
                          width="100%"
                          height="100%"
                          alt="img"
                        />
                      </div>
                      <p>Upload Artwork</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <label htmlFor="art_work" className={styles.label}>
                    <img
                      src={props.src.art}
                      className={styles.img_box_img}
                      alt="image"
                    />

                    <input
                      onChange={props.handleChangeImage}
                      name="art_work"
                      type="file"
                      id="art_work"
                      hidden
                    />
                  </label>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Portfolio</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="portfolio"
                  value={props.detail && props.detail.portfolio}
                  placeholder="www.example.com"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Instargram</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="instagram"
                  value={
                    props.detail && props.detail.social_links
                      ? props.detail.social_links.instagram
                      : ""
                  }
                  placeholder="www.instagram.com/example"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Facebook</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="facebook"
                  value={
                    props.detail && props.detail.social_links
                      ? props.detail.social_links.facebook
                      : ""
                  }
                    placeholder="www.facebook.com/example"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>X </label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="twitter"
                  value={
                    props.detail && props.detail.social_links
                      ? props.detail.social_links.twitter
                      : ""
                  }
                      placeholder="www.x.com/example"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>LinkedIn</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="linked_in"
                  value={
                    props.detail && props.detail.social_links
                      ? props.detail.social_links.linked_in
                      : ""
                  }
                     placeholder="www.linkedin.com/example"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>
                  Arts Qualification University
                </label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="qualification"
                  value={props.detail && props.detail.qualification}
                  placeholder="Saneca University"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Artist Type</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="artist_type_id"
                  value={props.detail && props.detail.artist_type_id}
                  placeholder="Enter Artist Type"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />

                {/* <FormControl fullWidth sx={{}}>
                  <Select
                    fullWidthclassName={styles.inputs}
                    type="text"
                    defaultValue={artist_type_default[0]._id}
                    value={props.detail && props.detail.artist_type_id}
                    onChange={props.handleChange}
                    placeholder="Select Artist Type"
                    name="artist_type_id"
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      border: "none",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          opacity: 0.5
                        },
                        '&:hover fieldset': {
                            borderColor: 'yellow',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'green',
                        },
                      },
                    }}
                  >
                    <MenuItem disabled sx={{ color: "#636365" }} value="">
                      None
                    </MenuItem>
                    {props.artistType &&
                      props.artistType.map((vl) => (
                        <MenuItem value={vl._id}>{vl.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Subscription Plans</label>
                <TextField
                  className={styles.inputs}
                  type="text"
                  value={props.detail && props.detail.subsciption_plan_id}
                  onChange={props.handleChange}
                  name="subsciption_plan_id"
                  placeholder="Select a plan"
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    border: "none",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            {/* {props.input &&
              props.input.map((val) => (
                // <div key={val.name} className={styles.inputs_div}>
                <>
                  {val.type === "password" ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.password_label}>
                        <label className={styles.input_label}>Password</label>
                        <label className={styles.forgot}>Change?</label>
                      </div>
                      <TextField
                        className={styles.input_pass}
                        variant="outlined"
                        type="password"
                        name="password"
                        placeholder="****"
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 0.5,
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              // opacity: 0.5
                            },
                            // '&:hover fieldset': {
                            //     borderColor: 'yellow',
                            // },
                            // '&.Mui-focused fieldset': {
                            //     borderColor: 'green',
                            // },
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  ) : val.type === "file" ? (
                    <Grid item xs={12} sm={6} md={6}>
                      <label className={styles.input_label}>{val.label}</label>
                      <br />
                      <input type="file" />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.inputs_div_margin}>
                        <label className={styles.input_label}>{val.label}</label>
                        <TextField
                          className={styles.inputs}
                          variant="outlined"
                          type={val.type}
                          name={val.name}
                          value={props.aritstDetail}
                          placeholder={val.placeholder}
                          onChange={props.handleChange}
                          sx={{
                            backgroundColor: "white",
                            height: 50,
                            borderRadius: "6px",
                            mt: 0.5,
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                                // opacity: 0.5
                              },
                              // '&:hover fieldset': {
                              //     borderColor: 'yellow',
                              // },
                              // '&.Mui-focused fieldset': {
                              //     borderColor: 'green',
                              // },
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </Grid>
                  )}
                </>
              ))} */}
          </Grid>
          {props.moreinputs &&
            props.moreinputs.map((val) => (
              <div>
                <div className={styles.new_div_inputs}>
                  <h2>{val.heading}</h2>
                  <hr className={styles.hrs} />
                </div>
                {val.exhibition_inputs.map((val) => (
                  <div key={val.name} className={styles.inputs_div_second}>
                    <div className={styles.edit_div}>
                      <label className={styles.input_edit_label}>
                        {val.label}
                      </label>
                      {/* <label onClick={handlePopup} className={styles.forgot}>
                        {val.edit && val.edit}
                      </label> */}
                    </div>
                    <TextField
                      className={styles.inpu}
                      variant="outlined"
                      type={val.type}
                      name={val.name}
                      onChange={props.handleChange}
                      placeholder={val.placeholder}
                      sx={{
                        backgroundColor: "white",
                        height: 50,
                        borderRadius: "6px",
                        mt: 1,
                        mb: 0.5,
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                            // opacity: 0.5
                          },
                        },
                      }}
                      fullWidth
                    />
                  </div>
                ))}
              </div>
            ))}
          <div>
            <div className={styles.new_div_inputs}>
              <h2>Bank</h2>
              <hr className={styles.hrs} />
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Name</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_name"
                // defaul
                value={props.detail.bank_name}
                placeholder="Enter bank name"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Address</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_address"
                value={props.detail && props.detail.bank_address}
                placeholder="Enter bank address"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Location</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_location"
                value={props.detail && props.detail.bank_location}
                placeholder="Enter bank location"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Holder name</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="account_holder_name"
                value={props.detail && props.detail.account_holder_name}
                placeholder="Enter account holder name"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Type</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="account_type"
                value={props.detail && props.detail.account_type}
                placeholder="Enter account type"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Number</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="number"
                name="account_number"
                value={props.detail && props.detail.account_number}
                placeholder="Enter account number"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Routing/ABA Number</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="routing_number"
                value={props.detail && props.detail.routing_ABA_number}
                placeholder="Enter routing/ABA number"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Swift Code</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="swift_code"
                value={props.detail && props.detail.swift_code}
                placeholder="Enter swift code"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
        </>
      ) : props.rendertype && props.rendertype === "Affiliate" ? (
        <>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>First Name</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="first_name"
                  value={props.detail?.first_name}
                  placeholder="Enter First Name"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Last Name</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="text"
                  name="last_name"
                  value={props.detail?.last_name}
                  placeholder="Enter Last Name"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Mobile Number</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="number"
                  name="phone"
                  value={props.detail && props.detail.phone}
                  placeholder="+91 82937493937"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Email Address</label>
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="email"
                  name="email"
                  value={props.affiliateDetails?.email}
                  placeholder="Enter Your Email"
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.password_label}>
                <label className={styles.input_label}>Password</label>
                <label
                  className={styles.forgot}
                  onClick={() => props.setPasspop(true)}
                >
                  Change Password?
                </label>
              </div>
              <TextField
                className={styles.input_pass}
                variant="outlined"
                // type="password"
                // disabled
                value={localStorage.getItem("password")}
                name="password"
                placeholder="*******"
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <div className={styles.inputs_div_margin}>
                <label className={styles.input_label}>Affiliate Type</label>
                {/* <Autocomplete
                  className={styles.inputs}
                  variant="outlined"
                  name="affiliate_account_type_id"
                  options={
                    props.affiliateType === null
                      ? []
                      : props.affiliateType.map((vl) => vl.name)
                  }
                  type="text"
                  defaultValue={
                    props.affiliateType &&
                    props.affiliateType.filter(
                      (val) =>
                        val._id === props.detail &&
                        props.detail.affiliate_account_type_id
                    )
                  }
                  value={props.detail && props.detail.affiliate_account_type_id}
                  placeholder="Company"
                  onChange={props.handleChange}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="affiliate_account_type_id"
                      {...params}
                      sx={{ border: "none", color: "#636365" }}
                      placeholder="Search"
                    />
                  )}
                /> */}
                <FormControl fullWidth sx={{}}>
                  <Select
                    fullWidthclassName={styles.inputs}
                    type="text"
                    value={
                      props.detail && props.detail.affiliate_account_type_id
                    }
                    onChange={props.handleChange}
                    placeholder="Select Affiliate Type"
                    name="affiliate_account_type_id"
                    sx={{
                      backgroundColor: "white",
                      height: 50,
                      borderRadius: "6px",
                      border: "none",
                      mt: 0.5,
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                          // opacity: 0.5
                        },
                        // '&:hover fieldset': {
                        //     borderColor: 'yellow',
                        // },
                        // '&.Mui-focused fieldset': {
                        //     borderColor: 'green',
                        // },
                      },
                    }}
                  >
                    <MenuItem disabled sx={{ color: "#636365" }} value="">
                      None
                    </MenuItem>
                    {props.affiliateType &&
                      props.affiliateType.map((vl) => (
                        <MenuItem value={vl._id}>{vl.name}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <div>
            <div className={styles.new_div_inputs}>
              <h2>Bank</h2>
              <hr className={styles.hrs} />
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Name</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_name"
                // defaul
                value={props.detail.bank_name}
                placeholder={props.detail.bank_name}
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Address</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_address"
                value={props.detail && props.detail.bank_address}
                placeholder="Bank Address"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Bank Location</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="bank_location"
                value={props.detail && props.detail.bank_location}
                placeholder=" Bank Location"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Holder name</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="account_holder_name"
                value={props.detail && props.detail.account_holder_name}
                placeholder="Holder Name"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Type</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="account_type"
                value={props.detail && props.detail.account_type}
                placeholder="Account Type"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Account Number</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="account_number"
                value={props.detail && props.detail.account_number}
                placeholder="Account Number"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Routing/ABA Number</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="routing_ABA_number"
                value={props.detail && props.detail.routing_ABA_number}
                placeholder="Routing/ABA Number"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Swift Code</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="swift_code"
                value={props.detail && props.detail.swift_code}
                placeholder="Swift Code"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>

          {/* address */}
          <div>
            <div className={styles.new_div_inputs}>
              <h2>Address</h2>
              <hr className={styles.hrs} />
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Address Type</label>

              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="address_type"
                value={props.detail && props.detail.address_type}
                placeholder="Address type"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Home">Home</MenuItem>
                <MenuItem value="Office">Office</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Address Line 1</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="address_line_1"
                value={props.detail && props.detail.address_line_1}
                placeholder="Address"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Address Line 2</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="address_type_2"
                value={props.detail && props.detail.address_type_2}
                placeholder="Address"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>City</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="city"
                value={props.detail && props.detail.city}
                placeholder="City"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>State</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="state"
                value={props.detail && props.detail.state}
                placeholder="State"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Country</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="country"
                value={props.detail && props.detail.country}
                placeholder="country"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>Zipcode</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="zipcode"
                value={props.detail && props.detail.zipcode}
                placeholder="Zipcode"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>

          {/*  */}
          <div>
            <div className={styles.new_div_inputs}>
              <h2>Affiliate Details</h2>
              <hr className={styles.hrs} />
            </div>
          </div>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How do you plan to promote Art Smiley services?
              </label>
              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="promote_art_smiley"
                value={props.detail && props.detail.promote_art_smiley}
                placeholder="Please Select"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Social media">Social media</MenuItem>
                <MenuItem value="Website">Website</MenuItem>
                <MenuItem value="Networking">Networking</MenuItem>
                <MenuItem value="Influencer">Influencer</MenuItem>
                <MenuItem value="Marketing">Marketing</MenuItem>
                <MenuItem value="Mobile App">Mobile App</MenuItem>
                <MenuItem value="Offline">Offline</MenuItem>
                <MenuItem value="Direct Linking">Direct Linking</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                Tell us about your audience interests
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="audience_interest"
                value={props.detail && props.detail.audience_interest}
                placeholder="Please Select"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                Enter your website URL
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="website_url"
                value={props.detail && props.detail.website_url}
                placeholder="Website URL"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                Enter Your Mobile App URL
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="app_url"
                value={props.detail && props.detail.app_url}
                placeholder="Mobile app URL"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                Which of the following topics best describes your website or
                mobile apps?
              </label>
              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="description"
                value={props.detail && props.detail.description}
                placeholder="Saving Account"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Interior Decor">Interior Decor</MenuItem>
                <MenuItem value="Architect">Architect</MenuItem>
                <MenuItem value="Art blogs">Art blogs</MenuItem>
                <MenuItem value="Art Forums">Art Forums</MenuItem>
                <MenuItem value="B2B">B2B</MenuItem>
                <MenuItem value="B2C">B2C</MenuItem>
                <MenuItem value="Art & Craft Stores">
                  Art & Craft Stores
                </MenuItem>
                <MenuItem value="Mobile Search">Mobile Search</MenuItem>
                <MenuItem value="Social Search">Social Search</MenuItem>
                <MenuItem value="Content creators">Content creators</MenuItem>
                <MenuItem value="Media">Media</MenuItem>
                <MenuItem value="Gift">Gift</MenuItem>
                <MenuItem value="Photos and Print Services">
                  Photos and Print Services
                </MenuItem>
                <MenuItem value="Editorial publications">
                  Editorial publications
                </MenuItem>
                <MenuItem value="Publisher">Publisher</MenuItem>
                <MenuItem value="Coupon Code Websites">
                  Coupon Code Websites
                </MenuItem>
                <MenuItem value="Shopping Directory">
                  Shopping Directory
                </MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How do you drive traffic to your website?
              </label>
              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="traffic_drivation"
                value={props.detail && props.detail.traffic_drivation}
                placeholder="Traffic"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Organic">Organic</MenuItem>
                <MenuItem value="Paid ads">Paid ads</MenuItem>
                <MenuItem value="Social media">Social media</MenuItem>
                <MenuItem value="Email marketing">Email marketing</MenuItem>
                <MenuItem value="Whatsapp marketing">
                  Whatsapp marketing
                </MenuItem>
                <MenuItem value="Retargeting">Retargeting</MenuItem>
                <MenuItem value="Lead Generation">Lead Generation</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How do you usually build links?
              </label>
              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="build_links"
                value={props.detail && props.detail.build_links}
                placeholder="Art Management System"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Linking via Landing Pages">
                  Linking via Landing Pages
                </MenuItem>
                <MenuItem value="Direct Linking">Direct Linking</MenuItem>
                <MenuItem value="Guest Posting">Guest Posting</MenuItem>
                <MenuItem value="Co marketing">Co marketing</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How do you utilize your websites and apps to genrate income?
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="generate_income"
                value={props.detail && props.detail.generate_income}
                placeholder="Online Selling"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How many total unique visitors do your websites and apps get per
                month?
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="unique_visitor_count"
                value={props.detail && props.detail.unique_visitor_count}
                placeholder="From 5000 to 5,000"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                What is your primary reason for joining the Artsmiley
                Associates?
              </label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="primary_reason_to_join"
                value={props.detail && props.detail.primary_reason_to_join}
                placeholder="other"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                How did you hear about us?
              </label>
              <Select
                className={styles.inputs}
                variant="outlined"
                type="text"
                name="hear_about_us"
                value={props.detail && props.detail.hear_about_us}
                placeholder="Ad"
                onChange={props.handleChange}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              >
                <MenuItem value="Social media">Social media</MenuItem>
                <MenuItem value="Google search">Google search</MenuItem>
                <MenuItem value="Friend">Friend</MenuItem>
                <MenuItem
                  value="Existing
                        Customer"
                >
                  Existing Customer
                </MenuItem>
                <MenuItem value="Existing Artist">Existing Artist</MenuItem>
                <MenuItem value="Existing Curator">Existing Curator</MenuItem>
                <MenuItem value="TV">TV</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <div className={styles.inputs_div_margin}>
              <label className={styles.input_label}>
                What are your websites or mobile apps about?
              </label>
              <TextareaAutosize
                maxRows={8}
                minRows={8}
                fullWidth
                sx={{
                  mt: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&&:after": {
                    border: "none",
                  },
                }}
                className={styles.textarea}
                name="website_about"
                value={props.detail && props.detail.website_about}
                placeholder="Type Here....."
                onChange={props.handleChange}
              />
            </div>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {props.input &&
              props.input.map((val) => (
                // <div key={val.name} className={styles.inputs_div}>
                <>
                  {val.type === "password" ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.password_label}>
                        <label className={styles.input_label}>Password</label>
                        <label
                          className={styles.forgot}
                          onClick={() => props.setPasspop(true)}
                        >
                          Change?
                        </label>
                      </div>
                      <TextField
                        className={styles.input_pass}
                        variant="outlined"
                        // type="password"
                        value={localStorage.getItem("password")}
                        name="password"
                        placeholder="************"
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 0.5,
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              // opacity: 0.5
                            },
                            // '&:hover fieldset': {
                            //     borderColor: 'yellow',
                            // },
                            // '&.Mui-focused fieldset': {
                            //     borderColor: 'green',
                            // },
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  ) : val.type === "file" ? (
                    <Grid item xs={12} sm={6} md={6}>
                      <label className={styles.input_label}>{val.label}</label>
                      <br />
                      <input type="file" />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.inputs_div_margin}>
                        <label className={styles.input_label}>
                          {val.label}
                        </label>
                        <TextField
                          className={styles.inputs}
                          variant="outlined"
                          type={val.type}
                          name={val.name}
                          placeholder={val.placeholder}
                          // value={props.affiliateDetails.+val.name}
                          onChange={props.handleChange}
                          sx={{
                            backgroundColor: "white",
                            height: 50,
                            borderRadius: "6px",
                            mt: 0.5,
                            fontFamily: "DM Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none",
                                // opacity: 0.5
                              },
                              // '&:hover fieldset': {
                              //     borderColor: 'yellow',
                              // },
                              // '&.Mui-focused fieldset': {
                              //     borderColor: 'green',
                              // },
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </Grid>
                  )}
                </>
              ))}
          </Grid>
          {props.moreinputs &&
            props.moreinputs.map((val) => (
              <div>
                <div className={styles.new_div_inputs}>
                  <h2>{val.heading}</h2>
                  <hr className={styles.hrs} />
                </div>
                {val.exhibition_inputs.map((val) => (
                  <div key={val.name} className={styles.inputs_div_second}>
                    <div className={styles.edit_div}>
                      <label className={styles.input_edit_label}>
                        {val.label}
                      </label>
                      <label onClick={handlePopup} className={styles.forgot}>
                        {val.edit && val.edit}
                      </label>
                    </div>
                    {val.type === "textarea" ? (
                      <TextareaAutosize
                        maxRows={8}
                        minRows={8}
                        fullWidth
                        sx={{
                          mt: 1,
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&&:after": {
                            border: "none",
                          },
                        }}
                        className={styles.textarea}
                        placeholder={val.placeholder}
                      />
                    ) : (
                      <TextField
                        className={styles.inpu}
                        variant="outlined"
                        type={val.type}
                        name={val.name}
                        placeholder={val.placeholder}
                        sx={{
                          backgroundColor: "white",
                          height: 50,
                          borderRadius: "6px",
                          mt: 1,
                          mb: 0.5,
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none",
                              // opacity: 0.5
                            },
                          },
                        }}
                        fullWidth
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          <ToastContainer />
        </>
      )}
    </>
  )
}
