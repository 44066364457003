import React, { useEffect, useState } from "react";
import styles from './Resendmail.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../State/api/Api";
import { AlertsSuccess, Successnotify } from "../../../../common/alert/Alert";
import homeImage from "../../../../constant";
import { Button } from "@mui/material";

export default function ResendMail() {
    const [signin, setSignin] = useState(false);
    const [user, setUser] = useState({ password: "", email: "", role: "buyer", showPassword: false });
    const [email_verify, setEmail_verify] = useState(null);
    const [is_verify, setIs_verify] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = useLocation();
    console.log(searchParams.search.length)


    // const a = searchParams.search && searchParams.search.split('?')[1].split('&')[0].split('&').map(param => param.split('='))
    // const b = searchParams.search && searchParams.search.split('?')[1].split('&')[2].split('&').map(param => param.split('='))
    // const c = searchParams.search && searchParams.search.split('?')[1].split('&')[1].split('&').map(param => param.split('='))
    // useEffect(() => {
    //     if (searchParams.search.length > 0) {
    //         setEmail_verify({ email: a[0][1], key: c[0][1], role: b[0][1] });
    //         setUser({ email: a[0][1], key: c[0][1], role: b[0][1] });
    //     }
    // }, [])

    // const handleVeryfied = async function () {
    //     // dispatch(setLoade(true));
    //     console.log("verifyed called", user)
    //     try {
    //         const result = await api.applicationApi(
    //             `api/user/userVerification`,
    //             "POST", email_verify
    //         );
    //         if (result && result.status === 200) {
    //             Successnotify(result.data.message)
    //             setIs_verify(true)
    //         } else if (result.status === 400) {
    //             setIs_verify(true)
    //             Errornotify(result.data.message)
    //         }
    //         else {
    //             setIs_verify(false)
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };
    const mail = localStorage.getItem("user_email");
    const usertype = localStorage.getItem("usertype");

    const handleResendMail = async function () {
        // dispatch(setLoade(true));
        try {
            const result = await api.applicationApi(
                `api/user/sendUserVerificationMail`,
                "POST", { email: mail, role: usertype }
            );
            if (result && result.status === 200) {
                Successnotify("Mail sent Successfully")
            } else {
            }
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <AlertsSuccess />
            <div className={styles.login_main}>
                <div className={styles.left_login_main}>
                    <img
                        src={ "/img/Login Image.png"}
                        height="60%"
                        width="50%"
                        className={styles.img_login} alt='img'
                    />
                </div>
                <div className={styles.right_main_l}>
                    <h2>Please verify your email</h2>
                    <p>You’re almost there! We sent an email to <br /><b>{mail}</b></p>
                    <p>Just click on the link in that email to complete your sign up.<br />  If you don’t see it,you may need to <b>check your spam</b> folder.</p>
                    <p> Still can’t find the mail?</p>
                    <Button variant='contained' onClick={handleResendMail}>
                        Resend mail
                    </Button>
                </div>
            </div>
        </>
    );
}
