import { createSlice } from "@reduxjs/toolkit";

// 
const a = { name: "",sale: {qty: 90,price: 4500}, rent: {time: "3 months",price: 4000}, print: {edition: "",qty: 120,size: [{width: 20,height: 30}, {width: 50,height: 60}],price: 6000}, copyright: "Yes", is_showcased: "No", is_hanged: "No", is_signed: "Yes", image: "", images: "", year: 2003, width: 30, height: 40, depth: 3, keywords: "", description: "", category_id: "", subject_id: "", material_id:"", style_id: ""}
const initialState = {
 category_id:'',
 sale: {qty: 1,price: 1},

 sub_category_id: "",
 name: "",
 material: "",
 description: "",
 dimensions: "",
 sku_id: "",
 buy_price: 1500,
 rent_price: 300,
 print_price: 500,
 image: "",
 is_best_seller: true,
 is_most_popular: false,
 item_type_id: "",
 star_rating: 55,
 copyright: "no",
 Subject: "",
 year: 2020,
 style: "",
 keyword: "",
 rent_time: "",
 delivery_charges: 200,
 qty: 10,
 tags: "",
 wall_mounted: true,
 width: 200,
 height: 300,
 country_id: "",
 color_id: "",
 orientation_id: "",
 style_id: "",
};

export const uploadartSlice = createSlice({
 name: "uploadart",
 initialState,
 reducers: {
  addArtData: (state, action) => {
   console.log(action.payload);
   state.data = { ...state.data, [action.payload.name]: action.payload.value };
  },
  addCategory: (state, action) => {
   console.log(action.payload);
   state.data.category_id = { ...state.data.category_id, [action.payload.name]: state.data.category_id.push(action.payload.value) };
  },
 },
});

// Action creators are generated for each case reducer function
export const { addArtData } = uploadartSlice.actions;
export const { addCategory } = uploadartSlice.actions;

export default uploadartSlice.reducer;
