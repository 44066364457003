import React, { useEffect } from 'react'
import styles from "./Giftcard.module.css"
import Tableheader from '../../../List/Table/Tableheader/Tableheader';
import Moreoption from '../../../List/Table/Moreoption/Moreoption';
import { useState } from 'react';
import homeImage from '../../../../../constant';
import serverimage from "../../../../../serverConstant";
import Sixcolumnimg from '../Tables/Sixcolumnimg';
import api from '../../../../../State/api/Api';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Successnotify } from '../../../../../common/alert/Alert';
import { Paper, TableContainer } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
const moreOptions = [
  {
    id: "sent",
    label: "Sent",
    value: "sent",
  },
  {
    id: "received",
    label: "Received",
    value: "received",
  },  
];
const approvedhead = [
  {
    id: "name",
    label: "Image",
  },
  {
    id: "mobile",
    label: "Name",
  },
  {
    id: "date",
    label: "Gift Card Coupon",
  },
  {
    id: "type",
    label: "Price",
  },
  {
    id: "action",
    label: "Status",
  },
  {
    id: "email",
    label: "Date",
  },
];
const datas = [
  {
    id: 1,
    src: "/img/image 1",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 2,
    src: "/img/image 2",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 3,
    src: "/img/image 3",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
  {
    id: 4,
    src: "/img/image 4",
    amount: "$100",
    date: "12-20-2020",
    to: "Emily Cooper",
    message: "Here's your birthday present",
    email: "abcd@gmail.com"
  },
];
function Giftcard() {
  const [activetab, setAcitvetab] = useState("sent");
  const [giftCard,setGiftCard] = useState([])
  const handleMorecategories = (e) => {
    setAcitvetab(e);
  };

 async  function fetchGiftCardOffers() {
  
      try {
        const apiResponse = await api.applicationApi(
          "api/user/getPurchaseGiftCards",
          "GET",
          {}
        );
        if (apiResponse.status === 200 || apiResponse.status === 201) {
          console.log(apiResponse.data.data,'gift card')
          setGiftCard(apiResponse.data.data);
          // dispatch(setUserAllOffer(apiResponse.data.response));
        
        } 
      } catch (err) {
        console.log(err);
      
    };
  }

  useEffect(() => {
    fetchGiftCardOffers()
  },[])
  return (
    <div className={styles.mains}>
      <div className={styles.component}>
        <div className={styles.header}>
          <div className={styles.rightheader}>
            <img src={`${homeImage}/image 135 (Traced).png`} width='20px' height='20px' /><span><b> $0</b></span>            
            <p><b>Available Balance</b></p> 
          </div>
        <Moreoption          
            moreOptions={moreOptions}   
            handleMorecategories={handleMorecategories}  
          />
        </div>
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F6F6", color: "black" }}>
        <Table sx={{ width: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {approvedhead.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : ( */}
          <TableBody>
            {giftCard && giftCard.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}> No Data Found</Box>
                </TableCell>
              </TableRow>
            ) : (
             giftCard?.map((row, i) => (
                <TableRow value={row.artist_name} style={{ color: "#636365", cursor: "pointer" }} key={i}>
                  {/* <Link to={`/artist/${row.id}`}> */}
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left"
                  >
                    {/* <img src={ `/img/${row.src}`} height='42px' width='60px' /> */}
                  
                      <img src={` ${row.giftCard?.gift_card_image}`} height="42px" width="60px" />
                    
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.giftCard?.name}
                  </TableCell>
                  <TableCell
                  onClick={() => navigator.clipboard.writeText(row.CardId).then(() => {
      Successnotify('Offer code copied to clipboard!');
      // setTimeout(() => setCopyMessage(''), 2000); // Clear message after 2 seconds
    })}
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                      cursor: 'pointer', // Change cursor to indicate clickability
        // boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.3)', // Inset box shadow
     
        transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for hover effect
        '&:hover': {
          boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)', // Darker shadow on hover
        },
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.CardId}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    ${row.giftCard?.min_price}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.isActive}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="left">
                    {row.purchaseDate}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>

        </Table>
      </TableContainer>
      <ToastContainer />
        {/* {activetab === "sent" ? (
          <Sixcolumnimg
            activetab={activetab}
            head={approvedhead}   
            data={giftCard}
          />
        ) : <></>
        } */}
      
      </div>
    </div>
  )
}

export default Giftcard