import { Grid, TextField } from "@mui/material"
import React, { useState } from "react"
import styles from "./Card.module.css"
import { Button, Select, MenuItem } from "@mui/material"
import { useDispatch } from "react-redux"
import {
  handleAddSubMenu,
  handleAddHeading,
} from "../../../../../State/api/superadminApi"
import { validateAdminSubMenu } from "../../../../../validation/validationAdminSubmenu"

export default function Card(props) {
  console.log(props?.headingByMenu?.headings, "props.headingByMenu--")
  const dispatch = useDispatch()
  const [errors, setErrors] = useState({})
  const [newdata, setNewdata] = useState({ name: "", link: "" })
  const [heading, setHeading] = useState("")
  const [error, setError] = useState(false)
  const handleNewChange = (e) => {
    const { name, value } = e.target
    setNewdata({ ...newdata, [name]: value })
  }

  const handleHeading = (e) => {
    const head = e.target.value
    setHeading(head)
    setError(false)
  }

  const handleSubmit = () => {
    const { submenuErrors, isValid } = validateAdminSubMenu(newdata)
    if (!isValid) {
      setErrors(submenuErrors)
    } else {
      setErrors({})
      dispatch(handleAddSubMenu(props.menu_id, newdata, props.fetchData))

      setNewdata({ name: "", link: "" })
    }
  }
  const handleSubmitHead = () => {
    if (heading.trim() === "") {
      setError(true)
      return
    }
    dispatch(handleAddHeading(props.menu_id, heading, props.fetchHeading))
    setHeading("")
  }
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.card}>
          <div className={styles.heading_main}>
            <div className={styles.subheadig}>Menu</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
            <div className={styles.subheading_head}>Main menu</div>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={6} md={6}>
                <TextField
                  value={
                    props.menuesData &&
                    props.menuesData.filter((val) => val._id === props.menu_id)
                      .length !== 0
                      ? props.menuesData.filter(
                          (val) => val._id === props.menu_id
                        )[0].name
                      : ""
                  }
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter main menu"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={14} sm={6} md={6}>
                <TextField
                  value={
                    props.menuesData &&
                    props.menuesData.filter((val) => val._id === props.menu_id)
                      .length !== 0
                      ? props.menuesData.filter(
                          (val) => val._id === props.menu_id
                        )[0].link
                      : ""
                  }
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter Redirect Link"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={14} sm={2} md={2}></Grid>
            </Grid>
          </div>
          {/* heading */}
          <div className={styles.heading_main}>
            <div className={styles.subheading}>Heading</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={6} md={6}>
                <TextField
                  onChange={handleHeading}
                  value={heading}
                  name="name"
                  error={error}
                  helperText={error ? "Heading is required." : ""}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter heading"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={6} md={6}>
                <div className={styles.input_detail_div}>
                  <div className={styles.img_text_add}>
                    <img
                      alt=""
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                    />
                  </div>
                  <Button onClick={handleSubmitHead}>Add Heading</Button>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* heading */}
          <div className={styles.heading_main}>
            <div className={styles.subheading}>Sub Menu</div>
            <div className={styles.hrline}></div>
          </div>
          <div className={styles.inputs_div}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={7} md={7}>
                <TextField
                  onChange={handleNewChange}
                  value={newdata.name}
                  name="name"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter submenu"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={14} sm={7} md={7}>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={newdata.heading_id}
                  label="Select heading"
                  fullWidth
                  sx={{
                    background: "#f6f6f6",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  placeholder="select type"
                  onChange={handleNewChange}
                  name="heading_id"
                  // name="blog_type"
                >
                  {/* {props.headingByMenu?.headings &&
                    props.headingByMenu?.headings?.map((val) => (
                      <MenuItem value={val?._id}>{val?.name}</MenuItem>
                    ))} */}
                  <MenuItem value="" disabled>
                    Select Heading
                  </MenuItem>
                  {props.headingByMenu?.headings &&
                    props.headingByMenu?.headings?.map((val) => (
                      <MenuItem key={val?._id} value={val?._id}>
                        {val?.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={10} md={10}></Grid>
              <Grid item xs={14} sm={10} md={10}>
                <TextField
                  onChange={handleNewChange}
                  value={newdata.link}
                  name="link"
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    "& .MuiInputBase-root": {
                      height: 50,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Enter Redirect Link"
                  className={styles.input}
                  fullWidth
                  variant="outlined"
                />
                {errors?.link && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.link}
                  </span>
                )}
              </Grid>
              {/* <Grid item xs={14} sm={2} md={2}>
                <div className={styles.toggle_btn}>
                  <span>Inactive</span>
                  <label className={styles.switch}>
                    <input type="checkbox" />
                    <span className={`${styles.slider} ${styles.round}`}></span>
                  </label>
                </div>
              </Grid> */}
            </Grid>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              <Grid item xs={14} sm={6} md={6}>
                <div className={styles.input_detail_div}>
                  <div className={styles.img_text_add}>
                    <img
                      alt=""
                      src={"/img/addicon.png"}
                      height="11px"
                      width="11px"
                    />
                  </div>
                  <Button onClick={handleSubmit}>Add sub menu</Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}
