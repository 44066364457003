import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const subadminSlice = createSlice({
    name: 'subadmindata',
    initialState,
    reducers: {
        setSubadmin: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setSubadmin } = subadminSlice.actions;

export default subadminSlice.reducer;