import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./FollowedArtist.module.css";
import Fivecolumntable from "../Tables/Fivecolumntable";
import Tableheader from "../../../List/Table/Tableheader/Tableheader";
import Fourcolumnimg from "../Tables/Fourcolumnimg";
import api from "../../../../../State/api/Api";
import TableHeaderFollowedArtist from "../../../List/Table/Tableheader/TableHeaderFollowedArtist";

const head = [
  {
    id: 1,
    label: "Image",
  },
  {
    id: 2,
    label: "Artist Name",
  },

  {
    id: 3,
    label: "Followers",
  },
  {
    id: 4,
    label: "Action",
  },
];
const datas = [
  {
    id: 1,
    src: "/img/image 1",
    name: "Month end sale",
    follower: "12.5k ",
    text: "UNFOLLOW",
  },
  {
    id: 2,
    src: "/img/image 2",
    name: "Month end sale",
    follower: "12.5k ",
    text: "UNFOLLOW",
  },
  {
    id: 3,
    src: "/img/image 3",
    name: "Month end sale",
    follower: "12.5k",
    text: "UNFOLLOW",
  },
  {
    id: 4,
    src: "/img/image 4",
    name: "Month end sale",
    follower: "12.5k ",
    text: "UNFOLLOW",
  },
];

export default function FollowedArtist() {
  const [followedArtist, setFollowedArtist] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortedValue, setSortedValue] = useState(10);
  const filteredFollowedArtist = followedArtist
  ?.filter((data) => 
    data?.first_name.toLowerCase().includes(searchInput.toLowerCase()) || 
    data?.last_name.toLowerCase().includes(searchInput.toLowerCase())
  )
  .slice(0, sortedValue);

  const fetchFollowedArtist = async () => {
    try {
      const { data } = await api.applicationApi(
        "api/buyer/fetchFollowedUser",
        "GET"
      );
      setFollowedArtist(data.data.followings);
    } catch (error) {}
  };

  useEffect(() => {
    fetchFollowedArtist();
  }, []);
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <TableHeaderFollowedArtist
          setSearchInput={setSearchInput}
          searchInput={searchInput}
          setSortedValue={setSortedValue}
          sortedValue={sortedValue}
          type="followedartist"
        />
        <Fourcolumnimg
          head={head}
          data={filteredFollowedArtist}
          setFollowedArtist={setFollowedArtist}
        />
      </div>
    </div>
  );
}
