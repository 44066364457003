import React from 'react';

const Pagenotfound = () => {
    return (
        <div style={{ display: "flex", alignItems: 'center', justifyContent: "center", height: "25vh", width: "100vw", overflow:"hidden" }}>
            Page Not Found
        </div>
    );
}

export default Pagenotfound;
