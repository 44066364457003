import {
  Autocomplete,
  Box,
  Button,
  Fade,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import styles from "./Enterdetails.module.css"
import Backdrop from "@mui/material/Backdrop"
import ReactQuill from "react-quill"
import homeImage from "../../../../../constant"
import {
  deletePrintProductPrice,
  handleAddFAQcat,
} from "../../../../../State/api/superadminApi"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { AlertsSuccess, Errornotify } from "../../../../../common/alert/Alert"
import { handlechangePassword } from "../../../../../State/api/superadminApi"
import { inviteArtist } from "../../../../../State/api/curatorApi"
import { validateAdminPolicy } from "../../../../../validation/validationAdminPolicy"
import { validateAdminFaq } from "../../../../../validation/validationAdminFaq"
import { validateAdminPassword } from "../../../../../validation/validationAdminPasswodchng"

export default function Enterdetails(props) {
  console.log(props, "props--artistcurat")
  const dispatch = useDispatch()
  const [policyErrors, setPolicyErrors] = useState({})
  const [faqErrors, setFaqErrors] = useState({})
  const [passwordErrors, setPasswordErrors] = useState({})
  const email_regex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+[A-Za-z]+$/
  const [invite_mail, setInvite_mail] = useState("")
  const handleClose = () => props.setOpen(false)
  const [pass, setPass] = useState({
    oldPassword: "",
    newPassword: "",
    confirm_new_password: "",
  })
  const handleClosereject = () =>
    props.setOpens({ open: false, rejectReason: "", rejectid: "" })

  const { id } = useParams()
  // const handleClosereject = () =>
  //   props.setOpens({ ...props.opens, open: false })
  const handlemultiClose = () =>
    props.setOpens({ open: false, rejectReason: "", rejectid: "" })
  const [acc_type, setAcc_type] = useState("0")
  const [faq, setFaq] = useState(
    props.editdata
      ? props.editdata
      : {
          question: "",
          answer: "",
          user_type_id: id,
          faq_type: "",
        }
  )
  console.log(faq, "faq--111")
  const [policy, setPolicy] = useState(
    props.editdata ? props.editdata : { title: "", description: "" }
  )
  const [product_id, setProduct_id] = useState("")
  const [faqCat, setFaqCat] = useState("")
  const [print, setPrint] = useState(
    props.editdata ? props.editdata : { name: "" }
  )
  const [printPrice, setPrintPrice] = useState(
    props.editdata ? props.editdata : { print_Price: "" }
  )

  const handleChangeFaq = (e) => {
    const { name, value } = e.target
    setFaq({ ...faq, [name]: value })
  }
  const onChangePass = (e) => {
    const { name, value } = e.target
    props.setPass({ ...props.pass, [name]: value })
  }

  //new pass curator
  const onChangePasscur = (e) => {
    const { name, value } = e.target
    setPass({ ...pass, [name]: value })
  }
  const handleChangePolicy = (e) => {
    const { name, value } = e.target
    setPolicy({ ...policy, [name]: value })
  }
  const handleChangePrint = (e) => {
    const { name, value } = e.target
    let validValue = value
    if (name === "name") {
      // Allow only alphabets and spaces for the name field
      validValue = value.replace(/[^a-zA-Z\s]/g, "")
    }
    setPrint({ ...print, [name]: validValue })
  }

  const handleChangePrintPrice = (e) => {
    const { name, value } = e.target
    let validValue = value
    setPrintPrice({ ...printPrice, [name]: validValue })
  }

  const handleChangeFAQcat = (e) => {
    setFaqCat(e.target.value)
  }

  const handleChange = (e) => {
    const val = e.target.value
    setAcc_type(val)
  }
  const handleChangeProduct = (e) => {
    setProduct_id(e.target.value)
  }

  const handlePolicyAdd = () => {
    const { policyErrors, isValid } = validateAdminPolicy(policy)

    if (!isValid) {
      setPolicyErrors(policyErrors)
    } else {
      setPolicyErrors({})
      props.editdata ? props.handleAdd(policy) : props.handleAdd(policy)
    }
  }
  const handleFaqAdd = () => {
    const { faqErrors, isValid } = validateAdminFaq(faq)

    if (!isValid) {
      setFaqErrors(faqErrors)
    } else {
      setFaqErrors({})
      props.editdata
        ? props.handleEditfaq(props.editdata._id, faq)
        : props.handleAddfaq(faq)
      // props.editdata ? props.handleAdd(policy) : props.handleAdd(policy)
    }
  }

  const handleSubmitChangePassword = () => {
    const { passErrors, isValid } = validateAdminPassword(pass)
    if (!isValid) {
      setPasswordErrors(passErrors)
    } else {
      
      dispatch(handlechangePassword(pass))
      setPasswordErrors({})
   
    }
  }
  return (
    <div>
      {props.poptype === "account" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Account Type
              </p>
              {/* <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                sx={{ my: 1.5, display: 'flex', justifyContent: 'space-between' }}
                                alignItems="center" item columnSpacing={{ xs: 1, sm: 2, md: 3 }} colgap={1.5} columns={{ xs: 12, sm: 12, md: 22 }}>
                                {input.map((val) => (
                                    <Grid item xs={12} sm={6} md={11} key={val.id} className={styles.inputs_gird}>
                                        <label>{val.label}</label><br />
                                        <TextField sx={{ mt: 0.5, backgroundColor: '#f6f6f6', "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px' }} size='small' fullWidth placeholder={`Enter ${val.label}`} type='text' />
                                    </Grid>
                                ))}
                            </Grid> */}

              {/* <div className={styles.input_title}> */}
              <label className={styles.input_label}>Account Type</label>
              {/* <FormControl fullWidth> */}
              {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={acc_type}
                label="Account Type"
                placeholder="Account Type"
                fullWidth
                // className={styles.search_select}
                size="small"
                sx={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: "6px",
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiInputBase-root": {
                    // height: 40
                  },
                }}
                onChange={handleChange}
              >
                <MenuItem value="0">Account Type</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              {/* </FormControl> */}
              {/* <Autocomplete
        // disablePortal
        id="combo-box-demo"
        size="small"
        options={top100Films}
        sx={{ mt: 0.5, backgroundColor: "#F6F6F6", border: "none", borderRadius: "6px" }}
        className={styles.search_select}
        fullwidth
        renderInput={(params) => (
         <TextField
          sx={{
           backgroundColor: "#f6f6f6",
           borderRadius: "6px",
           color: "#636365",
           "& .MuiOutlinedInput-notchedOutline": { border: "none" },
           "& .MuiInputBase-root": {
            // height: 40
           },
          }}
          {...params}
          fullwidth
          placeholder="Select Type"
         />
        )}
       /> */}
              {/* </div> */}
              <div>
                <label className={styles.input_label}>Email</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder="Enter Email"
                  type="text"
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "10px" }}
                  className={styles.action_btn}
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "newpassword" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Set password
              </p>
              <label className={styles.input_label}>Current Password *</label>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="password"
                name="oldPassword"
                value={pass.oldPassword}
                placeholder="Enter current password"
                onChange={onChangePasscur}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  border: "1px solid #D0D0D0",
                  mt: 0.5,
                  mb: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
              {passwordErrors?.oldPassword && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {passwordErrors?.oldPassword}
                </span>
              )}
              <br />
              {/* </div> */}
              <div>
                <label className={styles.input_label}>New Password *</label>
                <br />
                <TextField
                  className={styles.inputs}
                  variant="outlined"
                  type="password"
                  name="newPassword"
                  value={pass.newPassword}
                  placeholder="Enter new password"
                  onChange={onChangePasscur}
                  sx={{
                    backgroundColor: "white",
                    height: 50,
                    borderRadius: "6px",
                    mt: 0.5,
                    mb: 0.5,
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    border: "1px solid #D0D0D0",
                    fontWeight: "400",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                        // opacity: 0.5
                      },
                      // '&:hover fieldset': {
                      //     borderColor: 'yellow',
                      // },
                      // '&.Mui-focused fieldset': {
                      //     borderColor: 'green',
                      // },
                    },
                  }}
                  fullWidth
                />
                {passwordErrors?.newPassword && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {passwordErrors?.newPassword}
                  </span>
                )}
                <br />
              </div>
              <div>
                <label className={styles.input_label}>
                  {" "}
                  Confirm New Password *
                </label>
                <br />
              </div>
              <TextField
                className={styles.inputs}
                variant="outlined"
                type="password"
                name="confirm_new_password"
                value={pass.confirm_new_password}
                placeholder="Enter confirm new password"
                onChange={onChangePasscur}
                sx={{
                  backgroundColor: "white",
                  height: 50,
                  borderRadius: "6px",
                  mt: 0.5,
                  mb: 0.5,
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  border: "1px solid #D0D0D0",
                  fontWeight: "400",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                      // opacity: 0.5
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
              {passwordErrors?.confirm_new_password && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {passwordErrors?.confirm_new_password}
                </span>
              )}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={handleSubmitChangePassword}
                  sx={{
                    width: "130px",
                    height: "54px",
                    borderRadius: "10px",
                    mt: 2,
                  }}
                >
                  Update
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "faquestion" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.editdata ? "Edit" : "Add"} FAQ Question
              </p>
              <div>
                <label className={styles.input_label}>Question *</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter Question"
                  type="text"
                  name="question"
                  value={faq.question}
                  onChange={handleChangeFaq}
                />
                {faqErrors?.question && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {faqErrors?.question}
                  </span>
                )}
              </div>
              <div className={styles.textarea_div}>
                <label className={styles.input_label}>FAQ Type *</label>
                <br />
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={faq.faq_type}
                  label="Faq type"
                  fullWidth
                  sx={{
                    background: "#f6f6f6",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  }}
                  placeholder="select type"
                  onChange={handleChangeFaq}
                  name="faq_type"
                  // name="blog_type"
                >
                  <MenuItem value="For FAQ">For FAQ</MenuItem>
                  <MenuItem value="For Applicaiton">For Applicaiton</MenuItem>
                </Select>
                {faqErrors?.faq_type && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {faqErrors?.faq_type}
                  </span>
                )}
              </div>

              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Answer *</label>
                <br />
                {/* <TextareaAutosize
                  sx={{
                    mt: 3,
                    border: "1px solid #665D6F",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                  }}
                  placeholder="Enter description"
                  maxRows={5}
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                  name="answer"
                  value={faq.answer}
                  onChange={handleChangeFaq}
                /> */}

                <ReactQuill
                  theme="snow"
                  placeholder={`Enter Answer`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={faq.answer}
                  onChange={(content) =>
                    handleChangeFaq({
                      target: { name: "answer", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
              </div>
              {faqErrors?.answer && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {faqErrors?.answer}
                </span>
              )}
              {/* <TextareaAutosize
                                    maxRows={6}
                                    fullWidth
                                    sx={{ width: '99%' }}
                                    aria-label="maximum height"
                                    placeholder="Maximum 4 rows"
                                    defaultValue="Lorem ipsum dolor sit"
                                /> */}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    width: "100px",
                    height: "54px",
                    marginTop: "60px",
                  }}
                  className={styles.action_btnFAQ}
                  onClick={handleFaqAdd}
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "policy" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.editdata ? "Edit" : "Add"} Policy
              </p>
              <div>
                <label className={styles.input_label}>Title *</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter Title"
                  type="text"
                  name="title"
                  value={policy.title}
                  onChange={handleChangePolicy}
                />
                {policyErrors?.title && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {policyErrors?.title}
                  </span>
                )}
              </div>

              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Description *</label>
                <br />

                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={policy.description}
                  onChange={(content) =>
                    handleChangePolicy({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
                {policyErrors?.description && (
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    {policyErrors?.description}
                  </span>
                )}
              </div>
              {/* <TextareaAutosize
                                    maxRows={6}
                                    fullWidth
                                    sx={{ width: '99%' }}
                                    aria-label="maximum height"
                                    placeholder="Maximum 4 rows"
                                    defaultValue="Lorem ipsum dolor sit"
                                /> */}
              <div className={styles.actionPolicy}>
                {props.editdata ? (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      width: "156px",
                      height: "54px",
                      marginTop: "25px",
                    }}
                    className={styles.action_btn}
                    onClick={handlePolicyAdd}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      width: "156px",
                      height: "54px",
                      marginTop: "25px",
                    }}
                    className={styles.action_btn}
                    onClick={handlePolicyAdd}
                  >
                    Add
                  </Button>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "curator_invite_artist" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_decline}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Invite Artist
              </p>
              <div>
                <label className={styles.input_label}>Email</label>
                <br />
                <TextField
                  sx={{
                    mt: 1,
                    // "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    // backgroundColor: "#f6f6f6",
                    backgroundColor: "#f6f6f6",
                    "& fieldset": {
                      border:
                        invite_mail.length === 0
                          ? "none"
                          : invite_mail.match(
                              /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+[A-Za-z]+$/
                            )
                          ? "none"
                          : "",
                    },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  value={invite_mail}
                  placeholder="Enter Email"
                  type="email"
                  error={!invite_mail.match(email_regex)}
                  onChange={(e) => setInvite_mail(e.target.value)}
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "10px", width: "156px", height: "54px" }}
                  className={styles.action_btn}
                  // disabled={!invite_mail.match(email_regex)}
                  onClick={() => props.handleInviteArtist(invite_mail)}
                >
                  Invite
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "curator_art_cancel" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.opens.open}
          onClose={handleClosereject}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.opens.open}>
            <Box className={styles.modal_decline}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handlemultiClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.text}
              </p>
              <div className={styles.textarea_div}>
                <TextareaAutosize
                  sx={{
                    mt: 3,
                    border: "1px solid #665D6F",
                    color: "black",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                      pl: 1,
                    },
                    pl: 3,
                  }}
                  value={props.opens.rejectReason}
                  onChange={(e) =>
                    props.setOpens({
                      ...props.opens,
                      rejectReason: e.target.value,
                    })
                  }
                  placeholder="Write a Remark..."
                  maxRows={5}
                  className={styles.textarea}
                  fullWidth
                  minRows={5}
                />
              </div>
              {/* <TextareaAutosize
                                    maxRows={6}
                                    fullWidth
                                    sx={{ width: '99%' }}
                                    aria-label="maximum height"
                                    placeholder="Maximum 4 rows"
                                    defaultValue="Lorem ipsum dolor sit"
                                /> */}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "10px", width: "156px", height: "54px" }}
                  className={styles.action_btn}
                  onClick={() => props.handleAcceptRejectArts("Rejected")}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "affliate_copy_link" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Generate Link
              </p>
              <div>
                <label className={styles.input_label}>Text link</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": { border: "noe" },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="https://artsmiley.to/2ghfdkH"
                  type="text"
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{
                    display: "flex",
                    columnGap: "5px",
                    alignItems: "center",
                    borderRadius: "10px",
                    width: "159px",
                    height: "54px",
                  }}
                  className={styles.action_btn}
                >
                  <img src={"/img/"} height="20px" width="20px" />
                  Copy Link
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "print" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                {props.editdata ? "Edit" : "Add"} Product
              </p>
              <div>
                <label className={styles.input_label}>Name</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter name"
                  type="text"
                  name="name"
                  value={print.name}
                  onChange={handleChangePrint}
                />
              </div>
              <div className={styles.action}>
                {props.editdata ? (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      width: "156px",
                      height: "54px",
                    }}
                    className={styles.action_btn}
                    onClick={() => props.handleEditMaterial(print)}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "10px",
                      width: "156px",
                      height: "54px",
                    }}
                    className={styles.action_btn}
                    onClick={() => props.handleAdd(print)}
                  >
                    Add
                  </Button>
                )}
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : props.poptype === "print_prcie" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Print Price
              </p>
              <div>
                <label className={styles.input_label}>Select Product</label>
                <br />
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={product_id}
                  onChange={handleChangeProduct}
                >
                  <MenuItem value="">Select Product</MenuItem>
                  {props.productDt &&
                    props.productDt.data.map((v) => (
                      <MenuItem value={v._id}>{v.name}</MenuItem>
                    ))}
                </Select>
                <br />
                <br />
                <label className={styles.input_label}>Price</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter name"
                  type="number"
                  name="print_Price"
                  value={printPrice.print_Price}
                  onChange={handleChangePrintPrice}
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "10px",
                    width: "156px",
                    height: "54px",
                  }}
                  className={styles.action_btn}
                  onClick={() =>
                    props.handleAdd(printPrice.print_Price, product_id)
                  }
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.open}>
            <Box className={styles.modal_details}>
              <div className={styles.cancel_img}>
                <img
                  onClick={handleClose}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add FAQ Category
              </p>
              <div>
                <label className={styles.input_label}>Category name</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    "& .MuiOutlinedInput-notchedOutline": { border: "noe" },
                    borderRadius: "6px",
                    height: "54px",
                  }}
                  fullWidth
                  placeholder="Enter Name"
                  type="text"
                  value={faqCat}
                  name="FaqCat"
                  onChange={handleChangeFAQcat}
                />
              </div>
              <div className={styles.action}>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "10px", width: "156px", height: "54px" }}
                  className={styles.action_btn}
                  onClick={() => props.handleAddfaqCat(faqCat)}
                >
                  Add
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
