import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./UserAddress.module.css";
import CircularLoader from "../../../../../common/loader/Loader";
import Threedots from "../../../List/Table/Threedots/Threedots";
import { useNavigate } from "react-router-dom";
import EditUserAddress from "./editAddress/EditUserAddress";
import { handleFetchBuyerDetail } from "../../../../../State/api/userApi";
import { ToastContainer } from "react-toastify";
import { Successnotify } from "../../../../../common/alert/Alert";
import api from "../../../../../State/api/Api";

const data = [
  {
    type: "Home (Default)",
    address: "This is the home address",
    phone: "+91 998744565",
  },
  {
    type: "Office",
    address: "This is the Office address",
    phone: "+91 998744566",
  },
  {
    type: "Factory",
    address: "This is the Factory address",
    phone: "+91 998744563",
  },
];
const threedotData = [
  {
    link: "Inactive",
  },
];
export default function UserAddress() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [edit, setEdit] = useState(false);
  const [editAddressData, setEditAddressData] = useState(null)

  const loader = useSelector((state) => state.loader);

  const userDetail = useSelector((state) => state.buyerdetail.data);
  console.log(userDetail && userDetail.buyer)
  useEffect(() => {
    dispatch(handleFetchBuyerDetail());
  }, []);

  const handleClick = () => {
    setEdit(true);
  };
  const addresses = userDetail?.buyer?.addresses ?? [];
  const reversedAddresses = [...addresses].reverse();
  const handleMakeDefaultAddress = async (id) => {
    const {data} = await api.applicationApi(`api/buyer/setAddressDefault/${id}/`,"PATCH", {
      is_default: true
    });
   
      Successnotify(data.message)
      dispatch(handleFetchBuyerDetail())
  
  }
  return (
    <div className={styles.mains}>
      {edit ? (
        <EditUserAddress userDetail={userDetail} editAddressData={editAddressData}/>
      ) : (
        <>
          <div className={styles.components}>
            <div className={styles.add_btn_div}>
              <p>
                <b>Delivery Addresses</b>
              </p>
              <hr />
            </div>
            {/* {loader.loader ?
          <div className={styles.card_div}>
            <CircularLoader />
          </div>
          : */}
            <div className={styles.card_div}>
              {reversedAddresses.map((val, i) => (
                  <div key={val._id} className={styles.card}>
                    <div className={styles.edit_more}>
                      <span className={styles.heading}>{val.address_type}</span>
                      {val?.is_default === true && <span className={styles.heading}>(Default)</span>}
                      <Threedots
                        address_type={val.type}
                        data={threedotData}
                        val={val}
                        id={val?._id}
                        setEditAddressData={setEditAddressData}
                        setEdit={setEdit}
                        handleMakeDefaultAddress={handleMakeDefaultAddress}
                      />
                    </div>
                    <div className={styles.detail_main}>
                      <h1 className={styles.heading}>Address</h1>
                      <p className={styles.para}>
                      <p style={{color:"black",fontWeight:"bold"}}>Address Type : <span className={styles.addres_span}>{val.address_type}</span>,</p>
                      <p style={{color:"black",fontWeight:"bold"}}>Address Line 1 : <span className={styles.addres_span}>{val.address_line1}</span>,</p>
                      <p style={{color:"black",fontWeight:"bold"}}>Address Line 2 : <span className={styles.addres_span}>{val.address_line2}</span>,</p>
                      <p style={{color:"black",fontWeight:"bold"}}>Address Type : <span className={styles.addres_span}>{val.address_type}</span>,</p>
                        <p style={{color:"black",fontWeight:"bold"}}>Zipcode : <span className={styles.addres_span}>{val.flat_no}</span>,</p>
                         <p style={{color:"black",fontWeight:"bold"}}>City : <span className={styles.addres_span}>{val.city}</span>,</p>   
                          <p style={{color:"black",fontWeight:"bold"}}>State : <span className={styles.addres_span}>{val.state}</span>,</p> 
                        <p style={{color:"black",fontWeight:"bold"}}>Country : <span className={styles.addres_span}>{val.country}</span></p> 
                      </p>
                    </div>
                   
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.btn_div}>
            <Button
              className={styles.add_btn}
              onClick={handleClick}
              variant="contained"
            >
              Add New Address
            </Button>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
}
