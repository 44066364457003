import React, { useEffect, useLayoutEffect, useState } from "react";
import Uploadpopup from "../../../../gallery/components/popups/uploadPopup/Uploadpopup";
import Commonuploadart from "../../../../gallery/pages/uploadArt/commonUploadart/Commonuploadart";
import styles from "./Firstuploadartist.moudle.css";
import { Grid } from "@mui/material";
import Steppergallery from "../../../../gallery/pages/uploadArt/stepper/Steppergallery";
import { useDispatch, useSelector } from "react-redux";
import { fetchsubscriptionData } from "../../../../State/api/superadminApi";
import Uploadpopupsubscription from "../../../../gallery/components/popups/uploadPopup/Uplodpopupsubscription";
import api from "../../../../State/api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { setEditArtId } from "../../../../State/Features/common/editArtIdSlice";

export default function Firstuploadartist(props) {
  const [open, setOpen] = useState(false);

    const navigate = useNavigate()
  const dispatch = useDispatch();

  const [selectedPlans, setSelectedPlans] = useState({});

  const [openSubscription, setOpenSubscription] = useState(false);
  const subscriptionData = useSelector((state) => state.subscriptionData.data);
  const fetchData = () => {
    dispatch(fetchsubscriptionData());
  };

  const [artistSubscriptions, setArtistSubscriptions] = useState([]);

  useEffect(() => {
    if (!artistSubscriptions || artistSubscriptions.length === 0) return;

    const mostRecentSubscription =
      artistSubscriptions[artistSubscriptions.length - 1];
 
    if (
      !mostRecentSubscription ||
      !mostRecentSubscription.subscription_id ||
      !Array.isArray(mostRecentSubscription.subscription_id.feature_ids)
    ) {
      console.error("Invalid subscription data");
      return;
    }
    const expiryDate = new Date(mostRecentSubscription.expiry_date);
    const today = new Date();
  
    // Check if the subscription is expired
    if (isNaN(expiryDate) || expiryDate <= today) {
      console.warn("Subscription has expired or expiry date is invalid");
      return; // Exit if the subscription has expired or expiry date is invalid
    }
    const featureIds = mostRecentSubscription.subscription_id.feature_ids;

    const artsFeature = featureIds.find(
      (feature) => feature._id?.name === "no. of Arts"
    );
    const count = artsFeature ? artsFeature._id?.count : 0; // Default to 0 if not found

    if (count) {
      localStorage.setItem(
        "current_subscription_count",
        JSON.stringify(parseInt(count, 10))
      );
    }
  }, [artistSubscriptions]);
  async function fetchsubscriptionDataArtist() {
    try {
      const apiResponse = await api.applicationApi(
        `api/subscription/getArtistSubscriptions/${localStorage.getItem(
          "user_id"
        )}`,
        "GET"
      );

      if (apiResponse.status === 200 || apiResponse.status === 201) {
        setArtistSubscriptions(apiResponse.data.data);
      }
    } catch (err) {
    }
  }
  
  useEffect(() => {
    fetchsubscriptionDataArtist();
    fetchData();
  }, []);

  const [localStorageData, setLocalStorageData] = useState({
    countOfArts: JSON.parse(localStorage.getItem("count_of_arts")) || 0,
    currentSubscriptionCount: JSON.parse(localStorage.getItem("current_subscription_count")) || 0,
  });
  

  useLayoutEffect(() => {
    const checkLocalStorage = () => {
      const countOfArts = JSON.parse(localStorage.getItem("count_of_arts")) || 0;
      const currentSubscriptionCount = JSON.parse(localStorage.getItem("current_subscription_count")) || 0;
  
      // Only update if values have changed
      if (
        countOfArts !== localStorageData.countOfArts ||
        currentSubscriptionCount !== localStorageData.currentSubscriptionCount
      ) {
        setLocalStorageData({ countOfArts, currentSubscriptionCount });
  
        // Immediate logic to update subscription status
        if (currentSubscriptionCount > countOfArts) {
          setOpenSubscription(false);
        } else {
          setOpenSubscription(true);
        }
      }
    };
  
    // Initial check
    checkLocalStorage();
  
    // Listen for localStorage changes (in case other tabs/windows change it)
    const handleStorageChange = (event) => {
      if (event.key === "count_of_arts" || event.key === "current_subscription_count") {
        checkLocalStorage();
      }
    };
  
    window.addEventListener("storage", handleStorageChange);
  
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [localStorageData]);

useEffect(() => {
  const { countOfArts, currentSubscriptionCount } = localStorageData;

  // Set the state based on the condition
  if (currentSubscriptionCount > countOfArts) {
    setOpenSubscription(false);
  } else {
    setOpenSubscription(true);
  }
}, [localStorageData]);

  const stage = 0;

  useLayoutEffect(() => {
    if (subscriptionData && Array.isArray(subscriptionData)) {
      const initialPlans = subscriptionData.reduce((acc, plan) => {
        acc[plan.plan_name] = { monthly: true, yearly: false };
        return acc;
      }, {});
      setSelectedPlans(initialPlans);
    }
  }, [subscriptionData]);


  const handleCheckboxChange = (planName, type) => (event) => {
    const { checked } = event.target;

    setSelectedPlans((prev) => ({
      ...prev,
      [planName]: {
        monthly: type === 'monthly' ? checked : false,
        yearly: type === 'yearly' ? checked : false,
      },
    }));
  };
  return (
    <div className={styles.mains}>
      {open ? <Uploadpopup open={open} setOpen={setOpen} setUploadArt={props.setUploadArt} /> : ""}
      {openSubscription ? (
        <Uploadpopupsubscription
          open={openSubscription}
          setOpen={setOpenSubscription}
          subscriptionData={subscriptionData}
          handleCheckboxChange={handleCheckboxChange}
          selectedPlans={selectedPlans}
        />
      ) : (
        ""
      )}
    <Grid container>

  {/* This Grid item is for spacing, not for content */}

  {/* This Grid item is for content */}
  <Grid item xs={12} sm={12} md={12} >
    {/* Ensure Steppergallery takes full width */}
    <Steppergallery stage={stage} />
  </Grid>
</Grid>

{/* This is for Commonuploadart, ensuring it spans full width */}
<Grid container spacing={2} display="flex">
  {/* "Back to Home" section in a separate row */}

    <span className={styles.back_img} onClick={() => navigate("/arts")} style={{position:'relative',top:"50px",right:"150px",cursor:"pointer"}}>
      {"<"} Back To Home
    </span>


  {/* Commonuploadart in a separate row */}
  <Grid item xs={12} position="relative">
    <Commonuploadart
      uploadArt={props.uploadArt}
      setUploadArt={props.setUploadArt}
      handleChangedata={props.handleChangedata}
      setOpen={setOpen}
      src={props.src}
      setSrc={props.setSrc}
      selectedFiles={props.selectedFiles}
      setSelectedFiles={props.setSelectedFiles}
    />
  </Grid>
</Grid>


    </div>
  );
}
