import React, { useState } from "react"
import styles from "./EditMenu.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import { TextField } from "@mui/material"
import serverimage from "../../../../../serverConstant"
import {
  handleAddMenu,
  fetchMenuSubMenu,
  getAllMenuSubMenu,
  editMenudata,
} from "../../../../../State/api/superadminApi"
import { useDispatch, useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import { validateAdminMenu } from "../../../../../validation/validationAdminmenu"
import { validateAdminEditMenu } from "../../../../../validation/validationadminEditMenu"

export default function EditMenu(props) {
  console.log(props.name, "props.name--menu")
  const [data, setData] = useState(
    props.name
      ? {
          name: props.name && props.name.name,
          link: props.name && props.name.link,
          image: props.name && props.name.image,
        }
      : {
          name: "",
          link: "",
          image: "",
        }
  )
  const [errors, setErrors] = useState({})
  const [editerrors, setEditErrors] = useState({})
  const loader = useSelector((state) => state.loader)
  const [newdata, setNewdata] = useState({ name: "", link: "", image: "" })
  console.log(newdata, "newdata-menu")
  const [src, setSrc] = useState("")
  const [image, setImage] = useState("")
  const handleClose = () => props.setMenuOpen(false)
  const dispatch = useDispatch()

  const handleChange = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const handleEdit = (id) => {
    const { menuEditErrors, isValid } = validateAdminEditMenu(data)

    if (!isValid) {
      setEditErrors(menuEditErrors)
    } else {
      setEditErrors({})
      dispatch(
        editMenudata(
          id,
          data,
          props.setMenuOpen,
          props.fetchMenuData,
          getAllMenuSubMenu
        )
      )
    }
  }

  const handleNewChange = (e) => {
    const { name, value } = e.target
    setNewdata({ ...newdata, [name]: value })
  }

  const handleChangeImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0]
      const imgs = URL.createObjectURL(e.target.files[0])
      setImage(e.target.files[0])
      setSrc(imgs)
      setNewdata({ ...newdata, image: img })
      setData({ ...data, image: img })
    }
  }

  const handleSubmit = () => {
    const { menuErrors, isValid } = validateAdminMenu(newdata)

    if (!isValid) {
      setErrors(menuErrors)
    } else {
      setErrors({})
      const formData = new FormData()
      formData.append("name", newdata.name)
      formData.append("link", newdata.link)
      if (newdata.image) formData.append("image", newdata.image)

      dispatch(
        handleAddMenu(
          formData,
          props.setMenuOpen,
          props.fetchMenuData,
          getAllMenuSubMenu
        )
      )
    }
  }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      {props.type === "add" ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.menuopen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.menuopen}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setMenuOpen(false)}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Add Menu
              </p>

              {/* <div className={styles.textarea}>
                        <textarea className={styles.textareas} placeholder='Write a Remark' rows='10' />
                    </div> */}
              <div className={styles.upload_img}>
                <div className={styles.textarea_label}>Images</div>
                <div className={styles.preview_img}>
                  {newdata.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                          accept="image/png, image/jpeg, image/jpg"
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            alt="img"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={src}
                          className={
                            newdata.image
                              ? styles.img_view
                              : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>

                <div className={styles.file_div}></div>
                <p>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </p>
              </div>
              <div className={styles.textarea_label}>Main Menu *</div>
              <TextField
                onChange={handleNewChange}
                value={newdata.name}
                name="name"
                variant="outlined"
                placeholder="Enter Menu"
                fullWidth
                sx={{ mb: 1 }}
              />
              {errors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.name}
                </span>
              )}

              <div className={styles.textarea_label}>Link *</div>
              <TextField
                onChange={handleNewChange}
                value={newdata.link}
                name="link"
                variant="outlined"
                placeholder="Enter Link"
                fullWidth
                sx={{ mb: 1 }}
              />
              {errors?.link && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {errors?.link}
                </span>
              )}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  className={styles.action_btn}
                >
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Save"
                  )}
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={props.menuopen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={props.menuopen}>
            <Box className={styles.modal_del}>
              <div className={styles.cancel_img}>
                <img
                  onClick={() => props.setMenuOpen(false)}
                  src={"/img/cancel_cross.png"}
                  height="20px"
                  width="20px"
                  alt="img"
                />
              </div>
              <p
                className={styles.modal_heading}
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Edit Menu
              </p>
              <div className={styles.upload_img}>
                <div className={styles.textarea_label}>Images</div>
                <div className={styles.preview_img}>
                  {data.image === "" ? (
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={handleChangeImage}
                      >
                        <input
                          onChange={handleChangeImage}
                          type="file"
                          id="input"
                          hidden
                        />
                        <label htmlFor="input" className={styles.label}>
                          <img
                            alt="img"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                          />
                          <p> Upload your image</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        onChange={handleChangeImage}
                        type="file"
                        id="input"
                        hidden
                      />
                      <label htmlFor="input">
                        <img
                          alt="img"
                          src={data.image}
                          // alt="img"
                          className={
                            data.image ? styles.img_view : styles.img_view_hide
                          }
                        />
                      </label>
                    </>
                  )}
                </div>
                <div className={styles.file_div}></div>
                <p>
                  Upload an image of your artwork.Your file must be least
                  1200*1500 px , a JPG or PNG file ,width a color format of
                  rgb,and less than 50MB
                </p>
              </div>

              <label>Name *</label>
              <TextField
                onChange={handleChange}
                name="name"
                value={data.name}
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                sx={{ mb: 1 }}
              />
              {editerrors?.name && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {editerrors?.name}
                </span>
              )}
              <label>Link *</label>
              <TextField
                onChange={handleChange}
                name="link"
                value={data.link}
                variant="outlined"
                placeholder="Enter Link"
                fullWidth
                sx={{ mb: 1 }}
              />
              {editerrors?.link && (
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  {editerrors?.link}
                </span>
              )}
              <div className={styles.action}>
                <Button
                  variant="contained"
                  onClick={() => handleEdit(props.name._id)}
                  className={styles.action_btn}
                >
                  Save
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </div>
  )
}
