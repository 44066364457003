const availableCurrencies = [
    {
        "countryCode": "AU",
        "currencyCode": "AUD",
        "displayName": "Australia",
        "exchangeRate": 1.49,
        "locale": "en-AU"
    }, {
        "countryCode": "GB",
        "currencyCode": "GBP",
        "displayName": "United Kingdom",
        "exchangeRate": 0.78,
        "locale": "en-GB"
    }, {
        "countryCode": "US",
        "currencyCode": "USD",
        "displayName": "United States",
        "exchangeRate": 1,
        "locale": "en-US"
    }, {
        "countryCode": "CA",
        "currencyCode": "CAD",
        "displayName": "Canada",
        "exchangeRate": 1.32,
        "locale": "en-CA"
    }, {
        countryCode: "IN",
        currencyCode: "INR",
        displayName: "India",
        exchangeRate: 81.64,
        locale: "hi-IN"
    }, {
        "countryCode": "TR",
        "currencyCode": "TRY",
        "displayName": "Turkey",
        "exchangeRate": 26.61,
        "locale": "tr-TR"
    }, {
        "countryCode": "BR",
        "currencyCode": "BRL",
        "displayName": "Brazil",
        "exchangeRate": 4.76,
        "locale": "pt-BR"
    }, {
        "countryCode": "ZA",
        "currencyCode": "ZAR",
        "displayName": "South Africa",
        "exchangeRate": 18.38,
        "locale": "en-ZA"
    }, {
        "countryCode": "PH",
        "currencyCode": "PHP",
        "displayName": "Philippines",
        "exchangeRate": 53.85,
        "locale": "fil-PH"
    }, {
        "countryCode": "EU",
        "currencyCode": "EUR",
        "displayName": "European Union",
        "exchangeRate": 0.9,
        "locale": "en-EU"
    }
]

export default availableCurrencies;