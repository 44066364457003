import React, { useEffect, useState } from "react";
import styles from "./Signup.module.css";
import homeImage from "../../constant.js";
// import hi
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { fetchUsertypes, Registeration } from "../../State/api/commonapi";
import CircularLoader from "../../common/loader/Loader";
import { AlertsSuccess, Errornotify } from "../../common/alert/Alert";
import Entermobile from "../../curator/pages/applyForm/mobileNumber/Entermobile";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import api from "../../State/api/Api";

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));
const usertype = [
  // {
  //   id: 1,
  //   name: "Superadmin",
  //   value: "superadmin",
  // },
  //  {
  //   id: 2,
  //   name: "Admin",
  //   value: "admin",
  //  },
  {
    id: 1,
    name: "Buyer",
    value: "buyer",
  },
  {
    id: 2,
    name: "Curator",
    value: "curator",
  },
  {
    id: 3,
    name: "Affiliate",
    value: "affiliate",
  },
  {
    id: 4,
    name: "Artist",
    value: "artist",
  },
  {
    id: 5,
    name: "Gallery",
    value: "gallery",
  },
];
export default function Signup(props) {
  const {state} = useLocation();
  const type = state?.type
  const [termsPolicy, setTermsPolicy] = useState(false);
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    phone: "",
    email: "",
    role: type || "",
    // showPassword: false,
  });
  
  const [verification_view, setVerification_view] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const searchParams = useLocation().search;
  // Function to parse query parameters from URL
  const parseSearchParams = (searchParams) => {
    return new URLSearchParams(searchParams);
  };
  const queryParams = parseSearchParams(searchParams);
    
  // Extract parameters from URL
  const role = queryParams.get('role');
  const email = queryParams.get('email');
  const first_name = queryParams.get('first_name');
  const last_name = queryParams.get('last_name');
  const phone = queryParams.get('phone');


  const loader = useSelector((state) => state.loader);
  const handleLoginbtn = () => {
    navigate("/artsmiley/login",{state: {type: role ? role : type}});
  };
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
    let checked = false;
    let disabled = false;
    if (radioGroup) {
      checked = radioGroup.value === props.value;
      disabled = props.value !== props.userRole; // Disable if the value doesn't match the user's role
    }
    return <StyledFormControlLabel checked={checked} disabled={disabled} {...props} />;
  }

  const handleChangeNumber = (e) => {
  
      setUser({ ...user, phone: e });
 
  };
  MyFormControlLabel.propTypes = {
    value: PropTypes.any,
  };
  useEffect(() => {
    dispatch(fetchUsertypes());
  }, []);
  const handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    setUser({ ...user, [name]: val.trim() });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.first_name === "") {
      Errornotify("Please Enter First Name");
    } else if (user.last_name === "") {
      Errornotify("Please Enter Last Name");
    } else if (user.phone === "") {
      Errornotify("Please Enter Phone");
    } else if (user.phone.length < 10 ) {
      Errornotify("Please Enter Ten digit Contact No.");
    } else if (user.email === "") {
      Errornotify("Please Enter Email");
    } else if (user.password.length < 8) {
      Errornotify("Please Enter Minimum Eight Character");
    } else if(user.password !== user.confirm_password) {
      Errornotify("Please Enter Same Password");
    } else if (!termsPolicy) {
      Errornotify("Please Accept Terms & Policies");
    } else {
      dispatch(Registeration(user, navigate, setUser, setVerification_view,role,type));
    }
  };

  const handleResendmail = async function () {
    // dispatch(setLoade(true));
    try {
      const result = await api.applicationApi(
        `api/user/userVerification`,
        "POST"
      );
      if (result && result.status === 200) {
        // setIs_verify(true)
        // setNotify_msg(result.data.message)
        // setAffiliateType(result.data.data.data);
        // dispatch(setLoader(false));
      } else if (result.status === 400) {
        // setIs_verify(true)
        
        // setNotify_msg(result.data.message)
      } else {
        // setIs_verify(false)
      }
    } catch (err) {
    }
  };

  // const a =
  //   searchParams.search &&
  //   searchParams.search
  //     .split("?")[1]
  //     .split("&")[0]
  //     .split("&")
  //     .map((param) => param.split("="));
  // const b =
  //   searchParams.search &&
  //   searchParams.search
  //     .split("?")[1]
  //     .split("&")[1]
  //     .split("&")
  //     .map((param) => param.split("="));
      useEffect(() => {
        const queryParams = parseSearchParams(searchParams);
    
        // Extract parameters from URL
        const role = queryParams.get('role');
        const email = queryParams.get('email');
        const first_name = queryParams.get('first_name');
        const last_name = queryParams.get('last_name');
        const phone = queryParams.get('phone');
    
        // Update state with extracted parameters
        setUser({
          ...user,
          role: role || type,
          email: email || "",
          first_name: first_name || "",
          last_name: last_name || "",
          phone: phone || ""
        });
      }, []);
  return (
    <div className={styles.login_main}>
      <AlertsSuccess />
      <div className={styles.left_main}>
        <img
          src={ "/img/Signup Image.png"}
          className={styles.img_signup}
          alt="imageportrait"
        />
      </div>
      <div className={styles.right_main}>
        {verification_view ? (
          <div>
            <h2>Please verify your email</h2>
            <p>
              You’re almost there! We sent an email to <b>{user.email}</b>
            </p>
            <p>
              Just click on the link in that email to complete your sign up. If
              you don’t see it,you may need to <b>check your spam</b> folder.
            </p>
            <p> Still can’t find the mail?</p>
            <button
              type="submit"
              //  onClick={resendEmail}
            >
              Resend Email
            </button>
          </div>
        ) : (
          <div className={styles.signup_center}>
            <div>
              <img
                src={
                   
                  "/img/updated_logo.png"
                }
                className={styles.img_art}
                alt="portrait"
                onClick={() => navigate("/")}
              />
              <h3 className={styles.heading_art_s}> Welcome To Art Smiley</h3>
            </div>
            {/* {loader.loader ?
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularLoader />
                    </Box>
                    : */}
            <FormControl sx={{ my: 2, width: { sx: "100%", sm: "123%" } }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="role"
                onChange={handleChange}
                sx={{
                  "&, &.Mui-checked": {
                    color: " #D0D0D0",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 15,
                  },
                }}
                value={user.role}
                defaultValue="buyer"
              >
                {usertype &&
                  usertype.map((val) => (
                    <MyFormControlLabel
                      key={val.id}
                      userRole={user.role}
                      name="role"
                      value={val.value}
                      id={val._id}
                      control={<Radio  />}
                      sx={{ fontSize: "17px" }}
                      label={
                        <span style={{ fontSize: "17px", my: 0 }}>
                          {val.name}
                        </span>
                      }
                    />
                  ))}
                {/* <MyFormControlLabel value="curator" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Curator</span>} />
                        <MyFormControlLabel value="affiliate" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Affiliate</span>} />
                        <MyFormControlLabel value="artist" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Artist</span>} />
                        <MyFormControlLabel value="gallery" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Gallery</span>} /> */}
              </RadioGroup>
            </FormControl>
            {/* } */}
            <div>
            <Box sx={{ mb: 2, }}>
                <Button
                  variant="outlined"
                  onClick={handleLoginbtn}
                  disabled={email?.length > 0}
                  sx={{
                  
                    width: "151px",
                    height: "51px",
                    fontSize: "19px",
                    fontWeight: "700",
                    borderRadius:"9.96px"
                  }}
                >
                  Login
                </Button>
                {/* <Button variant='contained' sx={{ mr: 1, width: '100px' }}>Login</Button> */}
                <Button
                  variant="outlined"
                  sx={{
                    ml: 1,
                    width: "151px",
                    height: "51px",
                    background: "black",
                    fontSize: "19px",
                    fontWeight: "700",
                    color: "white",
                    borderRadius:"9.96px",
                    "&:hover": {
                      background: "black",
                    },
                  }}
                
                >
                  Sign Up
                </Button>
              </Box>
              <p className={styles.newacc_head}>Create new account</p>
              <div>
                <p className={styles.para_s}>
                  Already a member?
                  <span className={styles.para_span}>Log in</span>
                </p>
              </div>
            </div>
            <form className={styles.form_login} onSubmit={handleSubmit}>
              <span className={styles.span_input}>First name*</span>
              <br />
              <TextField
                onChange={handleChange}
                value={user.first_name}
                name="first_name"
                disabled={first_name?.length}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                id="outlined-basic"
                placeholder="Enter your first name"
                className=""
                fullWidth
                variant="outlined"
              />
              <br />
              <span className={styles.span_input}>Last name*</span>
              <br />
              <TextField
                onChange={handleChange}
                value={user.last_name}
                name="last_name"
                disabled={last_name?.length}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                  },
                }}
                id="outlined-basic"
                placeholder="Enter your last name"
                className=""
                fullWidth
                variant="outlined"
              />
              <br />
              <span className={styles.span_input}>Mobile number*</span>
              {/* <TextField
          onChange={handleChange}
          value={user.phone}
          name="phone"
          type="number"
          sx={{
            color: "#636365",
            "& .MuiOutlinedInput-notchedOutline": { border: "non" },
            "& .MuiInputBase-root": {
              // height: 35
            },
          }}
          inputProps={{
            style: {
              height: 1,
            },
          }}
          id="outlined-basic"
          placeholder="Enter your contact no."
          className=""
          fullWidth
          variant="outlined"
        /> */}
              <Entermobile
                handleChangeNumber={handleChangeNumber}
                type="sign_up"
                mobile={user.phone} 
                phone={phone}
              />
              <span className={styles.span_input}>Email*</span>
              <br />
              <TextField
                onChange={handleChange}
                value={user.email}
                name="email"
                disabled={email?.length}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                type="email"
                id="outlined-basic"
                placeholder="Enter your email"
                className=""
                fullWidth
                variant="outlined"
              />
              <br />
              <span className={styles.span_input}>Password*</span>
              <br />
              <TextField
                onChange={handleChange}
                value={user.password}
                name="password"
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUser({ ...user, showPassword: !user.showPassword })
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {user.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={user.showPassword ? "text" : "password"}
                id="outlined-basic"
                placeholder="Enter your password"
                fullWidth
                variant="outlined"
              />
               <span className={styles.span_input}>Confirm Password*</span>
              <br />
              <TextField
                onChange={handleChange}
                value={user?.confirm_password}
                name="confirm_password"
                error={user?.password !== user?.confirm_password}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUser({ ...user, showPasswordC: !user.showPasswordC })
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {user.showPasswordC ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={user.showPasswordC ? "text" : "password"}
                id="outlined-basic"
                placeholder="Enter your password"
                fullWidth
                variant="outlined"
              />
              <div className={styles.forgot_s}>
                <div className={styles.policy}>
                  <input
                    type="checkbox"
                    className={styles.checks}
                    onClick={() => setTermsPolicy(!termsPolicy)}
                  />
                  <span className={styles.para_s}>
                    agree to the <span  onClick={() => navigate("/user/termsconditions")}  style={{
                      fontSize: "16px",
                      textDecoration: "underline",
                      color: "#000",
                      cursor: "pointer",
                      marginLeft:"3px"
                  
                    }}>terms & policy</span>
                  </span>
                  <br />
                </div>
                {/* <span
                 style={{cursor:"pointer"}}
                  onClick={() => navigate("/artsmiley/forgot")}
                >
                  Forgot password?
                </span> */}
              </div>

              <Box className={styles.sign_button}>
                {loader.btnloader ? (
                  <Button
                    variant="contained"
                    sx={{ height: "35px", my: 2 }}
                    fullWidth
                  >
                    <CircularLoader color="white" type="small" />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{borderRadius:2, height: "55px", my: 2,lineHeight:"26px",fontSize:"20px" }}
                    fullWidth
                    type="submit"
                  >
                    Sign Up
                  </Button>
                )}
              </Box>
            </form>
          </div>
        )}
      </div>
    </div>
  );
  // return (
  //   <div className={styles.login_main}>
  //     <AlertsSuccess />
  //     <div className={styles.left_main}>
  //       <img
  //         src={ "/img/Signup Image.png"}
  //         className={styles.img_signup}
  //         alt="imageportrait"
  //       />
  //     </div>
  //     <div className={styles.right_main}>
  //       <div className={styles.signup_center}>
  //         <div>
  //           <img
  //             src={
  //                
  //               "/img/updated_logo.png"
  //             }
  //             className={styles.img_art}
  //             alt="portrait"
  //             onClick={() => navigate("/")}
  //           />
  //           <h3 className={styles.heading_art_s}> Welcome To Art Smiley</h3>
  //         </div>
  //         {/* {loader.loader ?
  //                       <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //                           <CircularLoader />
  //                       </Box>
  //                       : */}
  //         <FormControl sx={{ my: 0, width: { sx: "100%", sm: "123%" } }}>
  //           <RadioGroup
  //             row
  //             aria-labelledby="demo-row-radio-buttons-group-label"
  //             name="role"
  //             onChange={handleChange}
  //             sx={{
  //               "&, &.Mui-checked": {
  //                 color: " #D0D0D0",
  //               },
  //               "& .MuiSvgIcon-root": {
  //                 fontSize: 15,
  //               },
  //             }}
  //             value={user.role}
  //             defaultValue="buyer"
  //           >
  //             {usertype &&
  //               usertype.map((val) => (
  //                 <MyFormControlLabel
  //                   key={val.id}
  //                   name="role"
  //                   value={val.value}
  //                   id={val._id}
  //                   control={<Radio />}
  //                   sx={{ fontSize: "12px" }}
  //                   label={
  //                     <span style={{ fontSize: "12px", my: 0 }}>
  //                       {val.name}
  //                     </span>
  //                   }
  //                 />
  //               ))}
  //             {/* <MyFormControlLabel value="curator" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Curator</span>} />
  //                           <MyFormControlLabel value="affiliate" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Affiliate</span>} />
  //                           <MyFormControlLabel value="artist" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Artist</span>} />
  //                           <MyFormControlLabel value="gallery" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Gallery</span>} /> */}
  //           </RadioGroup>
  //         </FormControl>
  //         {/* } */}
  //         <div>
  //           <Box sx={{ mb: 1 }}>
  //             <Button
  //               variant="outlined"
  //               onClick={handleLoginbtn}
  //               sx={{ mr: 0, width: "100px", height: "30px" }}
  //             >
  //               Login
  //             </Button>
  //             {/* <Button variant='contained' sx={{ mr: 1, width: '100px', height: '30px' }}>Sign Up</Button> */}
  //           </Box>
  //           <p className={styles.newacc_head}>Create new account</p>
  //           <div>
  //             <p className={styles.para_s}>
  //               Already a member?
  //               <span className={styles.para_span}>Log in</span>
  //             </p>
  //           </div>
  //         </div>
  //         <form className={styles.form_login} onSubmit={handleSubmit}>
  //           <span className={styles.span_input}>First name*</span>
  //           <br />
  //           <TextField
  //             onChange={handleChange}
  //             value={user.first_name}
  //             name="first_name"
  //             sx={{
  //               color: "#636365",
  //               "& .MuiOutlinedInput-notchedOutline": { border: "non" },
  //               "& .MuiInputBase-root": {
  //                 // height: 35
  //               },
  //             }}
  //             inputProps={{
  //               style: {
  //                 height: 1,
  //               },
  //             }}
  //             id="outlined-basic"
  //             placeholder="Enter your first name"
  //             className=""
  //             fullWidth
  //             variant="outlined"
  //           />
  //           <br />
  //           <span className={styles.span_input}>Last name*</span>
  //           <br />
  //           <TextField
  //             onChange={handleChange}
  //             value={user.last_name}
  //             name="last_name"
  //             sx={{
  //               color: "#636365",
  //               "& .MuiOutlinedInput-notchedOutline": { border: "non" },
  //               "& .MuiInputBase-root": {
  //                 // height: 35
  //               },
  //             }}
  //             inputProps={{
  //               style: {
  //                 height: 1,
  //               },
  //             }}
  //             id="outlined-basic"
  //             placeholder="Enter your last name"
  //             className=""
  //             fullWidth
  //             variant="outlined"
  //           />
  //           <br />
  //           <span className={styles.span_input}>Mobile number*</span>
  //           {/* <TextField
  //             onChange={handleChange}
  //             value={user.phone}
  //             name="phone"
  //             type="number"
  //             sx={{
  //               color: "#636365",
  //               "& .MuiOutlinedInput-notchedOutline": { border: "non" },
  //               "& .MuiInputBase-root": {
  //                 // height: 35
  //               },
  //             }}
  //             inputProps={{
  //               style: {
  //                 height: 1,
  //               },
  //             }}
  //             id="outlined-basic"
  //             placeholder="Enter your contact no."
  //             className=""
  //             fullWidth
  //             variant="outlined"
  //           /> */}
  //           <Entermobile
  //             handleChangeNumber={handleChangeNumber}
  //             type="sign_up"
  //           />
  //           <span className={styles.span_input}>Email*</span>
  //           <br />
  //           <TextField
  //             onChange={handleChange}
  //             value={user.email}
  //             name="email"
  //             sx={{
  //               color: "#636365",
  //               "& .MuiOutlinedInput-notchedOutline": { border: "non" },
  //               "& .MuiInputBase-root": {
  //                 // height: 35
  //               },
  //             }}
  //             inputProps={{
  //               style: {
  //                 height: 1,
  //               },
  //             }}
  //             type="email"
  //             id="outlined-basic"
  //             placeholder="Enter your email"
  //             className=""
  //             fullWidth
  //             variant="outlined"
  //           />
  //           <br />
  //           <span className={styles.span_input}>Password*</span>
  //           <br />
  //           <TextField
  //             onChange={handleChange}
  //             value={user.password}
  //             name="password"
  //             sx={{
  //               color: "#636365",
  //               "& .MuiOutlinedInput-notchedOutline": { border: "non" },
  //               "& .MuiInputBase-root": {
  //                 // height: 35
  //               },
  //             }}
  //             inputProps={{
  //               style: {
  //                 height: 1,
  //               },
  //             }}
  //             InputProps={{
  //               endAdornment: (
  //                 <InputAdornment position="end">
  //                   <IconButton
  //                     onClick={() =>
  //                       setUser({ ...user, showPassword: !user.showPassword })
  //                     }
  //                   // onMouseDown={handleMouseDownPassword}
  //                   >
  //                     {user.showPassword ? <Visibility /> : <VisibilityOff />}
  //                   </IconButton>
  //                 </InputAdornment>
  //               ),
  //             }}
  //             type={user.showPassword ? "text" : "password"}
  //             id="outlined-basic"
  //             placeholder="Enter your password"
  //             fullWidth
  //             variant="outlined"
  //           />
  //           <div className={styles.forgot_s}>
  //             <div className={styles.policy}>
  //               <input
  //                 type="checkbox"
  //                 className={styles.checks}
  //                 onClick={() => setTermsPolicy(!termsPolicy)}
  //               />
  //               <span className={styles.para_s}>
  //                 agree to the terms & policy
  //               </span>
  //               <br />
  //             </div>
  //             <span>Forgot password?</span>
  //           </div>

  //           <Box className={styles.sign_button}>
  //             <Button
  //               variant="contained"
  //               sx={{ height: "35px", my: 2 }}
  //               fullWidth
  //               type="submit">
  //               {loader.btnloader ?
  //                 <CircularLoader color="white" type="small" />
  //                 :
  //                 "Sign Up"
  //               }
  //             </Button>
  //           </Box>
  //         </form>
  //       </div>
  //     </div>
  //   </div>
  // );
}
