import React from "react"
import styles from "./PopupRequest.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Fade, Grid } from "@mui/material"
import dayjs from "dayjs"
import serverimage from "../../../../serverConstant"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  width: 600,
  bgcolor: "background.paper",
  // border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 1,
}

const data = [
  {
    id: 1,
    user_title: "Username",
    user_val: "Artsmiley",
  },
  {
    id: 2,
    user_title: "Customer ID",
    user_val: "h3jh45",
  },
  {
    id: 3,
    user_title: "Buyer Name",
    user_val: "Smiley",
  },
  {
    id: 4,
    user_title: "Number of items",
    user_val: "878",
  },
  {
    id: 5,
    user_title: "Email Address",
    user_val: "jhe63hkj@gmail.com",
  },
]

export default function PopupQuote(props) {
  console.log(props.popupdataQuote, "data--popupdataQuote")

  // const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpenRequestQuote(false)

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.openRequestQuote}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openRequestQuote}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpenRequestQuote(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            {/* grid */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{
                my: 1.5,
                display: "flex",
                justifyContent: "space-between",
              }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 22 }}
            >
              <Grid item xs={12} sm={4} md={11} className={styles.inputs_gird}>
                <img
                  alt="img"
                  src={` ${props.popupdataQuote?.item_id?.image}`}
                  height="180px"
                  width="200px"
                />
              </Grid>
              <Grid item xs={12} sm={8} md={11} className={styles.inputs_gird}>
                <div className={styles.data_div}>
                  {/* {data.map((val) => ( */}
                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>User Name:</h5>
                    <p className={styles.user_value}>
                      {props.popupdataQuote?.first_name}
                      {props.popupdataQuote?.last_name}
                    </p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Sku_Id:</h5>
                    <p className={styles.user_value}>
                      {" "}
                      {props.popupdataQuote?.item_id?.sku_id}
                    </p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Art Name:</h5>
                    <p className={styles.user_value}>
                      {props.popupdataQuote?.item_id?.name}
                    </p>
                  </div>
                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Art Type:</h5>
                    <p className={styles.user_value}></p>
                  </div>

                  <div className={styles.user_detail}>
                    <h5 className={styles.user_title}>Request Date:</h5>
                    <p className={styles.user_value}>
                      {dayjs(props.popupdataQuote?.updatedAt)
                        .format("DD-MM-YYYY")
                        .toString()}
                    </p>
                  </div>

                  {/* ))} */}
                </div>
              </Grid>
            </Grid>

            <div>
              <h5 className={styles.requestpop}>Remark for Recreate:</h5>
              <p>{props.popupdataQuote?.item_id?.description}</p>
            </div>

            {/*  */}
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
