import React, { useEffect, useState } from "react"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./ContactUs.module.css"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Pagination from "../Pagination/Pagination"
import Addpopup from "../Popupdelete/addPopup/Addpopup"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import { setCareerdata } from "../../../../State/Features/superadmin/careerSlice"
import {
  deleteContactUsQuery,
  fetchContactUsQuery,
} from "../../../../State/api/superadminApi"

const upcominghead = [
  {
    id: "artist_name ",
    label: "Name",
  },
  {
    id: "location",
    label: "Phone",
  },
  {
    id: "like",
    label: "Email",
  },
  {
    id: "like1",
    label: "Date",
  },
  {
    id: "followe",
    label: "Message",
  },
]
const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]

export default function ContactUs() {
  const [data, setData] = useState({ offset: 1 })
  const [contactData, setContactData] = useState({ data: [], total_queries: 0 }) // Updated to match API response
  const dispatch = useDispatch()
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const fetchData = () => {
    dispatch(fetchContactUsQuery(setContactData))
  }

  useEffect(() => {
    fetchData()
  }, [data])

  const handleDelete = (id) => {
    dispatch(deleteContactUsQuery(id, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      setContactData({
        ...contactData,
        data: [...contactData.data].sort((a, b) =>
          a.first_name.toLowerCase() > b.first_name.toLowerCase() ? 1 : -1
        ),
      })
    } else if (value === "name-desc") {
      setContactData({
        ...contactData,
        data: [...contactData.data].sort((a, b) =>
          a.first_name.toLowerCase() < b.first_name.toLowerCase() ? 1 : -1
        ),
      })
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    const value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    const value = e.target.value
    setCondition({ ...condition, sorting: value })

    if (value === "low") {
      setContactData({
        ...contactData,
        data: [...contactData.data].sort((a, b) =>
          a.createdAt.toLowerCase() < b.createdAt.toLowerCase() ? -1 : 1
        ),
      })
    } else if (value === "high") {
      setContactData({
        ...contactData,
        data: [...contactData.data].sort((a, b) =>
          a.createdAt.toLowerCase() > b.createdAt.toLowerCase() ? -1 : 1
        ),
      })
    } else {
      fetchData()
    }
  }

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
        />
        <Tablesfivecol
          activetab="contact-us"
          head={upcominghead}
          data={contactData.data.filter((val) => {
            const fullName = `${val.first_name || ""} ${val.last_name || ""}`
              .toLowerCase()
              .trim()
            const search = condition.search.toLowerCase().trim()
            const nameMatch =
              val.first_name && val.first_name.toLowerCase().includes(search)
            const lastnameMatch =
              val.last_name && val.last_name.toLowerCase().includes(search)

            const fullNameMatch = fullName.includes(search)

            return nameMatch || lastnameMatch || fullNameMatch
          })}
          handleDelete={handleDelete}
        />
        {/* <Pagination
          totaldata={contactData.total_queries}
          pagination={data}
          setPagination={setData}
        /> */}
      </div>
    </div>
  )
}
