import React, { useState } from "react"
import styles from "./Artistdetail.module.css"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import homeImage from "../../../../../constant"
import Tables from "../../Table/Table/Tables"
import serverimage from "../../../../../serverConstant"
import dayjs from "dayjs"
import { Button } from "@mui/material"
import { ToastContainer } from "react-toastify"
import PopupModalSubscription from "../../Popupdelete/PopupModalSubscription"
import TableMarketing from "../../Table/Table/TableMarketing"
const dt = {
  img: "/img/artist_profile.png",
  name: "Emily Cooper",
  more_details: [
    {
      title: "Views",
      content: "10,456",
    },
    {
      title: "Likes",
      content: "8,456",
    },
    {
      title: "Favorites",
      content: "1,456",
    },
  ],
}
const data = [
  {
    heading: "Description",
    description_details: [
      {
        title: "Mobile Number",
        content: "+91 9998738792",
      },
      {
        title: "Email Adress",
        content: "Artsmiley",
      },
      {
        title: "CV",
        content: "Artsmiley",
      },
      {
        title: "ID Proof ",
        content: "Artsmiley",
      },
      {
        title: "Social Media Link ",
        content: "http:art.com",
      },
      {
        title: "Art Qualification University ",
        content: "20 W * 20 H * 50 D in",
      },
      {
        title: "Degree Year",
        content: "2012",
      },
      {
        title: "Artist Type ",
        content: "Emerging Artist ",
      },
      {
        title: "Assign to",
        content: "Joe Doe",
      },
      {
        title: "Exibition 1",
        content: "Artsmiley",
      },
      {
        title: "Exibition 2 ",
        content: "Art of Space",
      },
      {
        title: "Subscription Plan ",
        content: "Art of Space",
      },
      {
        title: "Porfolio ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and mgffdg gfdhfdg",
      },
      {
        title: "Biography ",
        content:
          "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and m",
      },
    ],
  },
  {
    heading: "Bank Details",
    description_details: [
      {
        title: "Bank Name",
        content: "Axis Bank",
      },
      {
        title: "Account Holder Name",
        content: "Emily cooper",
      },
      {
        title: "Account Number",
        content: "238787438493",
      },
      {
        title: "Routing/ABA Number",
        content: "4325324543",
      },
      {
        title: "Bank Location",
        content: "India",
      },
      {
        title: "Account Type",
        content: "Saving Account",
      },
    ],
  },
  // {
  //     heading: "Art Detail",
  // }
]

const headData = [
  {
    id: "image",
    label: "Image",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "user_name",
    label: "User Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "sku_id",
    label: "Sku_Id",
    // format: (value) => value.toLocaleString(),
  },
  {
    id: "art_name",
    label: "Art Name",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "buy",
    label: "Buy",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "rent",
    label: "Rent",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "print",
    label: "Print",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "action",
    label: "",
    format: (value) => value.toLocaleString(),
  },
]
export default function Artistdetail(props) {
  console.log(props, "props-artist-")
  const location = useLocation()
  const { active } = location.state || {}
  const navigate = useNavigate()
  const data = useSelector((state) => state.artistdata.data)
  const [openModal, setOpenModal] = useState(false)
  const handlePages = () => {
    const prevpathname = localStorage.getItem("pathname :")
    // navigate(prevpathname);
    // if (location.pathname.includes('/artist/')) {
    //     navigate('/artist');
    // }

    if (location.pathname.includes("/artist/")) {
      navigate("/artist")
    } else if (location.pathname.includes("/affiliate/")) {
      navigate("/affiliate")
    }

    // else if (location.pathname.includes('/art/')) {
    //     navigate('/art');
    // }
    // else if (location.pathname.includes('/affiliate/')) {
    //     navigate('/affiliate');
    // }
    // else if (location.pathname.includes('/curator/artist/')) {
    //     navigate('/curator/artist');
    // }
  }

  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`)
  }

  const handlePopup = (data) => {
    setOpenModal(true)
  }
  return (
    <>
      <div className={styles.mains}>
        {openModal ? (
          <PopupModalSubscription open={openModal} setOpen={setOpenModal} />
        ) : (
          ""
        )}
        {props.data &&
          props.data.map((val) => (
            <div className={styles.main_artistprofile}>
              <img
                src={"/img/_ Back.png"}
                onClick={handlePages}
                className={styles.backimage}
                height="13px"
                width="46px"
                alt="img"
              />
              {/* <p> < Back </p> */}
              <div className={styles.profile_detail}>
                <div>
                  {dt.img === "" ? (
                    <></>
                  ) : (
                    <>
                      {val?.artists?.map((pic, i) => (
                        <img
                          className={styles.profile_img}
                          src={pic.image ? pic.image : "/img/profile_alt.jpg"}
                          alt="img"
                        />
                      ))}
                    </>
                  )}
                  {/* <img className={styles.profile_img} src={ val.image} /> */}
                  {/* <img className={styles.profile_img} src={ /img/artist_profile.png' /> */}
                  {/* <h2>{data.artist_name}</h2> */}
                  <h2>
                    {val.first_name} {val.last_name}
                  </h2>
                  <div className={styles.more_details}>
                    {/* {props.dt.more_details &&
                      props.dt.more_details.map((val) => ( */}
                    <div>
                      <h4>Views</h4>
                      <p>{val.views ? val.views : "0"}</p>
                    </div>{" "}
                    <div>
                      <h4>Likes</h4>
                      <p>{val.likes ? val.likes : "0"}</p>
                    </div>{" "}
                    <div>
                      <h4>Favorites</h4>
                      <p>{val.favourites ? val.favourites : "0"}</p>
                    </div>
                    {/* ))} */}
                  </div>
                </div>

                <div className={styles.edit_btn}>
                  <div>
                    <Button
                      onClick={() => handlePopup()}
                      variant="contained"
                      sx={{
                        fontSize: "16px",
                        width: "150px",
                        height: "80px",
                        borderRadius: "10px",
                      }}
                    >
                      Artist Marketing Info
                    </Button>
                  </div>
                </div>
              </div>
              <hr />
              {props.type === "artist" ? (
                <div className={styles.all_details}>
                  <div className={styles.desc}>
                    <h1>Description</h1>
                    <Button
                      onClick={() => navigateToArtistDetail(val._id)}
                      variant="contained"
                      sx={{
                        fontSize: "16px",
                        width: "150px",
                        height: "50px",
                        borderRadius: "10px",
                      }}
                    >
                      Artist Details
                    </Button>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Mobile Number :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.phone ? val.phone : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Email Address :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.email ? val.email : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Country of Residence :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((con, i) =>
                          con.country ? con.country : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Nationality :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((nation, i) =>
                          nation.Nationality ? nation.Nationality : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Preffered Language :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((lan, i) =>
                          lan.language ? lan.language : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Full time professional artist? :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.is_fulltime_artist ? vl.is_fulltime_artist : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Year of Experience :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.exp ? vl.exp : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Represented by another online art gallery :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.is_another_art_galary_representation
                            ? vl.is_another_art_galary_representation
                            : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Please provide your online art gallery url :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.online_art_gallary_URL ? (
                            <a
                              href={vl.online_art_gallary_URL}
                              key={i}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {vl.online_art_gallary_URL}
                            </a>
                          ) : (
                            "----"
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Price Range :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.art_price_range[0] ? vl.art_price_range[0] : "----"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Sold art past 12 month :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.arts_solds_in_past_12_months
                            ? vl.arts_solds_in_past_12_months
                            : "----"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Why you are best? :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.promoting_by_reason
                            ? vl.promoting_by_reason
                            : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Work exclusively with art smiley :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val?.artists?.map((vl, i) =>
                          vl.exclusive_work ? vl.exclusive_work : "----"
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>CV :</div>
                    <div>
                      <div className={styles.des_content}>
                        {/* {val?.artists?.map((cv, i) => (cv.CV ? cv.CV : "----"))} */}

                        {val?.artists?.map((cv, i) =>
                          cv.CV ? (
                            <a
                              key={i}
                              href={` /${cv.CV}`}
                              download={cv.CV}
                              style={{
                                color: "#636365",
                                textDecoration: "none",
                              }}
                            >
                              Download CV
                            </a>
                          ) : (
                            "----"
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>ID Proof :</div>
                    <div>
                      <div className={styles.des_content}>
                        {/* {val.artists[0] ? val.artists[0].ID_proof : "----"} */}

                        {val?.artists?.map((cv, i) =>
                          cv.ID_proof ? (
                            <a
                              key={i}
                              href={` /${cv.ID_proof}`}
                              download={cv.ID_proof}
                              style={{
                                color: "#636365",
                                textDecoration: "none",
                              }}
                            >
                              Download ID
                            </a>
                          ) : (
                            "----"
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Social Instagram Link :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {/* {val.artists[0]
                          ? val.artists[0].social_links
                            ? val.artists[0].social_links.instagram
                            : "----"
                          : "----"} */}

                        {val?.artists?.map((insta, i) =>
                          insta?.social_links?.instagram
                            ? insta?.social_links?.instagram
                            : "----"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Art Qualification University :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0] ? val.artists[0].qualification : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Degree Year :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].degree_year === null
                            ? "----"
                            : val.artists[0].degree_year
                          : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Artist Type :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].artist_type.length > 0
                            ? val.artists[0].artist_type[0].name
                            : "----"
                          : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Assign to :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].assigned_to.length > 0
                            ? val.artists[0].assigned_to
                            : "----"
                          : "----"}
                      </div>
                    </div>
                  </div>
                  {val.exhibition &&
                    val.exhibition.map((item, i) => (
                      <div className={styles.description_deatils}>
                        <div className={styles.des_title}>
                          Exshibition {i + 1} :
                        </div>
                        <div>
                          <div className={styles.des_content}>
                            {val.subscription_plan
                              ? val.subscription_plan
                              : "----"}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Subscription plan :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].subscription_plan.length > 0
                            ? val.artists[0].subscription_plan[0].plan_name
                            : "----"
                          : "----"}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Portfolio :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].portfolio
                            ? val.artists[0].portfolio
                            : "----"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Biography :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].biography
                            ? val.artists[0].biography
                            : "----"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>About Us :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].about
                            ? val.artists[0].about
                            : "----"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>
                      Professional information :
                    </div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0]
                          ? val.artists[0].art_qualification
                            ? val.artists[0].art_qualification
                            : "----"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Shipping address :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.artists[0] ? (
                          val.artists[0].address1 ? (
                            <div>
                              {val.artists[0].address1}
                              {val.artists[0].address2}
                              {val.artists[0].city}
                              {val.artists[0].state}
                              {val.artists[0].zipcode}
                            </div>
                          ) : (
                            "----"
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  {active === "approved" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Approved Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.artists[0]
                            ? val.artists[0].approved_date
                              ? dayjs(val.artists[0].approved_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                  {active === "pending" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Created Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.createdAt
                            ? dayjs(val.createdAt)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"}
                        </div>
                      </div>
                    </div>
                  )}
                  {active === "featured" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Featured Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.artists[0]
                            ? val.artists[0].featured_date
                              ? dayjs(val.artists[0].featured_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                  {active === "decline" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Decline Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.artists[0]
                            ? val.artists[0].approved_date
                              ? dayjs(val.artists[0].approved_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                  {active === "recommended" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Recommended Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.artists[0]
                            ? val.artists[0].recommended_date
                              ? dayjs(val.artists[0].recommended_date)
                                  .format("YYYY-MM-DD")
                                  .toString()
                              : "----"
                            : ""}
                        </div>
                      </div>
                    </div>
                  )}
                  {active === "exclusive" && (
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Exclusive Date :</div>
                      <div>
                        <div className={styles.des_content}>
                          {val.createdAt
                            ? dayjs(val.createdAt)
                                .format("YYYY-MM-DD")
                                .toString()
                            : "----"}
                        </div>
                      </div>
                    </div>
                  )}

                  <hr />
                </div>
              ) : (
                <div className={styles.all_details}>
                  <h1>Description</h1>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Name :</div>
                    <div>
                      <div className={styles.des_content}>
                        {val.first_name}
                        {val.last_name}
                      </div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Mobile Number :</div>
                    <div>
                      <div className={styles.des_content}>{val.phone}</div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Email Address :</div>
                    <div>
                      <div className={styles.des_content}>{val.email}</div>
                    </div>
                  </div>
                  <div className={styles.description_deatils}>
                    <div className={styles.des_title}>Type :</div>
                    <div>
                      <div className={styles.des_content}>{val.type}</div>
                    </div>
                  </div>
                  {/* {val.exhibition && val.exhibition.map((item, i) => (
                                    <div className={styles.description_deatils}>
                                        <div className={styles.des_title}>Exshibition {i + 1} :</div>
                                        <div>
                                            <div className={styles.des_content}>{val.subscription_plan}</div>
                                        </div>
                                    </div>
                                ))}  */}
                  <hr />
                </div>
              )}
              {val.bankdetails &&
                val.bankdetails.map((item) => (
                  <div className={styles.all_details}>
                    <h1>Bank Details</h1>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Bank Name :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.bank_name ? item.bank_name : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>
                        Accoun Holder Name :
                      </div>
                      <div>
                        <div className={styles.des_content}>
                          {item.account_holder_name
                            ? item.account_holder_name
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Account Number :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.account_number ? item.account_number : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>
                        Routing/ABA Number :
                      </div>
                      <div>
                        <div className={styles.des_content}>
                          {item.routing_ABA_number
                            ? item.routing_ABA_number
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Bank Location :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.bank_location ? item.bank_location : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Account Type :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.account_type ? item.account_type : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Bank :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.account_type ? item.account_type : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.description_deatils}>
                      <div className={styles.des_title}>Bank :</div>
                      <div>
                        <div className={styles.des_content}>
                          {item.bank_name ? item.bank_name : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {/* {
                            props.data.map((val, i) => (
                                <div className={styles.all_details} key={i}>
                                    <h1>{val.heading}</h1>
                                    {val.description_details.map((item, i) => (
                                        <div key={i} className={styles.description_deatils}>
                                            <div className={styles.des_title}>{item.title} :</div>
                                            {Array.isArray(item.content) ? <div>
                                                {item.content.map((ct, i) => (
                                                    <div className={styles.des_content} key={i}>
                                                        {ct.content}
                                                    </div>
                                                ))}
                                            </div> : <div>
                                                <div className={styles.des_content}>{item.content}</div>
                                            </div>}
                                        </div>
                                    ))}
                                    <hr />
                                </div>
                            ))
                        } */}
              <div className={styles.kh}>
                <h2 className={styles.table_heading}>All Arts</h2>
                <Tables headData={headData} data={val.items} />
              </div>
              <br></br>
              <div className={styles.kh}>
                <h2 className={styles.table_heading}>
                  Artist Marketing Information
                </h2>
                <TableMarketing data={val.artist_marketing} />
              </div>
            </div>
          ))}
        {/* <Tables headData={headData} data={rows} /> */}
      </div>
      <ToastContainer />
    </>
  )
}
