import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Box, IconButton, MenuItem, Select } from "@mui/material"
// import Divider from '@mui/material/Divider';
import React, { useState } from "react"
import styles from "./Tables.module.css"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import moment from "moment"

export default function AdminTableExhibition(props) {
  
  const navigate = useNavigate()

  const loader = useSelector((state) => state.loader)

  const handleProfilepage = (id) => {
    const path = window.location.pathname
    if (path === "/artist") {
      navigate(`/artist/${id}`)
    } else if (path === "/exhibitions") {
      navigate(`/exhibitions/${id}`)
    } else if (path === "/auction") {
      navigate(`/auction/${id}`)
    }
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head.map((column, i) => (
                <TableCell
                  align="left"
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                  key={i}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props?.data?.map((row, i) => (
                  <TableRow
                    value={row.artist_name}
                    style={{ color: "#636365" }}
                    key={i}
                  >
                    {/* <Link to={`/artist/${row.id}`}> */}
                    {}

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      onClick={() => handleProfilepage(row._id)}
                      align="left"
                    >
                      {row?.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                      value={row.artist_name}
                    >
                      <Select
                        value=""
                        // onChange={handleChange}
                        displayEmpty
                        // fullWidth
                        sx={{
                          background: "#f6f6f6",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-root": {
                            borderRadius: "6px",
                          },
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "16px",
                          lineHeight: "21px",
                          color: "#636365",
                          height: "40px",
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">Artists Invited</MenuItem>
                        {row.artist_emails &&
                          row.artist_emails.map((val) => (
                            <MenuItem key={val._id}>{val}</MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.is_paid && row.is_paid === true ? "Paid" : "Free"}
                    </TableCell>
                    <TableCell
                      sx={{
                        // width:props.activetab==='enquiry'?'30%':'',
                        // width:'200',
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.start_date &&
                        moment(row.start_date).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.end_date &&
                        moment(row?.end_date)?.format("DD-MM-YYYY")}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* <Button
                        color={row.status ? "primary" : "primary"}
                        onClick={() => props.acceptReject(row._id, "Approved")}
                        variant="contained"
                        sx={{ width: "80px", mr: 2 }}
                      >
                        Accept
                      </Button>
                      <Button
                        color={row.status ? "primary" : "primary"}
                        onClick={() => props.acceptReject(row._id, "Rejected")}
                        variant="outlined"
                        sx={{ width: "80px", mr: 0 }}
                      >
                        Decline
                      </Button> */}
                      <IconButton onClick={() => props.handleDelete(row._id)}>
                        <img
                          src="/img/delete.png"
                          height="20px"
                          width="18px"
                          alt="img"
                        />
                      </IconButton>
                    </TableCell>

                    {/* </Link> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
