import React, { useEffect, useState } from "react"
import styles from "./Exhibitionmodal.module.css"
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Grid, MenuItem, Select } from "@mui/material"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import { useDispatch, useSelector } from "react-redux"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import TextField from "@mui/material/TextField"
import dayjs from "dayjs"
import { fetchUserArtist } from "../../../../../State/api/userApi"
const state = {
  option: [
    { name: "Option 1️⃣", id: 1 },
    { name: "Option 2️⃣", id: 2 },
  ],
}
export default function Exhibitionmodal(props) {
  console.log(props.curatorsData, "curatorsData----")
  const [radio, setRadio] = useState("")
  const [addData, setAddData] = useState({
    title: "",
    concept: "",
    start_date: "",
    end_date: "",
    end_date_registration: "",
    chief_guest: "",
    city: "",
    venue_address: "",
    price_per_art: 0,
    is_paid: false,
    artist_emails: [],
    curator_id: "",
  })
  const artistdata = useSelector((state) => state.userartistData)
  const a =
    artistdata &&
    artistdata.data
      .filter((val) => {
        return val.is_accepted === "Approved"
      })
      .map((vl) => {
        return vl.artist_name
      })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchUserArtist({}))
  }, [])
  // const handleChange = (e) => {
  //   const name = e.target.name
  //   const value = e.target.value
  //   setAddData({ ...addData, [name]: value })
  // }
  const handleChange = (e) => {
    const name = e.target.name
    let value = e.target.value

    if (name === "artist_emails") {
      value = value.split(",").map((email) => email.trim()) // Split the comma-separated emails into an array
    }

    setAddData({ ...addData, [name]: value })
  }
  const handleClose = () => props.setOpen(false)
  const handleChanges = (e) => {
    const name = e.target.name
    const value = e.target.value
    setAddData({ ...addData, [name]: value })
    console.log(addData)
  }
  const handleRadio = (e) => {
    if (e.target.value === "true") {
      setAddData({ ...addData, is_paid: true })
    } else {
      setAddData({ ...addData, is_paid: false })
    }
  }

  const handleStartDate = (start) => {
    setAddData({
      ...addData,
      start_date: dayjs(start).format("YYYY-MM-DD").toString(),
    })
  }
  const handleEndDate = (end) => {
    setAddData({
      ...addData,
      end_date: dayjs(end).format("YYYY-MM-DD").toString(),
    })
  }
  const handleEndAppDate = (endApp) => {
    setAddData({
      ...addData,
      end_date_registration: dayjs(endApp).format("YYYY-MM-DD").toString(),
    })
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_exhibition}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src={"/img/cancel_cross.png"}
                height="20px"
                width="20px"
                alt="img"
              />
            </div>
            <p
              className={styles.modal_heading}
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              Create Exhibition
            </p>
            <div className={styles.input_title}>
              <label>Exhibition Title</label>
              <br />
              <input
                onChange={handleChange}
                name="title"
                value={addData.title}
                placeholder="Enter Title"
                type="text"
              />
            </div>
            <div className={styles.textarea_div}>
              <label>Concept</label>
              <br />
              <textarea
                onChange={handleChange}
                name="concept"
                value={addData.concept}
                placeholder="Concept message"
                className={styles.textarea}
                rows={5}
              />
            </div>
            <div className={styles.input_title}>
              <label>End Date Receving Application</label>
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  //   disabled={addData.end_date === ""}
                  inputFormat="DD-MM-YYYY"
                  //   minDate={addData.start_date}
                  value={addData.end_date_registration}
                  onChange={handleEndAppDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        background: "#F3F3F3",
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Enter date",
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <TextField
                    sx={{
                        backgroundColor: "#f6f6f6",
                        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                        borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder="Enter Amount"
                    type="text"
                    name="end_date_registration"
                    value={addauction.end_date_registration}
                    onChange={handleChangetheme}
                /> */}
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{ display: "flex", justifyContent: "space-between" }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 22 }}
            >
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Start Date</label>
                <br />
                {/* <input onChange={handleChange} name='start_date' value={addData.start_date} placeholder={`Enter Start Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    inputFormat="DD-MM-YYYY"
                    value={addData.start_date}
                    onChange={handleStartDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          background: "#F3F3F3",
                          borderRadius: "6px",
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>End Date</label>
                <br />
                {/* <input onChange={handleChange} name='end_date' value={addData.end_date} placeholder={`Enter End Date`} type='text' /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    inputFormat="DD-MM-YYYY"
                    disabled={addData.start_date === ""}
                    value={addData.end_date}
                    onChange={handleEndDate}
                    minDate={addData.start_date}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          background: "#F3F3F3",
                          borderRadius: "6px",
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Enter date",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Cheif Guest</label>
                <br />
                <input
                  onChange={handleChange}
                  name="chief_guest"
                  value={addData.chief_guest}
                  placeholder={`Enter Cheif Guest Name`}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>City</label>
                <br />
                <input
                  onChange={handleChange}
                  name="city"
                  value={addData.city}
                  placeholder={`Enter City `}
                  type="text"
                />
              </Grid>
            </Grid>
            <div className={styles.input_title}>
              <label>Venue</label>
              <br />
              <input
                onChange={handleChange}
                name="venue_address"
                value={addData.venue_address}
                placeholder="Enter Venue"
                type="text"
              />
            </div>
            <div className={styles.radios_div}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="is_paid"
                  value={addData.is_paid}
                  defaultValue={addData.is_paid}
                  onClick={handleRadio}
                >
                  <FormControlLabel
                    value="false"
                    className={
                      addData.is_paid === false
                        ? styles.radio_active
                        : styles.radio_notactive
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                            {
                              color: "#636365",
                              backgroundColor: "#636365",
                              opacity: "0.2",
                              borderRadius: "50%",
                            },
                          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                            // color: 'blue',
                          },
                        }}
                        className={styles.radi}
                        size="small"
                      />
                    }
                    label="Free"
                  />
                  <FormControlLabel
                    value="true"
                    className={
                      addData.is_paid === true
                        ? styles.radio_active
                        : styles.radio_notactive
                    }
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                            {
                              color: "#636365",
                              backgroundColor: "#636365",
                              opacity: "0.2",
                              borderRadius: "50%",
                            },
                          "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
                            // color: 'blue',
                          },
                        }}
                        size="small"
                      />
                    }
                    label="Paid"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {addData.is_paid ? (
              <div className={styles.input_title}>
                <label>Price per atwork</label>
                <br />
                <input
                  onChange={handleChange}
                  name="price_per_art"
                  value={addData.price_per_art}
                  placeholder="Enter Amount"
                  type="text"
                />
              </div>
            ) : (
              ""
            )}
            <div className={styles.input_title}>
              {/* <FormControl fullWidth>
        <label id="select-mutli-artist">Invite Artist</label>
        {/* <FormControl sx={{ background: '#f6f6f6', height: '40px', }} fullWidth>
                                <InputLabel id="demo-multiple-name-label">Name</InputLabel> */}
              {/* <input onChange={handleChange} name='artist_emails' value={addData.artist_emails} placeholder='Enter Email id' type='email' /> */}
              <label>Invite Artist</label>
              <br />
              <input
                onChange={handleChange}
                name="artist_emails"
                value={addData.artist_emails}
                placeholder="Enter Email"
                type="text"
              />
            </div>

            <div className={styles.input_title}>
              <label className={styles.search_label}>
                Invite Specific Curator
              </label>
              <Select
                labelId="select-mutli-artist"
                id="demo-select-artist"
                value={addData.curator_id} // Single value (string)
                fullWidth
                sx={{
                  background: "#f6f6f6",
                  ".MuiOutlinedInput-notchedOutline": { border: "none" },
                  "& .MuiInputBase-root": {
                    borderRadius: "6px",
                  },
                  height: "40px",
                }}
                placeholder="select curator"
                onChange={handleChange} // Ensure handleChange updates addData.curator_id as a string
                name="curator_id" // Ensure you handle this in the state correctly
              >
                {props.curatorsData &&
                  props.curatorsData.data
                    .filter((fl) => fl.is_accepted === "Approved")
                    .map((val) => (
                      <MenuItem value={val._id} key={val._id}>
                        {val.first_name}
                      </MenuItem>
                    ))}
              </Select>
            </div>

            <div className={styles.action}>
              <Button
                onClick={() => props.handleAdd(addData)}
                variant="contained"
                className={styles.action_btn}
              >
                Save
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
