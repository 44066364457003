import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const AffiliatEarningSlice = createSlice({
  name: "affiliatedata",
  initialState,
  reducers: {
    setAffiliateEarningdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setAffiliateEarningdata } = AffiliatEarningSlice.actions

export default AffiliatEarningSlice.reducer
