import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:null,

}

const categorySlice = createSlice({
    name: 'categoryData',
    initialState,
    reducers: {
        categoryData: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { categoryData } = categorySlice.actions;

export const categoriesData = (state) => state.categoryData;

export default categorySlice.reducer;