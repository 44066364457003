import { Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./UserExhibition.module.css";
import Fivecolumntable from "../Tables/Fivecolumntable";
import Pagination from "../../../List/Pagination/Pagination";
import Tableheader from "../../../List/Table/Tableheader/Tableheader";


const head = [
   {
      id: "artist_name ",
      label: "Exhibition Title",
   },
   {
      id: "mobile_number",
      label: "Artist Name",
   },
   {
      id: "email",
      label: "Free/Paid",
   },
   {
      id: "assign_to",
      label: "Start Date",
   },
   {
      id: "type",
      label: "End Date",
   },
];

const data = [
   {
      id: 1,
      title: "The Life and Death",
      name: "Emily Cooper",
      paid: true,
      start_date: "12-04-2022",
      end_date: "16-04-2022"
   },
   {
      id: 2,
      title: "The Life and Death",
      name: "Emily Cooper",
      paid: false,
      start_date: "12-04-2022",
      end_date: "16-04-2022"
   },
   {
      id: 3,
      title: "The Life and Death",
      name: "Emily Cooper",
      paid: true,
      start_date: "12-04-2022",
      end_date: "16-04-2022"
   },
   {
      id: 4,
      title: "The Life and Death",
      name: "Emily Cooper",
      paid: true,
      start_date: "12-04-2022",
      end_date: "16-04-2022"
   },
]


export default function UserExhibition() {

   return (
      <>
         <div className={styles.mains}>
            <div className={styles.compoents}>
               <Tableheader />

               <Fivecolumntable
                  head={head}
                  data={data}
               />

               <Pagination />
            </div>
         </div>
      </>
   );
}
