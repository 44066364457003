import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Uploadimage from "../../../../curator/components/Common/uploadImage/Uploadimage";
import EntermobileArtist from "../../../../curator/pages/applyForm/mobileNumber/EntermobileArtist";
import styles from "./Personaldetailartist.module.css";
import homeImage from "../../../../constant";
import Formsubmission from "../../../../gallery/components/popups/formSubmit/Formsubmission";
import Header from "../../../../components/Pages/Header/Header";
import Navbar from "../../../../components/Pages/Navbar/Navbar";
import Footer from "../../../../components/Pages/Footer/Footer";
import ReactLanguageSelect from "react-languages-select";
import "react-languages-select/css/react-languages-select.css";

// import Autocomplete from '@mui/material/Autocomplete';
import * as locales from "@mui/material/locale";
import { useDispatch, useSelector } from "react-redux";
import { editArtistProfile } from "../../../../State/api/artistapi";
import { AlertsSuccess, Errornotify } from "../../../../common/alert/Alert";
import CircularLoader from "../../../../common/loader/Loader";
import { useNavigate } from "react-router-dom";
import { validationForArtistDetails } from "../../../../validation/validationForArtistDetails";
import validator from "validator";
import api from "../../../../State/api/Api";
const phoneLengthByCountry = {
  ad: 9, // Andorra
  af: 9, // Afghanistan
  al: 9, // Albania
  dz: 9, // Algeria
  ao: 9, // Angola
  ag: 10, // Antigua and Barbuda
  ar: 10, // Argentina
  am: 8, // Armenia
  aw: 7, // Aruba
  au: 9, // Australia
  at: 10, // Austria
  az: 9, // Azerbaijan
  bh: 8, // Bahrain
  bd: 10, // Bangladesh
  bn: 7, // Brunei
  bs: 10, // Bahamas
  bb: 10, // Barbados
  by: 9, // Belarus
  bq: 7, // Caribbean Netherlands
  be: 9, // Belgium
  bz: 7, // Belize
  bj: 8, // Benin
  bt: 8, // Bhutan
  bo: 8, // Bolivia
  ba: 8, // Bosnia and Herzegovina
  bw: 7, // Botswana
  br: 11, // Brazil
  bg: 9, // Bulgaria
  bf: 8, // Burkina Faso
  bi: 8, // Burundi
  kh: 9, // Cambodia
  cm: 9, // Cameroon
  ca: 10, // Canada
  cv: 7, // Cape Verde
  ci: 8, // Ivory Coast
  cw: 7, // Curaçao
  cg: 9, // Congo - Brazzaville
  cd: 9, // Congo - Kinshasa
  cf: 8, // Central African Republic
  td: 8, // Chad
  cl: 9, // Chile
  cn: 11, // China
  co: 10, // Colombia
  km: 7, // Comoros
  cr: 8, // Costa Rica
  hr: 9, // Croatia
  cu: 8, // Cuba
  cy: 8, // Cyprus
  cz: 9, // Czech Republic
  dk: 8, // Denmark
  dj: 8, // Djibouti
  dm: 10, // Dominica
  do: 10, // Dominican Republic
  ec: 9, // Ecuador
  eg: 10, // Egypt
  sv: 8, // El Salvador
  gq: 9, // Equatorial Guinea
  er: 7, // Eritrea
  ee: 7, // Estonia
  et: 9, // Ethiopia
  fj: 7, // Fiji
  fi: 9, // Finland
  fr: 9, // France
  ga: 7, // Gabon
  gm: 8, // Gambia
  gp: 9, // Guadeloupe
  gu: 10, // Guam
  ge: 9, // Georgia
  de: 10, // Germany
  gh: 9, // Ghana
  gr: 10, // Greece
  gd: 10, // Grenada
  gf: 9, // French Guiana
  gt: 8, // Guatemala
  gn: 9, // Guinea
  gw: 9, // Guinea-Bissau
  gy: 7, // Guyana
  ht: 8, // Haiti
  hn: 8, // Honduras
  hk: 8, // Hong Kong
  hu: 9, // Hungary
  is: 7, // Iceland
  in: 10, // India
  id: 10, // Indonesia
  io: 7, // British Indian Ocean Territory
  ir: 10, // Iran
  iq: 10, // Iraq
  ie: 9, // Ireland
  il: 9, // Israel
  it: 10, // Italy
  jm: 7, // Jamaica
  jp: 10, // Japan
  jo: 9, // Jordan
  kz: 10, // Kazakhstan
  kp: 9, // North Korea
  ke: 10, // Kenya
  ki: 7, // Kiribati
  ks: 8, // Kosovo
  kn: 7, // Saint Kitts and Nevis
  kw: 8, // Kuwait
  kg: 9, // Kyrgyzstan
  la: 9, // Laos
  lv: 8, // Latvia
  lb: 8, // Lebanon
  ls: 8, // Lesotho
  lr: 7, // Liberia
  ly: 9, // Libya
  li: 7, // Liechtenstein
  lt: 8, // Lithuania
  lu: 9, // Luxembourg
  lc: 7, // Saint Lucia
  mo: 8, // Macau
  mg: 10, // Madagascar
  mw: 9, // Malawi
  my: 10, // Malaysia
  mv: 7, // Maldives
  ml: 8, // Mali
  mk: 8, // Macedonia
  mq: 9, // Martinique
  mt: 8, // Malta
  mh: 7, // Marshall Islands
  mr: 8, // Mauritania
  mu: 8, // Mauritius
  mx: 10, // Mexico
  fm: 7, // Micronesia
  md: 8, // Moldova
  mc: 9, // Monaco
  mn: 8, // Mongolia
  me: 8, // Montenegro
  ma: 9, // Morocco
  mz: 9, // Mozambique
  mm: 8, // Myanmar
  na: 9, // Namibia
  nc: 8, // New Caledonia
  nr: 7, // Nauru
  np: 10, // Nepal
  nl: 9, // Netherlands
  nz: 9, // New Zealand
  ni: 8, // Nicaragua
  ne: 8, // Niger
  ng: 10, // Nigeria
  no: 8, // Norway
  om: 8, // Oman
  pk: 10, // Pakistan
  pw: 7, // Palau
  pa: 7, // Panama
  pf: 8, // French Polynesia
  pg: 8, // Papua New Guinea
  py: 9, // Paraguay
  pe: 9, // Peru
  ph: 10, // Philippines
  pl: 9, // Poland
  pt: 9, // Portugal
  ps: 9, // Palestine
  pr: 10, // Puerto Rico
  qa: 8, // Qatar
  ro: 10, // Romania
  ru: 10, // Russia
  re: 9, // Reunion
  rw: 9, // Rwanda
  ws: 7, // Samoa
  sa: 9, // Saudi Arabia
  sn: 9, // Senegal
  sm: 9, // San Marino
  st: 7, // Sao Tome and Principe
  ss: 9, // South Sudan
  sz: 8, // Swaziland
  rs: 9, // Serbia
  sc: 7, // Seychelles
  sl: 8, // Sierra Leone
  sg: 8, // Singapore
  sk: 9, // Slovakia
  si: 8, // Slovenia
  sb: 7, // Solomon Islands
  so: 7, // Somalia
  za: 9, // South Africa
  kr: 10, // South Korea
  es: 9, // Spain
  lk: 9, // Sri Lanka
  sd: 9, // Sudan
  sr: 7, // Suriname
  se: 9, // Sweden
  ch: 9, // Switzerland
  sy: 9, // Syria
  tw: 9, // Taiwan
  tj: 9, // Tajikistan
  tz: 9, // Tanzania
  th: 9, // Thailand
  tl: 7, // Timor-Leste
  tg: 8, // Togo
  to: 7, // Tonga
  tt: 10, // Trinidad and Tobago
  tn: 8, // Tunisia
  tr: 10, // Turkey
  tm: 8, // Turkmenistan
  tv: 6, // Tuvalu
  ug: 9, // Uganda
  ua: 9, // Ukraine
  vc: 7, // Saint Vincent and the Grenadines
  va: 9, // Vatican CityS
  ae: 9, // United Arab Emirates
  gb: 10, // United Kingdom
  us: 10, // United States
  uy: 9, // Uruguay
  uz: 9, // Uzbekistan
  vu: 7, // Vanuatu
  ve: 7, // Venezuela
  vn: 10, // Vietnam
  ye: 9, // Yemen
  zm: 9, // Zambia
  zw: 9, // Zimbabwe
};

const data = [
  {
    type: "text",
    label: "What is your first name? *",
    placeholder: "Enter text",
    name: "first_name",
  },
  {
    type: "text",
    label: "What is your last name? *",
    placeholder: "Enter text",
    name: "last_name",
  },
  {
    type: "email",
    label: "What is your email address? *",
    placeholder: "Enter text",
    name: "email",
  },
  {
    type: "number",
    label: "What is your phone number? *",
    placeholder: "Enter text",
    name: "phone",
  },
];
const country = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];
const nationalities = [
  { code: "AD", label: "Andorran" },
  { code: "AE", label: "Emirati" },
  { code: "AF", label: "Afghan" },
  { code: "AG", label: "Antiguan or Barbudan" },
  { code: "AI", label: "Anguillian" },
  { code: "AL", label: "Albanian" },
  { code: "AM", label: "Armenian" },
  { code: "AO", label: "Angolan" },
  { code: "AQ", label: "Antarctican" },
  { code: "AR", label: "Argentine" },
  { code: "AS", label: "American Samoan" },
  { code: "AT", label: "Austrian" },
  { code: "AU", label: "Australian" },
  { code: "AW", label: "Aruban" },
  { code: "AX", label: "Ålander" },
  { code: "AZ", label: "Azerbaijani" },
  { code: "BA", label: "Bosnian" },
  { code: "BB", label: "Barbadian" },
  { code: "BD", label: "Bangladeshi" },
  { code: "BE", label: "Belgian" },
  { code: "BF", label: "Burkinabe" },
  { code: "BG", label: "Bulgarian" },
  { code: "BH", label: "Bahraini" },
  { code: "BI", label: "Burundian" },
  { code: "BJ", label: "Beninese" },
  { code: "BL", label: "Barthélemois" },
  { code: "BM", label: "Bermudian" },
  { code: "BN", label: "Bruneian" },
  { code: "BO", label: "Bolivian" },
  { code: "BR", label: "Brazilian" },
  { code: "BS", label: "Bahamian" },
  { code: "BT", label: "Bhutanese" },
  { code: "BV", label: "Bouvet Islander" },
  { code: "BW", label: "Botswana" },
  { code: "BY", label: "Belarusian" },
  { code: "BZ", label: "Belizean" },
  { code: "CA", label: "Canadian" },
  { code: "CC", label: "Cocos Islander" },
  { code: "CD", label: "Congolese (DRC)" },
  { code: "CF", label: "Central African" },
  { code: "CG", label: "Congolese (Republic)" },
  { code: "CH", label: "Swiss" },
  { code: "CI", label: "Ivorian" },
  { code: "CK", label: "Cook Islander" },
  { code: "CL", label: "Chilean" },
  { code: "CM", label: "Cameroonian" },
  { code: "CN", label: "Chinese" },
  { code: "CO", label: "Colombian" },
  { code: "CR", label: "Costa Rican" },
  { code: "CU", label: "Cuban" },
  { code: "CV", label: "Cape Verdean" },
  { code: "CW", label: "Curaçaoan" },
  { code: "CX", label: "Christmas Islander" },
  { code: "CY", label: "Cypriot" },
  { code: "CZ", label: "Czech" },
  { code: "DE", label: "German" },
  { code: "DJ", label: "Djiboutian" },
  { code: "DK", label: "Danish" },
  { code: "DM", label: "Dominican" },
  { code: "DO", label: "Dominican" },
  { code: "DZ", label: "Algerian" },
  { code: "EC", label: "Ecuadorian" },
  { code: "EE", label: "Estonian" },
  { code: "EG", label: "Egyptian" },
  { code: "EH", label: "Western Saharan" },
  { code: "ER", label: "Eritrean" },
  { code: "ES", label: "Spanish" },
  { code: "ET", label: "Ethiopian" },
  { code: "FI", label: "Finnish" },
  { code: "FJ", label: "Fijian" },
  { code: "FK", label: "Falkland Islander" },
  { code: "FM", label: "Micronesian" },
  { code: "FO", label: "Faroe Islander" },
  { code: "FR", label: "French" },
  { code: "GA", label: "Gabonese" },
  { code: "GB", label: "British" },
  { code: "GD", label: "Grenadian" },
  { code: "GE", label: "Georgian" },
  { code: "GF", label: "French Guianese" },
  { code: "GG", label: "Guernsey" },
  { code: "GH", label: "Ghanaian" },
  { code: "GI", label: "Gibraltarian" },
  { code: "GL", label: "Greenlandic" },
  { code: "GM", label: "Gambian" },
  { code: "GN", label: "Guinean" },
  { code: "GP", label: "Guadeloupean" },
  { code: "GQ", label: "Equatorial Guinean" },
  { code: "GR", label: "Greek" },
  { code: "GS", label: "South Georgian" },
  { code: "GT", label: "Guatemalan" },
  { code: "GU", label: "Guamanian" },
  { code: "GW", label: "Bissau-Guinean" },
  { code: "GY", label: "Guyanese" },
  { code: "HK", label: "Hong Konger" },
  { code: "HM", label: "Heard Islander" },
  { code: "HN", label: "Honduran" },
  { code: "HR", label: "Croatian" },
  { code: "HT", label: "Haitian" },
  { code: "HU", label: "Hungarian" },
  { code: "ID", label: "Indonesian" },
  { code: "IE", label: "Irish" },
  { code: "IL", label: "Israeli" },
  { code: "IM", label: "Manx" },
  { code: "IN", label: "Indian" },
  { code: "IO", label: "British Indian Ocean Territory" },
  { code: "IQ", label: "Iraqi" },
  { code: "IR", label: "Iranian" },
  { code: "IS", label: "Icelander" },
  { code: "IT", label: "Italian" },
  { code: "JE", label: "Jerseyman" },
  { code: "JM", label: "Jamaican" },
  { code: "JO", label: "Jordanian" },
  { code: "JP", label: "Japanese" },
  { code: "KE", label: "Kenyan" },
  { code: "KG", label: "Kyrgyzstani" },
  { code: "KH", label: "Cambodian" },
  { code: "KI", label: "Kiribati" },
  { code: "KM", label: "Comoran" },
  { code: "KN", label: "Kittitian or Nevisian" },
  { code: "KP", label: "North Korean" },
  { code: "KR", label: "South Korean" },
  { code: "KW", label: "Kuwaiti" },
  { code: "KY", label: "Caymanian" },
  { code: "KZ", label: "Kazakh" },
  { code: "LA", label: "Laotian" },
  { code: "LB", label: "Lebanese" },
  { code: "LC", label: "Saint Lucian" },
  { code: "LI", label: "Liechtensteiner" },
  { code: "LK", label: "Sri Lankan" },
  { code: "LR", label: "Liberian" },
  { code: "LS", label: "Basotho" },
  { code: "LT", label: "Lithuanian" },
  { code: "LU", label: "Luxembourger" },
  { code: "LV", label: "Latvian" },
  { code: "LY", label: "Libyan" },
  { code: "MA", label: "Moroccan" },
  { code: "MC", label: "Monegasque" },
  { code: "MD", label: "Moldovan" },
  { code: "ME", label: "Montenegrin" },
  { code: "MF", label: "Saint Martin" },
  { code: "MG", label: "Malagasy" },
  { code: "MH", label: "Marshallese" },
  { code: "MK", label: "Macedonian" },
  { code: "ML", label: "Malian" },
  { code: "MM", label: "Burmese" },
  { code: "MN", label: "Mongolian" },
  { code: "MO", label: "Macao" },
  { code: "MP", label: "Northern Mariana Islander" },
  { code: "MQ", label: "Martinican" },
  { code: "MR", label: "Mauritanian" },
  { code: "MS", label: "Montserratian" },
  { code: "MT", label: "Maltese" },
  { code: "MU", label: "Mauritian" },
  { code: "MV", label: "Maldivian" },
  { code: "MW", label: "Malawian" },
  { code: "MX", label: "Mexican" },
  { code: "MY", label: "Malaysian" },
  { code: "MZ", label: "Mozambican" },
  { code: "NA", label: "Namibian" },
  { code: "NC", label: "New Caledonian" },
  { code: "NE", label: "Nigerien" },
  { code: "NF", label: "Norfolk Islander" },
  { code: "NG", label: "Nigerian" },
  { code: "NI", label: "Nicaraguan" },
  { code: "NL", label: "Dutch" },
  { code: "NO", label: "Norwegian" },
  { code: "NP", label: "Nepali" },
  { code: "NR", label: "Nauruan" },
  { code: "NU", label: "Niuean" },
  { code: "NZ", label: "New Zealander" },
  { code: "OM", label: "Omani" },
  { code: "PA", label: "Panamanian" },
  { code: "PE", label: "Peruvian" },
  { code: "PF", label: "French Polynesian" },
  { code: "PG", label: "Papua New Guinean" },
  { code: "PH", label: "Filipino" },
  { code: "PK", label: "Pakistani" },
  { code: "PL", label: "Polish" },
  { code: "PM", label: "Saint-Pierrais or Miquelonnais" },
  { code: "PN", label: "Pitcairn Islander" },
  { code: "PR", label: "Puerto Rican" },
  { code: "PT", label: "Portuguese" },
  { code: "PW", label: "Palauan" },
  { code: "PY", label: "Paraguayan" },
  { code: "QA", label: "Qatari" },
  { code: "RE", label: "Réunionese" },
  { code: "RO", label: "Romanian" },
  { code: "RS", label: "Serbian" },
  { code: "RU", label: "Russian" },
  { code: "RW", label: "Rwandan" },
  { code: "SA", label: "Saudi Arabian" },
  { code: "SB", label: "Solomon Islander" },
  { code: "SC", label: "Seychellois" },
  { code: "SD", label: "Sudanese" },
  { code: "SE", label: "Swedish" },
  { code: "SG", label: "Singaporean" },
  { code: "SH", label: "Saint Helenian" },
  { code: "SI", label: "Slovenian" },
  { code: "SJ", label: "Svalbardian" },
  { code: "SK", label: "Slovak" },
  { code: "SL", label: "Sierra Leonean" },
  { code: "SM", label: "Sammarinese" },
  { code: "SN", label: "Senegalese" },
  { code: "SO", label: "Somali" },
  { code: "SR", label: "Surinamese" },
  { code: "SS", label: "South Sudanese" },
  { code: "ST", label: "Sao Tomean" },
  { code: "SV", label: "Salvadoran" },
  { code: "SX", label: "Sint Maarten" },
  { code: "SY", label: "Syrian" },
  { code: "SZ", label: "Swazi" },
  { code: "TC", label: "Turks and Caicos Islander" },
  { code: "TD", label: "Chadian" },
  { code: "TF", label: "French Southern and Antarctic Lands" },
  { code: "TG", label: "Togolese" },
  { code: "TH", label: "Thai" },
  { code: "TJ", label: "Tajikistani" },
  { code: "TK", label: "Tokelauan" },
  { code: "TL", label: "Timorese" },
  { code: "TM", label: "Turkmen" },
  { code: "TN", label: "Tunisian" },
  { code: "TO", label: "Tongan" },
  { code: "TR", label: "Turkish" },
  { code: "TT", label: "Trinidadian" },
  { code: "TV", label: "Tuvaluan" },
  { code: "TZ", label: "Tanzanian" },
  { code: "UA", label: "Ukrainian" },
  { code: "UG", label: "Ugandan" },
  { code: "UM", label: "U.S. Minor Outlying Islands" },
  { code: "UN", label: "United Nations" },
  { code: "US", label: "American" },
  { code: "UY", label: "Uruguayan" },
  { code: "UZ", label: "Uzbekistani" },
  { code: "VA", label: "Vatican" },
  { code: "VC", label: "Saint Vincentian" },
  { code: "VE", label: "Venezuelan" },
  { code: "VG", label: "British Virgin Islander" },
  { code: "VI", label: "American Virgin Islander" },
  { code: "VN", label: "Vietnamese" },
  { code: "VU", label: "Vanuatuan" },
  { code: "WF", label: "Wallis and Futunan" },
  { code: "WS", label: "Samoan" },
  { code: "YE", label: "Yemeni" },
  { code: "YT", label: "Mayottean" },
  { code: "ZA", label: "South African" },
  { code: "ZM", label: "Zambian" },
  { code: "ZW", label: "Zimbabwean" },
];

export default function Personaldetail({
  apply,
  setApply,
  handleChangeApplyData,
}) {
  const navigate = useNavigate();

  const [image, setImage] = useState("");
  const [src, setSrc] = useState({
    profile: "",
    cover: "",
    bestartwork: "",
    id: "",
  });
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});

  console.log(errors,'errors')
  const [locale, setLocale] = useState("zhCN");
  const [value, setValue] = React.useState(null);
  const loader = useSelector((state) => state.loader);
  const dispatch = useDispatch();
  const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  console.log(error,'error')

  const handleChangeNumber = (value, country) => {
    // Get country code and expected length
    const countryCode = country.countryCode; // e.g., 'in'
    const dialCode = country.dialCode; // e.g., '91'
    const expectedLength = phoneLengthByCountry[countryCode]; // e.g., 10 for India
  
    // Clean the phone number input, stripping non-numeric characters
    let phoneNumber = value.replace(/[^0-9]/g, ""); 
  
    // Remove the dial code from the phone number before validation
    if (phoneNumber.startsWith(dialCode)) {
      phoneNumber = phoneNumber.slice(dialCode.length);
    }
  
    // Limit the input to the expected length
    if (expectedLength && phoneNumber.length === expectedLength) {
      phoneNumber = phoneNumber.slice(0, expectedLength);
    }
  
    // Construct the formatted value with the country dial code
    const formattedValue = `+${dialCode}${phoneNumber}`;
  
    // Update the phone state with the formatted value
    setApply({...apply, phone:formattedValue});
  
    // Validate length for error display
    if (expectedLength && phoneNumber.length !== expectedLength) {
      setError(`Phone number must be ${expectedLength} digits long for this country code`);
    } else {
      setError(""); // Clear error if the length is valid
    }
  };
  

  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileUrl = URL.createObjectURL(file);

      if (name === "id") {
        // Check if file is a PDF
       
        const allowedTypes = [
          "application/pdf", 
          "application/msword", // For .doc files
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx files
          "image/jpeg", 
          "image/png" 
        ];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (!allowedTypes.includes(file.type)) {
          Errornotify("Please upload a file in PDF, Word, or image format (JPG, JPEG, PNG)");
          e.target.value = "";
        } else if (fileSizeInMB > 2) {
          Errornotify("File size should be less than 2MB");
          e.target.value = "";
        } else {
          setSrc({ ...src, id: fileUrl });
          setApply({ ...apply, ID_proof: file });
        }

        // Check if file size exceeds 500KB
      } else if (name === "profile_image") {
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 2 * 1024 * 1024) {
          // Check if the file size exceeds 1 MB
          Errornotify("Please upload an image less than 2MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          setSrc({ ...src, profile: fileUrl });
          setApply({ ...apply, image: file });
        }
      } else if (name === "cover") {
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 2 * 1024 * 1024) {
          // Check if the file size exceeds 1 MB
          Errornotify("Please upload an image less than 2MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          setSrc({ ...src, cover: fileUrl });
          setApply({ ...apply, backgroundImage: file });
        }
      } else {
        if (file.type !== "image/png" && file.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid file type
        } else if (file.size > 2 * 1024 * 1024) {
          // Check if the file size exceeds 1 MB
          Errornotify("Please upload an image less than 2MB");
          e.target.value = ""; // Clear input field for large file
        } else {
          setSrc({ ...src, bestartwork: fileUrl });
          setApply({ ...apply, art_work: file });
        }
      }

      setImage(file);
    }
  };


  const [email_errors, setEmail_Errors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);
 
  const handleCheckEmailExistOrNot = async () => {
    const {data} = await api.applicationApi("api/user/checkEmailIsExistOrNot", "POST", {email: apply.email, role : "artist"})
    console.log(data,'data')
    if(data.success === false) {
      setEmail_Errors({...email_errors, email: "This email is already registered. Please use a different email address."})
      setIsEmailValid(false);
    }else {
      setEmail_Errors({...email_errors, email: ""})
      setIsEmailValid(true);
    }
  }

  const handleEmailValidation = (email) => {
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
 
  useEffect(() => {
   if ( apply.email && handleEmailValidation(apply.email)) {
      handleCheckEmailExistOrNot();
    }
  }, [apply.email]);
  const handleClickSubmit = () => {
   
    const { artistErrors, isValidArtistDetails } = validationForArtistDetails(
      apply.first_name,
      apply.last_name,
      apply.email,
      apply.phone,
      apply.country,
      apply.nationality,
      apply.language,
      apply.ID_proof,
      apply.image,
      apply.backgroundImage,
       apply.completion
    );
    if (isValidArtistDetails && Object.keys(artistErrors).length === 0 && isEmailValid) {
      dispatch(editArtistProfile(apply, navigate));
      setErrors({});
      setError("");
      setEmail_Errors({})
    } else {
      setError(error)
      setErrors(artistErrors);
    
      setEmail_Errors(email_errors);
      
    }
  };
  // useEffect(() => {
  //   if (
  //     apply.is_featured === false ||
  //     apply.portfolio === "" ||
  //     apply.art_work === "" ||
  //     apply.exp === "" ||
  //     apply.art_price_range.length === 0 ||
  //     apply.arts_solds_in_past_12_months === 0 ||
  //     apply.produce_art_each_month === 0 ||
  //     apply.promoting_by_reason.length === 0 ||
  //     apply.online_art_gallary_URL === "" ||
  //     apply.category_id.length === 0
  //   ) {
  //     //  navigate("/artsmiley/artist/fillquestion")
  //   }
  // }, [])

  const languages = [
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
    { code: "ar", name: "Arabic" },
    { code: "es", name: "Spanish" },
    { code: "fr", name: "French" },
    { code: "de", name: "German" },
    { code: "zh", name: "Chinese" },
    { code: "ja", name: "Japanese" },
    { code: "ru", name: "Russian" },
    { code: "pt", name: "Portuguese" },
    { code: "it", name: "Italian" },
    { code: "ko", name: "Korean" },
    { code: "tr", name: "Turkish" },
    { code: "vi", name: "Vietnamese" },
    { code: "nl", name: "Dutch" },
    { code: "sv", name: "Swedish" },
    { code: "pl", name: "Polish" },
    { code: "uk", name: "Ukrainian" },
    { code: "el", name: "Greek" },
    { code: "he", name: "Hebrew" },
    { code: "th", name: "Thai" },
    { code: "cs", name: "Czech" },
    { code: "fi", name: "Finnish" },
    { code: "no", name: "Norwegian" },
    { code: "da", name: "Danish" },
    { code: "hu", name: "Hungarian" },
    { code: "ro", name: "Romanian" },
    { code: "sk", name: "Slovak" },
    { code: "bg", name: "Bulgarian" },
    { code: "hr", name: "Croatian" },
    { code: "sr", name: "Serbian" },
    { code: "lt", name: "Lithuanian" },
    { code: "lv", name: "Latvian" },
    { code: "et", name: "Estonian" },
    { code: "sl", name: "Slovenian" },
    { code: "is", name: "Icelandic" },
    { code: "ga", name: "Irish" },
    { code: "mt", name: "Maltese" },
    { code: "cy", name: "Welsh" },
    { code: "sq", name: "Albanian" },
    { code: "mk", name: "Macedonian" },
    { code: "bs", name: "Bosnian" },
    { code: "ms", name: "Malay" },
    { code: "sw", name: "Swahili" },
    { code: "am", name: "Amharic" },
    { code: "fa", name: "Persian" },
    { code: "ur", name: "Urdu" },
    { code: "bn", name: "Bengali" },
    { code: "ta", name: "Tamil" },
    { code: "te", name: "Telugu" },
    { code: "mr", name: "Marathi" },
    { code: "gu", name: "Gujarati" },
    { code: "kn", name: "Kannada" },
    { code: "ml", name: "Malayalam" },
    { code: "pa", name: "Punjabi" },
    { code: "si", name: "Sinhala" },
    { code: "ne", name: "Nepali" },
    { code: "km", name: "Khmer" },
    { code: "my", name: "Burmese" },
    { code: "lo", name: "Lao" },
    { code: "mn", name: "Mongolian" },
    { code: "kk", name: "Kazakh" },
    { code: "uz", name: "Uzbek" },
    { code: "hy", name: "Armenian" },
    { code: "az", name: "Azerbaijani" },
    { code: "ka", name: "Georgian" },
    // Add more languages as needed
  ];
  return (
    <>
      <AlertsSuccess />
      <Header />
      <Navbar />
      <div className={styles.mains}>
        {open ? <Formsubmission open={open} setOpen={setOpen} /> : ""}
        <div className={styles.components}>
          <h1>Personal Details</h1>

          <div>
            {/* <div className={styles.main_divs}>
                  <label className={styles.input_label}>{val.label}</label>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name={val.name}
                    sx={{ display: "flex", flexDirection: "row", mt: 0.8 }}
                    className={styles.radio_button}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </div> */}

            <div className={styles.main_divs}>
              <label className={styles.input_label}>
                What is your first name? *
              </label>
              <TextField
                className={styles.input_top}
                placeholder="Enter your first name"
                name="first_name"
                value={apply.first_name || ""} // Bind the value to state to control input
                onChange={(e) => {
                  // Only update state if input is alphabetic characters
                  if (/^[a-zA-Z]*$/.test(e.target.value)) {
                    setApply({ ...apply, first_name: e.target.value });
                  }
                }}
              variant="standard"
                sx={{
                 
                  mt: 0.8,
                  pb: errors?.first_name ? 0.5 : 0,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                  
                  },
                }}
                fullWidth
              />
              {errors?.first_name && !apply.first_name ? (
                <span
                  style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                >
                  {errors?.first_name}
                </span>
              ) : null}
            </div>
            <div className={styles.main_divs}>
              <label className={styles.input_label}>
                What is your last name? *
              </label>
              <TextField
                className={styles.input_top}
                placeholder="Enter your last name"
                name="last_name"
                value={apply.last_name || ""} // Bind the value to state to control input
                onChange={(e) => {
                  // Only update state if input is alphabetic characters
                  if (/^[a-zA-Z]*$/.test(e.target.value)) {
                    setApply({ ...apply, last_name: e.target.value });
                  }
                }}
              variant="standard"
                sx={{
                 
                  mt: 0.8,
                  pb: errors?.last_name ? 0.5 : 0,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                    // '&:hover fieldset': {
                    //     borderColor: 'yellow',
                    // },
                    // '&.Mui-focused fieldset': {
                    //     borderColor: 'green',
                    // },
                  },
                }}
                fullWidth
              />
              {errors?.last_name && !apply.last_name ? (
                <span
                  style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
                >
                  {errors?.last_name}
                </span>
              ) : null}
            </div>
            <div className={styles.main_divs}>
              <label className={styles.input_label}>
                What is your email address? *
              </label>
              <TextField
                className={styles.input_top}
                placeholder="Enter your email address"
                name="email"
                onChange={handleChangeApplyData}
              variant="standard"
                sx={{
                 
                  mt: 0.8,
                  pb: errors?.email ? 0.5 : 0,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1px solid #665D6F",
                      opacity: 0.5,
                    },
                   
                  },
                }}
                fullWidth
              />
           {errors?.email && (
  <span
    style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
  >
    {errors.email}
  </span>
)}

{!errors?.email &&  email_errors?.email && (
  <span
    style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
  >
    {email_errors.email}
  </span>
)}

            </div>
          </div>
          <label className={styles.input_label}>
            What is your phone number?*
          </label>
          <EntermobileArtist
            value={apply.phone}
            handleChangeNumber={(value, country) =>
              handleChangeNumber(value, country)
            }
          />
          {errors?.phone  ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {errors?.phone}
            </span>
          ) : null}
          {!errors?.phone && error && (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {error}
            </span>
          )}

          <div className={styles.main_divs}>
            <lable className={styles.input_label}>
              What is your country of residence? *
            </lable>
            <br />
            <Autocomplete
              id="combo-box-demo"
              disableClearable
              options={country.map((vl) => vl.label)}
              name="country"
              value={apply.country}
              onChange={(event, newValue) => {
                setApply({ ...apply, country: newValue });
              }}
              sx={{
                width: "100%",
                mt: 0.8,
                backgroundColor: "#f6f6f6",
                border: "none",
              }}
              className={styles.search_select}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ border: "none", color: "#636365" }}
                  placeholder="Search"
                />
              )}
            />
            {errors?.country && !apply.country ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {errors?.country}
              </span>
            ) : null}
          </div>
          <div className={styles.main_divs}>
            <label className={styles.input_label}>
              What is your Nationality? *
            </label>
            <br />
            <Autocomplete
              id="combo-box-demo"
              disableClearable
              options={nationalities.map((vl) => vl.label)}
              name="nationality"
              value={apply.nationality}
              onChange={(event, newValue) => {
                setApply({ ...apply, nationality: newValue });
              }}
              sx={{
                width: "100%",
                mt: 0.8,
                backgroundColor: "#f6f6f6",
                border: "none",
              }}
              className={styles.search_select}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ border: "none", color: "#636365" }}
                  placeholder="Search"
                />
              )}
            />
            {errors?.nationality && !apply.nationality ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {errors?.nationality}
              </span>
            ) : null}
          </div>

          <div className={styles.input_label}>
            What is your preferred language? *
          </div>
         
          <Autocomplete
            options={languages}
            getOptionLabel={(option) => `${option.name}`}
            name="language"
            value={
              apply.language
                ? languages.find((lang) => lang.name === apply.language)
                : null
            }
            disableClearable // Correcting the misspelling here
            onChange={(event, newValue) => {
              setApply({ ...apply, language: newValue ? newValue.name : "" });
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth variant="outlined" />
            )}
          />
          {errors?.language ? (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {errors?.language}
            </span>
          ) : null}
        
          <div className={styles.upload_img}>
            <div className={styles.input_label}>
              Upload your ID*{" "}
              <span style={{ fontSize: "15px" }}>
                (file format should be in pdf, Word or image, size: 2MB, Aadhaar, Passport, Driving License, etc.)
              </span>
            </div>
            <div className={styles.preview_img}>
              <input
               accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
                name="id"
                id="id"
                onChange={handleChangeImage}
                type="file"
                className={styles.preview_img_choose}
              />
            </div>
            {errors?.ID_proof && !apply.ID_proof?.name ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {errors?.ID_proof}
              </span>
            ) : null}
          </div>

          <div className={styles.upload_img}>
            <div className={styles.input_label}>
              Upload your Profile Image*{" "}
              <span style={{ fontSize: "15px" }}>
                (file format: PNG/JPEG, dimension: 120 x 120, size: 2MB)
              </span>
            </div>
            <div className={styles.preview_img} style={{width:'150px',height:"150px"}}>
              {src.profile === "" ? (
                <div className={styles.img_box} style={{width:'150px',height:'150px'}}>
                  <div
                    htmlFor="input"
                    style={{ textAlign: "center" }}
                    onClick={handleChangeImage}
                  >
                    <input
                      name="profile_image"
                      onChange={handleChangeImage}
                      type="file"
                      id="profile_image"
                      hidden
                      accept="image/*"
                    />
                    <label htmlFor="profile_image" className={styles.label}>
                      <img
                        name="profile_image"
                        src={"/img/uploadimg.png"}
                        width="46px"
                        height="43px"
                        alt="img"
                      />
                      <p>Upload Your Profile Image</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <input
                    onChange={handleChangeImage}
                    name="profile_image"
                    type="file"
                    id="profile_image"
                    hidden
                    accept="image/*"
                  />
                  <label htmlFor="profile_image">
                    <img
                      src={src.profile}
                      alt="img"
                      id="img"
                      style={{width:"150px",height:"150px"}}
                      className={image ? styles.img_view : styles.img_view_hide}
                    />
                  </label>
                </>
              )}
              <div className={styles.file_div}></div>
            </div>
            {errors?.image && !apply.image?.name ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {errors?.image}
              </span>
            ) : null}
          </div>
         
          <div className={styles.upload_img}>
            <div className={styles.input_label}>
              Upload your Cover Image*{" "}
              <span style={{ fontSize: "15px" }}>
                (file format: PNG/JPEG, dimension: 1520 x 233, size: 2MB)
              </span>
            </div>
            <div className={styles.preview_img} style={{height:"250px"}}>
              {src.cover === "" ? (
                <div className={styles.img_box} style={{height:"250px"}}>
                  <div
                    style={{ textAlign: "center" }}
                    onClick={handleChangeImage}
                  >
                    <input
                      name="cover"
                      onChange={handleChangeImage}
                      type="file"
                      id="cover"
                      hidden
                      accept="image/*"
                    />
                    <label htmlFor="cover" className={styles.label}>
                      <img
                        name="cover"
                        src={"/img/uploadimg.png"}
                        width="46px"
                        height="43px"
                        alt="img"
                      />
                      <p>Upload Your Cover Image</p>
                    </label>
                  </div>
                </div>
              ) : (
                <>
                
                  <input
                    onChange={handleChangeImage}
                    name="cover"
                    type="file"
                    id="cover"
                    hidden
                    accept="image/*"
                  />
                  <label htmlFor="cover">
                    <img
                      src={src.cover}
                      alt="img"
                      id="img"
                      style={{height:"100%" }}
                      className={image ? styles.img_view : styles.img_view_hide}
                    />
                  </label>
                </>
              )}
              <div className={styles.file_div}></div>
            </div>
            {errors?.background_image && !apply.backgroundImage?.name ? (
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
              >
                {errors?.background_image}
              </span>
            ) : null}
          </div>
          <div className={styles.main_divs}>
            <label className={styles.input_label}>
              I confirm that the information given in this form is true,
              complete and accurate.*
            </label>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={apply.completion}
              onClick={handleChangeApplyData}
              name="completion"
              sx={{ display: "flex", flexDirection: "row", mt: 0.8 }}
              className={styles.radio_button}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
            {errors?.completion ? (
    <span style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>
      {errors?.completion}
    </span>
  ) : null}
          </div>
         
          <div className={styles.btn_div}>
            {loader.loader ? (
              <CircularLoader />
            ) : (
              <Button
                onClick={() => {
                
                  
                    handleClickSubmit();
                 
                }}
                variant="contained"
                sx={{ width: "156px", height: "54px", borderRadius: "10px" }}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
