import { ThemeProvider } from "@emotion/react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  createTheme,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import React, { useEffect } from "react";
import styles from "./ArtistList.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import homeImage from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserArtist } from "../../../State/api/userApi";
import CircularLoader from "../../../common/loader/Loader";
import serverimage from "../../../serverConstant";
import { Checkbox } from "@mui/material";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import ArtistSidebar from "./ArtistSidebar";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "14px",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
});
const cardDetails = [
  {
    id: 1,
    artImg: "/img/Rectangle 22.png",
    artistImg: "/img/Ellipse 10.png",
    profession: "Photographer",
  },
  {
    id: 2,
    artImg: "/img/Rectangle 26.png",
    artistImg: "/img/Ellipse 12.png",
    profession: "Sculptor",
  },
  {
    id: 3,
    artImg: "/img/Rectangle 30.png",
    artistImg: "/img/Ellipse 14.png",
    profession: "Painter",
  },
  {
    id: 4,
    artImg: "/img/Rectangle 34.png",
    artistImg: "/img/Ellipse 16.png",
    profession: "Photographer",
  },
  {
    id: 5,
    artImg: "/img/Rectangle 38.png",
    artistImg: "/img/Ellipse 18.png",
    profession: "Sculptor",
  },
  {
    id: 6,
    artImg: "/img/Rectangle 42.png",
    artistImg: "/img/Ellipse 20.png",
    profession: "Painter",
  },
  {
    id: 7,
    artImg: "/img/Rectangle 46.png",
    artistImg: "/img/Ellipse 22.png",
    profession: "Photographer",
  },
  {
    id: 8,
    artImg: "/img/Rectangle 50.png",
    artistImg: "/img/Ellipse 24.png",
    profession: "Sculptor",
  },
  {
    id: 9,
    artImg: "/img/Rectangle 54.png",
    artistImg: "/img/Ellipse 26.png",
    profession: "Painter",
  },
  {
    id: 10,
    artImg: "/img/Rectangle 58.png",
    artistImg: "/img/Ellipse 28.png",
    profession: "Photographer",
  },
  {
    id: 11,
    artImg: "/img/Rectangle 62.png",
    artistImg: "/img/Ellipse 30.png",
    profession: "Sculptor",
  },
  {
    id: 12,
    artImg: "/img/Rectangle 66.png",
    artistImg: "/img/Ellipse 32.png",
    profession: "Painter",
  },
];
function ArtistList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const artistdata = useSelector((state) => state.userartistData);
  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`);
  };

  const loader = useSelector((state) => state.loader);

  const handleNavigate = (route) => {
    navigate(route);
  };
  useEffect(() => {
    dispatch(fetchUserArtist());
  }, []);
  return (
    <>
      {loader.loader ? (
        <div className={styles.loader}>
          <CircularLoader />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <div className={styles.artist__list}>
            <div className={styles.headerCategory}>
              <br />
              <div>
                <Typography variant="h4">
                  <span className={styles.headerTitle}>Artist List</span>
                </Typography>
                <Typography variant="h6">
                  <span className={styles.headerSubTitle}>
                    <b
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNavigate("/")}
                    >
                      Home /{" "}
                    </b>
                    Artist List
                  </span>
                </Typography>
              </div>
            </div>
            <div className={styles.headerMain}>
              <Typography ml={-5} fontSize="1.8rem" fontFamily="marcellus">
                Our Artist
              </Typography>
              <TextField
                id="input-with-icon-textfield"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                sx={{
                  width: "30%",
                }}
              />
            </div>
            <div className={styles.artistlist__main}>
              <ArtistSidebar />
              <div className={styles.artist__grid}>
                <Container style={{ paddingRight: "5%" }}>
                  <Grid
                    container
                    columnSpacing={{ xs: 2, md: 5 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    {artistdata.data &&
                      artistdata.data.map((item) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <div className={styles.artist__card}>
                            <div className={styles.artist__top}>
                              <img
                                className={styles.actualart}
                                src={
                                  item.backgroundImage
                                    ?  item.backgroundImage
                                    : "/img/artist_bg_alt.jpeg"
                                }
                                alt="img"
                              />
                            </div>
                            <div className={styles.artistphoto__div}>
                              <img
                                className={styles.artist__photo}
                                src={
                                  item.image
                                    ?  item.image
                                    : "/img/artist_profile.png"
                                }
                                height="50px"
                                alt="img"
                              />
                            </div>
                            <div className={styles.artist__namestars}>
                              <Typography ml={1} fontWeight="600">
                                {item.artist_name}
                              </Typography>
                              <div className={styles.rating}>
                                <img
                                  className={styles.starRating}
                                  src={ "/img/Gold Star.png"}
                                  alt="star"
                                />
                                <Typography ml={0.5} mr={1}>
                                  3.5
                                </Typography>
                              </div>
                            </div>
                            <div className={styles.artist__location}>
                              <Typography fontSize="0.85rem" ml={0.75}>
                                {item.country ? item.country : ""}
                              </Typography>
                              <Typography fontSize="0.85rem" mr={0.75}>
                                {item.portfolio ? item.portfolio : ""}
                              </Typography>
                            </div>

                            <Button
                              sx={{
                                textTransform: "none",
                                color: "black",
                                backgroundColor: "transparent",
                                alignItems: "center",
                                width: "95%",
                                marginLeft: "2.5%",
                                marginTop: "2%",
                                marginBottom: "4%",
                                border: "1px solid black",
                                "&:hover": {
                                  backgroundColor: "black",
                                  color: "white",
                                },
                              }}
                              onClick={() => navigateToArtistDetail(item._id)}
                            >
                              Explore
                            </Button>
                          </div>
                        </Grid>
                      ))}
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
}

export default ArtistList;
