import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { acceptDeclineAuctionArt, fetchAuctionArtData } from "../../../../../State/api/superadminApi";
import CommonAcceptreject from "../../Common/artdetail/acceptReject/Acceptreject";
import Cancel from "../../Popupdelete/Cancel/Cancel";
import styles from "./auctionDetail.module.css";

export default function AuctionDetail() {
 const { id } = useParams();
 const dispatch = useDispatch();
 const auctionAllArt = useSelector((state) => state.exhibitionAllArt.data);
 const fetchData = () => {
  dispatch(fetchAuctionArtData(id));
 };
 useEffect(() => {
  fetchData();
 }, []);

 const handleAcceptRejejectArt = (ids, data) => {
  dispatch(acceptDeclineAuctionArt(ids, data, fetchData));
 };
 const [open, setOpen] = useState(false);
 return (
  <div className={styles.mains}>
   {open ? <Cancel open={open} setOpen={setOpen} /> : ""}
   <CommonAcceptreject data={auctionAllArt} handleAcceptRejejectArt={handleAcceptRejejectArt} />
   
  </div>
 );
}
