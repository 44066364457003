import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data:null,

}

const buyerSlice = createSlice({
    name: 'buyerdata',
    initialState,
    reducers: {
        setBuyerdata: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setBuyerdata } = buyerSlice.actions;

export const buyerData = (state) => state.buyerData;

export default buyerSlice.reducer;