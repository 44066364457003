import { Button, Fade, Modal, TextField } from '@mui/material';
import styles from './Datetime.module.css';
import React from 'react';
import { Box } from '@mui/system';
import Backdrop from '@mui/material/Backdrop';
import dayjs from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import homeImage from '../../../../../constant';

const datecolor = '#636365'
const icon = <img src={ '/img/Edit.png'} />
export default function Datetime(props) {
    const [value, setValue] = React.useState(dayjs(''));

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const handleClose = () => props.setOpen(false);
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <Box className={styles.modal_details}>
                        <div className={styles.cancel_img}>
                            <img onClick={handleClose} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        </div>
                        <p className={styles.modal_heading} id="transition-modal-title" variant="h6" component="h2">
                            {props.text}
                        </p>
                        {props.type === 'order_detail' ?

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack>
                                    <label className={styles.input_label}>Date</label>
                                    <DesktopDatePicker
                                        // label="Date desktop"
                                        inputFormat="MM/DD/YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        components={{
                                            OpenPickerIcon: EventNoteOutlinedIcon
                                            // <img src={ /img/Calander.png'} height='16px' width='15px' />
                                        }}
                                        className={styles.date_picker}
                                        renderInput={(params) => <TextField className={styles.input_date}
                                            sx={{ input: { border: '#fff' }, "& .MuiInputLabel-root": {color: 'green'},  "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "orange" },
                                              },
                                                "& .MuiOutlinedInput-notchedOutline": { border: "#665D6F" },
                                             borderRadius: '6px', mt: 0, mb: 2 }} {...params} />}
                                    />
                                    {/* <label className={styles.input_label}>Date</label> */}

                                    {/* <TimePicker
                                        // label="Time"
                                        // inputFormat="Enter Time"
                                        value={value}
                                        onChange={handleChange}
                                        className={styles.date_picker}
                                        components={{
                                            // OpenPickerIcon: icon
                                        }}
                                        renderInput={(params) => <TextField inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Enter Time"
                                        }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                                input: { datecolor },
                                                label: {}, borderRadius: '6px', mt: 0
                                            }} {...params} />}
                                    /> */}
                                </Stack>
                            </LocalizationProvider>
                            :
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack>
                                    <label className={styles.input_label}>Date</label>
                                    <DesktopDatePicker
                                        // label="Date desktop"
                                        inputFormat="MM/DD/YYYY"
                                        value={value}
                                        onChange={handleChange} components={{
                                            OpenPickerIcon: EventNoteOutlinedIcon
                                            // <img src={ /img/Calander.png'} height='16px' width='15px' />
                                        }}
                                        className={styles.date_picker}
                                        renderInput={(params) => <TextField className={styles.input_date}
                                            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" }, borderRadius: '6px', mt: 0, mb: 2 }} {...params} />}
                                    />
                                    <label className={styles.input_label}>Time</label>

                                    <TimePicker
                                        // label="Time"
                                        // inputFormat="Enter Time"
                                        value={value}
                                        onChange={handleChange}
                                        className={styles.date_picker}
                                        components={{
                                            // OpenPickerIcon: icon
                                        }}
                                        renderInput={(params) => <TextField inputProps={{
                                            ...params.inputProps,
                                            placeholder: "Enter Time"
                                        }}
                                            sx={{
                                                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                                input: { datecolor },
                                                label: {}, borderRadius: '6px', mt: 0
                                            }} {...params} />}
                                    />
                                </Stack>
                            </LocalizationProvider>
                        }
                        <div className={styles.action}>
                            <Button variant='contained' sx={{ borderRadius: '10px', width: '156px', height: '54px' }} className={styles.action_btn} >Save</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
