import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import serverimage from "../../../../../serverConstant"
import styles from "./Orderdetail.module.css"
import api from "../../../../../State/api/Api"
import { useParams } from "react-router-dom"
import { Errornotify } from "../../../../../common/alert/Alert"

export default function ArtistOrderDetails(props) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const [orderData, setOrderData] = useState(null)
  console.log(orderData, "orderData")
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true })
  }
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true })
  }

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true)
    try {
      const result = await api.applicationApi(
        `api/order/fetchOrder/${id}`,
        "GET"
      )

      if (result && result.status === 200) {
        setOrderData(result.data.data)
        // dispatch(setLoader(false));
        setLoading(false)
      } else if (result.status === 401) {
        Errornotify("Error with code 401")
      }
    } catch (err) {
      Errornotify("Network Error")
      setLoading(false)
    }
  }
  useEffect(() => {
    handleFetchOrders()
  }, [])
  return (
    <div className={styles.mains}>
      <>
        <div className={styles.orderIdstyl}>
          <h1 className={styles.main_heading}>Order Id</h1>
          {/* <span className={styles.ordersubsty}>{orderData[0]?._id}</span> */}
        </div>{" "}
        {orderData?.map((val) =>
          val?.items?.map((item) => (
            <>
              <div>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.status}></div>
                  <div className={styles.more_detail}>
                    <div>
                      <div>
                        <h1 className={styles.art_name}>{item?.item?.name}</h1>
                        {/* <span className={styles.ordersubsty}>
                            {" "}
                            {item?.order_status}
                          </span> */}

                        <div style={{ padding: "10px 0px" }}>
                          <img
                            src={` ${item?.item?.image}`}
                            height="85px"
                            width="121px"
                            alt="img"
                          />{" "}
                        </div>

                        <div className={styles.more_detail_child}>
                          <div className={styles.title}> Price:</div>
                          <div className={styles.content}>
                            ${item?.item_amount}
                          </div>
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Material:</div>
                          <div className={styles.content}>
                            {item?.item?.print[0]?.material}
                          </div>
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Category:</div>
                          <div className={styles.content}>Photography</div>
                        </div>
                        <div className={styles.more_detail_child}>
                          <div className={styles.title}>Dimension:</div>
                          <div className={styles.content}>
                            {item?.item?.print?.map((val) => val?.size?.width)}*
                            {item?.item?.print?.map((val) => val?.size?.height)}
                            *
                          </div>
                          {/* <div className={styles.content}>20 W x 20 H x 20 D in</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        )}
      </>
    </div>
  )
}
