import * as React from "react"
import styles from "./Commonsidebar.module.css"
import { Button } from "@mui/material"
import { Link, useLocation, useNavigate } from "react-router-dom"

export default function Commonsidebar(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const path = location.pathname
  const usertypes = localStorage.getItem("usertype")
  const handleDashboard = () => {
    navigate("/dashboard")
  }
  const handleClick = (val) => {
    navigate(val)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <div>
      <div className={styles.demo}>
        {usertypes === "buyer" ||
        usertypes === "affiliate" ||
        usertypes === "subadmin" ? (
          " "
        ) : (
          <h2 onClick={handleDashboard}>Dashboard</h2>
        )}
        {props.sidebarRoutes &&
          props.sidebarRoutes.map((val, i) => (
            <div className={styles.demo_child_div} key={i}>
              <div
                className={
                  path === val.path || path.includes(`${val.path}/`)
                    ? styles.flex_start
                    : ""
                }
              ></div>
              <Button
                onClick={() => handleClick(val.path)}
                variant={
                  path === val.path || path.includes(`${val.path}/`)
                    ? "contained"
                    : ""
                }
                fullWidth
                sx={{
                  whiteSpace: "nowrap",
                  ml: 1,
                  display: "flex",
                  justifyContent: "flex-start",
                  pl: 2,
                  mr: 1,
                  borderRadius: " 2.06016px 0px 0px 2.06016px",
                }}
              >
                <b>{val.name}</b>
              </Button>
            </div>
          ))}
      </div>
    </div>
  )
}
