import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminSubMenu = (menu) => {
  let submenuErrors = {}

  // if (validator.isEmpty(menu.name)) {
  //   submenuErrors.name = "Name is required"
  // }

  if (validator.isEmpty(menu.link)) {
    submenuErrors.link = "Link is required"
  } else if (!validator.isURL(menu.link)) {
    submenuErrors.link = "Please enter a valid URL"
  }

  return {
    submenuErrors,
    isValid: isEmpty(submenuErrors),
  }
}
