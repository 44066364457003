import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchDisputeData,
  acceptRejectRefundStatus,
} from "../../../../State/api/superadminApi"
import Pagination from "../Pagination/Pagination"
import Tableseven from "../Table/Sevencol/Tableseven"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Admindispute.module.css"
import {
  disputeData,
  setDisputedata,
} from "../../../../State/Features/superadmin/disputeSlice"

const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Dispute ID",
  },
  {
    id: "mobile_number",
    label: "Order ID",
  },
  {
    id: "email",
    label: "Genrated By",
  },
  {
    id: "assign_to",
    label: "Name",
  },
  {
    id: "type",
    label: "Title",
  },
  {
    id: "actiont",
    label: "Refund",
  },
  {
    id: "action",
    label: "Status",
  },
]
const sortdata = [
  { label: "Order ID ↓", value: "low" },
  { label: "Order ID ↑", value: "high" },
]
const filterdata = [
  { label: "Resolved", value: "resolved" },
  { label: "Open", value: "open" },
]
export default function Admindispute() {
  const [data, setData] = useState({
    offset: 0,
  })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const disputedata = useSelector((state) => state.disputeData.data)
  const dispatch = useDispatch()
  const fetchData = () => {
    dispatch(fetchDisputeData(data))
  }

  useEffect(() => {
    fetchData()
  }, [data])

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }
  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setDisputedata({
          data:
            disputedata.data &&
            [...disputedata.data].sort((a, b) => {
              if (a.order_id.toLowerCase() < b.order_id.toLowerCase()) return -1
              // a.name.toLowerCase() < b.name.toLowerCase()) return -1
            }),
          totalCount: disputedata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setDisputedata({
          data:
            disputedata.data &&
            [...disputedata.data].sort((a, b) => {
              if (a.order_id.toLowerCase() > b.order_id.toLowerCase()) return -1
            }),
          totalCount: disputedata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  const ApprovedRefundStatus = (id, dt) => {
    dispatch(acceptRejectRefundStatus(id, dt, fetchData))
  }
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.tableheader_enter}>
          <Tableheader
            button=""
            handleFilter={handleFilter}
            handleSorting={handleSorting}
            filterdata={filterdata}
            sortdata={sortdata}
            condition={condition}
            search={condition.search}
            handleSearch={handleSearch}
          />
        </div>
        <Tableseven
          ApprovedRefundStatus={ApprovedRefundStatus}
          activetab="dispute_superadmin"
          head={exhibitionhead}
          data={
            disputedata &&
            disputedata.data
              .filter((val) => {
                if (condition.filter === "") {
                  return val
                } else if (condition.filter === "resolved") {
                  return val.dispute_status === "Resolved"
                } else {
                  return val.dispute_status === "Open"
                }
              })
              .filter((val) => {
                if (condition.search === "") {
                  return val
                } else if (
                  val._id.toLowerCase().includes(condition.search.toLowerCase())
                ) {
                  return val
                }
              })
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        <Pagination
          totaldata={disputedata && disputedata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
