import React, { useCallback, useEffect, useState } from "react"
import styles from "./Artist.module.css"
import Artistprofile from "./Artistprofile/Artistprofile"
import Tables from "../Table/sixcolumn/Tables"
import Tablesfour from "../Table/Fourcolumn/Tables"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tableheader from "../Table/Tableheader/Tableheader"
import Popupfeatured from "./Popups/Popupfeatured"
import {
  acceptDeclineArtist,
  blockUnblockArtist,
  deleteArtist,
  fetchArtistData,
  handleAddArtist,
  SetfeaturedArtist,
  setRecommendedArtist,
  subscriptionPurchaseSubadmin,
  fetchCuratorList,
  handleArtistTypeChange,
} from "../../../../State/api/superadminApi"
import { fetchArtistTypes } from "../../../../State/api/userApi"
import { useDispatch, useSelector } from "react-redux"
import { Pending, Search } from "@mui/icons-material"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import Pagination from "../Pagination/Pagination"
import api from "../../../../State/api/Api"
import axios from "axios"
import { setArtistdata } from "../../../../State/Features/superadmin/ArtistSlice"
import PopupCuratorList from "../Popupdelete/PopupCuratorList"
import TablesApproved from "../Table/Fourcolumn/TablesApproved"

const moreOptions = [
  {
    id: "pending",
    label: "Pending",
    value: "pending",
  },
  {
    id: "approved",
    label: "Approved",
    value: "approved",
  },
  {
    id: "featured",
    label: "Featured Artist",
    value: "featured",
  },
  {
    id: "decline",
    label: "Decline",
    value: "decline",
  },
  {
    id: "recommended",
    label: "Recommended Artist",
    value: "recommended",
  },
  {
    id: "exclusive",
    label: "Exclusive Artist",
    value: "exclusive",
  },
  // {
  //   id: "deleted",
  //   label: "Deleted",
  //   value: "deleted",
  // },
]
const pendinghead = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "date",
    label: "Created Date",
  },
  {
    id: "assign_to",
    label: "Assign to",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "action",
    label: "Action",
  },
]
const approvedheadExclusive = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "date",
    label: "Exclusive Date",
  },

  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const approvedheadDecline = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "date",
    label: "Decline Date",
  },

  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const approvedheadRecommended = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "date",
    label: "Recommended Date",
  },

  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const approvedheadcur = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "contact_details",
    label: "Contact Details",
  },
  {
    id: "date",
    label: "Approved Date",
  },
  {
    id: "assign_to",
    label: "Assign to",
  },
  {
    id: "type",
    label: "Type",
  },
  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const featuredhead = [
  {
    id: "artist_name ",
    label: "Artist Name",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "date",
    label: "Featured Date",
  },
  {
    id: "like",
    label: "Likes",
  },
  {
    id: "followe",
    label: "Followers",
  },
]
const sortdata = [
  { label: "Like ↓", value: "low" },
  { label: "Like ↑", value: "high" },
]
const filterdata = [
  { label: "100<", value: "101" },
  { label: "100>", value: "99" },
  // { label: "Print Price", value: "print" }
]
export default function Artist() {
  const [activetab, setAcitvetab] = useState("pending")
  const [open, setOpen] = useState(false)
  const [openCur, setOpenCur] = useState(false)
  const [popupdata, setPopupdata] = useState(null)
  const [curatorData, setCuratorData] = useState([])
  const [search, setSearch] = useState(false)
  const [button, setButton] = useState({ type: "add", text: "Add" })
  const [condition, setCondition] = useState({
    search: "",
    sorting: "",
    filter: "",
  })
  const [poptype, setPoptype] = useState("")
  const [curatorUser, setCuratorUser] = useState("")
  const [page, setPage] = useState("")
  const [data, setData] = useState({ is_accepted: "Pending", offset: 1 })
  const [pendingProfileData, setPendingProfileData] = useState({
    artist_name: "",
  })

  const dispatch = useDispatch()
  const artistData = useSelector((state) => state.artistdata.data)
  console.log(artistData,'artistData')
  const artistTypes = useSelector((state) => state.artistTypedata)
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const fetchData = () => {
    dispatch(fetchArtistData(data))
  }

  // curator

  const handleSetArtist = (id) => {
    dispatch(SetfeaturedArtist(id, { is_featured: true }, fetchData))
  }
  const handleRemoveArtist = (id) => {
    dispatch(SetfeaturedArtist(id, { is_featured: false }, fetchData))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  useEffect(() => {
    dispatch(fetchArtistTypes())
  }, [])

  const handleBlockUnblock = (id, data) => {
    dispatch(blockUnblockArtist(id, { is_blocked: data }, fetchData))
  }
  const handleArtistDelete = (id) => {
    dispatch(deleteArtist(id, fetchData))
  }
  const handleAcceptDecline = (id, data, selectedType, subscriptionDetails) => {
    dispatch(acceptDeclineArtist(id, { is_accepted: data }, fetchData))
    if (data === "Approved") {
      dispatch(
        subscriptionPurchaseSubadmin({
          subscriptionName: subscriptionDetails.name,
          artistId: id,
          subscriptionId: subscriptionDetails.subscriptionId,
          subscription_tenure: subscriptionDetails.subscription_tenure,
          payment_mode: subscriptionDetails.payment_mode,
          transactionId: subscriptionDetails.transactionId,
          amount: subscriptionDetails.amount,
        })
      )
    }
    dispatch(handleArtistTypeChange(id, selectedType))
  }
  const handleRecommended = (id, dt) => {
    dispatch(setRecommendedArtist(id, { is_recommended: dt }, fetchData))
  }
  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
    dispatch(
      setArtistdata({
        data: [...artistData.data].sort((a, b) => {
          return a.likes - b.likes
        }),
        totalCount: artistData.totalCount,
      })
    )
  }
  const handleSorting = (event) => {
    const value = event.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setArtistdata({
          data:
            artistData.data &&
            [...artistData.data].sort((a, b) => {
              return a.likes - b.likes
            }),
          totalCount: artistData.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setArtistdata({
          data:
            artistData.data &&
            [...artistData.data].sort((a, b) => {
              return b.likes - a.likes
            }),
          totalCount: artistData.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }

  const handlePopup = (data) => {
    const country = data?.country
    dispatch(fetchCuratorList(country, setCuratorData))
    setOpenCur(true)
    setPopupdata(data?.id)
    fetchData()
  }

  return (
    <div className={styles.mains}>
      {/* {open ? (
        <Popupfeatured fetchData={fetchData} open={open} setOpen={setOpen} />
      ) : (
        ""
      )} */}
      {openCur ? (
        <PopupCuratorList
          popupdata={popupdata}
          fetchData={fetchData}
          open={openCur}
          setOpen={setOpenCur}
          curatorData={curatorData}
          setCuratorUser={setCuratorUser}
          curatorUser={curatorUser}
        />
      ) : (
        ""
      )}
      {/* {search ? (
        <Popupfeatured
          condition={condition}
          setSearch={setCondition}
          artistData={artistData && artistData.data}
          poptype="search"
          open={search}
          setOpen={setSearch}
        />
      ) : (
        ""
      )} */}
      <AlertsSuccess />
      <div className={styles.component}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          poptype={poptype}
          button={button}
          setButton={setButton}
          setPoptype={setPoptype}
          setOpen={setOpen}
          handleSearch={handleSearch}
          search={condition.search}
        />
        <Moreoption
          //  setButton={setButton}
          activetab={activetab}
          setPoptype={setPoptype}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
          data={data}
        />
        {activetab === "pending" ? (
          <>
            <Tables
              activetab={activetab}
              setPendingProfileData={setPendingProfileData}
              setPage={setPage}
              setOpen={setOpenCur}
              setPoptype={setPoptype}
              head={pendinghead}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    if (vl.is_accepted === "Pending") {
                      return vl
                    }
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search)
                    const fullNameMatch = fullName.includes(search)
                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search)

                    const curatorMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].curator &&
                      val.artist[0].curator.length > 0 &&
                      val.artist[0].curator.some(
                        (cur) =>
                          cur.curator_name &&
                          cur.curator_name.toLowerCase().includes(search)
                      )
                    return (
                      nameMatch ||
                      lastnameMatch ||
                      emailMatch ||
                      fullNameMatch ||
                      phoneMatch ||
                      curatorMatch
                    )
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.createdAt) -
                        new Date(artistA.createdAt)
                      )
                    }

                    return 0
                  })
              }
              handleArtistDelete={handleArtistDelete}
              handleBlockUnblock={handleBlockUnblock}
              handleAcceptDecline={handleAcceptDecline}
              handleSetArtist={handleSetArtist}
              handleRemoveArtist={handleRemoveArtist}
              handlePopup={handlePopup}
              artistTypes={artistTypes}
            />
          </>
        ) : activetab === "approved" ? (
          <>
            <TablesApproved
              activetab={activetab}
              head={approvedheadcur}
              handleSetArtist={handleSetArtist}
              handleBlockUnblock={handleBlockUnblock}
              handleRecommended={handleRecommended}
              handleRemoveArtist={handleRemoveArtist}
              handleArtistDelete={handleArtistDelete}
              handlePopup={handlePopup}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    return vl.is_accepted === "Approved"
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const fullNameMatch = fullName.includes(search)
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)

                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search)
                    const artistMatch =
                      val.artist &&
                      val.artist[0] 
                      // val.artist[0].country.toLowerCase().includes(search)

                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search)

                    return (
                      nameMatch ||
                      lastnameMatch ||
                      fullNameMatch ||
                      artistMatch ||
                      emailMatch ||
                      phoneMatch
                    )
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.approved_date) -
                        new Date(artistA.approved_date)
                      )
                    }

                    return 0
                  })
              }
            />
          </>
        ) : activetab === "exclusive" ? (
          <>
            <Tablesfour
              activetab={activetab}
              head={approvedheadExclusive}
              handleSetArtist={handleSetArtist}
              handleBlockUnblock={handleBlockUnblock}
              handleRecommended={handleRecommended}
              handleRemoveArtist={handleRemoveArtist}
              handleArtistDelete={handleArtistDelete}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    return vl?.artist[0]?.exclusive_work === "Yes"
                  })
                  // .filter((val) => {
                  //   const fullName = `${val.first_name || ""} ${
                  //     val.last_name || ""
                  //   }`
                  //     .toLowerCase()
                  //     .trim()
                  //   const search = condition.search.toLowerCase().trim()
                  //   const fullNameMatch = fullName.includes(search)
                  //   const nameMatch =
                  //     val.first_name &&
                  //     val.first_name.toLowerCase().includes(search)
                  //   const lastnameMatch =
                  //     val.last_name &&
                  //     val.last_name.toLowerCase().includes(search)

                  //   const emailMatch =
                  //     val.email && val.email.toLowerCase().includes(search)
                  //   const artistMatch =
                  //     val.artist &&
                  //     val.artist[0] &&
                  //     val.artist[0].country.toLowerCase().includes(search)

                  //   const phoneMatch =
                  //     val.phone && val.phone.toLowerCase().includes(search)

                  //   return (
                  //     nameMatch ||
                  //     lastnameMatch ||
                  //     fullNameMatch ||
                  //     artistMatch ||
                  //     emailMatch ||
                  //     phoneMatch
                  //   )
                  // })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.createdAt) -
                        new Date(artistA.createdAt)
                      )
                    }

                    return 0
                  })
              }
            />
          </>
        ) : activetab === "decline" ? (
          <>
            <Tablesfour
              activetab={activetab}
              head={approvedheadDecline}
              handleSetArtist={handleSetArtist}
              handleRemoveArtist={handleRemoveArtist}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    return vl.is_accepted === "Rejected"
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const fullNameMatch = fullName.includes(search)
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search)
                    const artistMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].country.toLowerCase().includes(search)

                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search)

                    return (
                      nameMatch ||
                      fullNameMatch ||
                      lastnameMatch ||
                      artistMatch ||
                      emailMatch ||
                      phoneMatch
                    )
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.approved_date) -
                        new Date(artistA.approved_date)
                      )
                    }

                    return 0
                  })
              }
            />
          </>
        ) : activetab === "deleted" ? (
          <>
            <Tablesfour
              activetab={activetab}
              head={approvedheadDecline}
              handleSetArtist={handleSetArtist}
              handleRemoveArtist={handleRemoveArtist}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    return vl.is_deleted === true
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const fullNameMatch = fullName.includes(search)
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search)
                    const artistMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].country.toLowerCase().includes(search)

                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search)

                    return (
                      nameMatch ||
                      fullNameMatch ||
                      lastnameMatch ||
                      artistMatch ||
                      emailMatch ||
                      phoneMatch
                    )
                  })
                  .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
              }
            />
          </>
        ) : activetab === "recommended" ? (
          <>
            <Tablesfour
              handleArtistDelete={handleArtistDelete}
              handleSetArtist={handleSetArtist}
              handleRecommended={handleRecommended}
              activetab={activetab}
              head={approvedheadRecommended}
              data={
                artistData &&
                artistData.data
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const fullNameMatch = fullName.includes(search)
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)
                    const emailMatch =
                      val.email && val.email.toLowerCase().includes(search)
                    const artistMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].country.toLowerCase().includes(search)

                    const phoneMatch =
                      val.phone && val.phone.toLowerCase().includes(search)

                    return (
                      nameMatch ||
                      fullNameMatch ||
                      lastnameMatch ||
                      artistMatch ||
                      emailMatch ||
                      phoneMatch
                    )
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.recommended_date) -
                        new Date(artistA.recommended_date)
                      )
                    }

                    return 0
                  })
                  .filter((val) => {
                    if (condition.filter === "101") {
                      return val.followers > 100
                    } else if (condition.filter === "99") {
                      return val.followers < 100
                    } else {
                      return val
                    }
                  })
              }
            />
          </>
        ) : (
          <>
            <Tablesfour
              activetab={activetab}
              head={featuredhead}
              handleSetArtist={handleSetArtist}
              handleRemoveArtist={handleRemoveArtist}
              data={
                artistData &&
                artistData.data
                  .filter((vl) => {
                    return vl.is_featured === true
                  })
                  .filter((val) => {
                    const fullName = `${val.first_name || ""} ${
                      val.last_name || ""
                    }`
                      .toLowerCase()
                      .trim()
                    const search = condition.search.toLowerCase().trim()
                    const fullNameMatch = fullName.includes(search)
                    const nameMatch =
                      val.first_name &&
                      val.first_name.toLowerCase().includes(search)
                    const lastnameMatch =
                      val.last_name &&
                      val.last_name.toLowerCase().includes(search)

                    const artistMatch =
                      val.artist &&
                      val.artist[0] &&
                      val.artist[0].country.toLowerCase().includes(search)

                    return (
                      nameMatch || fullNameMatch || lastnameMatch || artistMatch
                    )
                  })
                  .sort((a, b) => {
                    const artistA = a.artist && a.artist[0]
                    const artistB = b.artist && b.artist[0]

                    if (artistA && artistB) {
                      return (
                        new Date(artistB.featured_date) -
                        new Date(artistA.featured_date)
                      )
                    }

                    return 0
                  })
              }
            />
          </>
        )}

        {artistData && artistData.data.length > 0 && (
          <Pagination
            totaldata={artistData && artistData.totalCount}
            pagination={data}
            setPagination={setData}
          />
        )}
      </div>
    </div>
  )
}
