import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const ArtsSlice = createSlice({
    name: "artsdata",
    initialState,
    reducers: {
        setArtsdata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setArtsdata } = ArtsSlice.actions;

export const ArtsData = (state) => state.artsdata;

export default ArtsSlice.reducer;
