import React from 'react'
import Commonhome from '../../../../../curator/pages/applyForm/common/Commonhome'

const data = {
  heading: "Join Art Smiley's community of reputed Galleries",
  subheading: "Please fill all details as comprehensively and precisely as possible. The most completed one will have the best chance of being selected!"
}

export default function Questionhome() {
  return (
    <>
      <Commonhome data={data} />
    </>
  )
}
