import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";
import api from "../../../../../State/api/Api";
import { useNavigate, useParams } from "react-router-dom";
import { Errornotify, Successnotify } from "../../../../../common/alert/Alert";
import { IoIosArrowBack } from "react-icons/io";
import { ToastContainer } from "react-toastify";

export default function BuyerDisputeDetail(props) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const navigate = useNavigate()
  const [orderData, setOrderData] = useState(null);
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };

  const handleFetchOrders = async function () {
    // dispatch(setLoader(true));
    setLoading(true)
    try {
      const result = await api.applicationApi(`api/dispute/getDisputeById/${id}`, "GET");
    
      if (result && result.status === 200) {
        setOrderData(result.data.data);
        // dispatch(setLoader(false));
        setLoading(false)
      } else if (result.status === 401) {
        Errornotify("Error with code 401");
      }
    } catch (err) {
      Errornotify("Network Error");
      setLoading(false)
    }
  };
useEffect(() => {
    handleFetchOrders();
  }, []);

  const handleAcceptOrderItem = async (orderItemId) => {
try {
  const response = await api.applicationApi(`api/order/updateOrderItemStatus/${orderItemId}`,"PATCH",{
    "order_status":"Approved" //Approved and Cancelled
    // "cancellation_reason":"This Is testing" // Not Optional
})
if(response.status === 200 || response.status === 201) {
  Successnotify(response.data.message)
}else {
Errornotify(response.data.message)
}
}catch(error) {
}
  };

  const handleRejectOrderItem = async (orderItemId) => {
    try {
      const response = await api.applicationApi(`api/order/updateOrderItemStatus/${orderItemId}`,"PATCH",{
        "order_status":"Rejected" //Approved and Cancelled
        // "cancellation_reason":"This Is testing" // Not Optional
    })
    if(response.status === 200 || response.status === 201) {
      Successnotify("Order Item Declined Successfully!")
    }
    }catch(error) {
    }
  }
  return (
    <div className={styles.mains} style={{backgroundColor:'transparent'}}>
   
        <>
          <h5 className={styles.main_heading} onClick={() => navigate("/userdispute")}>
            {/* {props.type==="auction_gallery" ? props.data1 && props.data.auction: props.data && props.data.data && props.data.data.exhibition} */}
            {/* {props.data && props.data.data.data && props.data.data.data[0].artist} */}
            <IoIosArrowBack /> <span> Go Back</span>
          </h5>
       
        </>
 
   
  
      {/* <hr /> */}
  
        
        
           <>    
                <>
                <div>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.status}>
                
    {/* <Button
      sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
      Rejected
    </Button> */}

    <div className={styles.item_acc_dec_div}>
    <h5 className={styles.main_heading}>
            {/* {props.type==="auction_gallery" ? props.data1 && props.data.auction: props.data && props.data.data && props.data.data.exhibition} */}
            {/* {props.data && props.data.data.data && props.data.data.data[0].artist} */}
            <span style={{color:'black',marginRight:"6px",}}>Status:</span> <span style={{color:'red',fontSize:"16px"}}>Open</span>
          </h5>
    </div>

    {/* <Button
      onClick={handleDatepopup}
      variant="contained"
      sx={{ width: "226px", height: "46px" }}>
      Pick A Schedule
    </Button>

    <Button
      sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "326px", height: "46px" }}>
      Product Received at Artist’s Location
    </Button>

    <p className={styles.schedule_time}>Order Status</p>


  <Button
    sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Sold
  </Button>

  <Button
    sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Unsold
  </Button>

  <Button
    sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
    Pending
  </Button> */}


                

                  </div>
                 {orderData?.map((data) => (
                    <div className={styles.more_detail}>
                    <div>
                     
                        <div>
                          <h1 className={styles.art_name}>{data.title}</h1>
                          <div style={{padding:"10px 0px"}}>
                            <img src={` ${data?.images}`} height="85px" width="121px" alt='img' />{" "}
                          </div>
                         
                         
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}> Dispute Id:</div>
                            <div className={styles.content}>{data?.dispute_id}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Order Id:</div>
                            <div className={styles.content}>{data?.order[0]?.order_number}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Description:</div>
                            <div className={styles.content}>{data.description}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Payment:</div>
                            <div className={styles.content}>{data.refund_status}</div>
                            {/* <div className={styles.content}>20 W x 20 H x 20 D in</div> */}

                          </div>
                        </div>
                
                    </div>
                   
                  </div>
                 ))}
                </div>
              </div>
                </>
            

           </>
         <ToastContainer />
       
    </div>
  );
}
  