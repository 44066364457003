import React, { useState, useEffect } from "react"
import styles from "./TableAffiliate.module.css"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { getAllAffilateLink } from "../../State/api/superadminApi"
import { Button, IconButton, Tooltip } from "@mui/material"
import CopyLinkModal from "../CopyLinkaffiliate/CopyLinkModal"
import { useDispatch, useSelector } from "react-redux"
// import Popup from "../../Popupdelete/Popup"
// import dayjs from "dayjs"
// import Tableheader from "../Tableheader/Tableheader"
// import Moreoption from "../Moreoption/Moreoption"
// import homeImage from "../../../../../constant"
// import serverimage from "../../../../../serverConstant"
const headData = [
  {
    id: "sl",
    label: "SL No",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "CreatedDate",
    label: "Created Date",
    format: (value) => value.toLocaleString(),
  },
  {
    id: "link",
    label: "Link",
    // format: (value) => value.toLocaleString(),
  },

  {
    id: "action",
    label: "Action",
    format: (value) => value.toLocaleString(),
  },
]

export default function TableAffiliate(props) {
  const [open, setOpen] = React.useState(false)
  const [selectedLink, setSelectedLink] = useState("")
  const dispatch = useDispatch()
  const getAllaffiliateLinkData = useSelector(
    (state) => state.getAllaffiliateLinkData.data
  )

  const fetchData = () => {
    dispatch(getAllAffilateLink())
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleEditLink = (link) => {
    setSelectedLink(link)
    setOpen(true)
  }
  return (
    <>
      {open && (
        <CopyLinkModal open={open} setOpen={setOpen} link={selectedLink} />
      )}
      <div className={styles.shadow}>
        {/* <Tableheader /> */}
        {/* <Moreoption  artistOptions={artistOptions} /> */}
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#F6F6F6", color: "black", boxShadow: "none" }}
        >
          <Table sx={{ minWidth: 870 }} aria-label="customized table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
                {headData.map((column, i) => (
                  <TableCell
                    align="left"
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getAllaffiliateLinkData &&
                getAllaffiliateLinkData.map((row, i) => (
                  <TableRow style={{ color: "#636365" }}>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      component="th"
                      scope="row"
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.createdAt
                        ? dayjs(row.createdAt?.row?.createdAt)
                            .format("YYYY-MM-DD")
                            .toString()
                        : "---"}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.link.length > 30
                        ? `${row.link.substring(0, 30)}...`
                        : row.link}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* <Button
                        color={row.status ? "primary" : "primary"}
                        variant={row.status ? "contained" : "contained"}
                        sx={{ width: "100px", mr: 2 }}
                      >
                        Block
                      </Button> */}
                      <IconButton onClick={() => handleEditLink(row?.link)}>
                        <Tooltip title="Copy">
                          <ContentCopyIcon
                            style={{ fontSize: 25 }}
                            onClick={() => setOpen(true)}
                          />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}
