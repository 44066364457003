import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const submenuSlice = createSlice({
  name: "submenuData",
  initialState,
  reducers: {
    submenuData: (state, action) => {
      state.data = action.payload
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
})

export const { submenuData } = submenuSlice.actions

export const submenuesData = (state) => state.submenuData

export default submenuSlice.reducer
