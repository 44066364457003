import * as React from "react"
import styles from "./Menu.module.css"
import { Button, FormControlLabel, TextField } from "@mui/material"
import { useState } from "react"
import Card from "../Common/Card/Card"
import EditMenu from "../Popupdelete/editMenu/EditMenu"
import TabsMenu from "../Common/Tabsmenu/TabsMenu"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import MenuList from "./Menulist"
import {
  fetchMainMenu,
  deleteMainmenu,
  fetchSubMenu,
  fetchHeadingByID,
} from "../../../../State/api/superadminApi"

export default function Menu() {
  const dispatch = useDispatch()
  const [menu, setmenu] = useState({ add: false, edit: false })
  const [name, setName] = useState({ name: "", _id: "", link: "", image: "" })
  const [submenu, setSubmenu] = useState({ add: false, edit: false })
  const [menu_id, setMenu_id] = useState("")
  const handleChange = () => {
    // setValue(newValue);
  }

  const menuesData = useSelector((state) => state.menuData.data)

  const headingByMenu = useSelector((state) => state.headingByMenu.data)
  const submenuesData = useSelector((state) => state.submenuData.data)
  const fetchMenuData = () => {
    dispatch(fetchMainMenu(setMenu_id))
  }
  React.useEffect(() => {
    fetchMenuData()
  }, [])

  const fetchHeading = () => {
    dispatch(fetchHeadingByID(menu_id))
  }

  React.useEffect(() => {
    fetchHeading()
  }, [menu_id])
  // headingByMenu

  // const fetchsubmenuData = () => {
  //   dispatch(fetchSubMenu(setMenu_id))
  // }
  // React.useEffect(() => {
  //   fetchsubmenuData()
  // }, [])

  const handleAddCategoryPopup = (type, value) => {
    if (type === "add") {
      setmenu({ ...menu, add: true })
    } else {
      setmenu({ ...menu, edit: true })
      setName({
        ...name,
        name: value.name,
        _id: value._id,
        link: value.link,
        image: value.image,
      })
    }
  }

  const handleNewChange = (e) => {
    setName({ ...name, name: e.target.value })
  }

  const handleDelete = (id) => {
    dispatch(deleteMainmenu(id, fetchMenuData))
  }
  return (
    <>
      <div className={styles.mains}>
        {menu.add ? (
          <EditMenu
            handleNewChange={handleNewChange}
            name={name}
            type="add"
            menuopen={menu.add}
            fetchMenuData={fetchMenuData}
            setMenuOpen={setmenu}
          />
        ) : (
          ""
        )}

        {menu.edit ? (
          <EditMenu
            type="edit"
            handleNewChange={handleNewChange}
            name={name}
            menuopen={menu.edit}
            fetchMenuData={fetchMenuData}
            setMenuOpen={setmenu}
          />
        ) : (
          ""
        )}

        <div className={styles.components}>
          <div className={styles.category_btn}>
            <Button
              onClick={() => handleAddCategoryPopup("add")}
              variant="contained"
              sx={{ height: "44px", width: "141px" }}
            >
              Add Menu
            </Button>
          </div>
          <div className={styles.card_main}>
            <div className={styles.tabs_div}>
              <TabsMenu
                label={menuesData && menuesData}
                setMenu_id={setMenu_id}
                menu_id={menu_id}
              />
            </div>
            <div className={styles.card_enter}>
              <Card
                menuesData={menuesData}
                menu_id={menu_id}
                submenuesData={submenuesData}
                headingByMenu={headingByMenu}
                fetchHeading={fetchHeading}
              />
              {/* <div className={styles.save_btn}>
              <Button
                variant="contained"
                sx={{ width: "120px", height: "54px", borderRadius: "10px" }}
              >
                Save
              </Button>
            </div> */}
            </div>
          </div>
          <MenuList
            // open={open}
            handleDelete={handleDelete}
            // setOpen={setCategory}
            // headingByMenu={headingByMenu}
            menu_id={menu_id}
            handleAddCategoryPopup={handleAddCategoryPopup}
            menuesData={menuesData && menuesData}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
