import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import RemoveIcon from "@mui/icons-material/Remove";
import { Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../alert/Alert";
import { useMediaQuery } from "@material-ui/core"; 

const data = [
  {
    id: 1,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 2,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 3,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
];
const service_data = [
  {
    id: 1,
    src: "img/service_artist_1",
    heading: "Fair Participation",
    des: "We participate in 8 international art fairs each year to showcase our artists on a global scale.   ",
  },
  {
    id: 2,
    src: "img/service_artist_1",
    heading: "Our Online magzine",
    des: "Art Blog, News and Artist Interviews",
  },
  {
    id: 3,
    src: "img/service_artist_1",
    heading: "Meetings",
    des: "Artists Meet & Greet Jan 2020",
  },
];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HomeCurator(props) {
  const [expanded, setExpanded] = useState("panel1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/artsmiley/curator/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

   

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
       <div className={styles.hero_main_curator}>
        <Container maxWidth="lg">
        <div className={`${styles.pageitle} ${styles.max_width}`}>
              <h1>Join the Art Smiley Curator’s Community</h1>
              <button
              onClick={handleSignupButton}
              variant="contained"
              
              className={styles.apply_btn}
            >
              Apply
            </button>
            </div>
        </Container>
       </div>
          
          <section className={styles.sec_sc}>
            <Container maxWidth="lg">
            <div className={`${styles.text_center} ${styles.max_width}`}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Drive Art Sales Through Engagement and Storytelling with Art Smiley Curator’s Community</h3>
          <p className={styles.sub_title}>Art Smiley Curator’s Community makes a strong contribution to multifaceted art. By fostering connections with art lovers and patrons, curators can greatly improve artwork sales through the artful narration of compelling stories behind the art pieces. Furthermore, by curating exhibitions that showcase a variety of artistic expressions, curators foster a collaborative environment that nurtures personal connections between artists and buyers. Promoting these exhibitions through multiple channels enhances the relationship between the art and its audience, ultimately enriching the overall experience and deepening appreciation for the artworks.</p>
          </div>
          <div className={`${styles.text_center} ${styles.max_width}`}>
          <h3 className={styles.sec_title}>How to Join the Art Smiley Curator’s Community?</h3>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
              <div className={styles.stboxh}>
                <div className={styles.stvob}>
                  <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curatorsignup.svg"}
                alt="img"
              />  </div>
                  <h4>Submit Your Application</h4>
                  <div className={styles.stcont}>
                    <p>Begin your journey by providing the necessary details for registration. This is the first step towards becoming a valued member of the ArtSmiley Curator's Community.[sign up button]</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-recommended.svg"}
                alt="img"
              />  </div>
                  <h4>Review and Approval</h4>
                  <div className={styles.stcont}>
                    <p>Once your information is submitted, our team will review your application within 3-4 weeks. During this period, we’ll assess your profile to ensure it aligns with our curatorial standards and goals. Upon approval, you will receive a confirmation email to create a password for your account and login to your dashboard.</p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
            <div className={styles.stboxh}>
                <div className={styles.stvob}>
                <div className={styles.sticon}>
                  <img
                className={styles.img_fluid}
                src={"/img/curator-earn.svg"}
                alt="img"
              />  </div>
                  <h4>Start Curating and Earning</h4>
                  <div className={styles.stcont}>
                    <p>After your account is activated, you’ll gain access to our platform, where you can start curating collections, selecting pieces that resonate with your artistic vision. As you showcase high-quality contemporary art, you’ll earn commissions, contributing to your growth as a curator and supporting the artists within our community. Additionally, you can invite artists from your community to join ArtSmiley, allowing you to curate their artworks and further expand the diversity and richness of your collections. You can curate the exhibitions and select the best artworks that enhance the better interaction between artists and potential buyers. Leveraging various promotional channels to highlight these exhibitions strengthens the connection between the artwork and its audience, ultimately enhancing the experience and fostering a deeper appreciation for the pieces on display.</p>
                  </div>
                </div>
              </div>
            </Grid>
            </Grid>
            </Container>
          </section>
          <section className={styles.hero_main_curator_benefits}>
            <Container maxWidth="lg">
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Curator Privileges</h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>Collaborate with a Global Community</h4>
                <div className={styles.befncont}>
                  <p>Connect with artists, collectors, and curators from around the world. Exchange ideas, inspire others, and help shape the narrative of contemporary art. Engage with a diverse network that enhances your ability to discover art for sale and promotes the buying art online experience.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Access to Diverse Artworks</h4>
                <div className={styles.befncont}>
                  <p>Explore a wide range of artworks, from traditional to contemporary, and curate collections that reflect your unique perspective. Whether you're looking for original art for sale, fine art, or canvas wall art, you'll find an extensive selection of works that speak to your vision and interests.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Curate Exhibitions to Sell Artworks</h4>
                <div className={styles.befncont}>
                  <p>As a curator, you have the opportunity to organize physical and virtual exhibitions specifically designed to showcase and sell artworks.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>04</p>
                </div>
                <h4>Earn Commissions</h4>
                <div className={styles.befncont}>
                  <p>By curating and promoting exceptional artworks, you will earn commissions on every art original sold, adding value to both your career and the artists you support. These earnings are a testament to the success of the buy original art movement, connecting creators with buyers worldwide.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>05</p>
                </div>
                <h4>Support and Guidance</h4>
                <div className={styles.befncont}>
                  <p>Our dedicated team is here to assist you every step of the way. Whether you need help navigating the platform or advice on curating, we’re just a message away.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>06</p>
                </div>
                <h4>Track Your Commissions</h4>
                <div className={styles.befncont}>
                  <p>Use our platform to easily monitor and track your commissions, providing you with clear insights into your earnings and helping you manage your curatorial activities effectively. This streamlined approach supports your goal of promoting fine art and canvas wall art.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>07</p>
                </div>
                <h4>Select Featured Artists</h4>
                <div className={styles.befncont}>
                  <p>As a curator, you play a pivotal role in highlighting talent. You have the authority to select and showcase featured artists, drawing attention to their original art and providing a platform for them to reach wider audiences. Your work directly impacts the visibility of art for sale, helping buyers buy original art from talented creators.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>08</p>
                </div>
                <h4>Specially Curated Collections</h4>
                <div className={styles.befncont}>
                  <p>Manage the curated artworks that reflect your unique artistic vision. All curated collections are featured on a dedicated page, allowing you to showcase your selections in a professional, organized manner, making it easier for collectors to explore and buy art. Whether promoting canvas wall art or fine art, your curated collections help guide buyers to discover pieces that speak to their tastes and interests.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          </div>
            </Container>
          </section>
          <section className={styles.hero_main_curator_benefits}>
            <Container maxWidth="lg">
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Members-Only Insights</h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>Exclusive Artworks</h4>
                <div className={styles.befncont}>
                  <p>Discover a curated selection of exceptional contemporary art pieces that represent the cutting edge of contemporary art.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Curator Insights</h4>
                <div className={styles.befncont}>
                  <p>Gain deeper understanding with detailed commentary and stories from our curators about each artwork.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Early Previews</h4>
                <div className={styles.befncont}>
                  <p>Get first access to new arrivals and special releases, keeping you ahead of the latest art trends.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          </div>
            </Container>
          </section>
          <section className={styles.hero_main_curator_benefits}>
            <Container maxWidth="lg">
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Dashboard Features and Benefits</h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>Total Featured Artists</h4>
                <div className={styles.befncont}>
                  <p>Track the number of artists in your collections.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Total Arts</h4>
                <div className={styles.befncont}>
                  <p>Monitor the total amount of art represented.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Curated Collections</h4>
                <div className={styles.befncont}>
                  <p>Count your specially curated artwork collections.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>04</p>
                </div>
                <h4>Total Earnings</h4>
                <div className={styles.befncont}>
                  <p>Check your earnings to track financial progress.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>05</p>
                </div>
                <h4>Total Number of Artists</h4>
                <div className={styles.befncont}>
                  <p>Know how many artists you’re engaging currently.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>06</p>
                </div>
                <h4>Total Weekly Earnings</h4>
                <div className={styles.befncont}>
                  <p>Stay updated on weekly earnings.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>07</p>
                </div>
                <h4>Total Monthly Earnings</h4>
                <div className={styles.befncont}>
                  <p>Track your monthly earnings.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>08</p>
                </div>
                <h4>Total Yearly Earnings</h4>
                <div className={styles.befncont}>
                  <p>View your cumulative earnings for the year</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>09</p>
                </div>
                <h4>Total Curated Exhibitions</h4>
                <div className={styles.befncont}>
                  <p>Review the total number of the curated exhibitions by you.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          </div>
            </Container>
          </section>
          <section className={styles.hero_main_curator_benefits}>
            <Container maxWidth="lg">
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title} ${styles.text_white}`}>Remarkable Events</h3>
          </div>
          <div>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>01</p>
                </div>
                <h4>Private Viewings</h4>
                <div className={styles.befncont}>
                  <p>Enjoy exclusive events where you can see artworks up close and interact with artists and curators.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>02</p>
                </div>
                <h4>Curator-led Tours</h4>
                <div className={styles.befncont}>
                  <p>Join guided tours to explore the collection and appreciate the diverse styles and techniques of featured pieces.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={4}>
            <div className={styles.befnhgt}>
              <div className={styles.befnbox}>
                <div className={styles.befnmbr}>
                  <p>03</p>
                </div>
                <h4>Special Collaborations</h4>
                <div className={styles.befncont}>
                  <p>Participate in unique events that foster connections between artists, collectors, and art enthusiasts.</p>
                </div>
              </div>
            </div>
          </Grid>
          </Grid>
          </div>
            </Container>
          </section>
          <div className={`${styles.text_center} ${styles.max_width}`}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Art Smiley Curators</h3>
            <p className={styles.sub_title}>ArtSmiley’s curators carefully select a broad range of contemporary arts, spotlighting both emerging and renowned artists. Our curators expertise brings fresh, vibrant perspectives to the global art community. Check Art Smiley’s existing curator and their expertise in reviewing the classic art pieces in the link below.
              <br/>
              [curator images]
              [Show existing curators of Art Smiley]
            </p>
          </div>
          <div className={`${styles.text_center} ${styles.max_width}`}>
            <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
            <h3 className={styles.sec_title}>Upcoming Events</h3>
            <p className={styles.sub_title}>The Curator Community events offer exclusive access to curators to curate exhibitions and create networking opportunities with prominent artists and collectors. Engage with the art community, discover new talent, and deepen your appreciation for exceptional artworks in a collaborative environment.
              [Event List]
            </p>
          </div>
          <section className={styles.sec_sc}>
            <Container maxWidth="lg">
            <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={`${styles.sec_title}`}>Frequently Asked Questions</h3>
          </div>
            <div className={styles.faq_box}>
            <div className="acchdr">
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >{expanded === "panel1" ? (
          <div className={styles.accodian_no}>1</div>
        ) : (
          ""
        )}
            <h3 className={`${styles.faqhdr} ${
      expanded === "panel1" ? styles.faqhdrExpanded : ""
    }`}>How does the Associates Program work?</h3>
        </AccordionSummary>
        <AccordionDetails>
        <p>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >{expanded === "panel2" ? (
          <div className={styles.accodian_no}>2</div>
        ) : (
          ""
        )}
           <h3 className={`${styles.faqhdr} ${
      expanded === "panel2" ? styles.faqhdrExpanded : ""
    }`}>
           How do I qualify for this program?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          {expanded === "panel3" ? (
                    <div className={styles.accodian_no}>3</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel3" ? styles.faqhdrExpanded : ""
    }`}>
          How do I earn in this program?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          {expanded === "panel4" ? (
                    <div className={styles.accodian_no}>4</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel4" ? styles.faqhdrExpanded : ""
    }`}>
          How do I sign up to the program?
          </h3>
        </AccordionSummary>
        <AccordionDetails>
        <p>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.</p>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary className="acchdr"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          {expanded === "panel5" ? (
                    <div className={styles.accodian_no}>5</div>
                  ) : (
                    ""
                  )}
          <h3 className={`${styles.faqhdr} ${
      expanded === "panel5" ? styles.faqhdrExpanded : ""
    }`}>
          Lorem Ipsum is simply dummy text of the printing and
          typesetting industry
          </h3>
        </AccordionSummary>
        <AccordionDetails>
                  <p>Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the, when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.</p>
        </AccordionDetails>
      </Accordion>
    </div>
            </div>
            <div className={styles.btnx_div}>
              <button
                variant="contained"
                onClick={handleSignupButton}
                className={styles.apply_btnbl}
              >
                Apply
              </button>
            </div>
            </Container>
          </section>
          {/* <Box className={styles.benifits_box}>
                        <Box className={styles.benifits_card}>
                            h
                            <div className={styles.Avatar_ligth} >
                                <div className={styles.Avatar4}>04</div>
                            </div>
                            <h3 className={styles.benifits_head}>Best Earn</h3>
                            <p className={styles.benifits_des}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </Box>
                        <div className={styles.dot_line}></div>
                    </Box> */}
 
          <Box className={styles.subscribe_banner}>
            <div>
              <img alt="img" src={"/img/email.png"} width="80px" height="63px" />
            </div>
            <h5 className={styles.subscribe_head}>
              Subscribe to the art smiley newsletter
            </h5>
            <TextField
              variant="outlined"
              value={email}
              placeholder="Please enter your mail address"
              onChange={handleChangeSubscription}
              sx={{
                width: "400px",
                background: "#3c3c3c",
                // opacity: '0.2',
                border: "1px solid #FFFFFF",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                // "::placeholder": { color: "red" },
                // '&::-webkit-input-placeholder': { color: 'blue' },
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#FFFFFF",
                input: { color: "white" },
                border: "none",
                // opacity: '0.6',
              }}
            />
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                height: "54px",
                width: "131px",
                borderRadius: "6px",
              }}
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
          </Box>
      
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default HomeCurator;
