import React, { useEffect, useState } from "react"
import styles from "./PopupCuratorList.module.css"
import Backdrop from "@mui/material/Backdrop"
import { useParams } from "react-router-dom"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Fade from "@mui/material/Fade"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import {
  addmarketingdata,
  fetchArtistProfileData,
} from "../../../../State/api/superadminApi"
import { Grid, MenuItem, Select } from "@mui/material"
import { useDispatch } from "react-redux"
import "react-quill/dist/quill.snow.css"
import ReactQuill from "react-quill"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "350px",
  backgroundColor: "#FFFFFF",
  width: 550,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  pl: 3,
  pr: 1,
  pt: 1,
  pb: 4,
}
export default function PopupModalSubscription(props) {
  const dispatch = useDispatch()
  const { id } = useParams()
  console.log(id, "id----")
  const handleClose = () => props.setOpen(false)
  const [subinput, setSubinput] = useState({
    feature: "",
    description: "",
  })
  const handleChangeSub = (e) => {
    const { name, value } = e.target
    setSubinput({ ...subinput, [name]: value })
  }
  const curatorSubmit = () => {
    dispatch(
      addmarketingdata(
        id,
        subinput.feature,
        subinput.description,
        props.setOpen
      )
    )
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} className={styles.modal_del}>
            <div className={styles.cancel_img}>
              <img
                onClick={() => props.setOpen(false)}
                src="/img/cancel_cross.png"
                height="20px"
                width="20px"
              />
            </div>
            <Typography
              align="center"
              id="transition-modal-title"
              className={styles.assign_heading}
              variant="h6"
              component="h2"
            >
              Artist Marketing Information
            </Typography>
            <div className={styles.search_action}>
              <label className={styles.search_label}>Select</label>

              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={subinput.feature}
                label="Feature"
                fullWidth
                sx={{
                  background: "#f6f6f6",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                placeholder="select type"
                onChange={handleChangeSub}
                name="feature"
              >
                <MenuItem value="feature artists in home page">
                  feature artists in home page
                </MenuItem>
                <MenuItem value="exclusive promotion of artworks on social media">
                  exclusive promotion of artworks on social media
                </MenuItem>
                <MenuItem value="Video promotion on YT and social networking">
                  Video promotion on YT and social networking
                </MenuItem>
                <MenuItem value="promotion artist on artsmiley blogs">
                  promotion artist on artsmiley blogs
                </MenuItem>
                <MenuItem value="solo online exhibitions">
                  solo online exhibitions
                </MenuItem>
                <MenuItem value="discount on first refusal opportunity on exhibitions">
                  discount on first refusal opportunity on exhibitions
                </MenuItem>
              </Select>

              <div className={styles.textarea_div}>
                <label className={styles.textarea_label}>Description</label>
                <br />

                <ReactQuill
                  theme="snow"
                  placeholder={`Enter description`}
                  className={`${styles.textarea_blog}  ${styles.customEditor}`}
                  value={subinput.description}
                  onChange={(content) =>
                    handleChangeSub({
                      target: { name: "description", value: content },
                    })
                  }
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }, { font: [] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["bold", "italic", "underline"],
                      ["link", "image"],
                      [{ align: [] }],
                      ["clean"],
                    ],
                  }}
                />
              </div>
            </div>
            <div className={styles.action}>
              <Button
                variant="contained"
                sx={{ borderRadius: "10px" }}
                className={styles.action_btn}
                onClick={curatorSubmit}
              >
                Add
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
