// import { Grid, Typography } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import homeImage from "../../../constant";
// import api from "../../../State/api/Api";
// import { setLoader } from "../../../State/Features/common/loaderSlice";
// import styles from "./Aucations.module.css";

// const ExhibitonContent = [
//   {
//     id: 1,
//     imgUrl1: "/img/Rectangle 5259.png",
//     imgUrl2: "/img/Rectangle 5260.png",
//   },
//   {
//     id: 1,
//     imgUrl1: "/img/Rectangle 5261.png",
//     imgUrl2: "/img/Rectangle 5262.png",
//   },
// ];
// function Aucations() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const [aucationData, setAucationData] = useState();

//   const handleNavigate = (route) => {
//     navigate(route);
//   };

//   const handleFetchAucation = async function () {
//     dispatch(setLoader(true));
//     try {
//       const result = await api.applicationApi("api/user/getexhibitions");
//       if (result && result.status === 200) {
//         setAucationData(result.data);
//         dispatch(setLoader(false));
//       } else if (result.status === 401) {
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };
//   useEffect(() => {
//     handleFetchAucation();
//   }, []);
//   return (
//     <div className={styles.aucations}>
//       <div className={styles.exhibition}>
//         <div className={styles.headerCategory}>
//           <br />
//           <div>
//             <Typography fontWeight="500" fontFamily="marcellus" fontSize="2vw">
//               <span className={styles.headerTitle}>Auction</span>
//             </Typography>
//             <Typography
//               fontWeight="500"
//               ml={8}
//               fontFamily="marcellus"
//               fontSize="1vw"
//             >
//               <span className={styles.headerSubTitle}>
//                 <b
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleNavigate("/")}
//                 >
//                   Home /{" "}
//                 </b>
//                 <span
//                   style={{ cursor: "pointer" }}
//                   onClick={() => handleNavigate("/event")}
//                 >
//                   Events
//                 </span>
//                 / Exhibition
//               </span>
//             </Typography>
//           </div>
//         </div>
//         <div className={styles.main__content}>
//           <div className={styles.link__div}>
//             <Link style={{ textDecoration: "none", color: "black" }}>
//               <Typography fontSize="1vw" fontFamily="marcellus">
//                 CURRENT EXHIBITIONS
//               </Typography>
//             </Link>
//             <div className={styles.verticalLine3}></div>
//             <Link style={{ textDecoration: "none", color: "black" }}>
//               <Typography fontSize="1vw" color="#636365" fontFamily="marcellus">
//                 PAST
//               </Typography>
//             </Link>
//             <div className={styles.verticalLine3}></div>
//             <Link style={{ textDecoration: "none", color: "black" }}>
//               <Typography fontSize="1vw" color="#636365" fontFamily="marcellus">
//                 UPCOMING
//               </Typography>
//             </Link>
//           </div>
//           <hr className={styles.divider} />
//           <div className={styles.exhibitioncards}>
//             <Grid container spacing={8.5}>
//               {ExhibitonContent.map((item) => (
//                 <Grid item xs={12} sm={12} md={12}>
//                   <div className={styles.leftcard}>
//                     <img className={styles.leftImage} src={item.imgUrl1} />
//                     <div className={styles.cardInfo__right}>
//                       <img
//                         className={styles.designImage}
//                         src={"/img/Design.png"}
//                       />
//                       <Typography fontFamily="marcellus" fontSize="2vw">
//                         The Life And Death Of Charles
//                       </Typography>
//                       <Typography fontSize="0.8vw" ml={0.5} color="#636365">
//                         Discover our curator's top exhibitions to see in London{" "}
//                         <br /> this April.
//                       </Typography>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/location.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw">
//                           Berlin | Germany
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/calendar-2.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw">
//                           Monday - Tuesday
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/Group 1000007138.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw" fontWeight="600">
//                           54{" "}
//                         </Typography>
//                         <Typography ml={0.5} fontSize="0.8vw">
//                           Art Showcase
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/Group 1000007139.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw" fontWeight="600">
//                           15{" "}
//                         </Typography>{" "}
//                         <Typography ml={0.5} fontSize="0.8vw">
//                           Artists
//                         </Typography>
//                       </div>
//                       <button className={styles.interested__button}>
//                         <Typography fontSize="0.8vw">Interested</Typography>
//                       </button>
//                       <Typography fontSize="0.8vw" mt={11}>
//                         By Phin Jennings | 01 Apr 2022
//                       </Typography>
//                     </div>
//                   </div>
//                   <div className={styles.rightcard}>
//                     <div className={styles.cardInfo__left}>
//                       <img
//                         className={styles.designImage}
//                         src={"/img/Design.png"}
//                       />
//                       <Typography fontFamily="marcellus" fontSize="2vw">
//                         The Life And Death Of Charles
//                       </Typography>
//                       <Typography fontSize="0.8vw" ml={0.5} color="#636365">
//                         Discover our curator's top exhibitions to see in London{" "}
//                         <br /> this April.
//                       </Typography>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/location.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw">
//                           Berlin | Germany
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/calendar-2.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw">
//                           Monday - Tuesday
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/Group 1000007138.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw" fontWeight="600">
//                           54{" "}
//                         </Typography>
//                         <Typography ml={0.5} fontSize="0.8vw">
//                           Art Showcase
//                         </Typography>
//                       </div>
//                       <div className={styles.logos_flex}>
//                         <img
//                           className={styles.loc__logo}
//                           src={"/img/Group 1000007139.png"}
//                         />
//                         <Typography ml={1} fontSize="0.8vw" fontWeight="600">
//                           15{" "}
//                         </Typography>{" "}
//                         <Typography ml={0.5} fontSize="0.8vw">
//                           Artists
//                         </Typography>
//                       </div>
//                       <button className={styles.interested__button}>
//                         <Typography fontSize="0.8vw">Interested</Typography>
//                       </button>
//                       <Typography fontSize="0.8vw" mt={11}>
//                         By Phin Jennings | 01 Apr 2022
//                       </Typography>
//                     </div>
//                     <img className={styles.rightImage} src={item.imgUrl2} />
//                   </div>
//                 </Grid>
//               ))}
//             </Grid>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Aucations;

import { ThemeProvider } from "@emotion/react";
import { Button, Grid, Typography } from "@mui/material";
import { createTheme } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Aucations.module.css";
import api from "../../../State/api/Api";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import { useDispatch, useSelector } from "react-redux";
import serverimage from "../../../serverConstant";
import { setActiveStatus } from "../../../State/Features/user/activetabSlice";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
});

function Aucations() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [exhibitionData, setExhibitionData] = useState([]);

  const navigateToExhibitionDetail = (id) => {
    navigate(`/user/exhibition/${id}`);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  const fetchExhibitions = async () => {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/user/getexhibits");
      if (result && result.status === 200) {
        setExhibitionData(result.data.responses);
      } else if (result.status === 401) {
        // Handle unauthorized access if necessary
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    fetchExhibitions();
  }, []);

  const auctionStatus = useSelector((state) => state.activetab);
  const handleAuctionStatus = (status) => {
    dispatch(setActiveStatus({ status, maintab: "exhibition" }));
  };

  const filteredExhibitions = exhibitionData.filter((val) => {
    const currentDate = new Date();
    const startDate = new Date(val.start_date.substring(0, 10));
    const endDate = new Date(val.end_date.substring(0, 10));

    return (
      ((auctionStatus.status === "upcoming" && startDate > currentDate) ||
        (auctionStatus.status === "past" && endDate < currentDate) ||
        (auctionStatus.status === "ongoing exhibitions" &&
          startDate <= currentDate &&
          endDate >= currentDate)) &&
      val.is_accepted === "Approved"
    );
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.exhibition}>
        <div className={styles.headerCategory}>
          <br />
          <div>
            <Typography fontWeight="500" variant="h4">
              <span className={styles.headerTitle}>Auction</span>
            </Typography>
            <Typography fontWeight="500" ml={8} variant="h6">
              <span className={styles.headerSubTitle}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/user")}
                >
                  Home /{" "}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/user/")}
                >
                  Events
                </span>{" "}
                / <b>Aucations</b>
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.main__content}>
          <div className={styles.link__div}>
            {["ongoing exhibitions", "past", "upcoming"].map(
              (status, index) => (
                <React.Fragment key={status}>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    onClick={() => handleAuctionStatus(status)}
                  >
                    <Typography
                      fontSize="1vw"
                      fontFamily="marcellus"
                      color={auctionStatus.status === status ? "" : "#636365"}
                    >
                      {status.toUpperCase()}
                    </Typography>
                  </Link>
                  {index < 2 && <div className={styles.verticalLine3}></div>}{" "}
                  {/* Add line only if it's not the last item */}
                </React.Fragment>
              )
            )}
          </div>
          <hr className={styles.divider} />
          <div className={styles.exhibitioncards}>
            <Grid container spacing={0}>
              {filteredExhibitions.length === 0 ? (
                <label style={{ textAlign: "center", width: "100%" }}>
                  No Exhibition Found
                </label>
              ) : (
                filteredExhibitions.map((item, i) => (
                  <Grid item xs={12} sm={12} md={12} key={item._id}>
                    <div
                      className={
                        i % 2 === 0 ? styles.leftcard : styles.rightcard
                      }
                      onClick={() => navigateToExhibitionDetail(item._id)}
                    >
                      {i % 2 === 0 && (
                        <img
                          alt="img"
                          className={styles.leftImage}
                          src={ item.image}
                        />
                      )}
                      <div
                        className={
                          i % 2 === 0
                            ? styles.cardInfo__right
                            : styles.cardInfo__left
                        }
                      >
                        <img
                          alt="img"
                          className={styles.designImage}
                          src={"/img/Design.png"}
                        />
                        <Typography fontFamily="marcellus" fontSize="2vw">
                          {item.title}
                        </Typography>
                        <Typography fontSize="0.8vw" ml={0.5} color="#636365">
                          {item.concept}
                        </Typography>
                        <div className={styles.logos_flex}>
                          <img
                            alt="img"
                            className={styles.loc__logo}
                            src={"/img/location.png"}
                          />
                          <Typography ml={1} fontSize="0.8vw">
                            {item.venue_address} | {item.city}
                          </Typography>
                        </div>
                        <div className={styles.logos_flex}>
                          <img
                            alt="img"
                            className={styles.loc__logo}
                            src={"/img/calendar-2.png"}
                          />
                          <Typography ml={1} fontSize="0.8vw">
                            Monday - Tuesday
                          </Typography>
                        </div>
                        <div className={styles.logos_flex}>
                          <img
                            alt="img"
                            className={styles.loc__logo}
                            src={"/img/Group 1000007138.png"}
                          />
                          <Typography ml={1} fontSize="0.8vw" fontWeight="600">
                            {item.item_ids?.length}
                          </Typography>
                          <Typography ml={0.5} fontSize="0.8vw">
                            Art Showcase
                          </Typography>
                        </div>
                        <div className={styles.logos_flex}>
                          <img
                            alt="img"
                            className={styles.loc__logo}
                            src={"/img/Group 1000007139.png"}
                          />
                          <Typography ml={1} fontSize="0.8vw" fontWeight="600">
                            {item.artist_ids.length}
                          </Typography>
                          <Typography ml={0.5} fontSize="0.8vw">
                            Artists
                          </Typography>
                        </div>
                        <button className={styles.interested__button}>
                          <Typography fontSize="0.8vw">Interested</Typography>
                        </button>
                        <Typography fontSize="0.8vw" mt={11}>
                          By {item.chief_guest} | {item.updatedAt}
                        </Typography>
                      </div>
                      {i % 2 !== 0 && (
                        <img
                          alt="img"
                          className={styles.rightImage}
                          src={ item.image}
                        />
                      )}
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Aucations;
