import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const AffiliateprofileSlice = createSlice({
    name: "affiliateprofiledata",
    initialState,
    reducers: {
        setAffiliateprofiledata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAffiliateprofiledata } = AffiliateprofileSlice.actions;

export const affliateData = (state) => state.affiliateprofiledata;

export default AffiliateprofileSlice.reducer;
