import validator from "validator";
import isEmpty from "./isEmpty";

export const validationForInquiry = ({ email, description }) => {
  let inquiryErrors = {};

  if (validator.isEmpty(email)) {
    inquiryErrors.email = "Email is required";
  } else if (!validator.isEmpty(email) && !validator.isEmail(email)) {
    inquiryErrors.email = "Email is invalid";
  }

  if (validator.isEmpty(description)) {
    inquiryErrors.description = "Description is required";
  }

  return {
    inquiryErrors,
    isValidInquiry: isEmpty(inquiryErrors),
  };
};
