import React, { useEffect, useState } from "react";
import styles from "./Auctiondetailgallery.module.css";
import Commondetailexshibitiongallery from "../../exshibition/commonExshibitiongallery/details/Commondetailexshibitiongallery";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../../../../State/api/Api";
import { setLoader } from "../../../../State/Features/common/loaderSlice";

const data1 = [
 {
  id: 1,
  title: "No. of items",
  content: "06",
 },
];
const data = [
 {
  main_title: "Art Of Space",
  content: "0",
  img: "/img/Image 1.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
 {
  main_title: "The Wonder of India",
  content: "1",
  img: "/img/Image 2.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "Remarks*  Lorem ip dolor sit amet corcresdac",
 },
 {
  main_title: "Way Of Seeing",
  content: "0",
  img: "/img/Image 3.png",
  more_detail: [
   {
    title: "Price",
    content: "$45,000",
   },
   {
    title: "Materials",
    content: "Bronze, Cardboard, Carbon FIber,Ceramic",
   },
   {
    title: "Category",
    content: "Photography",
   },
   {
    title: "Dimensions",
    content: "20 W * 20 H * 50 D in",
   },
  ],
  remark: "",
 },
];
export default function Auctiondetailgallery() {
 const [auctionProfile, setAuctionProfile] = useState(null);
 const { id } = useParams();
 const dispatch = useDispatch();

 const handleFetchAuctionDetail = async function () {
  dispatch(setLoader(true));
  try {
   const result = await api.applicationApi(`api/user/getauctions/${id}`);
   if (result && result.status === 200) {
    setAuctionProfile(result.data.response);
    dispatch(setLoader(false));
   } else if (result.status === 401) {
   }
  } catch (err) {
   console.log(err);
  }
 };
 useEffect(() => {
  handleFetchAuctionDetail();
 }, []);
 return (
  <div className={styles.mains}>
   <Commondetailexshibitiongallery type="auction_gallery" data1={data1} data={auctionProfile} />
  </div>
 );
}
