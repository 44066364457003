import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const ArtistprofileSlice = createSlice({
    name: "artistprofiledata",
    initialState,
    reducers: {
        setArtistprofiledata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setArtistprofiledata } = ArtistprofileSlice.actions;

export const ArtistProfileData = (state) => state.aritistprofiledata;

export default ArtistprofileSlice.reducer;
