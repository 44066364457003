import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 data: null,
 orderType: null
};

const wishlistSlice = createSlice({
 name: "wishlistdata",
 initialState,
 reducers: {
  setWishlistdata(state, action) {
   state.data = action.payload;
  },
  setWishlistdataOrderType(state, action) {
    state.orderType = action.payload;
   },
 },
});

export const { setWishlistdata,setWishlistdataOrderType } = wishlistSlice.actions;

export default wishlistSlice.reducer;
