import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const blogSliceSlug = createSlice({
  name: "blogData",
  initialState,
  reducers: {
    setBlogSlugdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setBlogSlugdata } = blogSliceSlug.actions

// export const testimonial = (state) => state.testmonialdata;

export default blogSliceSlug.reducer
