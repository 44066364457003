import { createTheme, Grid, ThemeProvider, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchBlogData } from "../../../State/api/superadminApi"
import { useNavigate } from "react-router-dom"
import homeImage from "../../../constant"
import serverimage from "../../../serverConstant"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import styles from "./blogDetails.module.css"
import moment from "moment"

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
  },
})
function BlogDetails() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [pressData, setPressData] = useState()
  const [data, setData] = useState({ offset: 1 })

  const blogdt = useSelector((state) => state.blogdata.data)
  const fetchData = () => {
    dispatch(fetchBlogData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  // const d = Date.now()
  // const dates = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(d);
  
  const navigatetopressdetails = (id) => {
    navigate(`/user/blog-details/${id}`)
  }

  const handleNavigate = (route) => {
    navigate(route)
  }
  // const handleFetchPress = async function () {
  //   dispatch(setLoader(true))
  //   try {
  //     const result = await api.applicationApi("api/user/pressrelease")
  //     if (result && result.status === 200) {
  //       setPressData(result.data)
  //       dispatch(setLoader(false))
  //     } else if (result.status === 401) {
  //     }
  //   } catch (err) {
  
  //   }
  // }
  // useEffect(() => {
  //   handleFetchPress()
  // }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.pressreleases}>
        <div className={styles.headerCategory}>
          <br />
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',textAlign:'center'}}>
            <Typography variant="h4">
              <span >Blog</span>
            </Typography>
            <Typography ml={10} variant="h6">
              <span className={styles.headerSubTitle}>
                <b
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/")}
                >
                  Home /{" "}
                </b>
                Blog
              </span>
            </Typography>
          </div>
        </div>
        <div className={styles.pressreleases__grid}>
          <Grid container>
          {blogdt &&
  blogdt.data
    .filter((item) => new Date(item.date) <= new Date()) // Filter blogs with date <= today
    .map((item) => (

                <Grid item xs={12} sm={6} md={3}>
                  <div
                    className={styles.card__main}
                    onClick={() => navigatetopressdetails(item.title_slug)}
                  >
                    <img
                      className={styles.card__image}
                      src={ item.media}
                      width="100px"
                      height="200px"
                      alt="img"
                    />
                    <Typography
                      fontSize="0.85rem"
                      fontWeight="600"
                      fontFamily="DM Sans"
                    >
                      {item?.title}
                    </Typography>
                    <div className={styles.card__prof}>
                      <Typography
                        fontWeight="500"
                        fontSize="0.85rem"
                        color="#636365"
                        fontFamily="DM Sans"
                      >
                        <b>{moment(item.date).format("DD/MM/YYYY")}</b>
                      </Typography>
                    </div>
                    <Typography
                      fontSize="0.8rem"
                      fontFamily="DM Sans"
                      sx={{
                        textAlign: "justify",
                        marginTop: 0.5,
                      }}
                    >
                      {/* {item.description} */}<p
                        dangerouslySetInnerHTML={{
                          __html:
                            item &&
                            item.description &&
                            item.description.length > 200
                              ? item.description.substring(0, 200) + "..."
                              : item && item.description
                              ? item.description
                              : "",
                        }}
                      ></p>
                      
                    </Typography>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default BlogDetails
