import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  fetchTestimonialData,
  handleAddTestimonial,
} from "../../../../State/api/superadminApi"
import { setTestimonialdata } from "../../../../State/Features/superadmin/testimonialSlice"
import Pagination from "../Pagination/Pagination"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Fourcolimg from "../Table/Fourcolumn/Fourcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Testimonial.module.css"

const head = [
  {
    id: 1,
    label: "Image",
  },
  {
    id: 2,
    label: "Customer Name",
  },
  {
    id: 3,
    label: "Position",
  },
  {
    id: 4,
    label: " Created Date",
  },
  {
    id: 5,
    label: "Description",
  },
]

const sortdata = [
  { label: "Oldest to Newest", value: "low" },
  { label: "Newest to Oldest", value: "high" },
]
const filterdata = [
  { label: "Name (A-Z)", value: "name-asc" },
  { label: "Name (Z-A)", value: "name-desc" },
]
export default function Testimonial() {
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [data, setData] = useState({ offset: 0 })
  const [open, setOpen] = useState(false)
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const dispatch = useDispatch()
  const testdata = useSelector((state) => state.testmonialdata.data)
  console.log(testdata, "testdata--")

  const fetchData = () => {
    dispatch(fetchTestimonialData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const handleAdd = (dt) => {
    dispatch(handleAddTestimonial(dt, setOpen, fetchData))
  }
  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })

    if (value === "name-asc") {
      dispatch(
        setTestimonialdata({
          data:
            testdata.data &&
            [...testdata.data].sort((a, b) =>
              a.customer_name.toLowerCase() > b.customer_name.toLowerCase()
                ? 1
                : -1
            ),
          totalCount: testdata.totalCount,
        })
      )
    } else if (value === "name-desc") {
      dispatch(
        setTestimonialdata({
          data:
            testdata.data &&
            [...testdata.data].sort((a, b) =>
              a.customer_name.toLowerCase() < b.customer_name.toLowerCase()
                ? 1
                : -1
            ),
          totalCount: testdata.totalCount,
        })
      )
    } else {
      fetchData()
    }
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setTestimonialdata({
          data:
            testdata.data &&
            [...testdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() < b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: testdata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setTestimonialdata({
          data:
            testdata.data &&
            [...testdata.data].sort((a, b) => {
              if (a.createdAt.toLowerCase() > b.createdAt.toLowerCase())
                return -1
            }),
          totalCount: testdata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      {" "}
      <div className={styles.components}>
        <AlertsSuccess />
        {open ? (
          <Addimgpopup
            handleAdd={handleAdd}
            poptype="testimonials"
            open={open}
            setOpen={setOpen}
          />
        ) : (
          ""
        )}
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
          setButton={setButton}
        />
        <Fourcolimg
          activetab="testimonial"
          head={head}
          data={
            testdata &&
            testdata.data.filter((val) => {
              const search = condition.search.toLowerCase().trim()
              const nameMatch =
                val.customer_name &&
                val.customer_name.toLowerCase().includes(search)

              const positionMatch =
                val.position && val.position.toLowerCase().includes(search)
              const descMatch =
                val.description &&
                val.description.toLowerCase().includes(search)

              return nameMatch || positionMatch || descMatch
            })
          }
        />
        <Pagination
          totaldata={testdata && testdata.totalCount}
          pagination={data}
          setPagination={setData}
        />
      </div>
    </div>
  )
}
