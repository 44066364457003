import {
  createTheme,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  Container,
  Grid,
  Button,
  // Slider
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import styles from "./Artworks.module.css";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../common/alert/Alert";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ProductSidebar from "./ProductSidebar";
import { useNavigate, useParams } from "react-router-dom";
import serverimage from "../../../serverConstant";
import api from "../../../State/api/Api";
import CircularLoader from "../../../common/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import {
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
  handelAddWishlist,
  handelAddtocart,
} from "../../../State/api/userApi";
import { setSearchFilter } from "../../../State/Features/user/filterationSlice";
import { removeUser } from "../../../State/Features/common/userSlice";

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "0.85vw",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "0.85vw",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
});

const sortdata = [
  { label: "Price ↓", value: "low" },
  { label: "Price ↑", value: "high" },
];
function Artworks() {
  const [open, setOpen] = useState(false);
  const [allitems, setAllitems] = useState();
  const [style, setStyle] = useState([]);

  const dispatch = useDispatch();
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const categorydt = useSelector((state) => state.allcategoryData.data);
  // const [filter, setFilter] = useState({
  //   type: [],
  //   category: [],
  //   style: [],
  //   artist_type: [],
  //   artist: [],
  //   country: [],
  //   orientation: [],
  //   color: [],
  //   search: "",
  // });
  const { id } = useParams();

  const handleClick = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const handleFetchStyles = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getstyles`);
      if (result && result.status === 200) {
        setStyle(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  const handleFetchCategories = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getcategories/${id}`);
      if (result && result.status === 200) {
        setAllitems(result.data.artworks);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        );
      }
    } catch (err) {
    }
  };
  const handleFetchCategoris = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/getcategories/${id}`);
      if (result && result.status === 200) {
        setAllitems(result.data.artworks);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  };
  useEffect(() => {
     handleFetchCategories();
    dispatch(fetchProductType());
    dispatch(fetchArtistTypes());
    // dispatch(fetchUserArtist({}));
    // dispatch(fetchCategoryData({}));
    handleFetchStyles();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const navigateToArtworkDetail = (ids) => {

    // const recent = localStorage.getItem("recently_view");
    // if (recent) {
    //   if (!recent.includes(ids)) {
    //     const r = JSON.parse(recent) || []
    //     r.push(ids)
    //     localStorage.setItem("recently_view", JSON.stringify(r))
    //   }
    //   else {
    //     const include = JSON.parse(recent) || []
    //     const index = include.indexOf(ids);
    
    //     include.splice(index, 1);
    //     include.push(ids)
    //     localStorage.setItem("recently_view", JSON.stringify(include))
    //   }
    // } else {
    //   const recently = [ids]
    //   localStorage.setItem("recently_view", JSON.stringify(recently))
    // }
    navigate(`/user/allcategory/${id}/${ids}`);
  };
  const handleWishlist = (e, id) => {
    e.stopPropagation();
    if (userty.signed === false) {
      Errornotify("Please login ");
      // navigate("/artsmiley/login")
    } else {
      dispatch(handelAddWishlist({ item_id: id }));
    }
  };
  const handleAddCart = (e, id) => {
    e.stopPropagation();
    const val = id;
    const index = myCart && myCart.result.map((item) => item.item_id._id);
    if (userty.signed === false) {
      Errornotify("Please login ");
    } else if (index.includes(val)) {
      Errornotify("Already in your basket");
    } else {
      dispatch(handelAddtocart({ item_id: val, quantity: 1 }));
    }
  };
  const userty = useSelector((state) => state.user.data);
  const myCart = useSelector((state) => state.mycartData.data);
  const loader = useSelector((state) => state.loader);
  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const userartists = useSelector((state) => state.userartistData);
  const allcategory = useSelector((state) => state.allcategoryData);
  const filter = useSelector((state) => state.filteration);
  const [condition, setCondition] = useState({ sorting: "" });

  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchFilter(value));
  };
  const handleSorting = (event) => {
    const value = event.target.value;
    if (value === "low") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAllitems(
          // data:
          allitems &&
          [...allitems].sort((a, b) => {
            return a.sale.price - b.sale.price;
          }),
          // totalCount: allitems.totalCount,
        )
      );
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value });
      dispatch(
        setAllitems(
          // data:
          //   allitems &&
          [...allitems].sort((a, b) => {
            return b.sale.price - a.sale.price;
          }),
          // totalCount: allitems.totalCount,
        )
      );
    } else {
      handleFetchCategories();
      setCondition({ ...condition, sorting: value });
    }
  };
  const filters = allitems && allitems.map(val => (val.style_id.map(c => (c._id))))
  return (
    <div className={styles.trendingcategory}>
      <AlertsSuccess />
      <ThemeProvider theme={theme}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
            <br />
            <div>
              <Typography variant="h4">
                <span className={styles.headerTitle}>Artworks</span>
              </Typography>
              <Typography variant="h6">
                <span className={styles.headerSubTitle} style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/")}>
                  Home /{" "} </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleNavigate("/user/allcategory")}
                >
                  Category
                </span>{" "}
                / <span>
                  <b
                    style={{ cursor: "pointer" }}
                  >{categorydt && categorydt.allcategories.filter(val => val._id === id)[0].name}</b></span>

              </Typography>
            </div>
          </div>
        </div>
        {/* header section */}
        <div className={styles.header__div}>
          <div className={styles.searchBar__div}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <FormControl variant="standard">
                <TextField
                  id="input-with-icon-textfield"
                  label=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter.search}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  variant="outlined"
                />
              </FormControl>
            </Box>
          </div>
          <div className={styles.filter__label}>
            <span className={styles.filter__span}>Sort By Price</span>
          </div>
          <div className={styles.filter__div}>
            <FormControl sx={{ minWidth: 120, color: "red" }}>
              <Select
                value={condition && condition.sorting}
                onChange={handleSorting && handleSorting}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                sx={{
                  color: "#636365",
                  border: "0.6px solid #665D6F",
                  borderRadius: "6px",
                }}
              >
                <MenuItem value="">Sort by</MenuItem>
                {sortdata && sortdata.map(val => (
                  <MenuItem value={val.value}>{val.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={styles.ProductContent}>
          {/* sidebar */}

          <ProductSidebar
            value_height={value_height}
            value_width={value_width}
            priceRange={priceRange}
            setValueHeight={setValueHeight}
            filter={filter}
            style={style}
            setFilter={filter}
            setPriceRange={setPriceRange}
            setValueWidth={setValueWidth}
            producttypeData={productType.data}
            artisttypeData={artistTypes.data}
            allartistData={userartists.data}
            allcategoryData={allcategory.data && allcategory.data.allcategories}
          />
          <div className={styles.ProductCards}>
            <Container>
              <Grid
                container
                columnSpacing={{ xs: 2, md: 5 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) :
                  (
                    allitems &&
                      allitems
                        .filter(
                          (val, i) =>
                            val.is_accepted === "Approved" &&
                            val.sale.price >= filter.price[0] &&
                            val.sale.price <= filter.price[1] &&
                            val.height >= value_height[0] &&
                            val.height <= value_height[1] &&
                            val.width >= value_width[0] &&
                            val.width <= value_width[1] &&
                            (filter.artist.length === 0
                              ? val
                              : filter.artist.includes(
                                val.artist_id && val.artist_id._id
                              )) &&
                            (filter.category.length === 0
                              ? val
                              : filter.category.includes(val.category_id)) &&
                            (filter.style.length === 0
                              ? val
                              : filter.style.some(element => (
                                filters && filters[i].includes(element)
                              ))) &&
                            (filter.country.length === 0
                              ? val
                              : filter.country.includes(val.country_id)) &&
                            (filter.type.length === 0
                              ? val
                              : filter.type.includes(val.item_type_id)) &&
                            (filter.artist_type.length === 0
                              ? val
                              : filter.artist_type.includes(
                                val.artisttype_id && val.artisttype_id._id
                              )) &&
                            (filter.orientation.length === 0
                              ? val
                              : filter.orientation.includes(
                                val.orientation_id && val.orientation_id
                              )) &&
                            (filter.color.length === 0
                              ? val
                              : filter.color.includes(
                                val.color_id && val.color_id
                              )) &&
                            (filter.search === ""
                              ? val
                              : val.name
                                .toLowerCase()
                                .includes(filter.search.toLowerCase()))
                        )
                        .length === 0 ? <Grid
                          xs={12}
                          sm={12}
                          md={12}
                          item
                          sx={{ mb: 2, textAlign: "center", mt: 2 }}
                        >No Art Found</Grid> :
                      allitems && allitems
                        .filter(
                          (val, i) =>
                            val.is_accepted === "Approved" &&
                            val.is_deleted === false &&
                            val.sale.price >= filter.price[0] &&
                            val.sale.price <= filter.price[1] &&
                            val.height >= value_height[0] &&
                            val.height <= value_height[1] &&
                            val.width >= value_width[0] &&
                            val.width <= value_width[1] &&
                            (filter.artist.length === 0
                              ? val
                              : filter.artist.includes(
                                val.artist_id && val.artist_id._id
                              )) &&
                            (filter.category.length === 0
                              ? val
                              : filter.category.includes(val.category_id)) &&
                            (filter.style.length === 0
                              ? value_width
                              : filter.style.some(element => (
                                filters && filters[i].includes(element)
                              ))) &&
                            (filter.country.length === 0
                              ? val
                              : filter.country.includes(val.country_id)) &&
                            (filter.type.length === 0
                              ? val
                              : filter.type.includes(val.item_type_id)) &&
                            (filter.artist_type.length === 0
                              ? val
                              : filter.artist_type.includes(
                                val.artisttype_id && val.artisttype_id._id
                              )) &&
                            (filter.orientation.length === 0
                              ? val
                              : filter.orientation.includes(
                                val.orientation_id && val.orientation_id
                              )) &&
                            (filter.color.length === 0
                              ? val
                              : filter.color.includes(
                                val.color_id && val.color_id
                              )) &&
                            (filter.search === ""
                              ? val
                              : val.name
                                .toLowerCase()
                                .includes(filter.search.toLowerCase()))
                        )

                        .map((item) => (
                          <Grid
                            xs={12}
                            sm={6}
                            md={4}
                            key={item._id}
                            item
                            sx={{ mb: 2 }}
                          >
                            <div
                              className={styles.card__main}
                              onClick={() => navigateToArtworkDetail(item._id)}
                            >
                              <div className={styles.card__top}>
                                <img
                                  className={styles.cardImage}
                                  src={ item.image}
                                  alt='img'
                                />
                                <div className={styles.img_icon}>
                                  <IconButton
                                    aria-label="add to shopping cart"
                                    sx={{
                                      color: "white",
                                      backgroundColor: "black",
                                      "&:hover": { backgroundColor: "black" },
                                    }}
                                  >
                                    <AddShoppingCartIcon
                                      onClick={(e) => handleAddCart(e, item._id)}
                                    />
                                  </IconButton>
                                  <br />
                                  <IconButton
                                    sx={{
                                      color: "white",
                                      backgroundColor: "black",
                                      marginTop: 1,
                                      "&:hover": { backgroundColor: "black" },
                                    }}
                                  >
                                    <FavoriteBorderIcon
                                      onClick={(e) => handleWishlist(e, item._id)}
                                    />
                                  </IconButton>
                                </div>
                              </div>
                              <div className={styles.card__middle}>
                                <div className={styles.card__middleLeft}>
                                  <Typography
                                    fontSize="0.95rem"
                                    fontWeight="700"
                                    mt={1}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    fontSize="0.8rem"
                                    fontWeight="700"
                                    mt={1}
                                    color="#ccc"
                                  >
                                    {item.artist_id && item.artist_id.first_name}{" "}
                                    {item.artist_id && item.artist_id.last_name}
                                  </Typography>
                                </div>
                                <div className={styles.card__middleRight}>
                                  <FavoriteBorderIcon fontSize="small" />
                                  <Typography fontWeight="400" ml={0.5}>
                                    522
                                  </Typography>
                                </div>
                              </div>
                              <hr />
                              <div className={styles.card__bottom}>
                                <div className={styles.buy__text}>
                                  <Button>
                                    <Typography
                                      fontWeight="600"
                                      color="#ccc"
                                      fontSize="0.65rem"
                                    >
                                      Buy
                                    </Typography>
                                    <Typography
                                      color="black"
                                      fontWeight="500"
                                      fontSize="0.85rem"
                                      mt={4}
                                      ml={-3}
                                    >
                                      <b>$ {item.sale && item.sale.price}</b>
                                    </Typography>
                                  </Button>
                                </div>
                                <div className={styles.verticalLine}></div>
                                <div className={styles.rent__text}>
                                  <Button>
                                    <Typography
                                      fontWeight="600"
                                      color="#ccc"
                                      fontSize="0.65rem"
                                    >
                                      Rent/Month
                                    </Typography>
                                    <Typography
                                      color="black"
                                      fontWeight="500"
                                      fontSize="0.85rem"
                                      mt={4}
                                      ml={-6.5}
                                      mr={3}
                                    >
                                      <b>$ {item.rent && item.rent.rent_price}</b>
                                    </Typography>
                                  </Button>
                                </div>
                                <div className={styles.verticalLine}></div>
                                <div className={styles.print__text}>
                                  <Button>
                                    <Typography
                                      fontWeight="600"
                                      color="#ccc"
                                      fontSize="0.65rem"
                                    >
                                      Print
                                    </Typography>
                                    <Typography
                                      color="black"
                                      fontWeight="500"
                                      fontSize="0.85rem"
                                      mt={4}
                                      ml={-3.75}
                                    >
                                      <b>$ {item.print && item.print.print_price}</b>
                                    </Typography>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))
                  )}
              </Grid>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default React.memo(Artworks);
