import React, { useState } from 'react';
import styles from './Galleryupload.module.css';
import Commonuploadart from './commonUploadart/Commonuploadart'
import Uploadpopup from '../../components/popups/uploadPopup/Uploadpopup';

export default function Galleryuploadart() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.mains}>
    {open ? <Uploadpopup open={open} setOpen={setOpen} /> : ''}
        <Commonuploadart setOpen={setOpen} />
    </div>
  )
}
