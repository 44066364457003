import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminCategory = (cat) => {
  let catErrors = {}

  if (validator.isEmpty(cat.name)) {
    catErrors.name = "Name is required"
  }

  if (!cat.image || cat.image === null) {
    catErrors.image = "Image is required"
  }

  return {
    catErrors,
    isValid: isEmpty(catErrors),
  }
}
