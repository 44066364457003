import React, { useState, useEffect, useRef, Suspense } from "react"
import styles from "./Homepage.module.css"
import { Helmet } from "react-helmet"
import TawkMessengerReact from "@tawk.to/tawk-messenger-react"
import { Container } from '@mui/material';
import {
  Box,
  Button,
  createMuiTheme,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Rating,
  TextField,
  Typography,
} from "@mui/material"
import TrustBox from "./TrustPilot"
import { ThemeProvider } from "@emotion/react"
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm"
import Slider from "./components/Slider"
// import "fontsource-marcellus";
import { useNavigate } from "react-router-dom"
import {
  fetcCompaingnsData,
  fetchHeroSectionData,
} from "../../../State/api/superadminApi"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import homeImage from "../../../constant"
// import Slider from "react-slick";

import "swiper/css/navigation"
import "swiper/css/scrollbar"
// Import Swiper styles
import "swiper/css"
import Homemodel from "./components/Homemodel"
import StarIcon from "@mui/icons-material/Star"
import { AlertsSuccess, Errornotify } from "../../../common/alert/Alert"
import api from "../../../State/api/Api"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import { useDispatch, useSelector } from "react-redux"
import {
  AddMailSubscription,
  fetchAllItemData,
  fetchArtist,
  fetchCategoryData,
  fetchFetchOffers,
  fetchUserArtist,
  handelFetchRecentlyView,
  handleFetchBuyerDetail,
} from "../../../State/api/userApi"
import CircularLoader from "../../../common/loader/Loader"
import serverimage from "../../../serverConstant"

import axios from "axios"
import {
  initializeCurrencyFormatter,
  formatNewCurrency,
} from "../../../utils/currencyFormat"
import capitalizeWords from "../../../utils/capitalizeContent"
const SliderCarousel = React.lazy(() => import('./components/SliderCarousel'));
const partners = [
  {
    id: 1,
    src: "/img/nisource.png",
  },
  {
    id: 2,
    src: "/img/fuse.png",
  },
  {
    id: 3,
    src: "/img/cognizant.png",
  },
  {
    id: 4,
    src: "/img/msoffice.png",
  },
  {
    id: 5,
    src: "/img/nisource.png",
  },
  {
    id: 6,
    src: "/img/intel.png",
  },
  {
    id: 7,
    src: "/img/msoffice.png",
  },
]
const pricedt = [
  {
    _id: 1,
    img: "/img/Rectangle 5230.png",
    price: "3000",
  },
  {
    _id: 2,
    img: "/img/Rectangle 5231.png",
    price: "4000",
  },
  {
    _id: 3,
    img: "/img/Rectangle 5230.png",
    price: "5000",
  },
  {
    _id: 4,
    img: "/img/Rectangle 5231.png",
    price: "5500",
  },
  {
    _id: 5,
    img: "/img/Rectangle 5230.png",
    price: "3500",
  },
]
const rating = [
  {
    id: 1,
    heading: "Got my artwork and it's beautifull",
    des: "Got my artwork and it's beautifull Got my artwork and it's beautifull",
    name: "Wayne C",
    hint: "Showing our 5 star reviews",
    duration: 1,
  },
  {
    id: 2,
    heading: "Got my artwork",
    des: "Got my artwork",
    name: "Wayne C",
    hint: "",
    duration: 1,
  },
  {
    id: 3,
    heading: "Got my artwork ",
    des: "Got my artwork ",
    name: "Wayne C",
    hint: "",
    duration: 1,
  },
  {
    id: 4,
    heading: "Got my artwork ",
    des: "Got my artwork and ",
    name: "Wayne C",
    hint: "",
    duration: 1,
  },
  {
    id: 5,
    heading: "Got my artwork ",
    des: "Got my artwork ",
    name: "Wayne C",
    hint: "",
    duration: 1,
  },
]
const newestpaintingData = [
  {
    id: 1,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 367.png",
  },
  {
    id: 2,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 368.png",
  },
  {
    id: 3,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 369.png",
  },
  {
    id: 4,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 370.png",
  },
  {
    id: 5,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5198.png",
  },
  {
    id: 6,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5199.png",
  },
  {
    id: 7,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5200.png",
  },
  {
    id: 8,
    title: "Galexy On Earth",
    artist: "John Deo",
    price: "$ 3,254",
    rentpermonth: "$ 680",
    url: "/img/Rectangle 5201.png",
  },
]
const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#C1C1C1",
      main: "#000000",
      dark: "#C1C1C1",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffffff",
      main: "#C1C1C1",
      dark: "#ffffff",
      contrastText: "#000",
    },
  },
  typography: {
    h2: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
      fontWeight: 400,
      // lineHeight: "50.16px",
    },
    h1: {
      fontFamily: "Marcellus",
      fontSize: "4vw",
      fontWeight: 400,
      paddingTop: "0%",
      paddingBottom: "0%",
      // lineHeight: "87.77px",
    },
    heading3: {
      fontFamily: "Marcellus",
      fontSize: "1.5vw",
      fontWeight: 400,
    },
    imgText: {
      fontFamily: "Marcellus",
      fontWeight: 400,
      align: "center",
      fontSize: "1.15vw",
    },
    subscribeHeading: {
      fontFamily: "Marcellus",
      color: "white",
      fontWeight: 400,
      fontSize: "2vw",
    },
  },
})

function Homepage(props) {
  const tawkMessengerRef = useRef()

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize()
  }
  const [data, setData] = useState({ offset: 1 })
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState(2)
  const [curatorCollection, setCuratorCollection] = useState([]);
  console.log(curatorCollection,'curatorCollection')
  const [testimonial, setTestimonial] = useState()
  const [recent, setRecent] = useState()
  const current_date = new Date()
  // current_date.setDate(current_date.getDate() - 5);
  

  const categorydt = useSelector((state) => state.allcategoryData)
  const offersdt = useSelector((state) => state.offersData)
  const orderType = useSelector((state) => state.allitemData.orderType)
  console.log(orderType,'orderType')
  const allitemdt = useSelector((state) => state.allitemData)
  console.log(allitemdt,'allitemdt')
  const compaingns = useSelector((state) => state.compaingnsdata.data)
  const loader = useSelector((state) => state.loader)
  const userartists = useSelector((state) => state.userartistData)
  const userDetail = useSelector((state) => state.buyerdetail.data)
  const usertype = useSelector((state) => state.user.data)
  const fol_id =
    userDetail &&
    userDetail.data.followings &&
    userDetail.data.followings.map((val) => val._id?._id)
  const abc =
    allitemdt.data &&
    allitemdt.data.filter(
      (val) => fol_id && fol_id.includes(val?.artist_id?._id)
    )

  // const sort_by_date = [...allitemdt.data] && [...allitemdt.data].sort((a, b) => {
  //   if (a.updatedAt > b.updatedAt) {
  //     return -1
  //   }
  // })
  
  const dat = allitemdt.data && allitemdt.data[0] && allitemdt.data[0].updatedAt

  var dateFormat =
    current_date.getFullYear() +
    "-" +
    (current_date.getMonth() + 1) +
    "-" +
    (current_date.getDate() - 5) +
    "-" +
    current_date.getTime()

  const dt_date = new Date(dat)
  const today_date = new Date(dateFormat)

  const b = JSON.parse(localStorage.getItem("recently_view"))
  const allitem_id =
    allitemdt.data && allitemdt.data.filter((v) => b && b.includes(v._id))

  // const sorted = allitem_id && allitem_id.sort((a, ab) => b.indexOf(a) - b.indexOf(ab));
  const a = allitem_id && allitem_id.filter((c, i) => b[i] === c._id)

  // This code is contributed by Nitin Jangra

  function sortAccording(A1, A2) {
    // Map to store the indices of elements in the second array
    let index = new Map()
    for (let i = 0; i < A2.length; i++) {
      // Consider only first occurrence of element
      if (!index.has(A2[i])) {
        // Assign value of i+1
        index.set(A2[i], i + 1)
      }
    }

    A1?.sort((a, b) => {
      let idx1 = index.get(a._id) || 0
      let idx2 = index.get(b._id) || 0

      // If both a and b are not present in the second array, sort them in ascending order
      if (idx1 === 0 && idx2 === 0) {
        return a - b
      }

      // If only b is present in the second array, b comes before a
      if (idx1 === 0) {
        return 1
      }

      // If only a is present in the second array, a comes before b
      if (idx2 === 0) {
        return -1
      }

      // If both a and b are present in the second array, sort them according to their respective indices
      return idx1 - idx2
    })
  }

  // allitem_id && sortAccording(allitem_id, b);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    )
  }
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true)
    }, 2000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const dispatch = useDispatch()

  const handleFetchTestimonial = async function () {
    try {
      dispatch(setLoader(true))
      const result = await api.applicationApi("api/user/testimonial", "GET", {})
      if (result && result.status === 200) {
        setTestimonial(result.data.responses)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(setLoader(false))
      } else {
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }

  const fetchCuratorCollections = async () => {
    try {
      dispatch(setLoader(true))
      const result = await api.applicationApi("api/art/fetchAllCuratorArts", "GET", {})
      if (result && result.status === 200) {
        setCuratorCollection(result.data)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
        dispatch(setLoader(false))
      } else {
      }
    } catch (err) {
      dispatch(setLoader(false))
    }
  }
  const handleClickBestSeller = (id) => {
    navigate(`/user/bestseller/${id}`)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  const handleClickTrending = (id) => {
    navigate(`/user/trending/${id}`)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  useEffect(() => {
    fetchCuratorCollections()
    initializeCurrencyFormatter()
    handleFetchTestimonial()
    dispatch(fetchCategoryData({}))
    dispatch(fetchFetchOffers())
    dispatch(fetcCompaingnsData({ offset: 1 }))
    dispatch(fetchAllItemData())
    dispatch(fetchUserArtist({}))
    dispatch(handleFetchBuyerDetail())

    dispatch(handelFetchRecentlyView({ limit: 90 }, setRecent))
  }, [])
  const [email, setEmail] = useState("")
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(email_regex)) {
      dispatch(AddMailSubscription({ email: email }, setOpen, setEmail))
    } else {
      Errornotify("Please Enter Valid email")
    }
  }
  // const a = userartists.data
  //   ? userartists.data.filter((val) => (
  //     val.is_featured === true && val.is_accepted === "Approved"
  //   ))
  //   : []

  //hero sectn
  const herodata = useSelector((state) => state.heroData.data)
  const fetchData = () => {
    dispatch(fetchHeroSectionData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  return (
    <ThemeProvider theme={theme}>
      <AlertsSuccess />
      {open ? (
        <Homemodel
          handleSubmit={handleSubmit}
          email={email}
          setEmail={setEmail}
          handleChange={handleChange}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      <div className={styles.side_links}>
        <img src={"/img/FB.png"} width="25px" height="30px" alt="img" />
        <img src={"/img/Twitter.png"} width="25px" height="30px" alt="img" />
        <img src={"/img/Mail.png"} width="25px" height="30px" alt="img" />
        <img src={"/img/Pinterest.png"} width="25px" height="30px" alt="img" />
        <img src={"/img/Add.png"} width="25px" height="30px" alt="img" />
      </div>
      <div className={styles.homepage}>
        {/* <div className={styles.homepage__LandingPage}>
          <div className={styles.landingpage__Text}>
            <Typography variant="h2">Latest Collection</Typography>
            <br />
            <Typography variant="h1">
              Original Artworks <br />& Paintings
            </Typography>
            <br />
            <Button
              onClick={() => {
                navigate("/user/allcategory")
                window.scrollTo({ top: 0, behavior: "smooth" })
              }}
              sx={{
                width: { xs: "100px", sm: "156px" },
                height: { xs: "36px", sm: "56px" },
              }}
              className={styles.lp__button}
              variant="contained"
            >
              <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
                Buy Now
              </Typography>
            </Button>
          </div>
          <div className={styles.landingpage__Images}>
            <div className={styles.images && styles.image__item1}>
              <img
                className={styles.top__Image}
                src={"/img/Rectangle 5264.png"}
                alt="img"
              />
            </div>
            <div className={styles.images && styles.image__item2}>
              <img
                className={styles.top__Image2}
                src={"/img/Rectangle 5266.png"}
                alt="img"
              />
            </div>
            <div className={styles.images && styles.image__item3}>
              <img
                className={styles.top__Image3}
                src={"/img/Rectangle 365.png"}
                alt="img"
              />
            </div>
          </div>
        </div> */}
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
           <SliderCarousel data={herodata ? herodata.data : []} type="hero" />
      </Suspense>
       
        )}
        
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="lg">
          <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Newest Paintings</h3>
          <p className={styles.sub_title}>Latest Additions, Brand New Art, Fresh Off the Easel</p>
          </div>
        
        {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
                <SliderCarousel
            data={
              allitemdt.data
                ? allitemdt.data.filter(
                    (val) => val.is_accepted === "Approved"
                  ).filter((val) => 
                    val.category_id?.name?.toLowerCase() === "painting"
                  )
                : []
            }
            type="paintings"
            orderType={orderType}
            formatNewCurrency={formatNewCurrency}
          />
          </Suspense>
      
        )}
          </Container>
        </div>
        
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Shop By Category</h3>
          <p className={styles.sub_title}>Explore Categories, Browse by Style, Shop by Theme</p>
          </div>

          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
          <SliderCarousel
              data={categorydt.data ? categorydt.data.allcategories : []}
              type=""
          />
      </Suspense>
        )}
        </Container>
        </div>
        
       
        <div className={styles.best_seller_main}>
          <div className={styles.banner}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="img"
            />
            <h2 className={styles.headings}>Best Sellers</h2>
          </div>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <Box sx={{ width: { sm: "70%", xs: "95%" }, m: "auto" }}>
              <ImageList
                variant="masonry"
                sx={{
                  columnCount: {
                    xs: "2 !important",
                    sm: "2 !important",
                    md: "3 !important",
                    lg: "4 !important",
                    // xl: '5 !important',
                  },
                }}
                gap={10}
              >
                <>
                  {allitemdt.data &&
                    allitemdt.data
                      .filter((val) => {
                        return val.is_best_seller === true
                      })
                      .map((item) => (
                        <ImageListItem
                          className={styles.image_list}
                          key={item._id}
                          onClick={() => handleClickBestSeller(item._id)}
                        >
                          <img
                            src={item.image}
                            // srcSet={`${item.category_id.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.artist_id && item.artist_id.first_name}
                            loading="lazy"
                          />
                          <h3 className={styles.cards_head}>{capitalizeWords(item.name)}</h3>
                          <p className={styles.cards_artist}>
                            {capitalizeWords(item.artist_id && item.artist_id.first_name)}{" "}
                            {capitalizeWords(item.artist_id && item.artist_id.last_name)}
                          </p>
                          {/* <ImageListItemBar sx={{ m: 0 }} className={styles.cards_head} position="below" title={item.painting_name} />
                <ImageListItemBar sx={{ m: 0 }} className={styles.cards_artist} position="below" title={item.artist} /> */}
                        </ImageListItem>
                      ))}
                </>
              </ImageList>
            </Box>
          )}
        </div>
        <div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Most Popular</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : allitemdt.data &&
          allitemdt.data.filter((val) => {
            return val.is_most_popular === true
          }).length === 0 ? (
          <p style={{textAlign:'center'}}>No Data Found</p>
        ) : (
         <Suspense fallback={<CircularLoader />}>
            <SliderCarousel
            data={
              allitemdt.data !== null
                ? allitemdt?.data?.filter((val) => {
                    return val.is_most_popular === true
                  })
                : []
            }
            type="most_popular"
          />
         </Suspense>
        )}
          </Container>
        </div>
        
        
        <div className={`prod-slider1 ${styles.sec_main}`}>
        <Container maxWidth="lg">
        <Grid container spacing={2}  alignItems="center">
        <Grid item md={3}>
        <div className={styles.price_explore}>
            <div>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="78.3px"
                alt=""
              />
            </div>
            <h3>Shop By Price</h3>
            <p>Shop High-Quality Art at Unbeatable Prices Today!</p>
            <button className={styles.remobtn}
              onClick={() => navigate("/user/search")}
            >
              Explore
            </button>
          </div>
        </Grid>
  <Grid item md={9} xs={12}>
  <div className={styles.price_div}>
            <Suspense fallback={<CircularLoader />}>
            <SliderCarousel
              // data={
              //   allitemdt.data !== null
              //     ? allitemdt.data
              //     : []
              // }
              data={pricedt}
              type="price"
            />
            </Suspense>
          </div>
  </Grid>
        </Grid>
          </Container>
          </div>
        <div className={styles.offer_banner}>
        <Container maxWidth="lg">
        <Grid container spacing={2}  alignItems="center">
        <Grid item md={3}  xs={12}>
        <div className={styles.explore_side}>
            <div>
              <img
                src={"/img/Design.png"}
                height="21.49px"
                width="78.3px"
                alt=""
              />
            </div>
            <h3>Offers</h3>
            <p>Grab Exciting Discounts on ArtSmiley Stunning Artworks Today!</p>
            <button  className={styles.remobtn}
              variant="contained"
              onClick={() => {
                navigate("/user/offers")
              }}
            >
              Explore
            </button>
          </div>
        </Grid>
        <Grid item md={9}  xs={12}>
        <Grid container spacing={2}>
        {loader.loader ? (
            <div className={styles.loaders}>
              <CircularLoader />
            </div>
          ) : (
            offersdt.data &&
            offersdt.data.slice(0, 2).map((val) => (
              <Grid item md={6} xs={12}>
                <div className={styles.offers_div}>
                <div className={styles.offer_card}>
                <Grid container spacing={2} alignItems="center">
                <Grid item md={6} xs={12}>
                <div>
                    <h1 className={styles.off}>Get Up To <span>{val.discount}%</span> Off</h1>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                <div className={styles.offerimgsc}>
                    <img
                      src={val.offer_image}
                       className={styles.img_fluid}
                      alt="img"
                    />
                  </div>
                </Grid>
                </Grid>
                </div>
              </div>
              </Grid>
            ))
          )}
        </Grid>
        </Grid>
        </Grid>
        </Container>
        </div>
        {localStorage.getItem("user_id") ? (
          <div className={styles.offer_banner} style={{ marginTop: "100px" }}>
            <Container maxWidth="lg">
            <Grid container spacing={2}  alignItems="center">
            <Grid item md={3} xs={12}>
            <div className={styles.explore_side}>
              <div>
                <img
                  src={"/img/Design.png"}
                  height="21.49px"
                  width="78.3px"
                  alt=""
                />
              </div>
              <h3>Campaign Offers</h3>
              <p>
                Grab Exciting Discounts on ArtSmiley Stunning Artworks Today!
              </p>
              <button  className={styles.remobtn}
                variant="contained"
                onClick={() => {
                  // navigate("/user/offers");
                }}
              >
                Explore
              </button>
            </div>
            </Grid>
        <Grid item md={9} xs={12}>
        <Grid container spacing={2}>
        {loader.loader ? (
              <div className={styles.loaders}>
                <CircularLoader />
              </div>
            ) : (
              compaingns?.data &&
              compaingns?.data?.slice(0, 2).map((val) => (
                <Grid item md={6} xs={12}>
                  <div className={styles.offers_div}>
                  <div className={styles.offer_card}>
                  <Grid container spacing={2} alignItems="center">
                  <Grid item md={6} xs={12}>
                  <div>
                      <h1 className={styles.off}>Get Up To {val.discount}% Off</h1>
                    </div>
                  </Grid>
                  <Grid item md={6} xs={12}>
                  <div>
                      <h3 className={styles.off} style={{ fontSize: "18px" }}>
                        {val.name}
                      </h3>
                    </div>
                  </Grid>
                  </Grid>
                  </div>
                </div>
                </Grid>
              ))
            )}
        </Grid>
        </Grid>
            </Grid>
            </Container>
          </div>
        ) : null}

<div className={`prod-slider ${styles.sec_main}`}>
        <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}> Curator Collections</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
         <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            data={curatorCollection ? curatorCollection.data?.filter((val) => val?.item_ids?.length > 0) : []}
            type="curator"
          />
         </Suspense>
        )}
          </Container>
        </div>
        
        <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>  Featured Artists</h3>
          <p className={styles.sub_title}>Celebrated Artists, Showcased Creators, Artists to Watch</p>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
          <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            data={
              userartists.data
                ? userartists.data.filter((val) => val.is_featured === true)
                : []
            }
            // data={userartists.data ? userartists.data : []}
            type="featured"
          />
          </Suspense>
        )}
          </Container>
       </div>
        
        
        {localStorage.getItem("user_id") && fol_id && fol_id.length > 0 ? (
          <>
          <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>From People You Follow</h3>
          <p className={styles.sub_title}>From Your Favorite Artists- Followed Artworks, Curated
          Favorites, Favorite Creations</p>
          </div>

          {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
           <Suspense fallback={<CircularLoader />}>
                <SliderCarousel
                data={
                  allitemdt.data &&
                  allitemdt.data
                    .filter(
                      (val) => fol_id && fol_id.includes(val?.artist_id?._id)
                    )
                    .reverse()
                }
                type="follow"
              />
           </Suspense>
            )}
          </Container>
          </div>
          </>
        ) : null}

        {/* testmonials */}
        <div className={`testmo-slider ${styles.sec_main}`}>
       <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Testimonials</h3>
          <p className={styles.sub_title}>What Our Customers Are Saying- Client Experiences, Customer Stories,
          Customer Voices</p>
          </div>
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <Slider type="testimonial" data={testimonial ? testimonial : []} />
          )}
          </Container>
          </div>
        
        {/* trending */}
        {/* <div className={styles.hero_main7}>
           
          {loader.loader ? (
            <div className={styles.loader}>
              <CircularLoader />
            </div>
          ) : (
            <>
              <div className={styles.trending_content_div}>
                <Grid
                  container
                  sx={{ justifyContent: "center" }}
                  columns={{ xs: 12, sm: 12, md: 12 }}
                >
                  {allitemdt.data &&
                    allitemdt.data &&
                    allitemdt.data
                      .filter((item) => {
                        return item.trending === true
                      })
                      .map((val) => (
                        <Grid
                          item
                          className={styles.trending_card}
                          xs={4}
                          sm={2}
                          md={2}
                          onClick={() => handleClickTrending(val._id)}
                        >
                          <div className={styles.trending_img_div}>
                            <img
                              src={val.category_id.image}
                              height="100%"
                              width="100%"
                              alt="img"
                            />
                          </div>
                          <div className={styles.type_div}>
                            <h4>{val.name}</h4>
                          </div>
                        </Grid>
                      ))}
                </Grid>
              </div>
              {allitemdt.data &&
              allitemdt.data &&
              allitemdt.data.filter((item) => {
                return item.trending === true
              }).length === 0 ? (
                <p>No Data Found</p>
              ) : (
                <Button
                  variant="contained"
                  sx={{ width: "136px", height: "56px", mt: 5 }}
                  onClick={() => {
                    navigate("/user/trending")
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }}
                >
                  Explore
                </Button>
              )}
            </>
          )}
        </div> */}
        {/* recently viewed */}
        {localStorage.getItem("user_id") &&
        userDetail?.data?.item_views.length > 0 ? (
          <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Recently Viewed</h3>
          </div>
          {loader.loader ? (
          <div className={styles.loader}>
            <CircularLoader />
          </div>
        ) : (
         <Suspense fallback={<CircularLoader />}>
             <SliderCarousel
            // data={allitem_id ? allitem_id.reverse() : []}
            type="recently"
            data={recent ? recent.reverse() : []}
          />
         </Suspense>
        )}
          </Container>
          </div>
          
        ) : (
          ""
        )}
        <div className={`prod-slider ${styles.sec_main}`}>
       <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>Partners</h3>
          <p className={styles.sub_title}>Collaborators - Creative Partners, Collaborative Teams, Key
          Collaborators</p>
          </div>
          {/* <div>
            <Helmet>
              <script
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                crossorigin="IamCrazy"
                async
                type="text/javascript"
              ></script>
            </Helmet>
            <TrustBox />
            <app-trustbox></app-trustbox>
          </div> */}
          <div className={styles.trending_content_div}>
            <Grid
              container
              columnSpacing={0}
              columns={{ xs: 14, sm: 14, md: 14 }}
            >
              {partners &&
                partners.map((val) => (
                  <Grid className={styles.partners_card} xs={7} sm={2} md={2}>
                    <div className={styles.partners_img_div}>
                      <img src={val.src} className={styles.img_fluid} alt="img" />
                    </div>
                  </Grid>
                ))}
            </Grid>
          </div>
          </Container>
          </div>
        
          <div className={`prod-slider ratingsc ${styles.sec_main}`}>
       <Container maxWidth="lg">
       <Grid container spacing={2}  alignItems="center">
       <Grid item md={2} xs={12}>
       <div>
            <h4 className={styles.extitle}>Excellent</h4>

            <div className={styles.rating_div}>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
              <StarIcon className={styles.sticon}/>
            </div>
            <p className={styles.rtpra}>
              Based on <u>8,180 reviews</u>
            </p>
            <div className={styles.img_type}>
              <StarIcon
                sx={{ width: "30px", height: "30px", color: "green" }}
              />
              <h3>TrustPilet</h3>
            </div>
          </div>
       </Grid>
       <Grid item md={1}></Grid>
       <Grid item md={9} xs={12}>
       <Suspense fallback={<CircularLoader />}>
       <SliderCarousel
            data={rating.length !== 0 ? rating : []}
            type="rating"
          /></Suspense>
       </Grid>
       </Grid>
       </Container>
       </div>
      

        <Box className={styles.subscribe_banner}>
          {/* <div className={styles.mail_header}>
            </div> */}
          <div>
            <img src={"/img/email.png"} width="80px" height="63px" alt="img" />
          </div>
          <h5 className={styles.subscribe_head}>
            Subscribe for ArtSmiley Newsletter to get the trending art updates,
            curated collections, and special offers.
          </h5>
          <TextField
            variant="outlined"
            placeholder="Please enter your mail address"
            sx={{
              width: { xs: "90%", sm: "400px" },
              backgroundColor: "#3c3c3c",
              "& fieldset": {
                border:
                  email.length === 0
                    ? "none"
                    : email.match(
                   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      )
                    ? "none"
                    : "",
              },
              // background: "black",
              // opacity: '0.2',
              // border: "1px solid #FFFFFF",
              borderRadius: "6px",
              "& .MuiOutlinedInput-notchedOutline": { border: "" },
              // "::placeholder": { color: "red" },
              // '&::-webkit-input-placeholder': { color: 'blue' },
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "21px",
              color: "#FFFFFF",
              input: { color: "white" },
              // opacity: '0.6',
            }}
            error="true"
            value={email}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            sx={{
              background: "white",
              color: "black",
              height: "54px",
              width: "131px",
              borderRadius: "6px",
            }}
            onClick={handleSubmit}
          >
            Subscribe
          </Button>
        </Box>

        <div className="App">
          {/* <button onClick={handleMinimize}> Minimize the Chat </button> */}
          {localStorage.getItem("usertype") === "buyer" ? (
            <TawkMessengerReact
              propertyId="64b247bbcc26a871b0288827"
              widgetId="1h5c6ge6i"
              ref={tawkMessengerRef}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default Homepage
