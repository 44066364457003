import { FormControl, MenuItem, Select } from "@mui/material"
import React, { useEffect } from "react"
import Fourcolimg from "../../../components/Pages/List/Table/Fourcolumn/Fourcolimg"
import styles from "./Earningsaffiliate.module.css"
import homeImage from "../../../constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllItemData } from "../../../State/api/userApi"
import { useState } from "react"
import { affiliateOrders } from "../../../State/api/superadminApi"
import { removeUser } from "../../../State/Features/common/userSlice"
import api from "../../../State/api/Api"

const head = [
  {
    id: 1,
    label: "Product",
  },
  {
    id: 2,
    label: "Sku Id",
  },
  {
    id: 3,
    label: "Title",
  },
  {
    id: 4,
    label: "Ordered Revenue",
  },
]
const data = [
  {
    id: 1,
    src: "/img/image 1",
    name: "3525",
    follower: "07-06-2022",
    like: "$16,000",
  },
  {
    id: 2,
    src: "/img/image 2",
    name: "3525",
    follower: "07-06-2022",
    like: "$16,000",
  },
  {
    id: 3,
    src: "/img/image 3",
    name: "3525",
    follower: "07-06-2022",
    like: "$16,000",
  },
  {
    id: 4,
    src: "/img/image 4",
    name: "3525",
    follower: "07-06-2022",
    like: "$16,000",
  },
]

export default function Earningsaffiliate() {
  const [age, setAge] = React.useState("")
  const allitemdt = useSelector((state) => state.allitemData)
  const [order, setOrder] = useState([])
  const [affiliate, setAffiliateDetails] = useState({ earning: 0 })
  const dispatch = useDispatch()
  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const handleFetchAffiliateProfile = async function () {
    try {
      const result = await api.applicationApi(
        `api/affiliate/getAffiliateEarning`,
        "GET"
      )
      if (result && result.status === 200) {
        const res = result.data
        setOrder(res?.data)
        setAffiliateDetails({
          earning: res?.earningData?.weekly_earning
            ? res?.earningData?.weekly_earning
            : 0,
        })
      } else if (result.status === 401) {
        dispatch(
          removeUser({
            signed: false,
            type: "",
            data: "",
          })
        )
      }
    } catch (err) {
    }
  }
  const fetchOrderData = () => {
    dispatch(affiliateOrders(setOrder))
  }
  useEffect(() => {
    fetchOrderData()
    handleFetchAffiliateProfile()
  }, [])
  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.selector_div}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">Filter</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={styles.card}>
          <img src={"/img/moneys.png"} width="44px" height="43px" alt="img" />
          <p>Weekly Earning</p>
          <h1>${affiliate.earning}</h1>
        </div>
        <div>
          <Fourcolimg
            activetab="affiliate_earning"
            head={head}
            data={order && order}
          />
        </div>
      </div>
    </div>
  )
}
