import validator from "validator"
import isEmpty from "./isEmpty"

export const validateAdminJoinTeam = (career) => {

  let careerErrors = {}

  if (validator.isEmpty(career.first_name)) {
    careerErrors.first_name = "First Name is required"
  }

  if (validator.isEmpty(career.last_name)) {
    careerErrors.last_name = "Last name is required"
  }

  if (validator.isEmpty(career.email)) {
    careerErrors.email = "Email is required"
  }
  if (validator.isEmpty(career.message)) {
    careerErrors.message = "Message is required"
  }
  if (!career.attachement || career.attachement === null) {
    careerErrors.attachement = "Attachment is required"
  }

  return {
    careerErrors,
    isValid: isEmpty(careerErrors),
  }
}
