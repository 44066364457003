import {
  Errornotify,
  Successnotify,
  Warnnotify,
} from "../../common/alert/Alert"
import { setBtnLoader, setLoader } from "../Features/common/loaderSlice"
import { removeUser, setUser } from "../Features/common/userSlice"
import { setUsertype } from "../Features/common/usertypeSlice"
import api from "./Api"
import "react-toastify/dist/ReactToastify.css"

export function Registeration(
  data,
  navigate,
  setUser,
  setVerification_view,
  role,
  type
) {
  return async function fetchProductThunk(dispatch) {
    dispatch(setBtnLoader(true))
    try {
      // const apiResponse = await axios.post('api/user/register',data)
      const apiResponse = await api.applicationApi(
        "api/user/register",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setBtnLoader(false))

        Successnotify(apiResponse.data.message)
        setTimeout(() => {
          navigate("/artsmiley/login", { state: { type: role ? role : type } })
        }, 500)
        setUser({
          first_name: "",
          last_name: "",
          password: "",
          phone: "",
          email: "",
          role: "buyer",
          showPassword: false,
        })
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setBtnLoader(false))
        }
      }
    } catch (err) {
      console.log(err)
      dispatch(setBtnLoader(false))
      // Errornotify(" Network error");
    }
  }
}
export function fetchUsertypes() {
  return async function fetchUsertypesThunk(dispatch, getState) {
    dispatch(setLoader(true))
    try {
      // const apiResponse = await axios.post('api/user/register',data)
      const apiResponse = await api.applicationApi(
        "api/userType/fetchUserTypes",
        "GET"
      )
      if (apiResponse.status === 200) {
        dispatch(setUsertype(apiResponse.data.data.data))
        dispatch(setLoader(false))
      } else {
        if (apiResponse.status === 401) {
          dispatch(
            removeUser({
              signed: false,
              type: "",
              data: "",
            })
          )
        }
        return false
      }
    } catch (err) {
      console.log(err)
    }
  }
}
export function loginApi(data, navigate) {
  return async function fetchLoginThunk(dispatch, getState) {
    dispatch(setBtnLoader(true))
    try {
      const apiResponse = await api.applicationApi(
        "api/user/login",
        "POST",
        data
      )
      if (apiResponse.status === 200) {
        dispatch(setBtnLoader(false))
        const buyer_back_url = localStorage.getItem("buyer_back_url")
        if (buyer_back_url !== null && data.role === "buyer") {
          navigate(buyer_back_url)
          localStorage.removeItem("buyer_back_url")
        } else if (buyer_back_url === null && data.role === "buyer") {
          navigate("/user/")
        } else if (
          apiResponse.data.data.is_accepted === "Pending" &&
          data.role !== "superadmin"
        ) {
          navigate("/home")
          localStorage.setItem("is_accepted", apiResponse.data.data.is_accepted)
        } else {
          navigate("/dashboard")
        }
        if (data.role === "superadmin") {
          localStorage.setItem("is_accepted", "Approved")
          localStorage.setItem(
            "user_type_id",
            apiResponse.data.data.user_type_id
          )
          localStorage.setItem("is_subadmin", apiResponse.data.data.is_subadmin)

          if (
            apiResponse.data.data.is_subadmin === true &&
            apiResponse.data.data.admin_role
          ) {
            // Only store admin_role if it's available and is_subadmin is true
            localStorage.setItem("admin_role", apiResponse.data.data.admin_role)
          } else {
            // Optionally clear or handle when admin_role is not available
            localStorage.removeItem("admin_role")
          }
        }

        localStorage.setItem(
          "user_name",
          apiResponse.data.data.first_name + apiResponse.data.data.last_name
        )
        localStorage.removeItem("user_email")
        localStorage.setItem("user_id", apiResponse.data.data._id)
        localStorage.setItem("login_token", apiResponse.data.token)
        localStorage.setItem("usertype", data.role)
        localStorage.setItem("is_accepted", apiResponse.data.data.is_accepted)
        localStorage.setItem("password", data.password)
        localStorage.setItem("applied_profile", apiResponse.data.profile)
        Successnotify(apiResponse.data.message)
        dispatch(
          setUser({
            type: data.role,
            signed: true,
            // data: data,
            is_accepted: apiResponse.data.data.is_accepted,
          })
        )
        // return true;
      } else {
        if (apiResponse.status === 401) {
          Errornotify(apiResponse.data.message)
          dispatch(
            removeUser({
              signed: false,
              type: "",
              //  data: "",
            })
          )
        } else {
          Errornotify(apiResponse.data.message)
          dispatch(setBtnLoader(false))
          if (
            apiResponse.data.data &&
            apiResponse.data.data.email_is_verified === false
          ) {
            navigate("/artsmiley/resendmail")
            localStorage.setItem("usertype", data.role)
            localStorage.setItem("user_email", data.email)
          }
        }
      }
    } catch (err) {
      // console.log(err, 'error enter');
      Errornotify(" Network error")
      dispatch(setBtnLoader(false))
    }
  }
}
