import axios from 'axios';
import React, { useEffect } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import CustomButton from './CustomButton';
import { Errornotify, Successnotify } from '../../common/alert/Alert';
import "./stripe.css"
import { placeOrderApi } from '../../State/api/orderApi';
import { useDispatch } from 'react-redux';



const StripeForGift = (props) => {
const dispatch = useDispatch()
const amount = 100
    const CustomStripeButton = ({ label, onClick }) => (
        <CustomButton label={label} onClick={onClick} />
      );
      
 

    const publishableKey = 'pk_test_51MA4mcIyO4WKZxxFhHb522uxoxPhVIu2jlcq6N9otLQLopPNUWVG6YFIPSZRc8ys9O8f5fM5kxKpA3wwm4K8ZIKe000RNSmrpp'

const payNow = (token) => {
    console.log(token,'token')
}


    return (
   
            <StripeCheckout
             stripeKey={publishableKey} 
                label="Pay With Stripe"
                name="Pay With Credit Card"
                billingAddress
                shippingAddress
                amount={amount * 100}
                description={`Your total is $${amount}`}
                token={payNow}
                ComponentClass="div">
                    <CustomStripeButton label="Stripe" />
                </StripeCheckout>
                
            
    
    );
}

export default StripeForGift;
