import * as React from 'react';
import styles from './Togglebutton.module.css'
import { styled } from '@mui/material/styles';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button, Typography } from '@mui/material';
import homeImage from '../../../../../constant';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export default function Togglebutton() {
    const [alignment, setAlignment] = React.useState('bold');
    const [formats, setFormats] = React.useState(() => ['italic']);

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
    };

    const handleAlignment = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <div>
            <Paper
                elevation={0}
                className={styles.toggle_main}
                sx={{
                    // display: 'flex',

                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                    // flexWrap: 'wrap',
                    // height: '70px'
                }}
            >
                <StyledToggleButtonGroup
                    size="small"
                    value={alignment}
                    exclusive
                    onChange={handleAlignment}
                    aria-label="text alignment"
                    className={styles.button_flex}
                >
                    <p className={styles.font_style} >Roboto</p>
                    <ToggleButton value="left" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        <img src={ '/img/codeView.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <p className={styles.font_style}>Normal</p>
                    <ToggleButton value="lef" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        <img src={ '/img/codeView.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="bold" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        <img src={ '/img/bold.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="bol" aria-label="left aligned">
                        {/* <FormatAlignLeftIcon /> */}
                        <img src={ '/img/italic.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                        {/* <FormatAlignCenterIcon /> */}
                        <img src={ '/img/underline.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right aligned">
                        {/* <FormatAlignRightIcon /> */}
                        <img src={ '/img/strikeThrough.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified" >
                        {/* <FormatAlignJustifyIcon /> */}
                        <img src={ '/img/textColor.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="bld" aria-label="bold">
                        {/* <FormatBoldIcon /> */}
                        <img src={ '/img/backgroundColor.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic">
                        {/* <FormatItalicIcon /> */}
                        <img src={ '/img/orderedList.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="undelined" aria-label="underlined">
                        {/* <FormatUnderlinedIcon /> */}
                        <img src={ '/img/unorderedList.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="color" aria-label="color" >
                        <img src={ '/img/outdent.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="clor" aria-label="color" >
                        <img src={ '/img/indent.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="colo" aria-label="color" >
                        <img src={ '/img/subscript.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="olor" aria-label="color" >
                        <img src={ '/img/superscript.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="coor" aria-label="color" >
                        <img src={ '/img/insertLink.png'} height='18px' width='16px' />
                    </ToggleButton>
                    <ToggleButton value="colors" aria-label="color" >
                        <img src={ '/img/imageManager.png'} height='18px' width='16px' />
                    </ToggleButton>
                </StyledToggleButtonGroup>
                <Button variant="contained" sx={{ my: { xs: 1, sm: 0 }, mx: { xs: 'auto', sm: 0 } }} className={styles.upload_btn}>
                    <img src={ '/img/Upload.png'} width='16px' height='15px' />
                    Upload
                </Button>
            </Paper>
            <textarea className={styles.textarea} />
        </div>
    );
}
