import validator from "validator"
import isEmpty from "./isEmpty"

export const validatePrssrelease = (press) => {

  let pressErrors = {}

  if (validator.isEmpty(press.title)) {
    pressErrors.title = "Title is required"
  }

  // if (
  //   !press.item_type_id ||
  //   press.item_type_id === null ||
  //   press.item_type_id === undefined
  // ) {
  //   pressErrors.item_type_id = "Press Type is required"
  // }

  if (validator.isEmpty(press.description)) {
    pressErrors.description = "Description is required"
  }
  // if (validator.isEmpty(press.link)) {
  //   pressErrors.link = "Link is required"
  // }
  if (!press.image || press.image === null) {
    pressErrors.image = "Image is required"
  }

  return {
    pressErrors,
    isValid: isEmpty(pressErrors),
  }
}
