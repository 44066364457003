import React from 'react'
import styles from './Activity.module.css'
import Notify from '../Common/Notification/Notification'

const data = [
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
    {
        src: '/img/image 1.png',
        name: 'Laurie Fox',
        title: 'Reported Order',
        content: 'kioiewfew ou judif dfhj sdk uwer ij f ew...',
        time: '2:00 PM',
        check:false,
        btn: [
            {
                label: 'Confirm File...'
            }, {
                label: 'Important ...'
            },
        ]
    },
]
export default function Activity() {
  return (
    <div className={styles.mains}>
        <Notify data={data} />
    </div>
  )
}
