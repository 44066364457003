import { Button } from "@mui/material"
import React, { useState } from "react"
import Pagination from "../Pagination/Pagination"
import Fourcolimg from "../Table/Fourcolumn/Fourcolimg"
import Tablesfour from "../Table/Fourcolumn/Tables"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Reports.module.css"
import homeImage from "../../../../constant"
import ReportsModal from "../Popupdelete/Reports/ReportsModal"
import { AlertsSuccess } from "../../../../common/alert/Alert"
const head = [
  {
    id: 1,
    label: "Report Type",
  },
  {
    id: 2,
    label: "From date",
  },
  {
    id: 3,
    label: "To Date",
  },
  {
    id: 4,
    label: "Status",
  },
  {
    id: 4,
    label: "subscription",
  },
]
// const data = [
//   {
//     id: 1,
//     src: "/img/image 1",
//     name: "Emilly Cooper",
//     follower: "Ui/Ux Desiner!",
//     like: "ths is description for gift hjdsfij",
//   },
//   {
//     id: 2,
//     src: "/img/image 2",
//     name: "Emilly Cooper",
//     follower: "Ui/Ux Desiner!",
//     like: "ths is description for gift",
//   },
//   {
//     id: 3,
//     src: "/img/image 3",
//     name: "Emilly Cooper",
//     follower: "Ui/Ux Desiner!",
//     like: "ths is description for gift",
//   },
//   {
//     id: 4,
//     src: "/img/image 4",
//     name: "Emilly Cooper",
//     follower: "Ui/Ux Desiner!",
//     like: "ths is description for gift",
//   },
// ]
export default function Reports() {
  const [button, setButton] = useState({ type: "add", text: "Reports" })
  const [open, setOpen] = useState(false)
  return (
    <>
      {open ? (
        <ReportsModal
          // handleAdd={handleAdd}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        <></>
      )}
      <AlertsSuccess />

      <div className={styles.mains}>
        <div className={styles.components}>
          <Tableheader button={button} setOpen={setOpen} />

          <Fourcolimg activetab="reports" head={head} />
          {/* <Tablesfour activetab='reports' head={approvedhead} data={approvedData}/> */}
          <Pagination />
        </div>
      </div>
    </>
  )
}
