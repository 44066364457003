import { Button, Container, Grid } from "@mui/material";
import React from "react";
import styles from "./Commonhome.module.css";
import homeImage from "../../../../constant";
import { useLocation, useNavigate } from "react-router-dom";

export default function Commonhome(props) {
   const navigate = useNavigate();
  console.log(window.location.pathname)
   const handleNavigate = () => {
      if(window.location.pathname === '/artsmiley/gallery/applynow') {
         navigate("/artsmiley/gallery/fillquestion")
      }else if(window.location.pathname === "/artsmiley/artist/applynow") {
         navigate("/artsmiley/artist/fillquestion")
      } else if(window.location.pathname === "/artsmiley/affiliate/applynow") {
         navigate("/artsmiley/affiliate/fillquestion")
      }else if(window.location.pathname === "/artsmiley/curator/applynow"){
         navigate("/artsmiley/curator/fillquestion")
      }else {
         navigate("/")
      }
  
   }
   return (
      <div className={styles.mains}>
         <Container maxWidth="lg">
         <Grid container spacing={2} sx={{
    justifyContent: "center",
    alignItems: "center",
  }}>
         <Grid item xs={12} sm={10} md={10}>
            <div className={styles.applybox}>
            <Grid container spacing={2} sx={{
    justifyContent: "center",
    alignItems: "center",
  }}>
            <Grid item xs={12} sm={2} md={2}>
            <div className={styles.logo_img}>
               <img onClick={() => navigate("/")} src={ "/img/updated_logo.png"} className="img-fluid" alt=""/>
            </div>
            </Grid>
            </Grid>
            
               <div className={styles.contst}>
               <div className={styles.main_heading}>
                  <h2>{props.data.heading}</h2>
               </div>
               <div className={styles.content}>
                  <p>{props.data.subheading}</p>
                  <button
                     onClick={handleNavigate}
                     variant="contained"
                     className={styles.apply_btnbl}
                     >
                     Apply Now
                  </button>
               </div>
               </div>
            </div>
         </Grid>
         </Grid>

         </Container>
         
      </div>
   );
}
