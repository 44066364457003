import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material"
import React, { useState } from "react"
import styles from "./Tables.module.css"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import homeImage from "../../../../../constant"
import { useNavigate } from "react-router-dom"
import moment from "moment"

export default function TablesApproved(props) {
  console.log(props, "props--12")
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [ids, setIds] = useState("")
  const navigate = useNavigate()
  const [selectedData, setSelectedData] = useState({ id: "", country: "" })
  console.log(selectedData, "selectedData-asas-")
  const threedots = Boolean(anchorEl)
  const loader = useSelector((state) => state.loader)

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget)
    setIds(event.target.id)
    const country = row?.artist[0]?.country || "Unknown"
    setSelectedData({
      id: row._id,
      country: country,
    })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handlePopup = () => {
    props.setOpen(true)
  }
  const handleProfilepage = (id) => {
    const path = window.location.pathname
    if (path === "/artist") {
      navigate(`/artist/${id}`, { state: { active: props.activetab } })
    } else if (path === "/curators") {
      navigate(`/curators/${id}`)
    } else if (path === "/exhibitions") {
      navigate(`/exhibitions/${id}`)
    }
  }
  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`)
  }

  // Updated handleDelete function
  const handleDelete = (data) => {
    props.handlePopup(selectedData)
    if (props.setOpen) {
      props.setOpen(true)
    }
  }
  return (
    <div className={styles.mains}>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#f6f6f6", color: "black" }}
      >
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3" }}>
              {props.head &&
                props.head.map((column, i) => (
                  <TableCell
                    align={props.head.length - 1 === i ? "left" : "left"}
                    sx={{
                      whiteSpace: "nowrap",
                      fontFamily: "DM Sans",
                      fontWeight: "500",
                      fontSize: "16px",
                      height: "21px",
                      color: "#1C1C1C",
                    }}
                    key={i}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {props.data && props.data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                props.data &&
                props.data.map((row, i) => (
                  <TableRow
                    style={{ color: "#636365", height: "30px" }}
                    className={styles.table_row}
                    key={row._id}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      style={{ cursor: "pointer" }}
                      className={styles.table_column}
                      align="left"
                      onClick={() => handleProfilepage(row._id)}
                    >
                      {row.first_name} {row.last_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row?.artist?.map((loc, i) => loc?.country)}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <div> {row?.email}</div>
                      <div>{row?.phone}</div>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {/* {row.artist[0]?.approved_date
                        ? row.artist?.map((cur, i) => cur.approved_date)
                        : "---"} */}
                      {row.artist[0]
                        ? row.artist[0].approved_date
                          ? dayjs(row.artist[0].approved_date)
                              .format("YYYY-MM-DD")
                              .toString()
                          : "----"
                        : ""}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <>
                        {row.artist[0]?.curator?.length > 0
                          ? row.artist?.map((cur, i) =>
                              cur.curator.map(
                                (curname, i) => curname.curator_name
                              )
                            )
                          : "---"}
                      </>
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <div> {row?.artist_type}</div>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <>
                        {" "}
                        {row.artist &&
                          row.artist.map((vl) => {
                            const lastSubscription =
                              vl?.subscriptions?.slice(-1)[0]
                            return lastSubscription ? (
                              <>{lastSubscription?.name}</>
                            ) : (
                              <>----</>
                            )
                          })}
                      </>
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {row.artist?.map((lik, i) => (
                        <>{lik.likesview?.likes}</>
                      ))}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        height: "auto",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        {row.followers ? row.followers.length : "0"}

                        <>
                          <Tooltip title="Account settings">
                            <IconButton
                              onClick={(event) => handleClick(event, row)}
                              size="small"
                              id={row._id}
                              sx={{ ml: 2 }}
                              aria-controls={
                                threedots ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={threedots ? "true" : undefined}
                            >
                              <img
                                src={"/img/Menu Icon.png"}
                                id={row._id}
                                height="21.62px"
                                alt="img"
                                width="4.63px"
                              />
                            </IconButton>
                          </Tooltip>
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={threedots}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                width: "150px",
                                overflow: "visible",
                                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                                filter:
                                  "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                                mt: 2.5,
                                "& .MuiAvatar-root": {
                                  width: "120px",
                                  height: "132px",
                                  ml: 0.5,
                                  mr: 1,
                                  // mt:5
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  // horizontal: 'center',
                                  right: 62,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "right",
                            }}
                            anchorOrigin={{
                              horizontal: "left",
                              vertical: "center",
                            }}
                          >
                            <MenuItem
                              onClick={() => props.handleSetArtist(ids, true)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Add Featured Artist
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => props.handleRecommended(ids, true)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Recommended
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() =>
                                // props.handleRecommended(ids, true)
                                navigateToArtistDetail(ids)
                              }
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              View Artist Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem
                              onClick={() => handleDelete(row)}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "16px",
                                lineHeight: "12px",
                                fontWeight: "400",
                                fontFamily: "DM Sans",
                              }}
                            >
                              Assign Curator
                            </MenuItem>
                            {/* <MenuItem
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                    lineHeight: "12px",
                                    fontWeight: "400",
                                    fontFamily: "DM Sans",
                                  }}
                                  onClick={() => props.handleArtistDelete(ids)}
                                >
                                  Delete
                                </MenuItem> */}
                          </Menu>
                        </>
                      </Box>
                    </TableCell>

                    {/*  */}
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
