import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const enquirySlice = createSlice({
    name: "enquiryData",
    initialState,
    reducers: {
        setEnquirydata: (state, action) => {
            state.data = action.payload;
        }
    },
});

export const { setEnquirydata } = enquirySlice.actions;

// export const curatorData = (state) => state.curatorData;

export default enquirySlice.reducer;
