import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import striptags from 'striptags'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./GiftCardOrder.module.css";
import homeImage from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../../common/alert/Alert";
import CircularLoader from "../../../common/loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import serverimage from "../../../serverConstant";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalCheckoutButton from "../../../payment/PaypalCheckoutButton";
import StripeDetail from "../../../payment/stripe/StripeDetail";
import CustomTabby from "../../../payment/stripe/CustomTabby";
import PaypalGift from "../../../payment/PaypalGift";
import StripeForGift from "../../../payment/stripe/StripeForGift";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { RiMoneyDollarCircleLine, RiMessage3Line, RiShieldFlashLine, RiGiftLine } from "react-icons/ri";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
const stepCardData = [
  {
    id: "01",
    imgUrl: "/img/Dollar Vector.png",
    text: "Select a gift card from the purchase options.",
    icon: <RiMoneyDollarCircleLine />,
  },
  {
    id: "02",
    imgUrl: "/img/chatVector.png",
    text: "Fill in the recipient’s details and message.",
    icon: <RiMessage3Line/>
  },
  {
    id: "03",
    imgUrl: "/img/secure transparent.png",
    text: "Choose your preferred payment option.",
    icon: <RiShieldFlashLine/>
  },
  {
    id: "04",
    imgUrl: "/img/gcVector.png",
    text: "The recipient will receive the gift card via email.",
    icon: <RiGiftLine/>
  },
];
const giftCard_data = [
  {
    id: 1,
    question: "How do I purchase a Gift Card on ArtSmiley?",
    answer: "To purchase a Gift Card on ArtSmiley, follow these steps: Navigate to the 'Gift Card' section from the menu, Select your preferred gift card amount, which can be used to buy or rent original art from our collection of artwork available for sale, Fill in the recipient's email, If you wish, add a personalized message to accompany the gift card, Complete the payment using PayPal, Stripe, or Tabby. Once completed, you will receive a confirmation email with the order details, The recipient will receive the gift card number, which they can use to redeem towards original art for sale or renting art on Art Smiley."
  },
  {
    id: 2,
    question: "Can I personalize the message on the Gift Card?",
    answer: "Yes! During the purchase process, you can include a personalized message, which will be sent to the recipient along with the gift card code."
  },
  {
    id: 3,
    question: "How will the recipient receive the Gift Card?",
    answer: "The recipient will receive the Gift Card via email. The email will contain the gift card code, the amount, and instructions on how to redeem it on their ArtSmiley account."
  },
  {
    id: 4,
    question: "What if the recipient does not have an ArtSmiley account?",
    answer: "If the recipient does not have an ArtSmiley account, they will be prompted to sign up during the gift card redemption process. Once they sign up, they can easily apply the gift card to their account."
  },
  {
    id: 5,
    question: "Can I use my Gift Card to purchase another Gift Card?",
    answer: "No, the balance on your ArtSmiley Gift Card cannot be used to purchase another gift card."
  },
  {
    id: 6,
    question: "Can I transfer the balance on my Gift Card to another account?",
    answer: "No, the balance on your gift card is non-transferable and must be used by the account it was applied to."
  },
  {
    id: 7,
    question: "Can I redeem my Gift Card for cash?",
    answer: "No, ArtSmiley Gift Cards cannot be redeemed for cash. They can only be used to make purchases on the ArtSmiley website."
  },
  {
    id: 8,
    question: "What should I do if I encounter issues while using my Gift Card?",
    answer: "If you face any issues with your gift card, please contact ArtSmiley customer service with your gift card's number. Our team will assist you in resolving the issue."
  },
  {
    id: 9,
    question: "How can I check the balance of my Gift Card?",
    answer: "You can check the balance of your Gift Card by logging into your ArtSmiley account and navigating to the 'Gift Card' section. Your current balance will be displayed at the top right of the screen. Use your gift card to buy or rent original art from our collection, including a variety of original art for sale."
  },
  {
    id: 10,
    question: "Can I return a Gift Card?",
    answer: "Gift cards are non-refundable and cannot be returned. Please ensure that you choose the correct amount and recipient before completing the purchase, as they can be used to buy or rent original art from our collection of, acrylic, and prints for sale."
  },
];
function GiftCardOrder() {
  const location = useLocation();
  const data = location.state?.data;
  const [makePayment, setMakePayment] = useState(false)
  console.log(makePayment,'makePayment')
  const [offer, setOffer] = useState({ amount: "", name: "" });
  const [offers, setOffers] = useState({
    offer_amount: data?.min_price,
    offer_currency: "",
    gift_Date: "",
    receipient_fullname: "",
    receipient_email: "",
    receipient_addressone: "",
    receipient_addresstwo: "",
    country: "",
    city: "",
    zip_code: "",
    about_giver: "",
    giver_email: "",
    offering_occassion: "",
    message: "",
    _id: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
  });
  const dispatch = useDispatch();
  const { id } = useParams();
 
  const [giftTerms, setGiftTerms] = useState();
  const [giftCurrency, setGiftCurrency] = useState();
  const [step, setStep] = useState("1");
  const loader = useSelector((state) => state.loader);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleGiftcardCurrency = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/user/giftcurrency");
      if (result && result.status === 200) {
        setGiftCurrency(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFetchGiftcard = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi("api/user/giftcardtandq");
      if (result && result.status === 200) {
        setGiftTerms(result.data.response);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const handleFetchGiftcards = async function () {
  //   dispatch(setLoader(true));
  //   try {
  //     const result = await api.applicationApi(`api/user/giftCards/${id}`,
  //     )
  //     if (result && result.status === 200) {
  //       // setGiftData(result.data.response);
  //       dispatch(setLoader(false));
  //     }
  //     else if (result.status === 401) {
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  useEffect(() => {
    // handleFetchGiftcards()
    handleFetchGiftcard();
    handleGiftcardCurrency();
  }, []);

  const handleChangeAmount = (e) => {
    const { name, value } = e.target;
    setOffer({ ...offer, [name]: value });
  };
  const handleSubmitAmount = async function () {
    localStorage.setItem("amount", offers.offer_currency);
 
     if (offers.offer_amount === ''){
      Errornotify("Please Enter amount");
    }
    else {
    
          setStep("2");
          dispatch(setLoader(false));
          Successnotify("First Step Clear");
   
      
    }
  };
  const handleChangesecond = (e) => {
    const { name, value } = e.target;
    setOffers({ ...offers, [name]: value });
  };
  const handleStepthird = () => {
    if( offers.receipient_email ==='' ){
      Errornotify("Please fill all receipient email address")
    }
    else{
      setStep("3");
      Successnotify("Second step cleared")
    }
  };
  const handleSubmitDetail = async function () {
    if(offers.receipient_email === ''){
      Errornotify("Please enter email address")
    }
    else{
      try {
        dispatch(setLoader(true));
        const result = await api.applicationApi(
          "api/user/purchaseGiftCard",
          "POST",
          offers
        );
        if (result && result.status === 200) {
          dispatch(setLoader(false));
          Successnotify("Offer is ready Move to Payment");
          setStep('1')
          setOffers({ offer_amount: "",
          offer_currency: "",
          gift_Date: "",
          receipient_fullname: "",
          receipient_email: "",
          receipient_addressone: "",
          receipient_addresstwo: "",
          country: "",
          city: "",
          zip_code: "",
          about_giver: "",
          giver_email: "",
          offering_occassion: "",
          message: "",
          _id: "",
          createdAt: "",
          updatedAt: "",
          __v: 0,})
        } else if (result.status === 401) {
          Errornotify("Error with code 401");
          dispatch(setLoader(false));
        } else {
          Errornotify("Validation Error");
        }
      } catch (err) {
        Errornotify("Network Error");
        dispatch(setLoader(false));
      }
    }
  };
  return (
    <div className={styles.giftcardorder}>
      <AlertsSuccess />
      <div className={styles.banner}>
      <div className={styles.header}>
      <div>
          <Typography fontFamily="marcellus" color="white" fontSize="2vw" textAlign="center">
          Share the Joy of Art – Send an ArtSmiley Gift Card Today!
          </Typography>
        </div>
        </div>
        <Container maxWidth="lg">
        <Grid container  spacing={2}>
        <Grid item xs={12} sm={5} md={5}>
        <div className={styles.bannercont}>
          <h3>${data?.min_price}</h3>
          <h1>{data?.name}</h1>
          <div className={styles.graduationDiv}>
              <div className={styles.verticalLine}></div>
              <div className={styles.graduation}>
                <img
                  className={styles.gradImg}
                  src={ "/img/graduationImg.png"} alt=""
                />
                <Typography fontSize="0.9vw">Buy a Gift Card</Typography>
              </div>
            </div>
          <ul>
            <li> {striptags(data?.description)}</li>
          </ul>
        </div>
        <p><Typography fontFamily="marcellus" color="white" textAlign="center">
             ArtSmiley gift cards are the perfect personalized gift, easily created and emailed instantly for any special occasion.
          </Typography></p>
        </Grid>
        <Grid item xs={12} sm={2} md={2}></Grid>
        <Grid item xs={12} sm={5} md={5}>
        <div className={styles.gcimgDiv}>
          <img className={styles.img_fluid} src={ data?.gift_card_image} alt="" />
            {/* <Typography
              style={{ marginTop: "-8%" }}
              fontFamily="marcellus"
              fontSize="1.75vw"
            >
              <span className={styles.gclabel}>GIFT CARD</span>
            </Typography> */}
          </div>
        </Grid>
        </Grid>
        </Container>
      </div>
      <div className={styles.giftsec}>
        <Container maxWidth="lg">
        <div className={styles.text_center}>
          <img className={styles.title_image} src={"/img/Design.png"} alt="SectionTitleImage" />
          <h3 className={styles.sec_title}>How Does It Work?</h3>
          </div>
          <div className={styles.stepsdescription}>
          <Grid container spacing={2}>
            {stepCardData.map((item) => (
              <Grid item xs={12} sm={6} md={3}>
                <div className={styles.stepbox}>
                  <ul className={styles.stpsel}>
                    <li>{item.id}</li>
                    <li>{item.icon}</li>
                  </ul>
                  <div className={styles.stecont}>
                  {item.text}
                  </div>
                </div>
                
              </Grid>
            ))}
          </Grid>
        </div>
        <div className={styles.giftcard__grid}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <div className={styles.gcImgdiv}>
                <img
                  className={styles.gc5img}
                  src={ "/img/Gift Card-5.png"} alt=""
                />
              </div>
              <div className={styles.textpad}>
                <Typography fontSize="0.8rem" ml={-1} mt={2}>
                  * If the event is coming up very soon, don’t worry! We will
                  send an email to the lucky recipient with your message and the
                  code. Our advisory service still stands.
                </Typography>
              </div>
              {/* <Typography color="black" ml={2} mt={1} fontSize="0.8rem">
                Any Question?
                <a className={styles.contactlink} href="#">
                  Contact Us
                </a>
              </Typography> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={styles.giftcard__form}>
                <Typography fontFamily="marcellus" fontSize="1.75rem">
                  Offer a Gift Card
                </Typography>
                <div className={styles.formHeading}>
                  <div className={styles.formnumber}>1</div>
                  <Typography ml={1.5}>
                    How much would you like to offer?
                  </Typography>
                </div>
                <div className={styles.content1}>
                  <Grid container spacing={1} alignItems='flex-end'>
                    <Grid item xs={12} sm={12} md={12} >
                      <div>
                        <Typography color="#636365" fontSize="0.9rem" mb={0.5}>
                          Offer amount:
                        </Typography>
                        <OutlinedInput
                          fullWidth
                          size="small"
                          id="outlined-basic"
                          variant="outlined"
                          value={offers.offer_amount}
                          name="offer_amount"
                          type="number"
                            startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                          onChange={handleChangesecond}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item xs={3} sm={3} md={3}>
                      <div>
                        <Box sx={{ maxWidth: 150, marginTop: "23%" }}>
                          <FormControl fullWidth>
                            <Select                              
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                }
                                ///getContentAnchorEl: null
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={offers.offer_currency}
                              name="offer_currency"
                              onChange={handleChangesecond}
                              size="small"
                            >
                              <MenuItem value="">Currency</MenuItem>
                              {giftCurrency &&
                                giftCurrency.map((val) => (
                                  <MenuItem key={val._id} value={val._id}>
                                    {val.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </div>
                    </Grid> */}
                  </Grid>
                </div>
                <Button
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    width: "100%",
                    marginTop: "3%",
                    padding: "2%",
                    borderRadius: "5px",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  onClick={() => setStep("2")}
                >
                  {/* {loader.loader ? <CircularLoader /> : */}
                  Proceed To The Next Step
                  {/* // } */}
                </Button>
                <>
  {/* Step 2 */}
  {(step === "2" || step === "3") &&   <div
    style={{
      display: "block", // Keep Step 2 always visible
    }}
  >
    <div className={styles.formHeading}>
      <div className={styles.formnumber}>2</div>
      <Typography ml={1.5}>Recipient's Details</Typography>
    </div>
    <div className={styles.content1}>
  <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
  On which date would you like to offer his gift card?:
  </Typography>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      // label="Date"
      value={offers.selected_date || null}
      onChange={(newValue) => handleChangesecond({ target: { name: 'selected_date', value: newValue } })}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          id="date-picker"
          variant="outlined"
          placeholder="Please select a date"
        />
      )}
    />
  </LocalizationProvider>
</div>
    <div className={styles.content1}>
      <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
        Full name of the recipient:
      </Typography>
      <TextField
        fullWidth
        size="small"
        id="outlined-basic"
        variant="outlined"
        type="email"
        name="receipient_email"
        value={offers.receipient_email}
        onChange={handleChangesecond}
      />
    </div>
    <div className={styles.content1}>
      <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
        Recipient's email address:
      </Typography>
      <TextField
        fullWidth
        size="small"
        id="outlined-basic"
        variant="outlined"
        type="email"
        name="receipient_email"
        value={offers.receipient_email}
        onChange={handleChangesecond}
      />
    </div>
    <Button
      sx={{
        backgroundColor: "black",
        color: "white",
        width: "100%",
        marginTop: "3%",
        padding: "2%",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      }}
      onClick={() => setStep("3")}
    >
      Proceed To The Next Step
    </Button>
  </div>}

  {/* Step 3 */}
  {step === "3" && (
    <div>
      <div className={styles.formHeading}>
        <div className={styles.formnumber}>3</div>
        <Typography ml={1.5}>Information about you</Typography>
      </div>
      <div className={styles.content1}>
        <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
         About You
        </Typography>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
          type="email"
          name="giver_email"
          // value={offers.giver_email}
          // onChange={handleChangesecond}
        />
      </div>
      <div className={styles.content1}>
        <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
          Your Email
        </Typography>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
          type="email"
          name="giver_email"
          value={offers.giver_email}
          onChange={handleChangesecond}
        />
      </div>
      <div className={styles.content1}>
        <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
        What is the occasion you are offering this gift for?
        </Typography>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
          type="email"
          name="giver_email"
          // value={offers.giver_email}
          // onChange={handleChangesecond}
        />
      </div>
      <div className={styles.content1}>
        <Typography mt={1} color="#636365" fontSize="0.9rem" mb={0.5}>
        The message you want to accompany the gift card
        </Typography>
        <TextField
          fullWidth
          size="small"
          id="outlined-basic"
          variant="outlined"
          type="email"
          name="giver_email"
          // value={offers.giver_email}
          // onChange={handleChangesecond}
        />
      </div>
      {!makePayment ? (
        <Button
          sx={{
            backgroundColor: "black",
            color: "white",
            width: "100%",
            marginTop: "3%",
            padding: "2%",
            borderRadius: "5px",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={() => setMakePayment(true)}
        >
          Proceed To Payment
        </Button>
      ) : null}
      {makePayment && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            padding: "10px 5px",
          }}
        >
          <div style={{ width: "48%", marginRight: "5px" }}>
            <PayPalScriptProvider
              options={{
                "client-id":
                  "AYWjsJ-Q2mNg_Pvjnslq_eUlu6xCfDP5-GXb-77XFykzgZlJS4TyaSw6Wl4cQj9k4B40twLP8SEcN_1B",
                components: "buttons",
                currency: "USD",
              }}
            >
              <PaypalGift
                amount={data?.min_price}
                gift_card_id={data?._id}
                email={offers.receipient_email}
                paymentType="paypal"
                setStep={setStep}
                offers={offers}
                setOffers={setOffers}
              />
            </PayPalScriptProvider>
          </div>
          <div style={{ width: "48%" }}>
            <StripeForGift />
          </div>
          <div style={{ width: "48%" }}>
            <CustomTabby />
          </div>
        </div>
      )}
    </div>
  )}
</>

              </div>
            </Grid>
          </Grid>
        </div>

        </Container>
      </div>
      <Typography fontFamily="marcellus" fontSize="2vw" textAlign="center">
      Add section what they can purchase using gift card.
          </Typography>
      {giftTerms &&
        giftTerms.map((val) => (
          <div className={styles.bottomText}>
            <Typography>{val.title}</Typography>
            <Typography>Buying and using the gift card</Typography>
            <ul>
              <li className={styles.bullets}>
                <Typography color="#636365">{val.description}</Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  The amount purchased on the gift card is deducted from a
                  purchase made on the singulart.com website.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  If the amount in the basket is greater than the amount on the
                  gift card, the difference is paid by the person making the
                  purchase and is to be paid directly on the site, via the
                  available payment options.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  The gift cards are sent by email and can be customized by the
                  buyer. Therefore, the buyer can offer the gift card to a
                  different person than the one specified on the website.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  An expiry date will be indicated on the card. This will be 6
                  months after the purchase of the gift card on ARTSMILEY.
                </Typography>
              </li>
            </ul>
            <Typography>Liability</Typography>
            <ul>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  ARTSMILEY is not liable for the use of the gift card by any
                  unauthorized person.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  The gift card is non refundable, even in the case of loss of
                  theft and cannot be redeemed for cash.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  In the case of any technical problems linked to our website
                  whilst redeeming the gift card, and at the express request of
                  the buyer, ARTSMILEY consider the possibility of issuing a
                  replacement code.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  If an artwork is returned after a purchase via the gift card,
                  the amount of the gift card will be refunded by issuing the
                  buyer with a new gift card - the validity period will be 1
                  month, from the date of retraction.
                </Typography>
              </li>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  ARTSMILEY can not be held responsible if the gift cards is not
                  received by the buyer due to inaccurate information provided
                  by said buyer. The buyer always has the possibility to contact
                  Customer services if they realize that they have made a
                  mistake in regards to the information indicated for billing
                  and receipt of their gift card.
                </Typography>
              </li>
            </ul>
            <Typography>Suspected fraud</Typography>
            <ul>
              <li className={styles.bullets}>
                <Typography color="#636365">
                  In case of suspected fraudulent activity with regards to the
                  use of the gift card, SINGULART reserves the right to refuse
                  its use immediately. In this case, SINGULART will contact the
                  buyer to confirm the purchase.
                </Typography>
              </li>
            </ul>
            <Typography>Terms and Conditions:</Typography>
            <ul>
              <li className={styles.bullets}>
                <Typography color="#636365">
                Gift card users are eligible to redeem their cards on all services, subject to the applicable terms and conditions of ArtSmiley. [Learn more]
                </Typography>
              </li>
            </ul>
          </div>
        ))}
        <Box className={styles.hero_main6} sx={{ maxWidth: "1287px" }}>
    {giftCard_data.map((item) => (
      <Accordion
        key={item.id}
        expanded={expanded === `panel${item.id}`}
        onChange={handleChange(`panel${item.id}`)}
      >
        <AccordionSummary
          expandIcon={
            expanded === `panel${item.id}` ? (
              <RemoveIcon  />
            ) : (
              <AddIcon/>
            )
          }
          aria-controls={`panel${item.id}bh-content`}
          id={`panel${item.id}bh-header`}
          className={styles.accordian_show}
        >
          <div className={styles.show_content}>
            {expanded === `panel${item.id}` && <div className={styles.accodian_no}>{item.id}</div>}
            <Typography >
              {item.question}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
          <Typography>{item.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
  <Typography fontFamily="marcellus" fontSize="2vw" textAlign="center">
  Add question what they can purchase using a gift card.
          </Typography>
    </div>
  );
}

export default GiftCardOrder;
