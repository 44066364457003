import {
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  Grid,
  Modal,
  TextareaAutosize,
  TextField,
} from "@mui/material"
import { Box } from "@mui/system"
import React, { useEffect, useState } from "react"
import styles from "./Subadminpopup.module.css"
import Backdrop from "@mui/material/Backdrop"
import homeImage from "../../../../../constant"
import { useDispatch, useSelector } from "react-redux"
import { fetchPermissionData } from "../../../../../State/api/superadminApi"
import CircularLoader from "../../../../../common/loader/Loader"
import Entermobile from "../../../../../curator/pages/applyForm/mobileNumber/Entermobile"
import { Dashboard } from "@mui/icons-material"

const permissionsubadminLeft = [
  {
    name: "Dashboard",
    value: 1,
  },
  {
    name: "Art",
    value: 2,
  },
  {
    name: "Artist",
    value: 3,
  },
  {
    name: "Curators",
    value: 4,
  },
  {
    name: "Buyers",
    value: 5,
  },
  {
    name: "Orders",
    value: 6,
  },
  {
    name: "Exibition",
    value: 7,
  },
  {
    name: "Auction",
    value: 8,
  },
  {
    name: "Subadmin",
    value: 9,
  },
  {
    name: "Chat",
    value: 10,
  },
  {
    name: "Affiliate",
    value: 11,
  },
  {
    name: "Notification",
    value: 12,
  },
  {
    name: "Inquiry",
    value: 13,
  },
]
const permissionsubadminMid = [
  {
    name: "Offers",
    value: 14,
  },
  {
    name: "Testimonials",
    value: 15,
  },
  {
    name: "Gallery",
    value: 16,
  },
  {
    name: "Menu",
    value: 17,
  },
  {
    name: "Campaigns",
    value: 18,
  },
  {
    name: "Career",
    value: 19,
  },
  {
    name: "Subscription",
    value: 20,
  },
  {
    name: "Press release",
    value: 21,
  },
  {
    name: "Blog",
    value: 22,
  },
  {
    name: "Gift card",
    value: 23,
  },
  {
    name: "Activity Page",
    value: 24,
  },
  {
    name: "Hero Section",
    value: 25,
  },
  {
    name: "Category",
    value: 26,
  },
]
const permissionsubadminRight = [
  {
    name: "Print",
    value: 27,
  },
  {
    name: "Print pricing",
    value: 28,
  },
  {
    name: "Reports",
    value: 29,
  },
  {
    name: "Policies",
    value: 30,
  },
  {
    name: "All account",
    value: 31,
  },
  {
    name: "FAQ",
    value: 32,
  },
  {
    name: "Recreate Request",
    value: 33,
  },
  {
    name: "Dispute",
    value: 34,
  },
  {
    name: "Rating and Review",
    value: 35,
  },
  {
    name: "Settings",
    value: 36,
  },
  {
    name: "Frame country",
    value: 37,
  },
  {
    name: "contact Us",
    value: 38,
  },
]

export default function Subadminpopup(props) {
  const [addSubadmin, setAddSubadmin] = useState(
    props.editSubadmin
      ? {
          first_name: props.editSubadmin.first_name,
          last_name: props.editSubadmin.last_name,
          email: props.editSubadmin.email,
          phone: props.editSubadmin.phone,
          _id: props.editSubadmin._id,
          admin_role: props.editSubadmin.admin_role,
          permission_ids: props.editSubadmin.access_role.map((val) => {
            return { _id: val._id }
          }),
        }
      : {
          first_name: "",
          last_name: "",
          role: "",
          email: "",
          phone: "",
          password: "",
          permission_ids: [],
          admin_role: "",
        }
  )
  // const [permission_ids, setPermission_ids] = useState([])
  const handleClose = () => props.setOpen(false)
  const loader = useSelector((state) => state.loader)

  const dispatch = useDispatch()

  const permissions = useSelector((state) => state.permissionData)

  useEffect(() => {
    dispatch(fetchPermissionData())
  }, [props.type === "edit" ? false : true])
  const handleChangeData = (e) => {
    const val = e.target.value
    const name = e.target.name
    let validValue = val
    if (name === "first_name" || name === "last_name") {
      // Allow only alphabets and spaces for the name field
      validValue = val.replace(/[^a-zA-Z\s]/g, "")
    }
    setAddSubadmin({ ...addSubadmin, [name]: validValue })
  }
  const handleChangeNumber = (e) => {
    setAddSubadmin({ ...addSubadmin, phone: e })
  }
  // const handleAccessRole = (e) => {
  //   const val = e.target.value
  //   const permission_ids = [...addSubadmin.permission_ids]
  //   const check_ids = addSubadmin.permission_ids.findIndex(
  //     (item) => item._id === val
  //   )
  //   if (check_ids === -1) {
  //     permission_ids.push({ _id: val })
  //     setAddSubadmin({ ...addSubadmin, permission_ids: permission_ids })
  //   } else {
  //     permission_ids.splice(check_ids, 1)
  //     setAddSubadmin({ ...addSubadmin, permission_ids: permission_ids })
  //   }
  // }

  const handleAccessRole = (e) => {
    const val = e.target.value
    let roles = addSubadmin.admin_role ? addSubadmin.admin_role.split(",") : []

    const roleIndex = roles.indexOf(val)
    if (roleIndex === -1) {
      // If the value is not found in the array, add it
      roles.push(val)
    } else {
      // If the value is found in the array, remove it
      roles.splice(roleIndex, 1)
    }

    // Convert the roles array back to a comma-separated string
    const updatedAdminRole = roles.join(",")

    setAddSubadmin({ ...addSubadmin, admin_role: updatedAdminRole })
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className={styles.modal_subadmin}>
            <div className={styles.cancel_img}>
              <img
                alt="img"
                onClick={handleClose}
                src={"/img/cancel_cross.png"}
                height="20px"
                width="20px"
              />
            </div>
            <p
              className={styles.modal_heading}
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              {props.type === "edit" ? "Edit" : "Add"} Sub Admin
            </p>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{ my: 1.5, display: "flex", justifyContent: "space-between" }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 22 }}
            >
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>First Name</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder={`Enter First Name`}
                  name="first_name"
                  value={addSubadmin.first_name}
                  onChange={handleChangeData}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Last Name</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder={`Enter Last Name`}
                  name="last_name"
                  value={addSubadmin.last_name}
                  onChange={handleChangeData}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Email</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder={`Enter Email`}
                  name="email"
                  value={addSubadmin.email}
                  onChange={handleChangeData}
                  type="email"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Role</label>
                <br />
                <TextField
                  sx={{
                    mt: 0.5,
                    backgroundColor: "#f6f6f6",
                    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                    borderRadius: "6px",
                  }}
                  size="small"
                  fullWidth
                  placeholder={`Enter Role`}
                  name="role"
                  // value={addSubadmin.role}
                  onChange={handleChangeData}
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={11} className={styles.inputs_gird}>
                <label>Phone No.</label>
                <br />

                <Entermobile
                  handleChangeNumber={handleChangeNumber}
                  type="subadmin"
                  addSubadmin={addSubadmin}
                />
                {/* <TextField
         sx={{
          mt: 0.5,
          backgroundColor: "#f6f6f6",
          "& .MuiOutlinedInput-notchedOutline": { border: "none" },
          borderRadius: "6px",
         }}
         size="small"
         fullWidth
         placeholder={`Enter Phone No.`}
         name="phone"
         value={addSubadmin.phone}
         onChange={handleChangeData}
         type="number"
        /> */}
              </Grid>

              {props.type === "edit" ? (
                <></>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={11}
                  className={styles.inputs_gird}
                >
                  <label>Password</label>
                  <br />
                  <TextField
                    sx={{
                      mt: 0.5,
                      backgroundColor: "#f6f6f6",
                      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                      borderRadius: "6px",
                    }}
                    size="small"
                    fullWidth
                    placeholder={`Enter Password`}
                    value={addSubadmin.password}
                    onChange={handleChangeData}
                    name="password"
                    type="password"
                  />
                </Grid>
              )}
            </Grid>
            <p className={styles.role_heading}>Access Role</p>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{ my: 1.5, display: "flex", justifyContent: "space-between" }}
              alignItems="center"
              item
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              colgap={1.5}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {props.type === "edit" ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminLeft &&
                      permissionsubadminLeft.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminMid &&
                      permissionsubadminMid.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminRight &&
                      permissionsubadminRight.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                </>
              ) : loader.loader ? (
                <Box className={styles.loader_box}>
                  {" "}
                  <CircularLoader size={60} />
                </Box>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminLeft &&
                      permissionsubadminLeft.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminMid &&
                      permissionsubadminMid.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    className={styles.inputs_gird}
                  >
                    {permissionsubadminRight &&
                      permissionsubadminRight.map((val) => (
                        <div className={styles.access_roles} key={val._id}>
                          <div>{val.name}</div>
                          <div className={styles.toggle_btn}>
                            <label className={styles.switch}>
                              <input
                                type="checkbox"
                                name="admin_role"
                                value={val.name}
                                checked={addSubadmin?.admin_role?.includes(
                                  val?.name
                                )}
                                onChange={handleAccessRole}
                              />
                              <span
                                className={`${styles.slider} ${styles.round}`}
                              ></span>
                            </label>
                          </div>
                        </div>
                      ))}
                  </Grid>
                </>
              )}
            </Grid>
            <div className={styles.action}>
              <Button
                variant="contained"
                sx={{ borderRadius: "10px" }}
                className={styles.action_btn}
                onClick={
                  props.type === "edit"
                    ? () =>
                        props.handleSubmitEdited(addSubadmin._id, addSubadmin)
                    : () => props.handleAdd(addSubadmin)
                }
              >
                {props.type === "edit" ? "Submit" : "Add"}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
