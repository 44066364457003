import React, { useState } from "react";
import styles from "./Questionsui.module.css";
import RichTextEditor from "react-rte";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Entermobile from "../../../pages/applyForm/mobileNumber/Entermobile";
import Uploadimage from "../uploadImage/Uploadimage";
import { check } from "prettier";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import homeImage from "../../../../constant";
import { Errornotify, Successnotify } from "../../../../common/alert/Alert";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../../../State/api/superadminApi";
import CircularLoader from "../../../../common/loader/Loader";
import country from "../../../../Country";
import { ToastContainer } from "react-toastify";
import { DeleteOutline } from "@material-ui/icons";
import { validationForArtistApplication } from "../../../../validation/ValidationForArtistApplication";
import { validationForGalleryApplication } from "../../../../validation/ValidationForGalleryApplication";
import api from "../../../../State/api/Api";
const phoneLengthByCountry = {
  ad: 9, // Andorra
  af: 9, // Afghanistan
  al: 9, // Albania
  dz: 9, // Algeria
  ao: 9, // Angola
  ag: 10, // Antigua and Barbuda
  ar: 10, // Argentina
  am: 8, // Armenia
  aw: 7, // Aruba
  au: 9, // Australia
  at: 10, // Austria
  az: 9, // Azerbaijan
  bh: 8, // Bahrain
  bd: 10, // Bangladesh
  bn: 7, // Brunei
  bs: 10, // Bahamas
  bb: 10, // Barbados
  by: 9, // Belarus
  bq: 7, // Caribbean Netherlands
  be: 9, // Belgium
  bz: 7, // Belize
  bj: 8, // Benin
  bt: 8, // Bhutan
  bo: 8, // Bolivia
  ba: 8, // Bosnia and Herzegovina
  bw: 7, // Botswana
  br: 11, // Brazil
  bg: 9, // Bulgaria
  bf: 8, // Burkina Faso
  bi: 8, // Burundi
  kh: 9, // Cambodia
  cm: 9, // Cameroon
  ca: 10, // Canada
  cv: 7, // Cape Verde
  ci: 8, // Ivory Coast
  cw: 7, // Curaçao
  cg: 9, // Congo - Brazzaville
  cd: 9, // Congo - Kinshasa
  cf: 8, // Central African Republic
  td: 8, // Chad
  cl: 9, // Chile
  cn: 11, // China
  co: 10, // Colombia
  km: 7, // Comoros
  cr: 8, // Costa Rica
  hr: 9, // Croatia
  cu: 8, // Cuba
  cy: 8, // Cyprus
  cz: 9, // Czech Republic
  dk: 8, // Denmark
  dj: 8, // Djibouti
  dm: 10, // Dominica
  do: 10, // Dominican Republic
  ec: 9, // Ecuador
  eg: 10, // Egypt
  sv: 8, // El Salvador
  gq: 9, // Equatorial Guinea
  er: 7, // Eritrea
  ee: 7, // Estonia
  et: 9, // Ethiopia
  fj: 7, // Fiji
  fi: 9, // Finland
  fr: 9, // France
  ga: 7, // Gabon
  gm: 8, // Gambia
  gp: 9, // Guadeloupe
  gu: 10, // Guam
  ge: 9, // Georgia
  de: 10, // Germany
  gh: 9, // Ghana
  gr: 10, // Greece
  gd: 10, // Grenada
  gf: 9, // French Guiana
  gt: 8, // Guatemala
  gn: 9, // Guinea
  gw: 9, // Guinea-Bissau
  gy: 7, // Guyana
  ht: 8, // Haiti
  hn: 8, // Honduras
  hk: 8, // Hong Kong
  hu: 9, // Hungary
  is: 7, // Iceland
  in: 10, // India
  id: 10, // Indonesia
  io: 7, // British Indian Ocean Territory
  ir: 10, // Iran
  iq: 10, // Iraq
  ie: 9, // Ireland
  il: 9, // Israel
  it: 10, // Italy
  jm: 7, // Jamaica
  jp: 10, // Japan
  jo: 9, // Jordan
  kz: 10, // Kazakhstan
  kp: 9, // North Korea
  ke: 10, // Kenya
  ki: 7, // Kiribati
  ks: 8, // Kosovo
  kn: 7, // Saint Kitts and Nevis
  kw: 8, // Kuwait
  kg: 9, // Kyrgyzstan
  la: 9, // Laos
  lv: 8, // Latvia
  lb: 8, // Lebanon
  ls: 8, // Lesotho
  lr: 7, // Liberia
  ly: 9, // Libya
  li: 7, // Liechtenstein
  lt: 8, // Lithuania
  lu: 9, // Luxembourg
  lc: 7, // Saint Lucia
  mo: 8, // Macau
  mg: 10, // Madagascar
  mw: 9, // Malawi
  my: 10, // Malaysia
  mv: 7, // Maldives
  ml: 8, // Mali
  mk: 8, // Macedonia
  mq: 9, // Martinique
  mt: 8, // Malta
  mh: 7, // Marshall Islands
  mr: 8, // Mauritania
  mu: 8, // Mauritius
  mx: 10, // Mexico
  fm: 7, // Micronesia
  md: 8, // Moldova
  mc: 9, // Monaco
  mn: 8, // Mongolia
  me: 8, // Montenegro
  ma: 9, // Morocco
  mz: 9, // Mozambique
  mm: 8, // Myanmar
  na: 9, // Namibia
  nc: 8, // New Caledonia
  nr: 7, // Nauru
  np: 10, // Nepal
  nl: 9, // Netherlands
  nz: 9, // New Zealand
  ni: 8, // Nicaragua
  ne: 8, // Niger
  ng: 10, // Nigeria
  no: 8, // Norway
  om: 8, // Oman
  pk: 10, // Pakistan
  pw: 7, // Palau
  pa: 7, // Panama
  pf: 8, // French Polynesia
  pg: 8, // Papua New Guinea
  py: 9, // Paraguay
  pe: 9, // Peru
  ph: 10, // Philippines
  pl: 9, // Poland
  pt: 9, // Portugal
  ps: 9, // Palestine
  pr: 10, // Puerto Rico
  qa: 8, // Qatar
  ro: 10, // Romania
  ru: 10, // Russia
  re: 9, // Reunion
  rw: 9, // Rwanda
  ws: 7, // Samoa
  sa: 9, // Saudi Arabia
  sn: 9, // Senegal
  sm: 9, // San Marino
  st: 7, // Sao Tome and Principe
  ss: 9, // South Sudan
  sz: 8, // Swaziland
  rs: 9, // Serbia
  sc: 7, // Seychelles
  sl: 8, // Sierra Leone
  sg: 8, // Singapore
  sk: 9, // Slovakia
  si: 8, // Slovenia
  sb: 7, // Solomon Islands
  so: 7, // Somalia
  za: 9, // South Africa
  kr: 10, // South Korea
  es: 9, // Spain
  lk: 9, // Sri Lanka
  sd: 9, // Sudan
  sr: 7, // Suriname
  se: 9, // Sweden
  ch: 9, // Switzerland
  sy: 9, // Syria
  tw: 9, // Taiwan
  tj: 9, // Tajikistan
  tz: 9, // Tanzania
  th: 9, // Thailand
  tl: 7, // Timor-Leste
  tg: 8, // Togo
  to: 7, // Tonga
  tt: 10, // Trinidad and Tobago
  tn: 8, // Tunisia
  tr: 10, // Turkey
  tm: 8, // Turkmenistan
  tv: 6, // Tuvalu
  ug: 9, // Uganda
  ua: 9, // Ukraine
  vc: 7, // Saint Vincent and the Grenadines
  va: 9, // Vatican CityS
  ae: 9, // United Arab Emirates
  gb: 10, // United Kingdom
  us: 10, // United States
  uy: 9, // Uruguay
  uz: 9, // Uzbekistan
  vu: 7, // Vanuatu
  ve: 7, // Venezuela
  vn: 10, // Vietnam
  ye: 9, // Yemen
  zm: 9, // Zambia
  zw: 9, // Zimbabwe
};
const languages = [
  { code: "en", name: "English" },
  { code: "hi", name: "Hindi" },
  { code: "ar", name: "Arabic" },
  { code: "es", name: "Spanish" },
  { code: "fr", name: "French" },
  { code: "de", name: "German" },
  { code: "zh", name: "Chinese" },
  { code: "ja", name: "Japanese" },
  { code: "ru", name: "Russian" },
  { code: "pt", name: "Portuguese" },
  { code: "it", name: "Italian" },
  { code: "ko", name: "Korean" },
  { code: "tr", name: "Turkish" },
  { code: "vi", name: "Vietnamese" },
  { code: "nl", name: "Dutch" },
  { code: "sv", name: "Swedish" },
  { code: "pl", name: "Polish" },
  { code: "uk", name: "Ukrainian" },
  { code: "el", name: "Greek" },
  { code: "he", name: "Hebrew" },
  { code: "th", name: "Thai" },
  { code: "cs", name: "Czech" },
  { code: "fi", name: "Finnish" },
  { code: "no", name: "Norwegian" },
  { code: "da", name: "Danish" },
  { code: "hu", name: "Hungarian" },
  { code: "ro", name: "Romanian" },
  { code: "sk", name: "Slovak" },
  { code: "bg", name: "Bulgarian" },
  { code: "hr", name: "Croatian" },
  { code: "sr", name: "Serbian" },
  { code: "lt", name: "Lithuanian" },
  { code: "lv", name: "Latvian" },
  { code: "et", name: "Estonian" },
  { code: "sl", name: "Slovenian" },
  { code: "is", name: "Icelandic" },
  { code: "ga", name: "Irish" },
  { code: "mt", name: "Maltese" },
  { code: "cy", name: "Welsh" },
  { code: "sq", name: "Albanian" },
  { code: "mk", name: "Macedonian" },
  { code: "bs", name: "Bosnian" },
  { code: "ms", name: "Malay" },
  { code: "sw", name: "Swahili" },
  { code: "am", name: "Amharic" },
  { code: "fa", name: "Persian" },
  { code: "ur", name: "Urdu" },
  { code: "bn", name: "Bengali" },
  { code: "ta", name: "Tamil" },
  { code: "te", name: "Telugu" },
  { code: "mr", name: "Marathi" },
  { code: "gu", name: "Gujarati" },
  { code: "kn", name: "Kannada" },
  { code: "ml", name: "Malayalam" },
  { code: "pa", name: "Punjabi" },
  { code: "si", name: "Sinhala" },
  { code: "ne", name: "Nepali" },
  { code: "km", name: "Khmer" },
  { code: "my", name: "Burmese" },
  { code: "lo", name: "Lao" },
  { code: "mn", name: "Mongolian" },
  { code: "kk", name: "Kazakh" },
  { code: "uz", name: "Uzbek" },
  { code: "hy", name: "Armenian" },
  { code: "az", name: "Azerbaijani" },
  { code: "ka", name: "Georgian" },
  // Add more languages as needed
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 500],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function Questionsui(props) {
  
  const numberRegex = props.apply?.exp?.length
    ? new RegExp("^[0-9]+$").test(props.apply?.exp)
    : false;
    const [error, setError] = useState("")
    console.log(error,'error')
  const [image, setImage] = useState("");
  const [imageBestWorks, setImageBestWorks] = useState("");
  const [src, setSrc] = useState({
    cv: "",
    id: "",
  });
  const [age, setAge] = React.useState("");
  const [achivement, setAchivement] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const regex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\.(com|org|net|in|edu|gov|co|io|info|biz|me)(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/;

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const loader = useSelector((state) => state.loader);
  const categorydt = useSelector((state) => state.categoryData.data);
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);
  // const handleChangeImage = (e) => {
  //   const name = e.target.name;
  
  //   if (e.target.files && e.target.files[0]) {
  //     const img = e.target.files[0];
  
  //     const imgs = URL.createObjectURL(e.target.files[0]);
  //     if (name === "CV") {
  //       setSrc({ ...src, cv: imgs });
  //       props.setApply({ ...props.apply, CV: img });
  //     } else if (name === "ID_proof") {
  //       setSrc({ ...src, id: imgs });
  //       props.setApply({ ...props.apply, ID_proof: img });
  //     }
  //   }
  // };
  const handleChangeNumber = (vl) => {
    props.setApply({ ...props.apply, phone: vl });
  };

  const handleChangeNumberCurator = (value, country) => {
    // Get country code and expected length
    const countryCode = country.countryCode; // e.g., 'np' for Nepal
    const dialCode = country.dialCode; // e.g., '977'
    const expectedLength = phoneLengthByCountry[countryCode]; // e.g., 10 for Nepal
  
    // Clean the phone number input, stripping non-numeric characters
    let phoneNumber = value.replace(/[^0-9]/g, "");
  
    // Remove the dial code from the phone number before validation
    if (phoneNumber.startsWith(dialCode)) {
      phoneNumber = phoneNumber.slice(dialCode.length);
    }
  
    // Prevent user from entering more digits than the expected length
    if (expectedLength && phoneNumber.length === expectedLength) {
      phoneNumber = phoneNumber.slice(0, expectedLength);
    }
  
    // Construct the formatted value with the country dial code
    const formattedValue = `+${dialCode}${phoneNumber}`;
  
    // Update the phone state with the formatted value
    props.setApply({ ...props.apply, phone: formattedValue });
  
    // Validate length for error display
    if (expectedLength && phoneNumber.length !== expectedLength) {
      setError(
        `Phone number must be ${expectedLength} digits long for this country code`
      );
    } else {
      setError(""); // Clear error if the length is valid
    }
  };
  const handleNavigate = () => {
    navigate("/artsmiley/artist/applyform");
  };

  const isValidURL = (url) => {
    // Regular expression to match URLs
    const regex =
      /^(https:\/\/www\.|http:\/\/www\.|www\.|https:\/\/|)[a-zA-Z0-9\-_$]+\.[a-zA-Z]{2,3}(\/[a-zA-Z0-9\-_$]{0,}|){0,}$/g;
    // const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(url);
  };
  const youtubeRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.+$/;

  const linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|company)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*\/?(\/\?.*)?$/;


  const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+(\/?.*)?$/;

  const twitterRegex = /^(https?:\/\/)?(www\.)?x\.com\/[a-zA-Z0-9_]+(\/?.*)?$/;


  const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+(\/?.*)?$/;



    const validateAndHandle = (url, errormsg, social_name) => {
      let isValid = true;
    
      if (social_name === "linked_in" && !linkedinRegex.test(url)) {
        Errornotify(errormsg);
        isValid = false;
      }
    
      if (social_name === "twitter" && !twitterRegex.test(url)) {
        Errornotify(errormsg);
        isValid = false;
      }
    
      if (social_name === "instagram" && !instagramRegex.test(url)) {
        Errornotify(errormsg);
        isValid = false;
      }
    
      if (social_name === "facebook" && !facebookRegex.test(url)) {
        Errornotify(errormsg);
        isValid = false;
      }
    
      if (social_name === "youtube" && !youtubeRegex.test(url)) {
        Errornotify(errormsg);
        isValid = false;
      }
    
      // Return true if valid, false if not
      return isValid;
    };
  const handleChangeImage = (e) => {
    const name = e.target.name;
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      const imgs = URL.createObjectURL(e.target.files[0]);
      
      if (name === "portfolio_file") {
        // setSrc({ ...src, profile: imgs });
        const validTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ]; // pdf and word doc types

        if (!validTypes.includes(img.type)) {
          Errornotify("Please upload a PDF or Word document.");
          e.target.value = "";
        } else if (img.size > 1 * 1024 * 1024) { // Check if file size exceeds 1MB
          Errornotify("Please upload a file less than 1MB.");
          e.target.value = ""; // Clear input field for large file
        } else {
          props.setApply({ ...props.apply, portfolio_file: img });
        }
      }
      if (name === "best_work") {
        if (img.type !== "image/png" && img.type !== "image/jpeg") {
          Errornotify("Please upload an image in png/jpeg format");
          e.target.value = ""; // Clear input field for invalid img type
        } else {
          props.setApply({ ...props.apply, best_work: img });
          setImageBestWorks(img);
        }
      }
      setImage(e.target.files[0]);
      
    }
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",

  ];


  const [email_errors, setEmail_Errors] = useState({});
  const [isEmailValid, setIsEmailValid] = useState(true);


  const role = props.type === "affiliate" ? "affiliate" : "curator"
  const email_value = props.type === "affiliate" ? props.apply.email : props.apply.curator_email



  const handleCheckEmailExistOrNot = async () => {
    const {data} = await api.applicationApi("api/user/checkEmailIsExistOrNot", "POST", {email: email_value,role : role})
   
    if(data.success === false) {
      setEmail_Errors({...email_errors, email: "This email is already registered. Please use a different email address."})
    
      setIsEmailValid(false);
    }else {
      setEmail_Errors({...email_errors, email: ""})
      setIsEmailValid(true);
    }
  }

  const handleEmailValidation = (email) => {
   
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (
      (props.apply.email || props.apply.curator_email) && 
      handleEmailValidation(email_value)
    ) {
      handleCheckEmailExistOrNot();
    }
  }, [props.apply.email, props.apply.curator_email]);

  const [othersReason, setOthersReason] = useState(sessionStorage.getItem("othersReason") || "");
  const [othersReasonArray, setOtherReasonArray] = useState([]);
  const [othersReasonCategory, setOthersReasonCategory] = useState(sessionStorage.getItem("othersReasonCategory") || "");
  const [othersReasonArrayCategory, setOtherReasonArrayCategory] = useState([]);

  const handleChangeKeyword = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change
    setOthersReason(value);
    sessionStorage.setItem("othersReason", value);
  };
  
const handleKeywordChangeRemove = () => {
  setOtherReasonArray([]);
  setOthersReason("");
 sessionStorage.removeItem("othersReason");
}

const handleKeywordChangeRemoveCategory = () => {
  setOtherReasonArrayCategory([]);
  setOthersReasonCategory("");
  sessionStorage.removeItem("othersReasonCategory");
}
  const handleChangeKeywordCategory = (e) => {
    const { value } = e.target;
    // Uncomment this line if you want to add the value on every change

    setOthersReasonCategory(value);
    sessionStorage.setItem("othersReasonCategory", value);
  };
  
  
 
  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImage(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const renderPhotosGallery = (source) => {
    return source.map((photo, index) => {
      return (
        <div className={styles.photoWrapper} key={index}>
          {" "}
          {/* Wrapper div with unique key */}
          <img
            className={styles.imgs}
            src={photo}
            alt=""
            style={{ cursor: "pointer" }}
          />
          <div className={styles.del_img_div}>
            <IconButton onClick={() => props.handleRemoveImageGallery(index)}>
              <img
                src="/img/delete.png"
                height="15px"
                width="14px"
                alt="delete icon"
              />
            </IconButton>
          </div>
        </div>
      );
    });
  };
  const [artistApplicationErrors, setArtistApplicationErrors] = useState({});

  const [galleryApplicationErrors, setGalleryApplicationErrors] = useState({});

  return (
    <>
      {props.type === "artist" ? (
        <div className={styles.mains}>
          <div className={styles.components}>
            <div className={styles.img_div}>
              <img
                onClick={() => navigate("/")}
                src={"/img/updated_logo.png"}
                alt="img"
              />
            </div>
            <BorderLinearProgress
              color="primary"
              variant="determinate"
              value={props.steps.stepper}
            />
            {props.steps.step === "1" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>1</h5>
                  </div>
                  <h2>
                    Artistic experience plays an important role in our selection
                    process. Incomplete information will not be taken into
                    consideration.
                  </h2>
                </div>
                <Grid container spacing={2} p={3}>
                <Grid item xs={6} mt={2.5}>
                    <h4>
                      Please upload your latest Profile in word document or pdf.
                    </h4>
                    <div
                      className={styles.preview_img}
                      style={{
                        margin: "0px",
                        height: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {props.apply.CV === "" ? (
                        <div>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              name="CV"
                              onChange={props.handleChangeImage}
                              type="file"
                              id="CV"
                              hidden
                              accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            />
                            <label htmlFor="CV" className={styles.label}>
                              <img
                                name="CV"
                                src={"/img/uploadimg.png"}
                                width="46px"
                                height="43px"
                                alt="img"
                              />
                              <p>Upload Your Latest Profile</p>
                            </label>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={styles.img_box}>
                            <div
                              htmlFor="input"
                              style={{ textAlign: "center" }}
                              onClick={props.handleChangeImage}
                            >
                              <input
                                name="CV"
                                onChange={props.handleChangeImage}
                                type="file"
                                id="CV"
                                hidden
                                accept=".pdf"
                              />
                              <label htmlFor="CV" className={styles.label}>
                                <img
                                  name="CV"
                                  src={"/img/uploadimg.png"}
                                  width="46px"
                                  height="43px"
                                  alt="img"
                                />
                                <p>{props.apply?.CV?.name}</p>
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>{" "}
                  <Grid item xs={6}  spacing={2}>
                    <h4>
                      Please write about yourself, education, art
                      qualifications, inspiration behind your artworks etc *
                    </h4>
                    <ReactQuill
                      className="reactQuilStle"
                      style={{
                        height: "120px",
                        textAlign: "left",
                        marginBottom: "35px",
                      }}
                      value={props.apply.about}
                      onChange={props.handleChangeTextEditor("about")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />
                    {artistApplicationErrors.about && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.about}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <h4>
                      What are your most important exhibitions, awards/prizes,
                      etc? * <br />
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          margin: "0",
                          padding: "0",
                        }}
                      >
                        {" "}
                        (Please provide us with the name, the year and the place
                        of each experience.)
                      </span>
                    </h4>

                    <ReactQuill
                      className="reactQuilStle"
                      style={{
                        height: "120px",
                        textAlign: "left",
                        marginBottom: "35px",
                      }}
                      value={props.apply.award_prize}
                      onChange={props.handleChangeTextEditor("award_prize")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />
                    {artistApplicationErrors.award_prize && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.award_prize}
                      </div>
                    )}
                  </Grid>
             
                  <Grid item xs={6}  mt={{ xs: 2.7, xl: 0 }}>
                    <h4>Upload your best artwork's images * <br /> <span style={{    fontWeight: "500",
                          fontSize: "14px",
                          margin: "0",
                          padding: "0",}}>
                (Upload Limit: 2–10 artworks, file format: PNG/JPEG, dimension: 120 x 120, size: 2MB)
              </span></h4>
                    <div
                      className={styles.preview_img}
                      style={{
                        margin: "0px",
                        height: "160px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <div htmlFor="input" style={{ textAlign: "center" }}>
                          <input
                            name="art_work"
                            onChange={props.handleImageChangeMultiple}
                            type="file"
                            id="art_work"
                            hidden
                            accept="image/*"
                          />
                          <label htmlFor="art_work" className={styles.label}>
                            <img
                              name="art_work"
                              src={"/img/uploadimg.png"}
                              width="46px"
                              height="43px"
                              alt="img"
                            />
                            <p>Upload Your Art Works</p>
                          </label>
                        </div>
                      </div>
                    </div>
                    {artistApplicationErrors.art_work && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {artistApplicationErrors.art_work}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <div className={styles.result}>
                  {renderPhotos(props.selectedFiles)}
                </div>
                <div className={styles.btn_div} style={{ padding: "0px 20px" }}>
                  <Button
                    onClick={() => {
                      const { isValid, artistApplicationErrors } =
                        validationForArtistApplication(
                          props.apply.about,
                          props.apply.award_prize,
                          props.apply.art_work
                        );
                      if (isValid) {
                        props.handleSteps();
                        setArtistApplicationErrors({});
                      } else {
                        console.log(artistApplicationErrors,'artistApplicationErrors')
                        setArtistApplicationErrors(artistApplicationErrors);
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : props.steps.step === "2" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>2</h5>
                </div>
                <h2> Are you a full time professional artist?*</h2>
              </div>
              <div className={styles.inputs}>
                <div className={styles.inputs_multi}>
                  <div className={styles.checkbox_div}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                    
                      name="is_fulltime_artist"
                      onChange={props.handleChangeApplyData}
                      value={props.apply.is_fulltime_artist}
                    >
                    
                          <FormControlLabel
                            value="Yes"
                            sx={{
                              backgroundColor: "#f6f6f6",
                              width: "110px",
                              borderRadius: "6px",
                              px: 1,
                            }}
                            control={<Radio />}
                            label="Yes"
                          />
                            <FormControlLabel
                            value="No"
                            sx={{
                              backgroundColor: "#f6f6f6",
                              width: "110px",
                              borderRadius: "6px",
                              px: 1,
                            }}
                            control={<Radio />}
                            label="No"
                          />
                     
                    </RadioGroup>
                  </div>
                </div>
                <div className={styles.btn_div}>
                <Button
                      onClick={() => props.handleStepsBack("1")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                  <Button
                    onClick={() => {
                      if (props.apply?.is_fulltime_artist) {
                        props.handleSteps();
                      } else {
                        Errornotify("Please select Yes or No");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            ) : props.steps.step === "3" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>3</h5>
                  </div>
                  <h2>How many years of experience you have as an artist?*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    value={props.apply.exp}
                    className={styles.input_text}
                    fullWidth
                    focused
                    name="exp"
                    placeholder="Enter Experience"
                    type="text"
                    error={
                      props.apply.exp.trim() !== "" &&
                      isNaN(props.apply.exp.trim())
                    }
                    color={
                      !props.apply.exp
                        ? ""
                        : isNaN(props.apply.exp.trim())
                        ? "error"
                        : "success"
                    }
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("2")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      // onClick={() => handleStepsss("/applyform")}
                      onClick={() => {
                        if (props.apply?.exp.length) {
                          if (!isNaN(props.apply?.exp)) {
                            props.handleSteps();
                          } else {
                            Errornotify("only numbers allowed");
                          }
                        } else {
                          Errornotify("kindly mention your experience");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "4" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>4</h5>
                  </div>
                  <h2>
                    If you are represented by another online art gallery, Please
                    provide URL link of your works on those galleries:{" "}
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="online_art_gallary_URL"
                    value={props.apply.online_art_gallary_URL}
                    focused
                    error={
                      props.apply.online_art_gallary_URL === ""
                        ? false
                        : !props.apply.online_art_gallary_URL.match(regex)
                    }
                    color={
                      props.apply.online_art_gallary_URL.length === 0
                        ? ""
                        : "success"
                    }
                    placeholder="www.example.com"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("3")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.online_art_gallary_URL.length > 0) {
                          if (props.apply.online_art_gallary_URL.match(regex)) {
                            props.handleSteps();
                          } else {
                            Errornotify("Kindly enter a valid url");
                          }
                        } else {
                          props.handleSteps();
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "5" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>5</h5>
                  </div>
                  <h2>Please provide the link to your online portfolio:</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="portfolio"
                    focused
                    error={
                      props.apply.portfolio === ""
                        ? false
                        : !props.apply.portfolio.match(regex)
                    }
                    color={props.apply.portfolio.length === 0 ? "" : "success"}
                    value={props.apply.portfolio}
                    placeholder="www.example.com"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("4")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.portfolio.length > 0) {
                          if (props.apply.portfolio.match(regex)) {
                            props.handleSteps();
                          } else {
                            Errornotify("Kindly enter a valid url");
                          }
                        } else {
                          props.handleSteps();
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "6" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>6</h5>
                  </div>
                  <h2>
                    Please provide us the links of your social media platform
                    here:
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="linked_in"
                        focused
                        error={
                          props.apply.social_links.linked_in === ""
                            ? false
                            : !props.apply.social_links.linked_in.match(
                                linkedinRegex
                              )
                        }
                        color={
                          props.apply.social_links.linked_in.length === 0
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.linked_in}
                        placeholder="www.linkedin.com/in/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        focused
                        error={
                          props.apply.social_links.facebook === ""
                            ? false
                            : !props.apply.social_links.facebook.match(
                                facebookRegex
                              )
                        }
                        color={
                          props.apply.social_links.facebook === ""
                            ? ""
                            : "success"
                        }
                        name="facebook"
                        value={props.apply.social_links.facebook}
                        placeholder="www.facebook.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="instagram"
                        focused
                        error={
                          props.apply.social_links.instagram === ""
                            ? false
                            : !props.apply.social_links.instagram.match(
                                instagramRegex
                              )
                        }
                        color={
                          props.apply.social_links.instagram === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.instagram}
                        placeholder="www.instagram.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="twitter"
                        focused
                        error={
                          props.apply.social_links.twitter === ""
                            ? false
                            : !props.apply.social_links.twitter.match(
                                twitterRegex
                              )
                        }
                        color={
                          props.apply.social_links.twitter === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.twitter}
                        placeholder="www.x.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="youtube"
                        focused
                        error={
                          props.apply.social_links.youtube === ""
                            ? false
                            : !props.apply.social_links.youtube.match(
                                youtubeRegex
                              )
                        }
                        color={
                          props.apply.social_links.youtube === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.youtube}
                        placeholder="www.youtube.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("5")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                    onClick={() => {
                      const socialLinks = props.apply?.social_links;
                    
                      // Check if any social links are provided
                      const hasSocialLinks = Object.keys(socialLinks || {}).some(
                        (key) => socialLinks[key]?.length > 0
                      );
                    
                      // If no social links are provided, call handleSteps immediately
                      if (!hasSocialLinks) {
                        props.handleSteps();
                        return;
                      }
                    
                      // Track validity of each social link
                      const validationResults = [];
                    
                      // Validate each social link and store the result
                      if (socialLinks?.linked_in.length > 0) {
                        validationResults.push(
                          validateAndHandle(
                            socialLinks?.linked_in,
                            "Please enter a valid linkedin url",
                            "linked_in"
                          )
                        );
                      }
                      if (socialLinks?.twitter.length > 0) {
                        validationResults.push(
                          validateAndHandle(
                            socialLinks?.twitter,
                            "Please enter a valid x url",
                            "twitter"
                          )
                        );
                      }
                      if (socialLinks?.instagram.length > 0) {
                        validationResults.push(
                          validateAndHandle(
                            socialLinks?.instagram,
                            "Please enter a valid instagram url",
                            "instagram"
                          )
                        );
                      }
                      if (socialLinks?.facebook.length > 0) {
                        validationResults.push(
                          validateAndHandle(
                            socialLinks?.facebook,
                            "Please enter a valid facebook url",
                            "facebook"
                          )
                        );
                      }
                      if (socialLinks?.youtube.length > 0) {
                        validationResults.push(
                          validateAndHandle(
                            socialLinks?.youtube,
                            "Please enter a valid youtube url",
                            "youtube"
                          )
                        );
                      }
                    
                      // If all validation results are true, call handleSteps
                      if (validationResults.every((isValid) => isValid)) {
                        props.handleSteps();
                      }
                    }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "7" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>7</h5>
                  </div>
                  <h2>What is your main category?* </h2>
                </div>

                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      {loader.loader ? (
                        <div className={styles.loader}>
                          <CircularLoader />
                        </div>
                      ) : (
                        categorydt &&
                        [...categorydt]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                         
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue=""
                            name="category_id"
                            onClick={props.handleSelectCheckbox}
                            key={item._id}
                          >
                            <FormControlLabel
                              key={item._id}
                              sx={{
                                backgroundColor: "#f6f6f6",
                                minWidth: "180px",
                                maxWidth: "max-content",
                                borderRadius: "6px",
                                px: 1,
                              }}
                              checked={props.apply?.category_id.some(
                                (cat) => cat._id === item._id
                              )}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  handleKeywordChangeRemoveCategory();
                                }
                              }}
                              value={item._id}
                              name="category_id"
                              control={<Checkbox />}
                              label={item.name}
                            />
                          </RadioGroup>
                          // </div>
                        ))
                      )}
                     
                    </div>
                    {props.apply?.category_id.some(
                      (cat) => cat._id === "67334c2511d9938babd097f3"
                    ) ? (
                     

                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        value={othersReasonCategory}
                        fullWidth
                        focused
                        startAdornment={
                          <InputAdornment position="end">↵</InputAdornment>
                        }
                        // onKeyDown={handleKeywordChangeCategory}
                        onChange={handleChangeKeywordCategory}
                        placeholder="Please specify"
                        type="text"
                      />
                    ) : null}
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("6")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.category_id?.length) {
                          if(props.apply?.category_id.some(
                            (cat) => cat._id === "67334c2511d9938babd097f3"
                          )) {
                            if(!othersReasonCategory?.length) {
                              Errornotify("Please enter other category");
                              return;
                            }
                          }
                          props.handleSteps();
                        } else {
                          Errornotify("Please select your categories");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "8" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>8</h5>
                  </div>
                  <h2>Please select your artwork selling price range*:</h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      {props.checkboxdt.step6 &&
                        props.checkboxdt.step6.map((item) => (
                          // <RadioGroup
                          //  aria-labelledby="demo-radio-buttons-group-label"
                          //  defaultValue=""
                          //  name="art_price_range"
                          //  key={item.id}>
                          //  <FormControlLabel
                          //   key={item.label}
                          //   onChange={props.handleChangeApplyData}
                          //   sx={{
                          //    backgroundColor: "#f6f6f6",
                          //    minWidth: "180px",
                          //    maxWidth: "max-content",
                          //    borderRadius: "6px",
                          //    px: 1,
                          //   }}
                          //   value={item.label}
                          //   control={<Radio />}
                          //   label={item.label}
                          //  />
                          // </RadioGroup>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="art_price_range"
                            value={props.apply.art_price_range}
                            key={item.label}
                            onClick={props.handleSelectCheckbox}
                          >
                            <FormControlLabel
                              name="art_price_range"
                              value={item.label}
                              checked={props.apply.art_price_range.includes(
                                item.label
                              )}
                              control={<Checkbox />}
                              label={item.label}
                            />
                          </RadioGroup>
                          // <FormControlLabel
                          //  name="art_price_range"
                          //  value={props.apply.art_price_range}
                          //  key={item.label}
                          //  onChange={props.handleSelectCheckbox}
                          //  label={item.label}
                          //  control={<Checkbox sx={{ p: 0, m: 0 }} />}
                          //  sx={{ p: 0, m: 0 }}
                          // />

                          //  <Select
                          //  labelId="select-mutli-artist"
                          //  id="demo-select-artist"
                          //  value={addData.artist_ids.length === 0 ? [] : addData.artist_ids.map((val) => val._id)}
                          //  fullWidth
                          //  multiple
                          //  sx={{
                          //   background: "#f6f6f6",
                          //   ".MuiOutlinedInput-notchedOutline": { border: "none" },
                          //   "& .MuiInputBase-root": {
                          //    borderRadius: "6px",
                          //   },
                          //   height: "40px",
                          //  }}
                          //  placeholder="select type"
                          //  onChange={handleSelectArtist}
                          //  name="artist_ids"
                          //  // input={<OutlinedInput label="Aritst Name" />}
                          //  // MenuProps={MenuProps}
                          // >
                          //  {artistdatas &&
                          //   artistdatas.data
                          //    .filter((fl) => {
                          //     return fl.is_accepted === "Approved";
                          //    })
                          //    .map((val) => (
                          //     <MenuItem value={val._id} key={val._id}>
                          //      {val.first_name} {val.last_name}
                          //     </MenuItem>
                          //    ))}
                          // </Select>
                        ))}
                    </div>
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("7")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.art_price_range?.length) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please select your selling price range");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                {/* {val.data &&
        val.data.map((item) => (
         <TextField
          key={item.id}
          InputProps={{ style: { fontSize: 25 } }}
          sx={{ width: { xs: "100%", sm: "45%" }, lineHeight: "34px", fontSize: "25px" }}
          variant="standard"
          className={styles.input_text}
          fullWidth
          name="{item.name}"
          placeholder="{item.placeholder}"
          type="{item.type}"
         />
        ))} */}
              </div>
            ) : props.steps.step === "9" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>9</h5>
                  </div>
                  <h2>
                    How many artworks have you sold in the past 12 months?*{" "}
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="arts_solds_in_past_12_months"
                    value={props.apply.arts_solds_in_past_12_months}
                    placeholder="Number of arts"
                    type="text"
                    error={
                      props.apply.arts_solds_in_past_12_months.trim() !== "" &&
                      isNaN(props.apply.arts_solds_in_past_12_months.trim())
                    }
                    color={
                      !props.apply.arts_solds_in_past_12_months
                        ? ""
                        : isNaN(props.apply.arts_solds_in_past_12_months.trim())
                        ? "error"
                        : "success"
                    }
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("8")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      // onClick={() => handleStepsss("/applyform")}
                      onClick={() => {
                        if (props.apply?.arts_solds_in_past_12_months.length) {
                          if (
                            !isNaN(props.apply?.arts_solds_in_past_12_months)
                          ) {
                            props.handleSteps();
                          } else {
                            Errornotify("kindly enter the number");
                          }
                        } else {
                          Errornotify("kindly enter number of arts");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "10" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>10</h5>
                  </div>
                  <h2>How many artworks do you produce each month?* </h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="produce_art_each_month"
                    value={props.apply.produce_art_each_month}
                    placeholder="Number of arts"
                    type="text"
                    onChange={props.handleChangeApplyData}
                    error={
                      props.apply.produce_art_each_month?.trim() !== "" &&
                      props.apply.produce_art_each_month.trim() === "0" // Check if produce_art_each_month is "0"
                    }
                    color={
                      !props.apply.produce_art_each_month
                        ? ""
                        : isNaN(props.apply.produce_art_each_month.trim())
                        ? "error"
                        : "success"
                    }
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("9")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.produce_art_each_month.length) {
                          if (!isNaN(props.apply?.produce_art_each_month)) {
                            if (props.apply.produce_art_each_month > 0) {
                              props.handleSteps();
                            } else {
                              Errornotify(
                                "kindly enter a number greater than 0"
                              );
                            }
                          } else {
                            Errornotify("kindly enter the number");
                          }
                        } else {
                          Errornotify("kindly enter number of arts");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "11" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>11</h5>
                  </div>
                  <h2>
                    Why do you think Art Smiley would need to select you and
                    promote your works?*
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      {props.checkboxdt.step9 &&
                        props.checkboxdt.step9.map((item) => (
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="promoting_by_reason"
                            onClick={props.handleSelectCheckbox}
                            key={item.id}
                          >
                            <FormControlLabel
                              key={item.id}
                              sx={{
                                backgroundColor: "#f6f6f6",
                                minWidth: "180px",
                                maxWidth: "max-content",
                                borderRadius: "6px",
                                px: 1,
                              }}
                              value={item.label}
                              control={<Checkbox />}
                              checked={props.apply.promoting_by_reason.includes(
                                item.label
                              )}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  handleKeywordChangeRemove();
                                }
                              }}
                              label={item.label}
                              name="promoting_by_reason"
                            />
                          </RadioGroup>
                        ))}
                    </div>
                  

                    {props.apply.promoting_by_reason.includes("Others") ? (
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        value={othersReason}
                        className={styles.input_text}
                        fullWidth
                        focused
                        startAdornment={
                          <InputAdornment position="end">↵</InputAdornment>
                        }
                        // onKeyDown={handleKeywordChange}
                        onChange={handleChangeKeyword}
                        placeholder="Please specify"
                        type="text"
                      />
                    ) : null}
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("10")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>

                    <Button
                      onClick={() => {
                        if (props.apply?.promoting_by_reason?.length) {
                          if (props.apply?.promoting_by_reason?.includes("Others")) {
                            if (othersReason.length) {
                              props.handleSteps();
                            } else {
                              Errornotify("Please enter your reason");
                            }
                          }else {
                            props.handleSteps();
                          }
                          // props.handleSteps();
                        } else {
                          Errornotify("Please select your reason");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "12" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>12</h5>
                  </div>
                  <h2>Which category of artist you belong to?*</h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      {props.artistTypes?.map((val) => (
                        <RadioGroup
                          key={val?._id}
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={props.apply.completion}
                          onClick={props.handleChangeApplyData}
                          name="artist_type_id"
                        >
                          <FormControlLabel
                            key={val?._id}
                            value={val?._id}
                            sx={{
                              backgroundColor: "#f6f6f6",
                              width: "auto",
                              borderRadius: "6px",
                              px: 1,
                            }}
                            checked={props.apply.artist_type_id === val?._id}
                            control={<Radio />}
                            label={val?.name}
                          />
                        </RadioGroup>
                      ))}
                    </div>
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("11")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.artist_type_id) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please select your artist type");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "13" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>13</h5>
                  </div>
                  <h2>Do you prefer to work Exclusively with Art Smiley?*</h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.apply.exclusive_work}
                        name="exclusive_work"
                        onClick={props.handleChangeApplyData}
                      >
                        <FormControlLabel
                          value="Yes"
                          sx={{
                            backgroundColor: "#f6f6f6",
                            width: "110px",
                            borderRadius: "6px",
                            px: 1,
                          }}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          sx={{
                            backgroundColor: "#f6f6f6",
                            width: "110px",
                            borderRadius: "6px",
                            px: 1,
                          }}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("12")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>

                    <Button
                      onClick={() => {
                        if (props.apply.exclusive_work) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please select Yes or No");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ): null}
          </div>
        </div>
      ) : props.type === "gallery" ? (
        <div className={styles.mains}>
          <div className={styles.components}>
            <div className={styles.img_div}>
              <img
                onClick={() => navigate("/")}
                src={"/img/updated_logo.png"}
                alt="img"
              />
            </div>
            <h4 className={styles.gallery_color}>Gallery Profile plays an important role in our selection process. Please fill all details properly. Incomplete information will not be 
            taken into consideration.</h4>
            <BorderLinearProgress
              color="primary"
              variant="determinate"
              value={props.steps.stepper}
            />
            {props.steps.step === "1" ? (
             <div className={styles.card}>
             <div className={styles.main_heading}>
               <div className={styles.question_no_div}>
                 <h5>1</h5>
               </div>
               <h2>
                 Please upload portfolio of your gallery in word document or pdf.*
               </h2>
             </div>
             <Grid container spacing={2} p={3}>
               {/* Portfolio Upload */}
               <Grid item xs={6} mt={2.5}>
                 <h4>Please upload your latest portfolio file *</h4>
                 <div
                   className={styles.preview_img}
                   style={{
                     margin: "0px",
                     height: "160px",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                   }}
                 >
                   {props.apply.portfolio_file === "" ? (
                     <div className={styles.img_box}>
                       <div
                         htmlFor="input"
                         style={{ textAlign: "center" }}
                         onClick={handleChangeImage}
                       >
                         <input
                           name="portfolio_file"
                           onChange={handleChangeImage}
                           type="file"
                           id="portfolio_file"
                           hidden
                           accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                         />
                         <label htmlFor="portfolio_file" className={styles.label}>
                           <img
                             name="portfolio_file"
                             src={"/img/uploadimg.png"}
                             width="46px"
                             height="43px"
                             alt="img"
                           />
                           <p>Upload Your File</p>
                         </label>
                       </div>
                     </div>
                   ) : (
                     <div className={styles.img_box}>
                       <div
                         htmlFor="input"
                         style={{ textAlign: "center" }}
                         onClick={handleChangeImage}
                       >
                         <input
                           name="portfolio_file"
                           onChange={handleChangeImage}
                           type="file"
                           id="portfolio_file"
                           hidden
                           accept=".pdf"
                         />
                         <label htmlFor="portfolio_file" className={styles.label}>
                           <img
                             name="portfolio_file"
                             src={"/img/uploadimg.png"}
                             width="46px"
                             height="43px"
                             alt="img"
                           />
                           <p>{props.apply.portfolio_file?.name}</p>
                         </label>
                       </div>
                     </div>
                   )}
                 </div>
                 {galleryApplicationErrors.portfolio_file && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {galleryApplicationErrors.portfolio_file}
                      </div>
                    )}
               </Grid>
           
               {/* About Gallery */}
               <Grid item xs={6} mt={2.5}>
                 <h4>About Gallery *</h4>
                 <ReactQuill
                   className="reactQuilStle"
                   style={{
                     height: "120px",
                     textAlign: "left",
                     marginBottom: "35px",
                   }}
                   value={props.apply.description}
                   onChange={props.handleChangeTextEditor("description")}
                   theme="snow"
                   modules={modules}
                   formats={formats}
                 />
                  {galleryApplicationErrors.description && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {galleryApplicationErrors.description}
                      </div>
                    )}
               </Grid>
           
               {/* Exhibitions, Awards, Prizes */}
               <Grid item xs={6} mt={2.5}>
                 <h4>
                   Please provide details of prestigious exhibitions, awards/prizes,
                   commissions, grants, recognitions, etc. *
                 </h4>
               <ReactQuill
                   className="reactQuilStle"
                   style={{
                     height: "120px",
                     textAlign: "left",
                     marginBottom: "35px",
                   }}
                   value={props.apply.award}
                   onChange={props.handleChangeTextEditor("award")}
                   theme="snow"
                   modules={modules}
                   formats={formats}
                 />
                  {galleryApplicationErrors.award && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {galleryApplicationErrors.award}
                      </div>
                    )}
               </Grid>


               <Grid item xs={6} mt={2.5}>
                 <h4>
                 Please provide us with the name, the year and the place of
                 each experience *.
                 </h4>
                 <ReactQuill
                      className="reactQuilStle"
                      style={{
                        height: "120px",
                        textAlign: "left",
                        marginBottom: "35px",
                      }}
                      value={props.apply.degree_year}
                      onChange={props.handleChangeTextEditor("degree_year")}
                      theme="snow"
                      modules={modules}
                      formats={formats}
                    />
                      {galleryApplicationErrors.degree_year && (
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          fontSize: "12px",
                          paddingTop: "15px",
                        }}
                      >
                        {galleryApplicationErrors.degree_year}
                      </div>
                    )}
               </Grid>
             
             </Grid>


           
            
           
             {/* Next Button */}
             <div className={styles.btn_div}>
               <Button
                 onClick={() => {
                  const { isValid, galleryApplicationErrors } =
                    validationForGalleryApplication(
                      props.apply.portfolio_file,
                      props.apply.description,
                      props.apply.award,
                      props.apply.degree_year,
                    );
                  if (isValid) {
                    props.handleSteps();
                    setGalleryApplicationErrors({});
                  } else {
                    //console.log(artistApplicationErrors,'artistApplicationErrors')
                    setGalleryApplicationErrors(galleryApplicationErrors);
                  }
                }}
                 variant="contained"
                 sx={{ width: "126px", height: "50px", my: 2 }}
               >
                 Next
               </Button>
             </div>
           </div>
           
            ) : props.steps.step === "2" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>2</h5>
                </div>
                <h2>
                  Upload Your Best Artists'  Works*{" "} <br />
                  <span style={{ fontSize: "15px" }}>
                    (Upload Limit: 2–10 artworks, file format: PNG/JPEG, size: 2MB)
                  </span>
                </h2>
              </div>
              <div className={styles.inputs}>
                <div className={styles.preview_img}>
           
                    <div className={styles.img_box}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                  
                      >
                        <input
                          name="best_work"
                          onChange={props.handleImageChangeMultipleGallery}
                          type="file"
                          id="best_work"
                          hidden
                          accept="image/*"
                        />
                        <label htmlFor="best_work" className={styles.label}>
                          <img
                            name="best_work"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p>Upload Your Best Artists'  Works</p>
                        </label>
                      </div>
                    </div>
               
               
                </div>
                <div className={styles.result}>
                {renderPhotosGallery(props.selectedFilesGallery)}
              </div>
                <div className={styles.btn_div}>
                  <Button
                    onClick={() => props.handleStepsBack("1")}
                    
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                   
                   onClick={() => {
                    if (props.apply.best_work.length) {
                      if (props.selectedFilesGallery.length >= 2 && props.selectedFilesGallery.length <= 10) {
                        props.handleSteps();
                      } else {
                        Errornotify("Upload between 2 and 10 best artist works");
                      }
                    } else {
                      Errornotify("Please upload your best artist works");
                    }
                    
                  }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            ) : props.steps.step === "3" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>3</h5>
                  </div>
                  <h2>
                    If your gallery is represented by another online platform, Please
                    provide URL link:
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="other_URL"
                    error={
                      props.apply.other_URL === ""
                        ? false
                        : !props.apply.other_URL.match(regex)
                    }
                    color={props.apply.other_URL.length === 0 ? "" : "success"}
                  
                    value={props.apply.other_URL}
                    placeholder="www.example.com"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("2")}
                     
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.other_URL.length > 0) {
                          if (props.apply?.other_URL.match(regex)) {
                            props.handleSteps();
                          } else {
                            Errornotify("Please enter the valid url");
                          }
                        } else {
                          props.handleSteps();
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "4" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>4</h5>
                </div>
                <h2>
                  Please provide the link to your gallery's online portfolio:{" "}
                </h2>
              </div>
              <div className={styles.inputs}>
                <TextField
                  InputProps={{ style: { fontSize: 25 } }}
                  sx={{ lineHeight: "34px", fontSize: "25px" }}
                  variant="standard"
                  className={styles.input_text}
                  fullWidth
                  error={
                    props.apply.portfolio === ""
                      ? false
                      : !props.apply.portfolio.match(regex)
                  }
                  color={props.apply.portfolio.length === 0 ? "" : "success"}
                  name="portfolio"
                  value={props.apply.portfolio}
                  placeholder="www.example.com"
                  type="text"
                  onChange={props.handleChangeApplyData}
                />
                <div className={styles.btn_div}>
                  <Button
                    onClick={() => props.handleStepsBack("3")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                   onClick={() => {
                    if (props.apply?.portfolio.length) {
                      if (props.apply?.portfolio.match(regex)) {
                        props.handleSteps();
                      } else {
                        Errornotify("Please enter the valid url");
                      }
                    } else {
                      props.handleSteps();
                    }
                  }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            ) : props.steps.step === "5" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>5</h5>
                  </div>
                  <h2>
                    Please provide us the links of your gallery's social media
                    platform here:
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="linked_in"
                        focused
                        error={
                          props.apply.social_links.linked_in === ""
                            ? false
                            : !props.apply.social_links.linked_in.match(linkedinRegex)
                        }
                        color={
                          props.apply.social_links.linked_in.length === 0
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.linked_in}
                        placeholder="www.linkedin.com/in/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        // focused
                        error={
                          props.apply.social_links.facebook === ""
                            ? false
                            : !props.apply.social_links.facebook.match(facebookRegex)
                        }
                        color={
                          props.apply.social_links.facebook === ""
                            ? ""
                            : "success"
                        }
                        name="facebook"
                        value={props.apply.social_links.facebook}
                        placeholder="www.facebook.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="instagram"
                        error={
                          props.apply.social_links.instagram === ""
                            ? false
                            : !props.apply.social_links.instagram.match(instagramRegex)
                        }
                        color={
                          props.apply.social_links.instagram === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.instagram}
                        placeholder="www.instagram.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="twitter"
                        error={
                          props.apply.social_links.twitter === ""
                            ? false
                            : !props.apply.social_links.twitter.match(twitterRegex)
                        }
                        color={
                          props.apply.social_links.twitter === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.twitter}
                        placeholder="www.x.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="youtube"
                        error={
                          props.apply.social_links.youtube === ""
                            ? false
                            : !props.apply.social_links.youtube.match(youtubeRegex)
                        }
                        color={
                          props.apply.social_links.youtube === ""
                            ? ""
                            : "success"
                        }
                        value={props.apply.social_links.youtube}
                        placeholder="www.youtube.com/example"
                        type="text"
                        onChange={props.handleChangeSocialLinks}
                      />
                    </Grid>
                  </Grid>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("4")}
                      
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        const socialLinks = props.apply?.social_links;
                      
                        // Check if any social links are provided
                        const hasSocialLinks = Object.keys(socialLinks || {}).some(
                          (key) => socialLinks[key]?.length > 0
                        );
                      
                        // If no social links are provided, call handleSteps immediately
                        if (!hasSocialLinks) {
                          props.handleSteps();
                          return;
                        }
                      
                        // Track validity of each social link
                        const validationResults = [];
                      
                        // Validate each social link and store the result
                        if (socialLinks?.linked_in.length > 0) {
                          validationResults.push(
                            validateAndHandle(
                              socialLinks?.linked_in,
                              "Please enter a valid linkedin url",
                              "linked_in"
                            )
                          );
                        }
                        if (socialLinks?.twitter.length > 0) {
                          validationResults.push(
                            validateAndHandle(
                              socialLinks?.twitter,
                              "Please enter a valid x url",
                              "twitter"
                            )
                          );
                        }
                        if (socialLinks?.instagram.length > 0) {
                          validationResults.push(
                            validateAndHandle(
                              socialLinks?.instagram,
                              "Please enter a valid instagram url",
                              "instagram"
                            )
                          );
                        }
                        if (socialLinks?.facebook.length > 0) {
                          validationResults.push(
                            validateAndHandle(
                              socialLinks?.facebook,
                              "Please enter a valid facebook url",
                              "facebook"
                            )
                          );
                        }
                        if (socialLinks?.youtube.length > 0) {
                          validationResults.push(
                            validateAndHandle(
                              socialLinks?.youtube,
                              "Please enter a valid youtube url",
                              "youtube"
                            )
                          );
                        }
                      
                        // If all validation results are true, call handleSteps
                        if (validationResults.every((isValid) => isValid)) {
                          props.handleSteps();
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            )  : props.steps.step === "6" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>6</h5>
                </div>
                <h2>
                  Please select the main categories your artists work with*
                </h2>
              </div>
              <div className={styles.inputs}>
                <div className={styles.inputs_multi}>
                  <div className={styles.checkbox_div}>
                    {loader.loader ? (
                      <div className={styles.loader}>
                        <CircularLoader />
                      </div>
                    ) : (
                      categorydt &&
                        [...categorydt]?.sort((a, b) => (a.name === "Other Media" ? 1 : b.name === "Other Media" ? -1 : 0))?.map((item) => (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue=""
                          name="category_id"
                          onClick={props.handleSelectCheckbox}
                          key={item._id}
                        >
                          <FormControlLabel
                            key={item._id}
                            checked={props.apply?.category_id.some(
                              (cat) => cat._id === item._id
                            )}
                            sx={{
                              backgroundColor: "#f6f6f6",
                              minWidth: "180px",
                              maxWidth: "max-content",
                              borderRadius: "6px",
                              px: 1,
                            }}
                            onChange={(event) => {
                              if (!event.target.checked) {
                                handleKeywordChangeRemoveCategory();
                              }
                            }}
                            value={item._id}
                            name="category_id"
                            control={<Checkbox />}
                            label={item.name}
                          />
                        </RadioGroup>
                        // </div>
                      ))
                    )}
                    {othersReasonArrayCategory.length ? (
                      <>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                        >
                          {othersReasonArrayCategory?.map((val, index) => (
                            <FormControlLabel
                              key={index} // Use a unique key for each child
                              sx={{
                                backgroundColor: "#f6f6f6",
                                minWidth: "180px",
                                maxWidth: "max-content",
                                borderRadius: "6px",
                                px: 1,
                                py:1
                              }}
                              onChange={(event) => {
                                if (!event.target.checked) {
                                  handleKeywordChangeRemoveCategory();
                                }
                              }}
                              checked={true}
                              control={<Checkbox sx={{display:"none"}} />}
                              label={val} // Display the value as the label
                            />
                          ))}
                        </RadioGroup>
                      </>
                    ) : null}
                  </div>
                  {props.apply?.category_id.some(
                    (cat) => cat._id === "67334c2511d9938babd097f3"
                  ) ? (
                    // Your conditional rendering here

                    <TextField
                      InputProps={{ style: { fontSize: 25 } }}
                      sx={{ lineHeight: "34px", fontSize: "25px" }}
                      variant="standard"
                      className={styles.input_text}
                      value={othersReasonCategory}
                      fullWidth
                      focused
                      startAdornment={
                        <InputAdornment position="end">↵</InputAdornment>
                      }
                      // onKeyDown={handleKeywordChangeCategory}
                      onChange={handleChangeKeywordCategory}
                      placeholder="Please specify"
                      type="text"
                    />
                  ) : null}
                </div>
                <div className={styles.btn_div}>
                  <Button
                    onClick={() => props.handleStepsBack("5")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.apply?.category_id?.length) {
                        if(props.apply?.category_id.some(
                          (cat) => cat._id === "67334c2511d9938babd097f3"
                        )) {
                          if(!othersReasonCategory.length) {
                            Errornotify("Please enter other category");
                            return;
                          }
                        }
                        props.handleSteps();
                      } else {
                        Errornotify("Please select your categories");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            ) : props.steps.step === "7" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>7</h5>
                </div>
                <h2>Please select your artwork selling price range:*</h2>
              </div>
              <div className={styles.inputs}>
                <div className={styles.inputs_multi}>
                  <div className={styles.checkbox_div}>
                    {props.checkboxdt.step11 &&
                      props.checkboxdt.step11.map((item) => (
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="art_price_range"
                          value={props.apply.art_price_range}
                          key={item.label}
                          onClick={props.handleSelectCheckbox}
                        >
                          <FormControlLabel
                            name="art_price_range"
                            checked={props.apply.art_price_range.includes(
                              item.label
                            )}
                            value={item.label}
                            control={<Checkbox />}
                            label={item.label}
                          />
                        </RadioGroup>
                      ))}
                  </div>
                </div>
                <div className={styles.btn_div}>
                  <Button
                    onClick={() => props.handleStepsBack("6")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                     onClick={() => {
                      if (props.apply?.art_price_range?.length) {
                        props.handleSteps();
                      } else {
                        Errornotify("Please select your selling price range");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
            ) : props.steps.step === "8" ? (
              <div className={styles.card}>
              <div className={styles.main_heading}>
                <div className={styles.question_no_div}>
                  <h5>8</h5>
                </div>
                <h2>
                  How many artworks have you sold in the past 12 months? *
                </h2>
              </div>
              <div className={styles.inputs}>
                <TextField
                  InputProps={{ style: { fontSize: 25 } }}
                  sx={{ lineHeight: "34px", fontSize: "25px" }}
                  variant="standard"
                  className={styles.input_text}
                  fullWidth
                  name="arts_solds_in_past_12_months"
                  value={props.apply.arts_solds_in_past_12_months}
                  placeholder="Number of arts"
                  type="text"
                  error={
                    props.apply.arts_solds_in_past_12_months.trim() &&
                    !/^\d+$/.test(props.apply.arts_solds_in_past_12_months)
                  }
                  color={
                    props.apply.arts_solds_in_past_12_months.trim()
                      ? /^\d+$/.test(props.apply.arts_solds_in_past_12_months)
                        ? "success"
                        : "error"
                      : ""
                  }
                  onChange={props.handleChangeApplyData}
                />
                <div className={styles.btn_div}>
                  <Button
                    onClick={() => props.handleStepsBack("7")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.apply?.arts_solds_in_past_12_months.length) {
                        if (
                          !isNaN(props.apply?.arts_solds_in_past_12_months)
                        ) {
                          props.handleSteps();
                        } else {
                          Errornotify("kindly enter the number");
                        }
                      } else {
                        Errornotify("kindly enter number of arts");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
            
          </div>
        </div>
      ) : props.type === "curator" ? (
        <div className={styles.mains}>
          <div className={styles.components}>
            <div className={styles.img_div}>
              <img
                onClick={() => navigate("/")}
                src="/img/updated_logo.png"
                alt="img"
              />
            </div>
            <BorderLinearProgress
              color="primary"
              variant="determinate"
              value={props.steps.stepper}
            />
            {props.steps.step === "1" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>1</h5>
                  </div>
                  <h2> Enter your first name*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="first_name"
                    value={props.apply.first_name}
                    focused
                    placeholder="Enter your first name"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      // onClick={() => handleStepsss("/applyform")}
                      onClick={() => {
                        if (props.apply?.first_name?.length) {
                          // Check if the first name contains only alphabets
                          const onlyAlphabets = /^[A-Za-z]+$/.test(
                            props.apply.first_name
                          );

                          if (onlyAlphabets) {
                            props.handleSteps();
                          } else {
                            Errornotify("only alphabets allowed");
                          }
                        } else {
                          Errornotify("Please enter your first name!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "2" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>2</h5>
                  </div>
                  <h2> Enter your last name*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="last_name"
                    value={props.apply.last_name}
                    focused
                    placeholder="Enter your last name"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("1")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.last_name?.length) {
                          // Check if the first name contains only alphabets
                          const onlyAlphabets = /^[A-Za-z]+$/.test(
                            props.apply.last_name
                          );

                          if (onlyAlphabets) {
                            props.handleSteps();
                          } else {
                            Errornotify("only alphabets allowed");
                          }
                        } else {
                          Errornotify("Please enter your last name!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "3" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>3</h5>
                  </div>
                  <h2> Enter your email address*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="curator_email"
                    value={props.apply.curator_email}
                    focused
                    placeholder="Enter your email address"
                    type="text"
                    onChange={props.handleChangeApplyData}
                    error={
                      props.apply.curator_email.trim() &&
                      !emailRegex.test(props.apply.curator_email)
                    }
                    color={
                      props.apply.curator_email.trim()
                        ? emailRegex.test(props.apply.curator_email)
                          ? "success"
                          : "error"
                        : ""
                    }
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("2")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        const email = props.apply.curator_email?.trim();
                        if (!email?.length) {
                          Errornotify("Please enter your email address");
                          return;
                        }
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(email)) {
                          Errornotify("Please enter a valid email address");
                          return;
                        }
                        if (!isEmailValid) {
                          Errornotify(email_errors.email || "Invalid email address. Please correct it.");
                          return;
                        }
                        props.handleSteps(); 
                      }}
                        
                    
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "4" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>4</h5>
                  </div>
                  <h2> Enter your phone number*</h2>
                </div>
                <div className={styles.inputs}>
                  {/* <TextField
                        InputProps={{ style: { fontSize: 25 } }}
                        sx={{ lineHeight: "34px", fontSize: "25px" }}
                        variant="standard"
                        className={styles.input_text}
                        fullWidth
                        name="phone"
                        value={props.apply.phone}
                        placeholder="Enter Phone"
                        type="number"
                        onChange={props.handleChangeApplyData}
                      /> */}
                  <Entermobile
                    handleChangeNumber={handleChangeNumberCurator}
                    value={props.apply.phone}
                    border="applyform"
                  />
                     {props.apply.phone && error && (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {error}
            </span>
          )}
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("3")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.phone?.length) {
                          if (
                            error.length
                          ) {
                            Errornotify("Please enter a valid phone number");
                          } else {
                            props.handleSteps();
                          }
                        } else {
                          Errornotify("Please enter your phone number");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "5" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>5</h5>
                  </div>
                  <h2>Please write about yourself, education, art qualifications, achievements, etc *</h2>
                </div>
                <div className={styles.inputs}>
                  
                  <ReactQuill
                    className="reactQuilStle"
                    style={{
                      height: "120px",
                      textAlign: "left",
                      marginBottom: "35px",
                    }}
                    value={props.apply.achievement}
                    onChange={props.handleChangeShowsCurated("achievement")}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className={styles.btn_div_new}>
                  <Button
                    onClick={() => props.handleStepsBack("4")}
                   
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      const hasAchievement =
                      props.apply.achievement &&
                      props.apply.achievement !== "<p><br></p>";
                    if (hasAchievement) {
                      props.handleSteps();
                    } else {
                      Errornotify("Please Fill out this field");
                    }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : props.steps.step === "6" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>6</h5>
                  </div>
                  <h2>Professional Qualifications*</h2>
                </div>
                <div className={styles.inputs}>
                  <ReactQuill
                    className="reactQuilStle"
                    value={props.apply.qualification}
                    style={{
                      height: "120px",
                      textAlign: "left",
                      marginBottom: "35px",
                    }}
                    onChange={props.handleChangeShowsCurated("qualification")}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                </div>
                <div className={styles.btn_div_new}>
                  <Button
                    onClick={() => props.handleStepsBack("5")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      const hasQualificationContent =
                        props.apply.qualification &&
                        props.apply.qualification !== "<p><br></p>";
                      if (hasQualificationContent) {
                        props.handleSteps();
                      } else {
                        Errornotify("Please enter your qualification");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : props.steps.step === "7" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>7</h5>
                  </div>
                  <h2>Shows Curated</h2>
                </div>
                <div className={styles.inputs}>
                  <ReactQuill
                    className="reactQuilStle"
                    value={props.apply.shows_curated}
                    style={{
                      height: "120px",
                      textAlign: "left",
                      marginBottom: "35px",
                    }}
                    onChange={props.handleChangeShowsCurated("shows_curated")}
                    theme="snow"
                    modules={modules}
                    formats={formats}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("6")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        props.handleSteps();
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
                {/* {val.data &&
     val.data.map((item) => (
      <TextField
       key={item.id}
       InputProps={{ style: { fontSize: 25 } }}
       sx={{ width: { xs: "100%", sm: "45%" }, lineHeight: "34px", fontSize: "25px" }}
       variant="standard"
       className={styles.input_text}
       fullWidth
       name="{item.name}"
       placeholder="{item.placeholder}"
       type="{item.type}"
      />
     ))} */}
              </div>
            ) : props.steps.step === "8" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>8</h5>
                  </div>
                  <h2>
                    Upload your profile detailing your experience?* <br />
                    <span style={{ fontSize: "15px" }}>
                      (Upload files in PDF format or Word document format)
                    </span>
                  </h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.preview_img}>
                    {props.apply.CV === "" ? (
                      <div className={styles.img_box}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={props.handleChangeImage}
                        >
                          <input
                            name="CV"
                            onChange={props.handleChangeImage}
                            type="file"
                            id="CV"
                            hidden
                            accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          />
                          <label htmlFor="CV" className={styles.label}>
                            <img
                              name="CV"
                              src={"/img/uploadimg.png"}
                              width="46px"
                              height="43px"
                              alt="img"
                            />
                            <p>Upload Your Profile Experience Details</p>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.img_box}>
                          <div
                            htmlFor="input"
                            style={{ textAlign: "center" }}
                            onClick={props.handleChangeImage}
                          >
                            <input
                              name="CV"
                              onChange={props.handleChangeImage}
                              type="file"
                              id="CV"
                              hidden
                              accept=".pdf"
                            />
                            <label htmlFor="CV" className={styles.label}>
                              <img
                                name="CV"
                                src={"/img/uploadimg.png"}
                                width="46px"
                                height="43px"
                                alt="img"
                              />
                              <p>{props.apply?.CV?.name}</p>
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("7")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.CV?.name?.length) {
                          props.handleSteps();
                        } else {
                          Errornotify(
                            "Please upload your profile detail document"
                          );
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "9" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>9</h5>
                  </div>
                  <h2>
                    ID Proof*{" "}
                    <span style={{ fontSize: "15px" }}>
                      (Upload files in PDF format or jpeg/png format)
                    </span>
                  </h2>
                </div>
                <div className={styles.inputs}>
                  {props.apply.ID_proof === "" ? (
                    <div className={styles.img_box_new}>
                      <div
                        htmlFor="input"
                        style={{ textAlign: "center" }}
                        onClick={props.handleChangeImage}
                      >
                        <input
                          name="ID_proof"
                          onChange={props.handleChangeImage}
                          type="file"
                          id="ID_proof"
                          hidden
                          accept=".pdf, .jpeg, .jpg, .png"

                        />
                        <label htmlFor="ID_proof" className={styles.label}>
                          <img
                            name="ID_proof"
                            src={"/img/uploadimg.png"}
                            width="46px"
                            height="43px"
                            alt="img"
                          />
                          <p>Upload Your ID Proof</p>
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.img_box_new}>
                        <div
                          htmlFor="input"
                          style={{ textAlign: "center" }}
                          onClick={props.handleChangeImage}
                        >
                          <input
                            name="ID_proof"
                            onChange={props.handleChangeImage}
                            type="file"
                            id="ID_proof"
                            hidden
                            accept=".pdf, .jpeg, .jpg, .png"

                          />
                          <label htmlFor="ID_proof" className={styles.label}>
                            <img
                              name="ID_proof"
                              src={"/img/uploadimg.png"}
                              width="46px"
                              height="43px"
                              alt="img"
                            />
                            <p>{props.apply?.ID_proof?.name}</p>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("8")}
                      // onClick={props.handleSteps}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.ID_proof?.name?.length) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please upload your ID proof!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
               
              </div>
            ) : props.steps.step === "10" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>10</h5>
                  </div>
                  <h2> Enter your country of residence*</h2>
                </div>
                <div className={styles.inputs}>
                
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    name="country"
                    value={props.apply.country}
                    onChange={props.handleChangeApplyData}
                    // variant=""
                    fullWidth
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {country.map((c) => (
                      <MenuItem value={c.label}>{c.label}</MenuItem>
                    ))}
                  </Select>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("9")}
                   
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.country.length) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please select your country residence!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div className={styles.mains}>
          <div className={styles.components}>
            <div className={styles.img_div}>
              <img
                onClick={() => navigate("/")}
                src={"/img/updated_logo.png"}
                alt="img"
              />
            </div>
            <BorderLinearProgress
              color="primary"
              variant="determinate"
              value={props.steps.stepper}
            />
            {props.steps.step === "1" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>1</h5>
                  </div>
                  <h2>Select your account type* :</h2>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.inputs_multi}>
                    <div className={styles.checkbox_div}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="Yes"
                        value={props.apply.affiliate_account_type_id}
                        name="affiliate_account_type_id"
                        onClick={props.handleChangeApplyData}
                      >
                        {props.affiliate &&
                          props.affiliate.map((item) => (
                            <FormControlLabel
                              value={item._id}
                              sx={{
                                backgroundColor: "#f6f6f6",
                                width: "110px",
                                borderRadius: "6px",
                                px: 1,
                              }}
                              control={<Radio />}
                              label={item.name}
                            />
                          ))}
                      </RadioGroup>
                    </div>
                  </div>
                  {props.apply.affiliate_account_type_id === "66307a1f62041dbbcad0609c" && ( 
                    <>
                  <div >
                  <div >
                <h2 className={styles.main_heading_text}> Enter your company name*</h2>
                </div>
              </div>
             
              <div className={styles.inputs_company} >
                <TextField
                  InputProps={{ style: { fontSize: 25 } }}
                  sx={{ lineHeight: "34px", fontSize: "25px" }}
                  variant="standard"
                  className={styles.input_text}
                  fullWidth
                  name="company_name"
                  value={props.apply.company_name}
                  focused
                  placeholder="Enter your company name"
                  type="text"
                  onChange={props.handleChangeApplyData}
                />
               
              </div>
              </>
                  )}            
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => {
                        const { affiliate_account_type_id, company_name } = props.apply;
                        if (!affiliate_account_type_id?.length) {
                          Errornotify("Please select your account type");
                          return;
                        }
                  
                        if (
                          affiliate_account_type_id === "66307a1f62041dbbcad0609c" &&
                          !company_name?.trim()
                        ) {
                          Errornotify("Company name is required");
                          return;
                        }
                  
                        props.handleSteps();
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
               
               
              </div>
            
            ) : props.steps.step === "2" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>2</h5>
                  </div>
                  <h2> Enter your first name*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="first_name"
                    color={
                      props.apply.first_name.trim()
                        ? /^[A-Za-z]+$/.test(props.apply.first_name)
                          ? "success"
                          : "error"
                        : ""
                    }
                    error={
                      props.apply.first_name.trim() &&
                      !/^[A-Za-z]+$/.test(props.apply.first_name)
                    }
                    focused
                    value={props.apply.first_name}
                    placeholder="Enter your first name"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("1")}
                     
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.first_name?.length) {
                         
                          const onlyAlphabets = /^[A-Za-z]+$/.test(
                            props.apply.first_name
                          );

                          if (onlyAlphabets) {
                            props.handleSteps();
                          } else {
                            Errornotify("only alphabets allowed");
                          }
                        } else {
                          Errornotify("Please enter your first name!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "3" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>3</h5>
                  </div>
                  <h2> Enter your last name*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="last_name"
                    color={
                      props.apply.last_name.trim()
                        ? /^[A-Za-z]+$/.test(props.apply.last_name)
                          ? "success"
                          : "error"
                        : ""
                    }
                    error={
                      props.apply.last_name.trim() &&
                      !/^[A-Za-z]+$/.test(props.apply.last_name)
                    }
                    focused
                    value={props.apply.last_name}
                    placeholder="Enter your last name"
                    type="text"
                    onChange={props.handleChangeApplyData}
                  />
                </div>
                <div className={styles.btn_div_new}>
                  <Button
                    onClick={() => props.handleStepsBack("2")}
                    // onClick={props.handleSteps}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => {
                      if (props.apply?.last_name?.length) {
                        // Check if the first name contains only alphabets
                        const onlyAlphabets = /^[A-Za-z]+$/.test(
                          props.apply.last_name
                        );

                        if (onlyAlphabets) {
                          props.handleSteps();
                        } else {
                          Errornotify("only alphabets allowed");
                        }
                      } else {
                        Errornotify("Please enter your last name!");
                      }
                    }}
                    variant="contained"
                    sx={{ width: "126px", height: "50px", my: 2 }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            )  : props.steps.step === "4" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>4</h5>
                  </div>
                  <h2>Enter your email address*</h2>
                </div>
                <div className={styles.inputs}>
                  <TextField
                    InputProps={{ style: { fontSize: 25 } }}
                    sx={{ lineHeight: "34px", fontSize: "25px" }}
                    variant="standard"
                    className={styles.input_text}
                    fullWidth
                    name="email"
                    focused
                    value={props.apply.email}
                    placeholder="Enter your email address"
                    type="email"
                    onChange={props.handleChangeApplyData}
                    error={
                      props.apply.email.trim() &&
                      !emailRegex.test(props.apply.email)
                    }
                    color={
                      props.apply.email.trim()
                        ? emailRegex.test(props.apply.email)
                          ? "success"
                          : "error"
                        : ""
                    }
                  />
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("3")}
                     
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        const email = props.apply.email?.trim();

                        if (!email?.length) {
                          Errornotify("Please enter your email address");
                          return;
                        }
                      
                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(email)) {
                          Errornotify("Please enter a valid email address");
                          return;
                        }
                      
                        if (!isEmailValid) {
                          Errornotify(email_errors.email || "Invalid email address. Please correct it.");
                          return;
                        }
                      
                        props.handleSteps(); 
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "5" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>5</h5>
                  </div>
                  <h2>Enter your country of residence*</h2>
                </div>
                <div className={styles.inputs}>
                
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    name="country"
                    value={props.apply.country}
                    onChange={props.handleChangeApplyData}
                    // variant=""
                    fullWidth
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {country.map((c) => (
                      <MenuItem value={c.label}>{c.label}</MenuItem>
                    ))}
                  </Select>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("4")}
                   
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.country?.length) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please select your country!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "6" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>6</h5>
                  </div>
                  <h2> Enter your preferred language*</h2>
                </div>
                <div className={styles.inputs}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    name="language"
                    value={props.apply.language}
                    onChange={props.handleChangeApplyData}
                    // variant=""
                    fullWidth
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {languages.map((c) => (
                      <MenuItem value={c.name}>{c.name}</MenuItem>
                    ))}
                  </Select>
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("5")}
                    
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply?.language?.length) {
                          props.handleSteps();
                        } else {
                          Errornotify("Please enter your language!");
                        }
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : props.steps.step === "7" ? (
              <div className={styles.card}>
                <div className={styles.main_heading}>
                  <div className={styles.question_no_div}>
                    <h5>7</h5>
                  </div>
                  <h2>Enter your phone number*</h2>
                </div>
                <div className={styles.inputs}>
               
                  <Entermobile
                    handleChangeNumber={handleChangeNumberCurator}
                    value={props.apply.phone}
                    border="applyform"
                  />
                      {props.apply.phone && error && (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}
            >
              {error}
            </span>
          )}
                  <div className={styles.btn_div}>
                    <Button
                      onClick={() => props.handleStepsBack("6")}
                    
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Previous
                    </Button>
                    <Button
                      onClick={() => {
                        if (props.apply.phone?.length) {
                          if (
                            error.length
                          ) {
                            Errornotify("Please enter a valid phone number");
                          } else {
                            props.handleSteps();
                          }
                        } else {
                          Errornotify("Please enter your phone number");
                        }
                
                      }}
                      variant="contained"
                      sx={{ width: "126px", height: "50px", my: 2 }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              // : props.steps.step === "10" ? (
              // <div className={styles.card}>
              //   <div className={styles.main_heading}>
              //     <div className={styles.question_no_div}>
              //       <h5>10</h5>
              //     </div>
              //     <h2>Skype </h2>
              //   </div>
              //   <div className={styles.inputs}>
              //     <TextField
              //       InputProps={{ style: { fontSize: 25 } }}
              //       sx={{ lineHeight: "34px", fontSize: "25px" }}
              //       variant="standard"
              //       className={styles.input_text}
              //       fullWidth
              //       name="skype_id"
              //       value={props.apply.skype_id}
              //       placeholder="Enter ID"
              //       type="text"
              //       onChange={props.handleChangeApplyData}
              //     />
              //     <div className={styles.btn_div}>
              //       <Button
              //         onClick={() => props.handleStepsBack("9")}
              //         // onClick={props.handleSteps}
              //         variant="contained"
              //         sx={{ width: "126px", height: "50px", my: 2 }}
              //       >
              //         Previous
              //       </Button>
              //       <Button
              //         onClick={() => {
              //           if (props.apply?.skype_id?.length) {
              //             props.handleSteps()
              //           } else {
              //             Errornotify("Please enter your skype ID!")
              //           }
              //         }}
              //         variant="contained"
              //         sx={{ width: "126px", height: "50px", my: 2 }}
              //       >
              //         Next
              //       </Button>
              //     </div>
              //   </div>
              // </div>

              // )
              ""
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
