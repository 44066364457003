import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import ProductSidebar from "./ProductSidebar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import styles from "./GalleryDetails.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import homeImage from "../../../constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
} from "../../../State/api/userApi";
import serverimage from "../../../serverConstant";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { setLoader } from "../../../State/Features/common/loaderSlice";
import api from "../../../State/api/Api";
import { useState } from "react";
import CircularLoader from "../../../common/loader/Loader";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArtistSidebar from "./ArtistSidebar";

function GalleryDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otherartistCard = [
    {
      id: 1,
      artImg: "/img/Rectangle 5253.png",
      artistImg: "/img/Ellipse 259.png",
    },
    {
      id: 2,
      artImg: "/img/Rectangle 58.png",
      artistImg: "/img/Ellipse 28.png",
    },
    {
      id: 3,
      artImg: "/img/Rectangle 62.png",
      artistImg: "/img/Ellipse 30.png",
    },
    {
      id: 4,
      artImg: "/img/Rectangle 66.png",
      artistImg: "/img/Ellipse 259.png",
    },
  ];
  const cardDetailsArt = [
    {
      id: 1,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 2,
      imgUrl: "/img/Rectangle 369.png",
    },
    {
      id: 3,
      imgUrl: "/img/Rectangle 370.png",
    },
    {
      id: 4,
      imgUrl: "/img/Rectangle 5199.png",
    },
    {
      id: 5,
      imgUrl: "/img/Rectangle 5197.png",
    },
    {
      id: 6,
      imgUrl: "/img/Rectangle 5200.png",
    },
    {
      id: 7,
      imgUrl: "/img/Rectangle 368.png",
    },
    {
      id: 8,
      imgUrl: "/img/Rectangle 5202.png",
    },
    {
      id: 9,
      imgUrl: "/img/Rectangle 5203.png",
    },
    {
      id: 10,
      imgUrl: "/img/Rectangle 5206.png",
    },
    {
      id: 11,
      imgUrl: "/img/Rectangle 5207.png",
    },
    {
      id: 12,
      imgUrl: "/img/Rectangle 5208.png",
    },
  ];
  const cardDetails = [
    {
      id: 1,
      artImg: "/img/Rectangle 22.png",
      artistImg: "/img/Ellipse 10.png",
      profession: "Photographer",
    },
    {
      id: 2,
      artImg: "/img/Rectangle 26.png",
      artistImg: "/img/Ellipse 12.png",
      profession: "Sculptor",
    },
    {
      id: 3,
      artImg: "/img/Rectangle 30.png",
      artistImg: "/img/Ellipse 14.png",
      profession: "Painter",
    },
    {
      id: 4,
      artImg: "/img/Rectangle 34.png",
      artistImg: "/img/Ellipse 16.png",
      profession: "Photographer",
    },
    {
      id: 5,
      artImg: "/img/Rectangle 38.png",
      artistImg: "/img/Ellipse 18.png",
      profession: "Sculptor",
    },
    {
      id: 6,
      artImg: "/img/Rectangle 42.png",
      artistImg: "/img/Ellipse 20.png",
      profession: "Painter",
    },
    {
      id: 7,
      artImg: "/img/Rectangle 46.png",
      artistImg: "/img/Ellipse 22.png",
      profession: "Photographer",
    },
    {
      id: 8,
      artImg: "/img/Rectangle 50.png",
      artistImg: "/img/Ellipse 24.png",
      profession: "Sculptor",
    },
    {
      id: 9,
      artImg: "/img/Rectangle 54.png",
      artistImg: "/img/Ellipse 26.png",
      profession: "Painter",
    },
    {
      id: 10,
      artImg: "/img/Rectangle 58.png",
      artistImg: "/img/Ellipse 28.png",
      profession: "Photographer",
    },
    {
      id: 11,
      artImg: "/img/Rectangle 62.png",
      artistImg: "/img/Ellipse 30.png",
      profession: "Sculptor",
    },
    {
      id: 12,
      artImg: "/img/Rectangle 66.png",
      artistImg: "/img/Ellipse 32.png",
      profession: "Painter",
    },
  ];
  const artistdata = useSelector((state) => state.userartistData);
  const [allitems, setAllitems] = useState();
  const loader = useSelector((state) => state.loader);
  const filter = useSelector((state) => state.filteration);
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const userartists = useSelector((state) => state.userartistData);
  const allcategory = useSelector((state) => state.allcategoryData);

  const navigateToArtistDetail = (id) => {
    navigate(`/user/artist/${id}`);
  };
  const handleFetchCategories = async function () {
    dispatch(setLoader(true));
    try {
      const result = await api.applicationApi(`api/user/allitems`, "GET");
      if (result && result.status === 200) {
        setAllitems(result.data.artworks);
        dispatch(setLoader(false));
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const filters =
    allitems && allitems.map((val) => val.style_id.map((c) => c._id));

  const navigateToArtworkDetail = (ids) => {
    navigate(`/user/productdetails/${ids}`);
  };
  useEffect(() => {
    handleFetchCategories();
    dispatch(fetchUserArtist());
    dispatch(fetchProductType());
    dispatch(fetchArtistTypes());
    dispatch(fetchCategoryData({}));
  }, []);
  return (
    <div className={styles.artistdetails}>
      <div className={styles.headerImage}>
        <img
          className={styles.bannerbigimg} alt=""
          src={ "/img/Rectangle 5217.png"}
        />
        <div className={styles.artistImgDiv}>
          <img
            className={styles.artistImg}
            src={ "/img/Ellipse gallery.png"} alt=""
          />
        </div>
      </div>
      <div className={styles.artistInfo}>
        <Typography
          ml={-4}
          fontFamily="marcellus"
          fontSize="2rem"
          textAlign="center"
        >
          Art Of Gallery
        </Typography>
        <div className={styles.artistStats}>
          <div className={styles.arts}>
            <Typography fontSize="2rem">41</Typography>
            <Typography>Artist</Typography>
          </div>
          <div className={styles.verticalLine3}></div>
          <div className={styles.arts}>
            <Typography fontSize="2rem">12</Typography>
            <Typography ml={1}>Arts</Typography>
          </div>
          <div className={styles.verticalLine3}></div>
          <div className={styles.followers}>
            <div className={styles.arts}>
              <Typography fontSize="2rem">2.5 M</Typography>
              <Typography ml={1}>Followers</Typography>
            </div>
          </div>
          <div className={styles.verticalLine3}></div>
          <div className={styles.sold}>
            <div className={styles.arts}>
              <Typography fontSize="2rem">9.6K</Typography>
              <Typography ml={1}>Sold</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.artistShare}>
        <Button
          sx={{
            color: "white",
            backgroundColor: "black",
            paddingLeft: "2%",
            paddingRight: "2%",
            "&:hover": {
              background: "black",
            },
          }}
          startIcon={<PersonAddAlt1OutlinedIcon />}
        >
          Follow this gallery
        </Button>
        <div className={styles.sharelogo}>
          <img
            className={styles.shareimage}
            src={ "/img/share 1 (traced).png"}
          />
        </div>
      </div>
      <div className={styles.artist__description}>
        <Typography fontWeight="600" fontSize="0.95rem">
          About Gallery
        </Typography>
        <p>
          <Typography fontSize="0.9rem" mt={-1}>
            Oil Painting Artist. Born in December. Lives in Istanbul, Turkey.
            www.agokhanyasar.com I got basic course of painting art from an
            institution of Istanbul Municipality then continued working at my
            home office. I started my online exhibition on several art
            platforms. I got a few awards from online painting art contests of
            which the certifications are present. And in this year October , i
            am a participant artist of Florence Biennale in Italy, exhibiting 2
            of my artworks named ''Hello'' and ''The Mountain''
          </Typography>
        </p>
        <Typography fontWeight="600" fontSize="0.95rem">
          Professional Details
        </Typography>
        <p>
          <Typography fontSize="0.9rem" mt={-1}>
            As an artist currently i have realistic approach to formation,
            perspectives and impressionist to colors, their harmony and
            combination. In my artworks i try to catch feelings impressively
            from today and past. Especially themes of love, compassion, beauty,
            vintage and different cultures.
          </Typography>
        </p>
      </div>
      <div className={styles.headerMain}>
        <Typography fontSize="1.8rem" fontFamily="marcellus">
          Our Artist
        </Typography>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          sx={{
            width: "30%",
          }}
        />
      </div>
      <div className={styles.artistlist__main}>
        <ArtistSidebar filter={filter} />
        <div className={styles.artist__grid}>
          <Container style={{ paddingRight: "5%" }}>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 5 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {artistdata.data &&
                artistdata.data
                  .filter(
                    (val, i) =>
                      (filter.category.length === 0
                        ? val
                        : filter.category.includes(val.category_id?._id)) &&
                      (filter.country.length === 0
                        ? val
                        : filter.country.includes(val.country)) &&
                      (filter.artist_type.length === 0
                        ? val
                        : filter.artist_type.includes(
                            val.artist_type_id && val.artist_type_id._id
                          ))
                  )
                  .map((item) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={styles.artist__card}>
                        <div className={styles.artist__top}>
                          <img
                            className={styles.actualart}
                            src={
                              item.backgroundImage
                                ?  item.backgroundImage
                                : "/img/artist_bg_alt.jpeg"
                            }
                            alt="img"
                          />
                        </div>
                        <div className={styles.artistphoto__div}>
                          <img
                            className={styles.artist__photo}
                            src={
                              item.image
                                ?  item.image
                                : "/img/artist_profile.png"
                            }
                            height="50px"
                            alt="img"
                          />
                        </div>
                        <div className={styles.artist__namestars}>
                          <Typography ml={1} fontWeight="600">
                            {item.artist_name}
                          </Typography>
                          <div className={styles.rating}>
                            <img
                              className={styles.starRating}
                              src={ "/img/Gold Star.png"}
                              alt="star"
                            />
                            <Typography ml={0.5} mr={1}>
                              3.5
                            </Typography>
                          </div>
                        </div>
                        <div className={styles.artist__location}>
                          <Typography fontSize="0.85rem" ml={0.75}>
                            {item.country ? item.country : ""}
                          </Typography>
                          <Typography fontSize="0.85rem" mr={0.75}>
                            {item.portfolio ? item.portfolio : ""}
                          </Typography>
                        </div>

                        <Button
                          sx={{
                            textTransform: "none",
                            color: "black",
                            backgroundColor: "transparent",
                            alignItems: "center",
                            width: "95%",
                            marginLeft: "2.5%",
                            marginTop: "2%",
                            marginBottom: "4%",
                            border: "1px solid black",
                            "&:hover": {
                              backgroundColor: "black",
                              color: "white",
                            },
                          }}
                          onClick={() => navigateToArtistDetail(item._id)}
                        >
                          Explore
                        </Button>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </Container>
        </div>
      </div>
      <div className={styles.headerMain}>
        <Typography fontSize="1.8rem" fontFamily="marcellus">
          All Artworks
        </Typography>
        <TextField
          id="input-with-icon-textfield"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          size="small"
          sx={{
            width: "30%",
          }}
        />
      </div>
      <div className={styles.artwork__div}>
        <ProductSidebar
          value_height={value_height}
          value_width={value_width}
          priceRange={priceRange}
          setValueHeight={setValueHeight}
          filter={filter}
          // style={style}
          setFilter={filter}
          setPriceRange={setPriceRange}
          setValueWidth={setValueWidth}
          producttypeData={productType.data}
          artisttypeData={artistTypes.data}
          allartistData={userartists.data}
          allcategoryData={allcategory.data && allcategory.data.allcategories}
        />
        <div className={styles.ProductCards}>
          <Container>
            <Grid
              container
              columnSpacing={{ xs: 2, md: 5 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {loader.loader ? (
                <div className={styles.loader}>
                  <CircularLoader />
                </div>
              ) : allitems &&
                allitems.filter(
                  (val, i) =>
                    val.is_accepted === "Approved" &&
                    val.sale.price >= filter.price[0] &&
                    val.sale.price <= filter.price[1] &&
                    val.height >= value_height[0] &&
                    val.height <= value_height[1] &&
                    val.width >= value_width[0] &&
                    val.width <= value_width[1] &&
                    (filter.artist.length === 0
                      ? val
                      : filter.artist.includes(
                          val.artist_id && val.artist_id._id
                        )) &&
                    (filter.category.length === 0
                      ? val
                      : filter.category.includes(val.category_id)) &&
                    (filter.style.length === 0
                      ? val
                      : filter.style.some(
                          (element) => filters && filters[i].includes(element)
                        )) &&
                    (filter.country.length === 0
                      ? val
                      : filter.country.includes(val.country_id)) &&
                    (filter.type.length === 0
                      ? val
                      : filter.type.includes(val.item_type_id)) &&
                    (filter.artist_type.length === 0
                      ? val
                      : filter.artist_type.includes(
                          val.artisttype_id && val.artisttype_id._id
                        )) &&
                    (filter.orientation.length === 0
                      ? val
                      : filter.orientation.includes(
                          val.orientation_id && val.orientation_id
                        )) &&
                    (filter.color.length === 0
                      ? val
                      : filter.color.includes(val.color_id && val.color_id)) &&
                    (filter.search === ""
                      ? val
                      : val.name
                          .toLowerCase()
                          .includes(filter.search.toLowerCase()))
                ).length === 0 ? (
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  item
                  sx={{ mb: 2, textAlign: "center", mt: 2 }}
                >
                  No Art Found
                </Grid>
              ) : (
                allitems &&
                allitems
                  .filter(
                    (val, i) =>
                      val.is_accepted === "Approved" &&
                      val.sale.price >= filter.price[0] &&
                      val.sale.price <= filter.price[1] &&
                      val.height >= value_height[0] &&
                      val.height <= value_height[1] &&
                      val.width >= value_width[0] &&
                      val.width <= value_width[1] &&
                      (filter.artist.length === 0
                        ? val
                        : filter.artist.includes(
                            val.artist_id && val.artist_id._id
                          )) &&
                      (filter.category.length === 0
                        ? val
                        : filter.category.includes(val.category_id)) &&
                      (filter.style.length === 0
                        ? value_width
                        : filter.style.some(
                            (element) => filters && filters[i].includes(element)
                          )) &&
                      (filter.country.length === 0
                        ? val
                        : filter.country.includes(val.country_id)) &&
                      (filter.type.length === 0
                        ? val
                        : filter.type.includes(val.item_type_id)) &&
                      (filter.artist_type.length === 0
                        ? val
                        : filter.artist_type.includes(
                            val.artisttype_id && val.artisttype_id._id
                          )) &&
                      (filter.orientation.length === 0
                        ? val
                        : filter.orientation.includes(
                            val.orientation_id && val.orientation_id
                          )) &&
                      (filter.color.length === 0
                        ? val
                        : filter.color.includes(
                            val.color_id && val.color_id
                          )) &&
                      (filter.search === ""
                        ? val
                        : val.name
                            .toLowerCase()
                            .includes(filter.search.toLowerCase()))
                  )

                  .map((item) => (
                    <Grid
                      xs={12}
                      sm={6}
                      md={4}
                      key={item._id}
                      item
                      sx={{ mb: 2 }}
                    >
                      <div
                        className={styles.card__main}
                        onClick={() => navigateToArtworkDetail(item._id)}
                      >
                        <div className={styles.card__top}>
                          <img
                            className={styles.cardImage}
                            src={ item.image}
                            alt="img"
                          />
                        </div>
                        <div className={styles.card__middle}>
                          <div className={styles.card__middleLeft}>
                            <Typography
                              fontSize="0.95rem"
                              fontWeight="700"
                              mt={1}
                            >
                              {item.name}
                            </Typography>
                            <Typography
                              fontSize="0.8rem"
                              fontWeight="700"
                              mt={1}
                              color="#ccc"
                            >
                              {item.artist_id && item.artist_id.first_name}{" "}
                              {item.artist_id && item.artist_id.last_name}
                            </Typography>
                          </div>
                          <div className={styles.card__middleRight}>
                            <FavoriteBorderIcon fontSize="small" />
                            <Typography fontWeight="400" ml={0.5}>
                              522
                            </Typography>
                          </div>
                        </div>
                        <hr />
                        <div className={styles.card__bottom}>
                          <div className={styles.buy__text}>
                            <Button>
                              <Typography
                                fontWeight="600"
                                color="#ccc"
                                fontSize="0.65rem"
                              >
                                Buy
                              </Typography>
                              <Typography
                                color="black"
                                fontWeight="500"
                                fontSize="0.85rem"
                                mt={4}
                                ml={-3}
                              >
                                <b>$ {item.sale && item.sale.price}</b>
                              </Typography>
                            </Button>
                          </div>
                          <div className={styles.verticalLine}></div>
                          <div className={styles.rent__text}>
                            <Button>
                              <Typography
                                fontWeight="600"
                                color="#ccc"
                                fontSize="0.65rem"
                              >
                                Rent/Month
                              </Typography>
                              <Typography
                                color="black"
                                fontWeight="500"
                                fontSize="0.85rem"
                                mt={4}
                                ml={-6.5}
                                mr={3}
                              >
                                <b>$ {item.rent && item.rent.price}</b>
                              </Typography>
                            </Button>
                          </div>
                          <div className={styles.verticalLine}></div>
                          <div className={styles.print__text}>
                            <Button>
                              <Typography
                                fontWeight="600"
                                color="#ccc"
                                fontSize="0.65rem"
                              >
                                Print
                              </Typography>
                              <Typography
                                color="black"
                                fontWeight="500"
                                fontSize="0.85rem"
                                mt={4}
                                ml={-3.75}
                              >
                                <b>$ {item.print && item.print.price}</b>
                              </Typography>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))
              )}
            </Grid>
          </Container>
        </div>
      </div>
      <div className={styles.latestartworks}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <div className={styles.latestartworksheading}>
              <img
                className={styles.designImage}
                src={ "/img/Design.png"}
              />
              <Typography fontSize="2rem" fontFamily="marcellus">
                Latest Artworks
              </Typography>
              <Typography fontSize="0.9rem" color="#636365">
                Discover the world through original <br /> paintings for sale
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={9}></Grid>
        </Grid>
      </div>
    </div>
  );
}

export default GalleryDetails;
