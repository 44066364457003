import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #3EE58F;
  color: #fff;
font-weight:bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  height: 35px;
  width: 100%;
  &:hover {
    background-color:   #3EE58F;  /* Slightly darker Stripe Blue for Hover State */
  }
`;

const CustomTabby = ({ label, onClick }) => (
  <StyledButton >Tabby</StyledButton>
);

export default CustomTabby;