import React, { useState } from 'react'
import Commonsidebar from '../../components/Pages/List/Common/sideBar/Commonsidebar'
import Artistgallery from '../components/popups/artistGallery/Artistgallery';
import Webpopup from '../components/popups/galleryWeb/Webpopup'

const sidebarRoutes = [
    // {
    //     name: 'GALLERY PROFILE',
    //     icon: 'fa fa-dashboard',
    //     path: '/galleryprofile',
    //     hasSubmenu: false,
    // },
    {
        name: 'PROFILE',
        icon: 'fa fa-truck',
        path: '/profile',
        hasSubmenu: false,
    },
   
    {
        name: 'ARTS',
        icon: 'fa fa-users',
        path: '/arts',
        hasSubmenu: false,
    },
    {
        name: 'UPLOAD ARTS',
        icon: 'fa fa-cube',
        path: '/upload/stepfirst',
        hasSubmenu: false,
    },
    {
        name: 'EXHIBITIONS',
        icon: 'fa fa-th-large',
        path: '/exhibitions',
        hasSubmenu: false,
    },
    {
        name: 'AUCTIONS',
        icon: 'fa fa-cube',
        path: '/auctions',
        hasSubmenu: false,
    },
    {
        name: 'ORDERS',
        icon: 'fa fa-cube',
        path: '/orders',
        hasSubmenu: false,
    },
    {
        name: 'SUBSCRIPTIONS',
        icon: 'fa fa-cube',
        path: '/subscriptions',
        hasSubmenu: false,
    },
    {
        name: 'EARNINGS',
        icon: 'fa fa-cube',
        path: '/earnings',
        hasSubmenu: false,
    },
    {
        name: "BUYER'S OFFERS",
        icon: 'fa fa-cube',
        path: '/buyeroffer',
        hasSubmenu: false,
    },
    {
        name: "ADMIN'S OFFER",
        icon: 'fa fa-cube',
        path: '/adminoffer',
        hasSubmenu: false,
    },
    {
        name: 'RECREATE',
        icon: 'fa fa-cube',
        path: '/recreate',
        hasSubmenu: false,
    },
    {
        name: 'QUOTE REQUEST',
        icon: 'fa fa-cube',
        path: '/quoterequest',
        hasSubmenu: false,
    },
    {
        name: 'INQUIRY',
        icon: 'fa fa-cube',
        path: '/inquiry',
        hasSubmenu: false,
    },
    {
        name: 'DISPUTE MANAGEMENT',
        icon: 'fa fa-cube',
        path: '/dispute',
        hasSubmenu: false,
    },
    {
        name: 'NOTIFICATIONS',
        icon: 'fa fa-cube',
        path: '/notification',
        hasSubmenu: false,
    },
    {
        name: 'ART SMILEY NEWS',
        icon: 'fa fa-cube',
        path: '/news',
        hasSubmenu: false,
    },
]
export default function Gallerysidebar() {
    const [open, setOpen] = useState(true);

    return (
        <>
            {/* <Webpopup open={open} setOpen={setOpen} /> */}
            {/* <Artistgallery open={open} setOpen={setOpen} /> */}
            <Commonsidebar sidebarRoutes={sidebarRoutes} />
        </>
    )
}
