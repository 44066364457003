import React, { useEffect, useState } from "react"
import styles from "./Login.module.css"
import Radio from "@mui/material/Radio"
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { Button, TextField } from "@mui/material"
import { Box } from "@mui/system"
import PropTypes from "prop-types"

import { styled } from "@mui/material/styles"
import { useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchUsertypes, loginApi } from "../../State/api/commonapi"
import CircularLoader from "../../common/loader/Loader"
import "react-toastify/dist/ReactToastify.css"
import homeImage from "../../constant"
import { InputAdornment } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { IconButton } from "@mui/material"
import { ToastContainer, toast } from "react-toastify"
import {
  AlertsSuccess,
  Errornotify,
  Successnotify,
} from "../../common/alert/Alert"
import api from "../../State/api/Api"

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}))

const usertype = [
  {
    id: 1,
    name: "Superadmin",
    value: "superadmin",
  },
]
export default function LoginSuperAdmin() {
  const { state } = useLocation()
  const type = state?.type
  const [signin, setSignin] = useState(false)
  const [user, setUser] = useState({
    password: "",
    email: "",
    role: "superadmin",
    showPassword: false,
  })
  const [email_verify, setEmail_verify] = useState(null)
  const handleChangeRadio = (e) => {
    const val = e.target.value
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const searchParams = useLocation()

  const loader = useSelector((state) => state.loader)
  useEffect(() => {
    dispatch(fetchUsertypes())
  }, [])

  const handleSignupbtn = () => {
    navigate("/artsmiley/signup", { state: { type } })
  }
  function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup()
    let checked = false
    let disabled = false
    if (radioGroup) {
      checked = radioGroup.value === props.value
      disabled = props.value !== props.userRole // Disable if the value doesn't match the user's role
    }
    return (
      <StyledFormControlLabel
        checked={checked}
        disabled={disabled}
        {...props}
      />
    )
  }

  MyFormControlLabel.propTypes = {
    value: PropTypes.any,
  }
  const handleChange = (e) => {
    let name = e.target.name
    let val = e.target.value.trim()

    setUser({ ...user, [name]: val })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (user.password.length < 8) {
      Errornotify("Password must have minimum 8 Characters")
    } else {
      dispatch(loginApi(user, navigate))
    }
  }

  const a =
    searchParams.search &&
    searchParams.search
      .split("?")[1]
      .split("&")[0]
      .split("&")
      .map((param) => param.split("="))
  const b =
    searchParams.search &&
    searchParams.search
      .split("?")[1]
      .split("&")[2]
      .split("&")
      .map((param) => param.split("="))
  const c =
    searchParams.search &&
    searchParams.search
      .split("?")[1]
      .split("&")[1]
      .split("&")
      .map((param) => param.split("="))
  useEffect(() => {
    if (searchParams.search.length > 0) {
      setEmail_verify({ email: a[0][1], key: c[0][1], role: b[0][1] })
      setUser({ email: a[0][1], key: c[0][1], role: b[0][1] })
    }
  }, [])

  const handleVeryfied = async function () {
    try {
      const result = await api.applicationApi(
        `api/user/userVerification`,
        "POST",
        email_verify
      )
      if (result && result.status === 200) {
        Successnotify(result.data.message)
      } else if (result.status === 400) {
        Errornotify(result.data.message)
      } else {
      }
    } catch (err) {
    }
  }

  useEffect(() => {
    email_verify && handleVeryfied()
  }, [email_verify])
  return (
    <>
      <AlertsSuccess />
      <div className={styles.login_main}>
        <div className={styles.left_login_main}>
          <img
            src={"/img/Login Image.png"}
            height="60%"
            width="50%"
            className={styles.img_login}
            alt="img"
          />
        </div>
        <div className={styles.right_main_l}>
          <div className={styles.login_center}>
            <div>
              <img
                src={"/img/updated_logo.png"}
                className={styles.img_art}
                onClick={() => navigate("/")}
                alt="img"
              />
              <h3 className={styles.heading_art_s}> Welcome To Art Smiley</h3>
              {/* <h2 className='heading_art'> Hello, Emily Cooper</h2> */}
            </div>
            {/* {loader.loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularLoader className='loaders' />
                            </Box>
                            : */}
            <FormControl sx={{ my: 2, width: { sx: "100%", sm: "120%" } }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="role"
                onChange={handleChange}
                sx={{
                  "&, &.Mui-checked": {
                    color: " #D0D0D0",
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: 15,
                  },
                }}
                value={user.role}
              >
                {usertype &&
                  usertype.map((val) => (
                    <MyFormControlLabel
                      name="role"
                      value={val.value}
                      userRole={user.role}
                      id={val.id}
                      control={<Radio />}
                      sx={{ fontSize: "17px" }}
                      label={
                        <span style={{ fontSize: "17px" }}>{val.name}</span>
                      }
                    />
                  ))}
                {/* <MyFormControlLabel value="curator" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Curator</span>} />
                            <MyFormControlLabel value="affiliate" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Affiliate</span>} />
                            <MyFormControlLabel value="artist" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Artist</span>} />
                            <MyFormControlLabel value="gallery" control={<Radio />} label={<span style={{ fontSize: '12px' }}>Gallery</span>} /> */}
              </RadioGroup>
            </FormControl>
            {/* } */}
            {/* <div>
           
              <div>
                <p className={styles.para_note}>
                  Note a member? <span style={{color:"#1C1C1C",fontSize:"16px"}}>Sign Up</span>
                </p>
              </div>
            </div> */}
            <form className={styles.form_login} onSubmit={handleSubmit}>
              <span className={styles.span_inputs}>Email</span>
              <br />
              <TextField
                onChange={handleChange}
                name="email"
                type="email"
                value={user.email}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                id="outlined-basic"
                placeholder="Enter your email"
                className=""
                fullWidth
                variant="outlined"
              />
              <br />
              <span className={styles.span_inputs}>Password</span>
              <br />
              <TextField
                onChange={handleChange}
                name="password"
                value={user.password}
                sx={{
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.7px solid #D0D0D0",
                  },
                  "& .MuiInputBase-root": {
                    height: "52px",
                    borderRadius: "8px",
                  },
                }}
                inputProps={{
                  style: {
                    height: 1,
                    fontSize: "15px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUser({ ...user, showPassword: !user.showPassword })
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {user.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                type={user.showPassword ? "text" : "password"}
                placeholder="Enter your password"
                fullWidth
                variant="outlined"
              />
              <br />
              <div className={styles.forgot}>
                <div className={styles.policy}>
                  <input
                    type="checkbox"
                    className={styles.checks}
                    onClick={() => setSignin(!signin)}
                  />
                  <span className={styles.para}>keep me signed in</span>
                  <br />
                </div>
                <span
                  className={styles.span_inputs}
                  onClick={() => navigate("/artsmiley/forgot")}
                >
                  Forgot password?
                </span>
              </div>
              <Box className={styles.login_buttons}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{
                    borderRadius: 2,
                    height: "55px",
                    my: 2,
                    lineHeight: "26px",
                    fontSize: "20px",
                  }}
                >
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </div>
      {/* <form className={styles.form_login} onSubmit={handleSubmit}>
              <span className={styles.span_inputs}>Email</span>
              <br />
              <TextField
                onChange={handleChange}
                name="email"
                type="email"
                value={user.email}
                sx={{
                  height: 40,
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": { border: "non" },
                  "& .MuiInputBase-root": {
                    // height: 40
                  },
                  mb: 0.5,
                }}
                inputProps={{
                  style: {
                    height: 10,
                  },
                }}
                id="outlined-basic"
                placeholder="Enter your email"
                className=""
                fullWidth
                variant="outlined"
              />
              <br />
              <span className={styles.span_inputs}>Password</span>
              <br />
              <TextField
                onChange={handleChange}
                name="password"
                value={user.password}
                sx={{
                  height: 40,
                  color: "#636365",
                  "& .MuiOutlinedInput-notchedOutline": { border: "non" },
                  "& .MuiInputBase-root": {
                    // height: 40
                  },
                  mb: 0.5,
                }}
                inputProps={{
                  style: {
                    height: 10,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setUser({ ...user, showPassword: !user.showPassword })
                        }
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {user.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="outlined-basic"
                type={user.showPassword ? "text" : "password"}
                placeholder="Enter your password"
                fullWidth
                variant="outlined"
              />
              <br />
              <div className={styles.forgot}>
                <div>
                  <input
                    type="checkbox"
                    className={styles.checks}
                    onClick={() => setSignin(!signin)}
                  />
                  <span className={styles.para}>keep me sign in</span>
                  <br />
                </div>
                <span className={styles.span_inputs}>Forgot password?</span>
              </div>
              <Box className={styles.login_buttons}>
                <Button variant="contained" fullWidth type="submit">
                  {loader.btnloader ? (
                    <CircularLoader color="white" type="small" />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Box>
            </form> */}
    </>
  )
}
