import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  deleteOffer,
  fetchOfferData,
  handleAddOffer,
  editOffer,
} from "../../../../State/api/superadminApi"
import { setOfferdata } from "../../../../State/Features/superadmin/offerSlice"
import Pagination from "../Pagination/Pagination"
import Addimgpopup from "../Popupdelete/addSubscription/addwithImage/Addimgpopup"
import Fourcolimg from "../Table/Fourcolumn/Fourcolimg"
import Tableheader from "../Table/Tableheader/Tableheader"
import styles from "./Offer.module.css"

const head = [
  {
    id: 1,
    label: "Image",
  },
  {
    id: 2,
    label: "Offer Name",
  },
  {
    id: 3,
    label: "Description",
  },
  {
    id: 4,
    label: "Discount",
  },
]
const datas = [
  {
    id: 1,
    src: "/img/image 1",
    name: "Month end sale",
    follower: "Month end sale jkrgfk kj ",
    like: "50%",
  },
  {
    id: 2,
    src: "/img/image 2",
    name: "Month end sale",
    follower: "Month end sale jkrgfk kj ",
    like: "50%",
  },
  {
    id: 3,
    src: "/img/image 3",
    name: "Month end sale",
    follower: "Month end sale jkrgfk kj ",
    like: "50%",
  },
  {
    id: 4,
    src: "/img/image 4",
    name: "Month end sale",
    follower: "Month end sale jkrgfk kj ",
    like: "50%",
  },
]
const heading = "Create Offer"

const sortdata = [
  { label: "By Discount ↓", value: "low" },
  { label: "By Discount ↑", value: "high" },
]
const filterdata = [
  { label: "Percentage", value: "percentage" },
  { label: "Price", value: "price" },
  //  { label: "Rejected", value: "rejected" },
]
export default function Offer() {
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [data, setData] = useState({ offset: 1 })
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [editdataOffer, setEditdataOffer] = useState()
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })
  const offerData = useSelector((state) => state.offerdata.data)

  const dispatch = useDispatch()

  const fetchData = () => {
    dispatch(fetchOfferData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  const handleAddoffer = (offer) => {
    dispatch(handleAddOffer(offer, setOpen, fetchData))
  }
  const handleDelete = (id) => {
    dispatch(deleteOffer(id, fetchData))
  }
  const handleEditPopup = (data) => {
    setEditdataOffer(data)
    setEdit(true)
  }
  const handleEdit = (id, data) => {
    dispatch(editOffer(id, data, setEdit, fetchData))
  }

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setOfferdata({
          data:
            offerData.data &&
            [...offerData.data].sort((a, b) => {
              return a.discount - b.discount
            }),
          totalCount: offerData.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setOfferdata({
          data:
            offerData.data &&
            [...offerData.data].sort((a, b) => {
              return b.discount - a.discount
            }),
          totalCount: offerData.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      {open ? (
        <Addimgpopup
          handleAddoffer={handleAddoffer}
          heading={heading}
          poptype="offer"
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      {edit ? (
        <Addimgpopup
          open={edit}
          setOpen={setEdit}
          editdataOffer={editdataOffer}
          handleEdit={handleEdit}
          poptype="editOffer"
        />
      ) : (
        ""
      )}
      <AlertsSuccess />
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
          setButton={setButton}
        />
        <Fourcolimg
          handleDelete={handleDelete}
          activetab="offer"
          head={head}
          handleEditPopup={handleEditPopup}
          data={
            offerData &&
            offerData.data
              .filter((val) => {
                const name = val.name
                  .toLowerCase()
                  .includes(condition.search.toLowerCase())
                const description = val.description
                  .toLowerCase()
                  .includes(condition.search.toLowerCase())

                const filterMatch = (() => {
                  if (condition.filter === "percentage") {
                    return val.discountType === "Percentage" // Filter for percentage offers
                  } else if (condition.filter === "price") {
                    return val.discountType === "Price" // Filter for price offers
                  }
                  return true // If no filter is selected, include all
                })()

                return (name || description) && filterMatch
              })

              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          }
        />
        {offerData &&
          Array.isArray(offerData.data) &&
          offerData.data.length > 0 && (
            <Pagination
              totaldata={offerData && offerData.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )}
      </div>
    </div>
  )
}
