import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loader: false,
    btnloader: false
}

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        }, setBtnLoader: (state, action) => {
            state.btnloader = action.payload;
            state.loader = state.loader;
            console.log(state, action.payload);
        }
    }
});

export const { setLoader } = loaderSlice.actions;
export const { setBtnLoader } = loaderSlice.actions;

export const selectloader = (state) => state.loader;

export default loaderSlice.reducer;