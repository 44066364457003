import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const allcategorySlice = createSlice({
    name: "allcategorydata",
    initialState,
    reducers: {
        setAllcategory: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setAllcategory } = allcategorySlice.actions;

export default allcategorySlice.reducer;
