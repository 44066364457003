import { Button, FormControl, MenuItem, Select } from "@mui/material"
import styles from "./Tableheader.module.css"
import React, { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
export default function Tableheader(props) {
  console.log(props, "props-tableheader--")
  // const [search, setSearch] = useState("");
  // const [age, setAge] = React.useState("");

  const location = useLocation()
  const navigate = useNavigate()
  // const handleOnSearch = (e) => {
  //   if (location.pathname === "/artist") {
  //     props.setSearch && props.setSearch(true);
  //     props.setPoptype && props.setPoptype(e.target.name); //
  //   }
  // };
  const handleAddbtn = () => {
    if (window.location.pathname === "/arts") {
      navigate("/upload/stepfirst")
    } else {
      props.setOpen && props.setOpen(true)
    }
    // navigate('/upload/stepfirst')
    // props.setPoptype("add")
    // props.handleAddnew()
  }

  return (
    <div>
      <div className={styles.buyer_header}>
        <div className={styles.input_div}>
          <input
            onChange={props.handleSearch}
            name="search"
            // onClick={handleOnSearch}
            value={props.search}
            className={styles.search_input}
            type="text"
            placeholder="Search by..."
          />
          <img
            className={styles.search_icon}
            src="/img/Search Symbol.png"
            height="20px"
            width="20px"
            alt="img"
          />
        </div>
        <div className={styles.header_action}>
          {!window.location.href.includes("print-pricing") &&
            !window.location.href.includes("reports") &&
            !window.location.href.includes("policies") &&
            props.activetab !== "artist_art_pending" &&
            props.activetab !== "artist_art_rejected" &&
            props.activetab !== "gallery_art_pending" &&
            props.activetab !== "gallery_art_rejected" && (
              <>
                <FormControl>
                  <Select
                   value={props.condition?.sorting || ""}
                    onChange={props.handleSorting && props.handleSorting}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "44px", color: "#636365" }}
                    className={styles.selects}
                    IconComponent={() => (
                      <img
                        src="/img/dropdownicon.png"
                        width="13px"
                        height="10px"
                        style={{ marginRight: "20px" }}
                        alt="img"
                      />
                    )}
                  >
                    <MenuItem value="">Sort by</MenuItem>
                    {props.sortdata &&
                      props.sortdata.map((val) => (
                        <MenuItem value={val.value}>{val.label}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {!window.location.href.includes("campaigns") &&
                  !window.location.href.includes("reports") &&
                  !window.location.href.includes("orders") && 
                  !window.location.href.includes("auctions") &&
                  (
                    <FormControl>
                      <Select
                 value={props.condition?.filter || ""}
                        onChange={props.handleFilter && props.handleFilter}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          height: "44px",
                          maxWidth: "150px",
                          color: "#636365",
                        }}
                        className={styles.selects}
                        IconComponent={() => (
                          <img
                            src="/img/dropdownicon.png"
                            width="13px"
                            height="10px"
                            style={{ marginRight: "20px" }}
                            alt="img"
                          />
                        )}
                      >
                        <MenuItem value="">Filter</MenuItem>
                        {props.filterdata &&
                          props.filterdata.map((val) => (
                            <MenuItem value={val.value}>{val.label}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
              </>
            )}
          {!window.location.href.includes("artist") &&
          !window.location.href.includes("curator") &&
          !window.location.href.includes("buyerlist") &&
          !window.location.href.includes("affiliate") &&
          !window.location.href.includes("affiliate") &&
          !window.location.href.includes("exhibitionartist") &&
          !window.location.href.includes("curator-testimonials") &&
          props.button &&
          props.button.type === "add" ? (
            <Button
              variant="contained"
              fullWidth
              onClick={handleAddbtn}
              className={styles.add_btn}
              sx={{
                width: { xs: "120", sm: "100px" },
                mt: { xs: 1, sm: 0 },
                mb: { xs: 1, sm: 0 },
                minWidth: 100,
                height: "44px",
                mr: 0,
                ml: 0,
                // fontFamily: "Poppins",
                // fontStyle: "normal",
                // fontWeight: "600",
                // fontSize: "14px",
                // lineHeight: "21px",
              }}
            >
              {props.button && props.button.text}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
