import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: 'ongoing',
    maintab : ''
};

const activetabSlice = createSlice({
    name: "status",
    initialState,
    reducers: {
        setActiveStatus(state, action) {
           state.status = action.payload.status
           state.maintab = action.payload.maintab
        },
    },
});

export const { setActiveStatus } = activetabSlice.actions;

export default activetabSlice.reducer;
