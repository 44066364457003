import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

export default function CircularLoader(props) {
  if (props && props.type === "small") {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress color="secondary" size="25px" />
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }
}
