import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AlertsSuccess } from "../../../../common/alert/Alert"
import {
  deleteAuction,
  fetchAuctionData,
  handleAcceptRejectAuction,
  handleAddAuction,
} from "../../../../State/api/superadminApi"
import { setAuctionData } from "../../../../State/Features/superadmin/auctionSlice"
import Pagination from "../Pagination/Pagination"
import Add from "../Popupdelete/addPopup/Addpopup"
import Tablesfivecol from "../Table/Fivecolumn/Tablefivecol"
import Moreoption from "../Table/Moreoption/Moreoption"
import Tables from "../Table/sixcolumn/Tables"
import Tableheader from "../Table/Tableheader/Tableheader"
import Tablethreecol from "../Table/Threecolmn/Tablethreecol"
import styles from "./Auction.module.css"
import AdminFiveColumn from "../Table/Fivecolumn/AdminfiveColumn/AdminFiveColumn"

const moreOptions = [
  {
    id: "request",
    label: "Request",
    value: "request",
  },
  {
    id: "auctionlive",
    label: "Live",
    value: "auctionlive",
  },
  {
    id: "upcoming",
    label: "Upcoming",
    value: "upcoming",
  },
  {
    id: "past",
    label: "Past",
    value: "past",
  },
  {
    id: "decline",
    label: "Decline",
    value: "decline",
  },
]
const requesthead = [
  {
    id: "like",
    label: "Start Date",
  },
  {
    id: "followe",
    label: "End Date",
  },
  {
    id: "location",
    label: "Auction Name",
  },
  {
    id: "location",
    label: "Artist Name",
  },
  {
    id: "like",
    label: "Action",
  },
]
const upcominghead = [
  {
    id: "like",
    label: "Auction Name",
  },
  {
    id: "followe",
    label: "Start Date",
  },
  {
    id: "location",
    label: "End Date",
  },
]
const livehead = [
  {
    id: "like",
    label: "Auction Name",
  },
  {
    id: "followe",
    label: "Time Left",
  },
  {
    id: "location",
    label: "Current Bid",
  },
  {
    id: "location",
    label: "End Date",
  },
  {
    id: "like",
    label: "Total Bids",
  },
]
const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Auction name",
  },
  {
    id: "mobile_number",
    label: "Start Date",
  },
  {
    id: "email",
    label: "End Date",
  },
  {
    id: "assign_to",
    label: "Win Bid",
  },
  {
    id: "type",
    label: "Total Bid",
  },
  {
    id: "action",
    label: "Art Sold to",
  },
]

const sortdata = [
  { label: "By Start Date ↓", value: "low" },
  { label: "By Start Date ↑", value: "high" },
  { label: "By End Date ↓", value: "endlow" },
  { label: "By End Date ↑", value: "endhigh" },
]
const filterdata = [
  { label: "Auction name", value: "Auction name" },
  { label: "Artist name", value: "Artist name" },
  //  { label: "Rejected", value: "rejected" },
]
export default function Auction() {
  const [activetab, setAcitvetab] = useState("request")
  const [button, setButton] = useState({ type: "add", text: "Create" })
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({ offset: 0 })
  const [condition, setCondition] = useState({
    search: "",
    filter: "",
    sorting: "",
  })

  const dispatch = useDispatch()
  const auctionsdata = useSelector((state) => state.auctionData.data)
  const handleMorecategories = (e) => {
    setAcitvetab(e)
  }
  const fetchData = () => {
    const payload = { offset: data.offset } // ensure only offset is being sent
    dispatch(fetchAuctionData(payload))
  }
  useEffect(() => {
    fetchData()
  }, [data])
  const handleSubmitAuction = (dt) => {
    dispatch(handleAddAuction(dt, setOpen, fetchData))
  }
  const handleAcceptReject = (id, dt) => {
    dispatch(handleAcceptRejectAuction(id, { is_accepted: dt }, fetchData))
  }
  const handleDeleteAuction = (id) => {
    dispatch(deleteAuction(id, fetchData))
  }
  const hadleNavigate = () => {}

  const handleFilter = (event) => {
    const value = event.target.value
    setCondition({ ...condition, filter: value })
  }

  const handleSearch = (e) => {
    let value = e.target.value
    setCondition({ ...condition, search: value })
  }

  const handleSorting = (e) => {
    let value = e.target.value
    if (value === "low") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAuctionData({
          data:
            auctionsdata.data &&
            [...auctionsdata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() < b.start_date.toLowerCase())
                return -1
            }),
          totalCount: auctionsdata.totalCount,
        })
      )
    } else if (value === "high") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAuctionData({
          data:
            auctionsdata.data &&
            [...auctionsdata.data].sort((a, b) => {
              if (a.start_date.toLowerCase() > b.start_date.toLowerCase())
                return -1
            }),
          totalCount: auctionsdata.totalCount,
        })
      )
    } else if (value === "endlow") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAuctionData({
          data:
            auctionsdata.data &&
            [...auctionsdata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() < b.end_date.toLowerCase()) return -1
            }),
          totalCount: auctionsdata.totalCount,
        })
      )
    } else if (value === "endhigh") {
      setCondition({ ...condition, sorting: value })
      dispatch(
        setAuctionData({
          data:
            auctionsdata.data &&
            [...auctionsdata.data].sort((a, b) => {
              if (a.end_date.toLowerCase() > b.end_date.toLowerCase()) return -1
            }),
          totalCount: auctionsdata.totalCount,
        })
      )
    } else {
      fetchData()
      setCondition({ ...condition, sorting: value })
    }
  }
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      {open ? (
        <Add
          poptype="auction"
          handleSubmitAuction={handleSubmitAuction}
          open={open}
          setOpen={setOpen}
        />
      ) : (
        ""
      )}
      <div className={styles.components}>
        <Tableheader
          handleFilter={handleFilter}
          handleSorting={handleSorting}
          filterdata={filterdata}
          sortdata={sortdata}
          condition={condition}
          search={condition.search}
          handleSearch={handleSearch}
          setOpen={setOpen}
          button={button}
        />
        <Moreoption
          activetab={activetab}
          setAcitvetab={setAcitvetab}
          moreOptions={moreOptions}
          handleMorecategories={handleMorecategories}
          setData={setData}
        />
        {activetab === "request" ? (
          <AdminFiveColumn
            handleDeleteAuction={handleDeleteAuction}
            handleAcceptReject={handleAcceptReject}
            activetab={activetab}
            head={requesthead}
            data={
              auctionsdata &&
              auctionsdata.data.filter((val) => {
                return (
                  val &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                )
              })
            }
          />
        ) : activetab === "decline" ? (
          <Tablethreecol
            activetab={activetab}
            handleDeleteAuction={handleDeleteAuction}
            head={upcominghead}
            data={
              auctionsdata.data &&
              auctionsdata.data.filter((val) => {
                return (
                  val.is_accepted === "Rejected" &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                )
              })
            }
          />
        ) : activetab === "auctionlive" ? (
          <Tablesfivecol
            activetab={activetab}
            head={livehead}
            data={
              auctionsdata &&
              auctionsdata.data.filter((val) => {
                const startDate = new Date(val.start_date.substring(0, 10))
                const endDate = new Date(val.end_date.substring(0, 10))
                const currentDate = new Date()

                return (
                  startDate <= currentDate &&
                  endDate >= currentDate &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                )
              })
            }
          />
        ) : activetab === "upcoming" ? (
          <Tablethreecol
            activetab={activetab}
            head={upcominghead}
            data={
              auctionsdata &&
              auctionsdata.data.filter((val) => {
                return (
                  val &&
                  new Date(val.end_date.substring(0, 10)) > new Date() > 0 &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                )
              })
            }
          />
        ) : (
          <Tables
            activetab={activetab}
            head={exhibitionhead}
            data={
              auctionsdata &&
              auctionsdata.data.filter((val) => {
                return (
                  val &&
                  new Date(val.end_date.substring(0, 10)) < new Date() > 0 &&
                  val.name
                    .toLowerCase()
                    .includes(condition.search.toLowerCase())
                )
              })
            }
          />
        )}
        {auctionsdata &&
          Array.isArray(auctionsdata.data) &&
          auctionsdata.data.length > 0 && (
            <Pagination
              totaldata={auctionsdata && auctionsdata.totalCount}
              pagination={data}
              setPagination={setData}
            />
          )}
      </div>
    </div>
  )
}
