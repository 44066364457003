import React, { useState } from "react";
import styles from "./Recretepopup.module.css";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, TextField } from "@mui/material";
import homeImage from "../../../../constant";
import dateFormat from "../../../../utils/dateFormat";
import serverimage from "../../../../serverConstant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height:'700px',
  backgroundColor: "#FFFFFF",
  // width: 445,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
  borderRadius: "10px",
  p: 0,
};

export default function Recretepopup(props) {
 console.log(props,'props')
  const [amount, setAmount] = useState();
  const handleClose = () => props.setOpen(false);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={props.open}>
          <Box className={styles.modal_del}>
            <div className={styles.main_detail}>
              <div className={styles.img_div}>
                <img alt='img' src={ props.acceptPopupData?.item_id?.image} height="200px" width="250px" />
              </div>
              <div>
                {/* {props.data && props.data.map((val) => ( */}
                <div className={styles.details}>
                  <h4 className={styles.title}>User Name :</h4>
                  <p>
                    {props.acceptPopupData.userName}
                  </p>
                </div>
                <div className={styles.details}>
                  <h4 className={styles.title}>Sku_Id :</h4>
                  <p>{props.acceptPopupData?.item_id?.sku_id}</p>
                </div>
                <div className={styles.details}>
                  <h4 className={styles.title}>Art Name :</h4>
                  <p>{props.acceptPopupData?.item_id?.name}</p>
                </div>
                <div className={styles.details}>
                  <h4 className={styles.title}>Art Type :</h4>
                  <p>{props.acceptPopupData.item_id?.sale ? "Sale" : "Print"}</p>
                </div>
                <div className={styles.details}>
                  <h4 className={styles.title}>Request Date :</h4>
                  <p>{dateFormat(props.acceptPopupData.createdAt)}</p>
                </div>
              
                {/* ))} */}
              </div>
            </div>
            <div className={styles.description_div}>
              <h3 className={styles.title}>Remark For Recreate:</h3>
              <p className={styles.description}>{props.acceptPopupData.message}</p>
            </div>
            {props.activetab === "galleryrecrete" || "buyeroffer" ? "" : (
              <div className={styles.inputs}>
                <label className={styles.input_label}>Quatation</label>
                <br />
                <TextField
                  fullWidth
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  variant="outlined"
                  placeholder="Enter amount"
                />
              </div>
            )}
            <div className={styles.btn_div}>
              {props.activetab === "galleryrecrete" ? (
                <>
                  <Button
                    onClick={() =>
                    {
                      props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                    }
                    }
                    variant="contained"
                    sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                    Accept
                  </Button>
                  <Button
                    onClick={() =>
                   {
                    props.handleAcceptRejectRecreate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                    handleClose()
                   }
                    }
                    variant="outlined"
                    sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                    Decline
                  </Button>
                </>
              ) : props.activetab === 'qouterequest' ? (
                <>
                  <Button
                    onClick={() =>
                     {
                      
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                     }
                    }
                    variant="contained"
                    sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                    Accept
                  </Button>
                  <Button
                    onClick={() =>
                    {
                      props.handleAcceptRejectQuate(props.acceptPopupData._id, { is_accepted: "Rejected" })
                      handleClose()
                    }
                    }
                    variant="outlined"
                    sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                    Decline
                  </Button>
                </>
              )
                : (
                  <>
                    <Button
                      onClick={() =>
                     {
                      props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Approved" })
                      handleClose()
                     }
                      }
                      variant="contained"
                      sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                      Accept
                    </Button>
                    <Button
                      onClick={() =>
                       {
                        props.handleAcceptRejectOffer(props.acceptPopupData._id, { is_accepted: "Rejected" })
                        handleClose()
                       }
                      }
                      variant="outlined"
                      sx={{ width: "156px", height: "54px", borderRadius: "10px" }}>
                      Decline
                    </Button>
                  </>
                )
              }

            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
