import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createTheme,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  ThemeProvider,
  Typography,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import styles from "./ProductSidebar.module.css"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Slider from "@mui/material/Slider"
import homeImage from "../../../constant"

import CircularLoader from "../../../common/loader/Loader"
import { useDispatch, useSelector } from "react-redux"

import { Checkbox } from "@mui/material"
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import CircleCheckedFilled from "@material-ui/icons/CheckCircle"
import { setLoader } from "../../../State/Features/common/loaderSlice"
import api from "../../../State/api/Api"
import {
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
} from "../../../State/api/userApi"
import serverimage from "../../../serverConstant"
import {
  setArtistTypeFilter,
  setCategoryFilter,
  setColorFilter,
  setStyleFilter,
  setTypeFilter,
  setCountryFilter,
  setArtistFilter,
  setOrientationFilter,
  setFilterByPriceNav,
} from "../../../State/Features/user/filterationSlice"
import { CropDin, CropLandscape, CropPortrait } from "@mui/icons-material"
import { handleFetchStyle } from "../../../State/api/superadminApi"
import { useParams } from "react-router-dom"
import { fetchAdminOffer } from "../../../State/api/artistapi"

function ProductSidebar(props) {
  const { id } = useParams()
  function valuetext(value) {
    return `${value}°C`
  }
  function valuetext_height(value_height) {
    return `${value_height}°C`
  }
  function valuetext_width(value_width) {
    return `${value_width}°C`
  }
  const dispatch = useDispatch()

  const [countrydt, setCountrydt] = useState()
  const [orientation, setOrientation] = useState()
  const [colordt, setColordt] = useState()

  const [activeIndex, setActiveIndex] = useState(null)
  const loader = useSelector((state) => state.loader)
  const productType = useSelector((state) => state.productType)
  const artistTypes = useSelector((state) => state.artistTypedata)
  const adminofferDt = useSelector((state) => state.adminOfferData.data);
  const userartists = useSelector((state) => state.userartistData)
  const allcategory = useSelector((state) => state.allcategoryData)
  const filter = useSelector((state) => state.filteration)
  const startPrice = Number(props.filteredValues?.startPrice ?? 0)
  const endPrice = Number(props.filteredValues?.endPrice ?? 50000)
  const minHeight = useMemo(
    () => Number(props.filteredValues?.minHeight ?? 0),
    [props.filteredValues.minHeight]
  )
  const maxHeight = useMemo(() => {
    const value = Number(props.filteredValues.maxHeight)
    return value > 0 ? value : 1500 // Default to 1500 if maxHeight is 0 or not provided
  }, [props.filteredValues.maxHeight])
  const minWidth = useMemo(
    () => Number(props.filteredValues.minWidth ?? 0),
    [props.filteredValues.minWidth]
  )
  const maxWidth = useMemo(() => {
    const value = Number(props.filteredValues.maxWidth)
    return value > 0 ? value : 1500 // Default to 1500 if maxHeight is 0 or not provided
  }, [props.filteredValues.maxWidth])
  const priceValue = [startPrice, endPrice]
  const heightValue = useMemo(
    () => [minHeight, maxHeight],
    [minHeight, maxHeight]
  )
  const widthValue = useMemo(() => [minWidth, maxWidth], [minWidth, maxWidth])
  const handleChange = (event, newValue) => {
    if (newValue.length) {
      if (newValue[0] !== 0 || newValue[1] !== 50000) {
        props.setFilteredValues({
          ...props.filteredValues,
          startPrice: newValue[0],
          endPrice: newValue[1],
        })
      } else {
        props.setFilteredValues({
          ...props.filteredValues,
          startPrice: "0",
          endPrice: "50000",
        })
      }
      setTimeout(() => {
        window.scrollTo({
          top: 120,
          behavior: "smooth",
        })
      }, 5000)
    }
    dispatch(setFilterByPriceNav(newValue))
  }
  // const handleChange_height = (event, newValue) => {
  //   props.setValueHeight(newValue);
  // };
  const handleChange_height = (event, newValue) => {
    if (newValue.length) {
      if (newValue[0] !== 0 || newValue[1] !== 1500) {
        props.setFilteredValues({
          ...props.filteredValues,
          minHeight: newValue[0],
          maxHeight: newValue[1],
        })
      } else {
        props.setFilteredValues({
          ...props.filteredValues,
          minHeight: 0,
          maxHeight: 1500,
        })
      }
      setTimeout(() => {
        window.scrollTo({
          top: 120,
          behavior: "smooth",
        })
      }, 5000)
    }
    dispatch(setFilterByPriceNav(newValue))
  }
  const handleChange_width = (event, newValue) => {
    if (newValue.length) {
      if (newValue[0] !== 0 || newValue[1] !== 1500) {
        props.setFilteredValues({
          ...props.filteredValues,
          minWidth: newValue[0],
          maxWidth: newValue[1],
        })
      } else {
        props.setFilteredValues({
          ...props.filteredValues,
          minWidth: 0,
          maxWidth: 1500,
        })
      }
      setTimeout(() => {
        window.scrollTo({
          top: 120,
          behavior: "smooth",
        })
      }, 5000)
    }
  }
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#ccc",
      },
    },
    typography: {
      accordionheading: {
        fontSize: "14px",
        fontWeight: 500,
      },
      radioname: {
        fontSize: "0.95vw",
        fontWeight: "400",
      },
    },
  })
  const handleCountry = (e) => {
    const val = e.target.value
    dispatch(setCountryFilter(val))
  }
  const handleFetchCountry = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcountry`)
      if (result && result.status === 200) {
        setCountrydt(result.data.response)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const handleFetchOrientation = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getorientation`)
      if (result && result.status === 200) {
        setOrientation(result.data.response)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const handleFetchColor = async function () {
    dispatch(setLoader(true))
    try {
      const result = await api.applicationApi(`api/user/getcolors`)
      if (result && result.status === 200) {
        setColordt(result.data.response)
        dispatch(setLoader(false))
      } else if (result.status === 401) {
      }
    } catch (err) {
    }
  }
  const handleChangeCheckbox = (e) => {
    const val = e.target.id
    dispatch(setTypeFilter(val))
    // const val = e.target.id;
    // const type = [...props.filter.type];
    // const index = props.filter.type.findIndex((item) => item === val);
    // if (index === -1) {
    //   type.push(val);
    //   props.setFilter({ ...props.filter, type: type });
    // } else {
    //   type.splice(index, 1);
    //   props.setFilter({ ...props.filter, type: type });
    // }
  }
  const handleArtistType = (e) => {
    const val = e.target.id
    dispatch(setArtistTypeFilter(val))
    // const val = e.target.value;
    // const artist_type = [...props.filter.artist_type];
    // const index = props.filter.artist_type.findIndex((item) => item === val);
    // if (index === -1) {
    //   artist_type.push(val);
    //   props.setFilter({ ...props.filter, artist_type: artist_type });
    // } else {
    //   artist_type.splice(index, 1);
    //   props.setFilter({ ...props.filter, artist_type: artist_type });
    // }
  }
  const handleFilterColor = (e) => {
    const val = e.target.id
    dispatch(setColorFilter(val))
  }
  const handleChangeStyle = (e) => {
    const val = e.target.value
    dispatch(setStyleFilter(val))
  }
  const handleFilterCategory = (e) => {
    const val = e.target.id
    if (val.length === 0) {
      dispatch(setCategoryFilter(val))
      dispatch(setStyleFilter([]))
    } else {
      dispatch(setStyleFilter([]))
      dispatch(setCategoryFilter(val))
      dispatch(handleFetchStyle({ category_id: val }, props.setStyle))
    }
  }
  const handleFilterArtist = (e) => {
    const val = e.target.id
    dispatch(setArtistFilter(val))
  }
  const handleCangeOrientation = (e) => {
    const value = e.target.id
    dispatch(setOrientationFilter(value))
  }
  useEffect(() => {
    handleFetchCountry()
    dispatch(fetchAdminOffer())
    handleFetchColor()
    dispatch(fetchProductType())
    dispatch(fetchArtistTypes())
    dispatch(fetchUserArtist({}))
    dispatch(fetchCategoryData({}))
    handleFetchOrientation()
    id && dispatch(handleFetchStyle({ category_id: id }, props.setStyle))
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className={`filtersidebar ${styles.ProductSidebar}`}>
        {/* <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            backgroundColor: "rgb(243, 243, 243);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography variant="accordionheading">
              <b>TYPE</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              productType.data &&
              productType.data.map((val) => (
                <div className={styles.radio__div}>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    name={val.name}
                    value={val.name}
                    id={val._id}
                    onClick={handleChangeCheckbox}
                    size="small"
                    color="primary"
                    checked={filter.type.includes(val._id)}
                  />
                  <label className={styles.radio__label} htmlFor="Assemblage">
                    {val.name}
                  </label>
                  <br />
                  <br />
                </div>
              ))
            )}
          </AccordionDetails>
        </Accordion> */}
        {window.location.href.includes("allcategory") ? (
          ""
        ) : (
          <>
            <Accordion
              defaultExpanded
              elevation={0}
              
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <h3>
                  Category
                </h3>
              </AccordionSummary>
              <AccordionDetails>
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  // allcategory.data &&
                  // allcategory.data.allcategories.map((val) => (
                  //   <div className={styles.radio__div}>
                  //     <Checkbox
                  //       icon={<CircleUnchecked />}
                  //       checkedIcon={<CircleCheckedFilled />}
                  //       type="checkbox"
                  //       name={val.name}
                  //       value={val.name}
                  //       id={val._id}
                  //       onClick={handleFilterCategory}
                  //       size="small"
                  //       color="primary"
                  //       checked={filter.category.includes(val._id)}
                  //     />
                  //     <label className={styles.radio__label} htmlFor="Art">
                  //       {val.name}
                  //     </label>
                  //     <br />
                  //     <br />
                  //   </div>
                  // ))
                  <div className={styles.radio__div}>
                    <ul className={styles.ctfilist}>
                    {allcategory.data &&
                      allcategory.data.allcategories.map((val) => (
                        
                          <li>
                          <label htmlFor={val._id}>
                          <input type="checkbox"
                                className={styles.checkbox_animated}
                                name="categoryName"
                                value={val.name}
                                onClick={props.handleChangeFilteredValues}
                                id={val._id}
                              />
                            {val.name}
                            </label>
                        </li>
                       
                        
                      ))}
                      </ul>
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        )}
        <Accordion
          defaultExpanded
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>
              Style
            </h3>
          </AccordionSummary>
          <AccordionDetails>
            {true ? (
              <div className={styles.radio__div}>
                 <ul className={styles.ctfilist}>
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  props.style &&
                  props.style.map((val) => (
                    <>
                      <li>
                        <label htmlFor={val._id}>
                            <input  type="checkbox"
                              className={styles.checkbox_animated}
                              name="styleName"
                              value={val.name}
                              onClick={props.handleChangeFilteredValues}
                              id={val._id}
                            />
                          {val.name}
                          </label>
                      </li>
                    </>
                  ))
                )}
                </ul>
              </div>
            ) : filter.category.length === 0 ? (
              "Please Select Category"
            ) : (
              <div className={styles.radio__div}>
                <ul className={styles.ctfilist}>
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : props.style && props.style.length === 0 ? (
                  "No props.style found"
                ) : (
                  props.style &&
                  props.style.map((val) => (
                    <>
                      <Checkbox
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        id={val._id}
                        // checked={val.name.toLowerCase() === props.selectedOption.toLowerCase()}
                        type="checkbox"
                        name="styleName"
                        value={val.name}
                        onClick={props.handleChangeFilteredValues}
                        size="small"
                        color="primary"
                        // onChange={handleStyleCheckboxChange}
                      />
                      <label className={styles.radio__label} htmlFor="Abstract">
                        {val.name}
                      </label>
                      <br />
                    </>
                  ))
                )}
              </ul>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        {/* <hr className={styles.divider} /> */}
        <div className={styles.price__slider}>
          <h3>Price</h3>
          <Box sx={{ width: 250, m: "auto", marginTop: 1 }}>
            <Slider
              // getAriaLabel={() => "Temperature range"}
              value={priceValue}
              getAriaLabel={() => "Price"}
              // value={value}
              size="small"
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              sx={{
                color: "grey",
                "& .MuiSlider-thumb": {
                  color: "#3A3B3C",
                },
                "& .MuiSlider-rail": {
                  color: "black",
                },
                ".MuiSlider-track": {
                  height: "4px",
                },
              }}
              color="secondary"
              min={0}
              max={50000}
            />
            <div className={styles.labels}>
              <span className={styles.start}>0</span>
              <span className={styles.end}>$50,000</span>
            </div>
          </Box>
          {/* <hr className={styles.divider} /> */}
        </div>
        <div className={styles.price__slider}>
          <h3>Height</h3>
          <Box sx={{ width: 250, m: "auto", marginTop: 1 }}>
            <Slider
              // getAriaLabel={() => "Temperature range"}
              value={heightValue}
              getAriaLabel={() => "height"}
              // value={value_height}

              onChange={handleChange_height}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext_height}
              size="small"
              sx={{
                color: "grey",
                "& .MuiSlider-thumb": {
                  color: "#3A3B3C",
                },
                "& .MuiSlider-rail": {
                  color: "black",
                },
                ".MuiSlider-track": {
                  height: "4px",
                },
              }}
              color="secondary"
              min={0}
              max={1500}
            />
            <div className={styles.labels}>
              <span className={styles.start}>0</span>
              <span className={styles.end}>1500+cm</span>
            </div>
          </Box>
          {/* <hr className={styles.divider} /> */}
        </div>
        <div className={styles.price__slider}>
          <h3>Width</h3>
          <Box sx={{ width: 250, m: "auto", marginTop: 1 }}>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={widthValue}
              // getAriaLabel={() => "Width"}
              // value={value_width}
              size="small"
              onChange={handleChange_width}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext_width}
              sx={{
                fontSize: "5px",
                color: "grey",
                "& .MuiSlider-thumb": {
                  color: "#3A3B3C",
                },
                "& .MuiSlider-rail": {
                  color: "black",
                },
                ".MuiSlider-track": {
                  height: "4px",
                },
              }}
              color="secondary"
              min={0}
              max={1500}
            />
            <div className={styles.labels} style={{ marginBottom: "3%" }}>
              <span className={styles.start}>0</span>
              <span className={styles.end}>1500+cm</span>
            </div>
          </Box>
        </div>

        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Orientation</h3>
            </AccordionSummary>
          <AccordionDetails>
            {orientation &&
              orientation.map((val) => (
                <Checkbox
                  name="typefilter"
                  // icon={<CircleUnchecked />}
                  icon={
                    val.name === "portrait" ? (
                      <CropPortrait />
                    ) : val.name === "landscape" ? (
                      <CropLandscape />
                    ) : val.name === "square" ? (
                      <CropDin />
                    ) : null
                  }
                  onClick={() =>
                    props.setFilteredValues({
                      ...props.filteredValues,
                      orientationName: val?.name,
                    })
                  }
                  checkedIcon={
                    val.name === "portrait" ? (
                      <CropPortrait />
                    ) : val.name === "landscape" ? (
                      <CropLandscape />
                    ) : val.name === "square" ? (
                      <CropDin />
                    ) : null
                  }
                  value={val._id}
                  id={val._id}
                  size="small"
                  color="primary"
                />
              ))}
            {/* <Checkbox
              name="typefilter"
              // icon={<CircleUnchecked />}
              icon={
                // <img
                //   className={styles.orientation}
                //   name="horizontal"
                //   src={ "/img/Orientation square.png"}
                //   onClick={handleCangeOrientation}
                // />
                <CropLandscape />
              }
              onClick={handleCangeOrientation}
              checkedIcon={
                // <img
                //   className={styles.orientation_select}
                //   name="horizontal"
                //   src={ "/img/orientation square.png"}
                //   onClick={handleCangeOrientation}
                // />
                <CropLandscape />
              }
              value="Conceptual"
              id="Conceptual"
              size="small"
              color="primary"
            />
            <Checkbox
              name="typefilter"
              // icon={<CircleUnchecked />}
              icon={
                // <img
                //   className={styles.orientation}
                //   name="landscape"
                //   src={ "/img/orientation rect.png"}
                //   onClick={handleCangeOrientation}
                // />
                <CropDin />
              }
              onClick={handleCangeOrientation}
              checkedIcon={
                // <img
                //   className={styles.orientation_select}
                //   name="landscape"
                //   src={ "/img/orientation rect.png"}
                //   onClick={handleCangeOrientation}
                // />
                <CropDin />
              }
              value="Conceptual"
              id="Conceptual"
              size="small"
              color="primary"
            /> */}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Color</h3>
          </AccordionSummary>
          <AccordionDetails>
            {colordt &&
              colordt.map((val, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: val?.name ? val?.name : "" }}
                  className={`${styles.a_palette_color} ${
                    activeIndex === index ? styles.active : ""
                  }`}
                  onClick={() =>
                    props.setFilteredValues({
                      ...props.filteredValues,
                      colorName: val?.name,
                    })
                  }
                ></div>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Search by Artist Type</h3>
            
          </AccordionSummary>
          <AccordionDetails>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              artistTypes.data &&
              artistTypes?.data?.map((val) => (
                <div className={styles.radio__div}>
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    name="artistTypeName"
                    value={val.name}
                    id={val._id}
                    size="small"
                    color="primary"
                    onClick={props.handleChangeFilteredValues}
                    checked={props.filteredValues?.artistTypeName?.includes(
                      val.name
                    )}
                  />
                  <label className={styles.radio__label} htmlFor="Emerging">
                    {val.name}
                  </label>
                  <br />
                  <br />
                </div>
              ))
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Artists</h3>
             </AccordionSummary>
          <AccordionDetails>
            {loader.loader ? (
              <div className={styles.loader}>
                <CircularLoader />
              </div>
            ) : (
              userartists.data &&
              userartists?.data
                .filter((val) => val.is_accepted === "Approved")
                .map((val) => (
                  <div className={styles.radio__div}>
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      name="artistName"
                      value={val.artist_name}
                      // name={val.first_name}
                      // value={val.last_name}
                      id={val._id}
                      size="small"
                      color="primary"
                      onClick={props.handleChangeFilteredValues}
                      checked={props.filteredValues?.artistName?.includes(
                        val.artist_name
                      )}
                    />
                    <label className={styles.radio__label}>
                      {val.artist_name}
                      {/* {val.first_name}
                     {val.last_name} */}
                    </label>
                    <br />
                    <br />
                  </div>
                ))
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Country</h3>
            
          </AccordionSummary>
          <AccordionDetails>
            {countrydt &&
              countrydt.map((val) => (
                <div className={styles.radio__div} key={val._id}>
                  <Checkbox
                    name="countryName"
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    checked={props.filteredValues?.countryName === val.name}
                    size="small"
                    color="primary"
                    sx={{
                      color: props.filteredValues?.countryName ? "black" : "inherit", // Change color to black when checked
                    }}
                    onClick={() =>
                      props.setFilteredValues({
                        ...props.filteredValues,
                        countryName: val.name,
                      })
                    }
                  />
                  <label className={styles.radio__label} htmlFor="Albania">
                    {val.name}
                  </label>
                  <br />
                  <br />
                </div>
              ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            width: "100%",
            border: "none",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.07);",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <h3>Offers</h3>
            </AccordionSummary>
          <AccordionDetails>
            {adminofferDt?.data &&
              adminofferDt?.data.map((val) => (
                <div className={styles.radio__div} key={val._id}>
                  <Checkbox
                    name="offername"
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleCheckedFilled />}
                    value={val._id}
                    id={val._id}
                    size="small"
                    color="primary"
                    onClick={() =>
                      props.setFilteredValues({
                        ...props.filteredValues,
                        offername: val.name,
                      })
                    }
                  />
                  <label className={styles.radio__label} htmlFor="Albania">
                    {val.name}
                  </label>
                  <br />
                  <br />
                </div>
              ))}
          </AccordionDetails>
        </Accordion>
      </div>
    </ThemeProvider>
  )
}

export default React.memo(ProductSidebar)
