import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,

}

const pressreleaseSlice = createSlice({
    name: 'pressreleasedata',
    initialState,
    reducers: {
        setPressrelease: (state, action) => {
            state.data = action.payload;
        },
        // prevPage: (state, action) => {
        //     state.pagination = action.payload;
        // }
    }
});

export const { setPressrelease } = pressreleaseSlice.actions;

// export const subscriptionfeatureData = (state) => state.subscriptionfeatureData;

export default pressreleaseSlice.reducer;