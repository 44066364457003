import { Button } from "@mui/material";
import React, { useState } from "react";
import serverimage from "../../../../../serverConstant";
import styles from "./Commondetailexshibitiongallery.module.css";

export default function Commondetailexshibitiongallery(props) {
  const [open, setOpen] = useState(false);
  console.log("props data", props.data && props.data.data.data)
  const handlePopup = () => {
    props.setOpen && props.setOpen({ open: true });
  };
  const handleDatepopup = () => {
    props.setOpen({ dateopen: true });
  };
  return (
    <div className={styles.mains}>
      {props.type === "order_detail_gallery" ? (
        ""
      ) : (
        <>
          <h1 className={styles.main_heading}>
            {/* {props.type==="auction_gallery" ? props.data1 && props.data.auction: props.data && props.data.data && props.data.data.exhibition} */}
            {/* {props.data && props.data.data.data && props.data.data.data[0].artist} */}
          </h1>
          <hr />
        </>
      )}
      <div className={styles.detail_id}>
        {props.type === "order_detail_gallery" ? (
          <h3>Exhibition Name</h3>
        ) : props.type === "auction_gallery" ? (
          <h3>Auction Name</h3>
        ) : (
          <h1>Exhibition Name</h1>
        )}
        <p>
          {props.type === "order_detail_gallery"
            ? props.data && props.data.exhibition
            : props.type === "auction_gallery"
              ? props.data && props.data.data.auction
              : props.data && props.data.data.exhibition}
        </p>
      </div>{" "}
      <div className={styles.detail_id}>
        <h1>No. of Items</h1>
        <p>{props.data === null ? 0 : props.data?.data.data.length === 0 ? "0" : props.data.data.data.length}</p>
      </div>
      {/* <hr /> */}
      {props.data === null ?
        <h2 align="center">No Data Found</h2>
        : props.data.data && props.data.data.data.length === 0 ? (
          <h2 align="center">No Data Found</h2>
        )
          : (
            //    ) : props.data.data.data && props.data.data.data.length === 0 ? (
            //     <h2 align="center">No Data Found</h2>
            props.data &&
            props.data.data.data &&
            props.data.data.data.map((item, i) => (
              <div key={i}>
                <hr className={styles.hrs} />
                <div className={styles.card_main_content}>
                  <div className={styles.status}>
                    {props.type === "auction_gallery" ? (
                      item.item_status === "Sold" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Sold
                        </Button>
                      ) : item.item_status === "Unsold" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Unsold
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )
                    ) : props.type === "exshibition_gallery" ? (
                      item.item_status === "Sold" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Sold
                        </Button>
                      ) : item.item_status === "Unsold" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Unsold
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )
                    ) :
                      props.type === "order_detail_gallery" ? (
                        item.content === "1" ? (
                          <Button
                            sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                            Rejected
                          </Button>
                        ) : item.content === "2" ? (
                          <div className={styles.item_acc_dec_div}>
                            <Button variant="contained" sx={{ width: "120px", height: "46px" }}>
                              Accept
                            </Button>
                            <Button
                              onClick={handlePopup}
                              variant="outlined"
                              sx={{ backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                              Decline
                            </Button>
                          </div>
                        ) : item.order_status?.toLowerCase() === "schedule pickup pending" ? (
                          <Button
                            onClick={handleDatepopup}
                            variant="contained"
                            sx={{ width: "226px", height: "46px" }}>
                            Pick A Shedule
                          </Button>
                        ) : item.content === "0" ? (
                          <Button
                            sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "326px", height: "46px" }}>
                            Product Recived at Artist’s Location
                          </Button>
                        ) : (
                          <p className={styles.schedule_time}>{item.order_status}</p>
                        )
                      ) : item.content === "0" ? (
                        <Button
                          sx={{ color: "#35BE43", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Accept
                        </Button>
                      ) : item.content === "1" ? (
                        <Button
                          sx={{ color: "#EE0909", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Rejected
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: "#FFC705", backgroundColor: "#EAEAEA", width: "120px", height: "46px" }}>
                          Pending
                        </Button>
                      )}

                    {/* no use at that time is_accepted  */}
                    {/* {item.is_accepted === "Pending" ? (
        <div className={styles.action_button}>
         <Button
          onClick={()=>props.handleAcceptRejejectArt(item._id, { is_accepted: "Approved" })}
          variant="contained"
          sx={{ height: "46px", width: "120px" }}>
          Accept
         </Button>
         <Button
          onClick={()=>props.handleAcceptRejejectArt(item._id, { is_accepted: "Rejected" })}
          variant="outlined"
          sx={{ height: "46px", width: "120px" }}>
          Decline
         </Button>
        </div>
       ) : (
        item.is_accepted
       )} */}

                  </div>
                  <div className={styles.more_detail}>
                    <div>
                      {item.item.map((val, i) => (
                        <div>
                          <h1 className={styles.art_name}>{val.name}</h1>
                          <div>
                            <img src={ val.image} height="85px" width="121px" alt='img' />{" "}
                          </div>
                          {localStorage.getItem('usertype') === 'gallery' ?
                            <div className={styles.more_detail_child}>
                              <div className={styles.title}>Artist Name:</div>
                              <div className={styles.content}>{val.artist && val.artist.map(c => <>{c.first_name} {c.last_name}</>)}</div>
                            </div> : ""}
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Price:</div>
                            <div className={styles.content}>${item.price}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Material:</div>
                            <div className={styles.content}>{val.material && val.material.map(c => <>{c.name}, </>)}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Category:</div>
                            <div className={styles.content}>{val.category}</div>
                          </div>
                          <div className={styles.more_detail_child}>
                            <div className={styles.title}>Dimension:</div>
                            <div className={styles.content}>{val.width} W x {val.height} H x {val.depth} D in</div>
                          </div>
                      
                        </div>
                      ))}
                    </div>
                    {/* {item.action ===  } */}
                    <div className={styles.action}>
                      <p className={styles.remark_text}>{item.remark}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
    </div>
  );
}
