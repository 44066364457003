import { Box, Button } from "@mui/material";
import React from "react";
import styles from "./webPopupCuretorAndAffilate.module.css";
import homeImage from "../../../../../constant";
import { useNavigate } from "react-router-dom";

export default function WebpopupAffiliate({open,setOpen}) {
  const navigate = useNavigate();
  return (
    <>
      {/* {
            props.type === 'web' ?
                // <Box sx={{ mt: { xs: '-20px', sm: '-64px' } }} className={props.data.length === props.i + 1 ? styles.cancel_imgweb : styles.cancel_img_hide_web} >
                //     {props.data.length === props.i + 1 ?
                //         <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                //         : ''}
                // </Box> 
                "" :
                <div className={props.data.length === props.i + 1 ? styles.cancel_img : styles.cancel_img_hide} >
                    {props.data.length === props.i + 1 ?
                        <img alt='img' onClick={() => props.setOpen(false)} src={ '/img/cancel_cross.png'} height='20px' width='20px' />
                        : ''}
                </div>
        } */}

      <div className={styles.card_content}>
      
       <img alt="img" src="/img/artist-popup.svg" width="50px" height="50px" />
      
        <h3>  <>
          {"I Am"}
          <br />
          {"An Affiliate"}
        </></h3>
        <h6>You can apply online, and we will respond to you as quickly as possible.</h6>
        <Button
          variant="contained"
          sx={{ width: "156px", height: "54px" }}
          onClick={() => {
            if(open === "AffiliateAndCuratorSignIn" ) {
              navigate("/artsmiley/login",{state: {type: "affiliate"}})
            }else {
              navigate("/artsmiley/affiliate/applynow")
              setOpen(false)
            }
          }}
        >
      {open === "AffiliateAndCuratorSignIn" ? "Sign In" : "Apply Online"}
        </Button>
      </div>
    </>
  );
}
