import React from "react"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  Tooltip,
} from "@mui/material"
import styles from "./Fourcolimg.module.css"
import homeImage from "../../../../../constant"
import { useSelector } from "react-redux"
import CircularLoader from "../../../../../common/loader/Loader"
import serverimage from "../../../../../serverConstant"
import { useNavigate } from "react-router-dom"

export default function Fourcolimg(props) {
  console.log(props, "props-testimonials-")
  const [anchorEl, setAnchorEl] = React.useState(null)
  const threedots = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleProfile = (id) => {
    navigate(`/earnings/${id}`)
  }
  const loader = useSelector((state) => state.loader)
  const handlePopup = (e) => {
    props.setOpen(true)
    props.setPoptype("addfeature")
  }
  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table
          sx={{ minWidth: 700, borderRadius: "0px" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3", borderRadius: "none" }}>
              {props.head.map((column, index) => (
                <TableCell
                  align={index === 0 ? "center" : "start"}
                  key={column.id}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* {loader.loader ? (
      <TableBody>
       <TableRow>
        <TableCell colSpan={8}>
         <Box className={styles.loader_box}>
          {" "}
          <CircularLoader size={60} />
         </Box>
        </TableCell>
       </TableRow>
      </TableBody>
     ) : ( */}
          <TableBody>
            {props.data && props.data.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}> No Data Found</Box>
                </TableCell>
              </TableRow>
            ) : (
              props.data &&
              props.data.map((row) => (
                <TableRow
                  className={styles.table_row}
                  key={row.id}
                  style={{
                    color: "#636365",
                    backgroundColor:
                      props.poptype === "add" ? "white" : "#F6F6F6",
                  }}
                >
                  {props.activetab === "affiliate_earning" ? (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                      // onClick={() => handleProfile(row.item_id._id)}
                    >
                      <img
                        className={
                          props.poptype === "add"
                            ? styles.add_img
                            : styles.add_img_second
                        }
                        src={` ${row.item_id?.image}`}
                        alt="img"
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {props.activetab === "offer" ? (
                        <img
                          className={
                            props.poptype === "add"
                              ? styles.add_img
                              : styles.add_img_second
                          }
                          src={` ${row.offer_image}`}
                          alt="img"
                        />
                      ) : props.activetab === "testimonial" ? (
                        <img
                          className={
                            props.poptype === "add"
                              ? styles.add_img
                              : styles.add_img_second
                          }
                          src={` ${row.image}`}
                          alt="img"
                        />
                      ) : props.activetab === "affiliate_earning" ? (
                        <img
                          className={
                            props.poptype === "add"
                              ? styles.add_img
                              : styles.add_img_second
                          }
                          src={` ${row.image}`}
                          alt="img"
                        />
                      ) : (
                        <img
                          className={
                            props.poptype === "add"
                              ? styles.add_img
                              : styles.add_img_second
                          }
                          src={` ${row.gift_card_image}`}
                          alt="
                              img"
                        />
                      )}
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "21px",
                      color: "#636365",
                    }}
                    className={styles.table_column}
                    align="center"
                  >
                    {props.activetab === "giftcard"
                      ? row.min_price
                      : props.activetab === "affiliate_earning"
                      ? row.item_id.sku_id
                      : row.name}
                    {row.customer_name}
                  </TableCell>
                  {props.activetab === "offer" ? (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            row &&
                            row.description &&
                            row.description.length > 100
                              ? row.description.substring(0, 100) + "..."
                              : row && row.description
                              ? row.description
                              : "",
                        }}
                      ></span>
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="left"
                    >
                      {props.activetab === "testimonial"
                        ? row.position
                        : props.activetab === "giftcard"
                        ? row.name
                        : props.activetab === "affiliate_earning"
                        ? row.item_id.name
                        : ""}
                    </TableCell>
                  )}
                  {props.activetab === "testimonial" && (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                    </TableCell>
                  )}

                  {props.activetab === "giftcard" && (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {dayjs(row.createdAt).format("DD-MM-YYYY").toString()}
                    </TableCell>
                  )}

                  {props.activetab === "affiliate_earning" ? (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left"
                    >
                      <div className={styles.auction_last_col}>
                        <span>${row.sum_amount}</span>
                      </div>
                    </TableCell>
                  ) : props.activetab === "offer" ? (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left"
                    >
                      <div className={styles.auction_last_col}>
                        <span>
                          {" "}
                          {row?.discountType === "Price" ? "$" : ""}
                          {row?.discount}
                          {row?.discountType === "Percentage" ? "%" : ""}
                        </span>
                        <div className={styles.action}>
                          <Tooltip title="Edit">
                            <img
                              alt="img"
                              src={"/img/Edit.png"}
                              height="15px"
                              width="14px"
                              onClick={() => props.handleEditPopup(row)}
                            />
                          </Tooltip>

                          <img
                            alt="img"
                            src={"/img/delete.png"}
                            height="20px"
                            width="18px"
                            onClick={() => props.handleDelete(row._id)}
                          />
                        </div>
                      </div>
                    </TableCell>
                  ) : props.activetab === "giftcard " ? (
                    <TableCell
                      sx={{
                        width: "30%",
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left"
                    >
                      <div className={styles.auction_last_col}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              row &&
                              row.description &&
                              row.description.length > 80
                                ? row.description.substring(0, 80) + "..."
                                : row && row.description
                                ? row.description
                                : "",
                          }}
                        ></span>
                        <Tooltip title="Account settings">
                          <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={
                              threedots ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={threedots ? "true" : undefined}
                          >
                            <img
                              src={"/img/Menu Icon.png"}
                              height="21.62px"
                              width="4.63px"
                              alt="img"
                            />
                          </IconButton>
                        </Tooltip>
                        {/* <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={threedots}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
               elevation: 0,
               sx: {
                width: "150px",
                overflow: "visible",
                boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                mt: 2.5,
                "& .MuiAvatar-root": {
                 width: "120px",
                 height: "132px",
                 ml: 0.5,
                 mr: 1,
                 // mt:5
                },
                "&:before": {
                 content: '""',
                 display: "block",
                 position: "absolute",
                 top: 0,
                 // horizontal: 'center',
                 right: 62,
                 width: 10,
                 height: 10,
                 bgcolor: "background.paper",
                 transform: "translateY(-50%) rotate(45deg)",
                 zIndex: 0,
                },
               },
              }}
              transformOrigin={{ horizontal: "center", vertical: "right" }}
              anchorOrigin={{ horizontal: "left", vertical: "center" }}>
              <MenuItem
               sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                lineHeight: "12px",
                fontWeight: "400",
                fontFamily: "DM Sans",
               }}
               value="addfeature"
               onClick={handlePopup}>
               Add Featured Artist
              </MenuItem>
              <Divider />
              <MenuItem
               sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                lineHeight: "12px",
                fontWeight: "400",
                fontFamily: "DM Sans",
               }}>
               Reconmmended
              </MenuItem>
              <Divider />
              <MenuItem
               sx={{
                display: "flex",
                justifyContent: "center",
                fontSize: "16px",
                lineHeight: "12px",
                fontWeight: "400",
                fontFamily: "DM Sans",
               }}>
               Block
              </MenuItem>
              <Divider />
              <MenuItem
               sx={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                fontSize: "16px",
                lineHeight: "12px",
                fontWeight: "400",
                fontFamily: "DM Sans",
               }}>
               Unblock
              </MenuItem>
             </Menu> */}
                      </div>
                    </TableCell>
                  ) : props.activetab === "testimonial" || "reports" ? (
                    <TableCell
                      sx={{
                        width: "25%",
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left"
                    >
                      <div className={styles.auction_last_col}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html:
                              row &&
                              row.description &&
                              row.description.length > 100
                                ? row.description.substring(0, 100) + "..."
                                : row && row.description
                                ? row.description
                                : "",
                          }}
                        ></span>
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        whiteSpace: "nowrap",
                      }}
                      className={styles.table_columns}
                      align="left"
                    >
                      <div className={styles.auction_last_col}>
                        <span> {row.like}</span>
                        <Button
                          className={styles.add_btn}
                          sx={{
                            alignSelf: "right",
                            minWidth: "80px",
                            height: "34px",
                          }}
                          variant="contained"
                        >
                          Add
                        </Button>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            )}
          </TableBody>
          {/* )} */}
        </Table>
      </TableContainer>
    </div>
  )
}
