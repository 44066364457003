import React, { useEffect, useState } from "react";
import styles from "./ArtistType.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AlertsSuccess } from "../../../../../common/alert/Alert";
import { editArtistType, fetchArtistTypes, handleAddartistType } from "../../../../../State/api/superadminApi";
import Tableheader from "../../Table/Tableheader/Tableheader";
import Tabletwocol from "../../Table/Twocol/Tabletwocol";
import Addartistype from "./addartistType/Addartistype";

const head = [
  {
    id: 1,
    label: "ID",
  },
  {
    id: 2,
    label: "Artist Type Name",
  },
];
export default function Artisttype() {
  const [button, setButton] = useState({ type: "add", text: "Add" });
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editdata, setEditdata] = useState(null);
  const dispatch = useDispatch();
  const artisttypedata = useSelector((state) => state.artisttypedata.data);
  console.log(artisttypedata, ":artistype");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(fetchArtistTypes());
  };

  const handleSubmitAdd = (name) => {
    dispatch(handleAddartistType({ name }, setAdd, fetchData));
  };

  const handleSubmitEdit = (edit) => {
    dispatch(
      editArtistType(
        editdata._id,
        { name: edit },
        setEdit,
        fetchData
      )
    );
  };
  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <div className={styles.components}>
        {add ? (
          <Addartistype
            handleSubmitAdd={handleSubmitAdd}
            heading="Add New Artist Type"
            type="add"
            fetchData={fetchData}
            open={add}
            setOpen={setAdd}
          />
        ) : (
          ""
        )}
        {edit ? (
          <Addartistype
            handleSubmitEdit={handleSubmitEdit}
            heading="Edit Artist Type"
            editdata={editdata}
            type=""
            fetchData={fetchData}
            open={edit}
            setOpen={setEdit}
          />
        ) : (
          ""
        )}
        <Tableheader button={button} setOpen={setAdd} />
        <Tabletwocol
          setEditdata={setEditdata}
          setOpen={setEdit}
          activetab="artisttype"
          head={head}
          data={artisttypedata}
        />
      </div>
    </div>
  );
}
