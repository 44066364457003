import React, { useState } from "react";
// import './Login.css'
import styles from "./Resetpass.module.css";
import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import homeImage from "../../../constant";
import { handleResePass } from "../../../State/api/superadminApi";
import { useDispatch } from "react-redux";
import { Errornotify } from "../../../common/alert/Alert";
import { useNavigate } from "react-router-dom";
export default function Resetpass() {
  const [user, setUser] = useState({ password: "", confirm: "" });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    setUser({
      ...user,
      [name]: val.trim(" "),
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password !== user.confirm) {
      Errornotify("Please Enter same password");
    } else if (user.password.length < 8) {
      Errornotify("Please enter minimum eight digit password");
    } else {
      dispatch(
        handleResePass(
          {
            password: user.password,
            email: localStorage.getItem("art_mail_email"),
            role: localStorage.getItem("art_mail_role"),
          },
          navigate
        )
      );
    }
  };

  return (
    <div className={styles.reset_main}>
      <div className={styles.left_main}>
        <img
          alt="img"
          src={ "/img/Login Image.png"}
          height="60%"
          width="50%"
          className={styles.img_login}
        />
      </div>
      <div className={styles.right_main_reset}>
        <div className={styles.reset_center}>
          <div>
            <img
              alt="img"
              onClick={() => navigate("/user/")}
              src={
                 
                "/img/updated_logo.png"
              }
              className={styles.img_forgot}
            />
            <h2 className={styles.heading_reset}>Reset Password</h2>
          </div>
          <div>
            <span className={styles.para_span_otp} >
              <span className={styles.para_spa} style={{fontWeight:"700"}}>
                {localStorage.getItem("art_mail_email")}
              </span>
            </span>
            <br />
            <span className={styles.opt_text_reset}>
              {" "}
              Enter the new password
            </span>
          </div>
          <form className={styles.forgot_form_login} onSubmit={handleSubmit}>
            <span className={styles.span_input_reset}>New Password</span>
            <br />
            {/* <input className={styles.input_reset' value={user.newpass} name='mobile' onChange={handleChange} placeholder='Enter your Password' type='number' /><br /> */}
            <TextField
              sx={{
                color: "#636365",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0.7px solid #D0D0D0",
                },
                "& .MuiInputBase-root": {
                  height: "52px",
                  borderRadius: "8px",
                },
              }}
              inputProps={{
                  style: {
                    height: 1,
                    fontSize: "12px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
              id="outlined-basic"
              value={user.newpass}
              name="password"
              onChange={handleChange}
              placeholder="Enter your Password"
              type="text"
              fullWidth
              variant="outlined"
            />
            <span className={styles.span_input_reset}>Confirm Password</span>
            <br />
            {/* <input className={styles.input_reset' value={user.confirm} name='mobile' onChange={handleChange} placeholder='Confirm Password' type='number' /><br /> */}
            <TextField
              sx={{
                color: "#636365",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "0.7px solid #D0D0D0",
                },
                "& .MuiInputBase-root": {
                  height: "52px",
                  borderRadius: "8px",
                },
              }}
              inputProps={{
                  style: {
                    height: 1,
                    fontSize: "12px",
                    fontWeight: "DM Sans",
                    lineHeight: "14px",
                    letter: "1%",
                    wordSpacing: "0.5px",
                  },
                }}
              id="outlined-basic"
              value={user.confirm}
              name="confirm"
              onChange={handleChange}
              placeholder="Confirm Password"
              type="text"
              fullWidth
              variant="outlined"
              error={user.password !== user.confirm}
            />

            <Box className={styles.reset_btn}>
              <Button
                variant="contained"
                sx={{borderRadius:2, height: "55px", my: 2,lineHeight:"26px",fontSize:"20px" }}
                fullWidth
                className={styles.opt_btn}
                type="submit"
              >
                Continue
              </Button>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
}
