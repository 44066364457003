import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const subscriptionArtistSlice = createSlice({
  name: "subArtistData",
  initialState,
  reducers: {
    setsubscriptionArtist: (state, action) => {
      state.data = action.payload
    },
    // prevPage: (state, action) => {
    //     state.pagination = action.payload;
    // }
  },
})

export const { setsubscriptionArtist } = subscriptionArtistSlice.actions

export const subscriptionArtistData = (state) => state.subscriptionArtistData

export default subscriptionArtistSlice.reducer
