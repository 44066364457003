import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./UserAuctions.module.css";
import Pagination from "../../../List/Pagination/Pagination";
import SevencolumnTable from "../Tables/SevencolumnTable";
import Tableheader from "../../../List/Table/Tableheader/Tableheader";

const head = [
  {
    id: "id",
    label: "Sku Id",
  },
  {
    id: "artist_name",
    label: "Auction name",
  },
  {
    id: "mobile_number",
    label: "Start Date",
  },
  {
    id: "email",
    label: "End Date",
  },
  {
    id: "assign_to",
    label: "Win Bid",
  },
  {
    id: "type",
    label: "Results",
  },
  {
    id: "action",
    label: "Action",
  },
];
const data = [
  {
    id: 1,
    sku_id: '#123456 ',
    title: 'John David',
    start_date: '01-05-2022',
    end_date: '02-09-2022 at 8PM',
    bid: '$2000',
    result: true,
    action: "Checkout"
  },

  {
    id: 2,
    sku_id: '#123456 ',
    title: 'John David',
    start_date: '01-05-2022',
    end_date: '02-09-2022 at 8PM',
    bid: '$2000',
    result: false,
    action: "Checkout"
  },
  {
    id: 3,
    sku_id: '#123456 ',
    title: 'John David',
    start_date: '01-05-2022',
    end_date: '02-09-2022 at 8PM',
    bid: '$2000',
    result: false,
    action: "Checkout"
  },
  {
    id: 4,
    sku_id: '#123456 ',
    title: 'John David',
    start_date: '01-05-2022',
    end_date: '02-09-2022 at 8PM',
    bid: '$2000',
    result: true,
    action: "Checkout"
  },
  {
    id: 5,
    sku_id: '#123456 ',
    title: 'John David',
    start_date: '01-05-2022',
    end_date: '02-09-2022 at 8PM',
    bid: '$2000',
    result: false,
    action: "Checkout"
  },
]
  

export default function UserAuctions() {
  
  return (
    <div className={styles.mains}>      
      <div className={styles.components}>
        <Tableheader />
        <SevencolumnTable
            head={head}
            data={data}
          />
        
        <Pagination/>
      </div>
    </div>
  );
}
