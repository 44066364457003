import React from "react"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { getSubscriptionArtistByid } from "../../../../State/api/superadminApi"
import { Box, Table } from "@mui/material"
import styles from "./SubscriptionArtistlist.module.css"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import CircularLoader from "../../../../common/loader/Loader"
// import serverimage from "../../../../../serverConstant"
import { useNavigate } from "react-router-dom"
//
import serverimage from "../../../../serverConstant"
export default function SubscriptionArtist(props) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleProfile = (id) => {
    navigate(`/earnings/${id}`)
  }
  const loader = useSelector((state) => state.loader)
  const careerDetailsdata = useSelector((state) => state.careerDetailsdata.data)

  const subscriptionArtistData = useSelector(
    (state) => state.subscriptionArtistData.data
  )
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
  }

  const head = [
    {
      id: 1,
      label: "Name",
    },
    {
      id: 2,
      label: "Email",
    },
    {
      id: 3,
      label: "Subscription Name",
    },
    {
      id: 4,
      label: "End Date",
    },
  ]

  const fetchCareerdetails = () => {
    dispatch(getSubscriptionArtistByid(id))
  }
  React.useEffect(() => {
    fetchCareerdetails()
  }, [id])
  return (
    <div>
      <h2>Artist list</h2>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "#F6F6F6", color: "black" }}
      >
        <Table
          sx={{ minWidth: 700, borderRadius: "0px" }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow sx={{ backgroundColor: "#bfbbc3", borderRadius: "none" }}>
              {head.map((column, index) => (
                <TableCell
                  align={index === 0 ? "center" : "start"}
                  key={column.id}
                  sx={{
                    whiteSpace: "nowrap",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                    height: "21px",
                    color: "#1C1C1C",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loader.loader ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={8}>
                  <Box className={styles.loader_box}>
                    {" "}
                    <CircularLoader size={60} />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {subscriptionArtistData &&
              subscriptionArtistData?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box className={styles.loader_box}> No Data Found</Box>
                  </TableCell>
                </TableRow>
              ) : (
                subscriptionArtistData &&
                subscriptionArtistData?.map((row) => (
                  <TableRow
                    className={styles.table_row}
                    key={row.id}
                    style={{
                      color: "#636365",
                      backgroundColor:
                        props.poptype === "add" ? "white" : "#F6F6F6",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                        cursor: "pointer",
                      }}
                      className={styles.table_column}
                      onClick={() => navigate(`/artist/${row.artist?._id}`)}
                      align="center"
                    >
                      {`${row.artist.first_name} ${row.artist.last_name}`}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row.artist?.email}
                    </TableCell>

                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {/* {row?.attachement} */}
                      {row?.name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "DM Sans",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#636365",
                      }}
                      className={styles.table_column}
                      align="center"
                    >
                      {row?.expiry_date ? formatDate(row.expiry_date) : ""}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}
