import axios from 'axios';
import React, { useEffect } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import CustomButton from './CustomButton';
import { Errornotify, Successnotify } from '../../common/alert/Alert';
import "./stripe.css"
import { placeOrderApi } from '../../State/api/orderApi';
import { useDispatch } from 'react-redux';



const StripeDetail = (props) => {
const dispatch = useDispatch()
useEffect(() => {
  localStorage.setItem("payment_method", JSON.stringify("Stripe"))
  return () => localStorage.removeItem("payment_method")
  },[])
    const CustomStripeButton = ({ label, onClick }) => (
        <CustomButton label={label} onClick={onClick} />
      );
      
 
    const price = 600
    // const publishableKey = process.env.STRIPE_PUBLIC_KEY
    const publishableKey = 'pk_test_51MA4mcIyO4WKZxxFhHb522uxoxPhVIu2jlcq6N9otLQLopPNUWVG6YFIPSZRc8ys9O8f5fM5kxKpA3wwm4K8ZIKe000RNSmrpp'
    // const [product, setProduct] = useState({
    //     name: 'Mobile',
    //     price: 5,
    // });
    // const priceForStripe = product.price * 100;

  const tokenUser =    localStorage.getItem("login_token");
    const amount = localStorage.getItem('total_amount'); // Convert to cents
    const payNow = async token => {
        // const userid = localStorage.getItem('user_id')
        // const userToken = localStorage.getItem('login_token');
        // const secretKey = "sk_test_51MA4mcIyO4WKZxxF3psl8XUHpyfyimA85458P1G3rALV6Rxot6KKiHFAjwo8GTiBypg6RwmQzWCRtLfCBxVAh6C400ubPkD5sT"
        try {
            // const response = await axios({
            //     url: 'http://192.168.29.154:8000/api/user/payment',
            //     // url :'http://localhost:5000/payment',
            //     headers: {
            //         'Authorization': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2NjQ3MDlhZTQxODJhZDJiN2Q4MDc5MzUiLCJpYXQiOjE3MTU5MzE2NDYsImV4cCI6MTcxNjUzNjQ0Nn0.C2Uq_hLUppFggDrWkxCfLx53_ypMZy9FU3AWLm3JctA`,
            //         'Content-Type': 'application/json'
            //     },
            //     method: 'post',
            //     data: {
            //         token: token?.id,
            //         order_id: "664459f6c48d0bbeabebf702",
            //         amount: amount,
            //         paymentType: "stripe",
            //         // detail: {
            //         //     user_id: userid,
            //         //     item_id: props.cart_item_id,
            //         // },

            //     },
            // });
            if(token) {
                
              dispatch(placeOrderApi(props.address))
             }else {
               Errornotify("Payment Failed");
             }
                // Successnotify("Payment is Successful")
                
                
         
        } catch (error) {
        }
    };


    return (
   
            <StripeCheckout
             stripeKey={publishableKey} 
                label="Pay With Stripe"
                name="Pay With Credit Card"
                billingAddress
                shippingAddress
                amount={amount * 100}
                description={`Your total is $${amount}`}
                token={payNow}
                ComponentClass="div">
                    <CustomStripeButton label="Stripe" />
                </StripeCheckout>
                
            
    
    );
}

export default StripeDetail;
