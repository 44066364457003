import React, { useEffect, useRef, useState } from "react";
import styles from "./Uploadpic.module.css";
import homeImage from "../../../../../constant";
import serverimage from "../../../../../serverConstant";
import { Button } from "@mui/material";
export default function uploadPic(props) {
  console.log(props.src,'src')
  
    return (
        <div className={styles.preview_img}>
            {props.src === "" ? (
                <div className={styles.img_box}>
                <img
                           src={"/img/dummy-profile.png"}
                            alt="imgs"
                            id="img"

                            className={props.src ? styles.img_view : styles.img_view_hide}
                        />
                </div>
            ) : (
                <>
                    <input accept='image/*' onChange={props.handleChangeImage} type="file" id={props.name} name={props.name} hidden />
                    <label htmlFor={props.name}>
                        <img
                         src={ props.src && props.src.startsWith('blob:')
  ? props.src
  : props.src ?  props.src : ( "/img/dummy-profile.png")}
                            alt="img"
                            id="img"

                            className={props.src ? styles.img_view : styles.img_view_hide}
                        />
                    </label>
                </>
            )
            }
            <div className={styles.file_div}></div>
     
        </div >
    );
}
