import { FormControl, MenuItem, Select } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchEnquiryData } from "../../../../State/api/superadminApi"
import Tables from "../Table/sixcolumn/Tables"
import styles from "./Enquiries.module.css"

const exhibitionhead = [
  {
    id: "artist_name ",
    label: "Inquiry ID",
  },
  {
    id: "mobile_number",
    label: "Customer Name",
  },
  {
    id: "email",
    label: "Inquiry Type",
  },
  {
    id: "assign_to",
    label: "Descrtipion",
  },
  {
    id: "type",
    label: "Assign to",
  },
  {
    id: "action",
    label: "Status",
  },
]
const exhibitionData = [
  {
    id: 1,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: true,
  },

  {
    id: 2,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: true,
  },
  {
    id: 3,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: false,
  },
  {
    id: 4,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: true,
  },
  {
    id: 5,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: false,
  },
  {
    id: 6,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: false,
  },
  {
    id: 7,
    artist_name: "#123456 ",
    mobile_no: "John David",
    email: "Paintings",
    assign_to:
      " erging artist erging artist vv erging artist erging artist erging artist erging artist erging artistGabrial ",
    type: "Emerging artist",
    action: true,
  },
]

export default function Enquiries() {
  const [age, setAge] = React.useState("")
  const [data, setData] = useState({ offset: 0, is_open: null })
  const dispatch = useDispatch()
  const enquirydata = useSelector((state) => state.enquirydata)

  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const fetchData = () => {
    dispatch(fetchEnquiryData(data))
  }
  useEffect(() => {
    fetchData()
  }, [data])

  return (
    <div className={styles.mains}>
      <div className={styles.components}>
        <div className={styles.table_header}>
          <FormControl>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ height: "44px", minWidth: "150px", color: "#636365" }}
              className={styles.selects}
            >
              <MenuItem value="">Sort by</MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Tables
          activetab="enquiry"
          head={exhibitionhead}
          data={enquirydata.data}
        />
      </div>
    </div>
  )
}
