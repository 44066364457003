import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import RemoveIcon from "@mui/icons-material/Remove";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../alert/Alert";

const data = [
  {
    id: 1,
    heading: "Worldwide Networking Prospects",
    content:
      "Tap into a vast network of international art collectors, and curators.We foster meaningful connections and collaborations that transcend geographical boundaries.",
  },
  {
    id: 2,
    heading: "Curated Art Events",
    content:
      "Benefit from our exclusive, physical and virtual exhibitions and auctions that spotlight unique artworks that attract art lovers, maximizing engagement and interest.",
  },
  {
    id: 3,
    heading: "Accelerate Sales",
    content:
      "Boost your sales by showcasing your work to a larger, targeted audience. Our platform's expansive reach connects you with serious buyers, helping convert interest into sales.",
  },
];
const service_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Exhibitions and Auctions",
    des: "As a registered gallery on Art Smiley, gain access to exclusive exhibitions and auctions that elevate your artists' visibility. Connect with global collectors and art enthusiasts, enhancing exposure and growth.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Highlighting Recommended Artworks",
    des: "Art Smiley proudly showcases a selection of trending and top-rated artworks from our partnered galleries, recognizing outstanding talent within the community.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Art Community",
    des: "Showcase your artists alongside other distinguished galleries while gaining access to valuable insights on emerging trends through articles, magazines, catalogs, and artist interviews.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Digital Promotion",
    des: "Art Smiley offers digital galleries and virtual tours, expanding reach to a global audience. Our team promotes exhibitions and auctions, using social media to attract art enthusiasts and boost visibility.",
  },
];
const gallery_subscription_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Public Profile",
    des: "Display your creations online and grab the membership of the Art Smiley community.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Custom Dashboard",
    des: "Seamlessly manage your collection and keep track of artworks, exhibitions, orders, and client inquiries.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "SSL Protection",
    des: "Ensure the safety of your personal information with SSL encryption.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "24/7 Support",
    des: "Access help whenever you need assistance.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Upload Up to 20 Pieces",
    des: "Start sharing your work with ease.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Engagement Metrics",
    des: "Analyze views, likes, follows, and reviews to gauge audience interaction.",
  },
];
const standard_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload Up to 200 Pieces",
    des: "Expand your portfolio and connect with a broader audience.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Feature on Homepage",
    des: "Gain significant visibility with a one-time spotlight on Art Smiley's homepage.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Virtual Event Participation",
    des: "Network with other artists and collectors by showcasing up to three works at any one of the Art Smiley virtual events.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Unique Marketing Opportunities",
    des: "One-time exclusive promotion of  selected artwork across social media platforms.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Pricing and Marketing Insights",
    des: "Access essential guidance to help you make strategic pricing and marketing decisions.",
  },
];
const premium_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload Up to 500 Pieces",
    des: "Present your full range of creative work.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Highlighted Profile on Homepage",
    des: "Gain enhanced visibility within the artistic community with a homepage feature.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Expanded Virtual Event Participation",
    des: "Enjoy exclusive networking opportunities with two time participation up to three works for each Art Smiley virtual event.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Broader Marketing Support",
    des: "Benefit from two time exclusive promotion of artworks across all Art Smiley social media channels.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Video Showcase for YouTube & Social Media",
    des: "Promote your artistry with a professionally produced video by our team or share your own production.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Special Exhibition Invitations",
    des: "Receive discounts and first-access opportunities for all Art Smiley-related exhibitions.",
  },
  {
    id: 7,
    src: "/img/service_artist_1",
    heading: "Guest Blog Features",
    des: "Share your artistic journey and insights through guest posts on Art Smiley's blog.",
  },
  {
    id: 8,
    src: "/img/service_artist_1",
    heading: "Solo Online Exhibition with Annual Plan",
    des: "Enjoy a dedicated virtual exhibition highlighting your artwork if you select the annual Premium Subscription.",
  },
];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const FAQs = [
  {
    sequence: 1,
    question: "How do I sign up as a gallery on ArtSmiley?",
    answer:
      "Begin your journey by registering your gallery on the Art Smiley platform. Make sure to fill all the mandatory fields with the right information. Once your application has been reviewed and approved, you will receive a confirmation email to set up your password, granting you access to all available features and services.",
  },
  {
    sequence: 2,
    question: "How can I upload the artwork?",
    answer:
      "Begin by logging into your dashboard, navigating to the 'Upload Arts' section, and submitting your artwork with all the necessary details.",
  },
  {
    sequence: 3,
    question: "How long does the application review process take?",
    answer:
      "The review process for your gallery account typically takes 3 to 4 weeks. We will notify you once the review is complete and guide you through the subsequent steps.",
  },
  {
    sequence: 4,
    question: "Can I explore the platform while waiting for my account to be approved?",
    answer:
      "Yes, you can explore the features of Art Smiley while your application is under review. Take this time to familiarize yourself with the platform.",
  },
  {
    sequence: 5,
    question: "What should I do if my application is not approved?",
    answer:
      "If your application is not approved, we encourage you to enhance your profile and consider reapplying after six months. Our team is happy to offer feedback and assistance to help you improve your chances of approval in the future.",
  },
  {
    sequence: 6,
    question: "What information should I include in my gallery's portfolio?",
    answer:
      "Your portfolio should detail your gallery’s history, including artworks, exhibitions, awards, and other recognitions. Be sure to include the names, years, and locations of these experiences. You will also be asked to upload your gallery’s artist artworks.",
  },
  {
    sequence: 7,
    question: "How many years of experience should I have to join Art Smiley?",
    answer:
      "There is no minimum requirement for years of experience, but you will need to specify how many years your gallery has been operating during the sign-up process.",
  },
  {
    sequence: 8,
    question: "Can I join Art Smiley if my gallery is represented by other online platforms?",
    answer:
      "Yes, you can. During the onboarding process, you will be asked to provide the URL links to other online platforms where your gallery is represented.",
  },
  {
    sequence: 9,
    question: "Do I need to have an online portfolio or social media presence?",
    answer:
      "No, including an online portfolio and social media links such as LinkedIn, Facebook, Instagram, Twitter, and YouTube is not mandatory as part of the onboarding process. However, providing these links can help us better assess your gallery’s online visibility and engagement.",
  },
  {
    sequence: 10,
    question: "What benefits do I receive as a registered gallery on Art Smiley?",
    answer:
      "As a registered gallery, you will have access to exclusive virtual auctions and exhibitions, to promote your artwork, networking opportunities, and insights/analytics to track your artwork’s performance.",
  },
  {
    sequence: 11,
    question: "How can I stay updated with the latest news and opportunities on ArtSmiley?",
    answer:
      "We invite you to subscribe to our newsletter to receive exclusive updates, special offers, and the latest news about new collections and events. You can manage your preferences or unsubscribe at any time.",
  },
  {
    sequence: 12,
    question: "What is the payment process for galleries?",
    answer:
      "As a gallery, you will receive your payment for artwork through flexible options. Payments will be processed and transferred to your account within 3 to 4 weeks following the successful delivery of the artwork. You can choose your payment either through Paypal or direct bank transfer.",
  },
  {
    sequence: 13,
    question: "What is the return policy for sold artwork?",
    answer:
      "If the buyer wishes to return the artwork, Art Smiley will manage the return process. In such cases, we will coordinate with you on how to handle the return and refund process.[return policy link]",
  },
  {
    sequence: 14,
    question: "What Types of Artwork Can Galleries Offer on Art Smiley?",
    answer:
      "Art Smiley welcomes a diverse range of artwork from galleries, providing a platform for various creative expressions. Accepted works include original paintings, illustrations, photography, digital artwork, and sculptures. Galleries can also list both open and limited edition prints, as well as unique handcrafted items or mixed media pieces. This wide range of accepted formats allows galleries to showcase their unique collections and reach a broader audience through Art Smiley.",
  },
  {
    sequence: 15,
    question: "What is the process for shipping artwork?",
    answer:
      "When a piece is sold, galleries receive an email with all order details. By logging into your dashboard, accept the artwork order and arrange a pickup with Art Smiley’s designated courier service. Shipping labels are available for download within the dashboard, ready to be printed and affixed to the package. Buyers are responsible for covering shipping fees; however, galleries need to ensure the artwork is packaged according to Art Smiley’s guidelines. Detailed packaging instructions are available here[packaging guidelines link].",
  },
  {
    sequence: 16,
    question: "What are the best practices for packaging artwork?",
    answer:
      "Galleries should use packaging tailored to the specific type of artwork to ensure safe transport. Flat pack boxes are ideal for small framed pieces, such as prints and photographs. For rolled canvases, tube packaging provides solid protection against bending. Larger or fragile items, like sculptures or oversized framed artworks, should be shipped in custom wooden crates for maximum safety. Regardless of the chosen packaging method, all items should be wrapped in bubble wrap or foam to prevent scratches and damage. For more detailed instructions, please refer to our packaging guidelines here [packaging guidelines link].",
  },
  {
    sequence: 17,
    question: "How does Art Smiley’s commission structure work for gallery sales?",
    answer:
      "Art Smiley has a structured commission model tailored to different types of gallery transactions: Sales: For each artwork sold, Art Smiley retains a commission of [dynamic value%], with the gallery keeping the remaining [dynamic value%]. Rentals: In cases where artwork is rented, Art Smiley charges a [dynamic value%] commission, allowing the gallery to keep [dynamic value%] of the rental revenue. Print Sales: For any sales of art prints, Art Smiley deducts a [dynamic value%] commission, with the remaining [dynamic value%] going to the gallery.",
  },
  {
    sequence: 18,
    question: "How Does the Artwork Rental Program Work for Galleries on Art Smiley?",
    answer:
      "Art Smiley’s artwork rental service allows galleries to offer their collections on a temporary basis, providing clients with the chance to experience art in their spaces without a long-term commitment. Galleries can set a monthly rental rate for each piece, which enables clients to enjoy the artwork for a specified period. Should a client decide to purchase the piece, 50% of each rental payment they’ve made can be credited toward the final sale price. This program not only generates recurring revenue for galleries but also enhances accessibility for art lovers exploring different pieces before committing to a purchase. Art Smiley retains a [dynamic value%] commission on each rental, with the rest going to the gallery.",
  },
  {
    sequence: 19,
    question: "How Will I Be Notified When My Artwork is Sold?",
    answer:
      "When one of your artworks sells on Art Smiley, you’ll receive an email notification with all the relevant details. Simply log in to your gallery dashboard to confirm the order and arrange for shipment. After accepting the order, schedule a pickup date and prepare the artwork for safe transport. For rolled canvases, package the piece securely by wrapping it in bubble wrap and placing it in a durable tube sealed at both ends. For framed pieces, flat-pack boxes are recommended to prevent bending or creasing. You can monitor the shipment status through your dashboard. Once the buyer confirms receipt and the 14-day return period has elapsed, Art Smiley will process your payment within 3 to 4 weeks.",
  },
  {
    sequence: 20,
    question: "How Should I Price My Original Works?",
    answer:
      "When pricing original pieces on Art Smiley, it's essential to consider both your target earnings and the platform's commission rate, currently set at [dynamic value%]. Make sure your prices incorporate this commission, allowing you to reach your financial goals. Thoughtfully pricing your work will ensure competitive rates on the platform while aligning with your gallery’s revenue objectives.",
  },
  {
    sequence: 21,
    question: "What Are Limited Edition Prints?",
    answer:
      "Limited edition prints are exclusive reproductions of an artwork, produced in a set, finite quantity. Each print in the edition is typically signed and numbered, enhancing its authenticity and collectible value. The limited availability of these prints adds a sense of rarity, making them particularly appealing to collectors and art enthusiasts. This exclusivity not only increases their desirability but also often contributes to a higher long-term value compared to open editions, offering a unique opportunity to own a piece of art that holds both artistic and investment appeal.",
  },
  {
    sequence: 22,
    question: "What Are Open Edition Prints?",
    answer:
      "Open edition prints are art reproductions that are not limited in quantity, there are no limitations on the number of copies that can be produced. Unlike limited edition prints, which are released in restricted numbers, open editions are continuously available, allowing for broader accessibility. This unlimited approach makes the artwork more affordable and reachable to a wider audience, providing art lovers with a high-quality reproduction that captures the original's essence without the exclusivity tied to limited editions.",
  },
  // {
  //   sequence: 23,
  //   question: "What is Art Smiley's refund policy?",
  //   answer:
  //     "Art Smiley handles all refund requests directly with the buyers. Refunds are initiated only under specific conditions, such as damage during transit or non-receipt of the item. In the event of a refund, Art Smiley manages the process from buyer notification to reimbursement. Galleries are notified promptly if an item is eligible for return or refund. However, refunds are not issued for items sold under no-return policies, or for custom or specially commissioned pieces.",
  // },
  // {
  //   sequence: 24,
  //   question: "Can Galleries Use Art Smiley's Platform to Promote Upcoming Exhibitions or Events?",
  //   answer:
  //     "Art Smiley provides galleries with a dedicated space to promote their events and exhibitions. This includes upcoming gallery showcases, curated exhibits, and special art events. Galleries can also take advantage of the platform’s marketing services, such as social media promotions and newsletters, to reach a wider audience and attract art enthusiasts to their events. Additionally, featuring events on Art Smiley allows galleries to connect with collectors, patrons, and art lovers worldwide.",
  // },
];



function HomeGallery() {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/gallery/fillquestion")
    // }
    navigate("/artsmiley/gallery/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
       
          <Box
            className={styles.hero_main_gallery}
            sx={{ positions: "relative", width:"100%",height:"100%" }}
          >
            <div className={styles.hero_heading}>
              <h1>
              Showcase your gallery's unique collection with Art Smiley. 
                <br />
                Join us to reach art lovers!
              </h1>
            </div>
            <Typography
              sx={{
                color:"white",
                fontSize: "16px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                lineHeight: "30px",
                textAlign: "center",
              }}
              component="div"
            >
      Join our gallery family and unlock new opportunities for exposure.<br/>
      Partner with Art Smiley to connect with art buyers and collectors.<br/>
      oin our artistic community. Register with Art Smiley to connect with a broader audience of art enthusiasts!
            </Typography>
            <Button
              onClick={handleSignupButton}
              variant="contained"
              sx={{
                width: "158px",
                height: "63px",
                borderRadius: "9px",
                backgroundColor: "white",
                color: "black",
              }}
              className={styles.hero_heaing_button}
            >
              Apply
            </Button>
          </Box>
          <Box
            className={styles.hero_main1}
            sx={{ positions: "relative" }}
          >
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
              }}
              component="div"
            >
           Join Our Innovative Gallery Art Network
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
              }}
              component="div"
            >
      Be a Part of Visionary Gallery Community <br/>
Connect with Dynamic Gallery Network <br/>
Become a Part of Thriving Gallery Community
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
              }}
              component="div"
            >
      Showcase your Gallery artwork in front of art enthusiasts looking to discover emerging and established talents. <br /> Experience the benefits of reaching art lovers beyond your current network.
            </Typography>
            <div className={styles.hero_card_gallery}>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "300px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                {/* <Avatar className={styles.Avatar1} >s</Avatar> */}
                <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                  Targeted Marketing
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
Art Smiley collaborates with galleries to create focused campaigns that connect with buyers interested in each gallery's unique artwork styles.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                 <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
               Global Visibility
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
           Art Smiley helps galleries expand their reach beyond in-gallery visits. By showcasing the artwork over a global audience, we increase the potential of art sales and open up new opportunities.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                 <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                Fair Earnings
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
Art Smiley charges a commission of 0% on each sale. As a registered gallery, you maintain full pricing control for your artists' works, allowing you to set prices that ensure fair earnings.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
          {/* <Box className={styles.benifits_box}>
                        <Box className={styles.benifits_card}>
                            h
                            <div className={styles.Avatar_ligth} >
                                <div className={styles.Avatar4}>04</div>
                            </div>
                            <h3 className={styles.benifits_head}>Best Earn</h3>
                            <p className={styles.benifits_des}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </Box>
                        <div className={styles.dot_line}></div>
                    </Box> */}
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
              }}
            >
         Ascend to New Achievements
            </Typography>
            <Box className={styles.hero_main8}>
              <Box className={styles.hero_main8_image}>
                <img src={"/img/Mockup.png"} alt="mockup" />
              </Box>
            </Box>
            <Box sx={{ width: "100%" }}>
              <div className={styles.cards_div}>
                <div className={styles.card_div}>
                  {data.map((val) => (
                    <div key={val.id} className={styles.cards}>
                      <div className={styles.round_img_div}>
                        <img
                          alt="img"
                          src={"/img/color-palette.png"}
                          width="25px"
                          height="31px"
                        />
                      </div>
                      <h4 className={styles.headings}>{val.heading}</h4>
                      <p className={styles.contents}>{val.content}</p>
                    </div>
                  ))}
                </div>
                <div className={styles.btn_div}>
                  <Button
                    variant="contained"
                    onClick={handleSignupButton}
                    sx={{
                      width: "156px",
                      height: "54px",
                      borderRadius: "10px",
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
         Power Up Your Path to Sales Excellence Registration 
            </Typography>
            {/* <Box> */}
            <div className={styles.hero_card}>
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "300px",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>01</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
                 Process
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
         Register your gallery on the Art Smiley platform to start promoting your artist’s works to a global audience. Once your application is reviewed and approved, you can access all the features.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "300px",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>02</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                  Portfolio Upload
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
              From your personalized gallery dashboard, you can upload artwork of your artists, creating a virtual platform that showcases your unique selection.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "300px",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>03</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
                 Subscription Plans
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
               Start with our complimentary plan and, as your gallery’s reach expands, consider our flexible paid plans designed to meet your gallery's evolving goals and promotional needs.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "300px",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>04</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
                    Smart Dashboard
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
               The comprehensive dashboard allows you to monitor total artwork listings, orders, quote requests, inquiries, exhibitions, auctions, likes, followers, and total earnings, all in one efficient platform. This helps you focus on growth and maximizing visibility.
                  </Typography>
                </CardContent>
              </Card>
            </div>
            {/* </Box> */}
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
              Testimonials
            </Typography>
            <div className={styles.card_div}>
              <div className={styles.card_testimonials}>
                <div>
                  <img
                    alt="img"
                    src={"/img/event3.png"}
                    width="100px"
                    height="100px"
                  />
                </div>
                <div>
                  <h5 className={styles.test_card_heading}>Albert Didier</h5>
                  <h6 className={styles.test_card_subhead}>Euismod ipsum</h6>
                  <h3 className={styles.test_card_des}>
                    In purus at morbi magna in in maecenas. Nunc nulla magna
                    elit, varius phasellus blandit convallis.
                  </h3>
                </div>
              </div>
              <div className={styles.card_testimonials}>
                <div>
                  <img
                    alt="img"
                    src={"/img/event3.png"}
                    width="100px"
                    height="100px"
                  />
                </div>
                <div>
                  <h5 className={styles.test_card_heading}>Albert Didier</h5>
                  <h6 className={styles.test_card_subhead}>Euismod ipsum</h6>
                  <h3 className={styles.test_card_des}>
                    In purus at morbi magna in in maecenas. Nunc nulla magna
                    elit, varius phasellus blandit convallis.
                  </h3>
                </div>
              </div>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
        Transforming Galleries with Art Smiley's Seamless Services
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
                margin:"0px 200px",
              }}
              component="div"
            >
      Art Smiley specializes in empowering art galleries to streamline their curation process and attract diverse audiences. Art Smiley’s services are tailored to meet the unique needs of global clients.
            </Typography>
            <div className={styles.service_card_div}>
              {service_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
        Gallery Subscription Plans
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
                margin:"0px 200px",
              }}
              component="div"
            >
      Advance your creative path with Art Smiley’s flexible subscription tiers, designed to support your unique artistic goals. Choose the plan that resonates with your vision and start presenting your work to a global audience today!
      <br/>
      Basic Subscription: Free
            </Typography>
            <div className={styles.service_card_div}>
              {gallery_subscription_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
        Standard Subscription
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
                margin:"0px 200px",
              }}
              component="div"
            >
      [%Dynamic value of Subscription amount per month] [%Dynamic value of Subscription amount per year]
      <br/>
      Includes all Basic Features plus:
            </Typography>
            <div className={styles.service_card_div}>
              {standard_subscription.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
        Premium Subscription
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
                margin:"0px 200px",
              }}
              component="div"
            >
      [%Dynamic value of Subscription amount per month] [%Dynamic value of Subscription amount per year]
      <br/>
      Includes all Standard Features plus:
            </Typography>
            <div className={styles.service_card_div}>
              {premium_subscription.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main6} sx={{ maxWidth: "1287px" }}>
    {FAQs.map((item) => (
      <Accordion
        key={item.sequence}
        expanded={expanded === `panel${item.sequence}`}
        onChange={handleChange(`panel${item.sequence}`)}
      >
        <AccordionSummary
          expandIcon={
            expanded === `panel${item.sequence}` ? (
              <RemoveIcon  />
            ) : (
              <AddIcon/>
            )
          }
          aria-controls={`panel${item.sequence}bh-content`}
          id={`panel${item.sequence}bh-header`}
          className={styles.accordian_show}
        >
          <div className={styles.show_content}>
            {expanded === `panel${item.sequence}` && <div className={styles.accodian_no}>{item.sequence}</div>}
            <Typography >
              {item.question}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
          <Typography>{item.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
          <Box className={styles.subscribe_banner}>
            <div>
              <img alt="img" src={"img/email.png"} width="80px" height="63px" />
            </div>
            <h5 className={styles.subscribe_head}>
              Subscribe to the art smiley newsletter
            </h5>
            <TextField
              variant="outlined"
              value={email}
              placeholder="Please enter your mail address"
              onChange={handleChangeSubscription}
              sx={{
                width: "400px",
                background: "#3c3c3c",
                // opacity: '0.2',
                border: "1px solid #FFFFFF",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                // "::placeholder": { color: "red" },
                // '&::-webkit-input-placeholder': { color: 'blue' },
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#FFFFFF",
                input: { color: "white" },
                border: "none",
                // opacity: '0.6',
              }}
            />
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                height: "54px",
                width: "131px",
                borderRadius: "6px",
              }}
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
          </Box>
      
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default HomeGallery;
