import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

export const buyerOfferDataSlice = createSlice({
    name: "recreateRequest",
    initialState,
    reducers: {
        setbuyerOfferData: (state, action) => {
            state.data = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setbuyerOfferData } = buyerOfferDataSlice.actions;

export default buyerOfferDataSlice.reducer;