import React, { useState } from 'react';
import styles from './CategoryDetail.module.css'
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories, handleDeletes, handleEdit, handleFetchMaterial, handleFetchMedium, handleFetchStyle, handleFetchSubject } from '../../../../../State/api/superadminApi';
import { Button, IconButton, TextField } from '@mui/material';
import Editcategory from '../../Popupdelete/editCategory/Editcategory';
import { AlertsSuccess } from '../../../../../common/alert/Alert';
import api from '../../../../../State/api/Api';
import CircularLoader from '../../../../../common/loader/Loader';
const CategoryDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [name, setName] = useState({ name: '', _id: "" })
    const [category, setCategory] = useState({ add: false, edit: false });
    // const [data, setData] = useState({ style: null, medium: null, material: null, subject: null })
    const [style, setStyle] = useState(null)
    const [material, setMaterial] = useState(null)
    const [medium, setMedium] = useState(null)
    const [subject, setSubject] = useState(null)

    const loader = useSelector((state) => state.loader);
    const fetchData = () => {
        dispatch(handleFetchStyle({ category_id: id }, setStyle));
    }
    const fetchDataMaterial = () => {
        dispatch(handleFetchMaterial({ category_id: id }, setMaterial));
    }
    const fetchDataSubject = () => {
        dispatch(handleFetchSubject({ category_id: id }, setSubject));
    }
    const fetchDataMedium = () => {
        dispatch(handleFetchMedium({ category_id: id }, setMedium));
    }
    const handleEdits = (type, value, id) => {
        if (type === "edit") {
            setCategory({ ...category, edit: true })
            setName({ ...name, name: value, _id: id });
        } else {
            setCategory({ ...category, add: true })
        }
    }
    const hundleSubmit = (type, id) => {
        switch (type) {
            case 'editMaterial':
                dispatch(handleEdit(`api/itemMaterial/editItemMaterial/${id}`, name, fetchDataMaterial));
                setCategory({ ...category, edit: false });
                break;
            case 'editStyle':
                dispatch(handleEdit(`api/itemStyle/editItemStyle/${id}`, name, fetchData));
                setCategory({ ...category, edit: false });
                break;
            case 'editMedium':
                dispatch(handleEdit(`api/itemMedium/editItemMedium/${id}`, name, fetchDataMedium));
                setCategory({ ...category, edit: false });
                break;
            case 'editSubject':
                dispatch(handleEdit(`api/itemSubject/editItemSubject/${id}`, name, fetchDataSubject));
                setCategory({ ...category, edit: false });
                break;
            default:
                break;
        }
    }
    const handleDelete = (type, id) => {
        switch (type) {
            case 'deleteMaterial':
                dispatch(handleDeletes(`api/itemMaterial/deleteItemMaterial/${id}`, fetchDataMaterial));
                break;
            case 'deleteStyle':
                dispatch(handleDeletes(`api/itemStyle/deleteItemStyle/${id}`, fetchData));
                break;
            case 'deleteMedium':
                dispatch(handleDeletes(`api/itemMedium/deleteItemMedium/${id}`, fetchDataMedium));
                break;
            case 'deleteSubject':
                dispatch(handleDeletes(`api/itemSubject/deleteItemSubject/${id}`, fetchDataSubject));
                break;
            default:
                break;
        }
    }
    const handleChange = (e) => {
        setName({ ...name, name: e.target.value });
    }

    const categoriesData = useSelector((state) => state.categoryData.data);
    useEffect(() => {
        fetchData();
        fetchDataSubject();
        fetchDataMaterial();
        fetchDataMedium();
        dispatch(fetchCategories());
    }, [])
    // useEffect(() => {
    //     fetchDataMaterial();
    // }, [])
    // useEffect(() => {
    //     fetchDataMedium();
    // }, [])
    // useEffect(() => {
    //     fetchDataSubject();
    // }, [])
    return (
        <div className={styles.mains}>
            <AlertsSuccess />
            {/* {category.edit ? (
                <Editcategory type="edit" open={category.edit} fetchData={fetchData} setOpen={setCategory} />
            ) : (
                ""
            )} */}
            <h3>
                Category Name : {categoriesData && categoriesData.filter(va => (va._id === id))[0.].name}
            </h3>
            <hr />
            <div className={styles.detail_main}>
                <div className={styles.detail}>
                    <h3 className={styles.title}>Style</h3>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : style && style.length === 0 ? "------" :
                        style && style.map(val => (
                            <p className={styles.detail_para}>{category.edit && val._id === name._id ? <TextField value={name.name} onChange={handleChange} variant='outlined' type='text'></TextField> : val.name}
                                {category.edit && val._id === name._id ? <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editStyle', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEdits("edit", val.name, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("deleteStyle", val._id)}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </p>
                        ))}
                </div>
                <div>
                    <h3>Material</h3>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : material && material.length === 0 ? "------" :
                        material && material.map(val => (
                            <p className={styles.detail_para}>{category.edit && val._id === name._id ? <TextField value={name.name} onChange={handleChange} variant='outlined' type='text'></TextField> : val.name}
                                {category.edit && val._id === name._id ? <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editMaterial', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEdits("edit", val.name, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("deleteMaterial", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </p>
                        ))}
                </div>
                <div>
                    <h3>Medium</h3>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : medium && medium.length === 0 ? "------" :
                        medium && medium.map(val => (
                            <p className={styles.detail_para}>{category.edit && val._id === name._id ? <TextField value={name.name} onChange={handleChange} variant='outlined' type='text'></TextField> : val.name}
                                {category.edit && val._id === name._id ? <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editMedium', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEdits("edit", val.name, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("deleteMedium", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </p>
                        ))}

                </div>
                <div>
                    <h3>Subject</h3>
                    {loader.loader ? (
                        <CircularLoader size={60} />
                    ) : subject && subject.length === 0 ? "------" :
                        subject && subject.map(val => (
                            <p className={styles.detail_para}>{category.edit && val._id === name._id ? 
                            <TextField value={name.name} onChange={handleChange} variant='outlined' type='text'></TextField>
                             : val.name}
                                {category.edit && val._id === name._id ? <Button variant='' sx={{ m: 'auto' }} onClick={() => hundleSubmit('editSubject', val._id)}>Submit</Button> :
                                    <>
                                        <IconButton
                                            onClick={() => handleEdits("edit", val.name, val._id)}
                                            sx={{ ml: 3 }}
                                        >
                                            <img
                                                src="/img/Edit.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDelete("deleteSubject", val._id)}
                                        // sx={{ ml: 1 }}
                                        >
                                            <img
                                                src="/img/delete.png"
                                                width="20px"
                                                height="20px"
                                                alt='img'
                                            />
                                        </IconButton>
                                    </>
                                }


                            </p>
                        ))}
                </div>
            </div>

        </div>
    );
}

export default CategoryDetail;
