import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const curatorEarningSlice = createSlice({
  name: "curatorEarningdata",
  initialState,
  reducers: {
    setCuratorEarningdata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setCuratorEarningdata } = curatorEarningSlice.actions

export const setCuratorearning = (state) => state.setCuratorEarningdata

export default curatorEarningSlice.reducer
