import React, { useState, useEffect } from "react"
import styles from "./MenuDetail.module.css"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchSubmenuData,
  editSubmenudata,
  deleteSubmenu,
} from "../../../../../State/api/superadminApi"
import { Button, IconButton, TextField } from "@mui/material"
import Editcategory from "../../Popupdelete/editCategory/Editcategory"
import { AlertsSuccess } from "../../../../../common/alert/Alert"
import CircularLoader from "../../../../../common/loader/Loader"

const MenuDetail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [name, setName] = useState({ name: "", link: "", _id: "" })
  const [menu, setMenu] = useState({ add: false, edit: false })

  const loader = useSelector((state) => state.loader)

  const handleEdits = (type, menuName, link, _id) => {
    if (type === "edit") {
      setMenu({ ...menu, edit: true })
      setName({ name: menuName, link, _id })
    } else {
      setMenu({ ...menu, add: true })
    }
  }

  const handleSubmit = (id) => {
    dispatch(editSubmenudata(id, name))
  }

  const handleDelete = (id) => {
    dispatch(deleteSubmenu(id)).then(() => {
      fetchMenuData()
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setName((prevName) => ({ ...prevName, [name]: value }))
  }

  const subMenuByMenuIdData = useSelector(
    (state) => state.MenuBySubMenuData.data
  )

  const fetchMenuData = () => {
    dispatch(fetchSubmenuData(id))
  }

  useEffect(() => {
    fetchMenuData()
  }, [])

  return (
    <div className={styles.mains}>
      <AlertsSuccess />
      <h3>Menu Name: {subMenuByMenuIdData ? subMenuByMenuIdData?.name : ""}</h3>
      <hr />
      <div className={styles.detail_main}>
        <div className={styles.detail}>
          <h3 className={styles.title}>Sub menu</h3>
          {loader.loader ? (
            <CircularLoader size={60} />
          ) : subMenuByMenuIdData &&
            subMenuByMenuIdData.submenus.length === 0 ? (
            "------"
          ) : (
            subMenuByMenuIdData &&
            subMenuByMenuIdData?.submenus?.map((val) => (
              <p className={styles.detail_para} key={val._id}>
                {menu.edit && val._id === name._id ? (
                  <>
                    <TextField
                      value={name.name}
                      name="name"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />
                    <TextField
                      value={name.link}
                      name="link"
                      onChange={handleChange}
                      variant="outlined"
                      type="text"
                    />
                    <Button
                      variant=""
                      sx={{ m: "auto" }}
                      onClick={() => handleSubmit(val._id)}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    {val.name} - {val.link}
                    <IconButton
                      onClick={() =>
                        handleEdits("edit", val.name, val.link, val._id)
                      }
                      sx={{ ml: 3 }}
                    >
                      <img
                        src="/img/Edit.png"
                        width="20px"
                        height="20px"
                        alt="Edit"
                      />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(val._id)}>
                      <img
                        src="/img/delete.png"
                        width="20px"
                        height="20px"
                        alt="Delete"
                      />
                    </IconButton>
                  </>
                )}
              </p>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuDetail
