import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: #6772E5;
  color: #fff;
font-weight:bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  height: 35px;
  width: 100%;
  &:hover {
    background-color: #5469D4; /* Slightly darker Stripe Blue for Hover State */
  }
`;

const CustomButton = ({ label, onClick }) => (
  <StyledButton onClick={onClick}>{label}</StyledButton>
);

export default CustomButton;