import React from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { AlertsSuccess, Errornotify } from "../../../../../common/alert/Alert"
import Questionsui from "../../../../../curator/components/Common/questionUi/Question"
import api from "../../../../../State/api/Api"
import { removeUser } from "../../../../../State/Features/common/userSlice"
import { setLoader } from "../../../../../State/Features/common/loaderSlice"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { fetchAffiliateType } from "../../../../../State/api/superadminApi"

const inputs = [
  {
    number: 1,
    label: "About Gallery",
    type: "text",
    name: "about_gallery",
    placeholder: "Enter text",
  },
  {
    number: 2,
    label:
      "Please provide details of prestigious exhibitions, awards/prizes, commissions, grants, recognitions etc?*",
    type: "text",
    name: "awards_etc",
    placeholder: "Enter text",
  },
  {
    number: 3,
    label:
      "Please provide us with the name, the year and the place of each experience.",
    type: "text",
    name: "experience",
    placeholder: " Enter URL",
  },
  {
    number: 4,
    label: "How many years of experience you have ?*",
    type: "text",
    name: "total_experience",
    placeholder: "Enter URL",
  },
  {
    number: 5,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox_width_input",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Painting",
      },
      {
        id: 2,
        label: "Drawing",
      },
      {
        id: 3,
        label: "Mixed media",
      },
      {
        id: 4,
        label: "Sculpture",
      },
      {
        id: 5,
        label: "Photography",
      },
      {
        id: 6,
        label: "Textile",
      },
      {
        id: 7,
        label: "Installation",
      },
      {
        id: 8,
        label: "NFT",
      },
      {
        id: 9,
        label: "Digital Art",
      },
      {
        id: 10,
        label: "Craft",
      },
      {
        id: 11,
        label: "Others",
      },
    ],
  },
  {
    number: 6,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "< $250",
      },
      {
        id: 2,
        label: "$250-$1000",
      },
      {
        id: 1,
        label: "$1000-$2500",
      },
      {
        id: 1,
        label: "$2500-5000",
      },
      {
        id: 1,
        label: "$5000-10000",
      },
      {
        id: 1,
        label: ">$10000",
      },
    ],
  },
  {
    number: 7,
    label:
      "If you are represented by another online platform, Please provide URL links:",
    type: "text",
    name: "url_link",
    placeholder: "Enter text",
  },
  {
    number: 8,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "text",
    name: "web_link",
    placeholder: "Enter text",
  },
  {
    number: 9,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "checkbox",
    name: "checkbox_width_input",
    placeholder: "Enter text",
    data: [
      {
        id: 1,
        label: "Painting PaintingPainting Painting Painting Painting    ",
      },
      {
        id: 2,
        label: "Drawing",
      },
      {
        id: 3,
        label: "Mixed media",
      },
      {
        id: 4,
        label: "Sculpture",
      },
      {
        id: 5,
        label: "Photography",
      },
      {
        id: 6,
        label: "Textile",
      },
      {
        id: 7,
        label: "Installation",
      },
      {
        id: 8,
        label: "NFT",
      },
      {
        id: 9,
        label: "Digital Art",
      },
      {
        id: 10,
        label: "Craft",
      },
      {
        id: 11,
        label: "Others",
      },
    ],
  },
  {
    number: 10,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "radio",
    name: "radio",
    placeholder: "Enter text",
    press_enter: true,
    data: [
      {
        id: 1,
        label: "Yes",
      },
      {
        id: 2,
        label: "No",
      },
    ],
  },
  {
    number: 11,
    label:
      "Please provide the link to your online portfolio: (Gallery Website)",
    type: "radio",
    name: "checkbox",
    placeholder: "Enter text",
    press_enter: true,
    data: [
      {
        id: 1,
        label: "Yes",
      },
      {
        id: 2,
        label: "No",
      },
    ],
  },
]
const checkboxdt = {
  step5: [
    {
      id: 1,
      label: "Painting",
    },
    {
      id: 2,
      label: "Drawing",
    },
    {
      id: 3,
      label: "Mixed media",
    },
    {
      id: 4,
      label: "Sculpture",
    },
    {
      id: 5,
      label: "Photography",
    },
    {
      id: 6,
      label: "Textile",
    },
    {
      id: 7,
      label: "Installation",
    },
    {
      id: 8,
      label: "NFT",
    },
    {
      id: 9,
      label: "Digital Art",
    },
    {
      id: 10,
      label: "Craft",
    },
    {
      id: 11,
      label: "Others",
    },
  ],
  step6: [
    {
      id: 1,
      label: "< $250",
    },
    {
      id: 2,
      label: "$250-$1000",
    },
    {
      id: 1,
      label: "$1000-$2500",
    },
    {
      id: 1,
      label: "$2500-5000",
    },
    {
      id: 1,
      label: "$5000-10000",
    },
    {
      id: 1,
      label: ">$10000",
    },
  ],
  step9: [
    {
      id: 1,
      label: "I am an experienced artist",
    },
    {
      id: 2,
      label: "I am an emerging and talented artist",
    },
    {
      id: 3,
      label: "I am an acclaimed artist",
    },
    {
      id: 4,
      label: "I have participated in many fairs worldwide",
    },
    {
      id: 5,
      label: "My works are present in many private collections worldwide",
    },
    {
      id: 6,
      label: "I have participated to a Biennial",
    },
    {
      id: 7,
      label: "My works are present in many public collections worldwide",
    },
    {
      id: 8,
      label: "NFT",
    },
    {
      id: 9,
      label: "Digital Art",
    },
    {
      id: 10,
      label: "Craft",
    },
    {
      id: 11,
      label: "Others",
    },
  ],
}
export default function Fillquestionartist({
  handleChangeApplyData,
  apply,
  setApply,
  handleSelectCheckbox,
  handleChangeSocialLinks,
  handleChangeShowsCurated
}) {
  const [steps, setSteps] = useState({ step: "1", stepper: 100 / 8 })
  const [affiliatetype, setAffiliateType] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleStepsBack = (num) => {
    if (num === "1") {
      setSteps({ step: "1", stepper: (100 / 8) * 1 })
    } else if (num === "2") {
      setSteps({ step: "2", stepper: (100 / 8) * 2 })
    } else if (num === "3") {
      setSteps({ step: "3", stepper: (100 / 8) * 3 })
    } else if (num === "4") {
      setSteps({ step: "4", stepper: (100 / 8) * 4 })
    } else if (num === "5") {
      setSteps({ step: "5", stepper: (100 / 8) * 5 })
    } else if (num === "6") {
      setSteps({ step: "6", stepper: (100 / 8) * 6 })
    } else if (num === "7") {
      setSteps({ step: "7", stepper: (100 / 8) * 7 })
    }
  }

  const handleSteps = () => {
    if (steps.step === "1" && apply.affiliate_account_type_id.length !== 0) {
      setSteps({ step: "2", stepper: (100 / 8) * 2 })
    } else if (steps.step === "2" && apply.first_name.length !== 0) {
      setSteps({ step: "3", stepper: (100 / 8) * 3 })
    } else if (steps.step === "3" && apply.last_name.length !== 0) {
      setSteps({ step: "4", stepper: (100 / 8) * 4 })
    } else if (steps.step === "4" && apply.email.length !== 0) {
      setSteps({ step: "5", stepper: (100 / 8) * 5 })
    } else if (steps.step === "5" && apply.country.length !== 0) {
      setSteps({ step: "6", stepper: (100 / 8) * 6 })
    }  else if (steps.step === "6" && apply.language.length !== 0) {
      setSteps({ step: "7", stepper: (100 / 8) * 7 })
    } else if (steps.step === "7" && apply.phone.length !== 0) {
      navigate("/artsmiley/affiliate/applyform")
    } 
     else {
      Errornotify("Please fill Detail")
    }
  }

  const handleFetchAffiliateType = () => {
    dispatch(fetchAffiliateType(setAffiliateType))
  }
  useEffect(() => {
    handleFetchAffiliateType()
  }, [])
  // const handleSteps = () => {

  // }
  return (
    <div>
      <AlertsSuccess />
      <Questionsui
        handleChangeSocialLinks={handleChangeSocialLinks}
        checkboxdt={checkboxdt}
        handleSelectCheckbox={handleSelectCheckbox}
        steps={steps}
        apply={apply}
        setApply={setApply}
        handleSteps={handleSteps}
        inputs={inputs}
        handleStepsBack={handleStepsBack}
        handleChangeApplyData={handleChangeApplyData}
        affiliate={affiliatetype && affiliatetype}
        type="affiliate"
        handleChangeShowsCurated={handleChangeShowsCurated}
      />
    </div>
  )
}
