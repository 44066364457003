import React from "react"
import styles from "./Commondisputedetail.module.css"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import NativeSelect from "@mui/material/NativeSelect"
import { Box } from "@mui/system"
import homeImage from "../../../../../constant"

export default function Commondisputedetails(props) {
  const [age, setAge] = React.useState("")

  const handleChange = (event) => {
    setAge(event.target.value)
  }
  return (
    <div className={styles.mains}>
      <div className={styles.back_btn}>
        <img src={"/img/_ Back.png"} width="46px" height="15px" />
      </div>
      <hr className={styles.hrs} />
      <h6 className={styles.main_heading}>Art Of Space</h6>
      <div className={styles.selector}>
        {props.type === "gallery" ? (
          <div className={styles.gallery_status}>
            <h6>Status:</h6>
            <p>Open</p>
          </div>
        ) : (
          <FormControl
            sx={{
              my: 1,
              p: 0,
              width: "120px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Select
              value={age}
              size="small"
              sx={{
                color: "#EE0909",
                backgroundColor: "#f6f6f6",
                borderRadius: "10px",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: "10px",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: "10px",
                },
                height: "46px",
                display: "flex",
                justifyContent: "center",
                // textAlign:'right'
              }}
              align="right"
              onChange={handleChange}
              displayEmpty
              IconComponent={() => (
                <img
                  src={"/img/dropdownicon.png"}
                  width="13px"
                  height="10px"
                  style={{ marginRight: "20px", textAlign: "left" }}
                />
              )}
            >
              <MenuItem sx={{ color: "green" }} value="">
                Open
              </MenuItem>
              <MenuItem sx={{ color: "green" }} value={10}>
                One
              </MenuItem>
              <MenuItem value={20}>Two</MenuItem>
              <MenuItem value={30}>Four</MenuItem>
            </Select>
          </FormControl>
        )}
      </div>
      {props.data &&
        props.data.map((val, i) => (
          <div className={styles.details}>
            <div className={styles.title}>{val.title}:</div>
            <div className={styles.content}>{val.content}</div>
          </div>
        ))}
      <div className={styles.image_div}>
        <img src={"/img/image 1.png"} width="113px" height="115px" />
        <img src={"/img/image 1.png"} width="113px" height="115px" />
        <img src={"/img/image 1.png"} width="113px" height="115px" />
        <img src={"/img/image 1.png"} width="113px" height="115px" />
      </div>
    </div>
  )
}
