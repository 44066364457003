import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

const HeadingByMenuSlice = createSlice({
  name: "headingByMenudata",
  initialState,
  reducers: {
    setHeadingByMenudata: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setHeadingByMenudata } = HeadingByMenuSlice.actions

export const headingbymenuData = (state) => state.setHeadingByMenudata

export default HeadingByMenuSlice.reducer
