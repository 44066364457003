import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: null,
};

const herosectionSlice = createSlice({
    name: "herodata",
    initialState,
    reducers: {
        setHerodata: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setHerodata } = herosectionSlice.actions;

export default herosectionSlice.reducer;
