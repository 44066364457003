import React, { useEffect } from "react"
import styles from "./Artdetails.module.css"
import { useParams, useNavigate } from "react-router-dom"
import CommonArtdetail from "../../Common/artdetail/CommonArtdetail"
import { useDispatch, useSelector } from "react-redux"
import {
  acceptDeclineArts,
  fetchArtsProfileData,
} from "../../../../../State/api/superadminApi"
import { AlertsSuccess } from "../../../../../common/alert/Alert"

export default function Artdetails(props) {
  // const navigate = useNavigate();

  const { id } = useParams()
  const dispatch = useDispatch()
  const artsprofileData = useSelector((state) => state.artsprofiledata)
  useEffect(() => {
    dispatch(fetchArtsProfileData(id))
  }, [])

  const handleArtsAcceptDecline = (id, data) => {
    dispatch(acceptDeclineArts(id, { is_accepted: data }))
  }
  return (
    <div className={styles.artdetails_main}>
      <AlertsSuccess />
      {/* <div className={styles.back_button}>
                <img src={ /img/_ Back.png'} onClick={handleBack} className={styles.backimage} height='13px' width='46px' />
            </div>
            <div className={styles.previewimg_img_div}>
                <img src={ /img/Image 1.png'} className={styles.previewimg} />
            </div> */}
      <CommonArtdetail
        handleArtsAcceptDecline={handleArtsAcceptDecline}
        page="superadmin"
        data={artsprofileData.data}
      />
    </div>
  )
}
