import {
  createTheme,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
  Input,
  makeStyles,
  Container,
  Grid,
  Button,
  // Slider
} from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import styles from "./TrendingCategory.module.css";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CheckBox } from "@mui/icons-material";
import { fontWeight } from "@mui/system";
import ProductSidebar from "./ProductSidebar";
import homeImage from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllItemData,
  fetchArtistTypes,
  fetchCategoryData,
  fetchProductType,
  fetchUserArtist,
} from "../../../State/api/userApi";
import serverimage from "../../../serverConstant";
import { hover } from "@testing-library/user-event/dist/hover";
import { setSearchFilter } from "../../../State/Features/user/filterationSlice";
import api from "../../../State/api/Api";
import CircularLoader from "../../../common/loader/Loader";

const cardDetails = [
  {
    id: 1,
    imgUrl: "/img/Rectangle 368.png",
  },
  {
    id: 2,
    imgUrl: "/img/Rectangle 369.png",
  },
  {
    id: 3,
    imgUrl: "/img/Rectangle 370.png",
  },
  {
    id: 4,
    imgUrl: "/img/Rectangle 5199.png",
  },
  {
    id: 5,
    imgUrl: "/img/Rectangle 5197.png",
  },
  {
    id: 6,
    imgUrl: "/img/Rectangle 5200.png",
  },
  {
    id: 7,
    imgUrl: "/img/Rectangle 368.png",
  },
  {
    id: 8,
    imgUrl: "/img/Rectangle 5202.png",
  },
  {
    id: 9,
    imgUrl: "/img/Rectangle 5203.png",
  },
  {
    id: 10,
    imgUrl: "/img/Rectangle 5206.png",
  },
  {
    id: 11,
    imgUrl: "/img/Rectangle 5207.png",
  },
  {
    id: 12,
    imgUrl: "/img/Rectangle 5208.png",
  },
];

const theme = createTheme({
  typography: {
    h4: {
      fontFamily: "Marcellus",
      fontSize: "2vw",
    },
    h6: {
      fontFamily: "Marcellus",
      fontSize: "1vw",
    },
    accordionheading: {
      fontSize: "0.85vw",
      fontWeight: 500,
    },
    radioname: {
      fontSize: "0.95vw",
      fontWeight: "400",
    },
  },
});
function TrendingCategory() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 50000]);
  const [value_height, setValueHeight] = useState([0, 1500]);
  const [value_width, setValueWidth] = useState([0, 1500]);
  const [condition, setCondition] = useState({ sorting: "", filter: "" });
  const [style, setStyle] = useState([]);
  const [filterByArtist, setFilterByArtist] = useState("all");
  const loader = useSelector((state) => state.loader);
  const filter = useSelector((state) => state.filteration);
  const productType = useSelector((state) => state.productType);
  const artistTypes = useSelector((state) => state.artistTypedata);
  const userartists = useSelector((state) => state.userartistData);
  const allcategory = useSelector((state) => state.allcategoryData);
  const handleClick = () => {
    setOpen(!open);
  };
  function valuetext(value) {
    return `${value}°C`;
  }
  const allitemdt = useSelector((state) => state.allitemData);
  // console.log(allitemdt)

  const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  const handleChangeSearch = (e) => {
    const value = e.target.value;
    dispatch(setSearchFilter(value));
  };
  const handleFetchStyles = async function () {
    try {
      const result = await api.applicationApi(`api/user/getstyles`);
      if (result && result.status === 200) {
        setStyle(result.data.response);
      } else if (result.status === 401) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilter = (event) => {
    const value = event.target.value;
    console.log(filterByArtist);
    setFilterByArtist(value);
    setCondition({ ...condition, filter: value });
  };
  useEffect(() => {
    dispatch(fetchAllItemData());
    dispatch(fetchProductType());
    dispatch(fetchArtistTypes());
    dispatch(fetchUserArtist({}));
    dispatch(fetchCategoryData({}));
    handleFetchStyles();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const filters =
    allitemdt.data &&
    allitemdt.data.map((val) => val.style_id.map((c) => c._id._id));
  return (
    <div className={styles.trendingcategory} style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <div className={styles.allcategory}>
          <div className={styles.headerCategory}>
            <br />
            <div>
              <Typography variant="h4">
                <span className={styles.headerTitle}>Trending</span>
              </Typography>
              <Typography variant="h6">
                <span className={styles.headerSubTitle}>
                  <b
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavigate("/")}
                  >
                    Home /{" "}
                  </b>
                  Trending
                </span>
              </Typography>
            </div>
          </div>
        </div>
        {/* header section */}
        <div className={styles.header__div}>
          <div className={styles.searchBar__div}>
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <FormControl variant="standard">
                <TextField
                  id="input-with-icon-textfield"
                  label=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={filter.search}
                  onChange={handleChangeSearch}
                  placeholder="Search"
                  variant="outlined"
                />
              </FormControl>
            </Box>
          </div>
          <div className={styles.filter__label}>
            <span className={styles.filter__span}>Sort By</span>
          </div>
          <div className={styles.filter__div}>
            <FormControl sx={{ minWidth: 120, color: "red" }}>
              <Select
                value=""
                // onChange=""
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="select"
                sx={{
                  color: "#636365",
                  border: "0.6px solid #665D6F",
                  borderRadius: "6px",
                }}
              >
                <MenuItem value="" sx={{ color: "#636365" }}>
                  Best Seller
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={styles.ProductContent}>
          {/* sidebar */}

          <ProductSidebar
            value_height={value_height}
            value_width={value_width}
            priceRange={priceRange}
            setValueHeight={setValueHeight}
            filter={filter}
            style={style}
            setFilter={filter}
            setPriceRange={setPriceRange}
            setValueWidth={setValueWidth}
            producttypeData={productType.data}
            artisttypeData={artistTypes.data}
            allartistData={userartists.data}
            allcategoryData={allcategory.data && allcategory.data.allcategories}
          />

          {/* product cards */}

          <div className={styles.ProductCards}>
            <Container>
              <Grid
                container
                columnSpacing={{ xs: 2, md: 5 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                {loader.loader ? (
                  <div className={styles.loader}>
                    <CircularLoader />
                  </div>
                ) : (
                  allitemdt.data &&
                  allitemdt.data
                    .filter((val) => {
                      return val.trending === true;
                    })
                    .filter((val, i) =>
                      val.is_accepted === "Approved" &&
                      val.sale.price >= filter.price[0] &&
                      val.sale.price <= filter.price[1] &&
                      val.height >= value_height[0] &&
                      val.height <= value_height[1] &&
                      val.width >= value_width[0] &&
                      val.width <= value_width[1] &&
                      (filter.artist.length === 0
                        ? val
                        : filter.artist.includes(
                            val.artist_id && val.artist_id._id
                          )) &&
                      (filter.category.length === 0
                        ? val
                        : filter.category === val.category_id._id) &&
                      (filter.style.length === 0
                        ? val
                        : filter.style.some(
                            (element) => filters && filters[i].includes(element)
                          )) &&
                      (filter.country.length === 0
                        ? val
                        : filter.country.includes(val.country_id)) &&
                      (filter.type.length === 0
                        ? val
                        : filter.type.includes(val.item_type_id)) &&
                      (filter.artist_type.length === 0
                        ? val
                        : filter.artist_type.includes(
                            val.artist_id && val.artist_id.artist_type_id
                          )) &&
                      (filter.orientation.length === 0
                        ? val
                        : filter.orientation.includes(
                            val.orientation_id && val.orientation_id
                          )) &&
                      (filter.color.length === 0
                        ? val
                        : filter.color.includes(
                            val.color_id && val.color_id
                          )) &&
                      (filter.search === ""
                        ? val
                        : val.name
                            .toLowerCase()
                            .includes(filter.search.toLowerCase())) &&
                      filterByArtist === "all"
                        ? val
                        : val.artist_id._id === filterByArtist
                    )
                    .map((item) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ mb: 2 }}
                        key={item._id}
                      >
                        <div
                          className={styles.card__main}
                          onClick={() => navigate(`/user/trending/${item._id}`)}
                        >
                          <div className={styles.card__top}>
                            <img
                              className={styles.cardImage}
                              src={ item.image}
                            />
                            <div className={styles.img_icon}>
                              <IconButton
                                aria-label="add to shopping cart"
                                sx={{
                                  color: "white",
                                  backgroundColor: "black",
                                  "&:hover": { backgroundColor: "black" },
                                }}
                              >
                                <AddShoppingCartIcon />
                              </IconButton>
                              <br />
                              <IconButton
                                sx={{
                                  color: "white",
                                  backgroundColor: "black",
                                  marginTop: 1,
                                  "&:hover": { backgroundColor: "black" },
                                }}
                              >
                                <FavoriteBorderIcon />
                              </IconButton>
                            </div>
                          </div>
                          <div className={styles.card__middle}>
                            <div className={styles.card__middleLeft}>
                              <Typography
                                fontSize="0.95rem"
                                fontWeight="700"
                                mt={1}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                fontSize="0.8rem"
                                fontWeight="700"
                                mt={1}
                                color="#ccc"
                              >
                                {item.artist_id && item.artist_id.first_name}{" "}
                                {item.artist_id && item.artist_id.last_name}
                              </Typography>
                            </div>
                            <div className={styles.card__middleRight}>
                              <FavoriteBorderIcon fontSize="small" />
                              <Typography fontWeight="400" ml={0.5}>
                                522
                              </Typography>
                            </div>
                          </div>
                          <hr />
                          <div className={styles.card__bottom}>
                            <div className={styles.buy__text}>
                              <Button>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Buy
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                  mt={4}
                                  ml={-3}
                                >
                                  <b>$ {item.buy_price}</b>
                                </Typography>
                              </Button>
                            </div>
                            <div className={styles.verticalLine}></div>
                            <div className={styles.rent__text}>
                              <Button>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Rent/Month
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                  mt={4}
                                  ml={-6.5}
                                  mr={3}
                                >
                                  <b>$ {item.rent_price}</b>
                                </Typography>
                              </Button>
                            </div>
                            <div className={styles.verticalLine}></div>
                            <div className={styles.print__text}>
                              <Button>
                                <Typography
                                  fontWeight="600"
                                  color="#ccc"
                                  fontSize="0.65rem"
                                >
                                  Print
                                </Typography>
                                <Typography
                                  color="black"
                                  fontWeight="500"
                                  fontSize="0.85rem"
                                  mt={4}
                                  ml={-3.75}
                                >
                                  <b>$ 356</b>
                                </Typography>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))
                )}
              </Grid>
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default TrendingCategory;
