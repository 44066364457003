import React, { useState } from "react";
// import Navbar from "../Navbar/Navbar"
// import Header from "../Header/Header"
import styles from "./Home.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
// import Footer from "../Footer/Footer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import RemoveIcon from "@mui/icons-material/Remove";
import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import homeImage from "../../constant";
import { useDispatch } from "react-redux";
import { AddMailSubscription } from "../../State/api/userApi";
import { AlertsSuccess, Errornotify } from "../alert/Alert";

const data = [
  {
    id: 1,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 2,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
  {
    id: 3,
    heading: "Internation visibility",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.",
  },
];
const faq_data = [
  {
    id: 1,
    question: "Is registration open to all individuals wanting to sell on Art Smiley?",
    answer: "Yes, individuals can sell on Art Smiley by simply creating an account. This opportunity is available to all artists looking to market their creations to a wider audience. ArtSmiley supports artists of all levels, from beginners to established professionals, allowing them to showcase and sell their artwork."
  },
  {
    id: 2,
    question: "How to Sign Up as an Artist on Art Smiley?",
    answer: "To join Art Smiley as an artist, start by visiting the platform's registration page. Fill out the required fields with your accurate information and any relevant details about your artistic practice. After submitting your application, it will be reviewed by the Art Smiley curatorial team. Once approved, you will receive a confirmation email that allows you to create your password. This will grant you access to your artist profile, where you can upload and sell your artwork."
  },
  {
    id: 3,
    question: "What is the expected timeframe for the application review process?",
    answer: "The review process for your artist application typically takes 3 to 4 weeks. You’ll be notified as soon as the review is complete, along with guidance on the next steps to begin showcasing your work."
  },
  {
    id: 4,
    question: "How do I submit my artwork for upload?",
    answer: "First, you need to login to your dashboard and select the upload arts section and start submitting the artwork with all details."
  },
  {
    id: 5,
    question: "What types of creative works are accepted for sale on Art Smiley?",
    answer: "With Art Smiley, you can sell various artistic creations, including all types of paintings, drawings, photography, digital art, sculptures, open, and limited edition prints. Unique handmade crafts or mixed media pieces are also permitted."
  },
  {
    id: 6,
    question: "What are the necessary steps to sell my artwork through Art Smiley?",
    answer: "To start selling your artwork on Art Smiley, First, create an artist account on the platform. After your account approval, begin uploading high-quality images of your artwork along with detailed descriptions. Set your prices based on your experience and market trends. Lastly, familiarize yourself with Art Smiley’s shipping and return policies to ensure the prompt delivery of the artwork. [shipping and returns policy link] Once your artwork is approved by the curator, it will be available for sale on the platform."
  },
  {
    id: 7,
    question: "How does the payout system work for artists on Art Smiley?",
    answer: "As an ArtSmiley artist, you can receive payments for your artwork through various options. Funds will be transferred to your bank account within 3 to 4 weeks after the successful delivery of your artwork. You can choose to receive your payment via bank account or PayPal, depending on your preference."
  },
  {
    id: 8,
    question: "How does the shipping procedure work?",
    answer: "Upon the sale of your artwork, you will receive an email with all the details. Login to your dashboard, accept the artwork order, and then schedule a pickup date with our courier service. Download soft copies of the shipping labels from your dashboard, print and stick them over the package. The buyer covers shipping charges, and your responsibility is limited to packaging your artwork according to the guidelines specified by Art Smiley.To know more about the packaging instructions, click this link [package guidelines link]."
  },
  {
    id: 9,
    question: "What are the best packaging methods for ensuring the safe transport of different kinds of artwork?",
    answer: "When packaging artwork, it’s essential to choose the right method to protect each piece. Flat pack boxes are perfect for small-sized framed prints, paintings, and photography. Tube packaging works well for rolled-up paintings, as its cylindrical shape prevents crushing of artwork. For fragile or large pieces like sculptures or big-sized framed artwork, custom wooden crates provide the highest level of protection. All artworks must be wrapped with bubble wrap or foam cushioning to prevent surface scratches or impact damage.You can check out the detailed instructions with our packaging guidelines[packaging guidelines link]."
  },
  {
    id: 10,
    question: "How are commissions calculated when selling through Art Smiley?",
    answer: "Art Smiley employs a commission structure that varies based on the type of transaction. For Sales: Art Smiley charges a commission of [dynamic value%] on each artwork sold through the platform, allowing artists to retain [dynamic value%] of the sale price. For Rentals: When renting out artwork, Art Smiley applies a [dynamic value%] commission, with the remaining [dynamic value%] going to the artist. For Print Sales: If art prints are sold, Art Smiley takes a [dynamic value%] commission, with the artist keeping [dynamic value%] of the earnings from each print sold."
  },
  {
    id: 11,
    question: "How Does Artwork Renting Work on Art Smiley?",
    answer: "Art Smiley offers a unique artwork rental service, allowing clients to rent pieces for a specified period. Artists can opt for artwork renting by specifying the price of the artwork on a monthly basis. This helps buyers explore the artwork and try it for a short period. They can purchase the artwork with a 50% rental amount credited for each month. The artist receives a portion of the rental fee, with Art Smiley taking a [dynamic value%] commission."
  },
  {
    id: 12,
    question: "How will I know when my painting is sold?",
    answer: "Once your painting is sold on Art Smiley, you will receive a confirmation email. Login to the dashboard and accept the order. After that, you must schedule a pickup date and securely pack your painting. After the painting is shipped, you can track its status through your Art Smiley account. Once the buyer receives the artwork and the 14-day return window has passed, your payment will be processed within 3 to 4 weeks."
  },
  {
    id: 13,
    question: "How Do I Determine the Price for My Original Works?",
    answer: "When setting the price for your original creations on Art Smiley, it's important to take your personal expectations into account. Make sure that your pricing reflects the platform's commission rate, which is currently [dynamic value%]. This means you should incorporate this commission into your pricing strategy to ensure that your potential earnings meet your financial objectives."
  },
  {
    id: 14,
    question: "What are limited edition prints?",
    answer: "Limited edition prints are artworks produced by the artists in a restricted number, meaning only a specific quantity of prints will be made. These prints are typically signed and numbered by the artist, which adds to their value and appeal."
  },
  {
    id: 15,
    question: "What Are Open Edition Prints?",
    answer: "If the artist chooses an artwork to sell as open edition prints, it will be printed by Art Smiley. Open edition prints refer to art reproductions that do not have a predetermined limit on the number of copies produced. Unlike limited edition prints, which are produced in a finite quantity, open edition prints can be created continuously without restriction."
  }
];

const service_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Platform Exclusivity",
    des: "Artists cannot sell their artwork on any other platform, ensuring the uniqueness and exclusivity of their pieces.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "First Preference for Exhibitions",
    des: "Exclusive artists are given priority in showcasing their artwork in our physical and virtual exhibitions, gaining maximum visibility.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Priority in Auctions",
    des: "Get first preference to feature your work in high-profile auctions, increasing exposure to collectors and buyers.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Increased Artwork Value",
    des: "The exclusivity of selling only through Art Smiley enhances the value and rarity of your artwork.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Artist-Centric Focus",
    des: "We manage the business aspects, allowing you to concentrate on creating and perfecting your art.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Exclusive Promotions",
    des: "Your artwork will be exclusively promoted on WhatsApp, email marketing, and social media platforms, reaching a broader audience of art enthusiasts.",
  },
  {
    id: 7,
    src: "/img/service_artist_1",
    heading: "Priority in ArtSmiley Projects",
    des: "Exclusive artists are given first preference in collaborating on ArtSmiley internal projects, ensuring maximum exposure and opportunities.",
  },
];
const exclusive_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Expand Your Reach with Exhibitions and Auctions",
    des: "As a registered artist, you will receive priority access to virtual and physical exhibitions, enhancing your global visibility. You’ll also gain exclusive opportunities to participate in auctions, connecting with dedicated collectors to elevate your artistic career.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Art Community",
    des: "Become a part of our thriving art community, where you can sell your work alongside other talented artists. Gain exclusive access to the latest trends and insights through features in blogs, magazines, catalogs, and artist interviews, helping you boost your visibility and enrich your artistic journey.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Featured  and Exclusive Artist",
    des: "Art Smiley assesses and categorizes featured artists based on their experience and profile, ensuring that each artist receives appropriate support and opportunities tailored to their unique career stage. Your artworks will be showcased, ensuring they reach a wider audience and attract potential buyers. We highlight the exclusive artist profile and enhance your presence in the art world, connecting you with collectors and art enthusiasts.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Most Popular and Recommended Artworks",
    des: "At Art Smiley, we showcase the most popular and recommended artworks to celebrate exceptional talent within our community. These artworks are carefully curated based on popularity and artistic merit, ensuring they resonate with collectors and art lovers. This curated spotlight not only elevates the artists' profiles but also enhances their presence in the art market.",
  },
];
const subscription_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Public Profile",
    des: "Showcase your artwork to the world and become a permanent part of the Art Smiley community.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Personalized Dashboard",
    des: "Easily manage your portfolio, track the status of your artworks, exhibitions, virtual auctions, orders, customer requests, and client offers.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "SSL Security",
    des: "Protect your personal and sensitive information with SSL encryption.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "24/7 Customer Support",
    des: "Get round-the-clock assistance whenever you need it.",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Upload up to 20 Works",
    des: "Start sharing your creations with up to 20 artwork uploads.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Analytics",
    des: "Monitor views, likes, follows, and reviews to track your audience engagement.",
  },
];
const standard_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload up to 200 Works",
    des: "Broaden your artwork display and reach a wider audience.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Homepage Feature for Your Artwork",
    des: "Enjoy heightened exposure with a one-time feature of your artwork on the Art Smiley homepage.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Participation in Virtual Events",
    des: "Network with fellow artists and collectors through one-time participation with up to three works in any Art Smiley, including virtual exhibitions or auctions.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Exclusive Marketing Opportunities",
    des: "Take advantage of one-time promotions for selected artworks on Art Smiley's social media channels (Facebook, Instagram, Twitter).",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Insights on Pricing and Marketing",
    des: "Receive valuable support and insights to make strategic decisions regarding your art pricing and marketing efforts.",
  },
];
const premium_subscription = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Upload up to 500 Works",
    des: "Present your full collection of creative artworks and expand your portfolio.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Highlighted Artist Profile on Homepage",
    des: "Stand out in the artistic community with a one-time feature of your profile on the Art Smiley homepage.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Enhanced Participation in Virtual Events",
    des: "Gain access to exclusive networking opportunities with two-time participation, featuring up to three works in any Art Smiley events, including virtual exhibitions or auctions.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Broadened Marketing Support",
    des: "Reach a wider audience with two exclusive promotions of your artworks across all Art Smiley social media channels (Facebook, Twitter, Instagram, GMB, LinkedIn, Pinterest, Tumblr).",
  },
  {
    id: 5,
    src: "/img/service_artist_1",
    heading: "Video Production for YouTube & Social Media",
    des: "Visually showcase your artistry with a video created by the Art Smiley team or your own one-minute video shared on our YouTube channel and other social media platforms.",
  },
  {
    id: 6,
    src: "/img/service_artist_1",
    heading: "Exclusive Exhibition Invitations",
    des: "Enjoy discounted rates and first refusal opportunities for all Art Smiley-associated physical or virtual exhibitions.",
  },
  {
    id: 7,
    src: "/img/service_artist_1",
    heading: "Featured Blog Posts",
    des: "Share your artistic insights and experiences by having your work featured in Art Smiley's blogs.",
  },
  {
    id: 8,
    src: "/img/service_artist_1",
    heading: "Solo Online Exhibition with Annual Plan",
    des: "Highlight your artwork in a dedicated virtual showcase if you choose the one-year Premium Subscription.",
  },
];
const why_choose_data = [
  {
    id: 1,
    src: "/img/service_artist_1",
    heading: "Reach a Diverse and Engaged Audience",
    des: "Tap into a global network of art enthusiasts, corporate buyers, and interior designers actively seeking unique art pieces. Expand your visibility through a platform that celebrates diverse artistic styles and mediums.",
  },
  {
    id: 2,
    src: "/img/service_artist_1",
    heading: "Build Your Artistic Brand",
    des: "Establish a professional online presence with a customizable artist profile highlighting your story and portfolio. Gain recognition through Art Smiley’s curated features and artist spotlights.",
  },
  {
    id: 3,
    src: "/img/service_artist_1",
    heading: "Expand Your Creative Potential",
    des: "Participate in physical and virtual exhibitions, auctions, and community events hosted by Art Smiley, gaining exposure and networking opportunities.",
  },
  {
    id: 4,
    src: "/img/service_artist_1",
    heading: "Fair and Reliable Earning Structure",
    des: "Keep more of your earnings with competitive commission rates and transparent payment systems. Receive timely payouts with no unexpected deductions or delays.",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Home(props) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleSignupButton = () => {
    // const applied_profile = localStorage.getItem("applied_profile");
    // const user_type = localStorage.getItem("usertype");
    
    // if (applied_profile === "true" && user_type === "aritst") {
    //   Errornotify("You Already Apply for Artist");
    // }
    // if (applied_profile === "true" && user_type === "affiliate") {
    //   Errornotify("You Already Apply for Artist");
    // } else {
    //   navigate("/artsmiley/applynow");
    // }
    navigate("/artsmiley/artist/applynow")
  };
  const handleChangeSubscription = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    dispatch(AddMailSubscription({ email: email }, "st", setEmail));
  };

  return (
    <>
      <AlertsSuccess />
      {/* <Header />
            <Navbar /> */}
      <React.Fragment>
        <CssBaseline />
        <Container disableGutters={true} maxWidth="2000px" sx={{ mb: 5 }}>
          <Box
            className={styles.hero_main}
            sx={{ positions: "relative", height: "395px",padding:"20px 0px" }}
          >
            <div className={styles.hero_heading}>
              <h1>
              Expand Your Reach And Increase Sales With Art Smiley's
                <br />
                Exclusive Artist Services.
              </h1>
            </div>
            <Button
              onClick={handleSignupButton}
              variant="contained"
              sx={{
                width: "158px",
                height: "63px",
                borderRadius: "9px",
                backgroundColor: "white",
                color: "black",
              }}
              className={styles.hero_heaing_button}
            >
              Apply
            </Button>
          </Box>
          <Box
            className={styles.hero_main1}
            sx={{ positions: "relative"}}
          >
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
              }}
              component="div"
            >
        Join Our Creative Community
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: " 400",
                fontFamily: "DM sans",
                color: "#636365",
                lineHeight: "30px",
                textAlign: "center",
              }}
              component="div"
            >
          Step into the Spotlight With Art Smiley Artist Network. Become a part of Art Smiley and showcase
          <br />  your art to a broader audience, expanding your reach and gaining recognition.
            </Typography>
            <div className={styles.hero_card}>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                {/* <Avatar className={styles.Avatar1} >s</Avatar> */}
                <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                 Global Presence
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
                 Expand your global presence by showcasing your artwork to collectors worldwide, with opportunities for promotion through both physical and virtual exhibitions, as well as online auctions. Art Smiley proudly supports a community of over 10,000 artists across the globe.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
                 <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                 Active Marketing
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
                We actively promote your work through blogs, social media, whatsapp, email campaigns, and curated catalogs that help to increase the sales of your artwork.
                  </Typography>
                </CardContent>
              </Card>
              <Card
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "320px",
                  height: "auto",
                  backgroundColor: " #F6F6F6",
                  padding: "30px 0px 0px 20px",
                }}
              >
               <div className={styles.Avatar1}>
                  <img
                    alt="img"
                    src={"/img/color-palette.png"}
                    width="25px"
                    height="31px"
                  />
                </div>
                <CardContent>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                    Commissions
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "30px",
                    }}
                    component="div"
                  >
       Art Smiley charges a [Dynamic value from super admin]% commission on sales, with artists setting prices to ensure fair earnings for each piece.
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
          {/* <Box className={styles.benifits_box}>
                        <Box className={styles.benifits_card}>
                            h
                            <div className={styles.Avatar_ligth} >
                                <div className={styles.Avatar4}>04</div>
                            </div>
                            <h3 className={styles.benifits_head}>Best Earn</h3>
                            <p className={styles.benifits_des}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </Box>
                        <div className={styles.dot_line}></div>
                    </Box> */}
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
              }}
            >
              Reach New Heights
            </Typography>
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "25px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                // color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
              }}
            >
           (Expand Your Artistic Horizons, Attain New Artistic Peaks, Pursue New Artistic Frontiers)
            </Typography>
            <Box className={styles.hero_main8}>
              <Box className={styles.hero_main8_image}>
                <img src={"/img/Mockup.png"} alt="mockup" />
              </Box>
            </Box>
            <Box sx={{ width: "100%"}}>
              <div className={styles.cards_div}>
                <div className={styles.card_div}>
                 
                    <div  className={styles.cards}>
                      <div className={styles.round_img_div}>
                        <img
                          alt="img"
                          src={"/img/color-palette.png"}
                          width="25px"
                          height="31px"
                        />
                      </div>
                      <h4 className={styles.headings}>Worldwide Recognition</h4>
                      <p className={styles.contents}>Present your creations to a global audience, fostering connections with collectors everywhere.</p>
                    </div>
                    <div  className={styles.cards}>
                      <div className={styles.round_img_div}>
                        <img
                          alt="img"
                          src={"/img/color-palette.png"}
                          width="25px"
                          height="31px"
                        />
                      </div>
                      <h4 className={styles.headings}>Artistic Independence</h4>
                      <p className={styles.contents}>Enjoy complete control over your pricing and creative choices, preserving your artistic integrity.</p>
                    </div>
                    <div  className={styles.cards}>
                      <div className={styles.round_img_div}>
                        <img
                          alt="img"
                          src={"/img/color-palette.png"}
                          width="25px"
                          height="31px"
                        />
                      </div>
                      <h4 className={styles.headings}>Dedicated Guidance</h4>
                      <p className={styles.contents}>Access customized support tailored to elevate your artistic journey and business success.</p>
                    </div>
                    <div  className={styles.cards} >
                      <div className={styles.round_img_div}>
                        <img
                          alt="img"
                          src={"/img/color-palette.png"}
                          width="25px"
                          height="31px"
                        />
                      </div>
                      <h4 className={styles.headings}>Artist Classification</h4>
                      <p className={styles.contents}>At Art Smiley, we assess artists and categorize them into the following types based on their experience and achievements:
                        <ul>
                          <li>Emerging</li>
                          <li>Experienced</li>
                          <li>Acclaimed</li>
                          <li>Masters</li>
                        </ul>
                        This approach ensures that artists receive targeted support and opportunities tailored to their career development. Your work will be prominently displayed, increasing visibility and connecting you with passionate collectors.
 </p>
                    </div>
                </div>
                <div className={styles.btn_div}>
                  <Button
                    variant="contained"
                    onClick={handleSignupButton}
                    sx={{
                      width: "156px",
                      height: "54px",
                      borderRadius: "10px",
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Box>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
              How To Apply?
            </Typography>
            <p>(How to Become an Artist with Us, Steps to Apply as an Artist, Ignite Your Artistic Journey: A Simple Application Process, Create, Connect, Contribute: How to Apply as an Artist)</p>
            {/* <Box> */}
            <div className={styles.hero_card}>
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "auto",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>01</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
             Artist Application  
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
         Begin your journey by applying to join the Art Smiley platform. Once your application is reviewed and approved, you'll receive a confirmation email to create a password and then you can unlock access to all features and services.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "auto",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>02</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "36px",
                    }}
                    gutterBottom
                  >
                   Artwork Uploads
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
  Access your personalized dashboard and start uploading your artwork. After the approval of the artwork, it will be available for sale. In this way, Art Smiley helps to attract a wide range of collectors and art enthusiasts.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "auto",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>03</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
               Explore Subscription Plans
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
Maximize your reach by starting with our free plan, and as your artistic goals grow, explore our flexible paid subscription plans tailored to your needs.
                  </Typography>
                </CardContent>
              </Card>
              <hr className={styles.hrs} />
              <Card
                className={styles.card_four}
                sx={{
                  position: "relative",
                  overflow: "visible",
                  maxWidth: "270px",
                  height: "auto",
                  padding: "50px 20px 48px 20px",
                }}
              >
                <div className={styles.Avatar2}>
                  <div className={styles.Avatar3}>04</div>
                </div>
                <CardContent sx={{ p: 0 }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: " 700",
                      fontFamily: "DM sans",
                      color: "#1C1C1C",
                      lineHeight: "25px",
                    }}
                    gutterBottom
                  >
User-Friendly Dashboard for Artists
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: " 400",
                      fontFamily: "DM sans",
                      color: "#636365",
                      lineHeight: "25px",
                    }}
                    component="div"
                  >
Easily manage your portfolio with our intuitive dashboard. Easily monitor and manage your total arts, total orders, quote requests, recreation requests, inquiries, exhibition, auctions, likes, followers, and overall earnings in a streamlined and efficient manner. 
                  </Typography>
                </CardContent>
              </Card>
            </div>
            {/* </Box> */}
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
              Testimonials
            </Typography>
            <div className={styles.card_div}>
              <div className={styles.card_testimonials}>
                <div>
                  <img
                    alt="img"
                    src={"/img/event3.png"}
                    width="100px"
                    height="100px"
                  />
                </div>
                <div>
                  <h5 className={styles.test_card_heading}>Albert Didier</h5>
                  <h6 className={styles.test_card_subhead}>Euismod ipsum</h6>
                  <h3 className={styles.test_card_des}>
                    In purus at morbi magna in in maecenas. Nunc nulla magna
                    elit, varius phasellus blandit convallis.
                  </h3>
                </div>
              </div>
              <div className={styles.card_testimonials}>
                <div>
                  <img
                    alt="img"
                    src={"img/event3.png"}
                    width="100px"
                    height="100px"
                  />
                </div>
                <div>
                  <h5 className={styles.test_card_heading}>Albert Didier</h5>
                  <h6 className={styles.test_card_subhead}>Euismod ipsum</h6>
                  <h3 className={styles.test_card_des}>
                    In purus at morbi magna in in maecenas. Nunc nulla magna
                    elit, varius phasellus blandit convallis.
                  </h3>
                </div>
              </div>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Exclusive Artist Benefits at Art Smiley
            </Typography>
            <div className={styles.service_card_div}>
              {service_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Exclusive Services for Talented Artists
            </Typography>
            <p>(Premium Services for Exceptional Artists, Exclusive Opportunities for Emerging Talent, Elite Services for Exceptional Artists)</p>
            <div className={styles.service_card_div}>
              {exclusive_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Artist Subscription Plans
            </Typography>
            <p>Elevate your artistic journey with Art Smiley's versatile subscription options, thoughtfully crafted to meet your individual aspirations as an artist. Select the plan that aligns with your goals and begin sharing your creativity with a global audience today!</p>
            <p>Basic Subscription: Free</p>
            <div className={styles.service_card_div}>
              {subscription_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Standard Subscription 
            </Typography>
            <p>[%Dynamic value of Subscription amount per month] [%Dynamic value of Subscription amount per year]</p>
            <p>Includes all Basic Features plus:</p>
            <div className={styles.service_card_div}>
              {standard_subscription.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
          <Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Premium Subscription
            </Typography>
            <p>[%Dynamic value of Subscription amount per month] [%Dynamic value of Subscription amount per year]</p>
            <p>Includes all Standard Features plus:</p>
            <div className={styles.service_card_div}>
              {premium_subscription.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply
              </Button>
            </div>
          </Box>
  <Box className={styles.hero_main6} sx={{ maxWidth: "1287px" }}>
    {faq_data.map((item) => (
      <Accordion
        key={item.id}
        expanded={expanded === `panel${item.id}`}
        onChange={handleChange(`panel${item.id}`)}
      >
        <AccordionSummary
          expandIcon={
            expanded === `panel${item.id}` ? (
              <RemoveIcon  />
            ) : (
              <AddIcon/>
            )
          }
          aria-controls={`panel${item.id}bh-content`}
          id={`panel${item.id}bh-header`}
          className={styles.accordian_show}
        >
          <div className={styles.show_content}>
            {expanded === `panel${item.id}` && <div className={styles.accodian_no}>{item.id}</div>}
            <Typography >
              {item.question}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 9.5, pr: 9 }}>
          <Typography>{item.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </Box>
);
<Box className={styles.hero_main7}>
            <img
              src={"/img/Design.png"}
              height="21.49px"
              width="108.3px"
              alt="logo"
            />
            <Typography
              sx={{
                zIndex: "10",
                fontSize: "40px",
                fontWeight: " 400",
                fontFamily: "Marcellus",
                color: "#1C1C1C",
                lineHeight: "50.16px",
                mb: 2,
                textTransform: "capitalize",
              }}
            >
          Why Choose Art Smiley to Sell Your Art?
            </Typography>
            <div className={styles.service_card_div}>
              {why_choose_data.map((val) => (
                <div key={val.id} className={styles.service_card}>
                  <img
                    alt="img"
                    src={`${val.src}.png`}
                    width="308px"
                    height="250px"
                  />
                  <h4 className={styles.service_card_heading}>{val.heading}</h4>
                  <p className={styles.service_card_description}>{val.des}</p>
                </div>
              ))}
            </div>
            <div className={styles.btn_div}>
              <Button
                variant="contained"
                onClick={handleSignupButton}
                sx={{
                  mt: 2,
                  width: "156px",
                  height: "54px",
                  borderRadius: "10px",
                }}
              >
                Apply Now
              </Button>
            </div>
          </Box>
          <Box className={styles.subscribe_banner}>
            <div>
              <img alt="img" src={"img/email.png"} width="80px" height="63px" />
            </div>
            <h5 className={styles.subscribe_head}>
              Subscribe to the art smiley newsletter
            </h5>
            <TextField
              variant="outlined"
              value={email}
              placeholder="Please enter your mail address"
              onChange={handleChangeSubscription}
              sx={{
                width: "400px",
                background: "#3c3c3c",
                // opacity: '0.2',
                border: "1px solid #FFFFFF",
                borderRadius: "6px",
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                // "::placeholder": { color: "red" },
                // '&::-webkit-input-placeholder': { color: 'blue' },
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "21px",
                color: "#FFFFFF",
                input: { color: "white" },
                border: "none",
                // opacity: '0.6',
              }}
            />
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                height: "54px",
                width: "131px",
                borderRadius: "6px",
              }}
              onClick={handleSubmit}
            >
              Subscribe
            </Button>
          </Box>
        </Container>
      </React.Fragment>
      {/* <Footer /> */}
    </>
  );
}

export default Home;
