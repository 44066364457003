// import { useSelect } from '@mui/base';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertsSuccess } from '../../../../../common/alert/Alert';
import { deleteSubscriptionFeature, editSubscriptionfeaturedata, fetchsubscriptionFeature, handleAddSubscriptionFeature } from '../../../../../State/api/superadminApi';
import Addartistype from '../../Artist/artistType/addartistType/Addartistype';
import Tableheader from '../../Table/Tableheader/Tableheader';
import Tabletwocol from '../../Table/Twocol/Tabletwocol';

const head = [
    {
        id: 1,
        label: "ID",
    },
    {
        id: 2,
        label: "Artist Type Name",
    },
];
export default function Subscriptionfeature() {
    const [button, setButton] = useState({ type: "add", text: "Add" });
    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(false);
    const [editdata, setEditdata] = useState(null);
    const dispatch = useDispatch();
    const features = useSelector((state) => state.subscriptionfeatureData)
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        dispatch(fetchsubscriptionFeature());
    }
    const handlePopup = () => {
        setEdit(true);
    }

    const handleSubmitAdd = (name) => {
        dispatch(handleAddSubscriptionFeature({ name }, setAdd, fetchData));
    };

    const handleSubmitEdit = (edit) => {
        dispatch(
            editSubscriptionfeaturedata(
                editdata._id,
                { name: edit },
                setEdit,
                fetchData
            )
        );
    };
    const handleDeletefeature = (id) => {
        dispatch(deleteSubscriptionFeature(id, fetchData));
    }
    return (
        <div>
            <AlertsSuccess />
            {add ? (
                <Addartistype
                    heading="Add New Subscription"
                    type="add"
                    fetchData={fetchData}
                    open={add}
                    setOpen={setAdd}
                    handleSubmitAdd={handleSubmitAdd}
                />
            ) : (
                ""
            )}
            {edit ? (
                <Addartistype
                    editdata={editdata}
                    handleSubmitEdit={handleSubmitEdit}
                    heading="Edit Subscription Feature"
                    type=""
                    fetchData={fetchData}
                    open={edit}
                    setOpen={setEdit}
                />
            ) : (
                ""
            )}
            {/* <Button onClick={handlePopup} variant='contained' >open</Button> */}
            <Tableheader button={button} setOpen={setAdd} />
            <Tabletwocol
                handleDeletefeature={handleDeletefeature}
                setEditdata={setEditdata}
                setOpen={setEdit}
                activetab="subscription_feature"
                head={head}
                data={features.data}
            />
        </div>
    )
}
